import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'view-claimed-event';

const translations = {
  title: 'View Claimed Request',
  noDescriptionProvided: 'No description provided',
  allDay: 'Anytime',
  providedBy: 'Provided by',
  questions: 'Questions regarding this help?',
  emailClaimOwner: 'Email Participant',
  thanksForPurchasing: 'Thanks for purchasing',
  claimOwnerPurchased: 'purchased',
  giftcardRequired: 'Please select a giftcard',
  moreButton: 'More',
  lessButton: 'Less',
  youAreNowFollowing:
    "You're now following the page, and will receive personal updates from the recipient or page organizers.",
  emailedConfirmation: 'We have emailed you a confirmation, and will send a reminder the day before.',

  claimFailConflict:
    'We’re sorry, somebody else just claimed this item before you did. Please select another item in the Calendar to offer support.',
  claimFailGone:
    'We’re sorry, it looks like this item no longer exists. Please select another item in the Calendar to offer support.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
