import packageJson from '@/../package.json';
import React from 'react';

export function getAppVersion() {
  if (!packageJson || !packageJson?.version)
    throw new Error('Could not import package.json or version field not available.');

  return `v${packageJson.version}`;
}

export function useAppVersion() {
  return React.useMemo(() => getAppVersion(), []);
}
