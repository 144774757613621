import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'search';

const translations = {
  title: 'Search Results',
  noResultsTitle: "We couldn't find anything for {{query}}",
  noResultsSubtitle: 'try narrowing down, or changing your search term',
  noResultsNote:
    '<span class="tw-font-bold">Note</span>: Many InKind Pages are private, and do not show here in search' +
    ' results.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
