import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { TextMessagesDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/TextMessagesDisabled';
import { TextMessagesOkOutline } from '@gik/ui/SvgIcon/GikIcons/TextMessagesOk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IGenericCardProps } from '../PrivacySettingsCard/PrivacySettingsCard';
import { PrivacySettingsCard } from '../PrivacySettingsCard/PrivacySettingsCard';

export const textMessagesCardBlockName = 'text-messages-card';

export const TextMessagesCard: React.FC<PropsWithClassName<IGenericCardProps>> = ({
  className,
  value,
  onChange,
  inputName,
}): JSX.Element => {
  const { t } = useTranslation();
  const bem = bemBlock(textMessagesCardBlockName);

  return (
    <PrivacySettingsCard
      className={bem(null, null, className)}
      title={t(translationKeys.textMessagesTitle)}
      icon={value ? TextMessagesOkOutline : TextMessagesDisabledOutline}
      description={
        value
          ? t(translationKeys.textMessagesDescription).toString()
          : t(translationKeys.textMessagesDescriptionDisabled).toString()
      }
      value={value}
      onChange={onChange}
      inputName={inputName}
    />
  );
};
