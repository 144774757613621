import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IAnimatedDivBone {
  className?: string;
}

const blockName = 'animated-div-bone';

export function AnimatedDivBone({ className }: IAnimatedDivBone) {
  const bem = useBemCN(blockName);

  return <div {...bem(null, null, [useBemCN('frame-bone')().className, className])} />;
}
