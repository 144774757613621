import { isBrowser } from '@/utils/environment';
import { useEnvStore } from '@gik/core/store/EnvStore';

type MatomoTrackEventFunction = [
  trackEvent: 'trackEvent',
  category: string,
  action: string,
  name: string,
  value?: number | string
];

type Matomo = MatomoTrackEventFunction;
interface WindowWithMatomo extends Window {
  _paq: Matomo[];
}

declare const window: WindowWithMatomo;

export function trackEvent(category: string, action: string, name: string, value?: number | string) {
  const useMatomo = useEnvStore.getState().USE_MATOMO === 'true';
  if (!isBrowser() || !useMatomo) return;

  window._paq?.push(['trackEvent', category, action, name, value]);
}
