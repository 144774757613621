import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import type { VenmoDonationFormValueType } from '@gik/create/components/DonationProvider/DonationProvider';
import DonationProvider, { DonationProviderType } from '@gik/create/components/DonationProvider/DonationProvider';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { venmoFormSchema } from '@gik/create/formSchemas';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { VenmoSupportButton } from '@gik/inkind-page/components/Support/VenmoSupportButton';
import React from 'react';

export interface IVenmoDonationProviderProps {
  value: VenmoDonationFormValueType;
  onValueChange: (value: VenmoDonationFormValueType) => void;
  asForm?: boolean;
  trackingId?: AnalyticsEvents;
  variant?: 'create' | 'page-settings';
  recalculateHeightCallback?: () => void;
}

const blockName = 'venmo-donation-provider';

export default function VenmoDonationProvider({
  value,
  onValueChange,
  asForm = false,
  trackingId,
  variant = 'create',
  recalculateHeightCallback,
}: IVenmoDonationProviderProps) {
  const bem = bemBlock(blockName);

  return (
    <DonationProvider
      asForm={asForm}
      trackingId={trackingId}
      schema={venmoFormSchema()}
      className={bem('content')}
      provider={DonationProviderType.Venmo}
      target={i18n.t(translationKeys.VenmoTargetVariantCreate)}
      // description={
      //   variant === 'page-settings'
      //     ? i18n.t(translationKeys.VenmoDescriptionVariantPageSettings)
      //     : i18n.t(translationKeys.VenmoDescriptionVariantCreate)
      // }
      value={value}
      onValueChange={onValueChange}
      recalculateHeightCallback={recalculateHeightCallback}
      previewComponent={_value => <VenmoSupportButton value={_value ?? value[VenmoFormInputNames.VenmoAddress]} />}
    />
  );
}
