import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';

const path = 'emailUsers';

export const emailUserTags = {
  createPageReminders: 'create-page-reminders',
};

export interface ITriggerEmailUserAnalyticsEventRequest {
  userEmail: string;
  eventName: string;
  eventParameters?: IAnalyticsProps;
}

export interface IEmailUserTagsRequest {
  userEmail: string;
  tags: string[];
}

export async function triggerEmailUserAnalyticsEvent(request: ITriggerEmailUserAnalyticsEventRequest) {
  await dotnetApi.post(`${path}/events`, {
    json: request,
  });
}

export async function addEmailUserTags(request: IEmailUserTagsRequest) {
  await dotnetApi.post(`${path}/tags`, {
    json: request,
  });
}

export async function removeEmailUserTags(request: IEmailUserTagsRequest) {
  await dotnetApi.delete(`${path}/tags`, {
    json: request,
  });
}
