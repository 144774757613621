import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { SupportButton } from '@gik/inkind-page/components/Support/SupportButton';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { PayPal } from '@gik/ui/SvgIcon/BrandIcons/PayPal';
import React from 'react';

export type IPayPalSupportButtonProps = UIComponent & {
  value?: string;
  onClick?: () => void;
};

export function PayPalSupportButton({ value, className, onClick }: IPayPalSupportButtonProps) {
  const bem = useBemCN('paypal-support-button');

  return (
    <SupportButton
      {...bem(null, null, className)}
      variant={'paypal'}
      href={`https://paypal.me/${value}`}
      onClick={onClick}
      icon={
        <SvgIcon
          style={{
            width: '84px',
            height: '32px',
            marginBottom: '6px',
          }}
          Icon={PayPal}
        />
      }
    />
  );
}
