import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const Calendar = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M57.5 6H46.5V4C46.5 3.4 46.1 3 45.5 3C44.9 3 44.5 3.4 44.5 4V6H15.5V4C15.5 3.4 15.1 3 14.5 3C13.9 3 13.5 3.4 13.5 4V6H3.5C2.9 6 2.5 6.4 2.5 7V19C2.5 19.6 2.9 20 3.5 20V38.5C3.5 39.1 3.9 39.5 4.5 39.5C5.1 39.5 5.5 39.1 5.5 38.5V20H55.5V53H5.5V46.3C5.5 45.7 5.1 45.3 4.5 45.3C3.9 45.3 3.5 45.7 3.5 46.3V53C3.5 54.1 4.4 55 5.5 55H55.5C56.6 55 57.5 54.1 57.5 53V20C58.1 20 58.5 19.6 58.5 19V7C58.5 6.4 58.1 6 57.5 6ZM4.5 18V8H13.5V9.1C11.8 9.5 10.5 11.1 10.5 13C10.5 15.2 12.3 17 14.5 17C16.7 17 18.5 15.2 18.5 13C18.5 11.1 17.2 9.6 15.5 9.1V8H44.5V9.1C42.8 9.5 41.5 11.1 41.5 13C41.5 15.2 43.3 17 45.5 17C47.7 17 49.5 15.2 49.5 13C49.5 11.1 48.2 9.6 46.5 9.1V8H56.5V18H4.5ZM14.5 13C15.1 13 15.5 12.6 15.5 12V11.3C16.1 11.6 16.5 12.3 16.5 13C16.5 14.1 15.6 15 14.5 15C13.4 15 12.5 14.1 12.5 13C12.5 12.3 12.9 11.6 13.5 11.3V12C13.5 12.6 13.9 13 14.5 13ZM45.5 13C46.1 13 46.5 12.6 46.5 12V11.3C47.1 11.6 47.5 12.3 47.5 13C47.5 14.1 46.6 15 45.5 15C44.4 15 43.5 14.1 43.5 13C43.5 12.3 43.9 11.6 44.5 11.3V12C44.5 12.6 44.9 13 45.5 13Z"
          fill="currentColor"
        />
        <path
          d="M4.5 43.5C5.1 43.5 5.5 43 5.5 42.5C5.5 42 5.1 41.5 4.5 41.5C3.9 41.5 3.5 41.9 3.5 42.5C3.5 43 3.9 43.5 4.5 43.5Z"
          fill="currentColor"
        />
        <path
          d="M9.5 48C9.5 49.7 10.8 51 12.5 51H15.5C17.2 51 18.5 49.7 18.5 48V45C18.5 43.3 17.2 42 15.5 42H12.5C10.8 42 9.5 43.3 9.5 45V48ZM11.5 45C11.5 44.4 11.9 44 12.5 44H15.5C16.1 44 16.5 44.4 16.5 45V48C16.5 48.6 16.1 49 15.5 49H12.5C11.9 49 11.5 48.6 11.5 48V45Z"
          fill="currentColor"
        />
        <path
          d="M32.6 30.4C33.1 30.8 33.8 31 34.5 31H37.5C38.2 31 38.8 30.8 39.4 30.4C40.1 29.8 40.5 29 40.5 28V25C40.5 23.3 39.2 22 37.5 22H34.5C32.8 22 31.5 23.3 31.5 25V28C31.5 29 31.9 29.8 32.6 30.4ZM33.5 25C33.5 24.4 33.9 24 34.5 24H37.5C38.1 24 38.5 24.4 38.5 25V28C38.5 28.6 38.1 29 37.5 29H34.5C33.9 29 33.5 28.6 33.5 28V25Z"
          fill="currentColor"
        />
        <path
          d="M31.5 38C31.5 39.7 32.8 41 34.5 41H37.5C39.2 41 40.5 39.7 40.5 38V35C40.5 34 40.1 33.2 39.4 32.6C38.8 32.2 38.2 32 37.5 32H34.5C33.8 32 33.2 32.2 32.6 32.6C31.9 33.2 31.5 34 31.5 35V38ZM33.5 35C33.5 34.4 33.9 34 34.5 34H37.5C38.1 34 38.5 34.4 38.5 35V38C38.5 38.6 38.1 39 37.5 39H34.5C33.9 39 33.5 38.6 33.5 38V35Z"
          fill="currentColor"
        />
        <path
          d="M31.5 48C31.5 49.7 32.8 51 34.5 51H37.5C39.2 51 40.5 49.7 40.5 48V45C40.5 43.3 39.2 42 37.5 42H34.5C32.8 42 31.5 43.3 31.5 45V48ZM33.5 45C33.5 44.4 33.9 44 34.5 44H37.5C38.1 44 38.5 44.4 38.5 45V48C38.5 48.6 38.1 49 37.5 49H34.5C33.9 49 33.5 48.6 33.5 48V45Z"
          fill="currentColor"
        />
        <path
          d="M45.5 31H48.5C50.2 31 51.5 29.7 51.5 28V25C51.5 23.3 50.2 22 48.5 22H45.5C43.8 22 42.5 23.3 42.5 25V28C42.5 29.7 43.8 31 45.5 31ZM44.5 25C44.5 24.4 44.9 24 45.5 24H48.5C49.1 24 49.5 24.4 49.5 25V28C49.5 28.6 49.1 29 48.5 29H45.5C44.9 29 44.5 28.6 44.5 28V25Z"
          fill="currentColor"
        />
        <path
          d="M12.5 41H15.5C17.2 41 18.5 39.7 18.5 38V35C18.5 33.3 17.2 32 15.5 32H12.5C10.8 32 9.5 33.3 9.5 35V38C9.5 39.7 10.8 41 12.5 41ZM11.5 35C11.5 34.4 11.9 34 12.5 34H15.5C16.1 34 16.5 34.4 16.5 35V38C16.5 38.6 16.1 39 15.5 39H12.5C11.9 39 11.5 38.6 11.5 38V35Z"
          fill="currentColor"
        />
        <path
          d="M20.5 48C20.5 49.7 21.8 51 23.5 51H26.5C28.2 51 29.5 49.7 29.5 48V45C29.5 44 29.1 43.2 28.4 42.6C27.8 42.2 27.2 42 26.5 42H23.5C22.8 42 22.2 42.2 21.6 42.6C20.9 43.2 20.5 44 20.5 45V48ZM22.5 45C22.5 44.4 22.9 44 23.5 44H26.5C27.1 44 27.5 44.4 27.5 45V48C27.5 48.6 27.1 49 26.5 49H23.5C22.9 49 22.5 48.6 22.5 48V45Z"
          fill="currentColor"
        />
        <path
          d="M21.6 40.4C22.1 40.8 22.8 41 23.5 41H26.5C27.2 41 27.8 40.8 28.4 40.4C29.1 39.8 29.5 39 29.5 38V35C29.5 33.3 28.2 32 26.5 32H23.5C21.8 32 20.5 33.3 20.5 35V38C20.5 39 20.9 39.8 21.6 40.4ZM22.5 35C22.5 34.4 22.9 34 23.5 34H26.5C27.1 34 27.5 34.4 27.5 35V38C27.5 38.6 27.1 39 26.5 39H23.5C22.9 39 22.5 38.6 22.5 38V35Z"
          fill="currentColor"
        />
        <path
          d="M42.7 35.7C42.8 36 43 36.2 43.2 36.5L46.3 39.7C46.7 40.1 47.3 40.1 47.7 39.7L50.8 36.5C51.2 36.1 51.5 35.5 51.5 34.8C51.5 34.2 51.3 33.6 50.8 33.1C50.4 32.7 49.8 32.4 49.2 32.4C48.6 32.4 48 32.6 47.6 33.1L46.9 33.8L46.2 33.1C46 32.9 45.7 32.7 45.5 32.6C45.2 32.5 44.9 32.4 44.6 32.4C44.3 32.4 44 32.5 43.7 32.6C43.4 32.7 43.2 32.9 43 33.1C42.8 33.3 42.6 33.6 42.5 33.9C42.4 34.2 42.3 34.5 42.3 34.8C42.5 35.2 42.6 35.5 42.7 35.7Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
