import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'situations-overview';

const translations = {
  title: 'For Life’s Challenging Moments',
  leadText: 'and more.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
