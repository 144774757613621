export default {
  title: 'Give By...',
  leadDescription:
    'Whether joyful or somber, life’s big moments inspire us to give from the heart. Congratulate or comfort your loved one with the perfect gift, browsing by situation, recipient, or gift type.',
  ShowAll: 'Show All',
  bySituationTitle: 'By Situation',
  byRecipientTitle: 'Browse By Recipient',
  byGiftTypeTitle: 'Browse By Gift Type',

  eligibleZipCodes: 'Available in Zips',
  ineligibleZipCodes: 'Unavailable in Zips',

  wishlistButtonLabel: 'Wishlist',
  buyButtonLabel: 'Buy Now',
};
