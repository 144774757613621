import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import type { ICreateAnnouncementPayload } from '@gik/api/calendar/calendarAnnouncements';
import { createAnnouncement } from '@gik/api/calendar/calendarAnnouncements';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { calendarEventMutatedAnalytics } from '@gik/calendar/utils/CalendarAnalytics';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import type { FormProps } from '@gik/ui/Form';
import type { FormikProps } from 'formik';
import React from 'react';
import type { ICalendarAddRequestValues } from '../EventCreateForm/CalendarEventCreateFlow';
import { calendarAnnouncementFormValuesToPayload } from '../EventForm/utils';
import type { ICalendarAnnouncementFormValues } from './CalendarAnnouncementForm';
import { CalendarAnnouncementForm } from './CalendarAnnouncementForm';

interface ICalendarAddAnnouncementFormProps extends FormProps {
  initialValues: Partial<ICalendarAnnouncementFormValues>;
  buttons: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (newEventId: string) => void;
  formValues: Partial<ICalendarAddRequestValues>;
  updateFormValues(values: Partial<ICalendarAddRequestValues>): void;
}

function CalendarAddAnnouncementFormComp({
  className,
  buttonsPortal,
  initialValues,
  formValues,
  updateFormValues,
  onSuccess = noop,
  buttons,
  ...otherProps
}: ICalendarAddAnnouncementFormProps): React.ReactElement {
  const bem = bemBlock('calendar-add-announcement-form');
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: calendarEventTypes } = useCalendarEventTypes();

  const setFormSaving = useCalendarStore(state => state.setFormSaving);

  async function handleSubmit(values: ICalendarAnnouncementFormValues) {
    const newValues = { ...formValues, ...values } as ICalendarAddRequestValues;
    updateFormValues(newValues);

    const payload: ICreateAnnouncementPayload = {
      ...(await calendarAnnouncementFormValuesToPayload(values)),
      typeId: formValues.eventTypeId,
      inkindRouteId,
    };

    setErrorMessage(null);
    setFormSaving(true);

    let newEventId: string;
    try {
      newEventId = await createAnnouncement(inkindRouteId, payload);
    } catch (err) {
      logger.error(err);
      setErrorMessage(err.message);
      setFormSaving(false);
      return null;
    }

    const typeName = calendarEventTypes?.find(type => type.id === payload.typeId)?.name;

    if (newEventId) {
      calendarEventMutatedAnalytics(
        AnalyticsEvents.eventCreated,
        newEventId,
        payload.allDay,
        payload.startsAt,
        payload.endsAt,
        payload.repeat,
        payload.description,
        payload.title,
        payload.typeId,
        typeName,
        payload.sendEmailToPageFollowers,
        undefined,
        {
          announcementTypeId: payload.announcementTypeId,
        }
      );

      onSuccess(newEventId);
    }
  }

  return (
    <div className={bem(null, null, className)}>
      <CalendarAnnouncementForm
        initialValues={{ ...initialValues }}
        buttonsPortal={buttonsPortal}
        errorMessage={errorMessage}
        buttons={buttons}
        {...otherProps}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export const CalendarAddAnnouncementForm = withComponentErrorBoundary(CalendarAddAnnouncementFormComp);
