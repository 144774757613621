import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import type { IUserLoginResponse } from '@gik/api/users/types';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import { signInFlowStartContentFormSchema } from '@gik/auth/formSchemas';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import { verifyEmailAndRedirectToLoginProvider } from '@gik/auth/utils/verifyEmailAndRedirectToLoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Case, Default, Switch } from '@gik/core/utils/SwitchCase';
import { Button } from '@gik/ui/Button';
import { Decoder } from '@gik/ui/Decoder';
import { Form, FormField } from '@gik/ui/Form';
import { Separator } from '@gik/ui/Separator';
import { ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum SignInFlowContentCopyVariant {
  GENERAL = 'general',
  INKIND_PAGE = 'inkind-page',
}

interface ISignInFlowStartFormValues {
  [SignInFlowStartContentFormInputNames.EmailAddress]: string;
}

export type ISignInFlowStartContentProps = UIComponent & {
  copyVariant?: SignInFlowContentCopyVariant;
  routeId?: string;
  goToLoginStep: (email: string, firstName: string) => void;
  goToAccountDeactivatedStep: (email: string, firstName: string) => void;
  goToSignUpStep: (email: string) => void;
  goToOAuthStep: (email: string, provider: ExternalLoginProvider, firstName: string) => void;
  initialValues?: ISignInFlowStartFormValues;
  trackingId?: AnalyticsEvents;
  onChange?: (values: ISignInFlowStartFormValues) => void;
  onSuccess(values: IUserLoginResponse);
  onIncorrectProvider?: (email: string) => Promise<void>;
};

export function SignInFlowStartContent({
  copyVariant,
  routeId,
  goToLoginStep,
  goToAccountDeactivatedStep,
  goToOAuthStep,
  goToSignUpStep,
  className,
  trackingId,
  initialValues,
  onChange,
  onSuccess,
  onIncorrectProvider,
  ...otherProps
}: ISignInFlowStartContentProps) {
  const bem = useBemCN('sign-in-flow-start-content');
  const { t } = useTranslation();
  const { data: inkindPage } = useInkind(routeId);

  async function handleSubmit(values: ISignInFlowStartFormValues) {
    const { [SignInFlowStartContentFormInputNames.EmailAddress]: email } = values;

    await verifyEmailAndRedirectToLoginProvider(
      email,
      goToLoginStep,
      goToAccountDeactivatedStep,
      goToSignUpStep,
      goToOAuthStep
    );
  }

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1 {...bem('title')}>{t(translationKeys.startContentTitle).toString()}</h1>

      <Switch value={copyVariant}>
        <Case value={SignInFlowContentCopyVariant.INKIND_PAGE}>
          <p {...bem('copy')}>
            {t(translationKeys.startContentCopyInKind)}
            {inkindPage ? (
              <strong>
                <Decoder text={inkindPage.title} />
              </strong>
            ) : (
              <ParagraphBone words={3} />
            )}
            .
          </p>
        </Case>
        <Default>
          <p {...bem('copy')}>{t(translationKeys.startContentCopyGeneral).toString()}</p>
        </Default>
      </Switch>

      <ExternalLoginForm vertical onSuccess={onSuccess} onIncorrectProvider={onIncorrectProvider} />

      <Separator variant="neutral-300" thickness="thin">
        <span className="tw-text-neutral-700">or</span>
      </Separator>

      <Form
        {...bem('form')}
        schema={signInFlowStartContentFormSchema}
        trackingId={trackingId}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onChange={onChange}
        render={({ isSubmitting, isValid }) => {
          return (
            <>
              <fieldset>
                <FormField name={SignInFlowStartContentFormInputNames.EmailAddress} />
              </fieldset>

              <Button
                {...bem('btnSubmit')}
                variant="primary"
                type="submit"
                size="lg"
                loading={isSubmitting}
                disabled={!isValid}
                fullWidth
              >
                Next
              </Button>
            </>
          );
        }}
      />
    </section>
  );
}
