import type {
  APIInfinitePagingConfig,
  APIInfiniteResponseInterface,
  OptimisticUpdate,
} from '@gik/core/api/BaseAPIConfig';
import type { KeyedMutatorWithHeaders } from '@gik/core/api/swr/middlewares/swrWithHeadersMiddleware';
import { SWRInfiniteDefaultPerPage, SWRInfiniteDefaultPerPageParamName } from '@gik/core/api/swr/swrDefaults';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
// Redeclare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

type GenericItemWithId = {
  id: string | number;
};

export type InfiniteLoaderSWRContentProps<D extends GenericItemWithId, T = D> = {
  data: D[];
  page: number;
  perPage: number;
  search: string;
  hasMore: boolean;
  transformed?: T[];
  isLoading: boolean;
  totalItems: number;
  totalPages: number;
  loadMore: () => void;
  mutate: KeyedMutatorWithHeaders<D[]>;
  optimisticUpdate: OptimisticUpdate<D>;
  error;
};

export type InfiniteLoaderSWRProps<D extends GenericItemWithId, T = D> = {
  loading?: boolean;
  search: string;
  header?(props?: InfiniteLoaderSWRContentProps<D, T>): React.ReactNode;
  children?(props?: InfiniteLoaderSWRContentProps<D, T>): React.ReactNode;
  footer?(props?: InfiniteLoaderSWRContentProps<D, T>): React.ReactNode;
  fetch: (paginationProps: APIInfinitePagingConfig, searchParams?: object) => APIInfiniteResponseInterface<D>;
  transform?(data: D[], props?: InfiniteLoaderSWRContentProps<D, T>): T[];
  onFetchDone?(data: D[], transformed: T[]): void;
} & UIComponent &
  APIInfinitePagingConfig;

export interface InfiniteLoaderRefProps<D extends GenericItemWithId, T = D> {
  mutate: () => void;
}

export function InfiniteLoaderSWRComp<D extends GenericItemWithId, T = D>(
  {
    children,
    className,
    perPage = SWRInfiniteDefaultPerPage,
    perPageParamName = SWRInfiniteDefaultPerPageParamName,
    search,
    loading,
    header,
    footer,
    fetch,
    transform = data => data as unknown as T[],
    onFetchDone,
    ...otherProps
  }: InfiniteLoaderSWRProps<D, T>,
  ref: React.MutableRefObject<InfiniteLoaderRefProps<D, T>>
): React.ReactElement {
  const bem = useBemCN('infinite-loader');

  // tracks _resHeaders['x-total-pages']
  const [totalPages, setTotalPages] = React.useState<number>(0);
  // tracks _resHeaders['x-total']
  const [totalItems, setTotalItems] = React.useState<number>(0);

  const searchProps = React.useMemo(
    () => ({
      ...(search ? { search } : {}),
    }),
    [search]
  );

  const pagingProps: APIInfinitePagingConfig = React.useMemo(
    () => ({
      ...(perPage ? { perPage } : {}),
      // perPageParamName,
    }),
    [perPage]
  );

  const {
    data,
    headers,
    mutate,
    error,
    size: page,
    setSize,
    isValidating,
    optimisticUpdate,
  } = fetch?.(pagingProps, searchProps) ?? {};

  const transformed = React.useMemo<T[]>(() => {
    if (!data) return undefined;
    return transform(data);
  }, [data, transform]);

  const isLoading = isValidating || (fetch && !data);
  const hasMore = page < totalPages;

  React.useEffect(() => {
    if (!data || !headers) return;

    const pages = headers['x-total-pages'] ?? data.length / perPage;
    const total = headers['x-total'];

    setTotalItems(total);
    setTotalPages(pages);

    onFetchDone?.(data, transformed);
  }, [headers, data, perPage, onFetchDone, transformed]);

  const loadMore = React.useCallback(() => {
    if (page >= totalPages) return;
    setSize(page + 1);
  }, [setSize, page, totalPages]);

  const renderProps: InfiniteLoaderSWRContentProps<D, T> = {
    data,
    transformed,
    page,
    perPage,
    hasMore,
    totalPages,
    totalItems,
    search,
    isLoading,
    loadMore,
    mutate: (data, shouldRevalidate?: boolean) => {
      if (!data && shouldRevalidate == undefined) return mutate();
      return mutate(data, headers, shouldRevalidate);
    },
    optimisticUpdate,
    error,
  };

  React.useImperativeHandle(ref, () => ({
    mutate,
  }));

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      {header?.(renderProps)}
      {children?.(renderProps)}
      {footer?.(renderProps)}
    </div>
  );
}

export const InfiniteLoaderSWR = React.forwardRef(InfiniteLoaderSWRComp);
