import TranslationUtils from '@gik/core/utils/TranslationUtils';
const blockName = 'external-product-interstitial';

const translations = {
  title: "That's so nice of you!",
  source:
    "Some products on Give InKind are purchased directly from our partners. We'll take you to {{sourceName}} to complete your purchase.",
  purchase: 'PURCHASE ON PARTNER SITE',
  buttonNotWorking: 'Button not working?',
  copyPasteInfo: 'Copy and paste the link for {{sourceName}} into your web browser instead.', // TODO: move into common translations
  copyLink: 'COPY LINK', // TODO: move into common translations
  copied: 'Link copied', // TODO: move into common translations
  helpfulInfo: 'Helpful information for your Wishlist purchase:',
  shippingAddress: 'Shipping Address:',
  recipientEmail: 'Digital gift card email:',
  recipientInfo: 'Recipient Info',
  emailLink: 'Email info to me',
  emailLinkLoggedIn: `We'll email this info to you as well`,
  emailText: `We'll email you the recipient's info and continue to purchase site`,
  emailKeepUpdated: `Keep me updated on Give InKind`,
  emailButton: `Send & continue with purchase`,
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
