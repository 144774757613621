export enum CreateStartedStep {
  ABOUT = 'About',
  SITUATIONS = 'Situation',
  CARE_CALENDAR = 'CareCalendar',
  WISHLIST = 'Wishlist',
  DONATIONS = 'Donations',
  PRIVACY = 'PrivacySettings',
  PAGE = 'PageName',
  RECIPIENT = 'RecipientInfo',
  FINISH = 'Finish',
}

export enum CreateClickedLocation {
  PAGE_HEADER = 'PageHeader',
  HOMEPAGE_TOP = 'HomePageTop',
  HOMEPAGE_HALF = 'HomePageHalf',
  HOMEPAGE_BOTTOM = 'HomePageBottom',
  PAGE_FOOTER = 'PageFooter',
  HOW_IT_WORKS_TOP = 'HowItWorksTop',
  HOW_IT_WORKS_HALF = 'HowItWorksHalf',
  HOW_IT_WORKS_BOTTOM = 'HowItWorksBottom',
  PAGE_FEATURES = 'FeaturesPage',
  COMPETITORS_PAGE = 'CompetitorsPage',
  FAQ_PAGE = 'FaqPage',
  TIPS_SAMPLE_PAGES_PAGE = 'TipsSamplePagesPage',
  BENEFITS_PAGE = 'BenefitsPage',
  SITUATIONS_PAGE = 'SituationsPage',
  SHOP_BOTTOM = 'ShopBottom',
}

export enum CreateHelpClickedProperties {
  STEP_NUMBER = 'stepNumber',
}
