import analytics from '@gik/analytics/components/i18n/en';
import emailConfirmation from '@gik/auth/components/EmailConfirmation/i18n/en';
import authForgotPassword from '@gik/auth/components/ForgotPassword/i18n/en';
import authLogin from '@gik/auth/components/Login/i18n/en';
import authResetPassword from '@gik/auth/components/ResetPassword/i18n/en';
import signInFlow from '@gik/auth/components/SignInFlow/i18n/en';
import walletSignInFlow from '@gik/auth/components/WalletSignInFlow/i18n/en';
import authSignup from '@gik/auth/components/Signup/i18n/en';
import calendarAnnouncements from '@gik/calendar/components/Announcements/i18n/en';
import calendarCalendar from '@gik/calendar/components/Calendar/i18n/en';
import viewClaimedEventModal from '@gik/calendar/components/ClaimEvent/i18n/en';
import contactPageOrganizers from '@gik/calendar/components/ContactPageOrganizers/i18n/en';
import contactPageOrganizersForAddress from '@gik/calendar/components/ContactPageOrganizersForAddress/i18n/en';
import calendarDoorDashButton from '@gik/calendar/components/DoorDashButton/i18n/en';
import calendarEmptyView from '@gik/calendar/components/EmptyView/i18n/en';
import CalendarEventCreateFlow from '@gik/calendar/components/EventCreateForm/i18n/en';
import calendarEventForm from '@gik/calendar/components/EventForm/i18n/en';
import calendarEventTypes from '@gik/calendar/components/EventTypes/i18n/en';
import calendarGiftcards from '@gik/calendar/components/GiftCards/i18n/en';
import calendarGrubhubButton from '@gik/calendar/components/GrubhubButton/i18n/en';
import modifyClaimedEventConfirmation from '@gik/calendar/components/ModifyClaimedEventConfirmation/i18n/en';
import recipientAddressPrompt from '@gik/calendar/components/RecipientAddressPrompt/i18n/en';
import calendar from '@gik/calendar/i18n/en';
import externalProductInterstital from '@gik/checkout/components/ExternalProductInterstitial/i18n/en';
import checkout from '@gik/checkout/i18n/en';
import contactPage from '@gik/contact/i18n/en';
import coreFormSchemas from '@gik/core/form/i18n/en';
import notFoundPage from '@gik/core/pages/NotFound/i18n/en';
import createPage from '@gik/create/i18n/en';
import crm from '@gik/crm/i18n/en';
import homepage from '@gik/homepage/i18n/en';
import inkindGroupAdmin from '@gik/inkind-page/group-admin/i18n/en';
import inkindPage from '@gik/inkind-page/i18n/en';
import marketingBenefitsHero from '@gik/marketing/components/BenefitsHero/i18n/en';
import marketingBenefitsList from '@gik/marketing/components/BenefitsList/i18n/en';
import marketingCompetitorsPreview from '@gik/marketing/components/CompetitorsPreview/i18n/en';
import marketingFeaturesNav from '@gik/marketing/components/FeaturesNav/i18n/en';
import marketingFeatureSteps from '@gik/marketing/components/FeatureSteps/i18n/en';
import marketingInkindUses from '@gik/marketing/components/InkindUses/i18n/en';
import marketingSamplePages from '@gik/marketing/components/SamplePages/i18n/en';
import marketingSituationsOverview from '@gik/marketing/components/SituationsOverview/i18n/en';
import marketingWhySupportMatters from '@gik/marketing/components/WhySupportMatters/i18n/en';
import marketingFeatures from '@gik/marketing/i18n/en/features';
import marketingBenefitsPage from '@gik/marketing/pages/BenefitsPage/i18n/en';
import marketingCommonQuestionsPage from '@gik/marketing/pages/CommonQuestionsPage/i18n/en';
import marketingCompetitorsPage from '@gik/marketing/pages/CompetitorsPage/i18n/en';
import marketingFeaturesPage from '@gik/marketing/pages/FeaturesPage/i18n/en';
import marketingHowItWorksPage from '@gik/marketing/pages/HowItWorksPage/i18n/en';
import marketingTipsSamplePage from '@gik/marketing/pages/TipsSamplePage/i18n/en';
import search from '@gik/search/i18n/en';
import situationsPage from '@gik/shop/i18n/en';
import stream from '@gik/stream/i18n/en';
import dialogs from '@gik/ui/Dialogs/i18n/en';
import form from '@gik/ui/Form/i18n/en';
import appFooter from '@gik/ui/gik/AppFooter/i18n/en';
import jitCollect from '@gik/ui/gik/JITCollect/i18n/en';
import relatedArticles from '@gik/ui/gik/RelatedArticles/i18n/en';
import StartInkindCard from '@gik/ui/gik/StartInkindCard/i18n/en';
import ui from '@gik/ui/i18n/en';
import claimedItemModal from '@gik/user-profile/components/ClaimedItemModal/i18n/en';
import wallet from '@gik/user-profile/components/Wallet/i18n/en';
import redemptionFlow from '@gik/user-profile/components/RedemptionModal/i18n/en';
import giftCardItem from '@gik/user-profile/components/GiftCardItem/i18n/en';
import claimedItems from '@gik/user-profile/components/ClaimedItems/i18n/en';
import savedArticles from '@gik/user-profile/components/SavedArticles/i18n/en';
import savedProducts from '@gik/user-profile/components/SavedProducts/i18n/en';
import userPages from '@gik/user-profile/components/UserPages/i18n/en';
import wishlistPage from '@gik/wishlist-page/components/i18n/en';

import app from './app';
import common from './common';
import core from './core';
import situations from './situations';

export default {
  ...core,
  ...common,
  ...situations,
  ...app,
  ...appFooter,
  ...ui,
  ...form,
  ...dialogs,
  ...externalProductInterstital,
  ...wishlistPage,
  ...analytics,
  ...modifyClaimedEventConfirmation,
  ...marketingFeatures,
  ...marketingFeaturesPage,
  ...marketingFeaturesNav,
  ...marketingCompetitorsPage,
  ...marketingBenefitsPage,
  ...marketingBenefitsHero,
  ...marketingBenefitsList,
  ...marketingInkindUses,
  ...marketingWhySupportMatters,
  ...marketingCommonQuestionsPage,
  ...marketingHowItWorksPage,
  ...marketingFeatureSteps,
  ...marketingSituationsOverview,
  ...marketingTipsSamplePage,
  ...marketingSamplePages,
  ...marketingCompetitorsPreview,
  ...StartInkindCard,
  ...homepage,
  ...modifyClaimedEventConfirmation,
  ...viewClaimedEventModal,
  ...savedArticles,
  ...savedProducts,
  ...claimedItems,
  ...claimedItemModal,
  ...userPages,
  ...authLogin,
  ...emailConfirmation,
  ...authForgotPassword,
  ...authResetPassword,
  ...authSignup,
  ...createPage,
  ...relatedArticles,
  ...recipientAddressPrompt,
  ...contactPageOrganizers,
  ...contactPageOrganizersForAddress,
  ...notFoundPage,
  ...inkindPage,
  ...inkindGroupAdmin,
  ...coreFormSchemas,
  ...calendar,
  ...calendarAnnouncements,
  ...calendarCalendar,
  ...calendarEmptyView,
  ...CalendarEventCreateFlow,
  ...calendarGiftcards,
  ...calendarGrubhubButton,
  ...calendarDoorDashButton,
  ...calendarEventTypes,
  ...calendarEventForm,
  ...jitCollect,
  ...checkout,
  ...contactPage,
  ...crm,
  ...situationsPage,
  ...search,
  ...stream,
  ...signInFlow,
  ...walletSignInFlow,
  ...wallet,
  ...giftCardItem,
  ...redemptionFlow,
};
