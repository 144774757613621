import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-event-create-flow';

const translations = {
  ServiceLabel: 'What types of {{name}} sounds good to you?',
  ServiceRequired: 'Please select at least one type',
  announcementFormTitle: "What's your announcement?",
  eventTypeTitleLabel: 'What type of help is needed?',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
