import { isBrowser } from '@/utils/environment';
import bemBlock from '@gik/core/utils/bemBlock';
import { dateTimeFormat } from '@gik/core/utils/DateTimeUtils';
import replacePath from '@gik/core/utils/replacePath';
import sleep from '@gik/core/utils/sleep';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import { UI } from '@gik/ui/UIManager';
import moment from 'moment';
import React from 'react';

export function getReminderTriggerKey(triggerKey: string, inkindRouteId: string) {
  return triggerKey + '-' + inkindRouteId;
}

// only allow one reminder per page load
// FIXME: we should be using JIT collection or some sort of modal queue
let anyReminderModalOpened = false;

export async function openReminder(blockName: string, title: string, description: string, onAdd: Function) {
  const bem = bemBlock(blockName);

  // FIXME: the close function is closing the page settings modal too, the sleep should not be needed here
  // this is caused by an issue with the back button override in modals and changing history
  // please see https://www.notion.so/giveinkind/Fix-back-button-override-causing-opened-modal-to-close-instantly-if-another-modal-is-closed-in-the-s-f78bb0f9436244eda23d692db3cdd68a
  async function handleAdd(close: Function) {
    close();
    await sleep(500);
    onAdd();
  }

  const result = await UI.confirm(
    <div className={bem('content')}>
      <div className={bem('copy')}>{description}</div>
    </div>,
    {
      title,
      centeredTitle: true,
      buttons: ({ close }) => (
        <>
          <Button variant="default" onClick={close}>
            {i18n.t(translationKeys.ReminderCancel).toString()}
          </Button>
          <Button variant="danger" onClick={() => handleAdd(close)}>
            {i18n.t(translationKeys.ReminderOk).toString()}
          </Button>
        </>
      ),
      closable: false,
    }
  );

  return result;
}

/**
 * opens the donations reminder modal if needed
 */
export function checkReminder(
  openReminder: Function,
  forceOpenQueryParameter: string,
  pageCreatedDate: moment.Moment,
  triggeredTimeStampKey: string,
  triggeredCountKey: string,
  firstReminderHours = 24,
  additionalReminderHours: number[] = [36],
  allowMultipleReminders = false
) {
  const urlParams = new URLSearchParams(window.location.search);

  // stop if a thank you modal should be opened
  if (urlParams.has('sendThankYou')) return;
  // stop if a tour should be opened
  if (urlParams.has('tour')) return;
  // stop if this is a sample page
  if (urlParams.has('sample-page')) return;

  if (urlParams.has(forceOpenQueryParameter)) {
    urlParams.delete(forceOpenQueryParameter);
    replacePath(urlParams);
    return openReminder();
  }

  if (!allowMultipleReminders && anyReminderModalOpened) {
    // do not allow multiple reminders to show on the same page
    return Promise.resolve(null);
  }

  let shouldOpen = false;

  const pageCreatedHoursAgo = moment().utc().diff(moment.utc(pageCreatedDate), 'hour', true);

  const triggerCount = isBrowser() && triggeredCountKey ? parseInt(localStorage.getItem(triggeredCountKey) || '0') : 0;
  const triggeredDateString = isBrowser() && triggeredTimeStampKey ? localStorage.getItem(triggeredTimeStampKey) : null;
  const triggeredHoursAgo = triggeredDateString
    ? moment().utc().diff(moment.utc(triggeredDateString), 'hour', true)
    : 0;

  // initial reminder
  if (triggerCount === 0 && pageCreatedHoursAgo > firstReminderHours) {
    // set as should open if modal hasn't been triggered yet
    shouldOpen = true;
  }

  // if the reminder was triggered once already it
  // should trigger again once if a specific number of hours has passed (different for donations and recipient address)
  if (
    triggerCount - 1 < additionalReminderHours.length &&
    triggeredHoursAgo > additionalReminderHours[triggerCount - 1]
  ) {
    shouldOpen = true;
  }

  if (!shouldOpen) return Promise.resolve(null);
  anyReminderModalOpened = true;
  // use localStorage to remember the last time the donations reminder was triggered
  if (triggeredTimeStampKey) {
    localStorage.setItem(triggeredTimeStampKey, moment().utc().format(dateTimeFormat));
    localStorage.setItem(triggeredCountKey, (triggerCount + 1).toString());
  }

  return openReminder();
}
