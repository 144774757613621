import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'forgot-password';

// TODO: fill in missing translations
const translations = {
  description:
    'Please enter the email address you registered with. <strong>We’ll email you a link to reset your password.</strong>',

  formSubmitButton: 'Submit',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
