import type { ISingleSelectProps, SelectOptionsType } from '@gik/ui/Select';
import ClockIcon from '@heroicons/react/outline/ClockIcon';
import React from 'react';
import { timeFromInt, timeToInt } from 'time-number';
import { Select } from './Select';

export interface ISelectTimeProps extends ISingleSelectProps {
  end?: string;
  format?: 12 | 24;
  start?: string;
  step?: number;
}

export function SelectTime({
  end = '23:59',
  format = 12,
  start = '00:00',
  step = 15,
  ...otherProps
}: ISelectTimeProps): React.ReactElement {
  const options = React.useMemo(() => {
    function generateFormattedTime(time) {
      const ret = timeFromInt(time);

      if (format === 24) {
        return ret;
      }

      const found = ret.match(/^(\d+):/);
      const hour = parseInt(found[1], 10);

      if (hour === 0) {
        return `${ret.replace(/^\d+/, '12')}AM`;
      } else if (hour < 12) {
        return `${ret}AM`;
      } else if (hour === 12) {
        return `${ret}PM`;
      }

      const newHour = hour < 22 ? `${hour - 12}` : (hour - 12).toString();

      return `${ret.replace(/^\d+/, newHour)}PM`;
    }

    function generateTimeRange() {
      const times = [];
      const iend = timeToInt(end);

      for (let i = timeToInt(start); i <= iend; i += step * 60) {
        times.push(i);
      }

      return times;
    }

    function getTimeOptions(): SelectOptionsType[] {
      return generateTimeRange().map((time: number) => {
        const formattedTime = generateFormattedTime(time);

        return {
          label: formattedTime,
          value: time?.toString(),
        };
      });
    }

    return getTimeOptions();
  }, [end, format, start, step]);

  const selectProps = {
    options,
    ...otherProps,
  };

  return <Select className="gik-select-time" {...selectProps} handlerIcon={ClockIcon} />;
}
