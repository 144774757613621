import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const Wishlist = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        ref={svgRef}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1828 17.8433L10.6625 20.5036C10.0318 21.1692 9.00647 21.1648 8.37133 20.4943L5.83171 17.8146C4.9375 16.8707 4.717 15.3773 5.40293 14.2671C6.346 12.742 8.33115 12.594 9.49279 13.8212C10.6458 12.604 12.6328 12.766 13.5862 14.3001C14.2808 15.4159 14.0706 16.9057 13.1828 17.8433Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M14.5 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V9.414C18.9999 9.1488 18.8946 8.89449 18.707 8.707L13.293 3.293C13.1055 3.10545 12.8512 3.00006 12.586 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V10.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
