import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { eventsApi } from '@gik/api/calendar';
import type { CreateEventPayload } from '@gik/api/calendar/calendarEvents';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { calendarEventMutatedAnalytics } from '@gik/calendar/utils/CalendarAnalytics';
import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import React from 'react';
import type { ICalendarEventFormProps, ICalendarEventFormValues } from './CalendarEventForm';
import { CalendarEventForm } from './CalendarEventForm';
import { calendarEventFormValuesToPayload, getStartTimeByTypeId } from './utils';

export interface ICalendarAddEventFormProps extends ICalendarEventFormProps {
  giftCards?: Product[];
  serviceIds: number[];
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (newEventId: string, addMore?: boolean) => void;
  onChange?: (values: ICalendarEventFormValues) => void;
}

const emptyValues: ICalendarEventFormValues = {
  title: null,
  description: null,
  repeat: null,
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
  allDay: false,
  allowGiftCards: undefined,
  weekdays: null,
  allowedServiceIds: null,
  sendEmailToPageFollowers: false,
};

function CalendarAddEventFormComp({
  buttonsPortal,
  onSuccess,
  buttons,
  giftCards,
  className,
  typeId,
  serviceIds,
  initialValues = {},
  ...otherProps
}: ICalendarAddEventFormProps): React.ReactElement {
  const bem = bemBlock('calendar-add-event-form');

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { hasChangedGiftCards } = useCalendarStore();

  const startTime = getStartTimeByTypeId(typeId);
  const endTime = startTime + 3600;

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [addMore, setAddMore] = React.useState<boolean>(false);

  const savingRef = React.useRef<boolean>(false);
  function setSaving(value: boolean) {
    savingRef.current = value;
  }

  async function handleSubmit(values: ICalendarEventFormValues, giftCardsOnly: boolean) {
    if (savingRef.current) return;
    if (serviceIds.length === 0) {
      throw new Error('no allowedServiceIds provided');
    }

    const payload: CreateEventPayload = {
      ...(await calendarEventFormValuesToPayload(values, giftCardsOnly)),
      inkindRouteId,
    };

    setErrorMessage(null);
    setSaving(true);

    let newEventId: string;
    try {
      newEventId = await eventsApi.createEvent(inkindRouteId, payload);
    } catch (err) {
      logger.error(`Failed to create event using payload: ${JSON.stringify(payload)}`, err);
      setErrorMessage(err.message);
      setSaving(false);
      return;
    }

    if (newEventId) {
      calendarEventMutatedAnalytics(
        AnalyticsEvents.eventCreated,
        newEventId,
        payload.allDay,
        payload.startsAt,
        payload.endsAt,
        payload.repeat,
        payload.description,
        payload.title,
        payload.typeId,
        calendarEventTypes.find(type => type.id === payload.typeId).name,
        payload.sendEmailToPageFollowers,
        {
          allowGiftCards: payload.allowGiftCards,
          giftCardsUpdated: hasChangedGiftCards,
          allowedGiftCardIds: payload.allowedGiftCardIds,
          deliveryInstructions: payload.deliveryInstructions,
          dropoffLocation: payload.dropoffLocation,
          emergencyContactInfo: payload.emergencyContactInfo,
          numberRequired: payload.numberRequired,
          petCareTypeId: payload.petCareTypeId,
          pickupLocation: payload.pickupLocation,
        }
      );
    }

    // don't set saving back to false so the save button will stay disabled
    // setSaving(false);
    setAddMore(false);
    onSuccess(newEventId, addMore);
  }

  return (
    <div className={bem(null, null, className)}>
      <CalendarEventForm
        typeId={typeId}
        errorMessage={errorMessage}
        initialValues={{
          ...emptyValues,
          ...initialValues,
          startTime,
          endTime,
          allowedServiceIds: serviceIds,
        }}
        giftCards={giftCards}
        buttonsPortal={buttonsPortal}
        buttons={buttons}
        {...otherProps}
        onSubmitForm={handleSubmit}
      />
    </div>
  );
}

export const CalendarAddEventForm = withComponentErrorBoundary(CalendarAddEventFormComp);
