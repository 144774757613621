import bemBlock from '@gik/core/utils/bemBlock';
import { copyWithNotification } from '@gik/core/utils/LinkUtils';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import DuplicateIcon from '@heroicons/react/solid/DuplicateIcon';
import React from 'react';
import type { IButtonProps } from '../Button/ButtonProps';
import type { UIButtonVariant, UISize } from '../types';

export interface ICopyButtonProps extends IButtonProps {
  icon?: React.ReactNode;
  text?: string;
  textToCopy: string;
  notificationText: string;
  variant?: UIButtonVariant;
  iconSize?: UISize;
  onClick?(): void;
}

const blockName = 'copy-button';

export function CopyButton({
  text,
  textToCopy,
  notificationText,
  onClick,
  icon,
  pill = true,
  size = 'sm',
  iconSize = 'sm',
  variant = 'default',
  ...otherProps
}: ICopyButtonProps) {
  const bem = bemBlock(blockName);

  return (
    <Button
      variant={variant}
      pill={pill}
      size={size}
      prepend={icon || <SvgIcon size={iconSize} Icon={DuplicateIcon} />}
      {...otherProps}
      className={bem()}
      onClick={() => {
        copyWithNotification(textToCopy, notificationText);
        onClick && onClick();
      }}
    >
      {text}
    </Button>
  );
}
