import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { EventLogType, useCalendarEventClaimHistory } from '@gik/api/calendar/calendar';
import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import RefreshIcon from '@heroicons/react/outline/RefreshIcon';
import React from 'react';

export const ResendButton = withComponentErrorBoundary(ResendButtonComp);

export type IResendButtonProps = UIComponent & {
  entry: ICalendarEntry;
  inkindRouteId: string;
  physical?: boolean;
};

function ResendButtonComp({ className, entry, inkindRouteId, physical, ...otherProps }: IResendButtonProps) {
  const bem = useBemCN('resend-button');
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [paused, setPaused] = React.useState<boolean>(false);
  const debounce = React.useRef<NodeJS.Timeout>();
  const cartItem = entry.claim?.cartItems?.length ? entry.claim.cartItems[0] : null;

  const { data: eventLogEntries } = useCalendarEventClaimHistory(inkindRouteId, entry);

  const status = React.useMemo(() => {
    if (!eventLogEntries) return 'Pending';

    if (
      eventLogEntries.some(
        eventLogEntry =>
          eventLogEntry.eventType === EventLogType.PerfectgiftFulfillment ||
          eventLogEntry.eventType === EventLogType.TangoCardFulfillment
      )
    ) {
      return 'Resend';
    }

    return 'Pending';
  }, [eventLogEntries]);

  // status will be based on the event log type

  const handleResend = React.useCallback(async () => {
    if (physical || submitting || status === 'Pending') return;

    if (paused) {
      UI.notifyWarning('Please wait 30 seconds before attempting to resend a giftcard');
      return null;
    }
    setSubmitting(true);
    Analytics.fireEvent(
      AnalyticsEvents.ResendTangoCard,
      { inkindRouteId, productName: cartItem.productName },
      () => ['productName'],
      () => ['inkindRouteId']
    );

    // eventlog entry id
    const response = await dotnetApi.post(
      `inkinds/${inkindRouteId}/supportHistory/${
        eventLogEntries.find(e => e.eventType == EventLogType.Purchase).Id
      }/resend`
    );
    if (response.ok) {
      UI.notifySuccess('Giftcard has been resent successfully');
    } else {
      UI.notifyError('Failed to resend giftcard');
    }

    setSubmitting(false);

    // disable the resend buttons for 30 seconds
    setPaused(true);
    debounce.current = setTimeout(() => {
      setPaused(false);
    }, 30000);

    return () => {
      clearTimeout(debounce.current);
    };
  }, [cartItem, eventLogEntries, inkindRouteId, paused, physical, status, submitting]);

  if (!eventLogEntries) return null;

  return (
    <Button
      {...otherProps}
      {...bem(null, null, className)}
      pill
      disabled={paused || submitting || status === 'Pending'}
      size="sm"
      variant="white"
      prepend={!physical && status === 'Resend' ? <SvgIcon Icon={RefreshIcon} /> : null}
      onClick={handleResend}
      tooltip={
        !physical && status == 'Pending'
          ? 'Gift cards are typically fulfilled and sent to the recipient in 24 hours.'
          : null
      }
    >
      {!physical ? status : 'via mail'}
    </Button>
  );
}
