import type { ICalendarEventTypeSelectListProps } from '@gik/calendar/components/EventTypes/CalendarEventTypesSelectList';
import type { PGGreetingCardDesign } from '@gik/core/models/wordpress/PGGreetingCardDesign';
import noop from '@gik/core/utils/noop';
import {
  PerfectgiftFaceplateButton,
  PerfectgiftGreetingCardButton,
  PerfectgiftUploadButton,
} from '@gik/shop/components/PerfectgiftFaceplateButtons/PerfectgiftFaceplateButtons';
import type { CheckboxProps } from '@gik/ui/Checkbox';
// import { Checkbox } from '@gik/ui/Checkbox';
import type { CreatableSelectProps } from '@gik/ui/CreatableSelect';
import type { InputDatePickerProps } from '@gik/ui/DatePicker';
import type { IGeoSuggestInput } from '@gik/ui/GeoSuggestInput';
import type { InputCurrencyProps, InputNumberProps, InputPhoneProps, InputProps } from '@gik/ui/Input';
import { ProductPrice } from '@gik/ui/ProductPrice';
import type { ISelectTimeProps, SelectOptionsType } from '@gik/ui/Select';
import type { TextAreaProps } from '@gik/ui/TextArea';
import type { IWeekdaySelectorProps } from '@gik/ui/WeekdaySelector';
import type { IYesNoButtonInputProps } from '@gik/ui/YesNoButtonInput';
import type { Moment } from 'moment';
import moment from 'moment';
import dynamic from 'next/dynamic';
import React from 'react';
import type { IImagePickerProps } from '../ImagePicker';
import type { RadioSelectProps } from '../Radio';
import type { RemoteSelectProps } from '../RemoteSelect';
import type { ISelectProps, ISelectUSStatesProps } from '../Select';
import type { ISelectListProps } from '../SelectList';
import type { ISwitchProps } from '../Switch';
import type { AnyType } from '../Table';
import { TextEditable } from '../TextEditable';
import type { UISize, UIVariant } from '../types';
import type { FieldInputProps, FormFieldExtraProps, FormSchemaEntry, PlainTextProps } from './index';

const InputDatePicker = dynamic<InputDatePickerProps>(() =>
  import('@gik/ui/DatePicker').then(mod => mod.InputDatePicker)
);

const YesNoButtonInput = dynamic<IYesNoButtonInputProps>(() =>
  import('@gik/ui/YesNoButtonInput').then(mod => mod.YesNoButtonInput)
);
const GeoSuggestInput = dynamic<IGeoSuggestInput>(() =>
  import('@gik/ui/GeoSuggestInput').then(mod => mod.GeoSuggestInput)
);
const CreatableSelect = dynamic<CreatableSelectProps<unknown>>(() =>
  import('@gik/ui/CreatableSelect').then(mod => mod.CreatableSelect)
);
const ThumbnailImagePicker = dynamic<IImagePickerProps>(() =>
  import('@gik/ui/ImagePicker').then(mod => mod.ThumbnailImagePicker)
);
const Input = dynamic<InputProps>(() => import('@gik/ui/Input').then(mod => mod.Input));
const InputCurrency = dynamic<InputCurrencyProps>(() => import('@gik/ui/Input').then(mod => mod.InputCurrency));
const InputNumber = dynamic<InputNumberProps>(() => import('@gik/ui/Input').then(mod => mod.InputNumber));
const InputPhone = dynamic<InputPhoneProps>(() => import('@gik/ui/Input').then(mod => mod.InputPhone));
const TextArea = dynamic<TextAreaProps>(() => import('@gik/ui/TextArea').then(mod => mod.TextArea));
const Checkbox = dynamic<CheckboxProps>(() => import('@gik/ui/Checkbox').then(mod => mod.Checkbox));
const ImagePicker = dynamic<IImagePickerProps>(() => import('@gik/ui/ImagePicker').then(mod => mod.ImagePicker));
const Switch = dynamic<ISwitchProps>(() => import('@gik/ui/Switch/Switch').then(mod => mod.Switch));
const Select = dynamic<ISelectProps>(() => import('@gik/ui/Select/Select').then(mod => mod.Select));
const SelectTime = dynamic<ISelectTimeProps>(() => import('@gik/ui/Select/SelectTime').then(mod => mod.SelectTime));
const SelectUSStates = dynamic<ISelectUSStatesProps>(() =>
  import('@gik/ui/Select/SelectUSStates').then(mod => mod.SelectUSStates)
);
const RadioSelect = dynamic<RadioSelectProps>(() => import('@gik/ui/Radio').then(mod => mod.RadioSelect));
const RemoteSelect = dynamic<RemoteSelectProps>(() => import('@gik/ui/RemoteSelect').then(mod => mod.RemoteSelect));
const SelectList = dynamic<ISelectListProps<unknown>>(() => import('@gik/ui/SelectList').then(mod => mod.SelectList));
const WeekdaySelector = dynamic<IWeekdaySelectorProps>(() =>
  import('@gik/ui/WeekdaySelector/WeekdaySelector').then(mod => mod.WeekdaySelector)
);
const CalendarEventTypeSelectList = dynamic<ICalendarEventTypeSelectListProps>(() =>
  import('@gik/calendar/components/EventTypes/CalendarEventTypesSelectList').then(
    mod => mod.CalendarEventTypeSelectList
  )
);

interface generateFieldArgs {
  entry: FormSchemaEntry;
  formFieldProps: FieldInputProps<unknown, HTMLElement>;
  hasError?: boolean;
  variant?: UIVariant;
  size?: UISize;
  disabled?: boolean;
  schemaComponentProps?: object;
  extraProps?: FormFieldExtraProps;
  onChange?(value: AnyType): void;
}

export function generateField({
  entry,
  formFieldProps,
  disabled,
  schemaComponentProps,
  hasError = false,
  extraProps,
  onChange = noop,
}: generateFieldArgs) {
  const fieldType = entry.type;

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.checked !== undefined) {
      onChange(!!(ev.target as HTMLInputElement).checked);
    } else {
      onChange((ev.target as HTMLInputElement).value);
    }
    formFieldProps.onChange?.(ev);
  };

  const handleOnChangeValue = (value: unknown) => {
    onChange(value);
    formFieldProps.onChange?.({
      target: {
        value,
        name: entry.name,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const mainProps = {
    name: entry.name,
    placeholder: entry.placeholder,
    maxLength: entry.maxLength,
    hasError,
    disabled: disabled,
    readOnly: entry.readOnly,
    onBlur: formFieldProps.onBlur,
    onFocus: formFieldProps.onFocus,
    onChange: handleOnChangeValue,
    ...extraProps,
    ...schemaComponentProps,
    ...(entry.props as object),
  };

  let _field: React.ReactElement;

  const style = {
    width: entry.width,
  };

  switch (fieldType) {
    case 'hidden':
      _field = <Input style={style} {...mainProps} value={entry.default as string} type="hidden" />;
      break;
    case 'plaintext':
      _field = (
        <div
          style={{
            ...((entry.props as PlainTextProps)?.centerVertical
              ? { marginTop: '6px' /* TODO: apply through sass */ }
              : {}),
          }}
        >
          <>
            {formFieldProps.value || entry.text}
            <Input
              style={style}
              {...mainProps}
              value={(formFieldProps.value || entry.default) as string}
              type="hidden"
            />
          </>
        </div>
      );
      break;
    case 'checkbox':
      delete mainProps.onChange;
      _field = (
        <Checkbox
          style={style}
          {...mainProps}
          onValueChange={handleOnChangeValue}
          checked={formFieldProps.checked as boolean}
        />
      );
      break;
    case 'switch':
      delete mainProps.onChange;
      _field = (
        <Switch
          style={style}
          {...mainProps}
          onValueChange={handleOnChangeValue}
          checked={formFieldProps.value as boolean}
        />
      );
      break;
    case 'textarea':
      delete mainProps.onChange;
      _field = (
        <TextArea
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onValueChange={handleOnChangeValue}
        />
      );
      break;
    case 'select':
      _field = <Select {...mainProps} value={formFieldProps.value as string} onChange={handleOnChangeValue} />;
      break;
    case 'select-time':
      _field = (
        <SelectTime
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onChange={handleOnChangeValue}
        />
      );
      break;
    case 'radio-select':
      _field = (
        <RadioSelect
          style={style}
          variant="default"
          {...(mainProps as typeof mainProps & RadioSelectProps)}
          value={formFieldProps.value as string}
        />
      );
      break;
    case 'select-us-states':
      _field = (
        <SelectUSStates
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onChange={handleOnChangeValue}
        />
      );
      break;
    case 'remote-select':
      _field = (
        <RemoteSelect
          {...(mainProps as typeof mainProps & RemoteSelectProps)}
          value={formFieldProps.value as string}
          onChange={handleOnChangeValue}
        />
      );
      break;
    case 'creatable-select':
      _field = <CreatableSelect {...mainProps} value={(formFieldProps.value as SelectOptionsType[]) || []} />;
      break;
    case 'date-picker':
      _field = (
        <InputDatePicker
          {...mainProps}
          value={formFieldProps.value ? moment(formFieldProps.value as string) : null}
          onChange={(date: Moment) => {
            if (!date) return;
            handleOnChangeValue(date);
          }}
        />
      );
      break;
    case 'currency':
      delete mainProps.onChange;
      _field = (
        <InputCurrency
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onValueChange={handleOnChangeValue}
          onChange={noop}
        />
      );
      break;
    case 'formatted-number':
      delete mainProps.onChange;
      _field = (
        <InputNumber
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onValueChange={handleOnChangeValue}
          onChange={noop}
        />
      );
      break;
    case 'phone':
      delete mainProps.onChange;
      _field = (
        <InputPhone
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onValueChange={handleOnChangeValue}
          onChange={noop}
        />
      );
      break;
    case 'image-picker':
      _field = (
        <ImagePicker
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onChange={result => handleOnChangeValue(result?.raster)}
        />
      );
      break;
    case 'thumbnail-image-picker':
      _field = (
        <ThumbnailImagePicker
          style={style}
          {...mainProps}
          value={formFieldProps.value as string}
          onChange={result => handleOnChangeValue(result?.raster)}
        />
      );
      break;
    case 'geosuggest':
      _field = <GeoSuggestInput {...mainProps} />;
      break;
    case 'select-list':
      delete mainProps.hasError;
      _field = <SelectList cols={1} {...mainProps} value={formFieldProps.value} render={entry.props['render']} />;
      break;
    case 'select-list-event-type':
      delete mainProps.hasError;
      _field = <CalendarEventTypeSelectList {...mainProps} />;
      break;
    case 'text-editable':
      delete mainProps.hasError;
      _field = <TextEditable {...mainProps} />;
      break;
    case 'yes-no':
      _field = (
        <YesNoButtonInput
          {...mainProps}
          onChange={value => {
            handleOnChangeValue(value);
          }}
          value={formFieldProps.value as boolean}
        />
      );
      break;
    case 'weekday-selector':
      delete mainProps.hasError;
      _field = <WeekdaySelector {...mainProps} value={formFieldProps.value as boolean[]} />;
      break;

    case 'product-price':
      _field = (
        <ProductPrice
          {...mainProps}
          product={entry.props['product']}
          toggleValue={entry.props['toggleValue']}
          onChange={value => {
            handleOnChangeValue(value);
          }}
          value={formFieldProps.value}
        />
      );
      break;

    case 'product-pg-upload':
      _field = (
        <PerfectgiftUploadButton
          product={entry.props['product']}
          toggleValue={entry.props['toggleValue']}
          onChange={value => {
            handleOnChange(value);
          }}
          value={formFieldProps.value as boolean}
        />
      );
      break;

    case 'product-pg-faceplate':
      _field = (
        <PerfectgiftFaceplateButton
          product={entry.props['product']}
          toggleValue={entry.props['toggleValue']}
          onChange={value => {
            handleOnChange(value);
          }}
          value={formFieldProps.value as boolean}
        />
      );
      break;

    case 'product-pg-greeting-card-design':
      _field = (
        <PerfectgiftGreetingCardButton
          onChange={value => {
            handleOnChangeValue(value);
          }}
          value={formFieldProps.value as PGGreetingCardDesign}
        />
      );
      break;

    // all other cases should be handled as a normal input field
    default:
      _field = (
        <Input
          style={style}
          {...mainProps}
          value={(formFieldProps.value as string) || ''}
          type={entry.fieldType || (fieldType as string)}
          onChange={handleOnChange}
        />
      );
  }

  return _field;
}
