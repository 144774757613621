import React from 'react';

export function useTouchDetect(onTouchStart: () => void, onTouchEnd: () => void) {
  React.useEffect(() => {
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchend', onTouchEnd);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchend', onTouchEnd);
    };
  }, []);
}
