import { translationKeys as calTranslationKeys } from '@gik/calendar/i18n/en';
import bemBlock from '@gik/core/utils/bemBlock';
import { convertDateFromBackend } from '@gik/core/utils/l10n';
import { dateTimeFormat, timeDisplayNoMeridiemFormat, timeMeridiemOnlyFormat } from '@gik/l10n';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IDateTimeDisplayProps extends React.HTMLAttributes<HTMLElement> {
  startsAt?: string;
  endsAt?: string;
  allDay?: boolean;
  dateTimeSeparator?: string;
  dateTimeSpacer?: boolean;
  timeSeparator?: string;
  shortTimes?: boolean;
  hideDate?: boolean;
  hideTime?: boolean;
  splitTimes?: boolean;
}

export default function DateTimeDisplay({
  startsAt = moment().format(dateTimeFormat),
  endsAt = moment().format(dateTimeFormat),
  allDay = false,
  hideDate,
  hideTime,
  splitTimes,
  className,
  shortTimes,
  dateTimeSeparator = '•',
  timeSeparator = '-',
  dateTimeSpacer = true,
  ...otherProps
}: IDateTimeDisplayProps): React.ReactElement {
  const bem = bemBlock('date-time-display');
  const { t } = useTranslation();

  const startTime = convertDateFromBackend(startsAt);
  const endTime = convertDateFromBackend(endsAt);

  let startTimeDisplay = startTime.format(timeDisplayNoMeridiemFormat) + startTime.format(timeMeridiemOnlyFormat);

  if (shortTimes) {
    startTimeDisplay = startTimeDisplay.replace(':00', '');
  }

  let endTimeDisplay = endTime.format(timeDisplayNoMeridiemFormat) + endTime.format(timeMeridiemOnlyFormat);
  if (shortTimes) {
    endTimeDisplay = endTimeDisplay.replace(':00', '');

    if (startTimeDisplay.endsWith('am') && endTimeDisplay.endsWith('am')) {
      startTimeDisplay = startTimeDisplay.replace('am', '');
    }
    if (startTimeDisplay.endsWith('pm') && endTimeDisplay.endsWith('pm')) {
      startTimeDisplay = startTimeDisplay.replace('pm', '');
    }
  }

  // FIXME: fix base components inheritance
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div
      {...(otherProps as any)}
      className={bem(
        null,
        [
          { 'short-times': shortTimes },
          {
            split:
              shortTimes &&
              splitTimes &&
              startTimeDisplay.includes(':') &&
              (startTimeDisplay.includes('am') || startTimeDisplay.includes('pm')),
          },
        ],
        className
      )}
    >
      {!hideDate && <span className={bem('date')}>{startTime.format('MMMM D, YYYY')}</span>}
      {!hideDate && !hideTime && dateTimeSeparator !== null && (
        <span className={bem('separator')}>{dateTimeSeparator}</span>
      )}
      {!hideTime && (
        <>
          {!hideDate && dateTimeSpacer && <span className={bem('spacer')}>&nbsp;</span>}
          <span className={bem('time')}>
            {!allDay ? (
              <>
                <span className={bem('startTime')}>{startTimeDisplay}</span>
                <span className={bem('timeSeparator')}>{timeSeparator}</span>
                <span className={bem('endTime')}>{endTimeDisplay}</span>
              </>
            ) : (
              <span className={bem('allDay')}>{t(calTranslationKeys.allDay)}</span>
            )}
          </span>
        </>
      )}
    </div>
  );
}
