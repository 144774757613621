import { QueryClient } from '@tanstack/react-query';

export const platformManagementQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      retry: 1,
    },
  },
});

export const useQueryDefaults = {
  retryDelay: 300,
  retry: (failureCount, error) => {
    // do not retry bad requests
    if (error.response?.status >= 400 && error.response?.status < 500) {
      return false;
    }
    if (failureCount >= 3) {
      return false;
    }
    return true;
  },
};
