import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const PhoneCallsOkOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M5.65605 5.28105C5.15595 5.78115 4.875 6.45942 4.875 7.16667V8.5C4.875 19.5453 13.8297 28.5 24.875 28.5H26.2083C26.9156 28.5 27.5939 28.219 28.094 27.719C28.594 27.2189 28.875 26.5406 28.875 25.8333V21.4613C28.8751 21.1814 28.787 20.9085 28.6233 20.6814C28.4596 20.4543 28.2286 20.2845 27.963 20.196L21.9723 18.1987C21.6679 18.0975 21.3371 18.1094 21.0408 18.2324C20.7445 18.3553 20.5024 18.581 20.359 18.868L18.8523 21.8773C15.5876 20.4022 12.9728 17.7874 11.4977 14.5227L14.507 13.016C14.794 12.8726 15.0197 12.6305 15.1426 12.3342C15.2656 12.0379 15.2775 11.7071 15.1763 11.4027L13.179 5.412C13.0906 5.14661 12.921 4.91574 12.6941 4.75207C12.4673 4.5884 12.1947 4.50021 11.915 4.5H7.54167C6.83442 4.5 6.15615 4.78095 5.65605 5.28105Z"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const PhoneCallsOk = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5.153C5.38971 2.00011 5.6187 2.08418 5.79924 2.23726C5.97979 2.39034 6.10018 2.6025 6.139 2.836L6.879 7.271C6.91436 7.48222 6.88097 7.69921 6.78376 7.89003C6.68655 8.08085 6.53065 8.23543 6.339 8.331L4.791 9.104C5.34611 10.4797 6.17283 11.7293 7.22178 12.7782C8.27072 13.8272 9.52035 14.6539 10.896 15.209L11.67 13.661C11.7655 13.4695 11.9199 13.3138 12.1106 13.2166C12.3012 13.1194 12.5179 13.0859 12.729 13.121L17.164 13.861C17.3975 13.8998 17.6097 14.0202 17.7627 14.2008C17.9158 14.3813 17.9999 14.6103 18 14.847V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H15C7.82 18 2 12.18 2 5V3Z"
          fill="#7BAB52"
        />
      </svg>
    );
  }
);
