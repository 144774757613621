import { PartnerInfoBox } from '@gik/calendar/components/ClaimEvent/services/partner/PartnerInfoBox';
import { PartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import type IWordpressDoorDashService from '@gik/core/models/wordpress/services/WordpressDoorDashService';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

type DoorDashBoxInfoProps = UIComponent & {
  entry: ICalendarEntry;
  event: ICalendarEvent;
  service: IWordpressDoorDashService;
};

export function DoorDashInfoBox({
  className,
  entry,
  event,
  service,
  ...otherProps
}: DoorDashBoxInfoProps): React.ReactElement {
  const bem = bemBlock('doordash-info-box');
  const partnerUrl = service.acf.container_module_doordash?.service_url;
  const partnerName = 'DoorDash';

  return (
    <PartnerInfoBox
      className={bem()}
      entry={entry}
      event={event}
      partnerUrl={partnerUrl}
      partnerName={partnerName}
      timeline={PartnerTimeline.FIVE_DAYS_PRIOR}
      {...otherProps}
    />
  );
}
