import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'modify-claimed-event-confirmation';

const translations = {
  title: 'Confirm {{modificationType}}',
  noteToClaimOwner: 'Note to {{claimOwnerFirstName}}',
  editInformation:
    'You are making changes to a request that is already claimed by {{claimOwnerFirstName}} {{claimOwnerLastName}}. Please enter a note below informing {{claimOwnerFirstName}} of the change:',
  deleteInformation:
    'You are deleting a request that is already claimed by {{claimOwnerFirstName}} {{claimOwnerLastName}}. Please enter a note below informing {{claimOwnerFirstName}} that you no longer need their help:',
  editAndSend: 'Edit & Send',
  deleteAndSend: 'Delete & Send',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
