import TranslationUtils from '@gik/core/utils/TranslationUtils';

const namespace = 'contact';

const translations = {
  ContactPageHeading: 'Contact & Support',
  FormHeading: 'Contact',
  FormSubheading: 'Feel free to reach out, for any reason:',
  ContactFormError:
    "We've encountered an error, please try again later. In the meantime, please use the orange chat button in the bottom right. Sorry for the inconvenience.",
  ContactFormSent: 'Thank you. We will get back to you very shortly.',

  ContactFormNameInputLabel: 'Name',
  ContactFormEmailInputLabel: 'E-mail',
  ContactFormMessageInputLabel: 'Message',
  ContactFormSignUpNewsletterInputLabel: 'Sign me up for the Give InKind Newsletter',

  LiveSupportTitle: 'Live Support',
  LiveSupportButtonText: 'Chat with a live representative',
  LiveSupportTimes: `<div>Mon-Fri 6am - 5pm PST</div>`,

  KnowledgeBaseTitle: 'Knowledge base',
  KnowledgeBaseButtonText: 'Browse all support articles',
  KnowledgeBaseContent: 'Find answers to common support questions before contacting us.',

  ConnectTitle: 'Connect on social media',

  BusinessInquiriesTitle: 'Local business inquiries',
  BusinessInquiriesButtonText: 'Local business submission form',
  BusinessInquiriesContent: 'Add your business to the list of products we recommend to our Give Inkind Users.',

  PressInquiriesTitle: 'Press inquiries',
  PressInquiriesButtonText: 'Visit our press center',
  PressInquiriesContent: `Direct press inquiries to <a href="mailto:{{email}}">{{email}}</a>.`,
};

export default {
  [namespace]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(namespace, translations);
