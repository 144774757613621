import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { donationsCardBlockName } from '@gik/create/components';
import type {
  CashAppDonationFormValueType,
  GoFundMeDonationFormValueType,
  PayPalDonationFormValueType,
  VenmoDonationFormValueType,
} from '@gik/create/components/DonationProvider/DonationProvider';
import DonationsContainer from '@gik/create/components/DonationsContainer/DonationsContainer';
import type { IPageFeatureCardProps } from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import PageFeatureCard from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { FundraisingThin } from '@gik/ui/SvgIcon/GikIcons/FundraisingThin';
import React from 'react';
import usePrevious from 'react-use/lib/usePrevious';

export type DonationsValues = PayPalDonationFormValueType &
  GoFundMeDonationFormValueType &
  VenmoDonationFormValueType &
  CashAppDonationFormValueType & {
    enabled: boolean;
  };

export interface IDonationsCardProps extends Pick<IPageFeatureCardProps, 'inputName'> {
  onChange?: (values: DonationsValues) => void;
  value: DonationsValues;
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
}

export default function DonationsCard({
  onChange = noop,
  value,
  className,
  inputName,
  disabled = false,
  direction,
}: PropsWithClassName<IDonationsCardProps>) {
  const bem = bemBlock(donationsCardBlockName);

  const isMd = useBreakpoint(Breakpoint.MD);

  // we need to detect when isMd, isPayPalValueValid and isGoFundMeValueValid are changed
  // when they are changed we need to force Accordion to recalculate height
  // initial validation error message is shown on blur
  // that's why we need to pass recalculateHeightCallback down to DonationProvider
  // and execute it there when blur state is changed
  const prevIsMdUp = usePrevious(isMd);
  const prevIsPayPalValueValid = usePrevious(value.isPayPalValueValid);
  const prevIsGoFundMeValueValid = usePrevious(value.isGoFundMeValueValid);
  const prevIsVenmoValueValid = usePrevious(value.isVenmoValueValid);
  const prevIsCashAppMeValueValid = usePrevious(value.isCashAppValueValid);
  const prevDirection = usePrevious(direction);

  const recalculateHeightFnRef = React.useRef<() => void>(noop);

  React.useEffect(() => {
    if (
      prevIsMdUp !== isMd ||
      prevDirection !== direction ||
      prevIsPayPalValueValid !== value.isPayPalValueValid ||
      prevIsGoFundMeValueValid !== value.isGoFundMeValueValid ||
      prevIsVenmoValueValid !== value.isVenmoValueValid ||
      prevIsCashAppMeValueValid !== value.isCashAppValueValid
    ) {
      recalculateHeightFnRef.current?.();
    }
  }, [
    isMd,
    prevIsMdUp,
    value,
    prevIsPayPalValueValid,
    prevIsGoFundMeValueValid,
    prevIsVenmoValueValid,
    prevIsCashAppMeValueValid,
    direction,
    prevDirection,
  ]);

  return (
    <PageFeatureCard
      className={bem(null, null, className)}
      icon={FundraisingThin}
      title={i18n.t(translationKeys.FundraisingTitle)}
      description={i18n.t(translationKeys.FundraisingDescription)}
      onChange={enabled =>
        onChange({
          ...value,
          enabled,
        })
      }
      value={value.enabled}
      inputName={inputName}
      disabled={disabled}
      recalculateHeightCallback={fn => (recalculateHeightFnRef.current = fn)}
      noSecondaryContentBackground
    >
      <DonationsContainer
        asForm={false}
        onChange={donationValues =>
          onChange({
            ...value,
            [GoFundMeFormInputNames.PageURL]: donationValues[GoFundMeFormInputNames.PageURL],
            [PayPalFormInputNames.EmailAddress]: donationValues[PayPalFormInputNames.EmailAddress],
            [VenmoFormInputNames.VenmoAddress]: donationValues[VenmoFormInputNames.VenmoAddress],
            [CashAppFormInputNames.CashAppAddress]: donationValues[CashAppFormInputNames.CashAppAddress],
            isPayPalValueValid: donationValues.isPayPalValueValid,
            isGoFundMeValueValid: donationValues.isGoFundMeValueValid,
            isVenmoValueValid: donationValues.isVenmoValueValid,
            isCashAppValueValid: donationValues.isCashAppValueValid,
          })
        }
        value={value}
        variant="page-settings"
        direction={direction}
        recalculateHeightCallback={recalculateHeightFnRef.current}
      />
    </PageFeatureCard>
  );
}
