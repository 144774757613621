import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories } from '@gik/api/calendar/serviceProductBundle';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import { timeFormat } from '@gik/core/utils/DateTimeUtils';
import { openIntercomWindow, useHideIntercomDefaultLauncher } from '@gik/core/utils/Intercom';
import { applyTimeToMoment } from '@gik/core/utils/moment';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { Button } from '@gik/ui/Button';
import type { FormRef } from '@gik/ui/Form';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import uniq from 'lodash/uniq';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeToInt } from 'time-number';
import { CalendarAddAnnouncementForm } from '../Announcements/CalendarAddAnnouncementForm';
import { CalendarAddEventForm } from '../EventForm/CalendarAddEventForm';
import type { ICalendarEventFormValues } from '../EventForm/CalendarEventForm';
import type { ICalendarServicesFormValues } from './CalendarEventServicesForm';
import { CalendarEventServicesFormFieldNames, CalendarServicesForm } from './CalendarEventServicesForm';
import { CalendarEventTypeForm } from './CalendarEventTypeForm';
import { translationKeys } from './i18n/en';

export interface ICalendarEventCreateFlowProps extends React.HTMLAttributes<HTMLDivElement> {
  onSuccess?: (newEventId: string, addMore: boolean) => void;
  buttonsPortal?: () => HTMLElement;
  date: Moment;
}

export interface ICalendarAddRequestValues extends ICalendarServicesFormValues, ICalendarEventFormValues {
  typeId: number;
}

function CalendarEventCreateFlowComp({
  onSuccess,
  className,
  date,
  buttonsPortal,
}: ICalendarEventCreateFlowProps): React.ReactElement {
  const bem = bemBlock('calendar-event-create-flow');
  const { t } = useTranslation();
  useHideIntercomDefaultLauncher();

  const servicesFormRef = React.useRef<FormRef>();

  if (!date) {
    date = moment();
    applyTimeToMoment(date, '12:00');
  }

  const [formValues, setFormValues] = React.useState<Partial<ICalendarAddRequestValues>>({
    startDate: date?.format(),
    // endDate: date?.format(dateFormat),
    startTime: timeToInt(date?.format(timeFormat)),
  });
  const [stepIndex, setStepIndex] = React.useState<number>(0);

  const [addMore, setAddMore] = React.useState<boolean>(false);

  const formSaving = useCalendarStore(state => state.formSaving);

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const { data: services } = useServices();
  const { data: serviceProductBundles } = useServiceCategories();

  function gotoPrevStep() {
    setStepIndex(stepIndex - 1);
  }

  function gotoNextStep() {
    setStepIndex(stepIndex + 1);
  }

  function updateFormValues(values) {
    setFormValues(values);
  }

  function onSelectEventType(typeId: number) {
    updateFormValues({ ...formValues, eventTypeId: typeId } as ICalendarAddRequestValues);

    const eventType = calendarEventTypes.find(item => item.id === typeId);

    Analytics.fireEvent(
      AnalyticsEvents.EventCreateStarted,
      {
        stepCompleted: 'RequestType',
        requestType: eventType.name,
      },
      _ => ['stepCompleted'],
      keys => keys.filter(key => key != 'stepCompleted')
    );

    if (typeId === CalendarEventTypeIds.Announcement) {
      // no service picker for announcements, so also fire that event
      Analytics.fireEvent(
        AnalyticsEvents.EventCreateStarted,
        {
          stepCompleted: 'HelpType',
        },
        _ => ['stepCompleted'],
        keys => keys.filter(key => key != 'stepCompleted')
      );
      // for announcements go straight to the form
      setStepIndex(2);
    } else {
      gotoNextStep();
    }
  }

  function onSubmitServicesForm(values: ICalendarServicesFormValues) {
    updateFormValues({ ...formValues, ...values } as ICalendarAddRequestValues);
    gotoNextStep();
  }

  function handleSaveAndAddMore(formId: string) {
    if (formSaving) return;
    setAddMore(true);

    // NOTE: timeout needed here because addMore will not be 'true' yet if we immediately submit the form
    // the addMore flow is picked up on form submit
    setTimeout(() => {
      document.getElementById(formId).dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }, timeoutDefaultValue);
  }

  function handleBack() {
    if (stepIndex === 1) updateFormValues({ ...formValues, allowedServiceIds: null } as ICalendarAddRequestValues);
    if (formValues.eventTypeId === CalendarEventTypeIds.Announcement) {
      setStepIndex(0);
    } else {
      gotoPrevStep();
    }
  }

  function handleSuccess(newEventId: string) {
    const typeName = calendarEventTypes.find(type => type.id === formValues.eventTypeId).name;

    if (formValues.eventTypeId === CalendarEventTypeIds.Announcement) {
      UI.notify(`${typeName} added`);
    } else {
      UI.notify(`${typeName} request added`);
    }

    onSuccess(newEventId, addMore);
  }

  function handleOpenIntercom() {
    openIntercomWindow();
  }

  function renderStep(step: number): React.ReactElement {
    const saveButtons = ({ submitting }, formId: string) => {
      const isSubmitting = submitting || formSaving;

      return (
        <div className={'gik-form__actions-new'}>
          <section>
            <Button variant={'default-light'} onClick={handleOpenIntercom} circle>
              <SvgIcon Icon={InterrogationRed} />
            </Button>
            <Button
              variant={'default-light'}
              onClick={handleBack}
              loading={isSubmitting}
              disabled={isSubmitting}
              preventClickWhenDisabled
              circle
            >
              <SvgIcon Icon={ChevronLeftIcon} />
            </Button>
          </section>
          <section>
            <Button
              onClick={() => handleSaveAndAddMore(formId)}
              loading={isSubmitting}
              disabled={isSubmitting}
              preventClickWhenDisabled
            >
              {/* TODO: handle translation for this */}
              Save &amp; Add<span className="gik-hidden-md-down">&nbsp;More</span>
            </Button>
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting} preventClickWhenDisabled form={formId}>
              {t(commonTranslationKeys.save)}
            </Button>
          </section>
        </div>
      );
    };

    const serviceIds = uniq(formValues[CalendarEventServicesFormFieldNames.Services]);

    switch (step) {
      case 0:
        return <CalendarEventTypeForm onChange={onSelectEventType} value={formValues.eventTypeId} />;
      case 1:
        return (
          <CalendarServicesForm
            initialValues={{
              [CalendarEventServicesFormFieldNames.Services]: formValues[CalendarEventServicesFormFieldNames.Services],
            }}
            eventTypeName={calendarEventTypes?.find(type => type.id === formValues.eventTypeId)?.name}
            eventTypeId={formValues.eventTypeId}
            ref={servicesFormRef}
            onSubmit={onSubmitServicesForm}
            buttonsPortal={buttonsPortal}
            buttons={({ isSubmitting, isValid }, formId: string) => (
              <div className={'gik-form__actions-new'}>
                <section>
                  <Button variant={'default-light'} circle>
                    <SvgIcon Icon={ChevronLeftIcon} onClick={handleBack} />
                  </Button>
                </section>
                <section>
                  <Button type="submit" form={formId} disabled={!isValid} loading={isSubmitting}>
                    {t(commonTranslationKeys.next)}
                  </Button>
                </section>
              </div>
            )}
          />
        );
      case 2:
        if (formValues.eventTypeId === CalendarEventTypeIds.Announcement) {
          return (
            <>
              <span className="gik-calendar-modal-section-title">{t(translationKeys.announcementFormTitle)}</span>
              <CalendarAddAnnouncementForm
                initialValues={{ ...formValues, allDay: true }}
                onSuccess={handleSuccess}
                buttonsPortal={buttonsPortal}
                buttons={saveButtons}
                formValues={formValues}
                updateFormValues={updateFormValues}
              />
            </>
          );
        }
        return (
          <CalendarAddEventForm
            serviceIds={serviceIds}
            typeId={formValues.eventTypeId}
            onSuccess={handleSuccess}
            initialValues={{ ...formValues }}
            buttonsPortal={buttonsPortal}
            buttons={saveButtons}
          />
        );
      default:
        return null;
    }
  }

  const isLoading = !services || !serviceProductBundles;

  if (isLoading) return <LoadingSpinner center />;

  return <div className={bem(null, null, className)}>{renderStep(stepIndex)}</div>;
}

export const CalendarEventCreateFlow = withComponentErrorBoundary(CalendarEventCreateFlowComp);
