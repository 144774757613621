import { navigateTo } from '@/utils/navigateTo';
import { getUser, useUser } from '@gik/api/users/user';
import { auth } from '@gik/auth';
import { logout, performLogin, signin } from '@gik/auth/utils/auth';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { createInkindPage } from '@gik/create/controllers/createPageController';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { Button } from '@gik/ui/Button';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const blockName = 'auth-tool';

export function AuthTool() {
  const bem = useBemCN(blockName);
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const loginHandler = React.useCallback(() => {
    signin();
  }, []);

  const logoutHandler = React.useCallback(() => {
    logout();
  }, []);

  const newHandler = React.useCallback(async () => {
    await logout(false);
    const response = await auth.executeUserSignUp({
      emailAddress: `dev+${uuidv4()}@giveinkind.com`,
      firstName: uuidv4(),
      lastName: uuidv4(),
      password: 'changeme',
      subscribeToNewsletter: false,
      inkindRouteId: history?.[0]?.inkindRouteId,
    });

    if (response) {
      performLogin(response.userId, response.refreshToken, response.refreshTokenHash, response.redirectUrl);
    }
  }, []);

  const newPageHandler = React.useCallback(async () => {
    const user = await getUser(useUserStore.getState().id);

    const response = await createInkindPage(uuidv4(), user, {
      pageName: `InKindTest ${uuidv4().split('-')[0]}`,
      situations: [37917],
      alternateNumberOwnerName: '',
      alternateNumber: '',
      careCalendarEnabled: true,
      wishlistEnabled: true,
      donationsEnabled: true,
      searchEnginesEnabled: true,
      flowersEnabled: true,
      pageSharingEnabled: true,
      phoneCallsEnabled: true,
      textMessagesEnabled: true,
      visitorsEnabled: true,
      featurePageEnabled: false,
      showPageEnabled: true,
      recipientType: RecipientType.Myself,
      wishlistItems: [71663, 92592, 121406],
    });

    navigateTo(`/inkinds/${response.routeId}`);
  }, []);

  return (
    <div {...bem(null, null, ['tw-flex', 'tw-flex-col', 'tw-gap-4', 'tw-p-4'])}>
      <div>User: {userId ?? 'Not logged in'}</div>
      {user?.fullName && <div>{user.fullName}</div>}
      {user?.emailAddress && <div>{user.emailAddress}</div>}
      <Button onClick={loginHandler}>Login</Button>
      <Button onClick={logoutHandler}>Logout</Button>
      <Button onClick={newHandler} tooltip={'Password is: changeme'}>
        Create new account & login
      </Button>
      <Button onClick={newPageHandler}>Create new InKind Page</Button>
    </div>
  );
}
