import ErrorImage from '@gik/core/assets/img/error-image.svg';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { HorVerLayout } from '@gik/core/types/layouts';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { PageSection } from '@gik/ui/gik/PageSection';
import React from 'react';
import routes from '../../routes';

export type ErrorPageSectionProps = {
  error?: unknown;
  errors?: unknown[];
  statusCode?: number;
  layout?: HorVerLayout;
  noPad?: boolean;
};

export function ErrorPageSection({ error, statusCode, layout = 'horizontal', noPad }: ErrorPageSectionProps) {
  const bem = useBemCN('error-page');
  const _showErrorDetails = useEnvStore(state => state.SHOW_ERROR_PAGE_STACK_TRACE);
  const showErrorDetails = _showErrorDetails === 'true';

  return (
    <PageSection {...bem(null, [{ ['layout-' + layout]: layout }, { 'no-pad': noPad }])}>
      <div {...bem('content')}>
        <div {...bem('main')}>
          <h1>We&apos;re Sorry</h1>
          <p>This wasn&apos;t supposed to happen...</p>
          <p>Something on our end has gone a bit haywire. We are working hard to get back up and running.</p>
          <p>
            Please check back shortly. You can also reach out to our{' '}
            <Button variant="primary-link" href="https://help.giveinkind.com/en" target="_blank">
              support team
            </Button>{' '}
            if needed.
          </p>
        </div>
        <ErrorImage {...bem('image')} />
      </div>
      <div {...bem('actions')}>
        <Button href={routes.home}>Take me home</Button>
      </div>
      <br />
      {statusCode && showErrorDetails && <div>Status code: {statusCode}</div>}
      {error && showErrorDetails && (
        <div>{typeof error === 'string' ? error : JSON.stringify(error, Object.getOwnPropertyNames(error))}</div>
      )}
    </PageSection>
  );
}
