import TranslationUtils from '@gik/core/utils/TranslationUtils';

const prefix = 'marketing-features';

const translations = {
  pageCreation: '"Page" can be created for recipient / cause',
  mealCalendar: 'Meal Calendar',
  nonMealRequestCalendar: 'Non-Meal Request Calendar',
  donationsFundraising: 'Donations',
  postUpdates: 'Ability to Post Updates',
  messageUsers: 'Ability to Message Users / Followers',
  productWishlist: 'Product Wishlist',
  productRecommendation: 'Product Recommendations',
  whatToSayRecommendations: '"What to Say" Recommendations',
  notificationPreferences: 'Notification Preferences',
  comments: 'Comments / Postes',
};

export default {
  [prefix]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(prefix, translations);
