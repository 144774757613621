import TranslationUtils from '@gik/core/utils/TranslationUtils';

const translations = {
  introTitle: 'Organize Support for Anyone, Anywhere.',
  introSubTitle: 'Easily create an InKind Page to let others know how to help through life’s challenging moments.',
  createPageCopy: 'Start an InKind Page',
  lifeIsFullOfUnknowns: 'Life is full of unknowns. Here’s what we do know...',

  titleCalendar: 'It takes a village.',
  descriptionCalendar: `Every situation is unique, and your <span class="tw-font-bold">Care Calendar</span> covers it all. Let others sign up to help with unlimited meals, rides, pet care, and more. For free.`,
  linkCalendar: 'Learn more about our Care Calendar',

  titleWishlist: 'Support comes in many forms.',
  descriptionWishlist: `Knowing how to help is hard. An InKind <span class="tw-font-bold">Wishlist</span> shares exactly what is needed most, empowering others to show up from anywhere.`,
  linkWishlist: 'Learn more about our Wishlist',

  titleUpdates: 'Your community cares.',
  descriptionUpdates: `Keeping friends and family updated can be overwhelming. <span class="tw-font-bold">Share news, photos and videos</span> from one centralized place.`,
  linkUpdates: 'Learn more about Updates',

  titleFundraising: 'We are stronger together.',
  descriptionFundraising: `Life can throw many unexpected moments our way. When extra help is needed, <span class="tw-font-bold">connect your favorite fundraiser</span> to ease the financial burden.`,
  linkFundraising: 'Learn more about donations',

  featureShareTitle: 'Share',
  featureShareCopy: 'Easily share your InKind Page with supporters via email, Facebook, and more',

  featureAddOrganizerTitle: 'Add Organizers',
  featureAddOrganizerCopy: 'Invite others to help organize support and manage your InKind Page',

  featurePrivacyTitle: 'Privacy Preferences',
  featurePrivacyCopy: 'Let others know if calls, texts, visitors, flowers, or sharing your InKind Page is ok',

  featureEatTitle: 'DoorDash',
  featureEatCopy: 'Schedule a meal delivery up to a week in advance for your Care Calendar',

  featureCardTitle: 'Gift Cards',
  featureCardCopy: 'Choose from 200+ digital gift cards to add to your InKind Wishlist',

  featureGiftTitle: 'Gift Boxes',
  featureGiftCopy: 'Send thoughtfully curated and hand-picked items to say “I’m thinking of you”',

  featureArticlesTitle: 'Articles & Guides',
  featureArticlesCopy: 'Find tips on what to say, how to help, and more on the Give InKind blog',

  featureRemindersTitle: 'Email Reminders',
  featureRemindersCopy: 'Provide peace of mind with automatic reminders to those that sign up to help',

  featureSupportTitle: 'Support',
  featureSupportCopy: 'Chat with real, live, caring people who are here to help if needed',

  featureFreeTitle: 'FREE',
  featureFreeCopy: 'Everyone deserves support — that’s why Give InKind is always free',

  inAction: 'InKind in action',

  createPageTitle: 'Create a Free Support Page',
  createPageContent: `Answering the question "how can I help?" can be hard. Give InKind makes it simple. Create a free Give InKind Page in minutes, share with friends and family, and get support when and where it's needed most.`,

  getStarted: 'GET STARTED',

  // TODO move this to core as soon as we have an endpoint exposing options and translation keys
  createPageMyself: 'Myself',
  createPageSomeoneElse: 'Someone else',
  createPageOrganization: 'Organization',
};

export default {
  homepage: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('homepage', translations);
