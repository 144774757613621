import { logger } from '@gik/analytics/utils/logger';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi, dotnetApi as ssrDotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { BaseUserWithLocation, User } from '@gik/core/models/gik/User';
import sleep from '@gik/core/utils/sleep';

const path = 'users';

export function getUser(id: string) {
  return ssrDotnetApi.get(`${path}/${id}`).json<User>();
}

export function useUser(id?: string, config?: SWRConfigInterface) {
  return useApi<User>(id ? `${path}/${id}` : null, null, config);
}

export function useUserSeenPremiumPageReminders(id?: string, config?: SWRConfigInterface) {
  return useApi<string[]>(id ? `${path}/${id}/seenPremiumPageReminders` : null, null, config);
}

export async function updateUser(id: string, user: BaseUserWithLocation): Promise<void> {
  await dotnetApi.put(`users/${id}`, {
    json: user,
  });
}

export async function updateUserAvatar(id: string, data: Blob): Promise<{ avatar_url: string }> {
  try {
    const response = await dotnetApi.put(`users/${id}/avatar`, {
      body: data,
    });

    return await response.json();
  } catch (e) {
    logger.error(e);
    throw e;
  }
}

export async function updateUserWelcomeScreenLastSeen(userId: string): Promise<void> {
  await dotnetApi.put(`users/${userId}/welcomeScreenLastSeen`);
}

export async function updateUserSeenPremiumPageReminders(userId: string, inkindRouteId: string): Promise<void> {
  await dotnetApi.put(`users/${userId}/seenPremiumPageReminders/${inkindRouteId}`);
}

export async function deleteUser(userId: string): Promise<void> {
  await dotnetApi.delete(`users/${userId}`);
}

export async function removeFavoriteArticle(userId: string, articleId): Promise<void> {
  await dotnetApi.delete(`users/${userId}/favorites/${articleId}`, {
    searchParams: {
      type: 'articles',
    },
  });
}

export async function removeFavoriteProduct(userId: string, productId): Promise<void> {
  await dotnetApi.delete(`users/${userId}/favorites/${productId}`, {
    searchParams: {
      type: 'products',
    },
  });
}

/**
 * Removes the current user from the page organizers. If page owner, the page is deactivated.
 */
export async function deactivateOrRemoveOrganizerPage(userId: string, routeId: string): Promise<void> {
  await dotnetApi.delete(`users/${userId}/inkinds/${routeId}/remove-organizer`);
  await sleep(2000); // give time for algolia to reindex
}

export async function unfollowPage(userId: string, routeId: string): Promise<void> {
  await dotnetApi.delete(`users/${userId}/inkinds/${routeId}/unfollow`);
  await sleep(2000); // give time for algolia to reindex
}

export async function followPage(userId: string, routeId: string): Promise<void> {
  await dotnetApi.post(`users/${userId}/inkinds`, {
    json: { routeId },
  });
}
