import { useSiteSettings } from '@gik/api/siteSettings/siteSettings';
import { useEnvStore } from '@gik/core/store/EnvStore';
import React from 'react';

export function useApplePay() {
  const { data: siteSettings } = useSiteSettings();
  const { ENABLE_APPLE_PAY } = useEnvStore(state => ({
    ENABLE_APPLE_PAY: state.ENABLE_APPLE_PAY,
  }));

  const isEnabled = React.useMemo(() => {
    return ENABLE_APPLE_PAY === 'true' && siteSettings?.applePayEnabled === 'true';
  }, [ENABLE_APPLE_PAY, siteSettings]);

  return {
    isEnabled,
  };
}
