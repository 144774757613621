import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'benefits';

const translations = {
  title: 'Benefits',
  leadDescription:
    'No more managing multiple tools to organize meals, fundraise, let people know what gifts would be helpful, or keep a health journal. Do it all on Give InKind — support made simple, so that no one goes through an important moment alone.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
