import React from 'react';
import type { ErrorFallbackProps } from '../components/ErrorBoundary';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ErrorBoundaryFallback } from '../components/ErrorBoundary/ErrorBoundaryFallback';

export type ComponentErrorBoundaryOptions = {
  ErrorFallback?: React.FC<ErrorFallbackProps>;
};

export default function withComponentErrorBoundary<Props, Ref = object>(
  WrappedComponent: React.FC<Props>,
  options: ComponentErrorBoundaryOptions = {}
) {
  const { ErrorFallback } = options;

  function wrapper(props: Props, ref: React.Ref<Ref>) {
    const comp = <WrappedComponent {...(props as Props)} ref={ref} />;

    return <ErrorBoundary fallback={ErrorFallback || ErrorBoundaryFallback}>{comp}</ErrorBoundary>;
  }

  return React.forwardRef<Ref, Props>(wrapper);
}
