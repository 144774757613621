import { requestResetPassword } from '@gik/api/users/auth';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { GIKApiError } from '@gik/core/models/gik/APIError';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

const blockName = 'forgot-password-form';

const schema: FormSchemaEntry[] = [
  {
    placeholder: 'Email address',
    name: 'email',
    type: 'email',
    required: true,
  },
];
export interface IForgotPasswordFormValues {
  email: string;
}
export interface IForgotPasswordFormProps {
  initialValues?: Partial<IForgotPasswordFormValues>;
  buttonsPortal?: () => HTMLElement;
  onSuccess?: () => void;
}

function ForgotPasswordFormComp({ buttonsPortal: _buttonsPortal, onSuccess, initialValues }: IForgotPasswordFormProps) {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState<string>();

  async function handleSubmit(values: IForgotPasswordFormValues) {
    setErrorMessage(null);
    const response = await requestResetPassword(values.email);

    if (response === true) {
      if (onSuccess) onSuccess();
      // this needs a timeout so the info modal doesn't get closed as a result of the onSuccess call above
      setTimeout(() => {
        UI.info(`Check ${values.email} for instructions to reset your Give InKind password.`, { title: 'Sent!' });
      }, timeoutDefaultValue);
    } else {
      setErrorMessage((response as GIKApiError).message || 'Something went wrong. Please try again.');
    }
  }

  const formId = 'forgotPasswordForm';

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        schema={schema}
        initialValues={initialValues}
        vertical
        className={bem('form')}
        id={formId}
        render={({ isValid, isSubmitting }) => {
          const buttons = (
            <>
              <Button
                className={bem('btnSubmit')}
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
                form={formId}
              >
                {t(translationKeys.formSubmitButton).toString()}
              </Button>
            </>
          );

          return (
            <>
              <div className={bem('description')}>{<HTMLParser rawHtml={t(translationKeys.description)} />}</div>
              <FormField name="email" />

              {errorMessage && <div className="gik-form__form-error">{errorMessage}</div>}

              {buttons}
            </>
          );
        }}
      />
    </>
  );
}

export const ForgotPasswordForm = withComponentErrorBoundary(ForgotPasswordFormComp);
