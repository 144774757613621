import { useGikSettings } from '@gik/api/acf/gik-settings/gikSettings';

export function usePerfectGiftCarrierPrices() {
  const { data: gikSettings } = useGikSettings();
  const greetingCardCarrierPrice = parseFloat(gikSettings?.acf.pg_greeting_card_price ?? '0');
  const trifoldWithEnvelopeCarrierPrice = parseFloat(gikSettings?.acf.pg_trifold_carrier_price ?? '0');

  return {
    greetingCardCarrierPrice,
    trifoldWithEnvelopeCarrierPrice,
  };
}
