import { isBrowser } from '@/utils/environment';
import { useDevStore } from '@gik/core/store/DevStore';
import React from 'react';
import type { APIError } from '../../utils/error';

export default function withSuspense<P, R>(
  WrappedComponent: React.FC<P>,
  Fallback?: React.FC<P>,
  ErrorFallback?: React.FC<{ errors: APIError[] }>
) {
  function WrappedWithSuspense(props: P, ref: React.Ref<R>) {
    const fallback = Fallback ? <Fallback {...(props as P)} /> : null;
    const forceSkeleton = useDevStore(state => state.forceSkeletons);

    let errorFallback = ErrorFallback;
    if (!errorFallback) {
      errorFallback = () => fallback;
    }

    const comp = <WrappedComponent {...(props as P)} ref={ref} skeleton={forceSkeleton} />;

    if (!isBrowser()) return comp;

    return <>{forceSkeleton ? fallback || comp : comp}</>;
  }

  return React.forwardRef<R, P>(WrappedWithSuspense);
}
