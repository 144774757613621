import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { getFaceplateURL } from '@gik/shop/components/PerfectgiftFaceplateSelector/PerfectgiftFaceplateSelector';
import type { OpenLoopType } from '@gik/shop/hooks/useOpenLoop';
import React from 'react';

export type PerfectgiftFaceplatePreviewProps = {
  faceplate: PerfectGiftFaceplate;
  recipientName?: string;
  customMessage: string;
  expiryMonth: string;
  customImage?: string;
  showMode: 'uploaded' | 'faceplate';
  overlay: OpenLoopType;
} & UIComponent;

export function PerfectgiftFaceplatePreview({
  children,
  faceplate,
  recipientName,
  customMessage,
  expiryMonth,
  customImage,
  showMode,
  overlay,
  style,
  className,
  ...otherProps
}: React.PropsWithChildren<PerfectgiftFaceplatePreviewProps>): React.ReactElement {
  const bem = useBemCN(`perfectgift-faceplate-preview`);

  let faceplateUrl;
  if (showMode == 'uploaded' && customImage) faceplateUrl = customImage;
  if (showMode == 'faceplate' && faceplate) faceplateUrl = getFaceplateURL(faceplate, 280 * 5);
  // if (!faceplateUrl) faceplateUrl = `${getAssetsUrl()}shop/ol-default.png`;

  return (
    <div {...otherProps} {...bem(null, null, className)} style={{ backgroundImage: `url(${faceplateUrl})`, ...style }}>
      <img
        {...bem('overlay')}
        src={overlay ? `${getAssetsUrl()}shop/${overlay}-field-headers-numbers.png` : undefined}
      />
      {/* {showMode == 'uploaded' && customImage && <img src={customImage} />} */}
      {/* {showMode == 'faceplate' && faceplate && <img src={getFaceplateURL(faceplate, 400)} />} */}
      {/* {!faceplate && !customImage && <img src={`${getAssetsUrl()}shop/ol-default.png`} />} */}

      <div {...bem('custom-overlay')}>
        {recipientName || 'Recipient Name'} <br />
        {customMessage || 'Custom Message'}
      </div>
      <div {...bem('month-overlay')}>{expiryMonth || '01/01'}</div>
    </div>
  );
}
