import { getCalendarAnnouncementIcon } from '@gik/calendar/utils/getCalendarAnnouncementIcon';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInPastAllTimezones } from '@gik/core/utils/DateTimeUtils';
import noop from '@gik/core/utils/noop';
import { Button } from '@gik/ui/Button';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { LineClamp } from '@gik/ui/LineClamp';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAnnouncementUIContextFromSlug } from '.';
import DateTimeDisplay from '../DateTimeDisplay/DateTimeDisplay';
import { translationKeys } from './i18n/en';

export interface ICalendarAnnouncementProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  iconName: string;
  title: string;
  description: string;
  customMoreButtonClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
  startsAt: string;
  endsAt: string;
  allDay: boolean;
  displayTime?: boolean;
}

export default function CalendarAnnouncement({
  className,
  type,
  iconName,
  title,
  description,
  customMoreButtonClick = noop,
  allDay,
  startsAt,
  endsAt,
  displayTime = true,
  ...otherProps
}: ICalendarAnnouncementProps): React.ReactElement {
  const bem = bemBlock('calendar-announcement');
  const { t } = useTranslation();

  const isPastEvent = React.useMemo(() => isInPastAllTimezones(moment.utc(startsAt)), [startsAt]);

  const buttonContext = getAnnouncementUIContextFromSlug(type);
  const customMoreButton = (clamped: boolean) => (
    <div className={bem('btn-more')}>
      <Button variant={buttonContext} size="xs" pill>
        {clamped && t(translationKeys.moreButton)}
        {!clamped && t(translationKeys.lessButton)}
      </Button>
    </div>
  );

  const icon = getCalendarAnnouncementIcon(iconName);

  return (
    <div className={bem(null, [{ [type]: type }, { past: isPastEvent }], className)} {...otherProps}>
      <header>
        <SvgIcon className={bem('icon')} Icon={icon} />

        {displayTime && (
          <DateTimeDisplay
            className="gik-hidden-md-down"
            shortTimes
            hideDate
            hideTime={allDay}
            startsAt={startsAt}
            endsAt={endsAt}
            allDay={false}
          />
        )}
      </header>
      <main>
        <span className={bem('title')}>
          <HTMLParser rawHtml={title} />
        </span>
        {displayTime && (
          <DateTimeDisplay
            className="gik-hidden-md"
            shortTimes
            hideDate
            hideTime={allDay}
            startsAt={startsAt}
            endsAt={endsAt}
            allDay={false}
          />
        )}
        <div className={bem('description')}>
          <LineClamp
            customButton={customMoreButton}
            lines={2}
            fadeOut={false}
            customButtonClick={customMoreButtonClick}
          >
            <HTMLParser rawHtml={description} />
          </LineClamp>
        </div>
      </main>
    </div>
  );
}
