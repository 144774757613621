import type { InkindPageViewHistoryItem } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import type { PopoverProps } from '@gik/ui/Popover';
import dynamic from 'next/dynamic';
import React from 'react';
import InkindPickerContent from './InkindPickerContent';

const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
  ssr: false,
});

export interface IInkindPickerProps {
  productNonD2CUrl: string;
  disableInterstitial?: boolean;
  inkindPageViewHistory: InkindPageViewHistoryItem[];
  isOpen: boolean;
  buyForSomeoneElseEnabled?: boolean;
  popoverTrigger: JSX.Element;
  onClose(): void;
  onInkindPageChosen(inkindRouteId: string): void;
  onBuyForSomeoneElse(): void;
}

export default function InkindPicker({
  popoverTrigger,
  isOpen,
  inkindPageViewHistory,
  buyForSomeoneElseEnabled,
  onClose,
  onInkindPageChosen,
  onBuyForSomeoneElse,
}: IInkindPickerProps): React.ReactElement {
  return (
    <Popover trigger={popoverTrigger} isOpen={isOpen} onClose={onClose} noPad className="gik-inkind-picker-popover">
      {isOpen && (
        <InkindPickerContent
          onInkindPageChosen={onInkindPageChosen}
          onBuyForSomeoneElse={onBuyForSomeoneElse}
          inkindPageViewHistory={inkindPageViewHistory}
          buyForSomeoneElseEnabled={buyForSomeoneElseEnabled}
        />
      )}
    </Popover>
  );
}
