import React from 'react';
import type { GikIconProps } from '.';
import { GikIconDefaultSize } from '.';

export const InterrogationRed = ({ size = GikIconDefaultSize, ...props }: GikIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.3607 17.2773C11.76 17.2773 11.3434 17.1401 11.1109 16.8657C10.8783 16.5914 10.7621 16.072 10.7621 15.3076C10.7621 14.4649 10.859 13.7593 11.0527 13.1909C11.2659 12.6226 11.5275 12.1522 11.8375 11.7798C12.1475 11.3878 12.4382 11.0742 12.7094 10.8391C12.942 10.6235 13.2035 10.4079 13.4942 10.1923C13.8042 9.9767 14.0949 9.74151 14.3661 9.48672C14.6374 9.21234 14.8602 8.91835 15.0346 8.60477C15.209 8.27158 15.2962 7.909 15.2962 7.51702C15.2962 6.83106 15.0443 6.33129 14.5405 6.0177C14.0367 5.68452 13.4167 5.51793 12.6804 5.51793C11.9441 5.51793 11.3918 5.63552 11.0237 5.87071C10.6555 6.0863 10.3842 6.35089 10.2098 6.66447C10.0354 6.95845 9.85137 7.22304 9.6576 7.45823C9.46383 7.67382 9.17318 7.78161 8.78565 7.78161C8.39812 7.78161 8.01058 7.64442 7.62305 7.37003C7.23551 7.09565 7.04175 6.67427 7.04175 6.1059C7.04175 5.57673 7.2452 5.00835 7.65211 4.40079C8.05902 3.77362 8.68877 3.24445 9.54134 2.81327C10.3939 2.38209 11.4693 2.1665 12.7676 2.1665C14.0077 2.1665 15.0928 2.38209 16.0228 2.81327C16.9529 3.22485 17.6699 3.80302 18.1737 4.54778C18.6968 5.27294 18.9584 6.0961 18.9584 7.01725C18.9584 7.70321 18.8228 8.34018 18.5515 8.92815C18.2996 9.51612 17.9896 10.0453 17.6214 10.5157C17.2533 10.986 16.8851 11.378 16.517 11.6916C16.1682 12.0052 15.8969 12.2404 15.7031 12.3972C15.18 12.8087 14.8021 13.2007 14.5696 13.5731C14.3371 13.9455 14.1917 14.3277 14.1336 14.7196C14.0949 15.1116 14.0755 15.5624 14.0755 16.072C14.0755 16.3464 13.9689 16.6207 13.7558 16.8951C13.5426 17.1499 13.0776 17.2773 12.3607 17.2773ZM12.3897 23.8332C11.7697 23.8332 11.2465 23.6078 10.8202 23.157C10.3939 22.7062 10.1808 22.1771 10.1808 21.5695C10.1808 20.9423 10.3939 20.4132 10.8202 19.982C11.2659 19.5312 11.789 19.3058 12.3897 19.3058C13.0098 19.3058 13.5329 19.5312 13.9592 19.982C14.4049 20.4132 14.6277 20.9423 14.6277 21.5695C14.6277 22.1771 14.4049 22.7062 13.9592 23.157C13.5329 23.6078 13.0098 23.8332 12.3897 23.8332Z"
        fill="#EF5C84"
      />
    </svg>
  );
};
