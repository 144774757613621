import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { eventsApi } from '@gik/api/calendar';
import type { UpdateEventPayload } from '@gik/api/calendar/calendarEvents';
import { deleteEvent } from '@gik/api/calendar/calendarEvents';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { CalendarEventForm, openModifyClaimedEventConfirmation, type ICalendarEventFormValues } from '@gik/calendar';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { calendarEventMutatedAnalytics } from '@gik/calendar/utils/CalendarAnalytics';
import { canSeeClaimOwner } from '@gik/calendar/utils/CalendarClaimUtils';
import { useProducts } from '@gik/checkout/api';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { dateTimeFormat, isInPastAllTimezones } from '@gik/core/utils/DateTimeUtils';
import { openIntercomWindow, useHideIntercomDefaultLauncher } from '@gik/core/utils/Intercom';
import { convertDateFromBackend } from '@gik/core/utils/l10n';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { timeDataFormat } from '@gik/l10n';
import { Button } from '@gik/ui/Button';
import { CodeBlock } from '@gik/ui/CodeBlock';
import type { FormProps } from '@gik/ui/Form';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Frequency, rrulestr } from 'rrule';
import { timeToInt } from 'time-number';
import { translationKeys } from './i18n/en';
import { calendarEventFormValuesToPayload } from './utils';

export type CalendarEventFormEditMode = 'instance' | 'series';

export interface ICalendarEditEventFormProps extends FormProps {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  buttonsPortal?: () => HTMLElement;
  onSuccess?: () => void;
  onDelete?: (response: boolean) => void;
}

function CalendarEditEventFormComp({
  buttonsPortal,
  onSuccess = noop,
  onDelete = noop,
  event,
  entry,
  className,
  ...otherProps
}: ICalendarEditEventFormProps): React.ReactElement {
  const bem = bemBlock('calendar-edit-event-form');
  const { t } = useTranslation();
  useHideIntercomDefaultLauncher();

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const { data: giftCards } = useProducts({ productIds: entry.allowedGiftCardIds });

  const allowedGiftCardIds = React.useMemo(() => entry.allowedGiftCardIds || [], [entry]);

  const [initialValues, setInitialValues] = React.useState<ICalendarEventFormValues>();

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [editMode, setEditMode] = React.useState<CalendarEventFormEditMode>(
    entry.repeat === '' || entry.repeat === undefined ? 'series' : undefined
  );

  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Moment>();
  const [endDate, setEndDate] = React.useState<Moment>();
  const { hasChangedGiftCards } = useCalendarStore();

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  const savingRef = React.useRef<boolean>(false);

  function setSaving(value: boolean) {
    savingRef.current = value;
  }

  React.useEffect(() => {
    if (!editMode) return;
    if (!event && !entry) return;

    setStartDate(convertDateFromBackend(editMode === 'instance' ? event.startsAt : entry.startsAt));
    setEndDate(entry.endsAt ? convertDateFromBackend(editMode === 'instance' ? event.endsAt : entry.endsAt) : null);
  }, [event, entry, editMode]);

  const isClaimed = !!entry.claim;
  const isPastEvent = React.useMemo(() => isInPastAllTimezones(moment.utc(event.startsAt)), [event]);
  const seriesHasClaim = entry.seriesHasClaim;

  // check if the current user is also the claimer
  const isClaimer = !!userId && userId === supporter?.id;
  // check if the current user can edit this page
  const isOrganizer = useInkindCan('manage', inkindRouteId, inkind?.groupId);

  /**
   * populate initial values
   */
  React.useEffect(() => {
    if (!event && !entry) return;
    if (!startDate) return;

    // if this is a repeated event wait to set initial values until the user has selected an editMode
    if (entry.repeat && editMode === undefined) return;

    const instanceStartTime = startDate?.isValid() ? startDate?.format(timeDataFormat) : null;
    const instanceEndTime = endDate?.isValid() ? endDate?.format(timeDataFormat) : null;

    let weekdays: boolean[];
    let repeat = -1;

    if (entry.repeat) {
      const rrule = rrulestr(entry.repeat.toString());

      if (rrule.options.freq === Frequency.WEEKLY && rrule.origOptions.byweekday) {
        // custom repeat
        repeat = 10;
        weekdays = [false, false, false, false, false, false, false];
        rrule.options.byweekday.map(weekday => {
          weekdays[weekday] = true;
        });
      } else if (rrule.options.freq === Frequency.DAILY && rrule.origOptions.interval === 2) {
        // every other day
        repeat = 11;
      } else {
        // normal repeat
        repeat = rrule.options.freq;
      }
    }

    const _initialValues: ICalendarEventFormValues = {
      title: entry.title,
      description: entry.description,
      repeat: editMode === 'instance' ? -1 : repeat,
      allDay: entry.allDay,
      startDate: startDate?.format(dateTimeFormat),
      endDate: endDate?.format(dateTimeFormat),
      startTime: instanceStartTime ? timeToInt(instanceStartTime) : null,
      endTime: instanceEndTime ? timeToInt(instanceEndTime) : null,
      allowGiftCards: entry.allowGiftCards,
      allowedServiceIds: entry.allowedServiceIds.concat([]),
      allowedGiftCardIds: allowedGiftCardIds.concat([]),
      numberRequired: entry.numberRequired,
      deliveryInstructions: entry.deliveryInstructions,
      emergencyContactInfo: entry.emergencyContactInfo,
      pickupLocation: entry.pickupLocation,
      dropoffLocation: entry.dropoffLocation,
      petCareTypeId: entry.petCareTypeId?.toString(),
      sendEmailToPageFollowers: false,
      weekdays,
    };

    setInitialValues(_initialValues);
  }, [allowedGiftCardIds, entry, editMode, endDate, startDate, event]);

  function updateEditMode(type: CalendarEventFormEditMode) {
    setEditMode(type);

    let dialogTitle = t(translationKeys.editDialogTitle);
    if (type === 'instance') {
      dialogTitle = t(translationKeys.editInstance);
    }
    if (type === 'series') {
      dialogTitle = t(translationKeys.editSeries);
    }
    // FIXME: better way to update the dialog title
    const titleEl = document.querySelector('.gik-calendar-edit-event .gik-modal-header__title');
    if (titleEl) {
      titleEl.innerHTML = dialogTitle;
    }
  }

  // get selected eventType
  const eventType = calendarEventTypes?.find(eventType => eventType.id === entry.typeId);

  if (!eventType) {
    throw new Error(`EventType '${eventType}' not recognized`);
  }

  async function handleSubmit(values: ICalendarEventFormValues, giftCardsOnly: boolean) {
    if (savingRef.current) {
      console.warn('savingRef.current was undefined while trying to submit the CalendarEditEventForm');
      return;
    }

    let noteToClaimOwner = null;

    // show a modification warning with a note to claim owner if this entry was already claimed
    // skip the notification if the owner is also the claimer
    if (entry.claim?.id && !isClaimer && isOrganizer) {
      noteToClaimOwner = await openModifyClaimedEventConfirmation({
        claimOwnerFirstName: supporter?.firstName,
        claimOwnerLastName: supporter?.lastName,
        modificationType: 'Edit',
      });
      if (!noteToClaimOwner) {
        return;
      }
    } else {
      console.warn('openModifyClaimedEventConfirmation did not open because the condition was not true', {
        claimId: entry.claim?.id,
        isClaimer,
        isOrganizer,
        conditionResult: entry.claim?.id && !isClaimer && isOrganizer,
      });
    }

    const payload: UpdateEventPayload = {
      ...(await calendarEventFormValuesToPayload(values, giftCardsOnly)),
      inkindRouteId,
      instanceDate: editMode === 'instance' ? event.startsAt : null,
      noteToClaimOwner,
    };

    setErrorMessage(null);
    setSaving(true);

    let response: boolean;

    try {
      response = await eventsApi.updateEvent(inkindRouteId, entry.id, payload);
    } catch (err) {
      logger.error('Failed to update event', err);
      if (err.exceptionType === 'System.IdentityModel.BadRequestException') {
        UI.notifyError(err.exceptionMessage);
      }
      setErrorMessage(err.message);
      setSaving(false);
      return;
    }

    if (response) {
      calendarEventMutatedAnalytics(
        AnalyticsEvents.eventUpdated,
        entry.id,
        payload.allDay,
        payload.startsAt,
        payload.endsAt,
        payload.repeat,
        payload.description,
        payload.title,
        payload.typeId,
        calendarEventTypes.find(type => type.id === payload.typeId).name,
        payload.sendEmailToPageFollowers,
        {
          allowGiftCards: payload.allowGiftCards,
          giftCardsUpdated: hasChangedGiftCards,
          allowedGiftCardIds: payload.allowedGiftCardIds,
          deliveryInstructions: payload.deliveryInstructions,
          dropoffLocation: payload.dropoffLocation,
          emergencyContactInfo: payload.emergencyContactInfo,
          numberRequired: payload.numberRequired,
          petCareTypeId: payload.petCareTypeId,
          pickupLocation: payload.pickupLocation,
        }
      );
    }

    // don't set saving back to false so the save button will stay disabled
    // setSaving(false);
    onSuccess();
  }

  async function handleDelete() {
    let noteToClaimOwner = null;
    let shouldDelete: boolean;

    if (editMode === 'instance') {
      const editModeName = t(translationKeys.requestType);
      const dialogTitle = t(translationKeys.deleteRequestConfirmTitle, { type: editModeName });

      shouldDelete = await UI.confirm(
        t(translationKeys.deleteRequestConfirm, { type: editModeName.toLocaleLowerCase() }),
        {
          title: dialogTitle,
          okButtonProps: { variant: 'danger' },
          okText: t(commonTranslationKeys.delete),
        }
      );
    } else {
      if (entry.claim?.id && !isClaimer && isOrganizer) {
        noteToClaimOwner = await openModifyClaimedEventConfirmation({
          claimOwnerFirstName: supporter?.firstName,
          claimOwnerLastName: supporter?.lastName,
          modificationType: 'Delete',
        });
        shouldDelete = !!noteToClaimOwner;
      } else {
        const isSeries = entry.repeat != null && entry.repeat != '';
        const editModeName = isSeries ? t(translationKeys.seriesType) : t(translationKeys.requestType);

        shouldDelete = await UI.confirm(
          t(translationKeys.deleteRequestConfirm, {
            type: editModeName.toLocaleLowerCase(),
          }),
          {
            title: t(translationKeys.deleteRequestConfirmTitle, { type: editModeName }),
            okButtonProps: { variant: 'danger' },
            okText: t(commonTranslationKeys.delete),
          }
        );
      }
    }
    if (shouldDelete) {
      let response: boolean;
      setDeleting(true);
      try {
        response = await deleteEvent(inkindRouteId, entry.id, {
          noteToClaimOwner,
          instanceDate: editMode === 'instance' ? event.startsAt : null,
        });
      } catch (err) {
        logger.error(err);
        if (err.exceptionType === 'System.IdentityModel.BadRequestException') {
          UI.notifyError(err.exceptionMessage);
        }
        setDeleting(false);
        return;
      }
      setDeleting(false);
      onDelete(response);
    }
  }

  function handleOpenIntercom() {
    openIntercomWindow();
  }

  // re-order filteredProducts so it has the same order as allowedGiftCardIds
  const giftCardsSorted = allowedGiftCardIds
    ?.map(id => {
      return giftCards?.find?.(item => item.id === id);
    })
    .filter(item => item); // remove null entries

  // choose editMode if this is a repeated event
  if (entry && entry.repeat && editMode === undefined) {
    return (
      <div className={bem('edit-choice', null, className)}>
        <div className="close-btn" onClick={close}></div>

        <Button onClick={() => updateEditMode('series')}>{t(translationKeys.editSeries).toString()}</Button>
        <p>{t(translationKeys.editSeriesDescription).toString()}</p>
        <hr />
        <Button onClick={() => updateEditMode('instance')}>{t(translationKeys.editInstance).toString()}</Button>
        <p>{t(translationKeys.editInstanceDescription).toString()}</p>
      </div>
    );
  }

  const isLoading = !calendarEventTypes || (!giftCards && allowedGiftCardIds?.length);

  if (isLoading)
    return (
      <>
        <CodeBlock value={giftCards} />

        <LoadingSpinner center />
      </>
    );

  if (!initialValues) return null;

  return (
    <div className={bem(null, null, className)}>
      <CalendarEventForm
        isClaimed={isClaimed}
        seriesHasClaim={seriesHasClaim}
        typeId={entry.typeId}
        errorMessage={errorMessage}
        initialValues={{ ...initialValues }}
        giftCards={giftCardsSorted}
        buttonsPortal={buttonsPortal}
        buttons={form => {
          const { isSubmitting } = form;
          const isSubmittingFinal = isSubmitting || savingRef.current || deleting;
          return (
            <div className={'gik-form__actions-new'}>
              <section>
                <Button variant={'default-light'} onClick={handleOpenIntercom} circle>
                  <SvgIcon Icon={InterrogationRed} />
                </Button>
              </section>
              <section>
                <Button
                  // onClick={form.submit}
                  type="submit"
                  form={'calendarEventForm'}
                  loading={savingRef.current}
                  disabled={isSubmittingFinal}
                  preventClickWhenDisabled
                >
                  {t(commonTranslationKeys.save).toString()}
                </Button>
                {!(isPastEvent && isClaimed) && (
                  <Button
                    variant="danger"
                    onClick={() => handleDelete()}
                    loading={deleting}
                    disabled={isSubmittingFinal}
                    preventClickWhenDisabled
                    prepend={<SvgIcon Icon={TrashIcon} />}
                    hideLabelOnMobile
                  >
                    {t(commonTranslationKeys.delete).toString()}
                  </Button>
                )}
              </section>
            </div>
          );
        }}
        {...otherProps}
        onSubmitForm={handleSubmit}
      />
    </div>
  );
}

export const CalendarEditEventForm = withComponentErrorBoundary(CalendarEditEventFormComp);
