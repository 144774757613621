import { isBrowser } from '@/utils/environment';
import type { BaseAPIResponseHeaders } from '@gik/core/api/BaseAPIConfig';
import type { MutatorCallback, SWRResponse } from 'swr';

export declare type KeyedMutatorWithHeaders<Data, Headers = Object> = (
  data?: Data | Promise<Data> | MutatorCallback<Data>,
  headers?: Headers,
  shouldRevalidate?: boolean
) => Promise<Data | undefined>;

function baseSwrWithHeadersMiddleware<D, E>(expandAPI) {
  return function (useSWRNext) {
    return (key, fetcher, config) => {
      const swr = useSWRNext(
        key,
        async (k, searchParams) => {
          const response = await fetcher(k, searchParams);

          let body: D;
          if (config?.text) body = await response.text();
          else body = await response.json();

          const headers: BaseAPIResponseHeaders = {
            ...(response.headers.get('x-total') ? { 'x-total': parseInt(response.headers.get('x-total')) } : {}),
            ...(response.headers.get('x-total-pages')
              ? { 'x-total-pages': parseInt(response.headers.get('x-total-pages')) }
              : {}),
          };

          return {
            body,
            headers,
          };
        },
        config
      );

      // fetcher function won't run on SSR so no need to manipulate the response body
      if (!isBrowser()) return swr;
      return expandAPI(swr);
    };
  };
}

function extendSwr<D>(originalSwr: SWRResponse) {
  const { data, mutate, ...rest } = originalSwr;

  return {
    ...rest,
    data: data?.body,
    headers: data?.headers,
    mutate(body?: D, headers?: BaseAPIResponseHeaders, shouldRevalidate?: boolean) {
      return mutate(body || headers ? { body, headers } : undefined, shouldRevalidate);
    },
  };
}

export const swrWithHeadersMiddleware = baseSwrWithHeadersMiddleware(extendSwr);

function extendSwrInfinite<D>(originalSwr: SWRResponse) {
  const { data, mutate, ...rest } = originalSwr;
  // FIXME: this is a workaround for whatever reason why optimistic updates seem to not work with SWR Infinite
  //   upon investigating the source code it seems it manipulates cache a little different than classic SWR
  let tempData = null;
  let tempHeaders = null;

  const mutateWithHeaders = async function (
    body?: D,
    headers: BaseAPIResponseHeaders = data?.[0]?.headers,
    shouldRevalidate?: boolean
  ) {
    if (!body && shouldRevalidate == undefined) return await mutate();

    const pageSize = data?.[0]?.body?.length;
    const a = [];
    for (let i = 0; i < (body as unknown as Array<unknown>).length; i += pageSize) {
      const chunk = (body as unknown as Array<unknown>).slice(i, i + pageSize);
      a.push({
        body: chunk,
        headers,
      });
    }

    return await mutate(a, shouldRevalidate);
  };

  return {
    ...rest,
    data: tempData ?? data?.map(d => d?.body),
    headers:
      tempHeaders ??
      data
        ?.map(d => d?.headers)
        .reduce(
          (prev, curr) => ({
            ...prev,
            ...curr,
          }),
          {}
        ),
    mutate: mutateWithHeaders,
    // TODO: extending the SWR api to return an optimistic update function seems like a good idea to implement on
    //  classic SWR too?
    async optimisticUpdate(
      newData: D,
      newHeaders?: BaseAPIResponseHeaders,
      apiFunction?: () => Promise<Response> | Promise<void>,
      remutate = true
    ) {
      tempHeaders = newHeaders ?? data?.[0]?.headers;
      tempData = newData;

      await mutateWithHeaders(tempData, tempHeaders, false);
      const result = await apiFunction();
      if (remutate) await mutateWithHeaders();

      tempData = null;
      tempHeaders = null;

      return result;
    },
  };
}

export const swrInfiniteWIthHeadersMiddleware = baseSwrWithHeadersMiddleware(extendSwrInfinite);
