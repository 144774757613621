import { timeoutDefaultValue } from '@gik/core/constants';
import { blurApp, unblurApp } from '@gik/core/utils/AppUtils';
import bemBlock from '@gik/core/utils/bemBlock';
import { MODAL_ANIMATION_LENGTH } from '@gik/ui/Modal/consts';
import classnames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';
import { animateScroll as scroll, scroller } from 'react-scroll';
import useLatest from 'react-use/lib/useLatest';
import useMeasure from 'react-use/lib/useMeasure';
import usePrevious from 'react-use/lib/usePrevious';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { ArrowButtonNext, ArrowButtonPrev } from '../ArrowButton';
import { LoadingSpinner } from '../LoadingSpinner';
import { ModalHeader } from './ModalHeader';
import type { ModalProps } from './types/ModalProps';

export type ModalRefProps = {
  scrollToTop: () => void;
};

export function ModalComp(
  {
    isOpen,
    pageNumber = 1,
    padding = true,
    pages,
    isLoading,
    closable = true,
    className,
    overlayClassName,
    header,
    footer,
    footerClass,
    overlay,
    centeredTitle,
    shouldCloseOnEsc = true,
    shouldCloseOnBack = true,
    shouldCloseOnOverlayClick = false,
    disableBackdrop,
    shouldReturnFocusAfterClose = true,
    backButtonOverrideEnabled = true,
    contentRef,
    contentId,
    headerId,
    closeAnimationLength = MODAL_ANIMATION_LENGTH,
    openAnimationLength = MODAL_ANIMATION_LENGTH,
    autoScrollContentTo,
    bodyOpenClassName,
    useBlur,
    portal,
    onClose,
    onAfterClose,
    onBack,
    onHeaderRef,
    onFooterRef,
    allowIntercom = false,
    bottom = false,
    allowOverflow = false,
  }: React.PropsWithChildren<ModalProps>,
  forwardedRef: React.MutableRefObject<ModalRefProps>
) {
  let _contentRef: HTMLDivElement;
  const bem = bemBlock('modal');

  React.useImperativeHandle(forwardedRef, () => ({ scrollToTop }));

  const [isReady, setIsReady] = React.useState<boolean>(false);
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [isClosing, setClosing] = React.useState<boolean>(false);
  const [scrolledToTop, setScrolledToTop] = React.useState<boolean>(false);
  const [scrolledToBottom, setScrolledToBottom] = React.useState<boolean>(false);

  const isOpenedLatest = useLatest(isOpened);

  const prevIsOpen = usePrevious(isOpen);
  React.useEffect(() => {
    if (prevIsOpen && !isOpen) {
      setClosing(true);
      setTimeout(handleAfterClose, closeAnimationLength);
    }
    if (!prevIsOpen && isOpen) {
      setTimeout(handleOpened, openAnimationLength);
    }
    // eslint-disable-next-line
  }, [isOpen, prevIsOpen]);

  React.useEffect(() => {
    _updateMoreContentIndicator();

    // we need one render cycle before rendering the contents so they have immediate access to the ref objects
    setTimeout(() => {
      setIsReady(true);
    }, timeoutDefaultValue);

    // eslint-disable-next-line
  }, []);

  const handleOpened = React.useCallback(() => {
    setIsOpened(true);
  }, []);

  const scrollToTop = React.useCallback(() => {
    scroll.scrollToTop({
      duration: 0,
      smooth: false,
      container: scrollContainerRef.current,
    });
  }, []);

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  // FIXME: remove 'any' type
  // eslint-disable-next-line
  const hasAutoScrollIdProp = (autoScroll: any): autoScroll is { id: string } => {
    return typeof autoScroll?.id === 'string';
  };
  const _autoScrollContentTo = hasAutoScrollIdProp(autoScrollContentTo) ? autoScrollContentTo.id : autoScrollContentTo;

  React.useEffect(() => {
    if (!isOpen || !_autoScrollContentTo || !scrollContainerRef?.current) {
      return;
    }

    const autoScrollOptions = {
      duration: 300,
      smooth: true,
      container: scrollContainerRef.current,
    };

    switch (_autoScrollContentTo) {
      case 'top':
        scroll.scrollToTop(autoScrollOptions);
        break;
      case 'bottom':
        scroll.scrollToBottom(autoScrollOptions);
        break;
      default:
        scroller.scrollTo(_autoScrollContentTo, autoScrollOptions);
    }
    // eslint-disable-next-line
  }, [isOpen, _autoScrollContentTo, scrollContainerRef?.current]);

  function handleAfterClose() {
    setClosing(false);
    if (useBlur) {
      setTimeout(() => {
        unblurApp();
      }, 500);
    }

    if (document.getElementsByClassName('gik-modal').length <= 0) {
      document.body.classList.remove(bodyOpenClassName);
      document.body.classList.remove('ReactModal__Body--open');
      document.body.classList.remove('ReactModal__Body--no-overflow');
    }

    onAfterClose?.();
  }

  function handleClose() {
    if (!isOpenedLatest) return false;
    onClose?.();
  }

  function handleAfterOpen() {
    if (useBlur) blurApp();
  }

  function handleArrowPrev() {
    if (currentPage?.onArrowPrev) {
      currentPage.onArrowPrev();
    }
  }

  function handleArrowNext() {
    if (currentPage?.onArrowNext) {
      currentPage?.onArrowNext();
    }
  }

  const [modalRef] = useMeasure();

  useUpdateEffect(() => {
    _updateMoreContentIndicator();
    setTimeout(() => _updateMoreContentIndicator(), Math.max(closeAnimationLength, openAnimationLength));
  });

  const _updateMoreContentIndicator = () => {
    if (!_contentRef) {
      return;
    }

    const _scrolledToTop = _contentRef.scrollTop === 0;
    const _scrolledToBottom = _contentRef.scrollTop + _contentRef.clientHeight > _contentRef.scrollHeight - 1;

    if (_scrolledToTop != scrolledToTop || _scrolledToBottom != scrolledToBottom) {
      setScrolledToTop(_scrolledToTop);
      setScrolledToBottom(_scrolledToBottom);
    }
  };

  const currentPage = pages[pageNumber - 1];

  const _footer = currentPage.footer || footer;

  const blockClasses = classnames([
    `modals modal-v2 gik-modal`,
    { closing: isClosing },
    { 'gik-modal--padded': padding },
    { 'gik-modal--hidden-title': !currentPage.title && !header },
    { 'gik-modal--has-footer': _footer },
    { 'gik-modal--top-indicator': !scrolledToTop },
    { 'gik-modal--bottom-indicator': !!_footer && !scrolledToBottom },
    { 'gik-modal--bottom': bottom },
    { 'gik-modal--allow-overflow': allowOverflow },
    className || '',
  ]);

  const dialogsBem = bemBlock('dialogs');

  const modalProps = {
    parentSelector: portal || undefined,
  };

  const finalOverlayClassName = bem(
    'overlay',
    [],
    [
      overlayClassName,
      { 'backdrop-disabled': disableBackdrop },
      { 'backdrop-enabled': !disableBackdrop },
      { closing: isClosing },
    ]
  );
  if (!isReady) return null;

  const Component = (
    <ReactModal
      contentRef={modalRef}
      isOpen={isOpen || prevIsOpen || isClosing}
      contentLabel={currentPage.title}
      ariaHideApp={false}
      shouldCloseOnEsc={closable && shouldCloseOnEsc}
      shouldCloseOnOverlayClick={closable && shouldCloseOnOverlayClick}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      onRequestClose={handleClose}
      onAfterOpen={handleAfterOpen}
      bodyOpenClassName={classnames([
        'ReactModal__Body--open', // default classname
        allowOverflow ? '' : 'ReactModal__Body--no-overflow',
        bodyOpenClassName || '',
      ])}
      onAfterClose={handleAfterClose}
      className={blockClasses}
      overlayClassName={finalOverlayClassName}
      portalClassName={dialogsBem(null, [{ 'allow-intercom': allowIntercom }])}
      {...modalProps}
    >
      {header !== undefined ? (
        <div className={bem('header')} ref={onHeaderRef}>
          {header}
        </div>
      ) : (
        <ModalHeader
          closable={closable}
          centeredTitle={centeredTitle}
          title={currentPage.title}
          content={currentPage.headerContent}
          onClose={handleClose}
          onBack={onBack}
          onRef={onHeaderRef}
          id={headerId}
          className={bem('header')}
        />
      )}
      <div
        className={bem('content-wrapper', [{ ['has-scroll']: scrolledToBottom === false || scrolledToTop === false }])}
        ref={element => {
          _contentRef = element;
          if (contentRef) {
            contentRef.current = element;
          }
          if (scrollContainerRef) {
            scrollContainerRef.current = element;
          }
          _updateMoreContentIndicator();
        }}
        onScroll={_updateMoreContentIndicator}
        id={contentId}
      >
        {currentPage.pageArrows && (
          <ArrowButtonPrev
            className={bem('arrow-prev')}
            disabled={currentPage.pageArrowPrevDisabled}
            onClick={handleArrowPrev}
          />
        )}
        {currentPage.pageArrows && (
          <ArrowButtonNext
            className={bem('arrow-next')}
            disabled={currentPage.pageArrowNextDisabled}
            onClick={handleArrowNext}
          />
        )}

        <div className={`content ${isLoading || !isReady ? 'loading' : ''}`}>
          {isLoading || !isReady ? <LoadingSpinner center /> : currentPage.content}
        </div>
      </div>
      <div className={bem('footer', [{ [footerClass]: footerClass }])} ref={onFooterRef}>
        {_footer}
      </div>

      {overlay ? <div className="modal-overlay">{overlay}</div> : null}
    </ReactModal>
  );

  return Component;
}

export const Modal = React.forwardRef(ModalComp);
