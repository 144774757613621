import bemBlock from '@gik/core/utils/bemBlock';
import { Checkbox } from '@gik/ui/Checkbox';
import { Image } from '@gik/ui/Image';
import React from 'react';

export interface ICalendarServiceTileProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  image: string;

  selected?: boolean;
  horizontal?: boolean;
  multiple?: boolean;
}

export function CalendarServiceTile({
  image,
  label,
  selected,
  horizontal,
  multiple,

  className,
}: ICalendarServiceTileProps) {
  const bem = bemBlock('calendar-service-tile');

  return (
    <div className={bem(null, [{ horizontal }, { vertical: !horizontal }, { selected }], className)}>
      <Image className={bem('image')} src={image} />
      <span className={bem('label')}>{label}</span>

      {multiple && <Checkbox checked={selected} />}
    </div>
  );
}
