import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Button } from '@gik/ui/Button';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import type { FormError } from '@gik/ui/Form/Form';
import { SvgIcon } from '@gik/ui/SvgIcon';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import React from 'react';
import type { FormVariant } from '../typesValues';
import type { FormFieldType } from './types';

export interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  required?: boolean;
  optional?: boolean;
  saving?: boolean;
  requiredAsterisk?: boolean;
  padded?: boolean;
  horizontal?: boolean;
  valid?: boolean;
  hideLabel?: boolean;
  fieldExtra?: React.ReactNode;
  fieldAppend?: React.ReactNode;
  label?: React.ReactNode;
  type?: string;
  fieldType?: FormFieldType | string;
  fieldWidth?: string;
  labelWidth?: number;
  labelClassName?: string;
  vertical?: boolean;
  disabled?: boolean;
  variant?: FormVariant;
  inline?: boolean;
  showError?: boolean;
  errorAfterTouched?: boolean;
  center?: boolean;
  requiredIndicator?: boolean;
  hasError?: boolean;
  error?: FormError;
  fieldName?: string;
  help?: string;
  showSavableButtons?: boolean;
  savable?: boolean;
  handleSave?: () => void;
  handleCancel?: () => void;
  highlight?: boolean;
}

const blockName = `form-group`;

/**
 * GIK FormGroup
 *
 * Displays a label and it's control in either a horizontal or vertical layout. Also displays control validation error messages.
 */
export function FormGroup({
  labelWidth,
  children,
  hasError = false,
  error,
  fieldExtra,
  fieldAppend,
  fieldWidth,
  fieldName,
  label,
  labelClassName,
  vertical,
  requiredIndicator,
  disabled,
  variant,
  fieldType,
  optional,
  required,
  hideLabel,
  saving,
  inline,
  showError = true,
  errorAfterTouched,
  center,
  id,
  help,
  className,
  showSavableButtons,
  savable,
  handleSave = noop,
  handleCancel = noop,
  highlight,
  ...otherProps
}: FormGroupProps): React.ReactElement {
  const bem = useBemCN(blockName);

  // const { form } = React.useContext(FormContext);
  // const ctxErrors = fieldHasError(form, fieldName);

  const labelStyle = React.useMemo(
    () => ({
      width: labelWidth ?? undefined,
    }),
    [labelWidth]
  );

  const errorStyle = React.useMemo(
    () => ({
      width: fieldWidth,
    }),
    [fieldWidth]
  );

  return (
    <div
      id={id}
      {...bem(
        null,
        [
          { vertical },
          { disabled },
          { [variant]: variant },
          { [`variant-${variant}`]: variant },
          { inline },
          { optional },
          { saving },
          { [fieldName]: fieldName },
          { center },
          { error: hasError },
          { [fieldType]: fieldType },
          { highlight },
        ],
        className
      )}
      {...otherProps}
      style={errorStyle}
    >
      {!hideLabel && label !== undefined && label !== null && label !== '' && (
        <div {...bem('label-wrapper')}>
          <label {...bem('label', null, labelClassName)} htmlFor={id} style={labelStyle}>
            {label}
            {required && requiredIndicator !== false && <span {...bem('required-indicator')}> *</span>}
            {optional && <span {...bem('optional-indicator')}> (optional)</span>}
            {help && <span {...bem('help')}>{help}</span>}
          </label>
        </div>
      )}
      {inline && ' '}
      <div {...bem('content-wrapper')}>
        <div {...bem('content')}>
          <div {...bem('control')}>{children}</div>
          {fieldExtra && <div {...bem('field-extra')}>{fieldExtra}</div>}
          {hasError && <div {...bem('error')}>{error.message}</div>}
        </div>
        {fieldAppend}
      </div>
      {savable && showSavableButtons && <SavableControls handleSave={handleSave} handleCancel={handleCancel} />}
    </div>
  );
}

type ISavableControls = {
  handleSave?: () => void;
  handleCancel?: () => void;
};

function SavableControls({ handleSave = noop, handleCancel = noop }: ISavableControls) {
  const bem = useBemCN('savable-controls');
  const commonProps: IButtonProps = {
    circle: true,
    size: 'xs',
  };

  return (
    <div {...bem()}>
      <Button variant={'default-extra-dark-plain'} {...bem('cancel-button')} {...commonProps} onClick={handleCancel}>
        <SvgIcon Icon={CloseIcon} />
      </Button>

      <Button variant={'success'} {...bem('save-button')} {...commonProps} onClick={handleSave}>
        <SvgIcon Icon={CheckIcon} />
      </Button>
    </div>
  );
}
