import type { FormSchemaEntry } from '@gik/ui/Form';

export const CompanyNameField = (): FormSchemaEntry => ({
  label: 'Company name',
  name: 'companyName',
  type: 'text',
  optional: true,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'organization' },
});
