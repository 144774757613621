import type { Hit } from '@algolia/client-search';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/i18n/en/app';
import type { InkindAlgoliaObject } from '@gik/search/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageRows } from './PageRows';

export type IPageRowsSectionProps = {
  onClick?(page: InkindPageAPIModel | Hit<InkindAlgoliaObject>): void;
} & UIComponent;

export function PageRowsSection({
  className,
  onClick,
}: React.PropsWithChildren<IPageRowsSectionProps>): React.ReactElement {
  const bem = bemBlock('page-rows-section');
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)}>
      <span className={bem('title')}>{t(translationKeys.pagesYouFollow).toString()}</span>
      <PageRows onClick={onClick} />
    </div>
  );
}
