import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'situations';

const translations = {
  NewBaby: 'New Baby',
  Adoption: 'Adoption',
  TwinsAndMore: 'Twins & More',
  NICU: 'NICU',
  Bedrest: 'Bedrest',
  PregnancyLoss: 'Pregnancy Loss',
  SeniorCare: 'Senior Care',
  MilitaryCare: 'Military Care',
  HeartacheDivorce: 'Heartache/Divorce',
  CharitableSupport: 'Charitable Support',
  LossOfJob: 'Loss of a Job',
  LossOfHome: 'Loss of a Home',
  OngoingSupport: 'Ongoing Support',
  MentalHealth: 'Mental Health',
  ClassroomTeacher: 'Classroom/Teacher',
  LossOfSpousePartner: 'Loss of a Spouse/Partner',
  LossOfChild: 'Loss of a Child',
  LossOfParent: 'Loss of a Parent',
  EndOfLifeHospice: 'End of Life/Hospice',
  LossOfSibling: 'Loss of a Sibling',
  Suicide: 'Suicide',
  OtherLossGrief: 'Other Loss/Grief',
  SickChild: 'Sick Child',
  Cancer: 'Cancer',
  Illness: 'Illness',
  BreastCancer: 'Breast Cancer',
  ChronicPainIllness: 'Chronic Pain/Illness',
  SurgeryInjury: 'Surgery/Injury',
  Stroke: 'Stroke',
  Recovery: 'Recovery',
  OtherMedical: 'OtherMedical',
  PediatricCancer: 'Pediatric Cancer',
  CoronaVirus: 'Pediatric Coronavirus',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
