import { translationKeys } from '@gik/calendar/i18n/en';
import bemBlock from '@gik/core/utils/bemBlock';
import { openExternalLink } from '@gik/core/utils/browser';
import i18n from '@gik/i18n';
import coreTranslationKeys from '@gik/i18n/en/core';
import { Button } from '@gik/ui/Button';
import { Apple } from '@gik/ui/SvgIcon/BrandIcons/Apple';
import { Google } from '@gik/ui/SvgIcon/BrandIcons/Google';
import { Outlook } from '@gik/ui/SvgIcon/BrandIcons/Outlook';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { IAddToCalendarLinks } from '.';

export function openAddToCalendarModal(links: IAddToCalendarLinks) {
  const bem = bemBlock('add-to-calendar-modal');

  function addToGoogle() {
    openExternalLink(links.google);
  }

  function addToOutlookApple() {
    openExternalLink(links.ics);
  }

  return UI.dialog(
    <div className={bem()}>
      <Button
        className={bem('to-calendar-button')}
        variant={'default-outline'}
        onClick={() => addToGoogle()}
        prepend={<SvgIcon Icon={Google} />}
      >
        {i18n.t(translationKeys.addToGoogleCalendar)}
      </Button>
      <Button
        className={bem('to-calendar-button')}
        uppercase={false}
        variant={'default-outline'}
        onClick={() => addToOutlookApple()}
        prepend={<SvgIcon Icon={Outlook} />}
        append={<SvgIcon Icon={Apple} />}
      >
        {i18n.t(translationKeys.addToICS)}
      </Button>
    </div>,
    {
      title: i18n.t(translationKeys.addToCalendarModalTitle),
      buttons: (
        <div>
          <Button variant={'default-dark'} onClick={() => UI.closeDialog()}>
            {i18n.t(coreTranslationKeys.core.close)}
          </Button>
        </div>
      ),
    }
  );
}
