import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'wallet-sign-in-flow';

const translations = {
  startContentTitle: 'Sign in to continue',
  startContentCopyGeneral: 'Sign in to access the InKind Wallet from {{pagename}}.',

  loginContentTitle: '👋️ Welcome Back, {{supportersFirstName}}',
  loginContentCopy: 'Please enter your password below',
  deactivatedContentCopy: 'Your account is not active. Please use the button below to select a different email.',

  oauthContentCopy: `It looks like you previously used <span class="tw-capitalize">{{provider}}</span> to sign in to Give InKind`,

  signupContentTitle: '🤝 Nice to meet you',
  signupContentCopy1: `We didn't find an account for that email. Try a `,
  signupContentCopy2: 'different email',
  signupContentCopy3: ', or sign up using...',

  twofaTitle: 'Enter 6-digit code',
  twofaCopy:
    'To make sure it’s you, we have sent a 6-digit verification code to your email. Please check your spam folder as the email can sometimes end up there.',

  headerTitleStart: 'Sign Up or Sign In',
  headerTitleLogin: 'Sign In',
  headerTitleOAuth: 'Sign In',
  headerTitle2fa: 'Verification Code',
  headerTitleSignup: 'Sign Up',
  headerTitleDeactivated: 'Account Deactivated',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
