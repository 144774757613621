import { useEnvStore } from '@gik/core/store/EnvStore';
import algoliasearch from 'algoliasearch/lite';

const {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
  ALGOLIA_INDEX_INKINDS,
  ALGOLIA_INDEX_PRODUCTS,
  ALGOLIA_INDEX_POSTS,
  ALGOLIA_INDEX_INTERCOM_ARTICLES,
} = useEnvStore.getState();

// TODO: listen to state changes and restart/reassign client and indexes with new values.
export const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY);
export const inkindsIndex = algoliaClient.initIndex(ALGOLIA_INDEX_INKINDS);
export const productsIndex = algoliaClient.initIndex(ALGOLIA_INDEX_PRODUCTS);
export const postsIndex = algoliaClient.initIndex(ALGOLIA_INDEX_POSTS);
export const intercomArticlesIndex = algoliaClient.initIndex(ALGOLIA_INDEX_INTERCOM_ARTICLES);
