import parse, { domToReact, type HTMLReactParserOptions } from 'html-react-parser';

export interface IHTMLParserProps {
  rawHtml: string;
  options?: HTMLReactParserOptions;
  allowFormattingTags?: boolean;
}

// tags that change the HTML visual representation
const formattingTags = ['h1', 'h2', 'h3', 'b', 'i', 'font', 'code', 'marquee'];

const replaceFn = ({ name, attribs, children }) => {
  if (formattingTags.includes(name)) {
    return <>{domToReact(children, { replace: replaceFn })}</>;
  }
  return undefined;
};

export function HTMLParser({ rawHtml, options: _options, allowFormattingTags = false }: IHTMLParserProps) {
  return (
    <>
      {rawHtml ? (
        <>
          {parse(rawHtml, {
            ..._options,
            replace: !allowFormattingTags ? replaceFn : _options?.replace,
          })}
        </>
      ) : null}
    </>
  );
}
