import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'claimed-item-modal';

const translations = {
  claimInfo: 'Claim Info',
  who: 'Who',
  addressNotDisplayed: 'Address not displayed for items in the past',
  when: 'When',
  what: 'What',
  deliveryService: ' - Delivery Service',
  noDescription: 'No description provided.',
  toSchedule: 'To schedule your meal delivery:',
  youWillBeAble: 'You’ll be able to schedule your meal delivery on ',
  beSureToOrder: 'Be sure to order your meal for:',
  pleaseRememberToCopy:
    'Please remember to copy the recipient’s address above to enter on {{partner}} and note the date and time for' +
    ' scheduling.',
  yourNotes: 'Your notes',
  specialNotes: 'Special notes from the page',
  noSpecialNotes: 'This page does not contain any special notes.',
  seeWhatsAvailable: 'See what’s available in {{cityAndState}} you can be ready on ordering day:',

  description: 'Description:',
  deliveryInstructions: 'Delivery Instructions:',
  pickupLocation: 'Pick-up location:',
  dropoffLocation: 'Drop-off location:',

  bringing: 'What you’re bringing:',
  providing: 'What you’re providing:',
  personalNote: 'Personal Note:',
  carType: 'What car will you be arriving in?',
  contactInformation: 'Contact Information:',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
