import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Checkbox } from '@gik/ui/Checkbox';
import { Separator } from '@gik/ui/Separator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitleAndDescription } from '../utils/SectionTitleAndDescription';
import type { IAlternatePhoneNumberValues } from './AlternatePhoneNumber/AlternatePhoneNumberForm';
import { FlowersCard } from './PrivacySettingsCards/FlowersCard';
import { PageSharingCard } from './PrivacySettingsCards/PageSharingCard';
import { PhoneCallsCard } from './PrivacySettingsCards/PhoneCallsCard';
import { TextMessagesCard } from './PrivacySettingsCards/TextMessagesCard';
import { VisitorsCard } from './PrivacySettingsCards/VisitorsCard';
import { PrivacySettingsSectionSkeleton } from './PrivacySettingsSectionSkeleton';

export interface PrivacySettingsValues {
  // switches
  phoneCallsEnabled: boolean;
  flowersEnabled: boolean;
  textMessagesEnabled: boolean;
  visitorsEnabled: boolean;
  pageSharingEnabled: boolean;
  // checkboxes
  showPageEnabled: boolean;
  featurePageEnabled: boolean;
  searchEnginesEnabled: boolean;
  // text
  alternateNumber: string;
  alternateNumberOwnerName: string;
}

export interface IPrivacySettingsSectionProps {
  values: PrivacySettingsValues;
  onChange?: (values: PrivacySettingsValues) => void;
  id?: string;
  variant?: 'page-settings' | 'create';
  isLoading?: boolean;
  errorMessage?: string;
}

export const privacySettingsSectionBlockName = 'privacy-settings-section';

const PrivacySettingsSectionComp: React.FC<PropsWithClassName<IPrivacySettingsSectionProps>> = ({
  values,
  onChange,
  className,
  id,
  variant = 'page-settings',
  isLoading,
  errorMessage,
}): JSX.Element => {
  const bem = bemBlock(privacySettingsSectionBlockName);
  const { t } = useTranslation();

  const handleToggle = (enabledKey: string, isToggled: boolean): void => {
    if (onChange) {
      onChange({
        ...values,
        [enabledKey]: isToggled,
      });
    }
  };

  const handleShowPageChange = (isToggled: boolean): void => {
    if (onChange) {
      onChange({
        ...values,
        showPageEnabled: isToggled,
        featurePageEnabled: isToggled,
        searchEnginesEnabled: isToggled,
      });
    }
  };

  const handleSubmitAlternateNumber = (alternateNumberValues: IAlternatePhoneNumberValues): void => {
    if (onChange) {
      onChange({
        ...values,
        ...alternateNumberValues,
      });
    }
  };

  const handleRemoveAlternateNumber = (): void => {
    if (onChange) {
      onChange({
        ...values,
        alternateNumber: '',
        alternateNumberOwnerName: '',
      });
    }
  };

  // TODO: use ErrorMessage component to style and center message
  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }
  if (isLoading || !values) {
    return <PrivacySettingsSectionSkeleton />;
  }

  return (
    <SectionTitleAndDescription
      id={id}
      className={bem(null, null, className)}
      title={
        variant === 'create'
          ? t(translationKeys.privacySettingsTitleVariantCreate)
          : t(translationKeys.privacySettingsTitleVariantPageSettings)
      }
      description={
        variant === 'create'
          ? t(translationKeys.privacySettingsDescriptionVariantCreate)
          : t(translationKeys.privacySettingsDescriptionVariantPageSettings)
      }
    >
      <div className={bem('cards')}>
        <PhoneCallsCard
          onChange={isToggled => handleToggle('phoneCallsEnabled', isToggled)}
          value={values.phoneCallsEnabled}
          onSubmitAlternateNumber={handleSubmitAlternateNumber}
          onRemoveAlternateNumber={handleRemoveAlternateNumber}
          initialValues={{
            alternateNumber: values.alternateNumber,
            alternateNumberOwnerName: values.alternateNumberOwnerName,
          }}
        />
        <TextMessagesCard
          onChange={isToggled => handleToggle('textMessagesEnabled', isToggled)}
          value={values.textMessagesEnabled}
        />
        <VisitorsCard
          onChange={isToggled => handleToggle('visitorsEnabled', isToggled)}
          value={values.visitorsEnabled}
        />
        <FlowersCard onChange={isToggled => handleToggle('flowersEnabled', isToggled)} value={values.flowersEnabled} />
        <PageSharingCard
          onChange={isToggled => handleToggle('pageSharingEnabled', isToggled)}
          value={values.pageSharingEnabled}
        />
      </div>
      <div className={bem('checkbox-section')}>
        <Checkbox
          checked={values.showPageEnabled}
          onValueChange={handleShowPageChange}
          className={bem('show-page-label')}
          label={t(translationKeys.showPageCheckbox)}
        />
        <p className={bem('show-page-description')}>{t(translationKeys.showPageDescription)}</p>
        <div className={bem('separator-container')}>
          <Separator className={bem('separator')} direction="horizontal" />
        </div>
        <div className={bem('checkbox-group', [{ disabled: !values.showPageEnabled }])}>
          <Checkbox
            disabled={!values.showPageEnabled}
            checked={values.featurePageEnabled}
            onValueChange={isToggled => handleToggle('featurePageEnabled', isToggled)}
            className={bem('feature-page-label')}
            label={t(translationKeys.featurePageCheckbox)}
          />
          <p className={bem('feature-page-description')}>{t(translationKeys.featurePageDescription)}</p>
          <Checkbox
            disabled={!values.showPageEnabled}
            checked={values.searchEnginesEnabled}
            onValueChange={isToggled => handleToggle('searchEnginesEnabled', isToggled)}
            className={bem('include-search-label')}
            label={t(translationKeys.includeSearchCheckbox)}
          />
          <p className={bem('include-search-description')}>{t(translationKeys.includeSearchDescription)}</p>
        </div>
      </div>
    </SectionTitleAndDescription>
  );
};

export const PrivacySettingsSection = withComponentErrorBoundary(PrivacySettingsSectionComp);
