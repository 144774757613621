import moment from 'moment';

export enum PartnerTimeline {
  WEEK_PRIOR = 'WEEK_PRIOR',
  FIVE_DAYS_PRIOR = '5_DAYS_PRIOR',
}

type UsePartnerTimelineHook = {
  canOrderNow: boolean;
  canOrderFrom: moment.Moment;
  orderTimelineText: string;
};

export const DOORDASH_TIMELINE = PartnerTimeline.FIVE_DAYS_PRIOR;
export const GRUBHUB_TIMELINE = PartnerTimeline.WEEK_PRIOR;

export function usePartnerTimeline(timeline: PartnerTimeline, eventDate: moment.Moment): UsePartnerTimelineHook {
  switch (timeline) {
    case PartnerTimeline.WEEK_PRIOR:
      return {
        canOrderNow: isPriorToEvent(eventDate, 6),
        canOrderFrom: eventDate.startOf('day').subtract(6, 'days'),
        orderTimelineText: 'a week',
      };
    case PartnerTimeline.FIVE_DAYS_PRIOR:
      return {
        canOrderNow: isPriorToEvent(eventDate, 5),
        canOrderFrom: eventDate.startOf('day').subtract(5, 'days'),
        orderTimelineText: '5 days',
      };
  }
}

const isPriorToEvent = (eventDate: moment.Moment, amount) => {
  const earliestOrderFromDate = moment.utc().startOf('day').add(6, 'days');
  return eventDate.startOf('day').isSameOrBefore(earliestOrderFromDate);
};
