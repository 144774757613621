import type { ICalendarAnnouncementType } from '@gik/calendar/models/CalendarAnnouncement';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';

const path = 'announcement-type';

export function getParams() {
  return {
    _fields: 'id,name,slug,acf.color_palette,acf.icon,acf.sort_order',
  };
}

export function getCalendarAnnouncementTypes() {
  return wpApi
    .get(path, {
      searchParams: getParams(),
    })
    .json<ICalendarAnnouncementType[]>();
}

export function useCalendarAnnouncementTypes(config?: SWRConfigInterface) {
  return useWPApi<ICalendarAnnouncementType[]>(path, getParams(), config);
}
