import pjson from '@/../package.json';
import { patchSiteSettings } from '@gik/admin/api/siteSettings';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { GIKSWRConfiguration } from '@gik/core/api/swr/useKySWR';

export async function getRemoteApplicationVersion(): Promise<string> {
  const response = await dotnetApi.get('site-settings/version');
  if (response.ok) {
    return await response.text();
  }
}

export async function setRemoteApplicationVersion(version: string) {
  return await patchSiteSettings({ frontEndVersion: version });
}

export function useRemoteApplicationVersion(config?: GIKSWRConfiguration) {
  return useApi('site-settings/version', null, { ...config, text: true });
}

export function getApplicationVersion() {
  return pjson.version;
}
