import Cookies from 'js-cookie';
import { isProduction } from './environment';

export interface ICookieOptions {
  domain?: string;
  expires?: number; // number of days the cookies should be valid
  path?: string; // number of days the cookies should be valid
}

export class GikCookies {
  domain: string;
  expires: number; // number of days the cookies should be valid
  path: string; // number of days the cookies should be valid

  constructor(expires = 7, path = '/', domain: string) {
    this.expires = expires;
    this.path = path;
    this.domain = domain;
  }

  set(name: string, value: string | object, cookieOptions: ICookieOptions = {}): string {
    return Cookies.set(name, value, {
      //domain: this.domain, // TODO: use a consistent domain with .NET in order to use cookies on all domains
      secure: isProduction, // do not require https on development
      expires: this.expires,
      path: this.path,
      ...cookieOptions,
    });
  }
  get(name?: string): string {
    return Cookies.get(name);
  }
  getJSON() {
    return Cookies.getJSON();
  }
  remove(name: string, cookieOptions: ICookieOptions = {}): void {
    return Cookies.remove(name, {
      //domain: this.domain, // TODO: use a consistent domain with .NET in order to use cookies on all domains
      path: this.path,
      ...cookieOptions,
    });
  }
}

const cookieDomain = null;
// if (isBrowser()) cookieDomain = window.location.hostname;
export const cookies = new GikCookies(7, '/', cookieDomain);
