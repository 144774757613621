import { useUser } from '@gik/api/users/user';
import type { BaseUserWithAvatar } from '@gik/core/models/gik/User';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Avatar } from '@gik/ui/Avatar';
import { CodeBlock } from '@gik/ui/CodeBlock';
import { CopyEmailButtonCircle } from '@gik/ui/CopyButton';
import { ExternalLink } from '@gik/ui/ExternalLink';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { Text } from '@gik/ui/Text/Text';
import { UI } from '@gik/ui/UIManager';
import React from 'react';

export interface IUserInfoModalContentProps {
  user?: BaseUserWithAvatar;
  userId?: string;
  detailed?: boolean;
}

const blockName = 'user-info-modal';

function UserInfoModalContentComp({ user: _user, userId, detailed }: IUserInfoModalContentProps) {
  const bem = useBemCN(blockName);

  const { data: _loadedUser } = useUser(userId);

  const user: BaseUserWithAvatar = _user ?? _loadedUser ?? null;

  function getDisplayName(user: BaseUserWithAvatar) {
    if (!user.fullName) return `${user.firstName} ${user.lastName}`.trim();
    return user.fullName.trim();
  }

  if (!user) return <LoadingSpinner center />;

  return (
    <section {...bem('content')}>
      <Avatar {...bem('avatar')} imageUrl={user.avatarThumbMediumURL} size={'2xl'} />
      <h1 {...bem('user-name')}>
        <Text>{getDisplayName(user)}</Text>
      </h1>
      {user.emailAddress && (
        <>
          <ExternalLink href={`mailto:${user.emailAddress}`} {...bem('user-email-address')}>
            {user.emailAddress}
          </ExternalLink>
          <CopyEmailButtonCircle textToCopy={user.emailAddress} {...bem('copy-button')} />
        </>
      )}
      {detailed && <div>{user.id}</div>}
    </section>
  );
}

export const UserInfoModalContent = withComponentErrorBoundary(UserInfoModalContentComp);

export function openUserInfoModal(user: BaseUserWithAvatar) {
  const bem = bemBlock(blockName);
  return UI.dialog(<UserInfoModalContent user={user} />, {
    className: bem(),
    closable: true,
    autowidth: true,
    modalProps: {
      shouldCloseOnOverlayClick: true,
    },
  });
}

export function openUserInfoModalById(userId: string, detailed: boolean) {
  const bem = bemBlock(blockName);
  return UI.dialog(<UserInfoModalContent userId={userId} detailed={detailed} />, {
    className: bem(),
    closable: true,
    autowidth: true,
    modalProps: {
      shouldCloseOnOverlayClick: true,
    },
  });
}
