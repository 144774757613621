import { timeoutDefaultValue } from '@gik/core/constants';
import { useCreateFlowStore } from '@gik/create';
import type { FormRef } from '@gik/ui/Form';
import React from 'react';

export function useFormNavigationObserver(formRef: React.MutableRefObject<FormRef>, enabled: boolean = true) {
  const { setNextDisabled } = useCreateFlowStore(state => ({
    setNextDisabled: state.setNextDisabled,
  }));

  React.useEffect(() => {
    let unsubscribe: () => void;

    if (!!formRef.current && enabled) {
      setTimeout(() => {
        unsubscribe = formRef.current?.subscribe?.('isValid', isValid => {
          if (formRef.current.formEl) {
            setNextDisabled(!isValid);
          }
        });
      }, timeoutDefaultValue);
    }

    return () => {
      unsubscribe?.();
    };
  }, [enabled, formRef, setNextDisabled]);
}
