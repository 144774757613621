import Link from '@/components/Link';
import { navigateTo } from '@/utils/navigateTo';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Form, FormGroup } from '@gik/ui/Form';
import { Input } from '@gik/ui/Input';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { SelectOptionsType } from '@gik/ui/Select';
import { Select } from '@gik/ui/Select';
// import { linkTo } from '@storybook/addon-links';
import he from 'he';
import React from 'react';
import { useTaxonomies } from '../../../api/give/taxonomies';
import { useProductsBySlug } from '../../../api/products/productsBySlug';
import routes from '../../routes';
import { useDevStore } from '../../store/DevStore';
import type { UIComponent } from '../../types/UI';
import { ProductsTable } from './ProductsTable';

export type ShopToolsProps = {} & UIComponent;

export function ShopTools({ className, ...otherProps }: React.PropsWithChildren<ShopToolsProps>): React.ReactElement {
  const bem = useBemCN('product-tools');
  const [productSlug, setSelectedProductSlug] = useDevStore(state => [state.productSlug, state.setProductSlug]);

  const [situation, setSituation] = React.useState<string>();

  const { data: situations } = useTaxonomies('situation', { page: 1, perPage: 100 });
  const { data: products } = useProductsBySlug(
    situation ? { situationSlugs: [situation], page: 1, perPage: 10 } : null
  );

  const next = useEnvStore(state => state.NEXT);

  const situationOptions: SelectOptionsType[] = React.useMemo(() => {
    if (!situation) setSituation(situations ? situations[0].slug : undefined);
    return situations?.map(situation => ({
      label: he.decode(situation.name),
      value: situation.slug,
    }));
  }, [situation, situations]);

  // const slugOptions: SelectOptionsType[] = React.useMemo(() => {
  //   return products?.map(product => ({ label: he.decode(product.name), value: product.slug }));
  // }, [products]);

  async function handleSelect(_slug: string) {
    setSelectedProductSlug(_slug);
    if (next) navigateTo(`${routes.products}/${_slug}`);
    // linkTo('Packages/Shop/Pages', '_ProductPage')();
  }

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <Form
        render={() => {
          return (
            <>
              <FormGroup>
                <Input placeholder={'Product Slug'} value={productSlug} onValueChange={setSelectedProductSlug} />
              </FormGroup>
              <FormGroup>
                <Select options={situationOptions} value={situation} onChange={setSituation} />
              </FormGroup>
              {/* <FormGroup horizontal label="Product">
                <Select block options={slugOptions} value={slug} onChange={setSlug} />
              </FormGroup> */}
            </>
          );
        }}
      />
      {!products && <LoadingSpinner />}
      <ProductsTable data={products} onSelect={handleSelect} />
      <div className="tw-p-2">
        <ul className="gik-list">
          <li>
            <Link href={`${routes.products}/this-does-not-exist`}>Link to non existing product</Link>
          </li>
          <li>
            <Link href={`${routes.give}/by/situation/birth/this-does-not-exist`}>Link to non existing taxonomy</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
