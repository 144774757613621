import lodashIsEqual from 'lodash/isEqual';
import _validator from 'validator';

export const isEmail = _validator.isEmail;
export const isURL = _validator.isURL;
export const isNumeric = _validator.isNumeric;
export const isEqual = lodashIsEqual;
export const isPhoneNumber = _validator.isMobilePhone;

/**
 * Check if a required value is not empty
 */
export function isEmpty(value: unknown): boolean {
  if (value === undefined) return true;
  if (value === null) return true;

  // if the value is a string it should not be empty
  if (isString(value)) {
    return _validator.isEmpty((value as string)?.trim?.());
  } else if (isNumber(value)) {
    return false;
  }

  return false;
}

/**
 * Check if a number is bigger or equal than the minimum allowed.
 */
export function minValue(value: number, min: number): boolean {
  return !isNaN(value) && value >= min;
}

/**
 * Check if a number is smaller or equal than the maximum allowed.
 */
export function maxValue(value: number, max: number): boolean {
  return !isNaN(value) && value <= max;
}

/**
 * Check if a number is bigger  than the minimum allowed.
 */
export function minValueExclusive(value: number, min: number): boolean {
  return !isNaN(value) && value > min;
}

/**
 * Check if a number is smaller or equal than the maximum allowed.
 */
export function maxValueExclusive(value: number, max: number): boolean {
  return !isNaN(value) && value < max;
}

/**
 * Check if a string has at least a certain amount of characters.
 */
export function minLength(value: string, min: number): boolean {
  return value && value.length < min;
}

/**
 * Check if a string has at least a certain amount of characters.
 */
export function maxLength(value: string, max: number): boolean {
  return value && value.length > max;
}

/**
 * Check if the value is a string
 */
export function isString(value: string | number | unknown): boolean {
  return typeof value === 'string';
}

/**
 * Check if the value is a number
 */
export function isNumber(value: string | number | unknown): boolean {
  return typeof value === 'number' && !isNaN(value);
}

/**
 * Use a regex to validate a string
 */
export function isValidFormat(value: string, format: string): boolean {
  const regex = new RegExp(format, 'gm');

  const str = value;
  let m;

  let foundMatch;
  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach(match => {
      foundMatch = match;
    });

    return !!foundMatch;
  }
}

export const validator = {
  isEmail,
  isURL,
  isNumeric,
  isEqual,
  isEmpty,
  minValue,
  maxValue,
  minValueExclusive,
  maxValueExclusive,
  minLength,
  maxLength,
  isString,
  isNumber,
  isValidFormat,
};
