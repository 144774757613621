import type { GiftNoteEntity } from './GiftNote';
import type { StrapiEntity } from './Strapi';

export type GiftEntity = StrapiEntity & GiftEntityAttributes;

export type GiftEntityAttributes = {
  amount: number;
  balance: number;
  uuid: string;
  status: GiftStatus;
  orderId: string;
  customerId: string;
  purchasedFrom: string;
  purchasedAs?: string;
  messageToRecipient: string;
  lineItemId: string;
  sourceProductName: string; // perfectgift or giftya
  sourceType: string; // perfectgift or giftya
  sourceProductId: string;
  sourceVariationId: string;
  wcProductId: string;
  wcVariationId: string;
  shippingFee?: number;
  pgOrderId?: string;
  pgOrderTotal?: number;
  pgRetryCount?: number;
  pgOrderFullfillmentStatus?: string;
  pgOrderStatus?: string;
  gyGiftId?: string;
  gyEGiftId?: string;
  imageUrl?: string;
  supporterId?: string;
  isAnonymous: boolean;
  thankYouSent: boolean;
  redemptionMethods: string; //GiftyaRedemtionMethod[];
  redeemedAsMethod: GiftRedemptionMethod;
  markedAsUsed: boolean;
  clientRecipientId: string;
  merchantId: string;
  gyReferenceId: string;
  calendarClaim?: CalendarClaimDetails;
  physicalCardDetails?: PhysicalCardDetails;
  metadata?: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
  billingName?: string;
  billingAddress?: BillingAddress;
  parentGifts?: GiftEntity[];
  parentUuids?: string[];
  cardId?: string;
  cardBrand?: string;
  cardMask?: string;
  cardDateLinked?: string;
  cardDateUnlinked?: string;
  cardDateUnenrolled?: string;
};

export type GiftActivityLog = GiftNoteEntity;

export type GiftHistory = {
  datetime: string;
  event: string;
  description: string;
  metadata: {
    amount: number;
    fee: number;
    merchant: string;
  };
};

export type PhysicalCardDetails = {
  nameOnCard: string;
  customMessage: string;
  carrierJson: string;
  shippingUuid: string;
  faceplateUuid?: string;
};

export type BillingAddress = {
  firstName: string;
  lastName: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  postcode: string;
};

export type CalendarClaimDetails = {
  eventType: {
    id: number;
    slug: string;
    name: string;
  };
  serviceType: string;
  claimDateTime: string;
  supporterFirstName: string;
  supporterLastName: string;
};

export enum GiftStatus {
  Created = 'created', // Gift Entity has been created in the GiveInkind gift tracker system
  Ready = 'ready', // a Giftya gift have been created for the gift -> gift is ready to be redeemed
  Merged = 'merged', // Gift Entity has been merged into a another Gift Entity
  // Pending = 'pending', // eGift is being redeemed as egift
  PendingEGift = 'pending-egift', // eGift is being redeemed as egift
  PendingLegacy = 'pending-legacy', // legacy gift is being redeemed as a legacy gift
  Unlinked = 'unlinked',
  Redeemed = 'redeemed', // Gift has been redeemed (either as EGift, Visa, Zelle etc...)
  Fulfilled = 'fulfilled', // Gift has been fulfilled by the remote service
  Used = 'used', // Gift has been used -> legacy fulfilled or linked card credits used up (egift is marked as used in a separate field)
  Refunded = 'refunded',
}

export enum GiftRedemptionMethod {
  EGIFT = 'EGIFT',
  CARD_LINK = 'CARD_LINK',
  VISA = 'VISA',
  ZELLE = 'ZELLE',
  LEGACY = 'LEGACY',
  LEGACY_VISA = 'LEGACY_VISA',
  LEGACY_VISA_PHYSICAL = 'LEGACY_VISA_PHYSICAL',
}

export type GiftyaRedemtionMethod = {
  redemption_method: string;
  configuration: Record<string, unknown>;
};

export type SendEGiftEmailPayload = {
  giftUuid: string;
  emailAddresses: string[];
};

export type GiftSendThankYouMessagePayload = {
  giftUuid: string;
  customerId: string;
  greeting: string;
  message: string;
  signature: string;
  backgroundColor?: string;
};

export type RegisterMissingCreditsPayload = {
  amount: number;
  isOnline: boolean;
  gift_id: string;
};
