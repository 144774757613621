import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-announcements';

const translations = {
  announcement: 'Announcement',
  moreButton: 'More',
  lessButton: 'Less',
  formTitleLabel: 'Title',
  formTitlePlaceholder: 'Title That Summarizes the Announcement',
  formDescriptionLabel: 'Announcement',
  formDescriptionPlaceholder: 'The full announcement goes here',
  formEmailToPageFollowersLabel: 'Email this announcement to your followers',
  deleteAnnouncementConfirmationTitle: 'Delete Announcement?',
  deleteAnnouncementConfirmation: 'Remove this announcement from your calendar?',
  editSeriesDescription:
    'Use this if you want to edit the series, \n\n changes will affect all instances of this announcement.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
