import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'features-page';

const translations = {
  // header
  title: 'Features',
  leadDescription:
    'Whether you want to arrange for home-cooked meals, start a gift card train, or just learn more about how to support someone through their big circumstances, Give InKind brings together all of the tools that you need to support anyone, from anywhere — for free.',

  // Feature Sections
  calendarTitle: 'Care Calendar',
  calendarDescription:
    'Our easy-to-use Care Calendar is fully customizable and ready to let others know how to help. Add unlimited requests for meals, grocery delivery, transportation, caregiver support and more.',

  calendarFAQ1Title: 'Your flexible (and free) meal train',
  calendarFAQ1Content: `
    <ul>
        <li>One meal a day or three? Add as many as you need or make them recurring</li>
        <li>Supporters can sign up to help in-person, or send a delivery or gift card</li>
        <li>Email reminders to those that sign up to help</li>
    </ul>`,

  calendarFAQ2Title: 'Receive meal deliveries and gift cards',
  calendarFAQ2Content: `
    <ul>
      <li>Easy ordering from DoorDash</li>
      <li>Supporters can send digital gift cards to fulfill calendar requests</li>
      <li>Great for those who need no-contact food deliveries</li>
    </ul>`,

  calendarFAQ3Title: 'Add other ways to help',
  calendarFAQ3Content: `
    <ul>
        <li>Childcare or help with pets</li>
        <li>Rides to the doctor or other appointments</li>
        <li>Help with groceries, chores, and more</li>
    </ul>`,

  wishlistTitle: 'Wishlist',
  wishlistDescription:
    'Give Supporters more ways to help by adding gift cards and other thoughtful gifts to a Give InKind Wishlist, or connect an Amazon Wishlist.',

  wishlistFAQ1Title: 'Build a Gift Card Train to let supporters know what’s helpful',
  wishlistFAQ1Content: `
    <ul>
      <li>Choose from over 200 digital gift cards</li>
      <li>Recipients are emailed gift cards so they can be put to use quickly</li>
      <li>A great way for supporters to say "I’m thinking about you"</li>
    </ul>`,

  wishlistFAQ2Title: 'Curated and practical gifts for every situation',
  wishlistFAQ2Content: `
    <ul>
        <li>Products are hand-selected by Give InKind specialists</li>
        <li>Gift guides to let you and supporters know what’s helpful</li>
        <li>A tangible way for others to send their love</li>
    </ul>`,

  wishlistFAQ3Title: 'Connect an Amazon Wishlist or Baby Registry',
  wishlistFAQ3Content: `
    <ul>
      <li>Don’t see an item on Give InKind? Add to an Amazon Wishlist</li>
      <li>Are there still items on your Amazon Baby Registry? Let people know what’s still needed</li>
      <li>Unlimited ways for Supporters to help</li>
    </ul>`,

  updatesTitle: 'Updates',
  updatesDescription:
    'Keep loved ones informed by sharing news, updates, photos and videos from one centralized place.',

  updatesFAQ1Title: 'A single source of communication',
  updatesFAQ1Content: `
    <ul>
      <li>Use as a health journal, keep supporters up-to-date, and share news</li>
      <li>Add unlimited updates with photos and videos</li>
      <li>Communicate changes or new needs with Page followers</li>
    </ul>`,

  updatesFAQ2Title: 'Choose where to share your Updates',
  updatesFAQ2Content: `
    <ul>
        <li>Post just to your page or share via email and social media</li>
        <li>InKind Page Organizers can edit or remove updates if needed</li>
    </ul>`,

  fundraisingTitle: 'Donations',
  fundraisingDescription:
    'Connect a GoFundMe and/or PayPal to quickly receive donations and help ease financial burden with no additional fees.',

  fundraisingFAQ1Title: 'Add your GoFundMe fundraiser',
  fundraisingFAQ1Content: `
    <ul>
      <li>Sometimes money is what’s needed most - and that’s ok, too</li>
      <li>Display fundraiser progress right on your InKind Page</li>
      <li>No additional Give InKind fees</li>
    </ul>`,

  fundraisingFAQ2Title: 'Connect your PayPal Account',
  fundraisingFAQ2Content: `
    <ul>
        <li>Page recipient receives donations immediately</li>
        <li>Donors do not need a PayPal Account to send funds</li>
        <li>No additional Give InKind fees</li>
    </ul>`,

  fundraisingFAQ3Title: 'Donate buttons are displayed on your InKind Page',
  fundraisingFAQ3Content: `
    <ul>
        <li>Add both GoFundMe and PayPal buttons to give Supporters options</li>
        <li>Donate buttons are displayed on your InKind Page</li>
        <li>Turn on/off donations as needed</li>
    </ul>`,

  moreTitle: '...and more',
  moreDescription:
    'InKind Pages have even more ways for people to be able to show their support and better understand their role in the journey.',

  moreFAQ1Title: 'Let others know about the recipient’s communication preferences',
  moreFAQ1Content: `
    <ul>
        <li>Share whether phone calls and/or text are welcomed</li>
        <li>Let supporters know whether or not they should visit</li>
        <li>People have mixed feelings about flowers. Easily let others know what’s preferred</li>

    </ul>`,

  moreFAQ2Title:
    'Give InKind experts provide supportive, actionable guides, tips and resources to help navigate complicated times.',
  moreFAQ2Content: `
    <ul>
        <li>Our gift guides and articles provide ideas and tips for helping</li>
        <li>Find guidance on what (or what not) to say during difficult situations</li>
        <li>Read relatable stories from people who have been there too</li>
    </ul>`,

  moreFAQ3Title:
    'Give InKind’s Curated Gift Boxes include hand-picked items for supporters to send their love. No two boxes are the same.',
  moreFAQ3Content: `
    <ul>
        <li>A rotating selection of hand-picked items, guaranteed to bring a smile</li>
        <li>An assortment of sweets, sips, spa, stationery and self-care, and includes a personal message from the Supporter</li>
        <li>Send with or without a gift card</li>
    </ul>`,
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
