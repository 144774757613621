import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'related-articles-section';

const translations = {
  title: 'RELATED ARTICLES',
  seeAllArticles: 'See all articles',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
