import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';

const blockName = `${gikClassPrefix}-frame-bone`;

export interface FrameBoneProps extends React.PropsWithChildren<BaseBoneProps> {}

export function FrameBone({ className, children }: FrameBoneProps) {
  const blockClasses = classnames([blockName, className || '']);

  return (
    <div className={blockClasses}>
      <div>{children}</div>
    </div>
  );
}
