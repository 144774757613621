/**
 * This disables the nextjs error overlay that is normally displayed on development and not on production.
 * Disabling the overlay results in a more similar experience between devevlopment and production when errors occur.
 *
 * please see: https://github.com/vercel/next.js/discussions/13387#discussioncomment-101564
 */
export const noOverlayWorkaroundScript = `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`;
