import type { Hit } from '@algolia/client-search';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { InkindAlgoliaObject } from '@gik/search/components';
import React from 'react';

type InkindPageRecipientInfoComplete = Partial<InkindPageAPIModel> &
  Required<Pick<InkindPageAPIModel, 'recipientFullName' | 'address' | 'zip' | 'city' | 'stateCode'>>;
type AlgoliaInkindHitInfoComplete = Partial<Hit<InkindAlgoliaObject>> &
  Required<
    Pick<Hit<InkindAlgoliaObject>, 'recipientFullName' | 'recipientZipCode' | 'recipientCity' | 'recipientState'>
  >;
export default function isInkindPageRecipientInfoComplete(
  inkindPageOrAlgoliaHit: InkindPageRecipientInfoComplete | AlgoliaInkindHitInfoComplete
): boolean {
  const inkindPage = inkindPageOrAlgoliaHit as InkindPageRecipientInfoComplete;
  const algoliaHit = inkindPageOrAlgoliaHit as AlgoliaInkindHitInfoComplete;

  return _isInkindPageRecipientInfoComplete(inkindPage) || isAlgoliaInkindHitInfoComplete(algoliaHit);
}

function _isInkindPageRecipientInfoComplete(inkindPage: InkindPageRecipientInfoComplete): boolean {
  return Boolean(
    inkindPage.recipientFullName && inkindPage.address && inkindPage.zip && inkindPage.city && !!inkindPage.stateCode
  );
}

export function isAlgoliaInkindHitInfoComplete(inkindPage: AlgoliaInkindHitInfoComplete) {
  return Boolean(
    inkindPage.recipientFullName && inkindPage.recipientZipCode && inkindPage.recipientCity && inkindPage.recipientState
  );
}

export function useInkindPageRecipientInfoComplete(inkindPage: InkindPageRecipientInfoComplete): boolean {
  return React.useMemo(() => inkindPage && isInkindPageRecipientInfoComplete(inkindPage), [inkindPage]);
}

export function isInkindPageRecipientInfoPartiallyComplete(inkindPage: Partial<InkindPageAPIModel>): boolean {
  return Boolean(
    inkindPage.recipientFullName || inkindPage.address || inkindPage.zip || inkindPage.city || !!inkindPage.stateCode
  );
}
