import { logger } from '@gik/analytics/utils/logger';
import contentDisposition from 'content-disposition';

// TODO implement paging in the future
export const apiCap = 100;

export async function downloadResponse(response: Response, filename?: string) {
  const dispositionContent = response.headers.get('Content-Disposition');

  const disposition = dispositionContent ? contentDisposition.parse(dispositionContent) : null;

  if (!disposition) {
    logger.warn('Content-Disposition header not found in response header', response);
  }

  if (!response.ok) {
    throw response;
  } else {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = disposition ? disposition.parameters.filename : filename || 'supporters.csv';
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  }
}
