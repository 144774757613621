import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitleAndDescription } from '../utils/SectionTitleAndDescription';
import { RecipientInfoFieldSet } from './RecipientInfoFieldSet';

export interface IRecipientInfoSectionProps {
  id?: string;
}

export const recipientInfoSectionBlockName = 'recipient-info-section';

const RecipientInfoSectionComp: React.FC<PropsWithClassName<IRecipientInfoSectionProps>> = ({
  className,
  id,
}): JSX.Element => {
  const bem = bemBlock(recipientInfoSectionBlockName);
  const { t } = useTranslation();

  return (
    <SectionTitleAndDescription
      id={id}
      className={bem(null, null, className)}
      title={t(translationKeys.recipientInfoTitle)}
      description={t(translationKeys.recipientInfoDescription)}
      hasBlueBackground
    >
      <RecipientInfoFieldSet className={bem('fieldset')} />
    </SectionTitleAndDescription>
  );
};

export const RecipientInfoSection = withComponentErrorBoundary(RecipientInfoSectionComp);
