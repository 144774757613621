import type { UseMutationOptions } from '@tanstack/react-query';
import { usePlatformManagementClient } from '../../../contexts';
import type {
  PlatformManagementApiResponse,
  RegisterMissingCreditsPayload,
  StrapiSingleDataSource,
} from '@gik/platform-management-api-js';
import type { GiftEntity } from '@gik/platform-management-api-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { buildOptions } from '../../../utils/buildOptions';

type CreatePayload = GiftEntity;
type UpdatePayload = Partial<GiftEntity>;

const uniqueName = 'gift';

export const useAdminGiftCreate = (
  options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, CreatePayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_create`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, CreatePayload>(
    (payload: CreatePayload) => client.admin.gift.create(payload),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminGiftUpdate = (
  options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, UpdatePayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_update`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, UpdatePayload>(
    (payload: UpdatePayload) => client.admin.gift.update(payload.id.toString(), payload),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminGiftDelete = (
  options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_delete`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>, Error, string>(
    (id: string) => client.admin.gift.delete(id),
    buildOptions(queryClient, [key], options)
  );
};

export const useAdminGiftRegisterMissingCredits = (
  params?: object,
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>,
    Error,
    RegisterMissingCreditsPayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_delete`;

  return useMutation<
    PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>,
    Error,
    RegisterMissingCreditsPayload
  >(
    (payload: RegisterMissingCreditsPayload) => {
      const { gift_id, ...rest } = payload;
      return client.admin.gift.registerMissingCredits(gift_id, rest, { params });
    },
    buildOptions(queryClient, [key], options)
  );
};
