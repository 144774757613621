import type { QueryParams, SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type WordpressLegal from '@gik/core/models/wordpress/WordpressLegal';

const path = 'pages';
const privacySlug = 'privacy-policy';
const termsSlug = 'terms-of-use';

function getParams(slug: string): QueryParams {
  return {
    slug,
  };
}

// general
function getLegal(slug: string) {
  return wpApi
    .get(path, {
      searchParams: getParams(slug),
    })
    .json<WordpressLegal[]>();
}

function useLegal(shouldRun: boolean, slug: string, config?: SWRConfigInterface) {
  return useWPApi<WordpressLegal[]>(shouldRun ? path : null, getParams(slug), config);
}

// privacy
export function getPrivacyPolicy() {
  return getLegal(privacySlug);
}

export function usePrivacyPolicy(shouldRun = true, config?: SWRConfigInterface) {
  return useLegal(shouldRun, privacySlug, config);
}

// terms
export function getTerms() {
  return getLegal(termsSlug);
}

export function useTerms(shouldRun = true, config?: SWRConfigInterface) {
  return useLegal(shouldRun, termsSlug, config);
}
