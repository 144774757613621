import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { Avatar, PageAvatar } from '@gik/ui/Avatar';
import { Button } from '@gik/ui/Button';
import { Container } from '@gik/ui/Container';
import { PageSection } from '@gik/ui/gik/PageSection';
import { useNavBarStore } from '@gik/ui/NavBar/NavBarStore';
import { Separator } from '@gik/ui/Separator';
import { FlowersOkOutline } from '@gik/ui/SvgIcon/GikIcons/FlowersOk';
import { PageSharingDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/PageSharingDisabled';
import { PhoneCallsDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/PhoneCallsDisabled';
import { TextMessagesDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/TextMessagesDisabled';
import { VisitorsOkOutline } from '@gik/ui/SvgIcon/GikIcons/VisitorsOk';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import moment from 'moment';
import React from 'react';

export interface IInKindPageBackgroundProps {
  blurred?: boolean;
}

export default function InKindPageBackground({ blurred = true }: IInKindPageBackgroundProps) {
  const bem = bemBlock('inkind-page-background');

  const pageName = 'Your InKind Page';
  const pageLocation = 'EVERYTOWN, USA';
  const pageOrganizer = 'ORGANIZER';

  const isMd = useBreakpoint(Breakpoint.MD);

  const setNavBarBlurred = useNavBarStore(state => state.setBlurred);

  React.useEffect(() => {
    // hide the body scrollbar
    document.body.style.overflow = 'hidden';
    setNavBarBlurred(true);
    return () => {
      document.body.style.overflow = 'unset';
      setNavBarBlurred(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={bem(null, [{ blurred }])}>
      <PageSection className={bem('header')} variant={'aqua'} noPadBottom noPadTop>
        <Container className={bem('header-inner')}>
          <PageAvatar className={bem('profile-picture')} size="3xl" rounded circle={false} />
          <section className={bem('info-container')}>
            <section className={bem('location-wrapper')}>
              <div className={bem('page-title')}>{pageName}</div>
              <div className={bem('page-location')}>{pageLocation}</div>
            </section>
            <section className={bem('organizers-wrapper')}>
              <div className={bem('page-organizers')}>
                <div className={bem('organizer')}>{pageOrganizer}</div>
                <Avatar size={'sm'} />
              </div>
            </section>
          </section>
          <section className={bem('controls')}>
            <div />
            <div className={bem('buttons')}>
              <Button size={isMd ? 'sm' : 'base'} className={bem('request-button')} variant={'white'} circle>
                <SvgIcon Icon={PhoneCallsDisabledOutline} />
              </Button>
              <Button size={isMd ? 'sm' : 'base'} className={bem('request-button')} variant={'white'} circle>
                <SvgIcon Icon={TextMessagesDisabledOutline} />
              </Button>
              <Button size={isMd ? 'sm' : 'base'} className={bem('request-button')} variant={'white'} circle>
                <SvgIcon Icon={FlowersOkOutline} />
              </Button>
              <Button size={isMd ? 'sm' : 'base'} className={bem('request-button')} variant={'white'} circle>
                <SvgIcon Icon={VisitorsOkOutline} />
              </Button>
              <Button size={isMd ? 'sm' : 'base'} className={bem('request-button')} variant={'white'} circle>
                <SvgIcon Icon={PageSharingDisabledOutline} />
              </Button>
            </div>
          </section>
        </Container>
      </PageSection>

      <Container className={bem('content')}>
        <section className={bem('updates')}>
          <div className={bem('section-title')}>UPDATES</div>
          <div className={bem('update-cards')}>
            <div className={bem('update-card')} />
            <div className={bem('update-card')} />
          </div>
          <div className={bem('section-title')}>STORY</div>
          <div className={bem('story-block')} />
          <Separator type="dashed" thickness="double" />
          <div className={bem('section-title', [{ 'special-notes': true }])}>SPECIAL NOTES</div>
          <div className={bem('special-notes-block')} />
          <div className={bem('section-title-2')}>
            CARE CALENDAR
            <Button className={bem('print-month')}>Print month</Button>
          </div>
          <div className={bem('care-calendar')}>
            <div className={bem('care-calendar-header')}>
              <div className={bem('calendar-header-nav')}>
                <SvgIcon Icon={ChevronLeftIcon} /> {moment().subtract(1, 'months').format('MMM')}
              </div>
              <div className={'tw-flex-1'}>{moment().format('MMMM YYYY')}</div>
              <div className={bem('calendar-header-nav')}>
                {moment().add(1, 'months').format('MMM')} <SvgIcon Icon={ChevronRightIcon} />
              </div>
            </div>
            <div className={bem('care-calendar-body')} />
          </div>
        </section>

        <section className={bem('right-sidebar')}>
          <Button variant={'secondary'}>Share</Button>

          <div className={bem('section-title-2')}>SUPPORT THROUGH GIVE INKIND</div>

          <Button>From wishlist</Button>
          <Button>From calendar</Button>
          <Button variant={'danger'}>Send a gift box</Button>

          <div className={bem('section-title-3')}>WISHLIST</div>

          <div className={bem('wishlist-card')} />
          <div className={bem('wishlist-card')} />
          <div className={bem('wishlist-card')} />
          <Button>See all products</Button>

          <div className={bem('section-title-3')}>RELATED ARTICLES</div>

          <div className={bem('article-card')} />
          <div className={bem('article-card')} />
          <Button>See all articles</Button>
        </section>
      </Container>
    </div>
  );
}
