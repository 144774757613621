import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const Fundraising = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 15.9083V19.5706L8.15261 19.8542C8.54356 19.9504 8.9447 19.999 9.34731 19.999H13.6131C14.7621 19.999 15.9002 19.7501 16.9541 19.2763C17.6536 18.9619 18.4548 18.6052 19.0938 18.3311H10.6094C10.0572 18.3311 9.60944 17.8834 9.60944 17.3311C9.60944 16.7788 10.0572 16.3311 10.6094 16.3311H11.9988C12.6362 16.3189 12.9074 16.0241 12.9873 15.8422C13.0286 15.7483 13.0438 15.6405 13.0017 15.5292C12.5222 15.5412 12.0281 15.4497 11.6166 15.3504C9.91521 14.9397 8.2327 15.0663 7 15.9083ZM14.9325 16.3311C15.2033 15.3868 14.8907 14.3448 13.9877 13.6697C13.7321 13.4786 13.4005 13.421 13.0954 13.5147C13.0026 13.5432 12.6813 13.55 12.0859 13.4063C9.87563 12.8727 7.27255 13.0008 5.34719 14.66L6 15.4176H5V20.3543C5 20.8146 5.31414 21.2154 5.76106 21.3254L7.67473 21.7963C8.22206 21.9309 8.78365 21.999 9.34731 21.999H13.6131C15.0513 21.999 16.4684 21.6874 17.7741 21.1005C18.8938 20.5971 20.1727 20.0317 20.6836 19.8381C21.1184 19.6733 21.4995 19.3841 21.7234 18.9618C21.9489 18.5365 21.966 18.0646 21.825 17.6496C21.544 16.8225 20.7145 16.3312 19.8053 16.3311H14.9325ZM19.9732 18.3662C19.9732 18.3662 19.9701 18.3648 19.9657 18.3599C19.9713 18.3634 19.9732 18.3662 19.9732 18.3662Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 16.5C2 15.1193 3.11929 14 4.5 14H6C6.55228 14 7 14.4477 7 15V21C7 21.5523 6.55228 22 6 22H4.5C3.11929 22 2 20.8807 2 19.5V16.5ZM4.5 16C4.22386 16 4 16.2239 4 16.5V19.5C4 19.7761 4.22386 20 4.5 20H5V16H4.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4578 2.17542C17.01 2.17542 17.4578 2.62313 17.4578 3.17542V3.26778C18.398 3.44453 19.2493 3.8716 19.812 4.51996C20.174 4.93706 20.1293 5.56865 19.7122 5.93065C19.2951 6.29265 18.6635 6.24798 18.3015 5.83087C18.1389 5.64349 17.8489 5.45309 17.4578 5.32615V7.26737C18.0793 7.38405 18.6543 7.60932 19.1338 7.92896C19.8556 8.41012 20.4578 9.18464 20.4578 10.1754C20.4578 11.1662 19.8556 11.9407 19.1338 12.4219C18.6543 12.7415 18.0793 12.9668 17.4578 13.0835V13.1754C17.4578 13.7277 17.01 14.1754 16.4578 14.1754C15.9055 14.1754 15.4578 13.7277 15.4578 13.1754V13.0831C14.5175 12.9063 13.6662 12.4792 13.1035 11.8309C12.7415 11.4138 12.7862 10.7822 13.2033 10.4202C13.6204 10.0582 14.252 10.1029 14.614 10.52C14.7766 10.7073 15.0667 10.8977 15.4578 11.0247V9.08346C14.8362 8.96678 14.2613 8.74151 13.7817 8.42187C13.0599 7.94071 12.4578 7.16619 12.4578 6.17542C12.4578 5.18464 13.0599 4.41012 13.7817 3.92896C14.2613 3.60932 14.8362 3.38405 15.4578 3.26737V3.17542C15.4578 2.62313 15.9055 2.17542 16.4578 2.17542ZM15.4578 5.32633C15.2371 5.39771 15.0458 5.48993 14.891 5.59312C14.5271 5.83571 14.4578 6.06119 14.4578 6.17542C14.4578 6.28964 14.5271 6.51512 14.891 6.75771C15.0458 6.8609 15.2371 6.95312 15.4578 7.0245V5.32633ZM17.4578 9.32633V11.0245C17.6784 10.9531 17.8697 10.8609 18.0245 10.7577C18.3884 10.5151 18.4578 10.2896 18.4578 10.1754C18.4578 10.0612 18.3884 9.83571 18.0245 9.59312C17.8697 9.48993 17.6784 9.39771 17.4578 9.32633Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
