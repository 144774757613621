import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import type { ScrollNavItemsArray } from '@gik/ui/ScrollNavBar';
import { ScrollNavBar } from '@gik/ui/ScrollNavBar';
import { Features } from '@gik/ui/SvgIcon/GikIcons/Features';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import EyeOffIcon from '@heroicons/react/solid/EyeOffIcon';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import UserIcon from '@heroicons/react/solid/UserIcon';
import UsersIcon from '@heroicons/react/solid/UsersIcon';
import React from 'react';

export const pageSettingsScrollNavBarBlockName = 'page-settings-scroll-navbar';

export const pageSettingsNavItemIds = {
  title: 'title-section-id',
  features: 'features-section-id',
  privacy: 'privacy-section-id',
  recipient: 'recipient-section-id',
  organizers: 'organizers-section-id',
} as const;

export type PageSettingsNavItemIdsType = (typeof pageSettingsNavItemIds)[keyof typeof pageSettingsNavItemIds];

export interface IPageSettingsScrollNavBarProps {
  containerId?: string;
}

const PageSettingsScrollNavBarComp: React.FC<IPageSettingsScrollNavBarProps> = ({
  containerId,
}): React.ReactElement => {
  const bem = bemBlock(pageSettingsScrollNavBarBlockName);
  const pageSettingsNavItems: ScrollNavItemsArray = [
    {
      variant: 'success',
      to: pageSettingsNavItemIds.title,
      icon: <SvgIcon Icon={InformationCircleIcon} />,
      label: i18n.t(translationKeys.scrollNavTitle),
    },
    {
      variant: 'primary',
      to: pageSettingsNavItemIds.features,
      icon: <SvgIcon Icon={Features} />,
      label: i18n.t(translationKeys.scrollNavFeatures),
    },
    {
      variant: 'danger',
      to: pageSettingsNavItemIds.privacy,
      icon: <SvgIcon Icon={EyeOffIcon} />,
      label: i18n.t(translationKeys.scrollNavPrivacy),
    },
    {
      variant: 'purple',
      to: pageSettingsNavItemIds.recipient,
      icon: <SvgIcon Icon={UserIcon} />,
      label: i18n.t(translationKeys.scrollNavRecipient),
    },
    {
      variant: 'secondary',
      to: pageSettingsNavItemIds.organizers,
      icon: <SvgIcon Icon={UsersIcon} />,
      label: i18n.t(translationKeys.scrollNavOrganizers),
    },
  ];

  return <ScrollNavBar containerId={containerId} navItems={pageSettingsNavItems} className={bem()} />;
};

export const PageSettingsScrollNavBar = withComponentErrorBoundary(PageSettingsScrollNavBarComp);
