import { stripeTokenLocalStorage } from '@/constants';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUserPages } from '@gik/api/inkinds/userPages';
import { useUser } from '@gik/api/users/user';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { getRecipientContactInformation } from '@gik/calendar/utils/getRecipientContactInformation';
import type { ClaimConflictErrorDetails, ClaimConflictResolve, OrderStatus } from '@gik/checkout/api';
import { ClaimConflictError, placeOrder } from '@gik/checkout/api';
import type {
  CheckoutFormPayload,
  CheckoutFormStepId,
  CheckoutFormValues,
} from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { CheckoutForm } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import type { ICheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import { CheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import { errorStringsToCheckFor } from '@gik/checkout/components/CheckoutForm/CheckoutFormWrapper';
import { translationKeys as checkoutTranslationKeys } from '@gik/checkout/i18n/en';
import type { ClaimStatus, PaymentConfirmationValues } from '@gik/checkout/types';
import { createCartItemFromProduct } from '@gik/checkout/utils/productUtils';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import { openPrivacyPolicySheet } from '@gik/core/pages/Legal/PrivacyPolicy';
import { openTermsOfServiceSheet } from '@gik/core/pages/Legal/TermsOfService';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { storage } from '@gik/core/utils/Storage';
import i18n from '@gik/i18n';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { isUserFollowingPage } from '@gik/inkind-page/utils/inkindPagePermissions';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { withStripe } from '@gik/ui/Stripe/withStripe';
import { UI } from '@gik/ui/UIManager';
import { useStripe } from '@stripe/react-stripe-js';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import React from 'react';
import { afterClaimSucceeded } from '../EventForm/utils';

export interface ICalendarClaimCheckoutFormProps extends React.HTMLAttributes<HTMLDivElement> {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  serviceSlug?: string;
  serviceCategorySlug: string;
  calendarEventTypes: IWordpressCalendarEventType[];
  stepsNavPortal?: () => HTMLElement;
  buttonsPortal?: () => HTMLElement;

  onGotoStep?(): void;

  setClosable?(closable: boolean): void;
  onFailed?(): void;
  onConflict?(claimConflicts: ClaimStatus[]): void;

  setOnDialogBack?(fn: () => void): void;
  setDialogFooterClass?(className: string): void;
  onBack?(): void;
  onSuccess?(): void;
  onStepChange?: (step: CheckoutFormStepId) => void;
  onStepProgression?: (step: CheckoutFormStepId, index: number) => void;
}

function CalendarClaimCheckoutForm({
  event,
  entry,
  className,
  serviceSlug,
  serviceCategorySlug,
  calendarEventTypes,
  onGotoStep,
  setOnDialogBack,
  setDialogFooterClass,
  stepsNavPortal,
  buttonsPortal,
  setClosable,
  onFailed = noop,
  onConflict = noop,
  onSuccess = noop,
  onBack = noop,
  onStepChange,
  onStepProgression,
}: ICalendarClaimCheckoutFormProps): React.ReactElement {
  const bem = bemBlock('calendar-claim-checkout-form');

  const stripe = useStripe();
  const userId = useUserStore(state => state.id);
  const { data: user, mutate: mutateUser } = useUser(userId);

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const {
    setPaymentConfirmationValues,
    ignoreClaimConflict,
    setClaimConflicts,
    setGoneConflicts,
    setResolveGiftCardClaimConflictValues,
    setDialogTitle,
    product,
    privateClaim,
  } = useCalendarStore(state => ({
    setPaymentConfirmationValues: state.setPaymentConfirmationValues,
    ignoreClaimConflict: state.ignoreClaimConflict,
    setClaimConflicts: state.setClaimConflicts,
    setGoneConflicts: state.setGoneConflicts,
    setResolveGiftCardClaimConflictValues: state.setResolveGiftCardClaimConflictValues,
    setDialogTitle: state.setDialogTitle,
    product: state.selectedGiftcard,
    privateClaim: state.privateClaim,
  }));

  const [claimErrors, setClaimErrors] = React.useState<ClaimConflictErrorDetails[]>();

  const { mainCart, setMainCart } = React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);

  const { data: userPages, mutate: mutateUserPages } = useUserPages(userId);
  const isFollowing = isUserFollowingPage(inkind, userId, userPages);

  const eventType = calendarEventTypes.find(item => item.id === entry.typeId);

  if (!product) {
    throw new Error(`calendarStore does not have a selectedGiftcard`);
  }

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [initialData, setInitialData] = React.useState<CheckoutFormValues>();

  const getInitialCheckoutDataCB = React.useCallback(
    function getInitialCheckoutData() {
      // shipping
      const {
        email: recipientEmail,
        fullName: recipientFullName,
        address1: recipientAddress1,
        address2: recipientAddress2,
        cityAndState: recipientCityAndState,
        postcode: recipientPostcode,
      } = getRecipientContactInformation(inkind);

      const cart: CartItem[] = [createCartItemFromProduct(product, inkind)];
      const billingAddress = user?.billingAddresses?.[0];

      return {
        billing: {
          country: 'US',
          firstName: billingAddress?.firstName || user?.firstName,
          lastName: billingAddress?.lastName || user?.lastName,
          state: billingAddress?.state || user?.state,
          email: billingAddress?.email || user?.emailAddress,
          phone: billingAddress?.phone,
          city: billingAddress?.city,
          companyName: billingAddress?.companyName,
          postalCode: billingAddress?.postalCode,
          address1: billingAddress?.address1,
          address2: billingAddress?.address2,
          stripeToken: stripeTokenLocalStorage ? storage.getWithExpiry('stripe-token') : undefined,
          saveAddress: true,
        },
        shipping: {
          country: 'US',
          email: recipientEmail,
          firstName: recipientFullName,
          address1: recipientAddress1,
          address2: recipientAddress2,
          city: recipientCityAndState?.split(',')[0]?.trim(),
          state: recipientCityAndState?.split(',')[1]?.trim(),
          postalCode: recipientPostcode,
        },
        summary: { agree: false, privateClaim },
        cart: cart,
      } as CheckoutFormValues;
    },
    [
      inkind,
      privateClaim,
      product,
      user?.billingAddresses,
      user?.emailAddress,
      user?.firstName,
      user?.lastName,
      user?.state,
    ]
  );

  function handleSuccess() {
    const claim = {
      serviceSlug,
      serviceCategorySlug,
    };
    afterClaimSucceeded(entry, claim, eventType);

    if (!isFollowing) {
      // revildate user pages to update the isFollowing state.
      mutateUserPages();
    }

    if (setDialogTitle) {
      setDialogTitle('Confirmation');
    }
    onSuccess();
  }

  async function handleSubmit(values: CheckoutFormPayload, updateCheckoutStatusText: (status: OrderStatus) => void) {
    setErrorMessage(undefined);

    // inject event-specific information (purchasing for an event)
    values.eventType = entry.typeName;
    values.eventDateTime = moment.utc(event.startsAt).toISOString();

    const createClaimRequests = values.products
      .filter(product => !!product.createClaimRequest)
      .map(product => {
        return {
          ...product.createClaimRequest,
          serviceSlug: product.createClaimRequest.serviceSlug ?? serviceSlug,
          privateClaim: product.createClaimRequest?.privateClaim ?? (privateClaim || values.products?.[0]?.anonymous),
        };
      });

    values.products.forEach((product, index) => {
      if (product.createClaimRequest) {
        product.createClaimRequest = {
          ...product.createClaimRequest,
          ...createClaimRequests[index],
        };
      }
    });

    // FIXME: remove duplication between here and CheckoutFormWrapper
    let response: PaymentConfirmationValues;
    try {
      response = await placeOrder(stripe, values, ignoreClaimConflict, updateCheckoutStatusText);
    } catch (err: unknown) {
      if (err instanceof ClaimConflictError) {
        Analytics.fireEvent(AnalyticsEvents.ClaimConflictsOpen, {
          userId,
          inkindRouteId,
          data: err.data,
        });

        setClaimErrors(err.data);
      }

      if (err instanceof Error) {
        if (err.message === 'conflicts') {
          return err;
        } else if (err.message === StatusCodes.GONE.toString()) {
          UI.error('Sorry, it looks like this request no longer exists.');
          setErrorMessage(i18n.t(checkoutTranslationKeys.genericBackendError));
          onFailed();
        }

        logger.error(err);
        let errorMessage: string = err.message || i18n.t(checkoutTranslationKeys.genericBackendError);

        if (errorMessage.toLowerCase().startsWith('invalid account.')) {
          errorMessage =
            'This card number appears to be invalid. Please double-check your entry and try again. If you continue to receive this error, contact your card issuer.';
        }

        const shouldUseAnalytics = !errorStringsToCheckFor.some(error =>
          errorMessage.toLowerCase().includes(error.toLowerCase())
        );

        if (shouldUseAnalytics) {
          Analytics.fireUserNotice(errorMessage, 'errorCheckout');
        }

        setErrorMessage(errorMessage);
        setClaimConflicts(null);
        setGoneConflicts(null);
        setClaimErrors(null);
      }
      return;
    }

    storage.remove('stripe-token');
    storage.remove('stripe-token-last-4-digits');

    Analytics.fireEvent(AnalyticsEvents.CheckoutCompletePurchase, {
      productNames: values.products?.map(cartItem => cartItem.name).join(','),
      productIds: values.products?.map(cartItem => cartItem.productId).join(','),
      productCheckoutTypes: values.products?.map(cartItem => cartItem.checkoutType).join(','),
      inkindRouteId,
      userId: user.id, // id of the purchaser
      initiatedOn: 'calendar',
      total: response.total.toString(),
      tip: response.tip.toString(),
      orderID: response.orderId.toString(),
    });

    const numberOfPhysical = values.products.filter(p => p.productType === 'physical').length;

    // FIXME: logic repeated in CheckoutFormWrapper, refactor
    setPaymentConfirmationValues({
      ...response,
      products: values.products,
      ...(values.products.some(p => p.checkoutType === 'perfectgift' && p.productType === 'physical')
        ? {
            shippingCost: parseFloat(values.shipping.shippingValue) * numberOfPhysical,
            shipping: values.shipping,
            pgCarrier: values.products.find(p => p.carrier?.carrierType != undefined).carrier.carrierType,
          }
        : {}),
      ...(values?.products[0]?.checkoutType === 'perfectgift' && values?.products[0]?.productType === 'physical'
        ? {
            shippingCost: parseFloat(values.shipping.shippingValue) * numberOfPhysical,
            shipping: values.shipping,
            pgCarrier: values.products.find(p => p.carrier?.carrierType != undefined).carrier.carrierType,
          }
        : {}),
    });

    const claimConflicts = response.claims?.filter(claim => claim.status === 'Conflict');
    const goneConflicts = response.claims?.filter(claim => claim.status === 'Gone');

    if ((!claimConflicts || claimConflicts.length === 0) && (!goneConflicts || goneConflicts.length === 0)) {
      return void handleSuccess();
    }

    if (claimConflicts?.length > 0 && !ignoreClaimConflict) {
      setResolveGiftCardClaimConflictValues({
        checkoutFormPayload: values,
        paymentConfirmation: response,
      });

      setClaimConflicts(claimConflicts);
      onConflict(claimConflicts);
    }

    if (goneConflicts?.length > 0) {
      setGoneConflicts(goneConflicts);
      onFailed(); // refresh calendar
    }
  }

  React.useEffect(() => {
    if (user && !initialData) {
      const _initialData = getInitialCheckoutDataCB();
      setInitialData(_initialData);
      // also override the mainCart so the cartitems will get updated in the checkoutflow

      // replace the first item in the mainCart
      if (mainCart?.length > 0) {
        const newCart = mainCart.concat([]);
        newCart[0] = _initialData.cart[0];
        setMainCart(newCart);
      }
    }
  }, [user, getInitialCheckoutDataCB, setMainCart, mainCart, initialData]);

  React.useEffect(() => {
    setOnDialogBack?.(() => () => {
      onBack();
    });
    // setOnDialogBack?.(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOnDialogBack]);

  const handleUpdateClaimConflicts = React.useCallback((suggestions: ClaimConflictResolve[]) => {
    // const newCart = mainCart.concat([]);
    // // accept the new available dates from the claimConflicts
    // suggestions.forEach(suggestion => {
    //   const cartItemIndex = newCart.findIndex(item => item.id === suggestion.id);
    //   if (cartItemIndex > -1) {
    //     const cartItem = newCart[cartItemIndex];
    //     if (cartItem.createClaimRequest) {
    //       cartItem.createClaimRequest.claimDate = suggestion.date.instanceDate;
    //       newCart[cartItemIndex] = cartItem;
    //     } else {
    //       // remove the claim associated with the cartitem so it becomes a normal giftcard purchase
    //       cartItem.createClaimRequest = null;
    //     }
    //     newCart[cartItemIndex] = cartItem;
    //   }
    // });
    // setMainCart(newCart);
    // setClaimErrors(null);
    setErrorMessage(null);
  }, []);

  // checkout form configuration
  const checkoutFormConfig = {
    shipping: false,
    hideProducts: false,
    skipProducts: false,
  };

  // wait until we have initial data
  if (!initialData) {
    return <LoadingSpinner center />;
  }

  return (
    <div className={bem(null, null, className)}>
      <CheckoutForm
        privateClaim={privateClaim}
        mainClaimEvent={event}
        onSubmit={handleSubmit}
        onGotoStep={onGotoStep}
        setClosable={setClosable}
        setOnDialogBack={setOnDialogBack}
        onUpdateClaimConflicts={handleUpdateClaimConflicts}
        setDialogFooterClass={setDialogFooterClass}
        initialData={initialData}
        {...checkoutFormConfig}
        errorMessage={errorMessage}
        stepsNavPortal={stepsNavPortal?.()}
        buttonsPortal={buttonsPortal?.()}
        onBillingInfoChanged={mutateUser}
        onOpenTermsOfService={openTermsOfServiceSheet}
        onOpenPrivacyPolicy={openPrivacyPolicySheet}
        initiatedOn="calendar"
        inkindRouteId={inkindRouteId}
        onStepChange={onStepChange}
        onStepProgression={onStepProgression}
        claimErrors={claimErrors}
      />
    </div>
  );
}

export default withStripe(CalendarClaimCheckoutForm);
