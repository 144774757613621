import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import React from 'react';

export interface TabDefinition {
  title: string | React.ReactNode;
  content: React.ReactNode;
}

export interface ITabbedViewProps {
  tabs: TabDefinition[];
  className?: string;
  onChange?: (selectedTab: TabDefinition, selectedTabIndex: number) => void;
  activeTab?: number;
  addColorWhenSelected?: boolean;
}

const blockName = 'tabbed-view';

export function TabbedView({
  tabs,
  className,
  onChange = noop,
  activeTab,
  addColorWhenSelected = false,
}: ITabbedViewProps) {
  const [internalSelectedTab, setInternalSelectedTab] = React.useState<number>(0);
  const bem = bemBlock(blockName);

  const handleChangeTab = (activeTab: number) => {
    setInternalSelectedTab(activeTab);
    onChange(tabs[activeTab], activeTab);
  };

  const getSelectedTab = (): number => {
    if (activeTab !== null && activeTab !== undefined) {
      return activeTab;
    } else {
      return internalSelectedTab;
    }
  };

  return (
    <div className={bem(null, null, className)}>
      <div className={bem('tabs-wrapper')}>
        <div className={bem('tabs-section')}>
          {tabs.map((tab, i) => (
            <section
              className={bem(
                'tab',
                [
                  {
                    selected: getSelectedTab() === i,
                  },
                ],
                [
                  {
                    selectedWithColor: addColorWhenSelected,
                  },
                ]
              )}
              onClick={() => handleChangeTab(i)}
              key={i}
            >
              {tab.title}
            </section>
          ))}
        </div>
        <div className={bem('tab-content')}>{tabs[getSelectedTab()]?.content}</div>
      </div>
    </div>
  );
}
