import type { SetState } from 'zustand';
import create from 'zustand';

export type AppStoreProps = {
  isSubmitting?: boolean; // a boolean that indicates wether a form is being submitted, can be used to delay app updating in this case
  setIsUpdating?(isUpdating: boolean): void;
  isUpdating?: boolean;
  setIsSubmitting?(isSubmitting: boolean): void;
  dirtyForms: string[];
  remoteAppVersion: string;
  setRemoteAppVersion?(remoteAppVersion: string): void;
  setDirtyForm?(name: string): void;
  clearDirtyForm?(name: string): void;
};

const initialState = {
  isSubmitting: false,
  isUpdating: false,
  isDirty: false,
  dirtyForms: [],
  remoteAppVersion: undefined,
};

export const useAppStore = create<AppStoreProps>((set: SetState<AppStoreProps>) => ({
  ...initialState,
  setRemoteAppVersion: (remoteAppVersion: string) =>
    set(() => {
      return {
        remoteAppVersion,
      };
    }),
  setIsSubmitting: (isSubmitting: boolean) =>
    set(() => {
      return {
        isSubmitting,
      };
    }),
  setIsUpdating: (isUpdating: boolean) =>
    set(() => {
      return {
        isUpdating,
      };
    }),
  setDirtyForm: (name: string) =>
    set(state => {
      if (state.dirtyForms.find(item => item === name)) {
        return {
          dirtyForms: state.dirtyForms,
        };
      }

      return {
        dirtyForms: state.dirtyForms.concat([name]),
      };
    }),
  clearDirtyForm: (name: string) =>
    set(state => {
      return {
        dirtyForms: state.dirtyForms.filter(item => item !== name),
      };
    }),
}));
