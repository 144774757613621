import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'why-support-matters';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'Why Support Matters',
  description:
    'Receiving the social support that is needed during life’s important moments has been proven to reduce stress levels, making these life-altering situations less damaging to long-term mental and physical health. Give InKind Founder, Laura Malcolm made the observation that, as a society, we’ve made it acceptable for people to say “Here are the things that I need” only twice in their adult life–1) when you get married and 2) when you have a baby. But when you’re going through a crisis or big life event, you need support. Give InKind makes support simple. ',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
