import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import type { ITableProps } from './Table';
import { Table } from './Table';

export type AnyType = string | number | boolean | Object;

export interface IKeyValueTableData {
  [key: string]: AnyType | AnyType[];
}

export interface IKeyValueTableProps<D> extends Omit<ITableProps<IKeyValueTableData>, 'data'> {
  stringify?: boolean;
  hideHeaders?: boolean;
  data: D;
}

// eslint-disable-next-line
const columns: any[] = [
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
];

export function KeyValueTable<D extends IKeyValueTableData>({
  className,
  stringify = true,
  hideHeaders,
  data,
  ...otherProps
}: IKeyValueTableProps<D>): React.ReactElement {
  const bem = bemBlock('table');

  const [_data, _setData] = React.useState<D[]>();

  React.useEffect(() => {
    const tableData = Object.keys(data).map(key => ({
      key,
      value: stringify ? JSON.stringify(data[key]) : data[key],
    }));
    _setData(tableData as unknown as D[]);
  }, [data, stringify]);

  if (!_data) return null;

  return (
    <Table<D>
      key={JSON.stringify(_data)}
      hideHeaders={hideHeaders}
      className={bem(null, null, className)}
      data={_data}
      columns={columns}
    />
  );
}
