import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'app';

const translations = {
  pagesYouFollow: 'Pages you follow',
  yourProfileAndPages: 'Your Profile & Pages',
  createPage: 'Create a Page',

  startAPage: 'Start a Page',
  signin: 'Sign In',
  logout: 'Logout',
  viewEditProfile: 'View/Edit Profile',

  healthCare: 'For Healthcare',
  howItWorks: 'How It Works',
  features: 'Features',
  tipsAndSamplePages: 'Tips & Sample Pages',
  comparisonChart: 'Comparison Chart',
  benefits: 'Benefits',
  commonQuestions: 'Common Questions',
  bySituation: 'By Situation',
  blog: 'Articles',
  giftGuides: 'Gift Guides',
  byGiftType: 'By Gift Type',
  connect: 'Connect with Give InKind:',

  forGroups: 'For Groups',
  forEmployers: 'Employers',
  forFaithGroups: 'Faith Groups',
  forHealthcare: 'Healthcare',

  // TODO: move any uncommon translations into the mobile nav translations
  waysToHelp: 'Ways to Help',
  whyGik: 'Why Give InKind?',
  aboutGik: 'About Give InKind',
  contact: 'Contact',
  support: 'Support',
  signIn: 'Sign In',
  embeddedVideoNotSupported: `Sorry, your browser doesn't support embedded videos.`,
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
