import { CheckoutFormModalContext } from '@gik/checkout/components/CheckoutForm/CheckoutFormModalContext';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import type { ClaimConflictResolve, OrderStatus } from '../api';
import type { CartItem } from '../types';

export let stripePromise: Promise<Stripe | null>;

type CheckoutFormContextInitialValues = {
  mainCart: CartItem[];
};

export function withCheckoutFormContext<P>(
  WrappedComponent: React.FC<P>,
  initialValues?: CheckoutFormContextInitialValues
): React.FC<P> {
  return function WrappedWithCheckoutFormContext(props: P) {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [anonymous, setAnonymous] = React.useState<boolean>(false);
    const [privateClaim, setPrivateClaim] = React.useState<boolean>(false);
    const [selectedShippingOptionName, setSelectedShippingOptionName] = React.useState<string>();
    const [orderStatus, setOrderStatus] = React.useState<OrderStatus>();

    const [mainCart, setMainCart] = React.useState<CartItem[]>(initialValues?.mainCart);
    const [messageToRecipient, setMessageToRecipient] = React.useState<string>();
    const stripeApiKey = useEnvStore(state => state.STRIPE_API_KEY);
    const [suggestedResolves, setSuggestedResolves] = React.useState<ClaimConflictResolve[]>();
    const modalContentRef = React.useRef<HTMLDivElement>(null);

    if (!stripePromise)
      stripePromise = loadStripe(stripeApiKey, {
        apiVersion: '2020-08-27', // TODO: remove after upgrading version in stripe dashboard
      });

    return (
      <CheckoutFormModalContext.Provider
        value={{
          modalContentRef,
          isSubmitting,
          setIsSubmitting,
          isSubmitted,
          setIsSubmitted,
          mainCart,
          setMainCart,
          suggestedResolves,
          setSuggestedResolves,
          messageToRecipient,
          setMessageToRecipient,
          anonymous,
          setAnonymous,
          privateClaim,
          setPrivateClaim,
          selectedShippingOptionName,
          setSelectedShippingOptionName,
          orderStatus,
          setOrderStatus,
        }}
      >
        <WrappedComponent {...(props as P)} />
      </CheckoutFormModalContext.Provider>
    );
  };
}
