import type { FormSchemaEntry } from '@gik/ui/Form';

export const StreetAddress1Field = (): FormSchemaEntry => ({
  label: 'Street address',
  name: 'address1',
  type: 'text',
  required: true,
  minLength: 2,
  maxLength: 255,
  props: { placeholder: 'U.S. Addresses only', autoComplete: 'address-line1' },
});

export const StreetAddress2Field = (): FormSchemaEntry => ({
  name: 'address2',
  type: 'text',
  required: false,
  minLength: 2,
  maxLength: 255,
  props: { placeholder: 'Apartment, suite, unit, etc (optional)', autoComplete: 'address-line2' },
});
