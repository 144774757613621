import { useGikSettings } from '@gik/api/acf/gik-settings/gikSettings';
import type { PGGreetingCardDesign } from '@gik/core/models/wordpress/PGGreetingCardDesign';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Grid } from '@gik/ui/Grid';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import React from 'react';

export type IPerfectgiftGreetingCardSelectorProps = {
  value: PGGreetingCardDesign;
  onSelect?: (greetingCard: PGGreetingCardDesign) => void;
} & UIComponent;

export function PerfectgiftGreetingCardSelector({
  children,
  onSelect,
  value,
  ...otherProps
}: React.PropsWithChildren<IPerfectgiftGreetingCardSelectorProps>): React.ReactElement {
  const bem = useBemCN(`perfectgift-greeting-card-selector`);
  const { data: gikSettings } = useGikSettings();
  const greetingCardOptions = gikSettings?.acf?.pg_greeting_card_designs;

  if (!greetingCardOptions)
    return (
      <div {...otherProps} {...bem()}>
        <LoadingSpinner center />
      </div>
    );

  return (
    <div {...otherProps} {...bem()}>
      <Grid cols={3} {...bem('grid')}>
        {greetingCardOptions
          .filter(greetingCard => greetingCard.enabled)
          .map(greetingCard => {
            return (
              <div
                key={greetingCard.url}
                {...bem('grid-tile', [{ selected: value === greetingCard }])}
                onClick={() => onSelect(greetingCard)}
              >
                {/* TODO: I'd still like a cloudinary onError fallback load the image_url*/}
                <img src={greetingCard.url} width={270} height={349} alt={'Greeting card cover design'} />
              </div>
            );
          })}
      </Grid>
    </div>
  );
}
