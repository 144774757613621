import { isBrowser } from '@/utils/environment';
import { logger } from '@gik/analytics/utils/logger';
import { CustomStatusCodes } from '@gik/api/CustomStatusCodes';
import { refreshJWTToken } from '@gik/api/users/auth';
import { Mutex } from 'async-mutex';
import type { HTTPError } from 'ky';
import { useUserStore } from '../../../store/UserStore';

const maxAge = 60 * 1000 * 10; // 10 minutes
const mutex = new Mutex();

let lastExpirationAlert = 0;

export async function jwtAccessTokenKyHook(request: Request, options) {
  const refreshToken = useUserStore.getState().refreshToken;
  const lastTokenRefresh = useUserStore.getState().lastTokenRefresh;
  const now = Date.now();
  let accessToken: string = useUserStore.getState().accessToken;
  if (refreshToken && now - lastTokenRefresh > maxAge) {
    // only refresh once even if two api calls are pending
    await mutex.runExclusive(async () => {
      if (lastTokenRefresh === useUserStore.getState().lastTokenRefresh) {
        // request a new access token
        try {
          ({ accessToken } = await refreshJWTToken());
        } catch (error) {
          logger.error(error);
          if ((error as HTTPError).response?.status === CustomStatusCodes.SessionExpired) {
            useUserStore.getState().logout();

            const { UI } = await import('@gik/ui/UIManager/manager');
            if (now - lastExpirationAlert > 60000) {
              lastExpirationAlert = now;
              (async () => {
                // const { auth } = await import('@gik/auth');
                // TODO: use translation
                await UI.error('Your session has expired. Please log in');
                // TODO: replace with auth.login()
                // auth.login();
              })();
            }
          }
        }
      } else {
        // another hook ran before this one, so just use the new access token
        accessToken = useUserStore.getState().accessToken;
      }
    });
  }
  if (accessToken && isBrowser()) {
    request.headers.set('Authorization', `Bearer ${accessToken}`);
    options.headers.set('Authorization', `Bearer ${accessToken}`);
  }
}
