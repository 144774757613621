import { useEnvStore } from '@gik/core/store/EnvStore';
import qs from 'qs';

/**
 * `bustCache && !bypassCache`: get fresh results, return the current cache content and then refresh the cache content.
 * `!bustCache && bypassCache`: get fresh results, return them, ignore the cache's existence
 * `bustCache && bypassCache`: get fresh results, refresh the cache and then return results.
 * (default) `!bustCache && !bypassCache`: tries to get cached results, if not, fetch from WP
 * hash: access a hash set within the cache
 */
export interface CacheOptions {
  bustCache?: boolean;
  bypassCache?: boolean; // calls the cache, but the cache itself directly calls WP -- can only GET
  'proxy-cms-pages'?: 'all';
  hash?: string;
}

// TODO: rename to CacheURLEncoder
export function WPURLEncoder(path, searchParams, bypassCache: boolean, qsStringifyOptions?) {
  let cacheOptions: CacheOptions;

  if (!bypassCache) {
    cacheOptions = {
      bustCache: searchParams?.bustCache === 'true',
      bypassCache: searchParams?.bypassCache === 'true',
      'proxy-cms-pages': searchParams?.['proxy-cms-pages'] as 'all',
      hash: searchParams?.hash,
    };
  }

  delete searchParams?.bustCache;
  delete searchParams?.bypassCache;
  delete searchParams?.['proxy-cms-pages'];
  delete searchParams?.hash;

  const wpSearchParams = searchParams && qs.stringify(searchParams, qsStringifyOptions);

  const _cacheUrl = bypassCache
    ? `${path}${wpSearchParams ? `?${wpSearchParams}` : ''}`
    : `${useEnvStore.getState().API_CACHE_URL}/url/` +
      encodeURIComponent(`${path}${wpSearchParams ? `?${wpSearchParams}` : ''}`);

  const cacheUrl = new URL(_cacheUrl);

  cacheOptions?.bustCache && cacheUrl.searchParams.set('bustCache', cacheOptions.bustCache.toString());
  cacheOptions?.bypassCache && cacheUrl.searchParams.set('bypassCache', cacheOptions.bypassCache.toString());
  cacheOptions?.['proxy-cms-pages'] &&
    cacheUrl.searchParams.set('proxy-cms-pages', cacheOptions['proxy-cms-pages'].toString());
  cacheOptions?.hash && cacheUrl.searchParams.set('hash', cacheOptions.hash.toString());

  return cacheUrl;
}
