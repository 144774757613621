import { logger } from '@gik/analytics/utils/logger';
import ImageUtils from '@gik/core/utils/ImageUtils';

export type SVGImage = string | ArrayBuffer;
export type RasterImage = string;
export type GIFImage = unknown; // TODO support GIF

interface BaseAttachImageResult {
  file: File;
  name: string;
}

export interface RasterAttachImageResult extends BaseAttachImageResult {
  blob: Blob;
  base64: RasterImage;
  width: number;
  height: number;
}

export interface AnimatedAttachImageResult extends BaseAttachImageResult {
  gif: GIFImage;
}

export interface VectorAttachImageResult extends BaseAttachImageResult {
  vector: SVGImage;
}

export type AttachImageResult = RasterAttachImageResult | AnimatedAttachImageResult | VectorAttachImageResult;

export enum AcceptType {
  GIF,
  PNG,
  JPEG,
  SVG,
  TEXT,
  CSV,
  VCF,
}

export enum ConvertType {
  FromFileExtension = -1,
}

export type AttachContactResult = {
  file: File;
  text: string;
  mimetype: string;
  name: string;
};

export function getMimeTypeFromFileName(value: string) {
  if (value.toLowerCase().endsWith('.gif')) {
    return 'image/gif';
  } else if (value.toLowerCase().endsWith('.jpg') || value.toLowerCase().endsWith('.jpeg')) {
    return 'image/jpg';
  } else if (value.toLowerCase().endsWith('.svg')) {
    return 'image/svg+xml';
  } else if (value.toLowerCase().endsWith('.png')) {
    return 'image/png';
  } else if (value.toLowerCase().endsWith('.csv')) {
    return 'text/csv';
  } else if (value.toLowerCase().endsWith('.vcf')) {
    return 'text/vcard';
  } else {
    return 'text/plain';
  }
}

export function getMimeTypeFromAcceptType(type: AcceptType) {
  switch (type) {
    case AcceptType.GIF:
      return 'image/gif';
    case AcceptType.JPEG:
      return 'image/jpeg';
    case AcceptType.SVG:
      return 'image/svg+xml';
    case AcceptType.PNG:
      return 'image/png';
    case AcceptType.CSV:
      return 'text/csv';
    case AcceptType.VCF:
      return 'text/vcard';
    default:
      return 'text/plain';
  }
}

export function getMimeType(convertTo: ConvertType | AcceptType, value: string) {
  return convertTo == ConvertType.FromFileExtension
    ? getMimeTypeFromFileName(value)
    : getMimeTypeFromAcceptType(convertTo);
}

export const DefaultMaxImageAttachmentSize = 1920;

export default class FileUtils {
  static async attachImage(
    maxSize = DefaultMaxImageAttachmentSize,
    acceptType: AcceptType[] = [AcceptType.GIF, AcceptType.PNG, AcceptType.JPEG],
    convertTo: ConvertType | AcceptType = AcceptType.JPEG
  ): Promise<AttachImageResult> {
    const { getBase64Strings } = await require('exif-rotate-js/lib');
    const dataURLtoBlob = await require('blueimp-canvas-to-blob');

    const fileInput = document.createElement('input');

    const _acceptType = [];

    if (acceptType.includes(AcceptType.GIF)) {
      _acceptType.push('.gif');
    }

    if (acceptType.includes(AcceptType.PNG)) {
      _acceptType.push('.png');
    }

    if (acceptType.includes(AcceptType.JPEG)) {
      _acceptType.push('.jpg');
      _acceptType.push('.jpeg');
    }

    if (acceptType.includes(AcceptType.SVG)) {
      _acceptType.push('.svg');
    }

    fileInput.type = 'file';
    fileInput.accept = _acceptType.join(', ');
    fileInput.hidden = true;
    document.body.appendChild(fileInput);

    return new Promise(resolve => {
      fileInput.addEventListener('change', async fileChangeEvent => {
        const target = fileChangeEvent.target as HTMLInputElement;
        if (!fileChangeEvent.target) {
          resolve(null);
          return;
        }

        try {
          const mimetype = getMimeTypeFromFileName(target.value);
          const file = target.files[0];

          const baseValues = { name: target.value, file };

          if (mimetype != 'image/svg+xml' && mimetype != 'image/gif') {
            const type = convertTo == ConvertType.FromFileExtension ? mimetype : getMimeTypeFromAcceptType(convertTo);

            const base64 = (
              await getBase64Strings(target.files, {
                maxSize,
                type,
              })
            )[0];

            const image = await ImageUtils.createImage(base64);
            const blob = dataURLtoBlob(base64);

            const value: RasterAttachImageResult = {
              ...baseValues,
              blob,
              base64,
              width: image.naturalWidth,
              height: image.naturalHeight,
            };
            resolve(value);
          } else {
            if (mimetype == 'image/svg+xml') {
              const reader = new FileReader();

              reader.onload = function (e) {
                const vector = e.target.result;

                const value: VectorAttachImageResult = {
                  ...baseValues,
                  vector,
                };
                resolve(value);
              };

              reader.readAsText(file);
            } else if (mimetype == 'image/gif') {
              // FIXME: TODO
            }
          }
        } catch (e) {
          resolve(null);
          return;
        }
      });

      fileInput.click();
    });
  }

  static async attachContacts(
    acceptType: AcceptType[] = [AcceptType.TEXT, AcceptType.CSV, AcceptType.VCF]
  ): Promise<AttachContactResult> {
    const _acceptType = [];

    if (acceptType.includes(AcceptType.CSV)) {
      _acceptType.push('.csv');
    }

    if (acceptType.includes(AcceptType.VCF)) {
      _acceptType.push('.vcf');
    }

    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = _acceptType.join(', ');
    fileInput.hidden = true;
    document.body.appendChild(fileInput);

    return new Promise(resolve => {
      fileInput.addEventListener('change', async fileChangeEvent => {
        const target = fileChangeEvent.target as HTMLInputElement;
        if (!fileChangeEvent.target) {
          resolve(null);
          return;
        }

        try {
          const mimetype = getMimeTypeFromFileName(target.value);
          const file = target.files[0];

          const reader = new FileReader();

          reader.onload = function (e) {
            const text = e.target.result.toString();
            resolve({ name: target.value, file, text, mimetype });
          };

          reader.readAsText(file);
        } catch (e) {
          logger.error(e);
          resolve(null);
          return;
        }
      });

      fileInput.click();
    });
  }
}
