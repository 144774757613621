import moment from 'moment';
import type { Moment } from 'moment-timezone';

/**
 * Set the time on a moment instance
 */
export function applyTimeToMoment(_moment: Moment, time: string): Moment {
  const timeMoment = moment.utc(`${_moment.format('YYYY-MM-DD')} ${time}`);
  _moment.hour(timeMoment.hour());
  _moment.minute(timeMoment.minute());
  _moment.second(timeMoment.second());
  _moment.millisecond(timeMoment.millisecond());
  return _moment;
}
