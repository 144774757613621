import { productHasAffiliateRelationship, productIsAffiliate } from '@gik/checkout/utils/productUtils';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { defaultToggleValue } from '@gik/shop/hooks/useOpenLoop';
import { HTMLParser } from '@gik/ui/HTMLParser/HTMLParser';
import { Separator } from '@gik/ui/Separator';
import { ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';

export type ProductDescriptionProps = {
  cartItem?: CartItem;
  product: Product;
  toggleValue: OLProductType;
};

function ProductDescriptionComp({ product, toggleValue }: ProductDescriptionProps) {
  const bem = bemBlock('product-description');

  if (!product) return <ProductDescriptionSkeleton />;

  const _toggleValue = toggleValue ?? defaultToggleValue;

  const description = product.isToggleable
    ? _toggleValue === 'physical'
      ? product.physicalDescription
      : product.digitalDescription
    : product.description;

  const pgBrandTerms = product.isToggleable
    ? product.variations
        ?.find(v => v.metaData?.find(m => m.key === 'pg_product_type' && m.value === _toggleValue))
        ?.metaData?.find(m => m.key == 'pg_brand_terms')?.value
    : product.brandTerms;

  const pgProductTerms = product.isToggleable
    ? product.variations
        ?.find(v => v.metaData?.find(m => m.key === 'pg_product_type' && m.value === _toggleValue))
        ?.metaData?.find(m => m.key == 'pg_product_terms')?.value
    : product.productTerms;

  return (
    <div className={bem()}>
      {/* TODO: UI Article component */}
      <div className={bem('description', undefined, 'gik-article')}>
        <HTMLParser rawHtml={description} />
      </div>

      {productIsAffiliate(product) && product.sources && product.sources.length && (
        <>
          <Separator thickness="thin" />
          <div className={bem('note')}>
            Payment fulfillment by {product.sources[0].name}. Give InKind has an affiliate relationship with many of the
            advertisers on our site, and may receive a commission from products purchased via this link.
          </div>
        </>
      )}

      {productHasAffiliateRelationship(product) && (
        <>
          <Separator thickness="thin" />
          <div className={bem('tango-card-disclaimer')}>
            <p>
              Give InKind has an affiliate relationship with many of the advertisers on our site, and may receive a
              commission from products purchased.
            </p>
          </div>
        </>
      )}

      {product.checkoutType === CheckoutType.TangoCard && (
        <>
          {product.disclaimer && (
            <div className={bem('disclaimer')}>
              <strong>Disclaimer:</strong> <HTMLParser rawHtml={product.disclaimer} />
            </div>
          )}

          {product.terms && (
            <div className={bem('terms')}>
              <strong>Terms &amp; Conditions:</strong> <HTMLParser rawHtml={product.terms} />
            </div>
          )}
        </>
      )}

      {product.checkoutType === CheckoutType.Perfectgift && (
        <>
          {pgBrandTerms && (
            <div className={bem('brand-terms')}>
              <strong>Brand Terms:</strong>{' '}
              <p>
                <HTMLParser rawHtml={pgBrandTerms} />
              </p>
            </div>
          )}

          {pgProductTerms && (
            <div className={bem('product-terms')}>
              <strong>Product Terms:</strong>{' '}
              <p>
                <HTMLParser rawHtml={pgProductTerms} />
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export const ProductDescription = withComponentErrorBoundary(ProductDescriptionComp);

export function ProductDescriptionSkeleton() {
  const bem = useBemCN('product-description-skeleton');
  return (
    <div {...bem()}>
      <ParagraphBone words={40} />
      <ParagraphBone words={30} />
      <ParagraphBone words={50} />
    </div>
  );
}
