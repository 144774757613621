import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import React from 'react';

interface ModalHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  append?: React.ReactNode;
  content?: React.ReactNode;
  centeredTitle?: boolean;
  closable?: boolean;
  onClose?(): void;
  onBack?(): void;
  onRef?(ref: HTMLDivElement): void;
}

export function ModalHeader({
  title,
  content,
  centeredTitle,
  append,
  closable = true,
  className,
  onClose = noop,
  onBack, // don't use noop here becuase we expect it to be empty to hide the back button
  onRef,
  ...otherProps
}: ModalHeaderProps): React.ReactElement {
  const bem = bemBlock('modal-header');

  const mainRef = React.useRef<HTMLDivElement>();
  const mainRefCurrent = mainRef.current;

  React.useEffect(() => {
    if (mainRefCurrent && onRef) {
      onRef(mainRefCurrent);
    }
  }, [mainRefCurrent, onRef]);

  const titleEl = (
    <p className={bem('title', [{ centered: centeredTitle, closable }])}>
      {title}
      {append && <div className={bem('title-append')}>{append}</div>}
    </p>
  );
  return (
    <div className={bem(null, null, className)} ref={mainRef} {...otherProps}>
      <div className={bem('title-row')}>
        {onBack ? (
          <div className={bem('back-button')} onClick={onBack}>
            <SvgIcon Icon={ChevronLeftIcon} />
            {titleEl}
          </div>
        ) : (
          titleEl
        )}

        {onClose && closable && (
          <div className={bem('close-button')} onClick={onClose}>
            <SvgIcon Icon={CloseIcon} />
          </div>
        )}
      </div>
      {content}
    </div>
  );
}
