import { isBrowser } from '@/utils/environment';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import React from 'react';

// TODO: remove this after SPA migration - redirects will not interrupt events
export const fireCompletedPurchaseLocalStorageKey = 'gik-fire-completed-purchase';

// TODO: remove this after SPA migration - redirects will not interrupt events
export default function useCompletedPurchaseForPageAnalytics(): void {
  React.useEffect(() => {
    if (isBrowser() && localStorage.getItem(fireCompletedPurchaseLocalStorageKey)) {
      localStorage.removeItem(fireCompletedPurchaseLocalStorageKey);
      Analytics.fireEvent(AnalyticsEvents.CompletedPurchaseCloseModal, {});
    }
  }, []);
}
