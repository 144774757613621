import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'features-nav';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'Features',
  leadDescription:
    'Whether you want to arrange for home-cooked meals, start a gift card train, or just learn more about how to support someone through their big circumstances, Give InKind brings together all of the tools that you need to support anyone, from anywhere — for free.',

  // Page Navigation
  navCalendar: 'Calendar',
  navWishlist: 'Wishlist',
  navUpdates: 'Updates',
  navFundraising: 'Donations',
  navMore: '...And more',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
