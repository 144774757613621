import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IOAuthUserLoginResponse, IUserLoginResponse } from '@gik/api/users/types';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import { SigninFlowAccountDeactivated } from '@gik/auth/components/SignInFlow/SigninFlowAccountDeactivated';
import { SignInFlowLoginContent } from '@gik/auth/components/SignInFlow/SignInFlowLoginContent';
import { SignInFlowOAuthContent } from '@gik/auth/components/SignInFlow/SignInFlowOAuthContent';
import { SignInFlowSignUpContent } from '@gik/auth/components/SignInFlow/SignInFlowSignUpContent';
import type { SignInFlowContentCopyVariant } from '@gik/auth/components/SignInFlow/SignInFlowStartContent';
import { SignInFlowStartContent } from '@gik/auth/components/SignInFlow/SignInFlowStartContent';
import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import type { ISignInFlowModalHeaderRef } from '@gik/auth/utils/authModals';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import { verifyEmailAndRedirectToLoginProvider } from '@gik/auth/utils/verifyEmailAndRedirectToLoginProvider';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export enum SignInFlowStep {
  START = 'start',
  LOGIN = 'login',
  OAUTH = 'oauth',
  SIGNUP = 'signup',
  DEACTIVATED = 'deactivated',
}

const SignInFlowStepHeaderTitleTranslationKeyMap: Record<SignInFlowStep, string> = {
  [SignInFlowStep.START]: translationKeys.headerTitleStart,
  [SignInFlowStep.LOGIN]: translationKeys.headerTitleLogin,
  [SignInFlowStep.OAUTH]: translationKeys.headerTitleOAuth,
  [SignInFlowStep.SIGNUP]: translationKeys.headerTitleSignup,
  [SignInFlowStep.DEACTIVATED]: translationKeys.headerTitleDeactivated,
};

export type SignInFlowInitialValues = {
  [SignInFlowStartContentFormInputNames.EmailAddress]: string;
  provider?: ExternalLoginProvider;
  firstName?: string;
};

export type ISignInFlowContentProps = UIComponent & {
  startOn?: SignInFlowStep;
  copyVariant?: SignInFlowContentCopyVariant;
  routeId?: string;
  trackingId?: AnalyticsEvents;
  onSuccess?: (values: IUserLoginResponse | IOAuthUserLoginResponse) => void;
  onForgotPassword?: (initialValues: Partial<IForgotPasswordFormValues>) => void;
  initialValues?: SignInFlowInitialValues;
  headerRef?: React.RefObject<ISignInFlowModalHeaderRef>;
};

export function SignInFlowContent({
  startOn,
  copyVariant,
  routeId,
  className,
  trackingId,
  onSuccess,
  onForgotPassword,
  initialValues,
  headerRef,
  ...otherProps
}: ISignInFlowContentProps) {
  const bem = useBemCN('sign-in-flow-content');
  const [currentStep, setCurrentStep] = React.useState<SignInFlowStep>(startOn ?? SignInFlowStep.START);
  const [email, setEmail] = React.useState<string>(
    initialValues?.[SignInFlowStartContentFormInputNames.EmailAddress] ?? null
  );
  const [firstName, setFirstName] = React.useState<string>(initialValues?.firstName ?? null);
  const [provider, setProvider] = React.useState<ExternalLoginProvider>(initialValues?.provider ?? null);

  React.useEffect(() => {
    headerRef?.current?.setTitle(SignInFlowStepHeaderTitleTranslationKeyMap[currentStep]);
  }, [currentStep, headerRef]);

  function goToStartStep() {
    setEmail(null);
    setFirstName(null);
    setProvider(null);
    setCurrentStep(SignInFlowStep.START);
  }

  function goToLoginStep(email: string, firstName: string) {
    setEmail(email);
    setFirstName(firstName);
    setCurrentStep(SignInFlowStep.LOGIN);
  }

  function goToAccountDeactivatedStep(email: string, firstName: string) {
    setEmail(email);
    setFirstName(firstName);
    setCurrentStep(SignInFlowStep.DEACTIVATED);
  }

  function goToSignUpStep(email: string) {
    setEmail(email);
    setCurrentStep(SignInFlowStep.SIGNUP);
  }

  function goToOAuthStep(email: string, provider: ExternalLoginProvider, firstName: string) {
    setEmail(email);
    setProvider(provider);
    setFirstName(firstName);
    setCurrentStep(SignInFlowStep.OAUTH);
  }

  const onIncorrectProvider = React.useCallback(async (email: string) => {
    await verifyEmailAndRedirectToLoginProvider(
      email,
      goToLoginStep,
      goToAccountDeactivatedStep,
      goToSignUpStep,
      goToOAuthStep
    );
  }, []);

  return (
    <div {...bem(null, currentStep ?? SignInFlowStep.START, className)} {...otherProps}>
      <TransitionGroup {...bem('transition')}>
        {currentStep === SignInFlowStep.LOGIN && (
          <CSSTransition id={SignInFlowStep.LOGIN} timeout={200} classNames={bem('transition').className}>
            <SignInFlowLoginContent
              supportersFirstName={firstName}
              initialValues={{
                email,
              }}
              goToStartStep={goToStartStep}
              trackingId={trackingId}
              onSuccess={onSuccess}
              onForgotPassword={onForgotPassword}
            />
          </CSSTransition>
        )}

        {currentStep === SignInFlowStep.SIGNUP && (
          <CSSTransition id={SignInFlowStep.SIGNUP} timeout={200} classNames={bem('transition').className}>
            <SignInFlowSignUpContent
              goToStartStep={goToStartStep}
              initialValues={{
                email,
              }}
              trackingId={trackingId}
              onSuccess={onSuccess}
              onIncorrectProvider={onIncorrectProvider}
            />
          </CSSTransition>
        )}

        {currentStep === SignInFlowStep.OAUTH && (
          <CSSTransition id={SignInFlowStep.OAUTH} timeout={200} classNames={bem('transition').className}>
            <SignInFlowOAuthContent
              email={email}
              provider={provider}
              supportersFirstName={firstName}
              onSuccess={onSuccess}
              goToStartStep={goToStartStep}
              onIncorrectProvider={onIncorrectProvider}
            />
          </CSSTransition>
        )}

        {currentStep === SignInFlowStep.START && (
          <CSSTransition id={SignInFlowStep.START} timeout={200} classNames={bem('transition').className}>
            <SignInFlowStartContent
              routeId={routeId}
              copyVariant={copyVariant}
              goToLoginStep={goToLoginStep}
              goToSignUpStep={goToSignUpStep}
              goToOAuthStep={goToOAuthStep}
              goToAccountDeactivatedStep={goToAccountDeactivatedStep}
              onSuccess={onSuccess}
              onIncorrectProvider={onIncorrectProvider}
              initialValues={initialValues}
            />
          </CSSTransition>
        )}

        {currentStep === SignInFlowStep.DEACTIVATED && (
          <CSSTransition id={SignInFlowStep.DEACTIVATED} timeout={200} classNames={bem('transition').className}>
            <SigninFlowAccountDeactivated goToStartStep={goToStartStep} supportersFirstName={firstName} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
}
