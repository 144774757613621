import { useInkind } from '@gik/api/inkinds/inkind';
import VisaLogo from '@gik/core/assets/img/visaLogo.svg';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { DonationsValues } from '@gik/create';
import { CareCalendarCard, DonationsCard } from '@gik/create';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { UI } from '@gik/ui/UIManager';
import ChatAlt2Icon from '@heroicons/react/outline/ChatAlt2Icon';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';
import NewspaperIcon from '@heroicons/react/outline/NewspaperIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import { modalContentId } from '../InkindPageSettingsModal';
import { pageSettingsNavItemIds } from '../utils/PageSettingsScrollNavBar';
import { SectionTitleAndDescription } from '../utils/SectionTitleAndDescription';
import FeatureCheckboxCard from './FeatureCheckboxCard';
import type { WishlistWithAmazonValues } from './WishlistCardWithAmazon/WishlistCardWithAmazon';
import { WishlistCardWithAmazon } from './WishlistCardWithAmazon/WishlistCardWithAmazon';

export type FeaturesSectionValues = {
  careCalendarEnabled: boolean;
  wishlistValues: WishlistWithAmazonValues;
  donationValues: DonationsValues;
  sendGiftBoxEnabled: boolean;
  sendVisaCardEnabled?: boolean;
  sendCareCardEnabled: boolean;
  discussions: boolean;
  enableSuggestedArticles: boolean;
};

export interface IFeaturesSectionProps {
  isRecipientInfoComplete: boolean;
  values: FeaturesSectionValues;
  onChange?: (values: FeaturesSectionValues) => void;
  id?: string;
}

export const featuresSectionBlockName = 'features-section';
export const featuresSectionSuggestedItems = 'features-section-suggested-items';

const FeaturesSectionComp: React.FC<PropsWithClassName<IFeaturesSectionProps>> = ({
  isRecipientInfoComplete,
  values,
  onChange,
  className,
  id,
}): JSX.Element => {
  const bem = bemBlock(featuresSectionBlockName);
  const { t } = useTranslation();

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkindPage } = useInkind(inkindRouteId);

  function notifyCantDisableAll() {
    UI.notify(t(translationKeys.cantDisableCareCalendarAndWishlist));
  }

  function canDisableCalendar() {
    return !values.wishlistValues.enabled && values.careCalendarEnabled;
  }

  function canDisableWishlist() {
    return !values.careCalendarEnabled && values.wishlistValues.enabled;
  }

  function onToggleCareCalendar(careCalendarEnabled: boolean) {
    if (!canDisableCalendar()) {
      onChange?.({
        ...values,
        careCalendarEnabled,
      });
    } else {
      notifyCantDisableAll();
    }
  }

  function onToggleWishlist(wishlistValues: WishlistWithAmazonValues) {
    // if Wishlist enabled isn't changed, then Amazon enabled or Amazon url are changed, just let them update
    const isWishlistEnabledChanged = values.wishlistValues.enabled !== wishlistValues.enabled;

    if (!canDisableWishlist() || !isWishlistEnabledChanged) {
      onChange?.({
        ...values,
        wishlistValues,
      });
    } else {
      notifyCantDisableAll();
    }
  }

  function onToggleDonations(donationValues: DonationsValues) {
    onChange?.({
      ...values,
      donationValues,
    });
  }

  function onToggleSendVisaCard(sendVisaCardEnabled: boolean) {
    onChange?.({
      ...values,
      sendVisaCardEnabled,
    });
  }

  // TODO: instead of comment off code, get settings from site settings and show/hide element accordingly
  // function onToggleSendGiftBox(sendGiftBoxEnabled: boolean) {
  //   onChange?.({
  //     ...values,
  //     sendGiftBoxEnabled,
  //   });
  // }

  function onToggleSendCareCard(sendCareCardEnabled: boolean) {
    onChange?.({
      ...values,
      sendCareCardEnabled,
    });
  }

  function onToggleDiscussion(discussions: boolean) {
    onChange?.({
      ...values,
      discussions,
    });
  }

  function onToggleEnableSuggestedArticles(enableSuggestedArticles: boolean) {
    onChange?.({
      ...values,
      enableSuggestedArticles,
    });
  }

  const scrollToRcp = React.useCallback(() => {
    scroller.scrollTo(pageSettingsNavItemIds.recipient, {
      duration: 300,
      smooth: true,
      containerId: modalContentId,
    });
  }, []);

  const handleClickDisabled = React.useCallback(() => {
    UI.notify('You must save a recipient address for this option to be available');

    scrollToRcp();
  }, [scrollToRcp]);

  return (
    <SectionTitleAndDescription
      id={id}
      className={bem(null, null, className)}
      title={t(translationKeys.featuresTitle)}
      description={t(translationKeys.featuresDescription)}
      hasBlueBackground
    >
      <div className={bem('cards')}>
        <CareCalendarCard value={values.careCalendarEnabled} onChange={onToggleCareCalendar} disabled={false} />

        <WishlistCardWithAmazon value={values.wishlistValues} onChange={onToggleWishlist} disabled={false} />

        <DonationsCard value={values.donationValues} onChange={onToggleDonations} disabled={false} />

        {/* TODO: instead of comment off code, get settings from site settings and show/hide element accordingly */}
        {/*<FeatureCheckboxCard
          value={values.sendGiftBoxEnabled}
          onChange={onToggleSendGiftBox}
          title={t(translationKeys.sendGiftBoxTitle)}
          description={t(translationKeys.sendGiftBoxDescription)}
          icon={GiftIcon}
          className={bem('giftbox')}
  />*/}
        <FeatureCheckboxCard
          value={values.sendCareCardEnabled}
          onChange={onToggleSendCareCard}
          title={t(translationKeys.sendCareCardTitle)}
          description={t(translationKeys.sendCareCardDescription).toString()}
          icon={CreditCardIcon}
          className={bem('carecard')}
        />
        <FeatureCheckboxCard
          value={values.sendVisaCardEnabled}
          onChange={onToggleSendVisaCard}
          onClickDisabled={handleClickDisabled}
          title={t(translationKeys.sendVisaCardTitle)}
          description={
            <>
              {t(translationKeys.sendVisaCardDescription)}

              {!isRecipientInfoComplete && (
                <span>
                  You must enter a{' '}
                  <Button variant="primary-link" onClick={scrollToRcp}>
                    recipient address
                  </Button>{' '}
                  for this option to be available.
                </span>
              )}
            </>
          }
          disabled={!isRecipientInfoComplete}
          prepend={
            <div style={{ width: '40px', height: '40px' }}>
              <div className={bem('visa-square', [{ disabled: !isRecipientInfoComplete }])}>
                <VisaLogo style={{ width: '30px' }} />
              </div>
            </div>
          }
          className={bem('visacard')}
        />
        <FeatureCheckboxCard
          value={values.discussions}
          onChange={onToggleDiscussion}
          title={t(translationKeys.discussionTitle)}
          description={t(translationKeys.discussionDescription).toString()}
          icon={ChatAlt2Icon}
          className={bem('messages')}
        />
        <FeatureCheckboxCard
          id={featuresSectionSuggestedItems}
          value={values.enableSuggestedArticles}
          onChange={onToggleEnableSuggestedArticles}
          title={t(translationKeys.suggestedArticlesTitle)}
          description={t(translationKeys.suggestedArticlesDescription).toString()}
          icon={NewspaperIcon}
          className={bem('suggested-articles')}
          locked={!inkindPage?.isPremium}
        />
      </div>
    </SectionTitleAndDescription>
  );
};

export const FeaturesSection = withComponentErrorBoundary(FeaturesSectionComp);
