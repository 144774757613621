import { getApplicationVersion, getRemoteApplicationVersion } from '@gik/api/siteSettings/version';
import semver from 'semver';
import { updateAppNotification } from '../components/openAppNotification/openAppNotification';
import { useAppStore } from '../store/AppStore';

let appUpdateInteral: ReturnType<typeof setInterval>;

export function validateApplicationVersion(version: string): boolean {
  if (!semver.valid(version)) throw new Error(`invalid version string "${version}"`);

  return semver.satisfies(getApplicationVersion(), '>=' + version);
}

/**
 * Check the version string of the app to see if it needs updating.
 * If an update is needed the app should prompt the user to reload the page.
 */
export async function checkRemoteApplicationVersion(): Promise<string | undefined> {
  const remoteVersion = await getRemoteApplicationVersion();
  useAppStore.setState({ remoteAppVersion: remoteVersion });
  if (!validateApplicationVersion(remoteVersion)) return remoteVersion;
  return undefined;
}

export const checkAppVersion = async () => {
  const response = await checkRemoteApplicationVersion();
  if (response !== undefined) {
    updateAppNotification();
    appUpdateStopPolling();
  }
};

/**
 * Start check for app updates
 *
 * @param interval: interval in milliseconds between checks
 */
export function appUpdateStartPolling(interval: number = 60 * 15 * 1000) {
  appUpdateInteral = setInterval(checkAppVersion, interval);
}

/**
 * Stop checking for app updates
 */
export function appUpdateStopPolling() {
  clearInterval(appUpdateInteral);
}
