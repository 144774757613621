import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';

export interface ISectionTitleAndDescriptionProps {
  title: string;
  description?: string;
  hasBlueBackground?: boolean;
  id?: string;
}

export const sectionTitleAndDescriptionBlockName = 'section-title-and-description';

const SectionTitleAndDescriptionComp: React.FC<
  PropsWithClassName<React.PropsWithChildren<ISectionTitleAndDescriptionProps>>
> = ({ title, description, hasBlueBackground, children, className, id }): JSX.Element => {
  const bem = bemBlock(sectionTitleAndDescriptionBlockName);

  return (
    <div id={id} className={bem(null, [{ 'blue-background': hasBlueBackground }], className)}>
      <h3 className={bem('title')}>{title}</h3>
      {description && (
        <p className={bem('description')}>
          <HTMLParser rawHtml={description} />
        </p>
      )}
      {children}
    </div>
  );
};

export const SectionTitleAndDescription = withComponentErrorBoundary(SectionTitleAndDescriptionComp);
