import bemBlock from '@gik/core/utils/bemBlock';
import type { HTMLAttributes } from 'react';
import React from 'react';

interface IFormErrorProps extends HTMLAttributes<HTMLDivElement> {
  status?: string;
  message: React.ReactNode;
  centered?: boolean;
}

export function FormError({
  message,
  className,
  status,
  centered,
  ...otherProps
}: IFormErrorProps): React.ReactElement {
  const bem = bemBlock('form-error');
  return (
    <div className={bem(null, [{ centered }], className)} {...otherProps}>
      {status}
      {message}
    </div>
  );
}
