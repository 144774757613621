import React from 'react';
import type { ICopyButtonProps } from './CopyButton';
import { CopyButton } from './CopyButton';

export interface ICopyAdressEmailProps extends Omit<ICopyButtonProps, 'notificationText'> {
  notificationText?: string;
}

export const CopyEmailButton: React.FC<ICopyAdressEmailProps> = ({
  text = 'COPY EMAIL',
  notificationText = 'Email copied to clipboard!',
  ...otherProps
}: ICopyButtonProps) => <CopyButton text={text} notificationText={notificationText} {...otherProps} />;

export function CopyEmailButtonCircle(props: ICopyAdressEmailProps) {
  return <CopyEmailButton text={null} variant={'default-extra-light'} pill={false} circle {...props} />;
}

export default CopyEmailButton;
