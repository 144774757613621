import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Apple = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M17.9583 11.1234C17.9838 14.1514 20.611 15.1565 20.6428 15.1692C20.6237 15.2392 20.223 16.6069 19.256 18.0127C18.4227 19.2341 17.5576 20.4427 16.1962 20.4682C14.854 20.4936 14.4278 19.673 12.8947 19.673C11.3616 19.673 10.8845 20.4427 9.61863 20.4936C8.30183 20.5445 7.3031 19.1768 6.46341 17.9618C4.74585 15.4809 3.43542 10.9453 5.19751 7.8855C6.07537 6.36514 7.64025 5.40458 9.33873 5.37913C10.6301 5.35369 11.8514 6.25064 12.6403 6.25064C13.4291 6.25064 14.9112 5.17557 16.4698 5.33461C17.125 5.36005 18.9507 5.59542 20.1275 7.31934C20.0258 7.37659 17.9329 8.59796 17.9583 11.1234ZM15.4392 3.69338C16.139 2.84733 16.6097 1.67048 16.4825 0.5C15.4774 0.538168 14.256 1.16794 13.5372 2.01399C12.8883 2.76463 12.3222 3.96056 12.4749 5.1056C13.5945 5.19466 14.7459 4.53944 15.4392 3.69338Z"
          fill="#A2AAAD"
        />
      </svg>
    );
  }
);
