import type { APIWithPagination, SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { Product } from '@gik/core/models/gik/Product';
import { apiCap } from '..';

const path = 'products';

export type ProductParams = {
  productCategorySlugs?: string[];
  recipientSlugs?: string[];
  situationSlugs?: string[];
  slug?: string[];
  ids?: string[];
  search?: string;
} & APIWithPagination;

function getParams(params?: ProductParams) {
  return {
    perPage: apiCap,
    ...params,
    productCategorySlugs: params?.productCategorySlugs?.join(','),
    recipientSlugs: params?.recipientSlugs?.join(','),
    situationSlugs: params?.situationSlugs?.join(','),
    slug: params?.slug?.join(','),
    include: params?.ids?.join(','),
  };
}

export async function getProductsBySlug(params?: ProductParams) {
  return dotnetApi.get(path, {
    searchParams: getParams(params),
  });
}

export function useProductsBySlug(params?: ProductParams, config?: SWRConfigInterface) {
  return useApi<Product[]>(params ? path : null, getParams(params), config);
}
