import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { IRecipientContactInformation } from '../models/RecipientContactInformation';

export function getRecipientContactInformation(inkind: InkindPageAPIModel): IRecipientContactInformation {
  return {
    fullName: inkind.recipientFullName,
    address1: inkind.address,
    address2: inkind.address2,
    cityAndState: inkind.location,
    postcode: inkind.zip,
    email: inkind.recipientEmail,
  };
}
