import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import type { IGridProps } from '../Grid';
import { Grid } from '../Grid';

export interface ISelectListOptionsType<T = string> {
  value: T;
  label?: string;
  className?: string;
}

export interface ISelectListProps<T = string> extends IGridProps {
  multiple?: boolean;
  value?: T | T[];
  options?: ISelectListOptionsType<T>[];
  flex?: boolean; // force flexbox display (to center items)
  render: (item: ISelectListOptionsType<T>, active: boolean, disabled: boolean) => React.ReactNode;
  onChange?: (value: T | T[]) => void;
  disabled?: boolean;
}

export function SelectList<T = string>({
  value,
  multiple = false,
  disabled = false,
  className,
  render,
  options,
  onChange,
  flex,
  ...otherProps
}: ISelectListProps<T>): React.ReactElement {
  const bem = bemBlock('select-list');
  const blockClasses = bem(null, [{ flex }, { disabled }], [className]);

  function handleClick(item: ISelectListOptionsType<T>) {
    if (disabled) return;

    if (multiple) {
      const values = (value as T[]) || [];
      if (isSelected(item)) {
        onChange(values.filter(value => item.value !== value));
      } else {
        onChange(values.concat([item.value]));
      }
    } else {
      onChange(item.value);
    }
  }

  function isSelected(item: ISelectListOptionsType<T>) {
    if (multiple) return (value as T[])?.indexOf(item.value) > -1;
    return item.value === value;
  }

  return (
    <Grid {...otherProps} className={blockClasses}>
      {options?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => handleClick(item)}
            className={bem('item', [{ selected: isSelected(item) }], [item.className])}
          >
            {render(item, isSelected(item), disabled)}
          </div>
        );
      })}
    </Grid>
  );
}
