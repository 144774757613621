import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import type { IGenericCardProps } from '../PrivacySettingsSection/PrivacySettingsCard/PrivacySettingsCard';
import { PrivacySettingsCard } from '../PrivacySettingsSection/PrivacySettingsCard/PrivacySettingsCard';

export const sendGiftBoxCardBlockName = 'feature-checkbox-card';

interface IFeatureCheckboxCardProps extends IGenericCardProps {
  icon?: SvgrComponent;
  prepend?: React.ReactNode;
  title: string;
  description: React.ReactNode;
  id?: string;
  className: string;
  locked?: boolean;
  disabled?: boolean;
  onClickDisabled?: () => void;
}

const FeatureCheckboxCard: React.FC<IFeatureCheckboxCardProps> = ({
  value,
  onChange,
  icon,
  prepend,
  title,
  description,
  className,
  locked,
  disabled,
  onClickDisabled,
  id,
}): JSX.Element => {
  const bem = bemBlock(sendGiftBoxCardBlockName);

  return (
    <PrivacySettingsCard
      id={id}
      className={bem(null, [{ disabled: !value || locked }], className)}
      disabled={disabled}
      title={title}
      icon={icon}
      prepend={prepend}
      checkboxSize="xl"
      description={description}
      value={value}
      onChange={onChange}
      onClickDisabled={onClickDisabled}
      locked={locked}
    />
  );
};

export default FeatureCheckboxCard;
