import { logger } from '@gik/analytics/utils/logger';
import { timeoutDefaultValue } from '@gik/core/constants';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { InputNumber } from '@gik/ui/Input';
import { Modal, ModalButtonFooter } from '@gik/ui/Modal';
import type { IOpenMojiProps, OpenMojiKey } from '@gik/ui/OpenMoji';
import { RangeSlider } from '@gik/ui/RangeSlider';
import { Separator } from '@gik/ui/Separator';
import dynamic from 'next/dynamic';
import React from 'react';
import useLatest from 'react-use/lib/useLatest';

const OpenMoji = dynamic<IOpenMojiProps>(() => import('@gik/ui/OpenMoji').then(mod => mod.OpenMoji));

export interface ITipModalProps {
  isOpen: boolean;
  totalCheckoutPrice: number;
  onClose?: () => void;
  onSave?: (percentage: number, amount: number) => void;
  onChange?: (percentage: number, amount: number) => void;
  onInit?: (percentage: number, amount: number) => void;
  percentageValue?: number;
  defaultValue?: number;
  className?: string;
}

export default function TipModal({
  isOpen,
  totalCheckoutPrice,
  onClose,
  onSave,
  onChange,
  onInit,
  percentageValue,
  defaultValue = 10,
  className,
}: ITipModalProps): React.ReactElement {
  const bem = useBemCN('tip-modal');

  const [sliderValue, setSliderValue] = React.useState<number>(defaultValue);
  const [customValue, setCustomValue] = React.useState<string>();
  const [ignoreCustomValue, setIgnoreCustomValue] = React.useState<boolean>(false);
  const [ignoreSliderValue, setIgnoreSliderValue] = React.useState<boolean>(false);
  const latestIgnoreCustomValue = useLatest(ignoreCustomValue);

  const maxPercentage = 40;

  const [ready, setReady] = React.useState<boolean>(false);

  if (!Number.isInteger(defaultValue) || defaultValue > 40 || defaultValue < 0) {
    logger.error(`defaultValue prop ${defaultValue} must be a whole number in range 0-40.`);
  }

  if (
    percentageValue !== undefined &&
    (!Number.isInteger(percentageValue) || percentageValue > 40 || percentageValue < 0)
  ) {
    logger.error(`percentageValue prop ${percentageValue} must be a whole number in range 0-40 or undefined.`);
  }

  const _onClickSave = (percentage: number): void => {
    if (onSave) onSave(percentage, getAmount(percentage));
  };

  const _onClickCancel = (): void => {
    if (onClose) onClose();
  };

  const onSliderChange = (percentage: number): void => {
    if (ignoreSliderValue) return;

    setSliderValue(percentage);
    setIgnoreCustomValue(true);

    setTimeout(() => {
      setCustomValue(undefined);
      setTimeout(() => {
        setIgnoreCustomValue(false);
      }, timeoutDefaultValue);
    }, timeoutDefaultValue);

    if (onChange) onChange(percentage, getAmount(percentage));
  };

  const getAmount = (percentage: number): number => {
    return Math.round(totalCheckoutPrice * (percentage / 100)); // round up
  };

  const getAmountCallback = React.useCallback(getAmount, [totalCheckoutPrice]);
  const handleCustomValueChange = React.useCallback(
    (value: string) => {
      if (latestIgnoreCustomValue.current) return;

      // if (value === "0") {
      //   // setCustomValue(undefined);
      //   return;
      // }

      setIgnoreSliderValue(true);

      const numValue = parseFloat(value);

      if (isNaN(numValue)) {
        setCustomValue('0');
        setSliderValue(0);
        return;
      }

      setCustomValue(value);
      const newPercentage = Math.round((numValue / totalCheckoutPrice) * 100);
      setSliderValue(newPercentage);

      setTimeout(() => {
        setIgnoreSliderValue(false);
      });
    },
    [latestIgnoreCustomValue, totalCheckoutPrice]
  );

  const getEmojiNameFromPercentage = (percentage: number): OpenMojiKey => {
    switch (true) {
      case percentage < 1:
        return 'pleading-face'; // 0
      case percentage < 4:
        return 'slightly-smiling-face'; // 1 - 3
      case percentage < 7:
        return 'smiling-face-with-smiling-eyes'; // 4 - 6
      case percentage < 10:
        return 'grinning-face'; // 7 - 9
      case percentage < 11:
        return 'grinning-face-with-big-eyes'; // 10
      case percentage < 16:
        return 'grinning-face-with-smiling-eyes'; // 11 - 15
      case percentage < 25:
        return 'beaming-face-with-smiling-eyes'; // 16 - 24
      case percentage < 31:
        return 'hugging-face'; // 25 - 30
      case percentage < maxPercentage:
        return 'smiling-face-with-heart-eyes'; // 31 - 39
      case percentage >= maxPercentage:
        return 'smiling-face-with-hearts'; // 40
      default:
        return 'pleading-face';
    }
  };

  React.useEffect(() => {
    const percentage = percentageValue !== undefined ? percentageValue : defaultValue;
    if (!isOpen) {
      setTimeout(setSliderValue, 500, percentage);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const percentage = percentageValue !== undefined ? percentageValue : defaultValue;
    if (!ready) {
      setReady(true);
      if (onInit) onInit(percentage, getAmountCallback(percentage));
    }
  }, [ready, defaultValue, percentageValue, onInit, getAmountCallback]);

  const getEmoji = (percentage: number): React.ReactElement => {
    const emojiName = getEmojiNameFromPercentage(percentage);
    return <OpenMoji name={emojiName} />;
  };

  const cancelButton = (
    <Button key="cancel" onClick={_onClickCancel} variant="default">
      Cancel
    </Button>
  );

  const saveButton = (
    <Button key="save" onClick={() => _onClickSave(sliderValue)} variant="primary">
      Save
    </Button>
  );

  const content = (
    <>
      <p {...bem('title')}>Keep Give InKind Free</p>
      <div {...bem('content')}>
        <div>
          <p {...bem('description')}>
            We don&#39;t charge any fees to organize support through Give InKind, and your optional contribution makes
            that possible.
          </p>
        </div>
        <div {...bem('values')}>
          <span {...bem('amount')}>${`${customValue || getAmount(sliderValue)}`}</span>
          <span {...bem('percentage')}>&nbsp;{`(${sliderValue}%)`}</span>
        </div>
        <div {...bem('icon-container')}>{getEmoji(sliderValue)}</div>
        <div {...bem('slider-container')}>
          <RangeSlider
            min={0}
            max={maxPercentage}
            value={sliderValue}
            onChange={onSliderChange}
            step={1}
            defaultValue={defaultValue}
            trackColor={customValue ? '#9EABAD' : undefined}
            {...bem('slider', [{ customValue }])}
          />
          <Separator size="sm">or</Separator>
        </div>
        <InputNumber
          prepend={'$'}
          placeholder="Enter Custom Tip"
          min={0}
          {...bem('custom-value', null, 'tw-w-full')}
          value={customValue}
          onValueChange={handleCustomValueChange}
        />
      </div>
    </>
  );

  return (
    <Modal
      {...bem(null, [], ['dialog', className])}
      isOpen={isOpen}
      pages={[
        {
          title: '',
          content: <>{content}</>,
        },
      ]}
      pageNumber={1}
      onClose={onClose}
      header={<div />}
      footer={<ModalButtonFooter buttons={[cancelButton, saveButton]} />}
    />
  );
}
