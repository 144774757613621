import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const FlowersDisabledOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M17.3508 11.709C18.3513 10.6715 19.4082 9.38873 21.0719 8.68084C23.9447 7.45842 27.4902 7.16675 27.4902 7.16675C27.4902 7.16675 28.5639 12.0169 25.3809 15.2504C22.198 18.484 16.9978 17.7654 16.9978 17.7654"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M15.8718 10.0142C12.957 7.16675 6.64157 7.16675 6.64157 7.16675C6.64157 7.16675 5.18417 12.048 8.58477 15.3023C11.9854 18.5566 18.3008 17.743 18.3008 17.743C18.3008 17.743 18.7866 12.8617 15.8718 10.0142Z"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M20.9978 8.4999C19.6645 5.16659 16.9979 4.49998 16.9979 4.49998C16.9979 4.49998 14.3312 5.16659 12.9979 8.49996"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M11.7645 21.1667C14.0745 21.2767 15.6645 23.1666 15.6645 23.1666C15.6645 23.1666 14.0644 25.1667 11.6645 25.1667C9.26456 25.1667 7.66472 23.1666 7.66472 23.1666C7.66472 23.1666 9.45449 21.0566 11.7645 21.1667Z"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M18.3311 17.8334C18.3311 17.8334 17.5019 16.6663 17.0073 23.4883C16.851 25.6432 18.6659 27.1794 19.9332 27.8525C22.6779 29.3101 26.3311 27.8525 26.3311 27.8525"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M28.9978 4.5L4.9978 28.5" stroke="#EF5C84" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  }
);

// export const FlowersDisabled = React.forwardRef(
//   ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
//     return (
//
//     )})

export const FlowersDisabled = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <g clipPath="url(#clip0)">
          <path
            d="M6.99997 13.5C8.0442 13.5 9.08845 14.227 9.62663 14.6687C9.84008 14.844 9.84008 15.1561 9.62663 15.3313C9.08845 15.7731 8.04419 16.5 6.99997 16.5C5.95574 16.5 4.91152 15.7731 4.37336 15.3313C4.15993 15.1561 4.15992 14.844 4.37336 14.6688C4.91151 14.227 5.95573 13.5 6.99997 13.5Z"
            fill="#9EABAD"
          />
          <path
            d="M10.0002 8.74612C8.5781 5.70479 4.85128 5.1326 3.49929 5.02495C3.22402 5.00303 3.00237 5.2229 3.02003 5.49847C3.08837 6.56513 3.39242 9.05954 4.74524 10.1406C6.49026 11.5352 7.81068 12 10.0002 12C12.1898 12 13.5101 11.5352 15.2551 10.1406C16.608 9.05954 16.9121 6.56513 16.9804 5.49847C16.9981 5.2229 16.7764 5.00303 16.5011 5.02495C15.1491 5.1326 11.4223 5.70479 10.0002 8.74612Z"
            fill="#9EABAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 11C10.5523 11 11 11.4477 11 12C11 13.3467 11.3368 14.3162 11.9203 14.9445C12.4913 15.5595 13.4406 16 15 16C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18C13.0594 18 11.5087 17.4405 10.4547 16.3055C9.41318 15.1838 9 13.6533 9 12C9 11.4477 9.44772 11 10 11Z"
            fill="#9EABAD"
          />
          <path
            d="M7 4.49994C7 4.49994 8.3006 2.76581 9.69073 2.12337C9.88714 2.03261 10.1129 2.03261 10.3093 2.12337C11.6994 2.76581 13 4.49994 13 4.49994C13 4.49994 11 4.99994 10 6.49994C9 4.99994 7 4.49994 7 4.49994Z"
            fill="#9EABAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7071 0.292893C20.0976 0.683417 20.0976 1.31658 19.7071 1.70711L1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L18.2929 0.292893C18.6834 -0.0976311 19.3166 -0.0976311 19.7071 0.292893Z"
            fill="#EF5C84"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
