import bemBlock from '@gik/core/utils/bemBlock';
import { Checkbox } from '@gik/ui/Checkbox';
import { Image } from '@gik/ui/Image';
import React from 'react';

export interface IEventServicesTileProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value: number;
  image: string;
  compact?: boolean; // compact will hide text for unselected tiles
  selected?: boolean;
  horizontal?: boolean;
  multiple?: boolean;
}

export function EventServicesTile({
  image,
  label,
  value,
  selected,
  horizontal,
  multiple,
  compact,
  className,
}: IEventServicesTileProps) {
  const bem = bemBlock('event-services-tile');

  return (
    <div
      className={bem(
        null,
        [
          { horizontal },
          { vertical: !horizontal },
          { selected },
          { compact },
          { [`value-${value}`]: value !== undefined },
        ],
        className
      )}
    >
      <div className={bem('image-wrapper')}>
        <Image className={bem('image')} src={image} />
      </div>
      <span className={bem('label')}>{label}</span>

      {multiple && <Checkbox checked={selected} />}
    </div>
  );
}
