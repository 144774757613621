import type { ShippingDetails } from '@gik/checkout/types';
import type { FormProps, ValidationError } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import type { FormApi, SubmissionErrors } from 'final-form';
import type { FormikProps } from 'formik';
import React from 'react';
import { recipientAddressFormSchema } from './RecipientAddressFormSchema';

export interface IRecipientAddressFormProps extends FormProps {
  form?: FormikProps<object>;
  required?: boolean;
  formId?: string;
  onBeforeSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;
}

export interface IRecipientAddressFormValues {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

export interface IRecipientAddressFormErrors {
  address1: ValidationError;
  address2: ValidationError;
  city: ValidationError;
  state: ValidationError;
  postalCode: ValidationError;
  country: ValidationError;
}

export function RecipientAddressForm({
  initialValues,
  onSubmit,
  onBeforeSubmit,
  form,
  formId,
  required,
  ...otherProps
}: IRecipientAddressFormProps): React.ReactElement {
  async function handleSubmit(values: ShippingDetails, form: FormApi<object, object>): Promise<void> {
    if (onBeforeSubmit) onBeforeSubmit(values, form);
    if (onSubmit) onSubmit(values, form);
  }

  const fields = () => (
    <>
      <FormField name="country" inline />
      <FormField name="address1" />
      <FormField name="address2" />
      <section>
        <FormField name="city" className="tw-flex-1" />
        <FormField name="state" />
        <FormField name="postalCode" />
      </section>
    </>
  );

  if (form) return fields();

  return (
    <Form
      id={formId}
      onSubmit={handleSubmit}
      schema={recipientAddressFormSchema(required)}
      initialValues={initialValues}
      vertical
      validateOnBlur={false}
      restoreAfterUpdate
      {...otherProps}
      render={fields}
    />
  );
}
