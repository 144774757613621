import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import type { IUpdateAnnouncementPayload } from '@gik/api/calendar/calendarAnnouncements';
import { deleteEvent, updateEvent } from '@gik/api/calendar/calendarEvents';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { translationKeys as eventTranslationKeys } from '@gik/calendar/components/EventForm/i18n/en';
import type { ICalendarAnnouncement, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import { calendarEventMutatedAnalytics } from '@gik/calendar/utils/CalendarAnalytics';
import bemBlock from '@gik/core/utils/bemBlock';
import { dateTimeFormat } from '@gik/core/utils/DateTimeUtils';
import { openIntercomWindow } from '@gik/core/utils/Intercom';
import { convertDateFromBackend } from '@gik/core/utils/l10n';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { timeDataFormat } from '@gik/l10n';
import { Button } from '@gik/ui/Button';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeToInt } from 'time-number';
import { translationKeys as eventFormTranslationKeys } from '../EventForm/i18n/en';
import { calendarAnnouncementFormValuesToPayload } from '../EventForm/utils';
import type { ICalendarAnnouncementFormProps, ICalendarAnnouncementFormValues } from './CalendarAnnouncementForm';
import { CalendarAnnouncementForm } from './CalendarAnnouncementForm';
import { translationKeys } from './i18n/en';

export type CalendarEventFormEditMode = 'instance' | 'series';

export interface ICalendarEditAnnouncementFormProps extends ICalendarAnnouncementFormProps {
  entry: ICalendarAnnouncement;
  event: ICalendarEvent;
  onSuccess?: () => void;
  onDelete?: () => void;
}

function CalendarEditAnnouncementFormComp({
  buttonsPortal,
  onSuccess = noop,
  onDelete = noop,
  entry,
  event,
  className,
  ...otherProps
}: ICalendarEditAnnouncementFormProps): React.ReactElement {
  const bem = bemBlock('calendar-edit-announcement-form');
  const { t } = useTranslation();

  const { data: eventTypes } = useCalendarEventTypes();

  const [errorMessage, setErrorMessage] = React.useState<string>();
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const [editMode, setEditMode] = React.useState<CalendarEventFormEditMode>();
  // const [startDate, setStartDate] = React.useState<Moment>();
  // const [endDate, setEndDate] = React.useState<Moment>();

  const [initialValues, setInitialValues] = React.useState<ICalendarAnnouncementFormValues>();

  const savingRef = React.useRef<boolean>(false);
  function setSaving(value: boolean) {
    savingRef.current = value;
  }

  const deletingRef = React.useRef<boolean>(false);
  function setDeleting(value: boolean) {
    deletingRef.current = value;
  }

  const startDate = React.useMemo(() => {
    return convertDateFromBackend(editMode === 'instance' ? event.startsAt : entry.startsAt);
  }, [editMode, entry, event]);

  const endDate = React.useMemo(() => {
    return convertDateFromBackend(editMode === 'instance' ? event.endsAt : entry.endsAt);
  }, [editMode, entry, event]);

  const instanceStartTime = React.useMemo(() => {
    return startDate.isValid() ? startDate?.format(timeDataFormat) : null;
  }, [startDate]);

  const instanceEndTime = React.useMemo(() => {
    return endDate.isValid()
      ? endDate?.format(timeDataFormat)
      : startDate.clone().add(1, 'hour').format(timeDataFormat);
  }, [startDate, endDate]);

  /**
   * populate initial values
   */
  React.useEffect(() => {
    (async () => {
      const RRule = (await import('rrule')).default;
      const RRuleFreq = (await import('rrule')).Frequency;
      const rrulestr = (await import('rrule')).rrulestr;
      let weekdays: boolean[];
      let repeat = -1;

      if (entry.repeat) {
        const rrule = rrulestr(entry.repeat.toString());
        // freq = rrule.options.freq;

        if (rrule.options.freq === RRuleFreq.WEEKLY && rrule.origOptions.byweekday) {
          // custom repeat
          repeat = 10;
          weekdays = [false, false, false, false, false, false, false];
          rrule.options.byweekday.map(weekday => {
            weekdays[weekday] = true;
          });
        } else if (rrule.options.freq === RRuleFreq.DAILY && rrule.origOptions.interval === 2) {
          // every other day
          repeat = 11;
        } else {
          // normal repeat
          repeat = rrule.options.freq;
        }
      }

      const _initialValues: ICalendarAnnouncementFormValues = {
        title: entry.title,
        description: entry.description,
        announcementTypeId: entry.announcementTypeId,
        allDay: entry.allDay,
        sendEmailToPageFollowers: entry.sendEmailToPageFollowers || false,
        startDate: startDate?.format(dateTimeFormat),
        endDate: endDate.isValid() ? endDate?.format(dateTimeFormat) : startDate?.format(dateTimeFormat),
        startTime: instanceStartTime !== null && instanceStartTime !== undefined ? timeToInt(instanceStartTime) : null,
        endTime: instanceEndTime !== null && instanceEndTime !== undefined ? timeToInt(instanceEndTime) : null,
        repeat: editMode === 'instance' ? -1 : repeat,
        weekdays,
      };

      setInitialValues(_initialValues);
    })();
  }, [editMode, startDate, endDate, entry, instanceEndTime, instanceStartTime]);

  // React.useEffect(() => {
  //   setStartDate();
  //   setEndDate(entry.endsAt ? convertDateFromBackend(editMode === 'instance' ? event.endsAt : entry.endsAt) : null);
  // }, [event, entry, editMode]);

  // const announcementEventType = eventTypes.find(item => item.slug === 'announcement');

  async function handleSubmit(values: ICalendarAnnouncementFormValues) {
    let response: boolean;

    const payload: IUpdateAnnouncementPayload = {
      ...(await calendarAnnouncementFormValuesToPayload({ ...values })),
      inkindRouteId,
      instanceDate: editMode === 'instance' ? event.startsAt : null,
      typeId: CalendarEventTypeIds.Announcement,
    };

    if (!payload.announcementTypeId) {
      throw new Error(`announcementTypeId is "${payload.announcementTypeId}"`);
    }

    setErrorMessage(null);
    setSaving(true);

    try {
      response = await updateEvent(inkindRouteId, entry.id, payload);
    } catch (err) {
      logger.error(err);
      setErrorMessage(err.message);
      setSaving(false);
      return false;
    }

    if (response) {
      calendarEventMutatedAnalytics(
        AnalyticsEvents.eventUpdated,
        entry.id,
        payload.allDay,
        payload.startsAt,
        payload.endsAt,
        payload.repeat,
        payload.description,
        payload.title,
        payload.typeId,
        eventType.name,
        payload.sendEmailToPageFollowers,
        undefined,
        {
          announcementTypeId: payload.announcementTypeId,
        }
      );
    }

    setSaving(false);
    onSuccess();
    return response;
  }

  async function handleDelete() {
    let shouldDelete = false;

    const editModeName =
      editMode === 'series' ? t(eventFormTranslationKeys.seriesType) : t(translationKeys.announcement);

    const dialogTitle = t(eventTranslationKeys.deleteRequestConfirmTitle, { type: editModeName });

    shouldDelete = await UI.confirm(
      t(eventTranslationKeys.deleteRequestConfirm, { type: editModeName.toLocaleLowerCase() }),
      {
        title: dialogTitle,
        okButtonProps: { variant: 'danger' },
        okText: t(commonTranslationKeys.delete),
      }
    );

    let response: boolean;
    if (!shouldDelete) return;

    setDeleting(true);

    try {
      response = await deleteEvent(inkindRouteId, entry.id, {
        instanceDate: editMode === 'instance' ? event?.startsAt : undefined,
      });
    } catch (err) {
      logger.error(err);
      setErrorMessage(err.message);
      setDeleting(false);
      return;
    }

    setDeleting(false);
    if (response === false) return;
    onDelete();
  }

  function handleOpenIntercom() {
    openIntercomWindow();
  }

  function updateEditMode(type: CalendarEventFormEditMode) {
    setEditMode(type);

    let dialogTitle = t(eventTranslationKeys.editDialogTitle);
    if (type === 'instance') dialogTitle = t(eventTranslationKeys.editInstance);
    if (type === 'series') dialogTitle = t(eventTranslationKeys.editSeries);
    // FIXME: better way to update the dialog title
    const titleEl = document.querySelector('.gik-calendar-edit-announcement .gik-modal-header__title');
    if (titleEl) titleEl.innerHTML = dialogTitle;
  }

  // get selected eventType
  const eventType = eventTypes?.find(eventType => eventType.id === entry.typeId);

  // choose editMode if this is a repeated event
  if (entry && entry.repeat && editMode === undefined) {
    return (
      <div className={bem('edit-choice', null, className)}>
        <div className="close-btn" onClick={close}></div>

        <Button onClick={() => updateEditMode('series')}>{t(eventTranslationKeys.editSeries)}</Button>
        <p>{t(translationKeys.editSeriesDescription)}</p>
        <hr />
        <Button onClick={() => updateEditMode('instance')}>{t(eventTranslationKeys.editInstance)}</Button>
        <p>{t(eventTranslationKeys.editInstanceDescription)}</p>
      </div>
    );
  }

  if (!eventTypes) return <LoadingSpinner center />;

  if (!eventType) {
    throw new Error(`EventType '${eventType}' not recognized`);
  }

  if (!initialValues) return null;

  return (
    <div className={bem(null, null, className)}>
      <CalendarAnnouncementForm
        initialValues={{ ...initialValues }}
        buttonsPortal={buttonsPortal}
        errorMessage={errorMessage}
        buttons={({ submitting }, formId: string) => {
          const isSubmitting = submitting || savingRef.current || deletingRef.current;
          return (
            <div className={'gik-form__actions-new'}>
              <section>
                <Button
                  variant="danger"
                  onClick={() => handleDelete()}
                  loading={deletingRef.current}
                  disabled={isSubmitting}
                  hideLabelOnMobile
                  prepend={<SvgIcon Icon={TrashIcon} />}
                >
                  {t(commonTranslationKeys.delete)}
                </Button>
                <Button variant={'default-light'} onClick={handleOpenIntercom} circle>
                  <SvgIcon Icon={InterrogationRed} />
                </Button>
              </section>
              <section>
                <Button type="submit" form={formId} loading={savingRef.current} disabled={isSubmitting}>
                  {t(commonTranslationKeys.save)}
                </Button>
              </section>
            </div>
          );
        }}
        {...otherProps}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export const CalendarEditAnnouncementForm = withComponentErrorBoundary(CalendarEditAnnouncementFormComp);
