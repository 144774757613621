import type { Hit } from '@algolia/client-search';
import { useGiftcardCategories } from '@gik/api/products/giftcardCategories';
import { GiftCardTile } from '@gik/calendar';
import getProducts from '@gik/checkout/api/products';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import type { ProductAlgoliaObject, ProductSearchFilter } from '@gik/search/components';
import { useSearch } from '@gik/search/components';
import { Button } from '@gik/ui/Button';
import type { IGridProps } from '@gik/ui/Grid';
import { Grid } from '@gik/ui/Grid';
import { Input } from '@gik/ui/Input';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { Select } from '@gik/ui/Select';
import { TabbedNav } from '@gik/ui/TabbedNav';
import { UI } from '@gik/ui/UIManager';
import arrayMove from 'array-move';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { translationKeys } from './i18n/en';
import { useBrandCategories } from '@gik/api/products/brandCategories';

export interface IGiftCardsEditorProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  maxItems?: number;
  value?: Product[];
  onChange?: (value: Product[]) => void;
  onSave?: (value: Product[]) => void;
  buttonsPortal?: () => HTMLElement;
  multiSelect?: boolean;
  source?: 'gift-card-category' | 'brand-category';
}

export function GiftCardsEditor({
  onChange,
  onSave,
  className,
  value = [],
  maxItems = 4,
  buttonsPortal,
  multiSelect = true,
  source = 'gift-card-category',
  ...otherProps
}: IGiftCardsEditorProps): React.ReactElement {
  const bem = bemBlock('giftcards-editor');
  const { t } = useTranslation();
  const isSm = useBreakpoint(Breakpoint.SM);

  const maxCards = 4;

  const [submitting, setSubmitting] = React.useState<boolean>();
  const [activeTabId, setActiveTabId] = React.useState<string>();
  const [selectedCards, setSelectedCards] = React.useState<Hit<ProductAlgoliaObject>[]>(
    (value?.map(p => ({
      objectID: p.id.toString(),
      title: p.name,
      'img-offsite_image_small': p.thumbnails?.[0],
      'img-thumbnail': p.thumbnails?.[0],
      gridImage: p.gridImage || p.thumbnails?.[0],
    })) as Hit<ProductAlgoliaObject>[]) || []
  );
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [ready, setReady] = React.useState<boolean>();

  const { data: giftCardCategories } = useGiftcardCategories({
    disabled: source != 'gift-card-category',
  });
  const brandCategories = useBrandCategories({
    disabled: source != 'brand-category',
  });

  // see https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
  const filters: ProductSearchFilter[] = React.useMemo(() => {
    // two filters in an array means they are ORed together (i.e. pg_ispgproduct OR tc_istcproduct)
    const giftCardFilter: ProductSearchFilter[] = [
      [
        {
          key: 'pg_ispgproduct',
          value: 1,
        },
        // {
        //   key: 'tc_istcproduct',
        //   value: 1,
        // },
        {
          key: 'gy_isgyproduct',
          value: 1,
        },
      ],
    ];

    if (source === 'gift-card-category') {
      return activeTabId
        ? [
            // activeTabId AND (pg_ispgproduct OR tc_istcproduct)
            {
              key: 'gift-card-category-ids',
              value: activeTabId,
            },
            giftCardFilter,
          ]
        : giftCardFilter;
    }

    return activeTabId
      ? [
          [
            {
              key: 'brand-category-ids',
              value: activeTabId,
            },
            ...(brandCategories
              ?.filter(c => c.id.toString() === activeTabId.toString())?.[0]
              .children.map(c => ({
                key: 'brand-category-ids',
                value: c.id,
              })) ?? []),
          ],
          giftCardFilter,
        ]
      : giftCardFilter;
  }, [activeTabId, brandCategories, source]);

  const results = useSearch<ProductAlgoliaObject>(searchQuery, 'products', filters, 1500, undefined, 0);

  const giftCards = React.useMemo(() => {
    return results?.hits || [];
  }, [results]);

  const [_loading, setLoading] = React.useState(false);
  async function handleChange(hits: Hit<ProductAlgoliaObject>[]) {
    if (!onChange) return;

    // find the product by id

    setLoading(true);
    const products = await getProducts(hits.map(h => h.objectID));
    setLoading(false);

    if (!products) return;

    onChange(products);
  }

  async function _handleSave(hits: Hit<ProductAlgoliaObject>[]) {
    if (!onSave) return;

    setSubmitting(true);

    // find the product by id
    const products = await getProducts(hits.map(h => h.objectID));
    if (!products) return;

    onSave(products);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    const newSel = arrayMove(selectedCards, oldIndex, newIndex);
    setSelectedCards(newSel);
    handleChange(newSel);
  }

  function handleDelete(item: Hit<ProductAlgoliaObject>) {
    // do not allow the last card to be deleted
    if (selectedCards?.length === 1) return;

    const newSel = selectedCards.filter(hit => hit.objectID !== item.objectID);
    setSelectedCards(newSel);
    handleChange(newSel);
  }

  function handleAdd(item: Hit<ProductAlgoliaObject>) {
    if (selectedCards?.length >= maxItems) {
      return UI.notifyError(t(translationKeys.maxCardsNotification).toString());
    }
    const newSel = multiSelect ? selectedCards.concat([item]) : [item];
    setSelectedCards(newSel);
    handleChange(newSel);
  }

  function handleSave() {
    // saving an empty list of giftcards should not be allowed
    if (!selectedCards.length) {
      UI.notifyError(t(translationKeys.minCardsNotification).toString());
      return;
    }

    _handleSave(selectedCards);
  }

  React.useEffect(() => {
    if (source != 'gift-card-category' && brandCategories && activeTabId === undefined)
      return void setActiveTabId(brandCategories?.[0].id.toString());

    // select the most popular category by default
    const mostPopularTabId = 41862;
    if (giftCardCategories && activeTabId === undefined) setActiveTabId(mostPopularTabId.toString());
  }, [giftCardCategories, activeTabId, source, brandCategories]);

  React.useEffect(() => {
    // this timeout is here to prevent the drawer animation from stuttering
    // let the drawer animate first before we start rendering the content
    setTimeout(() => {
      setReady(true);
    }, 300);
  }, []);

  const remainingItems = React.useMemo(
    () =>
      multiSelect ? giftCards.filter(item => !selectedCards?.find(sel => sel.objectID === item.objectID)) : giftCards,
    [giftCards, selectedCards, multiSelect]
  );

  const isLoading =
    (source === 'gift-card-category' && !giftCardCategories) ||
    (source === 'brand-category' && !brandCategories) ||
    !ready ||
    _loading;

  const categoriesSorted = React.useMemo(() => {
    if (source === 'brand-category') return brandCategories;

    return giftCardCategories
      ?.concat([
        {
          id: 0,
          name: t(translationKeys.allCategoryName),
          acf: {
            sort_order: '0',
          },
        },
      ])
      .sort((a, b) => {
        return parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order);
      });
  }, [brandCategories, giftCardCategories, source, t]);

  if (isLoading) return <LoadingSpinner center />;

  const navItems = categoriesSorted.map(item => ({
    title: item.name,
    id: item.id,
  }));

  const navOptions = categoriesSorted.map(item => ({
    label: item.name,
    value: item.id.toString(),
  }));

  const SortableGrid = SortableContainer<IGridProps>(Grid);
  const SortableItem = SortableElement(({ hit }: { hit: Hit<ProductAlgoliaObject> }) => (
    <GiftCardTile
      name={hit.title}
      thumbnail={hit['gridImage'] || hit['img-offsite_image_small']}
      className={bem('drag-item')}
      deleteButton={selectedCards.length > 1}
      onDelete={() => handleDelete(hit)}
    />
  ));

  const buttons = (
    <div className={'gik-drawer-actions gik-drawer-actions--centered'}>
      <Button loading={submitting} onClick={handleSave}>
        {t(commonTranslationKeys.save).toString()}
      </Button>
    </div>
  );

  let emptyCardsCount = maxCards;
  if (selectedCards) emptyCardsCount = maxCards - selectedCards?.length;

  return (
    <div className={bem(null, [{ disabled: submitting }], className)} {...otherProps}>
      {multiSelect && (
        <div className={bem('selection')}>
          <span className={bem('sel-title')}>{t(translationKeys.editorPickerTitle).toString()}</span>
          <p className={bem('sel-description')}>{t(translationKeys.editorPickerDescription).toString()}</p>
          <SortableGrid className={bem('sel-grid')} distance={10} axis="xy" onSortEnd={onSortEnd}>
            {selectedCards?.map((hit, index: number) => {
              return <SortableItem key={hit.objectID} index={index} hit={hit} />;
            })}
            {emptyCardsCount > 0 &&
              [...Array(emptyCardsCount)].map(count => {
                return (
                  <div key={count} className={bem('empty-tile')}>
                    <main></main>
                    <footer>hidden</footer>
                  </div>
                );
              })}
          </SortableGrid>
          <div className={bem('counter-wrapper')}>
            <div className={bem('counter')}>
              {selectedCards?.length}/{maxItems}
            </div>
          </div>
        </div>
      )}
      <div className={bem('browser')}>
        <div className={bem('filters')}>
          <Input
            value={searchQuery}
            onValueChange={setSearchQuery}
            placeholder={t(translationKeys.searchPlaceholder)}
          />
        </div>

        {isSm && navItems && (
          <TabbedNav
            value={parseInt(activeTabId)}
            onChange={_item => setActiveTabId(_item.id.toString())}
            items={navItems}
            fadeSides
            arrows={isSm && navItems.length > 1}
          />
        )}
        {!isSm && (
          <Select
            className={bem('select')}
            loading={!navItems}
            options={navOptions}
            value={activeTabId}
            onChange={value => setActiveTabId(value)}
          />
        )}

        {giftCards ? (
          <div className={bem('grid-wrapper')}>
            <Grid className={bem('grid')}>
              {remainingItems?.map(item => (
                <GiftCardTile
                  key={item.objectID}
                  name={item.title}
                  thumbnail={item['gridImage'] || item['img-offsite_image_small']}
                  addButton={multiSelect}
                  onAdd={() => handleAdd(item)}
                  onClickItem={() => handleAdd(item)}
                  highlight={!multiSelect && selectedCards.includes(item)}
                />
              ))}
            </Grid>
            {remainingItems?.length === 0 && (
              <div className={bem('grid-empty')}>{t(translationKeys.noGiftCardsInFilter).toString()}</div>
            )}
          </div>
        ) : (
          <LoadingSpinner center />
        )}
      </div>

      {multiSelect && renderPortal(buttons, buttonsPortal)}
    </div>
  );
}
