import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import { privacyStepSchema } from '@gik/create/formSchemas';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import type { PrivacySettingsValues } from '@gik/inkind-page/components/InkindPageSettingsModal/PrivacySettingsSection/PrivacySettingsSection';
import { PrivacySettingsSection } from '@gik/inkind-page/components/InkindPageSettingsModal/PrivacySettingsSection/PrivacySettingsSection';
import { Form } from '@gik/ui/Form';
import React from 'react';

export interface IPrivacyStepProps {
  onChange?: (values: IPrivacyStepFormValues) => void;
}

const blockName = 'privacy-step';

export interface IPrivacyStepFormValues extends PrivacySettingsValues {}

export default function PrivacyStep({ onChange }: IPrivacyStepProps) {
  const bem = bemBlock(blockName);

  const formValues = useCreateFlowStore(state => state.formValues);
  const { privacyFormRef } = React.useContext(CreatePageContext);

  useFormNavigationObserver(privacyFormRef);

  // TODO: tie form and component together
  return (
    <Form
      ref={privacyFormRef}
      initialValues={formValues}
      className={bem()}
      schema={privacyStepSchema}
      onChange={onChange}
      trackingId={AnalyticsEvents.CreateStarted}
      render={_props => (
        <PrivacySettingsSection onChange={onChange} values={formValues as PrivacySettingsValues} variant="create" />
      )}
    />
  );
}
