import bemBlock from '@gik/core/utils/bemBlock';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import React from 'react';
import type { IDatePickerProps } from '.';
import { DatePicker } from '../DatePicker';
import { SvgIcon } from '../SvgIcon/SvgIcon';

export interface InputDatePickerProps extends IDatePickerProps {}

/**
 * GIK Input component with a datepicker
 */
export function InputDatePicker({ className, ...otherProps }: InputDatePickerProps): React.ReactElement {
  const bem = bemBlock(`input-datepicker`);
  return (
    <div className={bem(null, null, className)}>
      <DatePicker {...otherProps} />
      <SvgIcon className={bem('icon')} Icon={CalendarIcon} />
    </div>
  );
}
