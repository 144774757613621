import type { LoginProvider } from '@gik/auth/utils/LoginProvider';
import type { Product } from '@gik/core/models/gik/Product';
import type WordpressSituation from '@gik/core/models/wordpress/WordpressSituation';
import type { IWordpressCreateFlowCategory } from '@gik/core/models/wordpress/WordpressSituation';
import type { EmailTakenStatus } from '@gik/create/enums/EmailTakenStatus';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import { DeterminationType } from '@gik/ui/LoadingLinear';
import type Slider from 'react-slick';
import type { GetState, SetState } from 'zustand';
import create from 'zustand';
import type { ICreateFlowFormValues } from '../models/CreateFlowFormValues';

export type LegalDrawerType = 'termsOfService' | 'privacyPolicy';

const initialFormValues: Partial<ICreateFlowFormValues> = {
  phoneCallsEnabled: true,
  flowersEnabled: true,
  textMessagesEnabled: true,
  visitorsEnabled: true,
  pageSharingEnabled: true,
  showPageEnabled: true,
  featurePageEnabled: false,
  searchEnginesEnabled: true,
  alternateNumber: '',
  alternateNumberOwnerName: '',
  situations: [],
  [RecipientInfoStepInputNames.AddAsPageOrganizer]: true,
  country: 'US',
};

export interface ICreateFlowStore {
  nextDisabled?: boolean;
  finishButtonSubmitting?: boolean;
  createProgress: number;
  createProgressType: DeterminationType;
  updateCreateProgress(): void;
  useIndeterminateProgress(): void;
  useDeterminateProgress(): void;
  setFinishButtonSubmitting?(finishButtonSubmitting: boolean): void;
  formValues?: Partial<ICreateFlowFormValues>;
  resetFormValues(): void;
  setNextDisabled?(disabled: boolean): void;
  setFormValues?(formValues: ICreateFlowFormValues): void;
  stepIndex: number;
  setStepIndex(stepIndex: number): void;
  subStepIndex: number;
  setSubStepIndex(subStepIndex: number): void;
  clearWishlistSuggestions(): void;
  suggestedProducts: Product[];
  setSuggestedProducts(products: Product[]): void;
  situations: WordpressSituation[];
  setSituations(situations: WordpressSituation[]): void;
  situationCategories: IWordpressCreateFlowCategory[];
  setSituationCategories(categories: IWordpressCreateFlowCategory[]): void;
  emailTakenStatus: EmailTakenStatus;
  setEmailTakenStatus(emailTakenStatus: EmailTakenStatus): void;
  selectedCategoryIndex: number;
  setSelectedCategoryIndex(selectedCategoryIndex: number): void;
  situationCarouselRef: Slider;
  setSituationCarouselRef(situationCarouselRef: Slider): void;
  legalTextDrawerType: LegalDrawerType;
  legalTextDrawerTitle: string;
  legalTextDrawerCopy: string;
  openLegalTextDrawer(title: string, copy: string, drawerType: LegalDrawerType): void;
  closeLegalTextDrawer(): void;
  isPageNameDrawerOpen: boolean;
  openPageNameDrawer(): void;
  closePageNameDrawer(): void;
  newUserLoginProvider: LoginProvider;
  setNewUserLoginProvider(newUserLoginProvider: LoginProvider): void;
}

/* TODO: remove when migration is finished */
export const useCreateFlowStore = create<ICreateFlowStore>(
  (set: SetState<ICreateFlowStore>, get: GetState<ICreateFlowStore>) => ({
    nextDisabled: true, // start as disabled until the form is valid
    createProgress: 0,
    createProgressType: DeterminationType.Determinate,
    formValues: initialFormValues,
    updateCreateProgress: () =>
      set(state => {
        return {
          createProgress: state.createProgress + 1,
        };
      }),
    useIndeterminateProgress: () => set(() => ({ createProgressType: DeterminationType.Indeterminate })),
    useDeterminateProgress: () => set(() => ({ createProgressType: DeterminationType.Determinate })),
    resetFormValues: () =>
      set(() => {
        return {
          formValues: initialFormValues,
          stepIndex: 0,
          subStepIndex: 0,
        };
      }),
    setNextDisabled: (disabled: boolean) =>
      set(() => {
        return {
          nextDisabled: disabled,
        };
      }),
    setFinishButtonSubmitting: (finishButtonSubmitting: boolean) =>
      set(() => {
        return {
          finishButtonSubmitting,
        };
      }),
    setFormValues: (formValues: ICreateFlowFormValues) =>
      set(() => {
        return {
          formValues,
        };
      }),
    stepIndex: 0,
    setStepIndex: (stepIndex: number) =>
      set(() => {
        return {
          stepIndex,
        };
      }),
    subStepIndex: 0,
    setSubStepIndex: (subStepIndex: number) =>
      set(() => {
        return {
          subStepIndex,
        };
      }),
    clearWishlistSuggestions() {
      const formValues = get().formValues;
      delete formValues[FeatureWishlistInputNames.WishlistItems];

      set(() => {
        return {
          formValues,
        };
      });
    },
    suggestedProducts: null,
    setSuggestedProducts: (suggestedProducts: Product[]) =>
      set(() => {
        return {
          suggestedProducts,
        };
      }),
    situations: null,
    setSituations: (situations: WordpressSituation[]) =>
      set(() => {
        return {
          situations,
        };
      }),
    situationCategories: null,
    setSituationCategories: (situationCategories: IWordpressCreateFlowCategory[]) =>
      set(() => {
        return {
          situationCategories,
        };
      }),

    emailTakenStatus: null,
    setEmailTakenStatus: (emailTakenStatus: EmailTakenStatus) =>
      set(() => {
        return {
          emailTakenStatus,
        };
      }),
    selectedCategoryIndex: null,
    setSelectedCategoryIndex: (selectedCategoryIndex: number) =>
      set(() => {
        return {
          selectedCategoryIndex,
        };
      }),
    situationCarouselRef: null,
    setSituationCarouselRef: (situationCarouselRef: Slider) =>
      set(() => {
        return {
          situationCarouselRef,
        };
      }),
    legalTextDrawerType: null,
    legalTextDrawerTitle: null,
    legalTextDrawerCopy: null,
    openLegalTextDrawer: (title: string, copy: string, drawerType: LegalDrawerType) =>
      set(() => {
        return {
          legalTextDrawerType: drawerType,
          legalTextDrawerTitle: title,
          legalTextDrawerCopy: copy,
        };
      }),
    closeLegalTextDrawer: () =>
      set(() => {
        return {
          legalTextDrawerType: null,
          legalTextDrawerTitle: null,
          legalTextDrawerCopy: null,
        };
      }),
    isPageNameDrawerOpen: null,
    openPageNameDrawer: () =>
      set(() => {
        return {
          isPageNameDrawerOpen: true,
        };
      }),
    closePageNameDrawer: () =>
      set(() => {
        return {
          isPageNameDrawerOpen: false,
        };
      }),
    newUserLoginProvider: undefined,
    setNewUserLoginProvider: newUserLoginProvider =>
      set(() => {
        return {
          newUserLoginProvider,
        };
      }),
  })
);
