import { useBemCN } from '@gik/core/utils/bemBlock';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import type { UISize } from '../types';

export interface IArrowButtonProps {
  disabled?: boolean;
  onClick(ev: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
  size?: UISize;
}

interface IInternalArrowButtonProps extends IArrowButtonProps {
  elementName: string;
  icon: SvgrComponent | React.FC;
}

function ArrowButton({
  disabled,
  elementName,
  icon,
  className,
  onClick,
  size,
}: IInternalArrowButtonProps): React.ReactElement {
  const bem = useBemCN('arrow-button');
  // TODO: move disabled into modifiers
  return (
    <div
      {...bem(
        elementName,
        [
          {
            [`size-${size}`]: size,
          },
        ],
        [{ disabled }, className]
      )}
      onClickCapture={(ev: React.MouseEvent<HTMLDivElement>) => {
        !disabled && onClick?.(ev);
      }}
    >
      <SvgIcon Icon={icon} size={size} />
    </div>
  );
}

export function ArrowButtonNext(props: IArrowButtonProps) {
  return <ArrowButton icon={ChevronRightIcon} elementName="next" {...props} />;
}

export function ArrowButtonPrev(props: IArrowButtonProps) {
  return <ArrowButton icon={ChevronLeftIcon} elementName="prev" {...props} />;
}
