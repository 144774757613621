import { ErrorBoundary } from '@gik/core/components/ErrorBoundary';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { getTailwindConfig } from '@gik/core/utils/tailwind';
import React from 'react';
import type { IconSize } from '../types';

const blockName = `svg-icon`;

export interface IBaseIconSetType {
  Icon: SvgrComponent;
  name?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?(ev: React.MouseEvent): void;
  onHover?(ev: React.MouseEvent): void;
}

interface BaseSvgIconProps extends IBaseIconSetType {
  size?: IconSize;
  strikeOut?: boolean;
}

export type SvgIconProps = BaseSvgIconProps;

function SvgIconComp({
  Icon,
  size = 'base',
  name,
  className,
  strikeOut,
  ...otherProps
}: SvgIconProps): React.ReactElement {
  const bem = useBemCN(blockName);

  const twConfig = getTailwindConfig();
  const parsedSize = twConfig.theme.iconSize[size];

  const Value = (
    <Icon
      width={parsedSize}
      height={parsedSize}
      {...bem(
        null,
        [
          {
            [name as string]: name,
          },
          { strikeOut },
        ],
        className
      )}
      {...otherProps}
    />
  );

  if (strikeOut) {
    return (
      <div {...bem('wrapper')}>
        {Value}
        <div {...bem('strike-line')}></div>
      </div>
    );
  }

  return <ErrorBoundary fallback={() => <div>-</div>}>{Value}</ErrorBoundary>;
}

// only re-render if the name changed
export const SvgIcon = React.memo(SvgIconComp, (prevProps, nextProps) => {
  return prevProps.name === nextProps.name && prevProps.Icon === nextProps.Icon;
});
