import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'claimed-items';

// TODO: fill in missing translations
const translations = {
  claimed: "Support you've claimed",
  history: 'Your support history',
  emptyUpcomingClaims: 'Items you claim on a Care Calendar will appear here for you to easily view and manage.',
  emptyHistoryClaims: "Items you've claimed in the past will appear here, to provide continued access to useful info.",
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
