import bemBlock from '@gik/core/utils/bemBlock';
import type { HTMLAttributes } from 'react';
import React from 'react';

export interface IModalButtonFooterProps extends HTMLAttributes<HTMLDivElement> {
  buttons?: React.ReactNode;
  custom?: boolean;
  vertical?: boolean;
  centeredButtons?: boolean;
  smallButtonsOnDesktop?: boolean;
}

export function ModalButtonFooter({
  buttons,
  children,
  custom,
  vertical,
  className,
  centeredButtons = true,
  smallButtonsOnDesktop = true,
  ...otherProps
}: IModalButtonFooterProps): React.ReactElement {
  const bem = bemBlock('modal-button-footer');

  return (
    <div
      className={bem(
        null,
        [
          { vertical },
          { custom },
          { empty: !buttons && !children },
          { 'centered-buttons': centeredButtons },
          { 'small-buttons-desktop': smallButtonsOnDesktop },
        ],
        className
      )}
      {...otherProps}
    >
      {buttons}
      {children}
    </div>
  );
}
