import bemBlock from '@gik/core/utils/bemBlock';
import { Image } from '@gik/ui/Image';
import React from 'react';

export interface ICalendarEventTypeTileProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  image: string;
  selected?: boolean;
  horizontal?: boolean;
  variant?: string;
}

export function CalendarEventTypeTile({
  image,
  label,
  selected,
  horizontal,
  className,
  variant = 'neutral',
}: ICalendarEventTypeTileProps) {
  const bem = bemBlock('calendar-event-type-tile');

  return (
    <div
      className={bem(
        null,
        [{ horizontal }, { vertical: !horizontal }, { selected }, { [`variant-${variant}`]: variant }],
        [className, `gik-calendar-event-type-theme--${variant}`]
      )}
    >
      <Image className={bem('image')} src={image} />
      <span className={bem('label')}>{label}</span>
    </div>
  );
}
