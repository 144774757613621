import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { HTMLParser } from '@gik/ui/HTMLParser';
import type { IOpenMojiProps } from '@gik/ui/OpenMoji';
import dynamic from 'next/dynamic';
import React from 'react';
import ReactDOM from 'react-dom';

const OpenMoji = dynamic<IOpenMojiProps>(() => import('@gik/ui/OpenMoji').then(mod => mod.OpenMoji));

export interface IOAuthErrorProps {
  oauthErrorMessage?: string;
  buttonsPortal?: () => HTMLElement;
  errorMessageButtons?: JSX.Element;
}

const blockName = 'oauth-error-modal-content';

function OAuthErrorModalContentComp({ oauthErrorMessage, buttonsPortal, errorMessageButtons }: IOAuthErrorProps) {
  const bem = bemBlock(blockName);

  return (
    <>
      {oauthErrorMessage && (
        <section className={bem('oauth-error-message')}>
          <header className="login-image-container">
            <OpenMoji name="thinking-face" />
            <span>hmmm...</span>
          </header>
          <p>
            <HTMLParser rawHtml={oauthErrorMessage} />
          </p>

          {buttonsPortal && buttonsPortal() && ReactDOM.createPortal(errorMessageButtons, buttonsPortal())}
          {!buttonsPortal && errorMessageButtons}
        </section>
      )}
    </>
  );
}

export const OAuthErrorModalContent = withComponentErrorBoundary(OAuthErrorModalContentComp);
