import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { translationKeys } from '@gik/calendar/i18n/en';
import type { ClaimConflictErrorDetails, ClaimConflictResolve } from '@gik/checkout/api';
import { useCheckoutStore } from '@gik/checkout/store/CheckoutStore';
import type { CartItem } from '@gik/core/models/gik/Order';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { OpenMoji } from '@gik/ui/OpenMoji';
import { UI } from '@gik/ui/UIManager';
import type { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { CheckoutFormModalContext, type ICheckoutFormModalContext } from '../CheckoutForm/CheckoutFormModalContext';
import { ProductList } from '../ProductList/ProductList';

export type ClaimConflictsResolutionProps = UIComponent & {
  claimErrors: ClaimConflictErrorDetails[];
  addToOrder?: boolean;
  inkindRouteId: string;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  onSuggestedResolvesChange?: (suggestions: ClaimConflictResolve[]) => void;
  noRemoveButton?: boolean;
  postPurchase?: boolean;
  setClosable?(closable: boolean): void;
};

export function ClaimConflictsResolution({
  children,
  className,
  claimErrors,
  inkindRouteId,
  addToOrder,
  buttons,
  buttonsPortal,
  onSuggestedResolvesChange,
  noRemoveButton = false,
  postPurchase = false,
  setClosable,
  ...otherProps
}: React.PropsWithChildren<ClaimConflictsResolutionProps>): React.ReactElement {
  const bem = useBemCN('claim-conflicts-resolution');
  const { t } = useTranslation();

  React.useEffect(() => {
    if (setClosable) {
      setClosable(false);
    }
  }, [setClosable]);

  const userId = useUserStore(state => state.id);

  const { mainCart, setMainCart } = React.useContext<ICheckoutFormModalContext>(CheckoutFormModalContext);
  const setCartStore = useCheckoutStore(state => state.setCart);

  const [suggestedResolves, setSuggestedResolves] = React.useState<ClaimConflictResolve[]>();

  const conflictsWithAlternateDates = React.useMemo(() => {
    return claimErrors?.filter(claimError => {
      return (
        (claimError.status?.toLowerCase() === 'conflict' || claimError.status?.toLowerCase() === 'gone') &&
        claimError.availableDates?.length > 0
      );
    });
  }, [claimErrors]);

  const conflictsWithoutAlternateDates = React.useMemo(() => {
    return claimErrors?.filter(claimError => {
      return (
        (claimError.status?.toLowerCase() === 'conflict' || claimError.status?.toLowerCase() === 'gone') &&
        (!claimError.availableDates || claimError.availableDates.length == 0)
      );
    });
  }, [claimErrors]);

  const handleSuggestedResolvesChange = React.useCallback(
    (suggestions: ClaimConflictResolve[]) => {
      setSuggestedResolves(suggestions);
      onSuggestedResolvesChange?.(suggestions);
    },
    [onSuggestedResolvesChange]
  );

  const handleRemove = React.useCallback(
    (cartItem: CartItem) => {
      (async () => {
        // confirm removal
        const confirmResponse = await UI.confirm('Are you sure you want to remove this item from your order?', {
          title: 'Confirm Delete',
          okButtonProps: { variant: 'danger' },
          okText: 'Delete',
        });
        if (!confirmResponse) return;

        // if the item to be removed is the first item in the cart the we also need to update other values in the main checkout form accordingly
        // such as the carrier details and the product type
        const cartIndex = mainCart.findIndex(item => item.id === cartItem.id);
        const oldCartItem = mainCart[cartIndex];

        Analytics.fireEvent(AnalyticsEvents.ClaimConflictsRemoveGiftCard, {
          userId,
          inkindRouteId,
          productId: oldCartItem.productId.toString(),
          productSlug: oldCartItem.productSlug,
          price: oldCartItem.price.toString(),
        });

        const newCart = mainCart.concat([]).filter(item => item.id !== cartItem.id);
        setMainCart(newCart);

        // remove the associated resolved claim
        const newResolvedClaims = suggestedResolves.concat([]).filter(item => item.id !== cartItem.id);

        setSuggestedResolves(newResolvedClaims);

        if (!addToOrder) setCartStore(newCart);
        UI.notify('Item Removed');
      })();
    },
    [addToOrder, inkindRouteId, mainCart, suggestedResolves, setCartStore, setMainCart, userId]
  );

  const handleChangeDate = React.useCallback((cartItem: CartItem, newDate: Moment) => {
    // update the associated resolved claim's date
    // const newResolvedClaims = resolvedClaims.concat([]);
    // // const matchingClaim = resolvedClaims.find(item => item.id === conflict.id);
    // const matchingConflict = resolvedClaims?.find(conflict => conflict.id === cartItem.id);
    // setResolvedClaims(newResolvedClaims);
  }, []);

  useEffectOnce(() => {
    // initially just accept the first available next date for each claim error

    const resolves: ClaimConflictResolve[] = [];
    claimErrors?.forEach(claimError => {
      resolves.push({
        id: claimError.id,
        date: claimError.availableDates?.[0],
      });
    });

    setSuggestedResolves(resolves);
  });

  if (!suggestedResolves) return null;

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <div {...bem('message')}>
        <div {...bem('title')}>
          <div {...bem('image')}>
            <OpenMoji name="hushed-face" width="72px" />
          </div>
          <span>{t(translationKeys.claimConflictsResolutionTitle)}</span>
        </div>

        {claimErrors?.length > 1 ? (
          postPurchase ? (
            <p>Your selected Calendar dates have just been taken by someone else.</p>
          ) : (
            <p>
              We <b>could not complete</b> your purchase because your selected Calendar dates have just been taken by
              someone else.
            </p>
          )
        ) : postPurchase ? (
          <p>Your selected Calendar date has just been taken by someone else.</p>
        ) : (
          <p>
            We <b>could not complete</b> your purchase because your selected Calendar date has just been taken by
            someone else.
          </p>
        )}
      </div>

      {conflictsWithAlternateDates?.length > 0 && (
        <>
          {conflictsWithAlternateDates?.length > 1 ? (
            <p>We have selected the soonest available Calendar dates for the following gift cards:</p>
          ) : (
            <p>We have selected the soonest available Calendar date for the following gift card:</p>
          )}

          <ProductList
            cart={mainCart}
            conflicts={conflictsWithAlternateDates}
            suggestedResolves={suggestedResolves}
            inkindRouteId={inkindRouteId}
            allowRemove={!noRemoveButton}
            onRemove={handleRemove}
            onChangeDate={handleChangeDate}
            onSuggestedResolvesChange={handleSuggestedResolvesChange}
          />
        </>
      )}

      {conflictsWithoutAlternateDates?.length > 0 && (
        <div className="tw-mt-8">
          {conflictsWithoutAlternateDates?.length > 1 ? (
            postPurchase ? (
              <p>
                Your gift cards have still been sent, however they will not be visible on the Care Calendar. If you wish
                to request a refund, please contact support within 24 hours
              </p>
            ) : (
              <p>
                No similar requests are open for the below Calendar dates. You can send the gift cards anyway, but they
                will not appear on the Care Calendar (though it will still be appreciated)
              </p>
            )
          ) : postPurchase ? (
            <p>
              Your gift card has still been sent, however it will not be visible on the Care Calendar. If you wish to
              request a refund, please contact support within 24 hours
            </p>
          ) : (
            <p>
              No similar requests are open for the below Calendar date. You can send the gift card anyway, but it will
              not appear on the Care Calendar (though it will still be appreciated)
            </p>
          )}

          <ProductList
            conflicts={conflictsWithoutAlternateDates}
            suggestedResolves={suggestedResolves}
            cart={mainCart}
            inkindRouteId={inkindRouteId}
            allowRemove={!noRemoveButton}
            onRemove={handleRemove}
          />
        </div>
      )}

      {renderPortal?.(buttons && buttons?.(true), () => buttonsPortal)}
    </div>
  );
}
