import { logger } from '@gik/analytics/utils/logger';
import type { KyInterface } from '@gik/core/api/ky/types';
import { useEnvStore } from '@gik/core/store/EnvStore';

export function KySWRFetcher<D>(kyInstance: KyInterface) {
  return async function (key, searchParams) {
    try {
      if (useEnvStore.getState().KY_LOGGER_ENABLED === 'true') logger.info('KY: fetching', { key, searchParams });

      return await kyInstance.get(key, { searchParams });
      // keep in mind http errors with status code < 500 are not considered a throwable error.
    } catch (error) {
      if (error.name === 'TimeoutError') {
        return null;
      }
      throw error;
    }
  };
}
