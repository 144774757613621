import { PremiumPageUpgradeModalContent } from '@gik/checkout/components/PremiumPageUpgradeModal/PremiumPageUpgradeModal';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import {getSiteSettings, useSiteSettings} from "@gik/api/siteSettings/siteSettings";

export default function InkindPageUpgradeSection() {
  const bem = useBemCN('page-settings-upgrade-section');
  const { data: siteSettings } = useSiteSettings();
  const freePremiumPageUpgrades = siteSettings?.freePremiumPageUpgrades === 'true';

  return (
    <section {...bem(null, [{ 'free-premium-page-upgrades': freePremiumPageUpgrades}])}>
      <div>
        <PremiumPageUpgradeModalContent initiatedOn="inkindPageSettings" variant="generic" freePremiumPageUpgrades={freePremiumPageUpgrades} />
      </div>
    </section>
  );
}
