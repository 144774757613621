import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import repeatElement from '@gik/core/utils/repeatElement';
import { GiftCardSkeleton, GiftCardSuggestion, useCreateFlowStore } from '@gik/create';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { EmbeddedVideo } from '@gik/ui/EmbeddedVideo';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { KeepProportions } from '@gik/ui/KeepProportions';
import React from 'react';

export interface IWishlistAsideProps {
  onChange?: (values: ICreateFeaturesWishlistAsideFormValues) => void;
  featureVideo: string;
  trackWishlistVideoPlayed?: () => void;
}

export interface ICreateFeaturesWishlistAsideFormValues {
  [FeatureWishlistInputNames.WishlistItems]: number[];
}

const blockName = 'wishlist-aside';

export default function WishlistAside({
  onChange = noop,
  featureVideo,
  trackWishlistVideoPlayed,
}: IWishlistAsideProps) {
  const bem = bemBlock(blockName);
  const suggestedProducts = useCreateFlowStore(state => state.suggestedProducts);
  const productLimit = 4;
  const formValues = useCreateFlowStore(state => state.formValues);
  const [selectedProducts, setSelectedProducts] = React.useState<Product[]>(undefined);

  React.useEffect(() => {
    if (suggestedProducts && !selectedProducts) {
      if (formValues[FeatureWishlistInputNames.WishlistItems]?.length > 0) {
        setSelectedProducts(
          formValues[FeatureWishlistInputNames.WishlistItems]?.slice(0, productLimit).map(id => {
            return suggestedProducts.find(product => product.id === id);
          })
        );
      } else {
        setSelectedProducts(suggestedProducts?.slice(0, productLimit));
      }
    }
    // eslint-disable-next-line
  }, [suggestedProducts]);

  React.useEffect(() => {
    const values: ICreateFeaturesWishlistAsideFormValues = {
      [FeatureWishlistInputNames.WishlistItems]: selectedProducts?.map(p => p.id),
    };

    onChange(values);
    // eslint-disable-next-line
  }, [selectedProducts]);

  function handleDismiss(i: number) {
    setSelectedProducts([...selectedProducts.slice(0, i), ...selectedProducts.slice(i + 1, selectedProducts.length)]);
  }

  return (
    <div className={bem()}>
      <ul className={bem('wishlist-features')}>
        <li>
          <HTMLParser rawHtml={i18n.t(translationKeys.WishlistHowItWorksDescription1)} />
        </li>
      </ul>

      <section className={bem('suggestions')}>
        {selectedProducts !== undefined
          ? selectedProducts.map((product, i) => (
              <GiftCardSuggestion key={i} showProductName product={product} onClickDismiss={() => handleDismiss(i)} />
            ))
          : repeatElement(productLimit, <GiftCardSkeleton />)}
      </section>

      <ul className={bem('wishlist-features')}>
        <li>
          <HTMLParser rawHtml={i18n.t(translationKeys.WishlistHowItWorksDescription2)} />
        </li>
        <li>
          <HTMLParser rawHtml={i18n.t(translationKeys.WishlistHowItWorksDescription3)} />
        </li>
      </ul>

      <KeepProportions className={bem('video')}>
        <EmbeddedVideo
          url={`https://www.youtube.com/watch?v=${featureVideo}`}
          lightboxOverlay
          lightboxTitle={i18n.t(translationKeys.WishlistTitle)}
          trackFunction={trackWishlistVideoPlayed}
        />
      </KeepProportions>
    </div>
  );
}
