import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IBaseScrollLinkProps extends React.PropsWithChildren<{}> {
  className?: string;
}

export interface IScrollLinkToIdProps extends IBaseScrollLinkProps {
  toId: string;
}

export interface IScrollLinkToClassNameProps extends IBaseScrollLinkProps {
  toClassName: string;
}

// export interface IScrollLinkToRefProps extends IBaseScrollerProps {
//   toRef: React.RefObject<any>;
// }

const blockName = 'scroll-link';

// TODO: remove
// @deprecated
export function ScrollLink(props: IScrollLinkToIdProps | IScrollLinkToClassNameProps) {
  const bem = bemBlock(blockName);

  const handleClick = () => {
    if ((props as IScrollLinkToIdProps).toId) {
      document.getElementById((props as IScrollLinkToIdProps).toId).scrollIntoView();
    } else if ((props as IScrollLinkToClassNameProps).toClassName) {
      document.getElementsByClassName((props as IScrollLinkToClassNameProps).toClassName)[0].scrollIntoView();
    }
    // else if ((props as IScrollerToRefProps).toRef) {
    //   // TODO
    // }
  };

  return (
    <div className={bem(null, null, [props.className])} onClick={handleClick}>
      {props.children}
    </div>
  );
}
