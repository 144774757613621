import type { Product } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { IPerfectgiftFaceplateUploaderResult } from '@gik/shop/components/PerfectgiftFaceplateButtons/PerfectgiftFaceplateButtons';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { useOpenLoopInfo } from '@gik/shop/hooks/useOpenLoopInfo';
import type { IImageCropData } from '@gik/ui/ImageCropper';
import type { CroppedImageResponse } from '@gik/ui/ImageCropper/cropImage';
import getCroppedImg from '@gik/ui/ImageCropper/cropImage';
import React from 'react';

export type OpenLoopType = 'visa' | 'mastercard';

export function getOpenLoopType(product: Product): OpenLoopType {
  if (!product) return undefined;

  if (product.name.toLowerCase().includes('visa')) return 'visa';
  if (product.name.toLowerCase().includes('mastercard')) return 'mastercard';

  return undefined;
}

export function getDefaultFaceplate(product: Product): PerfectGiftFaceplate {
  if (!product) return undefined;

  // @ts-ignore
  const productFaceplates: PerfectGiftFaceplate = product.variations
    ?.find(v => v.metaData?.find(m => m.key === 'pg_product_type' && m.value === 'physical'))
    ?.metaData?.find(m => m.key === 'pg_faceplates')?.value;

  return productFaceplates?.[0];
}

export const defaultToggleValue: OLProductType = 'physical';

export function useOpenLoop(product: Product, toggleValue: OLProductType = defaultToggleValue) {
  const [customImage, setCustomImage] = React.useState<IImageCropData>();
  const [croppedImage, setCroppedImage] = React.useState<CroppedImageResponse>();
  const [showMode, setShowMode] = React.useState<'uploaded' | 'faceplate'>('faceplate');

  const { hasPhysicalCard, designUploader } = useOpenLoopInfo(product, toggleValue);

  async function handleCustomImageChange(uploadPhoto: IPerfectgiftFaceplateUploaderResult) {
    if (uploadPhoto.image === customImage) return;

    // TODO state used only for comparison above, getCroppedImg never returns the same value with the same inputs
    setCustomImage(uploadPhoto.image);
    setCroppedImage(
      await getCroppedImg(uploadPhoto.image.image, uploadPhoto.image.croppedAreaPixels, uploadPhoto.image.rotation)
    );

    setShowMode('uploaded');
  }

  function handleFaceplateChange(faceplate: PerfectGiftFaceplate) {
    // if (faceplate.uuid === selectedFaceplate?.uuid) return;
    setShowMode('faceplate');
  }

  return {
    croppedImage,
    showMode,
    hasPhysicalCard,
    designUploader,

    handleCustomImageChange,
    handleFaceplateChange,
  };
}
