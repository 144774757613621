import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import type { ICreateFeaturesDonationsFormValues } from '@gik/create/components/FeatureStep/FeatureStep';
import { DonationsFeatureStep } from '@gik/create/components/FeatureStep/FeatureStep';
import { FeaturesStepInputNames } from '@gik/create/enums/FeaturesStepInputNames';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import type { FormProps } from '@gik/ui/Form';
import { Fundraising } from '@gik/ui/SvgIcon/GikIcons/Fundraising';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';

export interface IDonationsStepProps extends FormProps {
  onSubmit?: (values: ICreateFeaturesDonationsFormValues) => void;
  onChange?: (values: ICreateFeaturesDonationsFormValues) => void;
}

const blockName = 'donations-step';

export default function DonationsStep({ ...otherProps }) {
  const bem = bemBlock(blockName);
  const { donationsFormRef } = React.useContext(CreatePageContext) || { donationsFormRef: null };
  const { stepIndex, subStepIndex } = useCreateFlowStore(state => ({
    stepIndex: state.stepIndex,
    subStepIndex: state.subStepIndex,
  }));

  const enabled = stepIndex == 3 && subStepIndex == 2;
  useFormNavigationObserver(donationsFormRef, enabled);

  return (
    <DonationsFeatureStep
      ref={donationsFormRef}
      formName={FeaturesStepInputNames.Donations}
      className={bem()}
      icon={<SvgIcon className={bem('icon')} Icon={Fundraising} />}
      title={i18n.t(translationKeys.FundraisingTitle)}
      description={i18n.t(translationKeys.FundraisingCreateStepDescription)}
      finePrint={i18n.t(translationKeys.FundraisingFinePrint)}
      {...otherProps}
    />
  );
}
