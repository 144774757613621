import { useEnvStore } from '@gik/core/store/EnvStore';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

export function useGoogleAnalytics() {
  const googleAnalyticsId = useEnvStore(state => state.GOOGLE_ANALYTICS_ID);

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }
  }, [googleAnalyticsId]);
  return null;
}
