import { useInkind } from '@gik/api/inkinds/inkind';
import ContactPageOrganizersForAddress, { CalendarClaimFooter } from '@gik/calendar';
import type { IAddToCalendarLinks } from '@gik/calendar/components/AddToCalendarSelector';
import { AddToCalendarButton } from '@gik/calendar/components/AddToCalendarSelector/AddToCalendarButton';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import type { IRecipientContactInformation } from '@gik/calendar/models/RecipientContactInformation';
import { getRecipientContactInformation } from '@gik/calendar/utils/getRecipientContactInformation';
import bemBlock from '@gik/core/utils/bemBlock';
import { openNewBrowserTab } from '@gik/core/utils/browser';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { CopyAddressButton, CopyEmailButton } from '@gik/ui/CopyButton';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { Text } from '@gik/ui/Text/Text';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import he from 'he';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

interface ProvideMyselfClaimSuccessProps extends React.HTMLAttributes<HTMLDivElement> {
  entry: ICalendarEntry;
  event: ICalendarEvent;
  addToCalendarLinks: IAddToCalendarLinks;
  isFollowing: boolean;
  recipientContactInformation?: IRecipientContactInformation;
  buttonsPortal?: () => HTMLElement;
  // onBack?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

export function ProvideMyselfClaimSuccess({
  className,
  entry,
  buttonsPortal,
  addToCalendarLinks,
  // onBack = noop,
  isFollowing,
  recipientContactInformation,
  onCancel = noop,
  ...otherProps
}: ProvideMyselfClaimSuccessProps): React.ReactElement {
  // store previous following value as this will update as soon as the user claims the item
  const [prevIsFollowing] = React.useState(isFollowing);
  const bem = bemBlock('provide-myself-claim-success');
  const { t } = useTranslation();
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  //const { data: calendarEventTypes } = useCalendarEventTypes();

  function handleSeeGiftingOptions() {
    // TODO: see GIK-4216 Claim "See Gifting Options" flow

    /*const calendarEventType = calendarEventTypes.find(v => v.id === entry.typeId);
    if (!calendarEventType) {
      throw new Error(`No Wordpress Calendar event type found matching ID: ${entry.typeId}`);
    }
    const url = calendarEventType.acf.gifting_options_url
      .replace('{host}', window.location.host)
      .replace('{inkindId}', inkind.id.toString());*/

    openNewBrowserTab(`${window.location.origin}/give`);
  }

  if (!inkind) return <LoadingSpinner />;

  const { address1, address2, cityAndState, postcode, fullName, email } =
    recipientContactInformation || getRecipientContactInformation(inkind);
  const recipientAddressLine1 = [address1, address2].filter(v => v).join(', ');
  const recipientAddressLine2 = [cityAndState, postcode].join(' ');

  const _buttons = (
    <CalendarClaimFooter closeButton onClose={onCancel}>
      <Button onClick={handleSeeGiftingOptions}>See Gifting Options</Button>
    </CalendarClaimFooter>
  );

  return (
    <div className={bem(null, ['provide-myself'], className)} {...otherProps}>
      <div className={bem('check')}>
        <SvgIcon Icon={CheckIcon} size="2xl" />
      </div>

      <span className={bem('title')}>
        <Text>{entry.typeId !== CalendarEventTypeIds.Other ? entry.typeName : entry.title} claimed!</Text>
      </span>

      <AddToCalendarButton links={addToCalendarLinks} />

      {!prevIsFollowing && <p className={bem('description-muted')}>{t(translationKeys.youAreNowFollowing)}</p>}
      <p className={bem('description-muted')}>{t(translationKeys.emailedConfirmation)}</p>

      <div className={bem('details')}>
        {address1 ? (
          <div className={bem('details-address')}>
            <p>
              Here is the recipient’s address and phone number,
              <br />
              we’ll also email you all of these details:
            </p>
            <div className={bem('details-address-content')}>
              <section className={bem('section-address')}>
                <address>
                  <strong>{he.decode(fullName)}</strong>
                  <br />
                  {he.decode(recipientAddressLine1)}
                  <br />
                  {he.decode(recipientAddressLine2)}
                </address>
              </section>
              <section>
                <CopyAddressButton
                  pill={false}
                  circle
                  text=""
                  tooltip="Copy Address"
                  size="base"
                  iconSize="base"
                  variant="default-extra-light"
                  textToCopy={[fullName, recipientAddressLine1, recipientAddressLine2].join('\r\n')}
                />
              </section>
            </div>
          </div>
        ) : (
          <ContactPageOrganizersForAddress inkindRouteId={inkindRouteId} />
        )}

        {email && (
          <div className={bem('details-email')}>
            <p className={bem('description')}>Gift card purchase may require the recipient&apos;s email address:</p>
            <div className={bem('details-email-content')}>
              <section className={bem('section-email')}>
                <p>
                  <strong>{email}</strong>
                </p>
              </section>
              <section>
                <CopyEmailButton
                  pill={false}
                  circle
                  size="base"
                  iconSize="base"
                  text=""
                  tooltip="Copy Email"
                  variant="default-extra-light"
                  textToCopy={email}
                />
              </section>
            </div>
          </div>
        )}
      </div>

      <p className={bem('footer-text')}>
        <strong>You’re all set!</strong> You can close this message, or browse things to send by clicking below.
      </p>
      {renderPortal(_buttons, buttonsPortal)}
    </div>
  );
}
