import type { ICalendarEventClaimCreatePayload } from '@gik/api/calendar/calendarClaims';
import type { ClaimConflictErrorDetails } from '@gik/checkout/api';
import type { CartItem, PerfectgiftCarrierTypes, ShippingDetails } from '@gik/core/models/gik/Order';
import type { ProductCheckoutType } from '@gik/core/models/gik/Product';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
export * from '@gik/core/models/gik/Order'; // TODO: remove re-export after SPA migration

export interface PaymentConfirmationValues {
  /**
   * in the case of product bundles, the product bundle product name is no longer accessible
   * as we create an order with the child products.
   */
  productName: string;
  orderId: number;
  orderKey: string;
  orderDate: string;
  senderEmail: string;
  total: number;
  paymentMethod: string;
  subtotal: number;
  tip: number;
  taxCost?: number;
  shippingCost?: number;
  pgCarrier?: PerfectgiftCarrierTypes;
  shippingTax?: number;
  shipping?: ShippingDetails;
  products: CartItem[];
  // claim as part of order
  // claimStatus?: OrderClaimStatus; // someone else claimed the item immediately beforehand
  claims: ClaimStatus[];
  claimErrorMessage?: string;
  productType?: OLProductType;
  checkoutType?: ProductCheckoutType;
}

export type ClaimStatus = ClaimConflictErrorDetails & {
  claimId: string;
  errorMessage?: string;
  createClaimRequest: ICalendarEventClaimCreatePayload;
};

export type CheckoutFormInitiatedOnType =
  | 'wishlist'
  | 'productPage'
  | 'calendar'
  | 'inkindPage'
  | 'supportersPage'
  | 'inkindPageSettings'
  | 'profilePage'
  | 'add-from-wishlist'
  | 'add-from-calendar'
  | 'edit-cart-item';

export type AddressValidationRequest = {
  country: string;
  state: string;
  street: string;
  city: string;
  zip: string;
};
