import type { FormError } from '@gik/ui/Form';
import type { FormikProps } from 'formik';

export function fieldHasError(formProps: FormikProps<object>, fieldName: string) {
  const error: FormError = formProps.errors?.[fieldName];
  const touched: boolean = formProps.touched?.[fieldName];

  const hasError = error && touched;
  return {
    error,
    hasError,
  };
}
