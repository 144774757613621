import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { careCalendarCardBlockName } from '@gik/create/components';
import type { IPageFeatureCardProps } from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import PageFeatureCard from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { Calendar } from '@gik/ui/SvgIcon/GikIcons/Calendar';
import React from 'react';

export interface ICareCalendarCardProps extends Pick<IPageFeatureCardProps, 'inputName'> {
  onChange?: (values: boolean) => void;
  value: boolean;
  disabled?: boolean;
}

export default function CareCalendarCard({
  onChange = noop,
  value,
  inputName,
  className,
  disabled = false,
}: PropsWithClassName<ICareCalendarCardProps>) {
  const bem = bemBlock(careCalendarCardBlockName);
  return (
    <PageFeatureCard
      className={bem(null, null, className)}
      icon={Calendar}
      title={i18n.t(translationKeys.careCalendarTitle)}
      description={i18n.t(translationKeys.careCalendarDescription)}
      onChange={onChange}
      value={value}
      inputName={inputName}
      disabled={disabled}
    />
  );
}
