import { gikClassPrefix } from '@gik/core/constants';
import { useRandomInt } from '@gik/core/utils/getRandomInt';
import repeatElement from '@gik/core/utils/repeatElement';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { ListBone } from './ListBone';
import { WordBone } from './WordBone';

const blockName = `${gikClassPrefix}-nested-list-bone`;

export interface NestedListBoneProps extends BaseBoneProps {
  entries?: number;
  constantWidth?: boolean;
  nestingProbability?: number;
  minNestedEntries?: number;
  maxNestedEntries?: number;
}

export function NestedListBone({
  className,
  entries = 7,
  constantWidth = true,
  nestingProbability = 0.75,
  minNestedEntries = 1,
  maxNestedEntries = 4,
}: NestedListBoneProps) {
  if (nestingProbability < 0 || nestingProbability > 1) {
    throw new Error('Probability must be a percentage represented by a number between 0 and 1. ');
  }

  const blockClasses = classnames([blockName, className || '']);
  const randomDelta = useRandomInt(0, 100);

  return (
    <ul className={blockClasses}>
      {repeatElement(
        entries,
        <li>
          <WordBone width={constantWidth ? 100 : undefined} />
          {randomDelta / 100 <= nestingProbability && (
            <InnerListBone
              constantWidth={constantWidth}
              minNestedEntries={minNestedEntries}
              maxNestedEntries={maxNestedEntries}
            />
          )}
        </li>
      )}
    </ul>
  );
}

function InnerListBone({
  constantWidth,
  minNestedEntries,
  maxNestedEntries,
}: Pick<NestedListBoneProps, 'constantWidth'> &
  Pick<NestedListBoneProps, 'minNestedEntries'> &
  Pick<NestedListBoneProps, 'maxNestedEntries'>) {
  const randomDelta = useRandomInt(minNestedEntries, maxNestedEntries);

  return <ListBone constantWidth={constantWidth} entries={randomDelta} />;
}
