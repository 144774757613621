import { requestLogger } from '@gik/core/api/base/hooks/requestLogger';
import { timers } from '@gik/core/utils/TimerUtil';

export const requestLoggerKyHook = (request: Request) => {
  // start a new timer for this request
  const requestId = request.headers.get('x-browser-request-id');
  timers[requestId] = new Date();

  requestLogger(request);
};
