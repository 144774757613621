import { useApiCached } from '@gik/core/api/swr/useApi';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';

export interface IGetGoFundMeCampaignPageStatusResponse {
  valid: boolean;
  slug: string;
}

export function usePageGoFundMeCampaign(inkindPage: InkindPageAPIModel) {
  const hasValue = inkindPage?.routeId && inkindPage?.financialContributions?.goFundMeUrl;
  return useApiCached<IGetGoFundMeCampaignPageStatusResponse>(
    hasValue ? `inkinds/${inkindPage.routeId}/gofundme-campaign-status` : null,
    {
      hash: inkindPage?.routeId,
    }
  );
}

export function useGoFundMeCampaign(campaignSlug: string) {
  return useApiCached<IGetGoFundMeCampaignPageStatusResponse>(campaignSlug ? `gofundme/campaign-status/` : null, {
    campaignSlug,
  });
}
