import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import repeatElement from '@gik/core/utils/repeatElement';
import { ButtonBone, FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';

export const privacySettingsSectionSkeletonBlockName = 'privacy-settings-section-skeleton';

export const PrivacySettingsSectionSkeleton: React.FC = (): JSX.Element => {
  const bem = bemBlock(privacySettingsSectionSkeletonBlockName);
  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);

  return (
    <div className={bem()}>
      <ParagraphBone words={2} className={bem('title')} />
      <ParagraphBone words={isSmDown ? 6 : 9} className={bem('description')} />
      <div className={bem('cards')}>
        {repeatElement(
          5,
          <FrameBone className={bem('card')}>
            <FrameBone className={bem('icon')} />
            <ParagraphBone words={2} />
            <ButtonBone words={1} pill className={bem('switch')} />
          </FrameBone>
        )}
      </div>
      <div className={bem('second-section')}>
        <div className={bem('checkbox-group-1')}>
          <FrameBone className={bem('box')} />
          <ParagraphBone words={3} className={bem('label')} />
          <ParagraphBone words={isSmDown ? 7 : 11} className={bem('text')} />
        </div>
        <div className={bem('checkbox-group-2')}>
          {repeatElement(
            2,
            <>
              <FrameBone className={bem('box')} />
              <ParagraphBone words={3} className={bem('label')} />
              <ParagraphBone words={isSmDown ? 7 : 11} className={bem('text')} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
