import type { FormSchemaEntry } from '@gik/ui/Form';

type RecipientAddressFormSchema = (required: boolean, ref?: React.RefObject<HTMLElement>) => FormSchemaEntry[];

export const recipientAddressFormSchema: RecipientAddressFormSchema = (required, ref) => [
  {
    label: 'Country',
    name: 'country',
    type: 'hidden',
    default: 'US',
    text: 'United States (US)',
    required,
    props: { autoComplete: 'country-name' },
  },
  {
    name: 'address1',
    type: 'text',
    required,
    minLength: 2,
    maxLength: 255,
    placeholder: 'Address',
    props: { autoComplete: 'address-line1' },
  },
  {
    name: 'address2',
    type: 'text',
    required: false,
    minLength: 2,
    maxLength: 255,
    errorName: 'Address 2',
    placeholder: 'Address 2 (apt., room, suite #, etc.)',
    props: { autoComplete: 'address-line2' },
  },
  {
    name: 'city',
    type: 'text',
    required,
    minLength: 2,
    maxLength: 255,
    placeholder: 'City',
    props: { autoComplete: 'address-level2' },
  },
  {
    name: 'state',
    type: 'select-us-states',
    required,
    errorName: 'State',
    props: {
      placeholder: 'State',
      autoComplete: 'address-level1',
      type: 'compact',
      filterable: true,
      scrollParent: ref,
    },
  },
  {
    name: 'postalCode',
    type: 'number',
    required,
    minLength: 5,
    // this max length is for validation
    maxLength: 5,
    placeholder: 'Zip Code',
    // the max length in the props is to prevent more than 5 charaters to be used on the input
    props: { autoComplete: 'postal-code', maxLength: 5, maxLengthDisplay: false },
  },
];
