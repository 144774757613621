import { logger } from '@gik/analytics/utils/logger';
import { getCalendarEntriesPath } from '@gik/api/calendar/calendarEntries';
import type { ICalendarTimeAndRepeatValues } from '@gik/calendar/models/Calendar';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type { GIKApiError } from '@gik/core/models/gik/APIError';
import { StatusCodes } from 'http-status-codes';
import { BadRequest, UnhandledError } from '../HttpErrors';
import type { IUpdateAnnouncementPayload } from './calendarAnnouncements';

/**
 * Calendar Event payload model for creating or updating events
 */
export interface CreateUpdateEventPayload extends ICalendarTimeAndRepeatValues {
  inkindRouteId: string;

  typeId: number;
  title?: string; // only used by custom event types
  description?: string;

  allowGiftCards: boolean;
  numberRequired?: number;
  allowedServiceIds?: number[]; // claim type picker
  allowedGiftCardIds?: number[]; // gift card picker
  deliveryInstructions?: string; // for food and groceries
  emergencyContactInfo?: string; // for adult/seniour care, childcare and petcare this stores the emergency contact info
  pickupLocation?: string; // for transportation
  dropoffLocation?: string; // for transportation
  announcementTypeId?: number; // for announcements
  petCareTypeId?: number; // for pet care
  sendEmailToPageFollowers?: boolean;
}

export interface UnparentEventRequest {
  instanceDate: string; // set to modify an instance in a series rather than the entire series
}

export interface ModifyClaimedEventRequest {
  noteToClaimOwner?: string; // only required for modifying a claimed event
}

export interface CreateEventPayload extends CreateUpdateEventPayload {}

export interface UpdateEventPayload extends CreateUpdateEventPayload, UnparentEventRequest, ModifyClaimedEventRequest {}

export interface DeleteEventRequest extends ModifyClaimedEventRequest, UnparentEventRequest {}

export async function createEvent(inkindRouteId: string, payload: CreateEventPayload): Promise<string> {
  const response = await dotnetApi.post(getCalendarEntriesPath(inkindRouteId), {
    json: payload,
  });

  if (response.status === StatusCodes.CREATED) {
    const location = response.headers.get('location');
    return location?.split('/').slice(-1)[0];
  } else {
    throw new UnhandledError(`Unexpected response code: ${response.status} ${response.statusText}`);
  }
}

export async function updateEvent(
  inkindRouteId: string,
  eventId: string,
  payload: UpdateEventPayload | IUpdateAnnouncementPayload
): Promise<boolean> {
  const response = await dotnetApi.patch(`${getCalendarEntriesPath(inkindRouteId)}/${eventId}`, {
    json: payload,
  });

  if (!response.ok) {
    logger.error('Failed to update event', response.body);

    const jsonBody = await response.json<GIKApiError>();

    switch (response.status) {
      case StatusCodes.BAD_REQUEST:
        throw new BadRequest('Failed to update request: ' + jsonBody.message);
      default:
        throw new UnhandledError(`${response.status} (${response.statusText})`);
    }
  }
  return true;
}

export async function deleteEvent(
  inkindRouteId: string,
  eventId: string,
  payload?: DeleteEventRequest
): Promise<boolean> {
  const result = await dotnetApi.delete(`${getCalendarEntriesPath(inkindRouteId)}/${eventId}`, {
    json: payload,
  });
  if (result.status !== StatusCodes.NO_CONTENT) {
    throw new UnhandledError(`Unexpected response code: ${result.status} ${result.statusText}`);
  }
  return true;
}
