import { DevTools } from '@gik/core/components/DevTools/DevTools';
import React from 'react';

export function withDevTools(Component: React.ReactElement) {
  return (
    <>
      {Component}
      <DevTools />
    </>
  );
}
