import { isBrowser } from '@/utils/environment';

function normalizeConfig(config, key) {
  return {
    ...(config ? config : {}),

    ...(config?.fallbackData
      ? {
          fallbackData: isBrowser()
            ? {
                body: config.fallbackData,
                headers: {},
              }
            : config.fallbackData,
        }
      : {}),
    fallback: config?.fallback
      ? {
          ...config.fallback,
          ...(config.fallback[key]
            ? isBrowser()
              ? {
                  [key]: {
                    body: config.fallback[key],
                    headers: {},
                  },
                }
              : config.fallback[key]
            : {}),
        }
      : {},
  };
}

export function normalizeConfigMiddleware<D>(useSWRNext) {
  return (key, fetcher, config) => {
    return useSWRNext(key, fetcher, normalizeConfig(config, key));
  };
}
