import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ArrowButtonNext, ArrowButtonPrev } from '../ArrowButton';

export type HorizontalScrollProps = {
  arrows?: boolean;
  fadeSides?: boolean;
  scrollbar?: boolean;
  onPrev?(): void;
  onNext?(): void;
} & UIComponent;

function HorizontalScrollComp({
  children,
  arrows,
  fadeSides,
  scrollbar,
  onNext = noop,
  onPrev = noop,
  ...otherProps
}: React.PropsWithChildren<HorizontalScrollProps>): React.ReactElement {
  const bem = useBemCN('horizontal-nav');

  const contentRef = React.useRef<HTMLDivElement>();

  const [showArrows, setShowArrows] = React.useState<boolean>(arrows);
  const [scrollSize, setScrollSize] = React.useState<number>();
  const [scrollPos, setScrollPos] = React.useState<number>();

  function handlePrev() {
    onPrev();

    const el = contentRef.current;
    if (el) {
      const container = el.firstChild as HTMLDivElement;

      const newPos = container.scrollLeft - (el.offsetWidth - 100);
      setScrollPos(newPos);

      container.scrollTo({
        left: newPos,
        behavior: 'smooth',
      });
    }
  }
  function handleNext() {
    onNext();

    const el = contentRef.current;
    if (el) {
      const container = el.firstChild as HTMLDivElement;

      const newPos = container.scrollLeft + (el.offsetWidth - 100);
      setScrollPos(newPos);

      container.scrollTo({
        left: newPos,
        behavior: 'smooth',
      });
    }
  }

  function handleResize() {
    if (!arrows) return;
    const el = contentRef.current;
    if (el) {
      const container = el.firstChild as HTMLDivElement;

      setScrollSize(container.scrollWidth);
      setScrollPos(container.scrollLeft);

      // hide arrows if the container if wider than the scroll content
      if (container.scrollWidth <= container.offsetWidth) {
        setShowArrows(false);
      } else {
        setShowArrows(true);
      }
    }
  }

  function handleScroll() {
    handleResize();
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  const el = contentRef.current;
  const container = el?.firstChild as HTMLDivElement;

  const isAtStart = scrollPos === 0;
  const isAtEnd = scrollPos + container?.offsetWidth >= scrollSize;

  return (
    <div {...bem(null, [{ 'fade-sides': fadeSides }, { scrollbar }, { arrows: showArrows }])} {...otherProps}>
      <div {...bem('content-wrapper')} ref={contentRef}>
        <ScrollContainer
          horizontal
          vertical={false}
          {...bem('scroll-container', null, ['scroll-container'])}
          onScroll={handleScroll}
          onEndScroll={handleScroll}
        >
          {children}
        </ScrollContainer>
      </div>
      {showArrows && <ArrowButtonPrev onClick={handlePrev} {...bem('arrow-prev')} disabled={isAtStart} />}
      {showArrows && <ArrowButtonNext onClick={handleNext} {...bem('arrow-next')} disabled={isAtEnd} />}
    </div>
  );
}

export const HorizontalScroll = HorizontalScrollComp;
