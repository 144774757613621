import type { ConvertType } from '@gik/core/utils/FileUtils';
import { AcceptType, getMimeType } from '@gik/core/utils/FileUtils';
import type { Area } from 'react-easy-crop/types';

export default class ImageUtils {
  static notFoundImageGradient = 'linear-gradient(45deg,#2fc3e4,#41cbce 19.47%,#41cbce 78.03%,#00b7b0)';
  static imageOrNotFoundGradient(imageUrl: string): string {
    return imageUrl ? `url("${imageUrl}")` : ImageUtils.notFoundImageGradient;
  }

  static async getCroppedImage(
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
    mimeType: ConvertType | AcceptType = AcceptType.JPEG,
    name?: string
  ): Promise<string> {
    const image = await this.createImage(imageSrc);
    if (!image) {
      return null;
    }
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    return canvas.toDataURL(getMimeType(mimeType, name));
  }

  public static createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', _ => reject(null));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });
}
