import { Analytics } from '@gik/analytics';
import { ErrorPageSection } from '@gik/core/pages/Error/ErrorPageSection';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import React from 'react';
import { useEffectOnce } from 'react-use';

type ApiErrorDisplayProps = {
  errors?: unknown[];
};

export function ApiErrorDisplay({ errors }: ApiErrorDisplayProps) {
  const bem = useBemCN('api-error');

  useEffectOnce(() => {
    console.error('Api Errors:', errors);
    if (errors?.length) {
      // @ts-ignore
      Analytics.fireUserNotice(errors.map(e => e.message).join('\n'), 'errorApiDisplay');
    }
  });

  const handleShowError = React.useCallback(
    (error: Error) => {
      UI.dialog(
        <div {...bem('dialog-content')}>
          <ErrorPageSection error={error} layout="vertical" noPad />
        </div>,
        { title: 'Error', closable: true }
      );
    },
    [bem]
  );

  return (
    <div {...bem()}>
      {errors.map((error, index) => {
        return (
          <div
            className="tw-text-danger-500 tw-m-4 tw-h-full tw-flex-1"
            key={index}
            onClick={() => handleShowError(error as Error)}
          >
            <SvgIcon Icon={ExclamationCircleIcon} />
          </div>
        );
      })}
    </div>
  );
}
