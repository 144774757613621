import bemBlock from '@gik/core/utils/bemBlock';
import { Image } from '@gik/ui/Image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export interface ICalendarEventTypeTileAnnouncementProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  image: string;
  selected?: boolean;
  horizontal?: boolean;
}

export function CalendarEventTypeTileAnnouncement({
  image,
  label,
  selected,
  horizontal,
  onClick,
  className,
}: ICalendarEventTypeTileAnnouncementProps) {
  const bem = bemBlock('calendar-event-type-tile-announcement');
  const { t } = useTranslation();

  // function handleInfoClick() {
  //   UI.info('some info here');
  // }

  return (
    <div className={bem(null, [{ horizontal }, { vertical: !horizontal }, { selected }], className)}>
      <div className={bem('boxtitle')}>
        <span>{t(translationKeys.announcementTypeNew)}</span>
      </div>
      <header>
        <div>
          <span className={bem('title')}>{t(translationKeys.announcementTypeTitle)}</span>
          <span className={bem('description')}>
            {t(translationKeys.announcementTypeDescription)}{' '}
            {/* <SvgIcon Icon={InformationCircleIcon} onClick={handleInfoClick} size="sm" /> */}
          </span>
        </div>
      </header>
      <main>
        <div onClick={onClick}>
          <Image className={bem('image')} src={image} />
          <span className={bem('label')}>{label}</span>
        </div>
      </main>
    </div>
  );
}
