import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const VisitorsDisabledOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M19.8031 11.3284C20.5532 10.5783 20.9747 9.56087 20.9747 8.5C20.9747 7.43913 20.5532 6.42172 19.8031 5.67157C19.053 4.92143 18.0355 4.5 16.9747 4.5C15.9138 4.5 14.8964 4.92143 14.1462 5.67157C13.3961 6.42172 12.9747 7.43913 12.9747 8.5C12.9747 9.56087 13.3961 10.5783 14.1462 11.3284C14.8964 12.0786 15.9138 12.5 16.9747 12.5C18.0355 12.5 19.053 12.0786 19.8031 11.3284Z"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9978 17.8333V28.4999H12.9978L12.9978 15.8333"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.9978 4.5C4.9978 12.5 10.9978 15.8333 16.9978 16.5C22.9978 17.1667 28.9978 18.5 28.9978 28.5"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path d="M28.9978 4.5L4.9978 28.5" stroke="#EF5C84" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  }
);

export const VisitorsDisabled = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <g clipPath="url(#clip0)">
          <path
            d="M12.1037 7.12132C12.6664 6.55871 12.9824 5.79565 12.9824 5C12.9824 4.20435 12.6664 3.44129 12.1037 2.87868C11.5411 2.31607 10.7781 2 9.98242 2C9.18677 2 8.42371 2.31607 7.8611 2.87868C7.29849 3.44129 6.98242 4.20435 6.98242 5C6.98242 5.79565 7.29849 6.55871 7.8611 7.12132C8.42371 7.68393 9.18677 8 9.98242 8C10.7781 8 11.5411 7.68393 12.1037 7.12132Z"
            fill="#9EABAD"
          />
          <path d="M9 18H11C12.1046 18 13 17.1046 13 16V10.5L7 9L7 16C7 17.1046 7.89543 18 9 18Z" fill="#9EABAD" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 2C3.55228 2 4 2.44772 4 3C4 5.0508 4.75435 6.42155 5.83597 7.34292C6.95041 8.29227 8.48902 8.82596 10.1104 9.00612C11.8478 9.19916 13.8541 9.50392 15.4077 10.6548C17.0469 11.869 18 13.8683 18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 14.2983 15.2031 12.9922 14.2173 12.2619C13.1459 11.4683 11.6522 11.1897 9.88957 10.9939C8.01098 10.7852 6.04959 10.1522 4.53903 8.86541C2.99565 7.55068 2 5.61586 2 3C2 2.44772 2.44772 2 3 2Z"
            fill="#9EABAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7071 0.292893C20.0976 0.683417 20.0976 1.31658 19.7071 1.70711L1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L18.2929 0.292893C18.6834 -0.0976311 19.3166 -0.0976311 19.7071 0.292893Z"
            fill="#EF5C84"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
