export * from './CalendarAnnouncement';
export * from './CalendarAnnouncementForm';
export * from './CalendarAnnouncementSelectList';
export * from './CalendarAnnouncementTile';
export * from './CalendarAnnouncementView';
import type { UIButtonVariant } from '@gik/ui/types';

enum AnnouncementTypeIds {
  INFO = 41876,
  LOVE = 41877,
  WARNING = 41878,
}
enum AnnouncementTypes {
  INFO = 'information',
  HEART = 'love',
  WARNING = 'warn',
}

export function getAnnouncementUIContextFromId(announcementTypeId: number): UIButtonVariant {
  switch (announcementTypeId) {
    case AnnouncementTypeIds.INFO:
      return 'info';
    case AnnouncementTypeIds.LOVE:
      return 'danger';
    case AnnouncementTypeIds.WARNING:
      return 'secondary';
    default:
      return 'default';
  }
}

export function getAnnouncementUIContextFromSlug(slug: string): UIButtonVariant {
  switch (slug) {
    case AnnouncementTypes.INFO:
      return 'info';
    case AnnouncementTypes.HEART:
      return 'danger';
    case AnnouncementTypes.WARNING:
      return 'secondary';
    default:
      return 'default';
  }
}
