import Link from '@/components/Link';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import React from 'react';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import type { IconSize } from '../types';

const blockName = `popover-list-item`;

export interface PopoverListItemProps {
  prepend: JSX.Element;
  icon?: SvgrComponent;
  iconName?: string;
  href?: string;
  hover?: boolean;
  danger?: boolean;
  onClick?(): void;
  iconSize?: IconSize;
  isLoading?: boolean;
  className?: string;
}

export function PopoverListItem({
  href,
  prepend,
  icon,
  iconName,
  onClick,
  hover,
  danger,
  iconSize = 'sm',
  isLoading,
  className,
}: PopoverListItemProps): React.ReactElement {
  const bem = useBemCN(blockName);
  const Component = href ? Link : 'div';

  return (
    <Component
      href={href}
      {...bem(
        null,
        [
          {
            hover: hover,
          },
          {
            danger: danger,
          },
          {
            loading: isLoading,
          },
        ],
        className
      )}
      onClick={onClick}
    >
      {prepend}
      <div {...bem('icon')}>
        {isLoading ? <LoadingSpinner /> : icon ? <SvgIcon Icon={icon} key={iconName} size={iconSize} /> : null}
      </div>
    </Component>
  );
}
