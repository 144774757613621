import React from 'react';
import type { LinkProps } from 'react-scroll';
import { Link } from 'react-scroll';

export interface IAnchorLinkProps extends LinkProps {}

function AnchorLinkComp({ children, duration = 300, ...otherProps }: IAnchorLinkProps, ref) {
  return (
    <Link ref={ref} duration={duration} {...otherProps} smooth={true}>
      {children}
    </Link>
  );
}

export const AnchorLink = React.forwardRef(AnchorLinkComp);
export const AnchorLinkNoRef = AnchorLinkComp;
