import { Breakpoint, useBreakpoint } from '@gik/core/hooks/CustomHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { dateDisplayFormat } from '@gik/l10n';
import ChevronLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import { SvgIcon } from '../SvgIcon/SvgIcon';

const blockName = 'date-picker';

export interface IDatePickerProps {
  className?: string;
  placeholder?: string;
  value: Moment;
  name?: string;
  startDate?: Moment;
  allowPastDates?: boolean;
  onChange(date: Moment): void;
  onBlur?(ev: React.FocusEvent): void;
  onFocus?(ev: React.FocusEvent): void;
}

export function DatePicker({
  className,
  value,
  name,
  placeholder,
  startDate,
  allowPastDates,
  onChange,
  onBlur,
  onFocus,
}: React.PropsWithChildren<IDatePickerProps>) {
  const bem = bemBlock(blockName);
  const [date, setDate] = React.useState<Moment>(value);
  const [focused, setFocused] = React.useState<boolean>();

  const isMdUp = useBreakpoint(Breakpoint.MD);

  const handleDateChange = React.useCallback(
    (date: Moment) => {
      setDate(date);
      onChange?.(date);
    },
    [onChange]
  );

  const handleFocusChange = React.useCallback((data: { focused: boolean }) => {
    setFocused(data.focused);

    // data.focused ? onFocus?.() : onBlur?.();
  }, []);

  // block days before startDate
  function isDayBlocked(day: Moment) {
    if (startDate) {
      return day.isBefore(startDate);
    }
    return false;
  }

  function isOutsideRange(day: Moment) {
    if (!allowPastDates) {
      return day.isBefore(moment().startOf('day'));
    }

    return false;
  }

  React.useEffect(() => {
    if (value) setDate(moment(value));
  }, [value]);

  return (
    <div className={bem(null, null, className)}>
      <SingleDatePicker
        readOnly
        placeholder={placeholder}
        id={name || `${blockName}${DatePicker.counter++}`} // PropTypes.string.isRequired,
        date={date} // momentPropTypes.momentObj or null
        onDateChange={handleDateChange} // PropTypes.func.isRequired
        focused={focused} // PropTypes.bool
        onFocusChange={handleFocusChange} // PropTypes.func.isRequired
        hideKeyboardShortcutsPanel={true}
        enableOutsideDays={true}
        withPortal={true}
        numberOfMonths={isMdUp ? 2 : 1}
        displayFormat={dateDisplayFormat}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        navNext={<SvgIcon className="gik-datepicker-btn-next" Icon={ChevronRightIcon} />}
        navPrev={<SvgIcon className="gik-datepicker-btn-prev" Icon={ChevronLeftIcon} />}
      />
    </div>
  );
}

DatePicker.counter = 0;
