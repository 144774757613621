import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Google = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M21.6415 15.462C21.1964 17.3464 20.388 19.0301 18.9186 20.3154C17.0316 22.1956 14.7016 22.903 12.1536 22.862C8.27737 22.801 5.3436 20.9902 3.34716 17.5861C3.25507 17.4284 3.19674 17.2487 3.12306 17.0784C1.62906 13.9928 1.6219 10.9071 3.12409 7.82145C4.49427 4.91235 6.74449 3.08155 9.76831 2.33115C13.1953 1.47986 16.2723 2.2786 18.8961 4.62648C17.9383 5.6123 17.0101 6.56764 16.0421 7.56501C15.2398 6.86086 14.2513 6.3112 13.0848 6.15776C10.3014 5.79517 7.78926 7.24236 6.64216 9.87611C6.56746 10.0464 6.48662 10.2135 6.40885 10.3827C6.02409 11.7574 6.02307 13.1331 6.40782 14.5078C7.23362 16.5666 8.58845 18.0495 10.7425 18.6118C12.5046 19.0721 14.1664 18.7516 15.7198 17.7952C16.6796 17.028 17.4082 16.1031 17.6681 14.7768H12.2365V10.7579H21.7909C22.0498 12.3291 22.0089 13.9076 21.6415 15.4673"
          fill="#5087EB"
        />
      </svg>
    );
  }
);

export const GoogleWhite = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M21.6415 15.462C21.1964 17.3464 20.388 19.0301 18.9186 20.3154C17.0316 22.1956 14.7016 22.903 12.1536 22.862C8.27737 22.801 5.3436 20.9902 3.34716 17.5861C3.25507 17.4284 3.19674 17.2487 3.12306 17.0784C1.62906 13.9928 1.6219 10.9071 3.12409 7.82145C4.49427 4.91235 6.74449 3.08155 9.76831 2.33115C13.1953 1.47986 16.2723 2.2786 18.8961 4.62648C17.9383 5.6123 17.0101 6.56764 16.0421 7.56501C15.2398 6.86086 14.2513 6.3112 13.0848 6.15776C10.3014 5.79517 7.78926 7.24236 6.64216 9.87611C6.56746 10.0464 6.48662 10.2135 6.40885 10.3827C6.02409 11.7574 6.02307 13.1331 6.40782 14.5078C7.23362 16.5666 8.58845 18.0495 10.7425 18.6118C12.5046 19.0721 14.1664 18.7516 15.7198 17.7952C16.6796 17.028 17.4082 16.1031 17.6681 14.7768H12.2365V10.7579H21.7909C22.0498 12.3291 22.0089 13.9076 21.6415 15.4673"
          fill="white"
        />
      </svg>
    );
  }
);
