import type { SetState } from 'zustand';
import create from 'zustand';

interface INavBarStore {
  blurred?: boolean;

  setBlurred(blurred: boolean): void;
}

export const useNavBarStore = create<INavBarStore>((set: SetState<INavBarStore>) => ({
  blurred: false,
  setBlurred: (blurred: boolean) => set(() => ({ blurred })),
}));
