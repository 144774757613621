import { getCalendarAnnouncementIcon } from '@gik/calendar/utils/getCalendarAnnouncementIcon';
import bemBlock from '@gik/core/utils/bemBlock';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';

export interface ICalendarAnnouncementTileProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  title: string;
  iconName: string;
  selected?: boolean;
  horizontal?: boolean;
}

export function CalendarAnnouncementTile({
  type,
  iconName,
  title: label,
  selected,
  horizontal,
  className,
}: ICalendarAnnouncementTileProps) {
  const bem = bemBlock('calendar-announcement-tile');

  const icon = getCalendarAnnouncementIcon(iconName);

  return (
    <div className={bem(null, [{ horizontal }, { vertical: !horizontal }, { selected }, { [type]: type }], className)}>
      <SvgIcon className={bem('icon')} Icon={icon} />
      <span className={bem('label')}>
        <HTMLParser rawHtml={label} />
      </span>
    </div>
  );
}
