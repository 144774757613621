import { AnalyticsEvents, analyticsToDataLayerEvents } from '@gik/analytics/utils/Events';
import { trackEvent } from '@gik/analytics/utils/Matomo';
import pick from '@gik/core/utils/pick';

interface DataLayerEvent {
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

export interface IAnalyticsProps<T = string> {
  [key: string]: T;
}

interface EventData<T extends IAnalyticsProps> {
  eventName: AnalyticsEvents;
  eventData: T;
  gaActionKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[];
  gaLabelKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[];
  userEmail?: string;
}

const eventQueue: EventData<IAnalyticsProps>[] = [];
function queueEvent<T extends IAnalyticsProps>(eventData: EventData<T>) {
  // @ts-ignore
  eventQueue.push(eventData);
  if (timeoutRef === null) {
    processQueue();
  }
}

const timeoutInterval = 1500;
let timeoutRef: NodeJS.Timeout = null;
function processQueue() {
  const event = eventQueue.shift();
  if (event) {
    fireEventInternal(event.eventName, event.eventData, event.gaActionKeysFunc, event.gaLabelKeysFunc, event.userEmail);
  }

  timeoutRef = setTimeout(() => {
    if (eventQueue.length > 0) {
      processQueue();
    } else {
      timeoutRef = null;
    }
  }, timeoutInterval);
}

/**
 * Fires an event to multiple services.
 * Please use the gaLabelKeysFunc and gaActionKeysFunc going forward so that we can deprecate GTM usage.
 * See https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits and https://support.google.com/analytics/answer/1033068?hl=en for info on sending GA events.
 *
 * @param eventName
 * @param eventData a flat dictionary of event data (string key/value pairs)
 * @param gaActionKeysFunc picks keys from eventData to provide to GA as the action property. If provided, no event will be fired to GTM. An action should always be provided.
 * @param gaLabelKeysFunc picks keys from eventData to provide to GA as the label property. If provided, no event will be fired to GTM. A label is optional but recommended.
 * @param userEmail send the event to Vero for the user matching this email address.
 */
export function fireEvent<T extends IAnalyticsProps>(
  eventName: AnalyticsEvents,
  eventData: T = {} as T,
  gaActionKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[],
  gaLabelKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[],
  userEmail?: string
) {
  queueEvent({ eventName, eventData, gaActionKeysFunc, gaLabelKeysFunc, userEmail });
}

async function fireEventInternal<T extends IAnalyticsProps>(
  eventName: AnalyticsEvents,
  eventData: T = {} as T,
  gaActionKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[],
  gaLabelKeysFunc?: (eventDataKeys?: (keyof T)[]) => (keyof T)[],
  userEmail?: string
) {
  if (!eventName?.length) return;

  const { logger } = await import('@gik/analytics/utils/logger');
  const { triggerEmailUserAnalyticsEvent } = await import('@gik/api/emailUsers/emailUsers');
  const mixpanel = (await import('mixpanel-browser')).default;
  const { FullStory } = await import('@fullstory/browser');
  const fullStoryOrganizationId = (await import('@gik/core/store/EnvStore')).useEnvStore.getState().FULLSTORY_ORG_ID;
  const ReactGA = (await import('react-ga')).default;
  const TagManager = (await import('react-gtm-module')).default;
  const googleAnalyticsId = (await import('@gik/core/store/EnvStore')).useEnvStore.getState().GOOGLE_ANALYTICS_ID;
  const googleTagManagerId = (await import('@gik/core/store/EnvStore')).useEnvStore.getState().GOOGLE_TAG_MANAGER_ID;
  const mixPanelToken = (await import('@gik/core/store/EnvStore')).useEnvStore.getState().MIXPANEL_TOKEN;
  const userId = (await import('@gik/core/store/UserStore')).useUserStore.getState().id;
  const browserSessionId = (await import('@gik/core/store/AppSessionStore')).useAppSessionStore.getState()
    .browserSessionId;
  const browserId = (await import('@gik/core/store/AppLocalStore')).useAppLocalStore.getState().browserId;
  const appVersion = (await import('@gik/core/utils/appVersion')).getAppVersion();
  const gaSerialize = (keys: (keyof T)[]) => (keys?.length ? JSON.stringify(pick(eventData, keys)) : '');

  const extraData = {
    userId,
    browserSessionId,
    browserId,
    appVersion,
  };

  const eventDataWithExtra = {
    ...eventData,
    ...extraData,
  };

  logger.info('Fire analytics event: ' + eventName, { eventName, eventDataWithExtra });

  if (!gaLabelKeysFunc && !gaActionKeysFunc) {
    // Google Tag Manager (Legacy)
    if (googleTagManagerId) {
      // logger.info('Legacy GTM event', { eventName });
      try {
        TagManager.initialize({
          gtmId: googleTagManagerId,
          events: {
            event: eventName,
            ...eventDataWithExtra,
          },
        });
      } catch (error) {
        logger.error(`Failed to send GTM event ${eventName}`, error);
      }
    }
  } else if (googleAnalyticsId) {
    // Google Analytics
    const eventKeys = Object.keys(eventDataWithExtra) as (keyof T)[];
    const gaLabel = gaSerialize(gaLabelKeysFunc?.(eventKeys));
    const gaAction = gaSerialize(gaActionKeysFunc?.(eventKeys));
    logger.info('GA event', {
      category: eventName,
      action: gaAction,
      label: gaLabel,
    });
    try {
      ReactGA.event({
        category: eventName,
        action: gaAction,
        label: gaLabel,
      });
    } catch (error) {
      logger.error(`Failed to send GA event ${eventName}`, error);
    }
  }

  // Mixpanel
  if (mixPanelToken) {
    try {
      // logger.info('Firing mixpanel event');
      mixpanel.track(eventName, { ...eventDataWithExtra });
    } catch (error) {
      logger.error(`Failed to send MixPanel event ${eventName}`, error);
    }
  }

  // Matomo
  try {
    const eventKeys = Object.keys(eventData) as (keyof T)[];
    const gaLabel = gaSerialize(gaLabelKeysFunc?.(eventKeys));
    const gaAction = gaSerialize(gaActionKeysFunc?.(eventKeys));

    // logger.info('Firing Matomo event');
    if (eventName?.length > 0 && gaAction?.length > 0)
      trackEvent(eventName, gaAction, gaLabel, JSON.stringify(extraData));
  } catch (error) {
    logger.error(`Failed to send Matomo event ${eventName}`, error);
  }

  if (fullStoryOrganizationId) {
    try {
      FullStory('trackEvent', {
        name: eventName,
        properties: eventDataWithExtra,
      });
    } catch (error) {
      logger.error(`Failed to send Fullstory event ${eventName}`, error);
    }
  }

  if (userEmail) {
    try {
      triggerEmailUserAnalyticsEvent({
        userEmail,
        eventName,
        eventParameters: eventData,
      });
    } catch (error) {
      logger.error(`Failed to trigger emailUser analytics event ${eventName}`, error);
    }
  }

  exposeToDataLayer(eventName, eventDataWithExtra);
}

export function fireShareEvent(shareLabel: string) {
  fireEvent(AnalyticsEvents.Share, {
    shareAction: 'GiveInkind',
    shareLabel,
  });
}

export function fireDonateEvent(shareLabel: string) {
  fireEvent(AnalyticsEvents.Donate, {
    shareAction: 'GiveInkind',
    shareLabel,
  });
}

function exposeToDataLayer<T extends IAnalyticsProps>(eventName: AnalyticsEvents, eventData: T = {} as T) {
  const dataLayerEventName = analyticsToDataLayerEvents[eventName];
  if (!dataLayerEventName) return;

  const baseEventData = {
    userId: eventData.userId,
    browserSessionId: eventData.browserSessionId,
  };

  let dataLayerEventData;

  switch (eventName) {
    case AnalyticsEvents.CreateClicked:
    case AnalyticsEvents.SearchButtonClicked:
      dataLayerEventData = {
        initiatedOn: eventData.location,
      };
      break;
    case AnalyticsEvents.CreateHelpClicked:
      dataLayerEventData = {
        initiatedOn: eventData.stepNumber,
      };
      break;
    case AnalyticsEvents.CreateStarted:
      dataLayerEventData = {
        step: eventData.stepNumber,
        stepData: {
          ...(() => {
            const data = { ...eventData };
            delete data.browserSessionId;
            delete data.userId;
            delete data.stepNumber;

            return data;
          })(),
        },
      };
      break;
    case AnalyticsEvents.InkindPageTourFinished:
      dataLayerEventData = {
        status: eventData.status,
      };
      break;
    case AnalyticsEvents.UnclaimedEventViewed:
      dataLayerEventData = {
        requestType: eventData.requestType,
        step: eventData.stepViewed,
      };
      break;
    case AnalyticsEvents.PremiumPageUpgradeModalOpened:
    case AnalyticsEvents.PremiumPageUpgrade:
      dataLayerEventData = {
        initiatedOn: eventData.initiatedOn,
        variant: eventData.variant,
      };
      break;
    case AnalyticsEvents.ResendTangoCard:
      dataLayerEventData = {
        inkindRouteId: eventData.inkindRouteId,
        card: eventData.item,
      };
      break;
    case AnalyticsEvents.SupporterDetailsViewed:
      dataLayerEventData = {
        supportGiven: eventData.supportGiven,
        isOrganizer: eventData.isOrganizer,
      };
      break;
    case AnalyticsEvents.SupportersExported:
      dataLayerEventData = {};
      break;
    case AnalyticsEvents.VisaCTAClicked:
      dataLayerEventData = {
        inkindRouteId: eventData.inkindRouteId,
      };
      break;
    case AnalyticsEvents.CheckoutError:
      dataLayerEventData = {
        errorMessage: eventData.errorMessage,
        initiatedOn: eventData.initiatedOn,
        inkindRouteId: eventData.inkindRouteId,
      };
  }

  pushToDataLayer(dataLayerEventName, {
    ...baseEventData,
    ...dataLayerEventData,
  });
}

function pushToDataLayer<T extends IAnalyticsProps<string | object>>(eventName: string, eventData: T = {} as T) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventData,
  });
}

type UserNoticeType =
  | 'errorNotification'
  | 'errorDialog'
  | 'errorBoundary'
  | 'errorCalendar'
  | 'errorPromoCode'
  | 'errorCheckout'
  | 'errorCreatePage'
  | 'errorStripe'
  | 'errorApiDisplay'
  | 'errorApiNetworkDisplay';
export function fireUserNotice(content: string, type: UserNoticeType, otherProps = {}) {
  return fireEvent(AnalyticsEvents.UserNotice, {
    content,
    type,
    ...otherProps,
  });
}
