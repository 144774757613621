import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useApi } from '@gik/core/api/swr/useApi';
import type { GIKSiteSettings } from '@gik/core/models/gik/SiteSettings';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';

export type ClearCacheParams = {
  identifiers?: number[] | string[];
  type: string;
};

export function useSiteSettings(config?: SWRConfigInterface) {
  return useApi<GIKSiteSettings>(`site-settings`, null, config);
}

export async function getSiteSettings() {
  return await dotnetApi.get('site-settings').json<GIKSiteSettings>();
}
