import { isBrowser } from '@/utils/environment';
import { FullStory, init } from '@fullstory/browser';
import { useUser } from '@gik/api/users/user';
import { useTouchDetect } from '@gik/core/hooks/useTouchDetect';
import { useAppLocalStore } from '@gik/core/store/AppLocalStore';
import { useAppSessionStore } from '@gik/core/store/AppSessionStore';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { isProduction } from '@gik/core/utils/environment';
import { useRouter } from 'next/router';
import React from 'react';
const excludedPathnames = ['/email-confirmation', '/products/amazon-prime-membership'];

const FS_SESSION_START_DELAY = 10 * 1000;

export function useFullStory() {
  const userId = useUserStore(userStore => userStore.id);
  const [recording, setRecording] = React.useState(false);
  const { pathname } = useRouter();
  const fullStoryOrganizationId = useEnvStore(state => state.FULLSTORY_ORG_ID);

  React.useEffect(() => {
    if (isBrowser() && fullStoryOrganizationId)
      init({
        orgId: fullStoryOrganizationId,
      });
  }, [fullStoryOrganizationId]);

  React.useEffect(() => {
    if (isBrowser()) {
      window['_fs_capture_on_startup'] = false;

      if (
        excludedPathnames.some(excludedPathname => pathname.toLowerCase().startsWith(excludedPathname.toLowerCase()))
      ) {
        if (isProduction) FullStory?.('shutdown');
      } else {
        setTimeout(() => {
          try {
            // TODO: only call FS API if service is initialized
            if (isProduction) FullStory?.('restart');
            setRecording(true);
          } catch (e) {}
        }, FS_SESSION_START_DELAY);
      }
    }
  }, [pathname]);

  const browserSessionId = useAppSessionStore(state => state.browserSessionId);
  const browserId = useAppLocalStore(state => state.browserId);
  const { data: user } = useUser(userId);

  useTouchDetect(
    () => {
      if (isBrowser()) {
        if (isProduction) FullStory?.('event', 'TouchStart');
      }
    },
    () => {
      if (isBrowser()) {
        if (isProduction) FullStory?.('event', 'TouchEnd');
      }
    }
  );

  React.useEffect(() => {
    if (isBrowser() && fullStoryOrganizationId && user && recording) {
      if (isProduction)
        FullStory?.('setIdentity', {
          uid: userId,
          properties: {
            displayName: user.fullName,
            email: user.emailAddress,
            application: 'nextjs',
            cookiesEnabled: navigator?.cookieEnabled,
            browserSessionId,
            browserId,
          },
        });
    }
  }, [browserId, browserSessionId, fullStoryOrganizationId, user, userId, recording]);
}
