import { useFeatureVideos } from '@gik/api/acf/gik-settings/featureVideos';
import { WPURLEncoder } from '@gik/core/api/base/hooks/WPURLEncoder';
import { timeoutDefaultValue } from '@gik/core/constants';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { bemFunction } from '@gik/core/utils/bemBlock';
import bemBlock from '@gik/core/utils/bemBlock';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import CareCalendarAside from '@gik/create/components/FeaturesAside/CareCalendarAside';
import DonationsAside from '@gik/create/components/FeaturesAside/DonationsAside';
import WishlistAside from '@gik/create/components/FeaturesAside/WishlistAside';
import { translationKeys } from '@gik/create/i18n/en';
import type { ICreateFlowFormValues } from '@gik/create/models/CreateFlowFormValues';
import i18n from '@gik/i18n';
import type { FormProps } from '@gik/ui/Form';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UIFeaturesType } from '@gik/ui/types';
import { UI } from '@gik/ui/UIManager';
import { LightboxMedia } from '@gik/ui/UIManager/UILightboxTypes';
import type { IMultiVideoDefinition } from '@gik/ui/Video';
import { Video } from '@gik/ui/Video';
import PlayIcon from '@heroicons/react/solid/PlayIcon';
import React from 'react';

export interface IFeaturesAsideProps extends FormProps {
  onChange?: (values: ICreateFlowFormValues) => void;
  trackCalendarVideoPlayed?: () => void;
  trackWishlistVideoPlayed?: () => void;
  trackDonationsVideoPlayed?: () => void;
}

const blockName = 'features-aside';

export default function FeaturesAside({
  onChange,
  trackCalendarVideoPlayed,
  trackWishlistVideoPlayed,
  trackDonationsVideoPlayed,
}: IFeaturesAsideProps) {
  const bem = bemBlock(blockName);
  const { careCalendarFormRef, wishlistFormRef, donationsFormRef } = React.useContext(CreatePageContext);

  const [careCalendarEnabled, setCareCalendarEnabled] = React.useState<boolean>(undefined);
  const [wishlistEnabled, setWishlistEnabled] = React.useState<boolean>(undefined);
  const [donationsEnabled, setDonationsEnabled] = React.useState<boolean>(undefined);

  const subStepIndex = useCreateFlowStore(state => state.subStepIndex);
  const { data: { feature_videos: featureVideos } = { feature_videos: null } } = useFeatureVideos();

  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);
  const videoPlatformSettings = isSmDown ? 'desktop_settings' : 'mobile_settings';

  React.useEffect(() => {
    let unsubscriber;

    switch (subStepIndex) {
      case 0:
        setTimeout(() => {
          unsubscriber = careCalendarFormRef.current?.subscribe('values.careCalendarEnabled', _careCalendarEnabled => {
            if (careCalendarEnabled !== _careCalendarEnabled) {
              setCareCalendarEnabled(_careCalendarEnabled);
            }
          });
        }, timeoutDefaultValue);
        break;
      case 1:
        setTimeout(() => {
          unsubscriber = wishlistFormRef.current?.subscribe('values.wishlistEnabled', _wishlistEnabled => {
            if (wishlistEnabled !== _wishlistEnabled) {
              setWishlistEnabled(_wishlistEnabled);
            }
          });
        }, timeoutDefaultValue);
        break;
      case 2:
        setTimeout(() => {
          unsubscriber = donationsFormRef.current?.subscribe('values.donationsEnabled', _donationsEnabled => {
            if (donationsEnabled !== _donationsEnabled) {
              setDonationsEnabled(_donationsEnabled);
            }
          });
        }, timeoutDefaultValue);
        break;
    }

    return () => {
      unsubscriber?.();
    };
    // eslint-disable-next-line
  }, [subStepIndex]);

  return (
    <aside
      className={bem(null, [
        {
          'substep-0': subStepIndex === 0,
        },
        {
          'substep-1': subStepIndex === 1,
        },
        {
          'substep-2': subStepIndex === 2,
        },
        {
          'substep-0-covered': !careCalendarEnabled,
        },
        {
          'substep-1-covered': !wishlistEnabled,
        },
        {
          'substep-2-covered': !donationsEnabled,
        },
      ])}
    >
      <Switch value={subStepIndex}>
        <Case value={0}>
          <FeatureWithThumbnail
            type={UIFeaturesType.CareCalendar}
            formValue={careCalendarEnabled}
            bem={bem}
            thumbnails={featureVideos?.[0]?.[videoPlatformSettings]?.feature_video_thumbnails}
            videoId={featureVideos?.[0]?.[videoPlatformSettings]?.feature_video_id}
            title={i18n.t(translationKeys.careCalendarTitle)}
            trackFunction={trackCalendarVideoPlayed}
          >
            <CareCalendarAside
              featureVideo={featureVideos?.[0]?.[videoPlatformSettings]?.feature_video_id}
              trackCalendarVideoPlayed={trackCalendarVideoPlayed}
            />
          </FeatureWithThumbnail>
        </Case>
        <Case value={1}>
          <FeatureWithThumbnail
            type={UIFeaturesType.Wishlist}
            formValue={wishlistEnabled}
            bem={bem}
            thumbnails={featureVideos?.[1]?.[videoPlatformSettings]?.feature_video_thumbnails}
            videoId={featureVideos?.[1]?.[videoPlatformSettings]?.feature_video_id}
            title={i18n.t(translationKeys.WishlistTitle)}
            trackFunction={trackWishlistVideoPlayed}
          >
            <WishlistAside
              onChange={onChange}
              featureVideo={featureVideos?.[1][videoPlatformSettings]?.feature_video_id}
              trackWishlistVideoPlayed={trackWishlistVideoPlayed}
            />
          </FeatureWithThumbnail>
        </Case>
        <Case value={2}>
          <FeatureWithThumbnail
            type={UIFeaturesType.Donations}
            formValue={donationsEnabled}
            bem={bem}
            thumbnails={featureVideos?.[2]?.[videoPlatformSettings]?.feature_video_thumbnails}
            videoId={featureVideos?.[2]?.[videoPlatformSettings]?.feature_video_id}
            title={i18n.t(translationKeys.FundraisingTitle)}
            trackFunction={trackDonationsVideoPlayed}
          >
            <DonationsAside
              onChange={onChange}
              featureVideo={featureVideos?.[2][videoPlatformSettings]?.feature_video_id}
              trackDonationsVideoPlayed={trackDonationsVideoPlayed}
            />
          </FeatureWithThumbnail>
        </Case>
      </Switch>
    </aside>
  );
}

export interface IFeatureWithThumbnailProps {
  type: UIFeaturesType;
  bem: bemFunction;
  formValue: boolean;
  thumbnails?: IMultiVideoDefinition;
  videoId?: string;
  title: string;
  trackFunction?: () => void;
}

export function FeatureWithThumbnail({
  type,
  bem,
  formValue,
  thumbnails,
  videoId,
  title,
  trackFunction,
  children,
}: React.PropsWithChildren<IFeatureWithThumbnailProps>) {
  const videoRef = React.useRef<HTMLVideoElement>();

  const cachedThumbnails: IMultiVideoDefinition = React.useMemo(() => {
    return {
      mp4: thumbnails.mp4 ? WPURLEncoder(thumbnails.mp4, null, false).toString() : undefined,
      webm: thumbnails.webm ? WPURLEncoder(thumbnails.webm, null, false).toString() : undefined,
    };
  }, [thumbnails]);

  function handlePlayClick() {
    trackFunction?.();

    UI.lightbox(
      [
        {
          mediaType: LightboxMedia.VIDEO,
          url: `https://www.youtube.com/watch?v=${videoId}`,
          videoProps: { autoPlay: true },
        },
      ],
      { title }
    );
  }

  return (
    <div className={bem('feature-with-thumbnail', [{ [type]: true }])}>
      {!formValue ? (
        <div className={bem('animation')}>
          <Video ref={videoRef} className={bem('thumbnail')} videos={cachedThumbnails} autoPlay loop playsInline />
          <div className={bem('overlay')} onClick={handlePlayClick}>
            <SvgIcon className={bem('play-icon')} Icon={PlayIcon} size={'3xl'} />
          </div>
        </div>
      ) : (
        <div className={bem('content')}>{children}</div>
      )}
    </div>
  );
}
