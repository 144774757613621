import { timeoutDefaultValue } from '@gik/core/constants';
import { useBemCN } from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import translationKeys from '@gik/i18n/en/core';
import { Button } from '@gik/ui/Button';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import ChevronUpIcon from '@heroicons/react/solid/ChevronUpIcon';
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { SvgIcon } from '../SvgIcon';

// TODO: there must be a better way of doing that
export type LineClampingOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface ILineClampProps {
  className?: string;
  lines: LineClampingOptions;
  showButton?: boolean;
  fadeOut?: boolean;
  block?: boolean;
  horizontal?: boolean;
  compactButton?: boolean;
  customButtonClick?: (ev: React.MouseEvent<HTMLDivElement>) => void;
  customButton?: (clamped: boolean) => React.ReactNode;
  tooltip?: boolean;
}

const blockName = 'line-clamp';

function LineClampComp({
  className,
  children,
  lines,
  showButton = true,
  fadeOut = true,
  compactButton = false,
  block,
  horizontal,
  customButton,
  customButtonClick,
  tooltip,
}: React.PropsWithChildren<ILineClampProps>) {
  const [clamped, setClamped] = React.useState<boolean>(true);
  const [overflows, setOverflows] = React.useState<boolean>(false);
  const [blockSize, setBlockSize] = React.useState<number>(null);
  const bem = useBemCN(blockName);

  const resizeObserver = React.useRef<ResizeObserver>(null);
  const textWrapperElRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (textWrapperElRef.current && !resizeObserver.current) {
      resizeObserver.current = new ResizeObserver(entries => {
        const entry = entries[0];

        const blockSizeHeight = (
          Array.isArray(entry?.contentBoxSize) ? entry.contentBoxSize?.[0] : entry.contentBoxSize
        )?.blockSize;

        // fallback value for browsers that dont fully support ResizeObserver API (Safari)
        const contentRectHeight = entry?.contentRect?.height;

        setBlockSize(blockSizeHeight ?? contentRectHeight);
      });
      resizeObserver.current.observe(textWrapperElRef.current);
    }

    return () => {
      resizeObserver.current?.disconnect();
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      // the if statement prevent a state update if the component was unmounted

      if (textWrapperElRef.current) setOverflows(textWrapperElRef.current?.scrollHeight > blockSize + 4);
    }, timeoutDefaultValue);
  }, [lines, textWrapperElRef.current?.scrollHeight, blockSize]);

  return (
    <div
      {...bem(
        null,
        [
          { clamped },
          { isClamped: clamped && overflows },
          { block },
          { horizontal },
          { 'fade-out': overflows && fadeOut },
          { [`lines-${lines}`]: true },
        ],
        className
      )}
    >
      <div
        ref={textWrapperElRef}
        {...bem('text-wrapper')}
        title={tooltip && overflows && clamped ? (children as string) : null}
      >
        {children}
      </div>

      {showButton && !(clamped && !overflows) && (
        <>
          {customButton ? (
            <div
              {...bem('more-button')}
              onClick={customButtonClick ? customButtonClick : () => setClamped(clamped => !clamped)}
            >
              {customButton(clamped)}
            </div>
          ) : compactButton ? (
            <div {...bem('compact-button-wrapper')}>
              <Button
                type="button"
                variant="white"
                pill
                {...bem('compact-button')}
                onClick={() => setClamped(clamped => !clamped)}
                append={<SvgIcon size="xs" Icon={clamped ? ChevronDownIcon : ChevronUpIcon} />}
              >
                {i18n
                  .t(clamped ? translationKeys.core.showMoreCompact : translationKeys.core.showLessCompact)
                  .toString()}
              </Button>
            </div>
          ) : (
            <div {...bem('button')}>
              <Button variant="primary-plain" onClick={() => setClamped(clamped => !clamped)}>
                {i18n.t(clamped ? translationKeys.core.showMore : translationKeys.core.showLess).toString()}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export const LineClamp = React.memo(LineClampComp);
