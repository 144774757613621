import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { Image } from '@gik/ui/Image';
import React from 'react';

export enum SignInFlowCalloutBlock {
  GENERAL = 'general',
  CALENDAR_CLAIM = 'calendar-claim',
  UPDATE_VIEW_ATTEMPT = 'update-view-attempt',
  MESSAGE_OR_COMMENT_ATTEMPT = 'message-or-comment-attempt',
}

export type ISignInFlowCalloutBlockProps = UIComponent & {
  callout?: SignInFlowCalloutBlock;
};

export function SignInFlowCallout({ callout, className, ...otherProps }: ISignInFlowCalloutBlockProps) {
  const bem = useBemCN('sign-in-flow-callout');

  const src = React.useMemo(() => {
    const assetsUrl = getAssetsUrl();
    const path = 'auth/img/SignInFlow/';

    let filename = 'general.png';
    switch (callout) {
      case SignInFlowCalloutBlock.CALENDAR_CLAIM:
        filename = 'calendar.png';
        break;
      case SignInFlowCalloutBlock.UPDATE_VIEW_ATTEMPT:
        filename = 'updates.png';
        break;
      case SignInFlowCalloutBlock.MESSAGE_OR_COMMENT_ATTEMPT:
        filename = 'message-or-comment.png';
        break;
    }

    return `${assetsUrl}${path}${filename}`;
  }, [callout]);

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <Image {...bem('callout-image', callout)} src={src} alt={'Callout block'} />
    </div>
  );
}
