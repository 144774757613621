import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

export function StickyHorizontal({ children }): React.ReactElement {
  const bem = bemBlock('sticky-horizontal');
  const [ref, setRef] = React.useState<HTMLElement>();
  const [pos, setPos] = React.useState<number>();

  function handleResize() {
    setPos(document.body.parentElement.scrollLeft);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [ref]);

  const style = {
    left: pos + 'px',
  };

  return (
    <div className={bem()} ref={setRef} style={style}>
      {children}
    </div>
  );
}
