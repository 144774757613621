import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IUserSignUpResponse } from '@gik/api/users/types';
import { auth } from '@gik/auth/auth';
import { useAuthStore } from '@gik/auth/store/AuthStore';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { getInkindPageViewHistory } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import { Button } from '@gik/ui/Button';
import type { FormRef, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormError, FormField } from '@gik/ui/Form';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

const blockName = 'signup-form';
export const signupFormId = 'signupForm';
export interface IUserSignupFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeat: string;
  firstNamePlaceholder?: string;
  lastNamePlaceholder?: string;
  subscribeToNewsletter?: boolean;
}

export interface ISignupFormProps {
  onLogin?(): void;
  onChange?: (values: object) => void;
  initialValues?: Partial<IUserSignupFormValues>;
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (doneResponse: IUserSignUpResponse) => void;
  onAttemptStart?: () => void;
  onAttemptEnd?: () => void;
  firstNamePlaceholder?: string;
  lastNamePlaceholder?: string;
  hideButtons?: boolean;
  hideEmailField?: boolean;
  trackingId?: AnalyticsEvents;
  buttonsOverride?: (valid: boolean, submitting: boolean, values: object) => React.ReactNode;
  onSubmitOverride?: (values: IUserSignupFormValues) => Promise<void>;
  className?: string;
  disableEmailField?: boolean;
  disabled?: boolean;
}

export const subscribeToNewsletterInitialValue = true;

const SignupFormComp = React.forwardRef<FormRef, ISignupFormProps>(function SignupForm(
  {
    initialValues,
    onChange,
    buttonsPortal,
    onSuccess,
    onLogin,
    onAttemptStart,
    onAttemptEnd,
    hideButtons = false,
    hideEmailField = false,
    trackingId,
    buttonsOverride,
    onSubmitOverride,
    disableEmailField,
    className,
    disabled,
  },
  ref
) {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const formRef = React.useRef<FormRef>();
  React.useImperativeHandle(ref, () => formRef.current);

  const setSignupFormValues = useAuthStore(state => state.setSignupFormValues);

  React.useEffect(() => {
    setSignupFormValues(initialValues);
    // eslint-disable-next-line
  }, []);

  function handleChange(formValues) {
    onChange?.(formValues);
    setSignupFormValues(formValues);
  }

  // TODO: remove duplication between signup form schema and user settings schema
  const schema: FormSchemaEntry[] = [
    {
      placeholder: 'First name',
      name: 'firstName',
      type: 'text',
      required: true,
      minLength: 2,
      maxLength: 255,
      props: { autoComplete: 'given-name', variant: 'default-solid', autoFocus: true },
    },
    {
      placeholder: 'Last name',
      name: 'lastName',
      type: 'text',
      required: true,
      minLength: 2,
      maxLength: 255,
      props: { autoComplete: 'family-name', variant: 'default-solid' },
    },
    {
      placeholder: 'Email address',
      name: 'email',
      type: 'email',
      required: true,
      minLength: 2,
      maxLength: 255,
      props: {
        variant: 'default-solid',
        autoComplete: 'username',
      },
    },
    {
      placeholder: 'Enter Password',
      name: 'password',
      type: 'password',
      required: true,
      minLength: 8,
      maxLength: 255,
      props: {
        autoComplete: 'new-password',
        variant: 'default-solid',
      },
    },
    {
      placeholder: 'Re-Enter Password',
      name: 'passwordRepeat',
      type: 'password',
      required: true,
      mustEqual: 'password',
      minLength: 8,
      maxLength: 255,
      props: {
        autoComplete: 'new-password',
        variant: 'default-solid',
      },
    },
    {
      name: 'subscribeToNewsletter',
      type: 'checkbox',
      default: subscribeToNewsletterInitialValue,
      props: {
        label: i18n.t(translationKeys.subscribeToNewsletter),
      },
    },
  ];

  async function handleSubmit(values: IUserSignupFormValues) {
    onAttemptStart?.();
    setErrorMessage(null);
    try {
      const history = getInkindPageViewHistory();
      const response = await auth.executeUserSignUp({
        emailAddress: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        subscribeToNewsletter: values.subscribeToNewsletter,
        inkindRouteId: history?.[0]?.inkindRouteId,
      });

      if (onSuccess) {
        onSuccess(response);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
    onAttemptEnd?.();
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmitOverride || handleSubmit}
      onChange={handleChange}
      schema={schema}
      ref={formRef}
      vertical
      id={signupFormId}
      restoreAfterUpdate
      className={bem(null, null, className)}
      trackingId={trackingId}
      autoComplete
      render={({ isValid, isSubmitting, values }) => {
        const buttons = buttonsOverride ? (
          buttonsOverride(isValid, isSubmitting, values)
        ) : (
          <div className={bem('buttons')}>
            <Button
              className={bem('btnSubmit')}
              variant="primary"
              type="submit"
              size="lg"
              loading={isSubmitting}
              disabled={!isValid}
              form={signupFormId}
              fullWidth
            >
              {t(translationKeys.formSubmitButton).toString()}
            </Button>
            {onLogin && (
              <p>
                Already have an account?{' '}
                <Button variant="primary-link" onClick={() => onLogin()}>
                  Login
                </Button>
              </p>
            )}
          </div>
        );

        return (
          <>
            {errorMessage && <FormError message={errorMessage} centered />}
            {!hideEmailField && <FormField name="email" disabled={disableEmailField || disabled === true} />}
            <section className={bem('fullNameFields')}>
              <FormField name="firstName" disabled={disabled === true} />
              <FormField name="lastName" disabled={disabled === true} />
            </section>
            <FormField name="password" disabled={disabled === true} />
            <FormField name="passwordRepeat" disabled={disabled === true} />
            <FormField name="subscribeToNewsletter" disabled={disabled === true} />

            <p className={bem('tos')}>
              By creating an account you are agreeing to the{' '}
              <Button variant={'primary-link'} externalLink href="/terms-service">
                Terms & Conditions
              </Button>
            </p>

            {buttonsPortal?.() && !hideButtons && ReactDOM.createPortal(buttons, buttonsPortal())}
            {!buttonsPortal?.() && !hideButtons && buttons}
          </>
        );
      }}
    />
  );
});

export const SignupForm = withComponentErrorBoundary(SignupFormComp);
