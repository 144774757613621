import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { claimsApi } from '@gik/api/calendar';
import type {
  ICalendarEventClaimCreatePayload,
  ICalendarEventClaimUpdatePayload,
} from '@gik/api/calendar/calendarClaims';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories, useServicesByEventTypeId } from '@gik/api/calendar/serviceProductBundle';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUserPages } from '@gik/api/inkinds/userPages';
import { useUser } from '@gik/api/users/user';
import { CalendarUtil, ServiceModule } from '@gik/calendar';
import { DoorDashClaimSuccess } from '@gik/calendar/components/ClaimEvent/services/doordash/DoorDashClaimSuccess';
import { DoorDashInfoBox } from '@gik/calendar/components/ClaimEvent/services/doordash/DoorDashInfoBox';
import { GrubhubClaimSuccess } from '@gik/calendar/components/ClaimEvent/services/grubhub/GrubhubClaimSuccess';
import { GrubhubInfoBox } from '@gik/calendar/components/ClaimEvent/services/grubhub/GrubhubInfoBox';
import { PartnerClaimForm } from '@gik/calendar/components/ClaimEvent/services/partner/PartnerClaimForm';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import {
  CalendarEventTypeIds,
  CalendarServiceCategoryIds,
  CalendarServiceIds,
} from '@gik/calendar/models/CalendarEventTypes';
import type { ICalendarClaim } from '@gik/calendar/models/Claim';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { canSeeClaimOwner, getRequestDetailsTabs } from '@gik/calendar/utils/CalendarClaimUtils';
import type { CheckoutFormStepId } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { ClaimPaymentConfirmation } from '@gik/checkout/components/PaymentConfirmation/ClaimPaymentConfirmation';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type IWordpressDoorDashService from '@gik/core/models/wordpress/services/WordpressDoorDashService';
import type IWordpressGrubhubService from '@gik/core/models/wordpress/services/WordpressGrubhubService';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { Image } from '@gik/ui/Image';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { TabbedView } from '@gik/ui/TabbedView';
import { UI } from '@gik/ui/UIManager';
import { StatusCodes } from 'http-status-codes';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';
import { afterClaimSucceeded, unclaimCalendarEvent } from '../EventForm/utils';
import CalendarClaimCheckoutForm from './CalendarClaimCheckoutForm';
import { CalendarClaimFooter } from './CalendarClaimFooter';
import CalendarClaimHeader from './CalendarClaimHeader';
import ClaimFailedMessage from './ClaimFailedMessage';
import { EventServicesSelectList } from './EventServicesSelectList';
import GiftCardServiceForm from './services/gift-card/GiftCardServiceForm';
import { ProvideMyselfClaimForm } from './services/provide-myself/ProvideMyselfClaimForm';
import { ProvideMyselfClaimSuccess } from './services/provide-myself/ProvideMyselfClaimSuccess';

export interface ICalendarClaimEvent extends React.HTMLAttributes<HTMLDivElement> {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  modalContentId?: string;
  privateClaim?: boolean;

  setSelectedEvent?(event: ICalendarEvent);
  setSelectedEntry?(entry: ICalendarEntry);

  stepsNavPortal?: () => HTMLElement;
  buttonsPortal?: () => HTMLElement;
  updateModalTitle?(title: string): void;
  setModalClosable?(closable: boolean): void;

  onClose?(): void;

  // onFailed?(message: string): void;
  onSuccess?(): void;
  onFailed?(): void;
  onConflict?(): void;

  onEditSuccess?(): void;
  onStepChange?: (step: CheckoutFormStepId) => void;
  onStepProgression?: (step: CheckoutFormStepId, index: number) => void;

  onUnclaim?(): void;
  setOnDialogBack?(fn: () => void): void;
  setDialogFooterClass?(className: string): void;

  closeButtonOverrideRef?: React.MutableRefObject<() => Promise<boolean>>;
}

export interface IClaimFormValues {
  description: string;
  note: string;
}

export function CalendarClaimEvent({
  event,
  entry,
  modalContentId,
  className,
  privateClaim,
  buttonsPortal,
  stepsNavPortal,
  updateModalTitle = noop,
  onSuccess = noop,
  onFailed = noop,
  onConflict = noop,
  onEditSuccess = noop,
  onClose = noop,
  setOnDialogBack,
  onUnclaim = noop,
  setSelectedEntry = noop,
  setSelectedEvent = noop,
  setModalClosable,
  onStepChange,
  onStepProgression,
  setDialogFooterClass,
  closeButtonOverrideRef,
  ...otherProps
}: ICalendarClaimEvent): React.ReactElement {
  const bem = bemBlock('calendar-claim-event');

  const [serviceCategoryId, setServiceCategoryId] = React.useState<number>();
  const [serviceCategorySlug, setServiceCategorySlug] = React.useState<string>();
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const [service, setService] = React.useState<IWordpressService>();
  const [saving, setSaving] = React.useState<boolean>(false);
  const savingRef = React.useRef<boolean>();
  savingRef.current = saving;
  const [success, setSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  // new claim info after it is successfully created (response from create/update endpoint)
  const [claim, setClaim] = React.useState<ICalendarClaim>();

  const isMd = useBreakpoint(Breakpoint.MD);

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const { data: services } = useServices();
  const { data: serviceCategories } = useServiceCategories();

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const userId = useUserStore(state => state.id);
  const { data: inkind } = useInkind(inkindRouteId);

  const {
    paymentConfirmationValues,
    selectedGiftcard,
    setSelectedGiftcard,
    setPrivateClaim,
    claimFailErrorCode,
    setClaimConflict,
    resolveGiftCardClaimConflictValues,
  } = useCalendarStore(state => ({
    paymentConfirmationValues: state.paymentConfirmationValues,
    selectedGiftcard: state.selectedGiftcard,
    setSelectedGiftcard: state.setSelectedGiftcard,
    setPrivateClaim: state.setPrivateClaim,
    claimFailErrorCode: state.claimFailErrorCode,
    setClaimConflict: state.setClaimFailErrorCode,
    resolveGiftCardClaimConflictValues: state.resolveGiftCardClaimConflictValues,
  }));

  React.useEffect(() => {
    setModalClosable(!resolveGiftCardClaimConflictValues);
  }, [setModalClosable, resolveGiftCardClaimConflictValues]);

  const eventType = calendarEventTypes?.find(type => type.id === entry.typeId);

  const { data: serviceProductBundles } = useServiceCategories();
  const { data: matchingServices } = useServicesByEventTypeId(eventType?.id);

  const { data: userPages, mutate: mutateUserPages } = useUserPages(userId, { allPages: true });
  const isFollowing = userPages?.find(page => page.routeId === inkindRouteId);

  const { data: user } = useUser(userId);

  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  const isClaimed = !!entry.claim;
  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);
  const isClaimer = userId && supporter?.id === userId;
  const { t } = useTranslation();

  let allowedServiceIds = entry.allowedServiceIds;

  // if there are no allowedServices then just use all available services for this event type
  if (!allowedServiceIds || allowedServiceIds.length === 0) {
    allowedServiceIds = matchingServices?.map(item => item.id);
  }

  const isLoading = !calendarEventTypes || !services || !serviceCategories;

  const entryTypeName = entry.typeName;

  React.useEffect(() => {
    if (!isLoading && !canEdit && !isClaimed) {
      Analytics.fireEvent(
        AnalyticsEvents.UnclaimedEventViewed,
        {
          requestType: entryTypeName,
          stepViewed: 'HowToHelp',
        },
        _ => ['requestType'],
        keys => keys.filter(key => key !== 'requestType')
      );
    }
  }, [isLoading, canEdit, isClaimed, entryTypeName]);

  React.useEffect(() => {
    if (!isLoading && !canEdit && !isClaimed && stepIndex === 1 && service) {
      Analytics.fireEvent(
        AnalyticsEvents.UnclaimedEventViewed,
        {
          requestType: entryTypeName,
          stepViewed: 'ClaimForm',

          helpType:
            service.id === CalendarServiceIds.SeniorCarePhoneCall
              ? 'Phone Call'
              : [ServiceModule.MEAL_GIFT_CARD.toString(), ServiceModule.GENERIC_GIFT_CARD.toString()].indexOf(
                  service.acf.service_module
                ) >= 0
              ? 'Gift Card'
              : service.acf.service_module === ServiceModule.DOORDASH ||
                service.acf.service_module === ServiceModule.GRUBHUB
              ? 'Order Online'
              : 'In Person',
        },
        _ => ['requestType'],
        keys => keys.filter(key => key !== 'requestType')
      );
    }
  }, [isLoading, canEdit, isClaimed, entryTypeName, stepIndex, service]);

  function handleBack() {
    if (serviceCategoryId === CalendarServiceCategoryIds.GiftCard) {
      setService(null);
      setStepIndex(0);
      return;
    }
    if (stepIndex === 0) {
      setService(null);
      return;
    }
    setStepIndex(stepIndex - 1);
  }

  function handleNext() {
    setStepIndex(stepIndex + 1);
  }

  function handleEditEvent() {
    onClose();
    setTimeout(() => {
      CalendarUtil.edit(event, entry);
    }, 500);
  }

  async function handleSelectService(id: number) {
    selectService(id);
  }

  const selectService = React.useCallback(
    (id: number) => {
      const selectedService = matchingServices.find(item => id === item.id);
      if (!selectedService) {
        throw new Error(`service id "${id}" not found`);
      }

      setService(selectedService);

      if (selectedService['service-category'][0] === CalendarServiceCategoryIds.GiftCard) {
        setStepIndex(1);
        return;
      }
      setStepIndex(0);
    },
    [matchingServices]
  );

  async function handleUnclaim() {
    if (saving) {
      return;
    }

    const response = await UI.confirm('Are you sure you want to unclaim?', {
      okButtonProps: { variant: 'danger' },
      okText: 'Unclaim',
    });
    if (!response) {
      return;
    }

    setSaving(true);
    savingRef.current = true;
    await unclaimCalendarEvent(entry, calendarEventTypes, userId);
    onUnclaim();

    // don't set saving to false to keep the buttons disabled
    // setSaving(false);
  }

  /**
   * select service when serviceProductBundles changes
   */
  React.useEffect(() => {
    if (!matchingServices || !serviceProductBundles) {
      return;
    }

    if (isClaimed) {
      // Select service by claim service slug
      const selectedService = services.find(service => service.slug === entry.claim.serviceSlug);
      if (!selectedService) {
        throw new Error(`service "${entry.claim.serviceSlug}" could not be found`);
      }
      selectService(selectedService.id);
      setStepIndex(1);
    } else {
      // Auto Select the first service if only one was provided
      if (allowedServiceIds?.length === 1) {
        selectService(allowedServiceIds[0]);
      } else {
        // select the first service by default on desktop
        if (isMd && !service) {
          const service: IWordpressService = matchingServices?.find(
            item => item.acf.service_module === 'provide-myself'
          );
          if (service) {
            selectService(service.id);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [matchingServices, serviceProductBundles]);

  React.useEffect(() => {
    if (!service || !services || !serviceCategories) {
      return;
    }

    const selectedCategoryId = service['service-category'][0];
    const serviceCategory = serviceCategories?.find(s => s.id === selectedCategoryId);
    if (!serviceCategory) {
      throw new Error('no service category found with id ' + selectedCategoryId);
    }

    setServiceCategoryId(serviceCategory.id);
    setServiceCategorySlug(serviceCategory.slug);
  }, [service, services, serviceCategories]);

  const hasMultipleServices = entry.allowedServiceIds?.length > 1;

  React.useEffect(() => {
    if (selectedGiftcard) {
      updateModalTitle(t(translationKeys.sendGiftCardModalTitle));
    } else {
      updateModalTitle(
        hasMultipleServices
          ? t(translationKeys.claimModalTitleMultipleServices)
          : t(translationKeys.claimModalTitleSingleService)
      );
    }
  }, [selectedGiftcard, t, updateModalTitle, hasMultipleServices]);

  if (isLoading) {
    return <LoadingSpinner center />;
  }

  const selectedCategoryId = service ? service['service-category'][0] : null;
  const serviceCategory = serviceCategories?.find(s => s.id === selectedCategoryId);

  async function handleSubmit(values: IClaimFormValues) {
    let partnerUrl: string;

    if (serviceCategoryId === CalendarServiceCategoryIds.LocalRestaurant) {
      switch (service.slug) {
        case ServiceModule.DOORDASH:
          partnerUrl = (service as IWordpressDoorDashService).acf.container_module_doordash?.service_url;
          break;
        case ServiceModule.GRUBHUB:
          partnerUrl = (service as IWordpressGrubhubService).acf.container_module_grubhub?.service_url_template;
          break;
      }
    }

    // setSaving(true);

    if (isClaimed) {
      const payload: ICalendarEventClaimUpdatePayload = {
        ...values,
      };
      if (serviceCategoryId === CalendarServiceCategoryIds.LocalRestaurant) {
        payload.partnerUrl = partnerUrl;
      } else {
        delete payload.partnerUrl;
      }
      const response = await claimsApi.updateClaim(entry.claim?.id.toString(), payload);

      if (!response.ok) {
        UI.alert('Please try again.', { title: 'Claim update failed' });
        return;
      }
      onEditSuccess();
      UI.notifySuccess(
        <>
          <header>Request Updated</header>
          Update any reminders you may have set on your personal calendar.
        </>
      );
    } else {
      const payload: ICalendarEventClaimCreatePayload = {
        ...values,
        serviceSlug: service.slug,
        calendarEntryId: entry.id,
        claimDate: event.startsAt,
      };

      if (serviceCategoryId === CalendarServiceCategoryIds.LocalRestaurant) {
        payload.partnerUrl = partnerUrl;
      }

      const response = await claimsApi.createClaim(payload);

      if (response.status !== StatusCodes.CREATED) {
        logger.error('Failed to create claim: ' + response.status);

        if (response.status === StatusCodes.CONFLICT) {
          // someone else modified this event
          setClaimConflict(StatusCodes.CONFLICT);
          useCalendarStore.getState().reloadFunction();
        } else if (response.status === StatusCodes.GONE) {
          // event was deleted
          setClaimConflict(StatusCodes.GONE);
          useCalendarStore.getState().reloadFunction();
        } else {
          // the API returned an unexpected error
          setErrorMessage('unknown_claim_conflict');
          // onFailed('unknown_claim_conflict');
        }
        return;
      }

      const claimInfo = (await response.json()) as ICalendarClaim;

      setClaim(claimInfo);
      afterClaimSucceeded(entry, claimInfo, eventType);
      if (!isFollowing) {
        // revalidate user pages to update the isFollowing state.
        mutateUserPages();
      }

      // show success screen
      setSuccess(true);
      // setSaving(false);
      updateModalTitle(t(translationKeys.confirmationModalTitle));
    }

    onSuccess();
  }

  function handleBackToClaim() {
    setPrivateClaim(false);
    setSelectedGiftcard(null);
    onStepChange(null);
    onStepProgression(null, null);
  }

  function handleGotoStep() {
    const contentEl = document.querySelector(`#${modalContentId}`);
    if (contentEl) {
      contentEl.scrollTop = 0;
    }
  }

  const requestDetailTabs = getRequestDetailsTabs(entry, canEdit, isClaimer);

  if (paymentConfirmationValues) {
    return (
      <ClaimPaymentConfirmation
        className={bem('payment-confirmation')}
        productName={paymentConfirmationValues.products[0].name}
        values={paymentConfirmationValues}
        entry={entry}
        event={event}
        eventType={eventType}
        eventDate={moment.utc(event.startsAt).format(DateTimeUtils.EmailDateFormat)}
        purchasedClaimedItem={claimFailErrorCode === StatusCodes.CONFLICT}
        purchasedDeletedItem={claimFailErrorCode === StatusCodes.GONE}
        buttonsPortal={buttonsPortal}
        onDone={onClose}
        inkindRouteId={inkindRouteId}
        onSuccess={onSuccess}
        closeButtonOverrideRef={closeButtonOverrideRef}
        setModalClosable={setModalClosable}
        shipping={paymentConfirmationValues.products.some(
          p => p.checkoutType === 'perfectgift' && p.productType === 'physical'
        )}
      />
    );
  }

  if (selectedGiftcard && !paymentConfirmationValues) {
    return (
      <CalendarClaimCheckoutForm
        event={event}
        entry={entry}
        serviceSlug={service?.slug}
        serviceCategorySlug={serviceCategory?.slug}
        onFailed={onFailed}
        onConflict={onConflict}
        setOnDialogBack={setOnDialogBack}
        setDialogFooterClass={setDialogFooterClass}
        onBack={handleBackToClaim}
        onGotoStep={handleGotoStep}
        buttonsPortal={buttonsPortal}
        stepsNavPortal={stepsNavPortal}
        setClosable={setModalClosable}
        calendarEventTypes={calendarEventTypes}
        onSuccess={onSuccess}
        onStepChange={onStepChange}
        onStepProgression={onStepProgression}
      />
    );
  }

  const editEventButton = canEdit;
  const unclaimButton = isClaimer;
  let backButton = !isClaimed && !!service;

  // on desktop the back button should only be visible on the second step
  // and never show it for the giftcard form
  if (isMd && (stepIndex !== 1 || serviceCategoryId === CalendarServiceCategoryIds.GiftCard)) {
    backButton = false;
  }

  const cartItem = entry.claim?.cartItems?.length ? entry.claim.cartItems[0] : null;

  return (
    <div className={bem(null, null, [className, `gik-calendar-event-type-theme--${eventType?.slug}`])} {...otherProps}>
      <CalendarClaimHeader
        entry={entry}
        event={event}
        eventType={eventType}
        showServiceType={
          allowedServiceIds?.length === 1 && serviceCategoryId !== CalendarServiceCategoryIds.ProvideMyself
        }
        hideTime={allowedServiceIds?.length === 1 && serviceCategoryId === CalendarServiceCategoryIds.GiftCard}
        showPetCareType={eventType.id === CalendarEventTypeIds.PetCare}
        serviceSlug={service?.slug}
        serviceCategorySlug={serviceCategorySlug}
      />

      {success ? (
        <div className={bem(null, [{ [`variant-${eventType?.slug}`]: eventType?.slug }], className)}>
          {serviceCategoryId === CalendarServiceCategoryIds.ProvideMyself && (
            <ProvideMyselfClaimSuccess
              addToCalendarLinks={claim?.addToCalendarLinks}
              entry={entry}
              event={event}
              buttonsPortal={buttonsPortal}
              onCancel={onClose}
              isFollowing={!!isFollowing}
            />
          )}
          {serviceCategoryId === CalendarServiceCategoryIds.LocalRestaurant && (
            <Switch value={service.slug}>
              <Case value="doordash">
                <DoorDashClaimSuccess
                  entry={entry}
                  event={event}
                  partnerUrl={claim?.partnerUrl}
                  addToCalendarLinks={claim?.addToCalendarLinks}
                  buttonsPortal={buttonsPortal}
                  isFollowing={!!isFollowing}
                />
              </Case>
              <Case value="grubhub">
                <GrubhubClaimSuccess
                  entry={entry}
                  event={event}
                  partnerUrl={claim?.partnerUrl}
                  addToCalendarLinks={claim?.addToCalendarLinks}
                  buttonsPortal={buttonsPortal}
                  isFollowing={!!isFollowing}
                />
              </Case>
            </Switch>
          )}
        </div>
      ) : (
        <main>
          {!isClaimed && allowedServiceIds?.length > 1 && (
            <EventServicesSelectList
              value={service?.id}
              onChange={handleSelectService}
              eventTypeId={eventType.id}
              allowedServiceIds={allowedServiceIds}
            />
          )}

          {stepIndex === 0 && (
            <>
              {renderPortal(
                <CalendarClaimFooter
                  saving={savingRef.current}
                  backButton={backButton}
                  onBack={handleBack}
                  onCancel={onClose}
                  unclaimButton={unclaimButton}
                  onUnclaim={handleUnclaim}
                  editEventButton={editEventButton}
                  onEditEvent={handleEditEvent}
                >
                  {service && <Button onClick={handleNext}>Next</Button>}
                </CalendarClaimFooter>,
                buttonsPortal
              )}
            </>
          )}

          {service && (
            <>
              {stepIndex === 0 && (
                <>
                  {service['service-category'][0] === CalendarServiceCategoryIds.LocalRestaurant && (
                    <Switch value={service.slug}>
                      <Case value={ServiceModule.DOORDASH}>
                        <DoorDashInfoBox entry={entry} event={event} service={service as IWordpressDoorDashService} />
                      </Case>
                      <Case value={ServiceModule.GRUBHUB}>
                        <GrubhubInfoBox entry={entry} event={event} service={service as IWordpressGrubhubService} />
                      </Case>
                    </Switch>
                  )}
                  <TabbedView tabs={requestDetailTabs} />
                </>
              )}
              {stepIndex === 1 && (
                <>
                  {serviceCategoryId === CalendarServiceCategoryIds.ProvideMyself && (
                    <ProvideMyselfClaimForm
                      saving={savingRef.current}
                      errorMessage={errorMessage}
                      entry={entry}
                      buttonsPortal={buttonsPortal}
                      backButton={backButton}
                      onBack={handleBack}
                      onUnclaim={handleUnclaim}
                      onCancel={onClose}
                      onSubmit={handleSubmit}
                      editEventButton={editEventButton}
                      unclaimButton={unclaimButton}
                      onEditEvent={handleEditEvent}
                    />
                  )}
                  {serviceCategoryId === CalendarServiceCategoryIds.LocalRestaurant && (
                    <PartnerClaimForm
                      entry={entry}
                      errorMessage={errorMessage}
                      saving={savingRef.current}
                      buttonsPortal={buttonsPortal}
                      backButton={backButton}
                      onBack={handleBack}
                      onUnclaim={handleUnclaim}
                      onCancel={onClose}
                      onSubmit={handleSubmit}
                      editEventButton={editEventButton}
                      unclaimButton={unclaimButton}
                      onEditEvent={handleEditEvent}
                    />
                  )}
                  {serviceCategoryId === CalendarServiceCategoryIds.GiftCard && (
                    <>
                      {!entry.claim || entry.claim.serviceCategorySlug !== 'gift-card' ? (
                        <GiftCardServiceForm
                          // service={service}
                          entry={entry}
                          errorMessage={errorMessage}
                          buttonsPortal={buttonsPortal}
                          backButton={backButton}
                          onBack={handleBack}
                          editEventButton={editEventButton}
                          onEditEvent={handleEditEvent}
                        />
                      ) : (
                        <div className={bem('giftcard-view')}>
                          <strong className={bem('giftcard-view-title')}>
                            Thanks for purchasing a {cartItem?.productName}
                          </strong>
                          <Image src={cartItem?.imageUrl} />
                        </div>
                      )}
                    </>
                  )}

                  {serviceCategoryId !== CalendarServiceCategoryIds.GiftCard && <TabbedView tabs={requestDetailTabs} />}
                </>
              )}
            </>
          )}
        </main>
      )}

      {claimFailErrorCode && <ClaimFailedMessage errorCode={claimFailErrorCode} />}
    </div>
  );
}
