import { isBrowser } from '@/utils/environment';
import { dateTimeFormat } from '@gik/core/utils/DateTimeUtils';
import { markFirstLandedGIKPage } from '@gik/core/utils/Tracking';
import moment from 'moment';
import type React from 'react';
import useCompletedPurchaseForPageAnalytics from './useCompletedPurchaseForPageAnalytics';
import useViewFeaturedPageAnalytics from './useViewFeaturedPageAnalytics';

const inkindPageViewHistoryKey = 'inkind-page-view-history';

export interface PageViewHistoryItem {
  numVisits: number;
  lastVisit: string;
  firstVisit: string;
}

export interface InkindPageViewHistoryItem extends PageViewHistoryItem {
  inkindRouteId: string;
}

interface InkindPageViewRecorderProps {
  inkindRouteId: string;
}

export function getInkindPageViewHistory(): InkindPageViewHistoryItem[] {
  if (!isBrowser()) return null;
  const serializedInkindViewHistory = isBrowser() && localStorage.getItem(inkindPageViewHistoryKey);
  return serializedInkindViewHistory ? JSON.parse(serializedInkindViewHistory) : [];
}

// TODO: convert this into a hook
export default function InkindPageViewRecorder({ inkindRouteId }: InkindPageViewRecorderProps): React.ReactElement {
  markFirstLandedGIKPage(inkindRouteId);
  useViewFeaturedPageAnalytics();
  useCompletedPurchaseForPageAnalytics();

  if (!isBrowser()) return null;

  const inkindViewHistory: InkindPageViewHistoryItem[] = getInkindPageViewHistory();

  const now = moment().format(dateTimeFormat);
  const createNewInkindPageViewHistoryItem = (): InkindPageViewHistoryItem => ({
    firstVisit: now,
    lastVisit: now,
    inkindRouteId,
    numVisits: 1,
  });

  const currentItem = inkindViewHistory.find(item => item.inkindRouteId === inkindRouteId);
  if (!currentItem) {
    inkindViewHistory.push(createNewInkindPageViewHistoryItem());
  } else {
    currentItem.numVisits++;
    currentItem.lastVisit = now;
  }

  inkindViewHistory.sort((a, b) =>
    moment(a.lastVisit, dateTimeFormat).isAfter(moment(b.lastVisit, dateTimeFormat)) ? -1 : 1
  );
  if (isBrowser()) localStorage.setItem(inkindPageViewHistoryKey, JSON.stringify(inkindViewHistory));
  return null;
}
