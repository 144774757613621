import { useEnvStore } from '@gik/core/store/EnvStore';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

export function useMixPanel() {
  const mixPanelToken = useEnvStore(state => state.MIXPANEL_TOKEN);

  useEffect(() => {
    if (mixPanelToken) {
      mixpanel.init(mixPanelToken);
      // TODO: consider identifying the user in mixpanel
    }
  }, [mixPanelToken]);
  return null;
}
