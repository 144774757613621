import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';

interface IPartnerButtonProps {
  url: string;
  canOrderNow: boolean;
  orderNowCopy: string;
  browseCopy: string;
  className?: string;
}

const blockName = 'partner-button';

export const PartnerButton = ({ canOrderNow, orderNowCopy, browseCopy, url, className }: IPartnerButtonProps) => {
  const bem = bemBlock(blockName);
  const copy = React.useMemo(() => (canOrderNow ? orderNowCopy : browseCopy), [canOrderNow, orderNowCopy, browseCopy]);

  return (
    <Button
      className={bem(null, null, className)}
      type="a"
      externalLink
      href={url}
      size="lg"
      append={<SvgIcon Icon={ChevronRightIcon} />}
    >
      {copy}
    </Button>
  );
};
