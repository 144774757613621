import { Analytics } from '@gik/analytics';
import { useCalendarEvents } from '@gik/api/calendar/calendar';
import { useCalendarAnnouncementTypes } from '@gik/api/calendar/calendarAnnouncementType';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { CalendarEmptyView, CalendarUtil, useCollectRecipientInfo } from '@gik/calendar';
import type {
  ICalendarAnnouncement,
  ICalendarDay,
  ICalendarEntry,
  ICalendarEvent,
} from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import type { User } from '@gik/core/models/gik/User';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import DateTimeUtils, { dateFormat } from '@gik/core/utils/DateTimeUtils';
import { convertDateFromBackend } from '@gik/core/utils/l10n';
import { scrollIntoView } from '@gik/core/utils/scroll';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { IOpenMojiProps } from '@gik/ui/OpenMoji';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/outline/ChevronRightIcon';
import type { Moment } from 'moment';
import moment from 'moment-timezone';
import dynamic from 'next/dynamic';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useLatest from 'react-use/lib/useLatest';
import usePrevious from 'react-use/lib/usePrevious';
import { CalendarDay } from './CalendarDay';
import { translationKeys } from './i18n/en';

const OpenMoji = dynamic<IOpenMojiProps>(() => import('@gik/ui/OpenMoji').then(mod => mod.OpenMoji));

export interface ICalendarDaysListProps extends React.HTMLAttributes<HTMLDivElement> {
  inkindRouteId?: string;
  disableCreate?: boolean;
  scrollToFirstEntry?: boolean;
  forceEmptyView?: boolean;
  claimSelection?: boolean;
  hidePastDays?: boolean;
  renderEmptyDays?: boolean;
  giftCardsOnly?: boolean;
  privateClaim?: boolean;
  currentCart?: CartItem[];
  products?: Product[];

  onNext?(): void;
  onPrev?(): void;
  onGotoMonth?(month: string): void;
  onInit?(reload: Function): void;
  onClaimEvent?(event: ICalendarEvent, entry: ICalendarAnnouncement | ICalendarEntry, routeId: string): void;
}

export function getCalendarDateFromMonth(selectedMonth: string) {
  return moment.utc(selectedMonth + '-01T00:00:01Z');
}

function CalendarDaysListComp({
  className,
  scrollToFirstEntry = true,
  forceEmptyView,
  inkindRouteId: _inkindRouteId,
  disableCreate,
  claimSelection,
  hidePastDays,
  giftCardsOnly,
  currentCart,
  products,
  renderEmptyDays = true,
  privateClaim,
  onPrev,
  onNext,
  onInit,
  onGotoMonth,
  onClaimEvent,
  ...otherProps
}: ICalendarDaysListProps): React.ReactElement {
  const bem = bemBlock('calendar-days-list');
  const { t } = useTranslation();
  const collectRecipientInfo = useCollectRecipientInfo();

  const [isCurrentMonth, setIsCurrentMonth] = React.useState<boolean>(true);

  const [scrollTimeout, setScrollTimeout] = React.useState<NodeJS.Timeout>();
  const [prevMonthName, setPrevMonthName] = React.useState<string>();
  const [nextMonthName, setNextMonthName] = React.useState<string>();
  const [firstStartTime, setFirstStartTime] = React.useState<Moment>();
  const [hasUpcomingEvents, setHasUpcomingEvents] = React.useState<boolean>(false);
  const [shouldPerformLookahead, setShouldPerformLookahead] = React.useState<boolean>(true);
  const [jumpPerformed, setJumpPerformed] = React.useState<boolean>(false);
  const jumpPerformedLatest = useLatest(jumpPerformed);

  const [reloading, setReloading] = React.useState<boolean>(false);
  const [initialScrollCompleted, setInitialScrollCompleted] = React.useState<boolean>(false);
  const [ignoreFirstScroll, setIgnoreFirstScroll] = React.useState<boolean>(false);

  const mainRef = React.useRef<HTMLElement>();

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);

  const { data: eventTypes } = useCalendarEventTypes();
  const { data: announcementTypes } = useCalendarAnnouncementTypes();

  const storeSelectedMonth = useCalendarStore(state => state.selectedMonth);
  const setSelectedMonth = useCalendarStore(state => state.setSelectedMonth);
  const setScrollToEntryById = useCalendarStore(state => state.setScrollToEntryById);

  const setDate = React.useCallback(
    (date: Moment) => {
      if (!date) return;

      setSelectedMonth(date.format(DateTimeUtils.MonthFormat));
      setPrevMonthName(date.clone().add(-1, 'month').format('MMM'));
      setNextMonthName(date.clone().add(1, 'month').format('MMM'));
    },
    [setSelectedMonth]
  );

  // React.useEffect(() => {
  //   setDate(moment(storeSelectedMonth));
  //   // eslint-disable-next-line
  // }, [storeSelectedMonth]);

  const requestDate = React.useMemo(() => getCalendarDateFromMonth(storeSelectedMonth), [storeSelectedMonth]);
  // local state variable to keep track of selected month

  const prevSelectedMonth = usePrevious(storeSelectedMonth);

  const { data: inkind } = useInkind(inkindRouteId);

  const {
    data: calendarEvents,
    mutate: mutateCalendarEvents,
    error: calendarEventsError,
    isValidating,
  } = useCalendarEvents(requestDate ? inkindRouteId : null, {
    fromDate: requestDate?.format('YYYY-MM'),
    toDate: requestDate?.clone().add(1, 'month').format('YYYY-MM'),
  });

  // const calendarEventsFiltered = React.useMemo(() => {
  //   const newData = [];

  //   return newData;
  // }, [calendarEvents]);

  const { days: dataByDays, thisMonthHasEvents } = useCalendarDays(
    storeSelectedMonth,
    calendarEvents?.events,
    calendarEvents?.entries,
    giftCardsOnly,
    claimSelection,
    currentCart,
    products,
    user,
    hidePastDays
  );

  // A ref to the mutate function is needed to be used in the reload function
  const mutateEventsRef = React.useRef<Function>();
  mutateEventsRef.current = mutateCalendarEvents;

  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);

  // ---------- Functions

  const reload = React.useCallback(() => {
    setReloading(true);
    return mutateEventsRef.current();
  }, []);

  function scrollToEntryById(entryId: string) {
    const elAppHeader = document.querySelector('.gik-app-header') as HTMLDivElement;
    let offset = -10;
    if (elAppHeader) {
      offset -= elAppHeader.offsetHeight;
    }
    const entryEl = document.querySelector(`[data-id='${entryId}']`) as HTMLDivElement;
    if (entryEl) {
      scrollIntoView(entryEl, { offset, container: mainRef.current });
    }
  }

  async function handleAddEvent(date?: Moment) {
    if (!canEdit) {
      return;
    }

    const currentDate = moment.tz(new Date(), 'GMT');

    // do not allow this action if the date is in the past
    if (date?.isBefore(currentDate, 'day')) {
      return;
    }

    await collectRecipientInfo();
    const currentMonth = moment().format(DateTimeUtils.MonthFormat);

    // use the date passed in the argument
    // if no date was passed use the storeSelectedMonth only if it is different from the current month
    // the current month should default to the current date while other months should default to the first day of that month
    const finalDate = date || (storeSelectedMonth !== currentMonth ? moment(storeSelectedMonth) : undefined);
    CalendarUtil.add(finalDate);
  }

  function handleEditEvent(event: ICalendarEvent, entry: ICalendarEntry) {
    if (!canEdit || claimSelection) {
      return;
    }
    CalendarUtil.edit(event, entry);
  }

  const scrollToCurrentDay = React.useCallback(async () => {
    if (!mainRef || !mainRef.current || !isCurrentMonth) {
      return;
    }
    const currentDay = mainRef.current.getElementsByClassName(`gik-calendar-days-list__day-${moment().format('DD')}`);

    if (!ignoreFirstScroll) {
      if (!currentDay || currentDay.length === 0) {
        // just scroll to top if current day can't be found
        if (mainRef.current) {
          mainRef.current.scrollTop = 0;
        }
        return;
      }

      const dayElement = currentDay[0] as HTMLDivElement;
      if (mainRef.current) {
        mainRef.current.scrollTop = dayElement.offsetTop;
      }
      setInitialScrollCompleted(true);
    }
    if (ignoreFirstScroll) {
      setIgnoreFirstScroll(false);
    }
  }, [ignoreFirstScroll, isCurrentMonth]);

  const scrollToNextUpcomingEventOrCurrentDay = React.useCallback(() => {
    if (!mainRef || !mainRef.current || !thisMonthHasEvents || ignoreFirstScroll) {
      return;
    }

    mainRef.current.scrollTop = 0;

    function delayScroll() {
      clearTimeout(scrollTimeout);
      setScrollTimeout(
        setTimeout(() => {
          scrollToNextUpcomingEventOrCurrentDay();
        }, 300)
      );
    }

    const currentEntriesSelector = '.gik-calendar-day__in-view:not(.gik-calendar-day__in-view--past-event)';

    const elEntries = mainRef.current.querySelectorAll(currentEntriesSelector);
    const elAppHeader = document.querySelector('.gik-app-header') as HTMLDivElement;
    let offset = -10;
    if (elAppHeader) {
      offset -= elAppHeader.offsetHeight;
    }

    // if calendar events have not been rendered yet to the dom wait a bit and try again later
    const nonEmptyDays = mainRef.current.querySelectorAll(
      `.gik-calendar-day:not(.gik-calendar-day--empty) ${currentEntriesSelector}`
    );

    if (!elEntries.length) {
      if (!mainRef.current) return;

      mainRef.current.scrollTop = 0;
      setIgnoreFirstScroll(false);
      scrollToCurrentDay();

      return;
    }

    if (!nonEmptyDays.length) {
      delayScroll();
      return;
    }

    if (!ignoreFirstScroll) {
      const boxElement = elEntries[0] as HTMLDivElement;
      if (mainRef.current) {
        mainRef.current.scrollTop = boxElement.offsetTop + offset;
      } // need to add the application's header height
      setInitialScrollCompleted(true);

      if (ignoreFirstScroll) {
        setIgnoreFirstScroll(false);
      }
    }

    // eslint-disable-next-line
  }, [mainRef, ignoreFirstScroll, scrollToCurrentDay, thisMonthHasEvents]);

  const handleGotoRequest = React.useCallback(
    (month: string) => {
      setInitialScrollCompleted(true);
      setShouldPerformLookahead(false);
      setIgnoreFirstScroll(true);
      if (mainRef.current) mainRef.current.scrollTop = 0;
      setSelectedMonth(month);
      //setMonth();
    },
    [setSelectedMonth]
  );

  // ---------- Effects

  /**
   * onMount effect
   */
  React.useEffect(() => {
    setScrollToEntryById(scrollToEntryById);
    onInit?.(reload);
    // eslint-disable-next-line
  }, []);

  const eventsUpcoming = React.useMemo(() => {
    if (!calendarEvents) return null;
    return calendarEvents.events.filter(a => {
      const now = moment.utc(); //requestDate;
      return moment(a.startsAt).isAfter(now.startOf('day'));
    });
  }, [calendarEvents]);

  React.useEffect(() => {
    // logger.log('upcoming', eventsUpcoming?.length);
    if (eventsUpcoming?.length > 0) {
      setHasUpcomingEvents(true);
    }
  }, [eventsUpcoming]);

  React.useEffect(() => {
    if (firstStartTime) {
      return;
    }
    if (!calendarEvents || !calendarEvents.events || !calendarEvents.events?.length) {
      return;
    }

    const _nextUpcomingEvent = convertDateFromBackend(eventsUpcoming[0]?.startsAt);
    setFirstStartTime(_nextUpcomingEvent);

    // check if any event occurs next month (lookahead)
    if (calendarEvents.events?.length && _nextUpcomingEvent.isAfter(requestDate, 'month') && shouldPerformLookahead) {
      setTimeout(() => {
        setIgnoreFirstScroll(false);
        setDate(_nextUpcomingEvent);
      }, timeoutDefaultValue);
    } else {
      setDate(requestDate);
    }

    if (calendarEvents?.events && dataByDays && !thisMonthHasEvents && !jumpPerformedLatest.current) {
      setJumpPerformed(true);
      setTimeout(() => {
        onNext();
      }, 100);
    }

    // eslint-disable-next-line
  }, [calendarEvents, firstStartTime]);

  // React.useEffect(() => {
  //   console.log('jumpPerformed', jumpPerformedLatest.current);
  //   if (calendarEvents?.events && dataByDays && !thisMonthHasEvents && !jumpPerformedLatest.current) {
  //     setJumpPerformed(true);
  //     setTimeout(() => {
  //       onNext();
  //     }, 100);
  //   }
  // }, [dataByDays, onNext, shouldPerformLookahead, thisMonthHasEvents, calendarEvents, jumpPerformedLatest]);

  // FIXME: we should only refresh after the user makes an action on the calendar (switches page or creates/claims/deletes something)
  const isRefreshing = /*isValidating || */ thisMonthHasEvents === false || reloading;
  const inkindPageTitle = inkind?.title;
  const recipientName = inkind?.recipientFullName;
  const isLoading = !calendarEvents || !eventTypes || !announcementTypes || !inkindPageTitle;

  //const selectedDate = getSelectedDate(_selectedMonth);

  React.useEffect(() => {
    setIsCurrentMonth(requestDate.isSame(moment(), 'month'));
  }, [requestDate]);

  const gotoUpcomingEvent = React.useCallback(() => {
    const _nextUpcomingEvent = convertDateFromBackend(eventsUpcoming?.[0]?.startsAt);
    setFirstStartTime(_nextUpcomingEvent);

    if (mainRef.current) mainRef.current.scrollTop = 0;

    setShouldPerformLookahead(false);
    setDate(_nextUpcomingEvent);
  }, [eventsUpcoming, setDate]);

  /**
   * Handle scroll and claim after calendar load/login
   */
  React.useEffect(() => {
    if (!calendarEvents || isLoading || !thisMonthHasEvents) {
      return;
    }

    setTimeout(() => {
      // don't scroll if initial scroll completed prevents scrolling from happening when an event is edited.
      if (!initialScrollCompleted) {
        if (scrollToFirstEntry) {
          scrollToNextUpcomingEventOrCurrentDay();
        } else {
          scrollToCurrentDay();
        }
      } else {
        if (storeSelectedMonth !== prevSelectedMonth) {
          scrollToNextUpcomingEventOrCurrentDay();
        }
      }
      setReloading(false);
    }, 100);
  }, [
    scrollToFirstEntry,
    calendarEvents,
    prevSelectedMonth,
    scrollToCurrentDay,
    scrollToNextUpcomingEventOrCurrentDay,
    storeSelectedMonth,
    initialScrollCompleted,
    isLoading,
    inkindPageTitle,
    user,
    recipientName,
    inkind,
    thisMonthHasEvents,
  ]);

  React.useEffect(() => {
    // reset initialScrollCompleted if selected month changes
    setInitialScrollCompleted(false);
  }, [storeSelectedMonth]);

  React.useEffect(() => {
    // if on mount we already have calendarEvents it means it came from the SWR cache
    // we need to wait for initial revalidation before attempting to scroll to an event
    if (calendarEvents) {
      setIgnoreFirstScroll(true);
    }
    //eslint-disable-next-line
  }, []);

  const timeout = React.useRef<NodeJS.Timeout>();

  // ---------- Rendering

  // render errors
  if (calendarEventsError) {
    if (!timeout.current) {
      timeout.current = setTimeout(() => {
        Analytics.fireUserNotice(calendarEventsError.message, 'errorCalendar');
      }, 100);
    }

    return (
      <div className={bem('error')}>
        {/* Todo replace with svg icon */}
        <OpenMoji name="thinking-face" width="200px" />
        <p>{t(translationKeys.calendarFatalError)}</p>
        <Button onClick={() => window.location.reload()}>{t(translationKeys.calendarFatalErrorRefresh)}</Button>
      </div>
    );
  }

  // render loader

  if (isLoading) {
    return (
      <div className={bem('loading')}>
        <LoadingSpinner />
      </div>
    );
  }

  // render empty view is there is data but no events
  if (forceEmptyView || (thisMonthHasEvents === false && !isValidating)) {
    // logger.log('test', eventsUpcoming, hasUpcomingEvents);
    return (
      <CalendarEmptyView
        onAddEvent={() => handleAddEvent()}
        canEdit={canEdit && !disableCreate}
        inkindRouteId={inkindRouteId}
        lastActiveMonth={calendarEvents.lastActiveMonth}
        isCurrentMonth={isCurrentMonth}
        hasUpcomingEvents={hasUpcomingEvents}
        onGotoLastActiveMonth={() => handleGotoRequest(calendarEvents.lastActiveMonth)}
        onGotoUpcomingEvent={() => gotoUpcomingEvent()}
      />
    );
  }

  return (
    <div
      className={bem(null, [{ loading: isRefreshing }], className)}
      {...otherProps}
      ref={el => {
        mainRef.current = el;
        return el;
      }}
    >
      {isRefreshing && (
        <div className={bem('loading-overlay')}>
          <LoadingSpinner />
        </div>
      )}
      <ul>
        {dataByDays?.map((day, index) => {
          if (hidePastDays && moment(day.date).isBefore(moment().startOf('day'))) {
            return null;
          }

          if (day.events.length === 0 && !renderEmptyDays) {
            return null;
          }

          return (
            <li key={index} className={bem(`day-${day.dateRaw.format('DD')}`)}>
              <CalendarDay
                privateClaim={privateClaim}
                date={day.dateRaw}
                events={day.events}
                entries={calendarEvents.entries}
                onAddEvent={handleAddEvent}
                onEditEvent={handleEditEvent}
                onClaimEvent={(event: ICalendarEvent, entry: ICalendarEntry, routeId: string) => {
                  if (claimSelection) {
                    onClaimEvent?.(event, entry, routeId);
                    return;
                  }
                  CalendarUtil.claim(event, entry, routeId);
                }}
              />
            </li>
          );
        })}
      </ul>

      <div className={bem('footer-actions', null, 'no-print')}>
        <Button
          squared
          onClick={onPrev}
          className={bem('btn-prev')}
          prepend={<SvgIcon size="sm" Icon={ChevronLeftIcon} />}
        >
          {prevMonthName}
        </Button>

        <Button
          squared
          onClick={onNext}
          className={bem('btn-next')}
          append={<SvgIcon size="sm" Icon={ChevronRightIcon} />}
        >
          {nextMonthName}
        </Button>
      </div>
    </div>
  );
}

export const CalendarDaysList = withComponentErrorBoundary(CalendarDaysListComp);

function useCalendarDays(
  selectedMonth: string,
  events: ICalendarEvent[],
  entries: ICalendarEntry[],
  giftCardsOnly: boolean,
  claimSelection: boolean,
  currentCart: CartItem[],
  products: Product[],
  user: User,
  hidePastDays: boolean
) {
  const days = React.useMemo(() => {
    const date = getCalendarDateFromMonth(selectedMonth);
    const totalDays = date.daysInMonth();
    const _days: ICalendarDay[] = [];

    let currentDate = date.clone();

    if (!events) {
      return _days;
    }

    for (let i = 0; i < totalDays; i++) {
      const dayEvents = filterEventsByDay(currentDate, events);

      let dayEventsFinal: ICalendarEvent[] = dayEvents;

      if (giftCardsOnly) {
        // filter out events that don't have giftcards
        const dayEventsFiltered = [];
        dayEvents.forEach(dayEvent => {
          // skip the event if it has already passed
          if (hidePastDays && moment.utc(dayEvent.startsAt).isBefore(moment.utc().startOf('day'))) {
            return;
          }

          // see if this event has a claim in the current cart
          const matchingEntry = entries?.find(entry => entry.id === dayEvent.entryId);

          if (!matchingEntry) return;

          if (matchingEntry.allowGiftCards) {
            dayEvent.cartItem = currentCart?.find(
              item =>
                item.createClaimRequest?.calendarEntryId === matchingEntry?.id &&
                item.createClaimRequest?.claimDate === dayEvent.startsAt
            );

            const entryIsClaimed = currentCart?.some(
              item =>
                item.createClaimRequest?.calendarEntryId === matchingEntry.id &&
                item.createClaimRequest?.claimDate === dayEvent.startsAt
            );

            const isClaimed = !!matchingEntry.claim || entryIsClaimed;

            dayEvent.product = products?.find(product => product.id === dayEvent.cartItem?.productId);
            dayEvent.supporterName = user?.fullName;

            if ((claimSelection && !isClaimed) || !claimSelection) {
              dayEventsFiltered.push(dayEvent);
            }
          }
        });

        dayEventsFinal = dayEventsFiltered;
      }

      _days.push({
        date: currentDate.clone().format(dateFormat),
        dateRaw: currentDate.clone(),
        events: dayEventsFinal.sort((a, b) => {
          return new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime();
        }),
      });
      currentDate = currentDate.add(1, 'day');
    }

    return _days;
  }, [
    selectedMonth,
    events,
    giftCardsOnly,
    claimSelection,
    hidePastDays,
    entries,
    currentCart,
    products,
    user?.fullName,
  ]);

  const thisMonthHasEvents = days?.some(day => day.events.length > 0);

  return {
    days,
    thisMonthHasEvents,
  };
}
function filterEventsByDay(date: Moment, events: ICalendarEvent[]) {
  return events.filter(item => {
    return date.isSame(convertDateFromBackend(item.startsAt), 'day');
  });
}
