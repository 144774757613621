import { logger } from '@gik/analytics/utils/logger';
import type { APIInfinitePagingConfig, APIWithPagination } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi, useApiInfinite } from '@gik/core/api/swr/useApi';
import { StatusCodes } from 'http-status-codes';
import type { SWRConfiguration } from 'swr';
import type {
  ICreateInkindPageRequest,
  ICreateInkindPageResponse,
  InkindPageAPIModel,
  PatchInkindPageRequest,
} from '../../core/models/gik/InkindPage';
import { BadRequest } from '../HttpErrors';

const path = 'inkinds';

function getParams(inkindRouteIds: string[], params?: APIWithPagination) {
  return {
    include: inkindRouteIds.join(','),
    perPage: 1000,
    ...params,
  };
}

export function getInkinds(inkindRouteIds: string[]) {
  return dotnetApi.get(path, {
    searchParams: getParams(inkindRouteIds),
  });
}

export function useInkinds(inkindRouteIds?: string[], config?: SWRConfiguration) {
  return useApi<InkindPageAPIModel[]>(inkindRouteIds ? path : null, getParams(inkindRouteIds || []), config);
}

export function useInkindsInfinite(
  inkindRouteIds?: string[],
  params?: APIWithPagination,
  config?: SWRConfiguration,
  paging?: APIInfinitePagingConfig
) {
  return useApiInfinite<InkindPageAPIModel>(
    inkindRouteIds?.length ? path : null,
    getParams(inkindRouteIds || [], params),
    config,
    paging
  );
}

export async function createInkind(
  request: ICreateInkindPageRequest,
  idempotencyKey: string
): Promise<ICreateInkindPageResponse> {
  const response = await dotnetApi.post(path, {
    timeout: 60000,
    json: request,
    headers: {
      'x-idempotency-key': idempotencyKey,
    },
  });

  if (!response.ok) {
    logger.error(`Failed to create inkind page`, response);
    switch (response.status) {
      case StatusCodes.BAD_REQUEST:
        throw new BadRequest('Failed to create page:');
      default:
        throw response;
    }
  } else {
    return response.json();
  }
}

export async function patchInkind(inkindRouteId: string, changes: PatchInkindPageRequest): Promise<void> {
  const url = `${path}/${inkindRouteId}`;
  const response = await dotnetApi.patch(url, {
    json: changes,
  });

  if (!response.ok) {
    logger.error(`Failed to update inkind page`, response);
    switch (response.status) {
      case StatusCodes.BAD_REQUEST:
        throw new BadRequest('Failed to update page:');
      default:
        throw response;
    }
  }
}
