import { usePrivacyPolicy } from '@gik/api/legal';
import { FetchProvider } from '@gik/core/components/DynamicComponent/FetchProvider';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { IDrawerProps } from '@gik/ui/Drawer/DrawerTypes';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type WordpressLegal from '../../models/wordpress/WordpressLegal';
import { ErrorPageSection } from '../Error/ErrorPageSection';
import type { LegalPageProps } from './LegalPage';
import { LegalPage } from './LegalPage';
import { LegalSkeleton } from './LegalSkeleton';

export function openPrivacyPolicySheet(props?: IDrawerProps) {
  return UI.modalSheet(() => <PrivacyPolicy />, {
    ...props,
    title: 'Privacy Policy',
    padded: true,
  });
}

export type PrivacyPolicyProps = {
  article?: WordpressLegal;
} & LegalPageProps;

function PrivacyPolicyComp(props: PrivacyPolicyProps) {
  const useFetch = () => {
    return usePrivacyPolicy(true);
  };

  return (
    <FetchProvider<WordpressLegal[], string>
      fetch={useFetch}
      transform={data => data[0]?.content.rendered}
      loadingComponent={<LegalSkeleton />}
    >
      {({ transformed: text }) => <LegalPage {...props} text={text} />}
    </FetchProvider>
  );
}

export const PrivacyPolicy = withComponentErrorBoundary(PrivacyPolicyComp, { ErrorFallback: ErrorPageSection });
