import TranslationUtils from '@gik/core/utils/TranslationUtils';

const translations = {
  consentModalTitle: 'Your Privacy Matters to Us',
  consentModalCopy:
    `At Give InKind, we value your privacy and strive to maintain a transparent and secure user experience. ` +
    `To improve our services and provide you with a personalized experience, we record user interactions on our ` +
    `website. We kindly request your consent to also collect additional data, such as your IP address, form data, ` +
    `numbers, and email addresses. This information will help us better understand your needs and preferences, ` +
    `allowing us to tailor our content and services to enhance your experience on our platform. More information can` +
    ` be found at the Give InKind `,
  consentModalOKText: 'I Consent',
  consentModalCancelText: 'I Do Not Consent',

  TermsDisclaimer2: 'terms',
  TermsDisclaimer3: 'and',
  TermsDisclaimer4: 'privacy policy',
  TermsDisclaimer5: 'We never sell or give your information to any third party.',
};

export default {
  analytics: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('analytics', translations);
