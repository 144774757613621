import { useGikSettings } from '@gik/api/acf/gik-settings/gikSettings';
import type { Product } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { Grid } from '@gik/ui/Grid';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import React from 'react';

export type PerfectgiftFaceplateSelectorProps = {
  product: Product;
  productType: OLProductType;
  value: string;
  onSelect?: (faceplate: PerfectGiftFaceplate) => void;
} & UIComponent;

export function getFaceplateURL(faceplate: PerfectGiftFaceplate, scale?: number): string {
  if (!faceplate.cloudinary_id) return faceplate.image_url;

  return `https://res.cloudinary.com/gift-card-granny/image/upload${scale ? `/c_scale,f_auto,w_${scale}` : ''}/v1/${
    faceplate.cloudinary_id
  }`;
}

export function PerfectgiftFaceplateSelector({
  children,
  onSelect,
  value,
  product,
  productType,
  ...otherProps
}: React.PropsWithChildren<PerfectgiftFaceplateSelectorProps>): React.ReactElement {
  const bem = useBemCN(`perfectgift-faceplate-selector`);
  const { data: gikSettings } = useGikSettings();

  // @ts-ignore TODO: @see inkind-nextjs/packages/@gik/core/models/gik/Product.ts FIX type union
  const faceplates: PerfectGiftFaceplate[] = product.variations
    .find(v => v.metaData?.find(m => m.key === 'pg_product_type' && m.value === productType))
    ?.metaData?.find(m => m.key === 'pg_faceplates')?.value;

  if (!gikSettings)
    return (
      <div {...otherProps} {...bem()}>
        <LoadingSpinner center />
      </div>
    );

  return (
    <div {...otherProps} {...bem()}>
      <Grid cols={3} {...bem('grid')}>
        {faceplates
          .filter(
            faceplate =>
              !gikSettings.acf.pg_faceplate_designs_blocklist?.find(
                blockedFaceplate => blockedFaceplate.cloudinary_id === faceplate.cloudinary_id
              )
          )
          .map(faceplate => {
            return (
              <div
                key={faceplate.uuid}
                {...bem('grid-tile', [{ selected: value === faceplate.uuid }])}
                onClick={() => onSelect(faceplate)}
              >
                {/* TODO: I'd still like a cloudinary onError fallback load the image_url*/}
                <img
                  src={getFaceplateURL(faceplate, 178)}
                  width={178}
                  height={116}
                  alt={faceplate.slug}
                  data-cloudinary-id={faceplate.cloudinary_id}
                />
              </div>
            );
          })}
      </Grid>
    </div>
  );
}
