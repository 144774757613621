import { useApi } from '@gik/core/api/swr/useApi';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import type { WalletEntity } from '@gik/platform-management-api-js';
import type { InkindPageAPIModel } from '@gik/models/gik/InkindPage';

type InkindWallet = WalletEntity & Pick<InkindPageAPIModel, 'title'>;

export function useUserWallets(userId?: string, config?: SWRConfigInterface) {
  return useApi<InkindWallet[]>(userId ? `users/${userId}/wallets` : null, null, config);
}
