import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpAcfV3Api } from '@gik/core/api/ky/wpApi';
import { useWPAcfV3Api } from '@gik/core/api/swr/useWPAcfV3Api';
import type { IWordpressGikSettings } from '@gik/core/models/wordpress/WordPressGikSettings';

const path = 'options/options';

export function getGikSettings() {
  return wpAcfV3Api.get(path).json<IWordpressGikSettings>();
}

export function useGikSettings(config?: SWRConfigInterface) {
  return useWPAcfV3Api<IWordpressGikSettings>(path, null, config);
}
