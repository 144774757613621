import { logger } from '@gik/analytics/utils/logger';
import { timeoutDefaultValue } from '@gik/core/constants';
import { useEnvStore } from '@gik/core/store/EnvStore';

export function swrLoggerMiddleware(useSWRNext) {
  return (key, fetcher, config) => {
    try {
      const response = useSWRNext(
        key,
        async (k, searchParams) => {
          const response = await fetcher(k, searchParams);
          if (useEnvStore.getState().KY_LOGGER_ENABLED === 'true') logger.info('KY: successful fetch', key);
          return response;
        },
        config
      );

      // comment out or uncomment those calls as needed
      setTimeout(() => {
        // console.info('---------------');
        // console.info('fallback (ISR):', config?.fallback ? 'not undefined' : undefined);
        // console.info('fallbackData (SSR): ', config?.fallbackData ? 'not undefined' : undefined);
        // console.info('SWR key:', key);
        // console.info('SWR data: ', response.data ? 'not undefined' : undefined);
        // console.info('---------------');
      }, timeoutDefaultValue);

      return response;
    } catch (e) {
      logger.error('swrLoggerMiddleware', { key, config, e });
      throw e;
    }
  };
}
