import { CalendarDaysList } from '@gik/calendar';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import moment from 'moment';
import React from 'react';
import { CalendarBar } from './CalendarBar';

export type ICalendarProps = UIComponent & {
  inkindRouteId: string;
  forceEmptyView?: boolean;
  disableCreate?: boolean;
  claimSelection?: boolean;
  hidePastDays?: boolean;
  giftCardsOnly?: boolean;
  privateClaim?: boolean;
  noInitialMonth?: boolean;
  currentCart?: CartItem[];
  products?: Product[];
  noHeightConstraint?: boolean;
  onClaimEvent?(event: ICalendarEvent, entry: ICalendarEntry, routeId: string): void;
};

function CalendarComp({
  inkindRouteId,
  disableCreate,
  claimSelection,
  onClaimEvent,
  hidePastDays,
  giftCardsOnly,
  currentCart,
  products,
  privateClaim,
  noHeightConstraint = false,
  forceEmptyView,
  noInitialMonth,
}: ICalendarProps): React.ReactElement {
  const bem = bemBlock('inkind-calendar');

  const { setSelectedMonth, setReloadFunction, selectedMonth } = useCalendarStore(state => ({
    setSelectedMonth: state.setSelectedMonth,
    setReloadFunction: state.setReloadFunction,
    selectedMonth: state.selectedMonth,
  }));

  const gotoPrevMonth = React.useCallback(() => {
    setSelectedMonth(moment(selectedMonth).add(-1, 'month').format(DateTimeUtils.MonthFormat));
  }, [selectedMonth, setSelectedMonth]);

  const gotoNextMonth = React.useCallback(() => {
    setSelectedMonth(moment(selectedMonth).add(1, 'month').format(DateTimeUtils.MonthFormat));
  }, [selectedMonth, setSelectedMonth]);

  const handleListInit = React.useCallback(
    (reload: Function) => {
      setReloadFunction(reload);
    },
    [setReloadFunction]
  );

  return (
    <div className={bem(null, [{ 'no-height-constraint': noHeightConstraint }])}>
      <header className="no-print">
        <CalendarBar className={bem('header')} onPrev={gotoPrevMonth} onNext={gotoNextMonth} />
      </header>
      <main>
        <CalendarDaysList
          renderEmptyDays={!claimSelection}
          onPrev={gotoPrevMonth}
          onNext={gotoNextMonth}
          onInit={handleListInit}
          onClaimEvent={onClaimEvent}
          inkindRouteId={inkindRouteId}
          disableCreate
          hidePastDays={hidePastDays}
          claimSelection={claimSelection}
          giftCardsOnly={giftCardsOnly}
          currentCart={currentCart}
          products={products}
          privateClaim={privateClaim}
        />
      </main>
    </div>
  );
}

export const Calendar = React.memo(
  withComponentErrorBoundary(CalendarComp),
  (prevProps, nextProps) => prevProps.inkindRouteId === nextProps.inkindRouteId
);
