import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'gift-card-item';

const translations = {
  giftedAmount: 'Gifted Amount:',
  from: 'From:',
  sendThankYou: 'Send Thank You',
  thankYouSent: 'Thank You Sent',
  btnRedeem: 'Redeem',
  btnPending: 'Pending',
  linkedStatus: 'Activated',
  unlinkedStatus: 'Pending',
  redeemedStatus: 'EGift',
  createdStatus: 'Pending',
  cancelledStatus: 'Merged',
  readyStatus: 'To Redeem',
  usedStatus: 'Used',
  eGiftStatus: 'eGift',
  onlineStatus: 'Online',
  mailedStatus: 'Mailed',
  sentStatus: 'Sent',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
