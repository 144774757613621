import ErrorLogger from '@gik/core/utils/ErrorLogger';
import type { Response as GotResponse } from 'got';

export function errorLogger(response: Response | GotResponse, request?) {
  const domResponse = response as Response;
  const gotResponse = response as GotResponse;

  if ((domResponse.status && gotResponse.statusCode) >= 500) {
    ErrorLogger.captureException(
      new Error(
        `${request ? request.method : gotResponse.method} ${request?.url || gotResponse.url} ${
          domResponse.status || gotResponse.statusCode
        } (${domResponse.statusText || gotResponse.statusMessage})`
      )
    );
  }

  if (domResponse.status && gotResponse.statusCode >= 400 && gotResponse.statusCode < 500) {
    ErrorLogger.captureWarning(
      new Error(
        `${request ? request.method : gotResponse.method} ${request?.url || gotResponse.url} ${
          domResponse.status || gotResponse.statusCode
        } (${domResponse.statusText || gotResponse.statusMessage})`
      )
    );
  }
}
