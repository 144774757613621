export function extractYouTubeVideoIdFromURL(videoUrl: string): string {
  const url = new URL(videoUrl);

  if (url.host === 'youtu.be') {
    return url.pathname.substring(1);
  } else if (url.pathname.startsWith('/embed')) {
    return url.pathname.split('/')[2];
  }

  const params = new URLSearchParams(url.search);
  return params.get('v');
}

export function extractVimeoVideoIdFromURL(videoUrl: string): string {
  const url = new URL(videoUrl);
  return url.pathname.split('/')[1];
}

export function isYouTubeUrl(url: string) {
  return url?.replace('.', '').includes('youtube');
}
