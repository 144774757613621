import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { SupportButton } from '@gik/inkind-page/components/Support/SupportButton';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { Venmo } from '@gik/ui/SvgIcon/BrandIcons/Venmo';
import React from 'react';

export type IVenmoSupportButtonProps = UIComponent & {
  value?: string;
  onClick?: () => void;
};

export function VenmoSupportButton({ value, className, onClick }: IVenmoSupportButtonProps) {
  const bem = useBemCN('venmo-support-button');

  return (
    <SupportButton
      {...bem(null, null, className)}
      variant={'venmo'}
      href={`https://account.venmo.com/u/${value?.split('@').join('')}`}
      onClick={onClick}
      icon={
        <SvgIcon
          style={{
            width: '4rem',
          }}
          Icon={Venmo}
        />
      }
    />
  );
}
