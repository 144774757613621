import bemBlock from '@gik/core/utils/bemBlock';
import type { UIFeaturesType } from '@gik/ui/types';
import React from 'react';

export interface IStepCardProps {
  step?: number;
  type: UIFeaturesType;
}

const blockName = 'step-card';

export function StepCard({ step, type, children }: React.PropsWithChildren<IStepCardProps>) {
  const bem = bemBlock(blockName);

  return (
    <div className={bem(null, [{ [type]: true }])}>
      <div className={bem('step-number')}>{step}</div>
      <div className={bem('content')}>{children}</div>
    </div>
  );
}

export function StepCardGroup({ children }: React.PropsWithChildren<{}>) {
  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { step: index + 1, ...child.props });
        } else {
          return child;
        }
      })}
    </>
  );
}
