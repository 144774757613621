import { useInkind } from '@gik/api/inkinds/inkind';
import { useProducts } from '@gik/checkout/api';
import type { CheckoutFormInitiatedOnType } from '@gik/checkout/types';
import { createCartItemFromProduct, productHasIntegratedCheckout } from '@gik/checkout/utils/productUtils';
import withSuspense from '@gik/core/components/suspended/withSuspense';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { useDevStore } from '@gik/core/store/DevStore';
import { useEnvStore } from '@gik/core/store/EnvStore';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindPageRecipientInfoComplete } from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import type { IPerfectgiftFaceplateUploaderResult } from '@gik/shop/components/PerfectgiftFaceplateButtons/PerfectgiftFaceplateButtons';
import { PerfectgiftFaceplatePreview } from '@gik/shop/components/PerfectgiftFaceplatePreview/PerfectgiftFaceplatePreview';
import { ProductActions } from '@gik/shop/components/products/ProductActions/ProductActions';
import { ProductDescription } from '@gik/shop/components/products/ProductDescription/ProductDescription';
import { getProductDetailsFormSchema } from '@gik/shop/components/products/ProductDetails/formSchema';
import type { IProductDetailsValues } from '@gik/shop/components/products/ProductDetails/ProductDetails';
import { ProductPreview } from '@gik/shop/components/products/ProductPreview/ProductPreview';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { defaultToggleValue, getDefaultFaceplate, getOpenLoopType, useOpenLoop } from '@gik/shop/hooks/useOpenLoop';
import { Decoder } from '@gik/ui/Decoder';
import type { FormProps, FormRef, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import { Sticky } from '@gik/ui/Sticky';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import type { FormApi } from 'final-form';
import React from 'react';
import type { ProductCheckoutType } from '@gik/models/gik/Product';
import { Debug } from '@gik/core/utils/asPlaceholderReactNode';

export interface OrderFormProps extends FormProps {
  productIds: number[];
  initialOrders?: CartItem[];
  initiatedOn?: CheckoutFormInitiatedOnType;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  onLoadComplete?(): void;
  inkindRouteId?: string;
  anonymous?: boolean;
  anonymousOverride?: boolean;
  forceCheckoutType?: ProductCheckoutType;
}

interface IOrderForm extends IProductDetailsValues {
  [x: `productType-${number}`]: OLProductType;
  [x: `customMessage-${number}`]: string;
  [x: `nameOnCard-${number}`]: string;
  [x: `uploadPhoto-${number}`]: IPerfectgiftFaceplateUploaderResult;
  [x: `faceplate-${number}`]: PerfectGiftFaceplate;
}

function OrderFormComp({
  onSubmit,
  onChange,
  onLoadComplete,
  buttons,
  buttonsPortal,
  initialOrders = [],
  productIds,
  initiatedOn,
  inkindRouteId,
  anonymousOverride,
  anonymous,
  forceCheckoutType,
  ...otherProps
}: OrderFormProps): React.ReactElement {
  const bem = useBemCN('order-form');

  const { data: products, error: productError } = useProducts({ productIds, forceCheckoutType });
  const { data: inkindPage, error: inkindPageError } = useInkind(inkindRouteId);
  const inkindPageHasAddress = useInkindPageRecipientInfoComplete(inkindPage);

  const formRef = React.useRef<FormRef>();
  const [formSchema, setFormSchema] = React.useState<FormSchemaEntry[]>();
  const [ready, setReady] = React.useState<boolean>(false);
  const lastFormValues = React.useRef<IOrderForm>({});

  const hasNameOnCardField = true;

  const getToggleValue = React.useCallback(
    function getToggleValue(index: number) {
      if (inkindRouteId && !inkindPageHasAddress) return 'digital';
      return (
        formRef.current?.values?.[`productType-${index}`] ?? initialOrders[index]?.productType ?? defaultToggleValue
      );
    },
    [initialOrders, inkindPageHasAddress, inkindRouteId]
  );

  React.useEffect(() => {
    if (ready) return;
    setReady(true);
    if (products) onLoadComplete?.();
  }, [products, onLoadComplete, ready]);

  React.useEffect(() => {
    const schema = products
      ?.map((product, i) => {
        const toggleValue = getToggleValue(i);
        return getProductDetailsFormSchema(product, toggleValue, hasNameOnCardField, anonymousOverride, i);
      })
      .flat();
    if (schema) setFormSchema(schema);
    // TODO: FIX CALLBACK DEPS
    // eslint-disable-next-line
  }, [hasNameOnCardField, products, anonymousOverride]);

  const handleFormChange = React.useCallback(
    function handleFormChange(values: IOrderForm) {
      if (!values) return;
      let changedProductType = false;
      const schema = products
        ?.map((product, i) => {
          const prop = `productType-${i}`;
          const toggleValue = values[prop];
          changedProductType =
            changedProductType || toggleValue !== (lastFormValues.current?.[prop] ?? defaultToggleValue);

          return getProductDetailsFormSchema(product, toggleValue, hasNameOnCardField, anonymousOverride, i);
        })
        .flat();

      if (schema && changedProductType) setFormSchema(schema);

      const fixedCartItem = products?.reduce(
        (prev, curr, i) => ({
          ...values,
          ...prev,
          [`item-${i}`]: {
            ...values[`item-${i}`],
            productType: values[`productType-${i}`],
            customMessage: values[`customMessage-${i}`],
            nameOnCard: values[`nameOnCard-${i}`],
            uploadPhoto: values[`uploadPhoto-${i}`],
            faceplate: values[`faceplate-${i}`],
          },
        }),
        {}
      );

      onChange?.(fixedCartItem);
      lastFormValues.current = values;
    },
    [hasNameOnCardField, onChange, products, anonymousOverride]
  );

  const handleOnSubmit = React.useCallback(
    (values: CartItem[], form: FormApi<object, object>): void | Promise<void> => {
      const cart: CartItem[] = products.map((product: Product, i: number) => {
        return {
          ...values[`item-${i}`],
          productType: values[`productType-${i}`],
          customMessage: values[`customMessage-${i}`],
          nameOnCard: values[`nameOnCard-${i}`],
          uploadPhoto: values[`uploadPhoto-${i}`],
          faceplate: values[`faceplate-${i}`],

          variationId:
            product.variations?.find(v =>
              v.metaData?.find(m => m.key === 'pg_product_type' && m.value === values[`productType-${i}`])
            )?.id ??
            values[`item-${i}`].variationId ??
            // FIXME: if its not OL, and we are using select denomination, need to select the correct variationId
            product.variations?.[0]?.id,
          anonymous: values[`anonymous-${i}`],
        };
      });

      if (onSubmit) return onSubmit(cart, form);
    },
    [onSubmit, products]
  );

  const initialValues = React.useMemo(() => {
    const initialValues: IOrderForm = {};
    if (products) {
      products.forEach((product, index) => {
        initialValues[`item-${index}`] =
          initialOrders[index] ||
          createCartItemFromProduct(
            product,
            inkindPage,
            initiatedOn === 'wishlist' || initiatedOn === 'add-from-wishlist'
          );

        const order = initialOrders[index];

        initialValues[`productType-${index}`] = product.isToggleable ? getToggleValue(index) : undefined;
        initialValues[`faceplate-${index}`] = product.isToggleable ? getDefaultFaceplate(product) : undefined;
        initialValues[`anonymous-${index}`] = anonymousOverride !== undefined ? anonymousOverride : anonymous; //initialOrders[index]?.anonymous === true;

        //"select" types should always start with a null price (even if the backend returns an initial price)
        if (product.type === 'select') product.price = order?.price || null;
      });
    }

    return initialValues;
  }, [anonymous, anonymousOverride, getToggleValue, initialOrders, initiatedOn, inkindPage, products]);

  const form = React.useMemo(() => {
    return (
      <Form
        ref={formRef}
        vertical
        onSubmit={handleOnSubmit}
        onChange={handleFormChange}
        // restoreAfterUpdate
        schema={formSchema}
        initialValues={initialValues}
        {...otherProps}
        render={renderProps => {
          return (
            <>
              <OrderProductSection
                key={`${products[0].id}-0`}
                product={products[0]}
                forceCheckoutType={forceCheckoutType}
                index={0}
                bem={bem}
                initiatedOn={initiatedOn}
                formRef={formRef.current}
                formValues={renderProps?.values}
                inkindPageHasAddress={inkindPageHasAddress}
                forSomeoneElse={!inkindRouteId}
                anonymousOverride={anonymousOverride}
              />

              {renderPortal?.(buttons && buttons?.(renderProps.isValid), () => buttonsPortal)}
            </>
          );
        }}
      />
    );
  }, [
    anonymousOverride,
    bem,
    buttons,
    buttonsPortal,
    forceCheckoutType,
    formSchema,
    handleFormChange,
    handleOnSubmit,
    initialValues,
    initiatedOn,
    inkindPageHasAddress,
    inkindRouteId,
    otherProps,
    products,
  ]);

  const skeleton = useDevStore(devStore => devStore.forceSkeletons);
  const isLoading = !products || (inkindRouteId && !inkindPage) || skeleton;
  const isError = isLoading && (productError || inkindPageError);

  if (productError || inkindPageError) return <div>Failed to fetch data</div>;
  if (isLoading || isError) return <Skeleton />;
  if (!products.length) return <div>No products were found</div>;

  return form;
}

interface IOrderProductSectionProps {
  product: Product;
  index: number;
  bem;
  initiatedOn?: CheckoutFormInitiatedOnType;
  formRef: FormRef;
  formValues: object;
  inkindPageHasAddress: boolean;
  forSomeoneElse?: boolean;
  useLiveEditor?: boolean;
  anonymousOverride?: boolean;
  forceCheckoutType?: ProductCheckoutType;
}
function OrderProductSection({
  product,
  index,
  bem,
  initiatedOn,
  formRef,
  formValues,
  inkindPageHasAddress,
  forSomeoneElse,
  useLiveEditor = false,
  anonymousOverride,
  forceCheckoutType,
}: IOrderProductSectionProps) {
  const hasIntegratedCheckout = productHasIntegratedCheckout(product);

  const [zoom, setZoom] = React.useState<number>(1);
  const [cardMarginTop, setCardMarginTop] = React.useState<number>(0);

  // const variation = product?.variations?.find(variation =>
  //   variation.metaData.find(m => m.key == 'pg_product_type' && m.value == 'physical')
  // );

  // @ts-ignore
  const availableFaceplates: PerfectGiftFaceplate[] = product.variations
    ?.find(m => m.metaData?.find(m => m.key === `pg_product_type` && m.value === 'physical'))
    ?.metaData?.find(m => m.key === 'pg_faceplates')?.value as PerfectGiftFaceplate[];

  const selectedCartItem: CartItem = {
    ...(formValues?.[`item-${index}`] ?? {}),
    productType: formValues?.[`productType-${index}`],
    customMessage: formValues?.[`customMessage-${index}`],
    nameOnCard: formValues?.[`nameOnCard-${index}`],
    uploadPhoto: formValues?.[`uploadPhoto-${index}`],
    faceplate: formValues?.[`faceplate-${index}`] ?? availableFaceplates?.[0],
  };

  const { croppedImage, showMode, hasPhysicalCard, designUploader, handleCustomImageChange, handleFaceplateChange } =
    useOpenLoop(product, selectedCartItem?.productType);

  const olCardWidth = 400;

  const handleResize = React.useCallback(() => {
    const screenWidth = window.screen.width - 64;
    let zoom = screenWidth / olCardWidth;
    const maxZoom = 1;
    if (zoom > maxZoom) zoom = maxZoom;

    if (window.innerWidth > 768) zoom = 0.8;
    setZoom(zoom);

    const el = document.querySelector('.gik-order-form__preview-wrapper') as HTMLElement;
    if (!el) return;
    const cardHeight = el.offsetHeight;

    const mt = -cardHeight * 0.7 + 30;

    setCardMarginTop?.(mt);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  React.useEffect(() => {
    const uploadPhotoFormValue = formRef?.values?.[`uploadPhoto-${index}`];
    const faceplateFormValue = formRef?.values?.[`faceplate-${index}`];

    if (uploadPhotoFormValue) void handleCustomImageChange(uploadPhotoFormValue);
    if (faceplateFormValue) handleFaceplateChange(faceplateFormValue);
  }, [formRef?.values, handleCustomImageChange, handleFaceplateChange, index]);

  const anonymousEnabled = useEnvStore(state => state.USE_ANONYMOUS_DONATIONS) === 'true';

  const noPhysical = !forSomeoneElse && inkindPageHasAddress;

  return (
    <div key={`${product.id}-${index}`} {...bem('item')}>
      <header>
        <Sticky marginTop={cardMarginTop}>
          <div>
            <div {...bem('preview-wrapper')}>
              {(designUploader || hasPhysicalCard) && useLiveEditor ? (
                <div className="gik-product-preview">
                  <PerfectgiftFaceplatePreview
                    expiryMonth={selectedCartItem?.expiryMonth}
                    faceplate={selectedCartItem?.faceplate}
                    customImage={croppedImage?.objectUrl}
                    recipientName={selectedCartItem.nameOnCard}
                    customMessage={selectedCartItem?.customMessage}
                    showMode={showMode}
                    overlay={getOpenLoopType(product)}
                    style={{ zoom, margin: '0 auto' }}
                  />
                </div>
              ) : (
                <ProductPreview product={product} hasPhysicalCard={hasPhysicalCard} />
              )}
            </div>
            <div {...bem('preview-fade')}></div>
          </div>
        </Sticky>

        <main>
          <span {...bem('title')}>
            <Decoder text={product.name} />
          </span>
          <Debug data={product.checkoutType} />
          {hasIntegratedCheckout && <span {...bem('subtitle')}>on Give InKind</span>}
          {!hasIntegratedCheckout && (
            <span {...bem('subtitle')}>
              On <Decoder text={product.sources && product.sources[0] ? product.sources[0].name : 'Unknown'} />
            </span>
          )}

          {product.isToggleable && initiatedOn !== 'productPage' && !forSomeoneElse && (
            <Tooltip
              allowOnMobile
              text={'Physical variation not available to this page because it did not provide a recipient address.'}
              disabled={noPhysical}
            >
              <div className="productType-wrapper">
                <strong className="productType-title">Select Gift Card Type:</strong>
                <FormField name={`productType-${index}`} disabled={!noPhysical} />
                <div className="productType-description">
                  {formValues[`productType-${index}`] === 'physical' && (
                    <span>Sent by mail inside a custom greeting card. For in-store and online use.</span>
                  )}
                  {formValues[`productType-${index}`] === 'digital' && (
                    <span>Sent via email (without greeting card). For online and phone purchases only.</span>
                  )}
                </div>
              </div>
            </Tooltip>
          )}

          {(initiatedOn !== 'productPage' || !!forceCheckoutType) && (
            <FormField
              name={`item-${index}`}
              hideLabel
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  // prevent the default action of the enter key of the numeric input which is to submit the form
                  event.preventDefault();

                  if (anonymousOverride !== undefined) {
                    const nextEl = document.querySelector('#cardCarrierForm-btn') as HTMLInputElement;
                    if (nextEl) nextEl.focus();
                  } else {
                    const nextEl = document.querySelector('[name="anonymous-0"]') as HTMLInputElement;
                    if (nextEl) nextEl.focus();
                  }
                }
              }}
            />
          )}

          {!forSomeoneElse && anonymousEnabled && selectedCartItem?.checkoutType !== CheckoutType.GikPremium && (
            <>
              <div {...bem('anonymous')}>
                <div>
                  <FormField name={`anonymous-${index}`} />
                </div>
                <div>
                  <Tooltip
                    allowOnMobile
                    text={
                      anonymousOverride !== undefined
                        ? 'All gifts in an order share the same anonymity setting. To send a gift or gifts under a different setting, complete this order then begin a separate purchase.'
                        : "Your name won't be shown to the Page Organizer or the Recipient"
                    }
                  >
                    <div className="tw-text-neutral-600">
                      <SvgIcon Icon={InformationCircleIcon} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* {product.isToggleable && faceplaces.length > 1 && selectedCartItem?.productType === 'physical' && (
            <FormField name={`uploadPhoto-${index}`} />
          )}
          {product.isToggleable && faceplaces.length > 1 && selectedCartItem?.productType === 'physical' && (
            <FormField name={`faceplate-${index}`} />
          )} */}

          {/* {product.isToggleable && selectedCartItem?.productType === 'physical' && (
            <FormField name={`nameOnCard-${index}`} />
          )}
          {product.isToggleable && selectedCartItem?.productType === 'physical' && (
            <FormField name={`customMessage-${index}`} />
          )} */}

          {initiatedOn === 'wishlist' && (
            <ProductActions
              product={product}
              initiatedOn={initiatedOn}
              showBuyButton={false}
              showWishlistButton={false}
              selectedCartItem={selectedCartItem}
              formRef={formRef}
            />
          )}
        </main>
      </header>
      <main>
        {initiatedOn !== 'wishlist' && (
          <ProductActions
            product={product}
            initiatedOn={initiatedOn}
            showBuyButton={false}
            showWishlistButton={false}
            selectedCartItem={selectedCartItem}
            formRef={formRef}
          />
        )}
        <ProductDescription product={product} toggleValue={selectedCartItem?.productType} />
      </main>
    </div>
  );
}

const skelBem = bemBlock('order-form-skeleton');
const Skeleton = () => (
  <div className={skelBem()}>
    <header>
      <main>
        <FrameBone className={skelBem('image')} />
      </main>
      <aside>
        <ParagraphBone words={4} className={skelBem('title')} />
        <ParagraphBone words={3} className={skelBem('on-platform')} />
        <FrameBone className={skelBem('dropdown')} />
      </aside>
    </header>
    <section className={skelBem('user-actions')}>
      <FrameBone className={skelBem('action')} />
      <FrameBone className={skelBem('action')} />
    </section>
    <section>
      <ParagraphBone words={12} className={skelBem('title')} />
      <ParagraphBone words={100} className={skelBem('title')} />
      <ParagraphBone words={100} className={skelBem('title')} />
    </section>
  </div>
);

export const OrderForm = withSuspense(withComponentErrorBoundary(OrderFormComp), Skeleton);
