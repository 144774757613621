import bemBlock from '@gik/core/utils/bemBlock';
import { scrollIntoView } from '@gik/core/utils/scroll';
import i18n from '@gik/i18n';
import { StatusCodes } from 'http-status-codes';
import React from 'react';
import { translationKeys } from './i18n/en';

export interface IClaimFailedMessageProps {
  errorCode: number;
}

export default function ClaimFailedMessage({ errorCode }: IClaimFailedMessageProps): React.ReactElement {
  const bem = bemBlock('claim-failed');

  const mainRef = React.useRef();

  React.useEffect(() => {
    if (mainRef.current)
      scrollIntoView(mainRef.current, {
        container: document.getElementsByClassName('gik-modal__content-wrapper')[0] as HTMLDivElement,
      });
  }, [mainRef]);

  function getErrorMessage(errorCode: number) {
    switch (errorCode) {
      case StatusCodes.CONFLICT:
        return i18n.t(translationKeys.claimFailConflict);
      case StatusCodes.GONE:
        return i18n.t(translationKeys.claimFailGone);
      default:
        return 'Unknown Claim Conflict';
    }
  }

  return (
    <p ref={mainRef} className={bem()}>
      {getErrorMessage(errorCode)}
    </p>
  );
}
