export enum CalendarEventTypeIds {
  Breakfast = 39015,
  Lunch = 39017,
  Dinner = 39016,
  SeniorCare = 39011,
  ChildCare = 39012,
  Groceries = 39013,
  HomeServices = 39014,
  Other = 39018,
  PetCare = 39019,
  Transportation = 41799,
  Announcement = 41874,
}

export enum CalendarServiceCategoryIds {
  ProvideMyself = 39009,
  LocalRestaurant = 39010,
  GiftCard = 40589,
}

export enum CalendarServiceCategorySlugs {
  LocalRestaurant = 'order-local-restaurant',
  ProvideMyself = 'provide-myself',
  GiftCard = 'gift-card',
}

export enum CalendarServiceIds {
  SeniorCarePhoneCall = 197904,
}
