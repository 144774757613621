import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories, useServicesByEventTypeId } from '@gik/api/calendar/serviceProductBundle';
import { useProductsByServiceProductBundle } from '@gik/api/products/productsByServiceProductBundle';
import { CalendarServicePicker } from '@gik/calendar';
import { CalendarEventTypeIds, CalendarServiceCategorySlugs } from '@gik/calendar/models/CalendarEventTypes';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { getStartTimeByTypeId } from '@gik/calendar/utils/EventUtils';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { Product } from '@gik/core/models/gik/Product';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import bemBlock from '@gik/core/utils/bemBlock';
import { fieldHasError } from '@gik/core/utils/form';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { Button } from '@gik/ui/Button';
import {
  Form,
  FormField,
  FormGroup,
  validateForm,
  type FormProps,
  type FormRef,
  type FormSchemaEntry,
  type ValidationOptions,
} from '@gik/ui/Form';
import { FormError } from '@gik/ui/Form/FormError';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { UI } from '@gik/ui/UIManager';
import type { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from 'react-use/lib/usePrevious';
import { timeToInt } from 'time-number';
import type { ICalendarServicesFormValues } from '../EventCreateForm/CalendarEventServicesForm';
import {
  CalendarEventServicesFormFieldNames,
  CalendarServicesForm,
} from '../EventCreateForm/CalendarEventServicesForm';
import { CalendarEventTypePicker } from '../EventTypes';
import { GiftCardsEditor, GiftCardsPicker } from '../GiftCards';
import CalendarEventTypeSelectForm from './CalendarEventTypeSelectForm';
import { CalendarTimeAndRepeatForm } from './CalendarTimeAndRepeatForm';
import { getEventFormSchema } from './EventFormSchema';
import { translationKeys } from './i18n/en';
import { isFoodType } from './utils';

export enum CalendarEventFormFieldNames {
  Title = 'title',
  Description = 'description',
  AllDay = 'allDay',
  StartDate = 'startDate',
  StartTime = 'startTime',
  EndDate = 'endDate',
  EndTime = 'endTime',
  Repeat = 'repeat',
  Weekdays = 'weekdays',
  EmailToPageFollowers = 'sendEmailToPageFollowers',
}

export interface ICalendarEventTimeAndRepeatFormValues {
  [CalendarEventFormFieldNames.StartDate]: string;
  [CalendarEventFormFieldNames.EndDate]: string;
  [CalendarEventFormFieldNames.StartTime]: number;
  [CalendarEventFormFieldNames.EndDate]: string;
  [CalendarEventFormFieldNames.EndTime]: number;
  [CalendarEventFormFieldNames.AllDay]: boolean;
  [CalendarEventFormFieldNames.Repeat]: number;
  [CalendarEventFormFieldNames.Weekdays]: boolean[];
}

export interface ICalendarEventFormValues extends ICalendarEventTimeAndRepeatFormValues {
  [CalendarEventFormFieldNames.Title]: string;
  [CalendarEventFormFieldNames.Description]: string;
  [CalendarEventFormFieldNames.EmailToPageFollowers]: boolean;

  eventTypeId?: number;

  allowGiftCards: boolean;
  allowedServiceIds?: number[];
  allowedGiftCardIds?: number[];
  numberRequired?: number;
  deliveryInstructions?: string;
  emergencyContactInfo?: string;
  pickupLocation?: string;
  dropoffLocation?: string;
  petCareTypeId?: string;
}

export interface ICalendarEventFormProps extends FormProps {
  errorMessage?: React.ReactNode;
  initialValues?: Partial<ICalendarEventFormValues>;
  giftCards?: Product[]; // used when editing events
  typeId: number;
  isClaimed?: boolean;
  seriesHasClaim?: boolean;
  formId?: string;
  isSubmitting?: boolean;
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmitForm?: (values: ICalendarEventFormValues, giftCardsOnly: boolean) => void | Promise<void>;
  onChange?: (values: ICalendarEventFormValues) => void | Promise<void>;
}

const emptyValues: ICalendarEventFormValues = {
  title: null,
  description: null,
  repeat: null,
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
  allDay: false,
  allowGiftCards: undefined,
  // interval: 1,
  // freq: null,
  weekdays: null,
  allowedServiceIds: null,
  sendEmailToPageFollowers: false,
};

function CalendarEventFormComp({
  className,
  typeId,
  isClaimed,
  seriesHasClaim,
  giftCards,
  formId = 'calendarEventForm',
  isSubmitting = false,
  buttonsPortal,
  errorMessage,
  buttons = () => null,
  initialValues,
  onChange = noop,
  onSubmitForm = noop,
  ...otherProps
}: ICalendarEventFormProps): React.ReactElement {
  const bem = bemBlock('calendar-event-form');
  const { t } = useTranslation();

  const formApi = React.useRef<FormRef>();

  const [giftCardsOnly, setGiftCardsOnly] = React.useState<boolean>(false);
  const [rerender, setRerender] = React.useState<boolean>(false);
  const [eventTypeId, setEventTypeId] = React.useState<number>(typeId);
  const [giftCardService, setGiftCardService] = React.useState<IWordpressService>();

  const { data: eventTypes } = useCalendarEventTypes();
  const { data: services } = useServices();
  const { data: serviceProductBundles } = useServiceCategories();
  const petCareType = eventTypes.find(item => item.id === CalendarEventTypeIds.PetCare);

  const [serviceProductBundleId, setServiceProductBundleId] = React.useState<number>();
  const serviceProductBundleIdPrev = usePrevious(serviceProductBundleId);

  const [selectedGiftcards, setSelectedGiftcards] = React.useState<Product[]>(giftCards);
  const [schema, setSchema] = React.useState<FormSchemaEntry[]>();

  const { setHasChangedGiftCards } = useCalendarStore();

  // retrieve default set of giftcards by event type to use for serviceProductBundle
  // this is passed to the giftcard picker to use as initial data
  // eslint-disable-next-line
  const { data: productBundleGiftCards, isValidating: productBundleGiftCardsInvalidating } =
    useProductsByServiceProductBundle(serviceProductBundleId);

  const { data: matchingServices } = useServicesByEventTypeId(eventTypeId);

  const [initialFormValues, setInitialFormValues] = React.useState<ICalendarEventFormValues>();

  const formRef = React.useRef<FormikProps<ICalendarEventFormValues>>();

  const repeatType = formRef.current?.values?.repeat;

  const isRepeatDisabled = seriesHasClaim && isClaimed;

  React.useEffect(() => {
    getEventFormSchema(
      eventTypeId,
      formRef.current?.values,
      petCareType?.acf.pet_care_types,
      giftCardsOnly,
      isRepeatDisabled
    ).then(_schema => {
      setSchema(_schema);
      setTimeout(formRerender, timeoutDefaultValue);
    });
  }, [eventTypeId, giftCardsOnly, isRepeatDisabled, petCareType, repeatType, seriesHasClaim]);

  function handleGiftCardChange(cards: Product[]) {
    setSelectedGiftcards(cards);
    // _selectedGiftcards.current = cards;
  }

  const getAllowedServiceIds = React.useCallback(
    (allowedServiceIds: number[]) => {
      // if no allowedServiceIds were found then just use all the services for this eventType
      if (!allowedServiceIds?.length) {
        allowedServiceIds = [];

        matchingServices?.forEach(item => {
          // don't add giftcard service if allowGiftCards is false
          if (item.acf.service_module === 'gift-card' && !initialValues?.allowGiftCards) {
            return;
          }

          allowedServiceIds.push(item.id);
        });
      }
      return allowedServiceIds;
    },
    [matchingServices, initialValues]
  );

  const updateAllowedServiceIds = React.useCallback(
    (allowedIds: number[]) => {
      // skip if form is not ready yet
      if (!formRef) return undefined;

      const _allowedServiceIds = getAllowedServiceIds(allowedIds);

      const matchingService = _allowedServiceIds?.find(id => {
        return matchingServices
          .find(service => service.id === id)
          ?.slug.endsWith(CalendarServiceCategorySlugs.GiftCard);
      });

      const isGiftcardsSelected = matchingService !== undefined;

      // setTimeout(() => {

      if (isGiftcardsSelected) {
        // formRef.current?.mutators.updateAllowGiftCards(true);
        formRef.current?.setFieldValue('allowGiftCards', true);
      } else {
        // formApi.current?.mutators.updateAllowGiftCards(false);
        formRef.current?.setFieldValue('allowGiftCards', false);
      }

      // update form values
      // formApi.current?.mutators.updateFormAllowedServiceIds(_allowedServiceIds);
      formRef.current?.setFieldValue('allowedServiceIds', _allowedServiceIds);

      setInitialFormValues({
        ...initialFormValues,
        allowGiftCards: isGiftcardsSelected,
        allowedServiceIds: _allowedServiceIds,
      });

      return _allowedServiceIds;
      // });
    },
    [getAllowedServiceIds, initialFormValues, matchingServices]
  );

  function handleChange(values: ICalendarEventFormValues) {
    // handle startTime change
    if (
      (values.startTime !== undefined &&
        values.startTime !== null &&
        values.startTime !== formRef.current?.values?.startTime &&
        values.startTime < timeToInt('23:00')) ||
      !values.endTime
    ) {
      setTimeout(() => {
        // set endTime to be one hour after the startTime
        // @ts-ignore
        formRef.current?.setFieldValue('endTime', (parseInt(values.startTime) + timeToInt('01:00')).toString(), false);
      }, timeoutDefaultValue);
    }

    // if startDate changes the endDate should be set to one month after the startDate (only if there is to initial endDate set when we are editing an existing entry)
    if (
      (!values?.endDate || values.startDate !== formRef.current?.values?.startDate) &&
      (!values[CalendarEventFormFieldNames.AllDay] || !formRef.current?.values[CalendarEventFormFieldNames.AllDay])
    ) {
      // formApi.current?.mutators.updateEndDate(moment(values.startDate).add(1, 'month').format());
      formRef.current?.setFieldValue('endDate', moment(values.startDate).add(1, 'month').format(), false);
    }

    // if repeat changes rerender the form
    if (values.repeat !== formRef.current?.values?.repeat) {
      setTimeout(() => {
        formRerender();
      }, timeoutDefaultValue);
    }

    let _allowedServiceIds = values.allowedServiceIds.concat([]) || [];
    // values.allowedServiceIds = _allowedServiceIds;

    // toggle giftcards in allowedServiceIds if allowGiftcards is toggled.
    if (
      formRef.current?.values?.allowGiftCards !== undefined &&
      values.allowGiftCards !== formRef.current?.values?.allowGiftCards
    ) {
      const matchingService = matchingServices.find(service => {
        return service.slug.endsWith(CalendarServiceCategorySlugs.GiftCard);
      });

      let matchingIndex = _allowedServiceIds.findIndex(id => id === matchingService.id);

      // toggle gift card service is allowGiftcards is false
      if (values.allowGiftCards) {
        if (matchingService && matchingIndex == -1) _allowedServiceIds.push(matchingService.id);
      } else {
        if (matchingIndex > -1) _allowedServiceIds.splice(matchingIndex, 1);
      }

      // use all allowed services, if there are now allowed services left
      if (_allowedServiceIds.length === 0) {
        _allowedServiceIds = matchingServices.map(item => item.id);

        // remove gift card service is allowGiftcards is false
        if (!values.allowGiftCards) {
          matchingIndex = _allowedServiceIds.findIndex(id => id === matchingService.id);
          if (matchingIndex > -1) _allowedServiceIds.splice(matchingIndex, 1);
        }
      }

      // formApi.current?.mutators.updateFormAllowedServiceIds(_allowedServiceIds);
      formRef.current?.setFieldValue('allowedServiceIds', _allowedServiceIds, false);

      // set new allowedServiceIds on the values so it will be updated in the initialFormValues below
      values.allowedServiceIds = _allowedServiceIds;
    }

    setGiftCardsOnly(values.allowGiftCards && _allowedServiceIds.length === 1);

    // store form values in a ref to handle value changes

    setInitialFormValues(values);
    onChange(values);
  }

  function handleSubmit(values: ICalendarEventFormValues) {
    if (!serviceProductBundleId) {
      delete values.allowGiftCards;
    }

    // get services for the requested event type
    const _matchingServices = services
      ?.filter(item => {
        return item.acf.preview_mode !== true && item['cal-event-type']?.indexOf(eventTypeId) > -1;
      })
      .sort((a: IWordpressService, b: IWordpressService) => parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order));

    // strip out services that aren't part of the current event type
    // fixes: GIK-5873 https://www.notion.so/giveinkind/GIK-5873-bug-user-gets-bad-request-error-code-on-Calendar-Request-modal-c1b999233f5a41fe93fa716491449dc3
    if (values.allowedServiceIds) {
      values.allowedServiceIds = values.allowedServiceIds.filter(serviceId =>
        _matchingServices.find(service => service.id === serviceId)
      );
    }

    if (values.allowGiftCards) {
      if (!selectedGiftcards?.length) {
        throw new Error('selectedGiftCards empty while trying to submit');
      }
      values.allowedGiftCardIds = selectedGiftcards.map(item => item.id);
    }
    return onSubmitForm(values, giftCardsOnly);
  }

  function handleEditEventType() {
    if (isClaimed) {
      return;
    }
    const footerId = 'CalendarEventTypeSelectFormFooter';

    function handleEventTypeSubmit(eventTypeId: number, allowedServiceIds: number[]) {
      UI.closeModalSheet();

      // when event type changes we need to re-fetch new initial giftcards for this event type's giftcard service
      // _selectedGiftcards.current = null;

      // set null if event type changed
      if (eventTypeId !== formRef.current?.values?.eventTypeId) setSelectedGiftcards(null);

      // this timeoout avoid the sheet closing at the same time as the form is rerendering
      // this prevents a slow animation
      setTimeout(() => {
        setEventTypeId(eventTypeId);
        const _allowedServiceIds = updateAllowedServiceIds(allowedServiceIds);

        // update times if needed
        const startTime = getStartTimeByTypeId(eventTypeId);
        const endTime = startTime + 3600;

        // formApi.current?.mutators.updateStartTime(startTime);
        // formApi.current?.mutators.updateEndTime(endTime);

        formRef.current?.setFieldValue('startTime', startTime);
        formRef.current?.setFieldValue('endTime', endTime);

        // apply values to form
        // formApi.current?.mutators.updateEventTypeId(eventTypeId);
        formRef.current?.setFieldValue('eventTypeId', eventTypeId);

        const matchingService = _allowedServiceIds?.find(id => {
          return matchingServices
            .find(service => service.id === id)
            ?.slug.endsWith(CalendarServiceCategorySlugs.GiftCard);
        });

        const isGiftCardServiceSelected = matchingService !== undefined;

        setInitialFormValues({
          ...initialFormValues,
          startTime,
          endTime,
          eventTypeId,
          allowedServiceIds: _allowedServiceIds,
          allowGiftCards: isGiftCardServiceSelected,
        });

        formRerender();
      }, 300);
    }

    UI.modalSheet(
      () => {
        return (
          <CalendarEventTypeSelectForm
            hideAnnouncement
            // eventTypeId={eventTypeId}
            onSubmit={handleEventTypeSubmit}
            buttonsPortal={() => document.getElementById(footerId)}
          />
        );
      },
      {
        title: t(translationKeys.editRequestTypeDialogTitle).toString(),
        padded: true,
        footer: () => {
          return <div id={footerId}></div>;
        },
      }
    );
  }

  function handleEditServiceType() {
    if (isClaimed) {
      return;
    }
    const footerId = 'CalendarServiceTypeSelectFormFooter';

    function handleServiceFormSubmit(values: ICalendarServicesFormValues) {
      UI.closeModalSheet();

      // this timeout avoid the sheet closing at the same time as the form is rerendering
      // this prevents a slow animation
      setTimeout(() => {
        updateAllowedServiceIds(values[CalendarEventServicesFormFieldNames.Services]);
        formRerender();
      }, 300);
    }

    UI.modalSheet(
      <CalendarServicesForm
        initialValues={{ [CalendarEventServicesFormFieldNames.Services]: formRef.current?.values?.allowedServiceIds }}
        eventTypeId={eventTypeId}
        eventTypeName={eventType?.name}
        buttonsPortal={() => document.getElementById(footerId)}
        onSubmit={handleServiceFormSubmit}
        buttons={({ isSubmitting, isValid }, formId: string) => (
          <div className={'gik-drawer-actions gik-drawer-actions--centered'}>
            <Button type="submit" form={formId} disabled={!isValid} loading={isSubmitting}>
              Save
            </Button>
          </div>
        )}
      />,
      {
        title: t(translationKeys.editServiceTypeDialogTitle).toString(),
        padded: true,
        footer: () => {
          return <div id={footerId}></div>;
        },
      }
    );
  }

  function handleGiftCardEditorChange() {
    UI.modalSheetHasChanges = true;
  }

  function handleGiftCardEditorSave(selection: Product[]) {
    // _selectedGiftcards.current = selection;
    setHasChangedGiftCards(true /*selection.*/);
    setSelectedGiftcards(selection);
    UI.modalSheetHasChanges = false;
    UI.closeModalSheet();
  }

  function handleEditGiftCards() {
    const footerId = 'CalendarGiftCardEditorFooter';

    UI.modalSheet(
      <GiftCardsEditor
        value={selectedGiftcards}
        onChange={handleGiftCardEditorChange}
        onSave={handleGiftCardEditorSave}
        buttonsPortal={() => document.getElementById(footerId)}
      />,
      {
        title: t(translationKeys.editServiceTypeDialogTitle).toString(),
        className: 'gik-drawer-giftcard-editor',
        onClose: async () => {
          if (UI.modalSheetHasChanges) {
            const response = await UI.confirm(t(translationKeys.closeGiftCardWithoutSaving), {
              title: t(translationKeys.closeGiftCardWithoutSavingTitle),
              okButtonProps: { variant: 'danger' },
              okText: t(commonTranslationKeys.exit),
            });
            // return false to prevent the sheet from closing
            if (!response) {
              return false;
            }
          }
          return true;
        },
        footer: () => {
          return <div id={footerId}></div>;
        },
      }
    );
  }

  function formRerender() {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, timeoutDefaultValue);
  }

  async function validate(values: ICalendarEventFormValues, opts: ValidationOptions<ICalendarEventFormValues>) {
    const validation = await validateForm(schema, values, opts);

    // if (
    //   typeId !== CalendarEventTypeIds.Breakfast &&
    //   typeId !== CalendarEventTypeIds.Dinner &&
    //   typeId !== CalendarEventTypeIds.Lunch &&
    //   typeId !== CalendarEventTypeIds.Transportation &&
    //   typeId !== CalendarEventTypeIds.PetCare &&
    //   typeId !== CalendarEventTypeIds.ChildCare
    // ) {
    //   delete validation.numberRequired;
    // }

    // if (typeId !== CalendarEventTypeIds.PetCare) {
    //   delete validation.careType;
    // }

    // if (typeId !== CalendarEventTypeIds.Other) {
    //   delete validation.title;
    // }

    return validation;
  }

  const findGiftCardSelected = React.useCallback(() => {
    // stop if data isn't ready yet
    if (!serviceProductBundles || !matchingServices) return;

    const _allowedServiceIds = formRef.current?.values?.allowedServiceIds || [];

    const giftCardServiceProductBundle = serviceProductBundles?.find(
      item => item.slug === CalendarServiceCategorySlugs.GiftCard
    );
    // const selectedServices = services.filter(item => allowedServiceIds.indexOf(item.id) > -1);
    const _giftCardService = matchingServices.find(
      item => item['service-category'][0] === giftCardServiceProductBundle.id
    );

    const serviceProductBundleId = _giftCardService ? _giftCardService['service-product-bundle'][0] : null;
    setGiftCardService(_giftCardService);
    setServiceProductBundleId(serviceProductBundleId);
    setGiftCardsOnly(
      serviceProductBundleId && _allowedServiceIds.length === 1 && formRef.current?.values?.allowGiftCards
    );
  }, [matchingServices, serviceProductBundles]);

  /**
   * Populate initial form values
   *
   * note: this potentialy needs to wait to get products by event type id if no services were provided
   */
  // const populateInitialFormValues = React.useCallback(() => {

  // }, [matchingServices, services, initialValues, initialFormValues, typeId, giftCardService, getAllowedServiceIds]);

  // React.useEffect(populateInitialFormValues);

  /**
   * if the serviceProductBundleId changes then load in new giftcards for it
   */
  React.useEffect(() => {
    // stop if there no serviceProductBundleId
    if (serviceProductBundleId === undefined) return;
    // stop if serviceProductBundleId hasn't changed
    if (serviceProductBundleIdPrev !== undefined && serviceProductBundleId === serviceProductBundleIdPrev) return;

    findGiftCardSelected();
  }, [serviceProductBundleId, serviceProductBundleIdPrev, findGiftCardSelected]);

  /**
   * find giftcards if services are loaded and we don't have selected giftcards yet
   * This is used to reload initial selected giftcards if the eventType changes
   */
  React.useEffect(() => {
    if (!matchingServices || selectedGiftcards?.length > 0) return;

    findGiftCardSelected();
  }, [matchingServices, selectedGiftcards, findGiftCardSelected]);

  /**
   * This should only run once, but only when data has been loaded
   */
  React.useEffect(() => {
    // stop if form already has values
    if (initialFormValues) return;

    // stop if data isn't ready yet
    if (!services || !matchingServices) return;

    // stop if the giftcard service hasn't been found yet
    if (!giftCardService) {
      findGiftCardSelected();
      return;
    }

    const _allowedServiceIds = getAllowedServiceIds(
      formRef.current?.values?.allowedServiceIds || initialValues.allowedServiceIds
    );

    const matchingService = _allowedServiceIds?.find(id => {
      const testService = services.find(service => service.id === id);
      if (testService?.slug.endsWith(CalendarServiceCategorySlugs.GiftCard)) return true;
      return false;
    });

    const isGiftCardServiceSelected = matchingService !== undefined;

    setInitialFormValues({
      ...emptyValues,
      ...initialValues,
      eventTypeId: typeId,
      allowGiftCards: isGiftCardServiceSelected,
      allowedServiceIds: _allowedServiceIds,
    });
  }, [
    initialFormValues,
    giftCardService,
    services,
    typeId,
    initialValues,
    matchingServices,
    getAllowedServiceIds,
    findGiftCardSelected,
  ]);

  /**
   * Scroll error into view if there is one
   */
  React.useEffect(() => {
    if (!errorMessage) return;

    // if there is an error we need to wait one render cycle for the dom element to become available
    setTimeout(() => {
      const el = document.querySelector(`#${formId} .gik-form-error`);
      if (el) {
        el.scrollIntoView();
      }
    });
  }, [errorMessage, formId]);

  /**
   * set selected giftcards once they have been loaded
   */
  React.useEffect(() => {
    // stop if we already have selected giftcards
    if (selectedGiftcards && selectedGiftcards.length > 0) return;
    // stop if giftcards haven't been loaded yet
    if (!productBundleGiftCards) return;

    setSelectedGiftcards(productBundleGiftCards);
    //eslint-disable-next-line
  }, [productBundleGiftCards]);

  // const updateStartTime: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'startTime', () => args[0]);
  // };
  // const updateEndTime: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'endTime', () => args[0]);
  // };
  // const updateEndDate: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'endDate', () => args[0]);
  // };
  // const updateEventTypeId: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'eventTypeId', () => args[0]);
  // };
  // const updateFormAllowedServiceIds: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'allowedServiceIds', () => args[0]);
  // };
  // const updateAllowGiftCards: Mutator = (args, state, utils) => {
  //   utils.changeValue(state, 'allowGiftCards', () => args[0]);
  // };

  const isLoading = !eventTypes || !initialFormValues;
  if (isLoading) return <LoadingSpinner center />;

  const _isFoodType = isFoodType(eventTypeId);
  const eventType = eventTypes.find(item => item.id === eventTypeId);

  if (rerender || !schema) return null;

  return (
    <>
      <Form
        ref={formApi}
        schema={schema}
        initialValues={{ ...initialFormValues }}
        vertical
        className={bem(null, null, className)}
        id={formId}
        restoreAfterUpdate
        disabled={isSubmitting}
        // mutators={{
        //   updateStartTime,
        //   updateEndTime,
        //   updateEndDate,
        //   updateEventTypeId,
        //   updateFormAllowedServiceIds,
        //   updateAllowGiftCards,
        // }}
        render={(form: FormikProps<ICalendarEventFormValues>) => {
          const { values } = form;
          const _buttons = buttons(form, formId);

          formRef.current = form;
          formRef.current.values = values;

          return (
            <>
              <fieldset>
                <div className={bem('event-type-section')}>
                  <FormGroup
                    {...fieldHasError(form, 'eventTypeId')}
                    label={t(translationKeys.eventTypeLabel).toString()}
                    vertical
                  >
                    <CalendarEventTypePicker
                      editable={!isClaimed}
                      iconUrl={eventType?.acf.taxonomy_svg_icon}
                      title={eventType?.name}
                      eventTypeSlug={eventType?.slug}
                      onClick={handleEditEventType}
                      value={values.eventTypeId}
                      onChange={v => form.setFieldValue('eventTypeId', v)}
                    />
                  </FormGroup>

                  <FormGroup
                    {...fieldHasError(form, 'allowedServiceIds')}
                    label={t(translationKeys.serviceTypeLabel).toString()}
                    vertical
                  >
                    <CalendarServicePicker
                      editable={!isClaimed}
                      eventTypeId={eventTypeId}
                      giftcardBundleId={giftCardService?.id}
                      value={values.allowedServiceIds}
                      onChange={v => form.setFieldValue('allowedServiceIds', v)}
                      onClick={handleEditServiceType}
                    />
                  </FormGroup>
                </div>

                <section className={bem('section1')}>
                  <div className={bem('section-number')}>
                    {(_isFoodType ||
                      eventTypeId === CalendarEventTypeIds.ChildCare ||
                      eventTypeId === CalendarEventTypeIds.Transportation ||
                      eventTypeId === CalendarEventTypeIds.PetCare) && <FormField name="numberRequired" />}
                    {eventTypeId === CalendarEventTypeIds.PetCare && <FormField name="petCareTypeId" />}
                  </div>
                </section>
                {eventTypeId === CalendarEventTypeIds.Other && <FormField name="title" />}

                <CalendarTimeAndRepeatForm formValues={values} giftCardsOnly={giftCardsOnly} />

                {eventTypeId === CalendarEventTypeIds.Transportation && (
                  <>
                    <FormField name="pickupLocation" />
                    <FormField name="dropoffLocation" />
                  </>
                )}
                <FormField name="description" />
                {(eventTypeId === CalendarEventTypeIds.ChildCare ||
                  eventTypeId === CalendarEventTypeIds.SeniorCare ||
                  eventTypeId === CalendarEventTypeIds.PetCare) &&
                  !giftCardsOnly && <FormField name="emergencyContactInfo" />}
                {_isFoodType && !giftCardsOnly && <FormField name="deliveryInstructions" />}
                <FormField name="sendEmailToPageFollowers" />
                {serviceProductBundleId && (
                  <div className="tw-text-center">
                    <FormField name="allowGiftCards" />
                  </div>
                )}
              </fieldset>

              {serviceProductBundleId && (
                <GiftCardsPicker
                  disabled={values?.allowGiftCards !== true}
                  value={selectedGiftcards}
                  onEdit={handleEditGiftCards}
                  onChange={handleGiftCardChange}
                  loading={productBundleGiftCardsInvalidating && !selectedGiftcards}
                />
              )}
              <FormError message={errorMessage} centered />
              {renderPortal(_buttons, buttonsPortal)}
            </>
          );
        }}
        {...otherProps}
        onSubmit={handleSubmit}
        onChange={handleChange}
        validate={validate}
      />
    </>
  );
}

export const CalendarEventForm = withComponentErrorBoundary(CalendarEventFormComp);
