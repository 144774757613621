import type { IStringifyOptions } from 'qs';
import qs from 'qs';

export function createPathWithSearchParams(
  path: string,
  searchParams?: object,
  qsStringifyOptions?: IStringifyOptions
) {
  const searchParamsStr = (searchParams && qs.stringify(searchParams, qsStringifyOptions)) || '';
  return `${path}${searchParamsStr ? `?${searchParamsStr}` : ''}`;
}
