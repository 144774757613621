import { ErrorPageSection } from '@gik/core/pages/Error/ErrorPageSection';
import { isProduction } from '@gik/core/utils/environment';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import React from 'react';
import type { ErrorFallbackProps } from '.';
import { useBemCN } from '../../utils/bemBlock';

export const ErrorBoundaryFallback: React.FC<ErrorFallbackProps> = ({ maxHeight, errors }): React.ReactElement => {
  const bem = useBemCN('error-boundary-fallback');

  const handleShowError = React.useCallback(
    (error: Error) => {
      if (isProduction) return;

      UI.dialog(
        <div {...bem('dialog-content')}>
          <ErrorPageSection error={error} layout="vertical" noPad />
        </div>,
        { title: 'Error', closable: true }
      );
    },
    [bem]
  );

  return (
    <div
      {...bem()}
      // @ts-ignore
      style={{ '--max-height': maxHeight + 'px' }}
    >
      {errors.map((error, index) => {
        return (
          <div {...bem('error')} key={index} onClick={() => handleShowError(error)}>
            <SvgIcon Icon={ExclamationCircleIcon} />
          </div>
        );
      })}
    </div>
  );
};
