import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories } from '@gik/api/calendar/serviceProductBundle';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { ISelectListProps } from '@gik/ui/SelectList';
import { SelectList } from '@gik/ui/SelectList';
import React from 'react';
import { CalendarServiceTile } from './CalendarServiceTile';

export interface IWordpressServiceProductBundleOption {
  label: string;
  value: number;
  image?: string;
  selected?: boolean;
  variant?: string;
}

export interface ICalendarServiceSelectListProps extends Omit<ISelectListProps<number>, 'onChange' | 'render'> {
  eventTypeId?: number;
  onChange?: (id: number[], items: IWordpressService[]) => void;
}

export function CalendarServiceSelectList({
  multiple = true,
  className,
  eventTypeId,
  onChange = noop,
  ...otherProps
}: ICalendarServiceSelectListProps): React.ReactElement {
  const bem = bemBlock('calendar-service-select-list');

  // Local State
  const [matchingServices, setMatchingServices] = React.useState<IWordpressService[]>();
  const [options, setOptions] = React.useState<IWordpressServiceProductBundleOption[]>();

  // SWR
  const { data: services } = useServices();
  const { data: serviceProductBundles } = useServiceCategories();

  // Other Hooks
  const isMd = useBreakpoint(Breakpoint.MD);

  /**
   * Create a list of matching services based on calendar event type
   */
  React.useEffect(() => {
    if (!services) return;

    // get services for the requested event type
    const _matchingServices = services
      ?.filter(item => {
        return item.acf.preview_mode !== true && item['cal-event-type']?.indexOf(eventTypeId) > -1;
      })
      .sort((a: IWordpressService, b: IWordpressService) => parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order));

    setMatchingServices(_matchingServices);
  }, [services, eventTypeId]);

  /**
   * Create options for the SelectList when all data is ready
   */
  React.useEffect(() => {
    if (!serviceProductBundles || !matchingServices) return;

    // create options for the SelectList
    const _options: IWordpressServiceProductBundleOption[] = matchingServices.map(service => {
      const category = serviceProductBundles.find(item => item.id === service['service-category'][0]);

      return {
        label: service.acf.display_name || category.name,
        value: service.id,
        image: service.acf.taxonomy_svg_icon || category.acf.taxonomy_svg_icon,
      };
    });

    setOptions(_options);
  }, [serviceProductBundles, matchingServices, eventTypeId]);

  const isLoading = !options;

  if (isLoading) return <LoadingSpinner center />;

  return (
    <SelectList<number>
      {...otherProps}
      className={bem(null, null, className)}
      multiple={multiple}
      options={options}
      onChange={(v: number[]) => {
        const service = matchingServices.filter(item => v.indexOf(item.id) > -1);
        onChange(v, service);
      }}
      render={(item: IWordpressServiceProductBundleOption, selected: boolean) => {
        return (
          <CalendarServiceTile
            multiple={multiple}
            horizontal={!isMd}
            selected={selected}
            image={item.image}
            label={item.label}
          />
        );
      }}
    />
  );
}
