import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { CreateClickedLocation } from '../enums/CreateAnalyticsEnums';

export function useCreateAnalyticsController() {
  function trackCreateClicked(location: CreateClickedLocation) {
    Analytics.fireEvent(
      AnalyticsEvents.CreateClicked,
      {
        location,
      },
      keys => keys // only one key - location
    );
  }

  return {
    trackCreateClicked,
  };
}
