import { Analytics } from '@gik/analytics';
import { logger } from '@gik/analytics/utils/logger';
import { timeoutDefaultValue } from '@gik/core/constants';
import i18n from '@gik/i18n';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import { useDialogStore } from '@gik/ui/Dialogs';
import type { FormProps, FormSchemaEntry } from '@gik/ui/Form';
import { useNotificationsContext } from '@gik/ui/Notifications';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import type { UIMode } from '@gik/ui/typesValues';
import CheckCircleIcon from '@heroicons/react/outline/CheckCircleIcon';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import LockClosedIcon from '@heroicons/react/solid/LockClosedIcon';
import LockOpenIcon from '@heroicons/react/solid/LockOpenIcon';
import type { FormikProps } from 'formik';
import React from 'react';
import { CenterFixed } from '../CenterFixed';
import type {
  DialogCloseFnType,
  DialogContentType,
  DialogResponseType,
  IDialog,
  IDialogRenderProps,
  IDialogType,
} from '../Dialogs/DialogTypes';
import type { IDrawerProps } from '../Drawer/DrawerTypes';
import { translationKeys } from '../i18n/en';
import { LoadingSpinner } from '../LoadingSpinner';
import type { ModalProps } from '../Modal/types/ModalProps';
import type { UINotificationVariant } from '../types';
import type { LightboxMediaType } from './UILightboxTypes';

export interface UINotifyOptions {
  variant?: UINotificationVariant;
  clickThrough?: boolean;
  onClickThrough?: () => void;
  append?: React.ReactNode | string;
  prepend?: React.ReactNode | string;
  autoClose?: boolean;
  autoCloseTime?: number;
  close?: boolean;
  key?: string;
}

export interface UIDialogOptions {
  title?: React.ReactNode;
  key?: string;
  centered?: boolean;
  centeredTitle?: boolean;

  className?: string;
  overlayClassName?: string;
  buttons?: DialogContentType;
  autowidth?: boolean;
  headerId?: string;
  contentId?: string;
  onHeaderRef?(ref: HTMLDivElement): void;
  disableSystemStyling?: boolean;
  header?: DialogContentType;
  modalProps?: Partial<ModalProps>;
  footer?: React.ReactNode | string | ((props: ModalProps) => React.ReactNode | string);
  form?: boolean;
}

export interface UIGenericDialogOptions extends UIDialogOptions {
  closable?: boolean;
  okText?: React.ReactNode | string;
  okButton?: boolean;
  okButtonProps?: Partial<IButtonProps>;
  cancelText?: React.ReactNode | string;
  cancelButtonProps?: Partial<IButtonProps>;
  shouldCloseOnOverlayClick?: boolean;
  padding?: boolean;
  useBlur?: boolean;
  useBackdrop?: boolean;
  backButtonOverrideEnabled?: boolean;
  innerClassName?: string;
  innerStyle?: React.CSSProperties;
  onClose?: (response?: unknown) => boolean | Promise<boolean>;
  onAfterClose?: () => void;
  onReady?: (dialogProps: IDialogRenderProps) => void;
}

export type LightboxOptions = UIGenericDialogOptions & {
  mode?: UIMode;
  onClickOutside?: () => void;
};

/**
 * UIManager takes care of triggering dialogs and notifications.
 */
export class UIManager {
  constructor() {
    this._genericDialog = this._genericDialog.bind(this);
  }

  notify(content: React.ReactNode | string, options: UINotifyOptions = {}) {
    const notifications = useNotificationsContext.getState().notifications;
    // prevent opening if this notification is already open
    if (options.key && notifications.find(item => item.key === options.key)) return;

    useNotificationsContext.getState().add({ content, ...options });
  }

  notifySuccess(content: React.ReactNode | string, options: UINotifyOptions = {}) {
    // apply sensible defaults for a success type notification
    if (options.variant === undefined) {
      options.variant = 'success';
    }
    if (options.prepend === undefined) {
      options.prepend = <span className="tw-text-success-500">{<SvgIcon Icon={CheckCircleIcon} />}</span>;
    }

    this.notify(content, options);
  }

  notifyWarning(content: React.ReactNode | string, options: UINotifyOptions = {}) {
    // apply sensible defaults for a success type notification
    if (options.variant === undefined) {
      options.variant = 'warning';
    }
    if (options.prepend === undefined) {
      options.prepend = <span className="tw-text-warn-500">{<SvgIcon Icon={ExclamationCircleIcon} />}</span>;
    }

    this.notify(content, options);
  }

  notifyError(content: React.ReactNode | string, options: UINotifyOptions = {}) {
    // apply sensible defaults for a success type notification
    if (options.variant === undefined) {
      options.variant = 'error';
    }
    if (options.prepend === undefined) {
      options.prepend = <span className="tw-text-danger-500">{<SvgIcon Icon={ExclamationCircleIcon} />}</span>;
    }

    Analytics.fireUserNotice(content?.toString(), 'errorNotification');
    this.notify(content, options);
  }

  notifyPremiumPageLocked() {
    this.notify(i18n.t(translationKeys.PremiumLockedNotification), {
      variant: 'premium-locked',
      prepend: <SvgIcon Icon={LockClosedIcon} size="lg" />,
    });
  }

  notifyPremiumPageUnlocked() {
    this.notify(i18n.t(translationKeys.PremiumUnlockedNotification), {
      variant: 'premium-unlocked',
      prepend: <SvgIcon Icon={LockOpenIcon} size="lg" />,
    });
  }

  clearNotification(notificationId: string) {
    useNotificationsContext.getState().remove(notificationId);
  }

  clearAllNotifications() {
    useNotificationsContext.getState().removeAll();
  }

  alert(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    return this._genericDialog('alert', content, options);
  }

  error(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    Analytics.fireUserNotice(content?.toString(), 'errorDialog');
    return this._genericDialog('error', content, options);
  }

  warn(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    return this._genericDialog('warn', content, options);
  }

  success(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    return this._genericDialog('success', content, options);
  }

  confirm(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    return this._genericDialog('confirm', content, options);
  }

  info(content: React.ReactNode | string, options?: UIGenericDialogOptions): Promise<boolean | undefined> {
    return this._genericDialog('info', content, options);
  }

  nonDisruptiveConfirm(
    content: React.ReactNode | string,
    options?: UIGenericDialogOptions
  ): Promise<boolean | undefined> {
    return this.confirm(content, {
      ...options,
      modalProps: {
        ...options?.modalProps,
        bottom: true,
        disableBackdrop: true,
        overlayClassName: 'no-pointer-events',
      },
    });
  }

  dialog<T extends DialogResponseType = boolean>(
    content: DialogContentType,
    options?: UIGenericDialogOptions
  ): Promise<T | undefined> {
    return this._genericDialog('dialog', content, options);
  }

  async formDialog<T extends object = object>(
    formProps: FormProps<T>,
    options?: UIGenericDialogOptions & {
      renderFields?: (
        form: FormikProps<object>,
        fieldMap: (entry: FormSchemaEntry) => React.ReactNode | string
      ) => React.ReactNode | string;
      renderButtons?: (args: {
        okButton: React.ReactNode | string;
        cancelButton: React.ReactNode | string;
        close: DialogCloseFnType;
        form: FormikProps<object>;
      }) => React.ReactNode | string;
    }
  ): Promise<T> {
    const { Form } = await import('@gik/ui/Form');
    const { default: bemBlock } = await import('@gik/core/utils/bemBlock');
    const { Button } = await import('@gik/ui/Button');
    const { FormField } = await import('@gik/ui/Form');
    const ReactDOM = await import('react-dom');

    return new Promise<T>((resolve, reject) => {
      const bem = bemBlock('form-modal');
      const buttonsPortal = React.createRef<HTMLElement>();

      function buttons(form, close) {
        const buttonsDisabled = form.isSubmitting || !form.isValid;

        function onClickCancel() {
          reject();
        }

        function onClickSubmit() {
          form.form.submit();
        }

        const cancelButton = (
          <Button
            {...(options?.cancelButtonProps ?? {})}
            variant="default"
            className={bem('cancel-button')}
            onClick={() => void onClickCancel()}
          >
            {options?.cancelText ?? 'Cancel'}
          </Button>
        );

        const okButtonElement = (
          <Button
            {...(options?.okButtonProps ?? {})}
            variant="primary"
            className={bem('ok-button')}
            type="submit"
            disabled={buttonsDisabled}
            loading={form.isSubmitting}
            form="group-widget-form"
            // onClick={() => void onClickSubmit()}
          >
            {options?.okText ?? 'Submit'}
          </Button>
        );

        return (
          options?.renderButtons?.({ okButton: okButtonElement, cancelButton, close, form }) || (
            <>
              {cancelButton}
              {options.okButton !== false && okButtonElement}
            </>
          )
        );
      }

      const defaultFieldMap = entry => <FormField key={entry.name} name={entry.name} />;

      this.dialog(
        ({ close }) => (
          <section className={bem('content')}>
            <Form
              id="group-widget-form"
              {...formProps}
              onSubmit={(values: T) => {
                resolve(values);
                close?.();
              }}
              className={bem('form')}
              render={form => {
                return (
                  <>
                    {options?.renderFields?.(form, defaultFieldMap) || formProps.schema.map(defaultFieldMap)}
                    {buttonsPortal?.current ? (
                      ReactDOM.createPortal(buttons(form, close), buttonsPortal.current)
                    ) : (
                      <section className={bem('button-wrapper')}>{buttons(form, close)}</section>
                    )}
                  </>
                );
              }}
            />
          </section>
        ),
        {
          ...options,
          closable: true,
          onClose: async () => {
            reject();
            return true;
          },
          className: bem(null, null, options?.className),
          buttons: <section ref={buttonsPortal} className={bem('button-wrapper')} />,
        }
      );
    });
  }

  async overlay<T extends DialogResponseType = boolean>(
    content: React.ReactNode | string,
    options: UIGenericDialogOptions = {}
  ): Promise<T | undefined> {
    return this._genericDialog('dialog', content, {
      closable: true,
      centeredTitle: false,
      className: `gik-modal--overlay ${options.className || ''}`,
      overlayClassName: 'gik-overlay--generic',
      useBackdrop: false,
      ...options,
    });
  }

  async lightbox<T extends DialogResponseType = boolean>(
    media: LightboxMediaType[],
    options: LightboxOptions = {
      mode: 'dark',
    },
    initialSlide = 0
  ): Promise<T | undefined> {
    const { LightboxContent } = await import('./UILightbox');
    return this._genericDialog(
      'dialog',
      <LightboxContent initialSlide={initialSlide} media={media} onClickOutside={options.onClickOutside} />,
      {
        ...options,
        closable: true,
        centeredTitle: true,
        className: `gik-modal--lightbox gik-modal--mode-${options?.mode ?? 'dark'} ${options.className || ''}`,
        overlayClassName: `gik-overlay--lightbox gik-overlay--mode-${options?.mode ?? 'dark'}`,
      }
    );
  }

  async lightboxFull<T extends DialogResponseType = boolean>(
    media: LightboxMediaType[],
    options: LightboxOptions = {
      mode: 'dark',
    },
    initialSlide = 0
  ): Promise<T | undefined> {
    const { LightboxContent } = await import('./UILightbox');
    return this._genericDialog(
      'dialog',
      <LightboxContent initialSlide={initialSlide} media={media} onClickOutside={options.onClickOutside} />,
      {
        ...options,
        closable: true,
        centeredTitle: true,
        className: `gik-modal--lightboxfull gik-modal--mode-${options?.mode ?? 'dark'} ${options.className || ''}`,
        overlayClassName: `gik-overlay--lightbox gik-overlay--mode-${options?.mode ?? 'dark'}`,
      }
    );
  }

  loadingSpinnerOverlay<T extends DialogResponseType = boolean>(options: UIGenericDialogOptions = {}) {
    return this._genericDialog(
      'dialog',
      (dialogProps: IDialogRenderProps) => {
        return (
          <CenterFixed>
            <LoadingSpinner />
          </CenterFixed>
        );
      },
      {
        ...options,
        closable: false,
        className: `gik-modal--loading-spinner ${options.className || ''}`,
        backButtonOverrideEnabled: false,
      }
    );
  }

  closeDialog(dialogId?: string, response?: DialogResponseType) {
    useDialogStore.getState().close(dialogId, response);
  }

  closeAllDialogs(response?: DialogResponseType) {
    useDialogStore.getState().closeAll(response);
  }

  closeDialogByKey(key: string, response?: DialogResponseType) {
    useDialogStore.getState().closeByKey(key, response);
  }

  modalSheet(content: React.ReactNode | string | (() => React.ReactNode | string), options?: IDrawerProps) {
    useDialogStore.getState().addModalSheet({
      children: typeof content === 'function' ? content() : content,
      ...options,
    });
  }

  closeModalSheet() {
    // TODO: move check into dialogs store
    if (!useDialogStore.getState().sheetIsOpen) return;
    useDialogStore.getState().closeModalSheet();
    // TODO: move into dialogs store, this should be an atomic operation
    setTimeout(() => {
      useDialogStore.getState().removeModalSheet();
    }, 500);
  }

  get modalSheetHasChanges() {
    return useDialogStore.getState().modalSheetHasChanges;
  }

  set modalSheetHasChanges(hasChanges: boolean) {
    useDialogStore.getState().setModalSheetHasChanges(hasChanges);
  }

  private _genericDialog<T extends DialogResponseType>(
    type: IDialogType,
    content: React.ReactNode | string,
    options: UIGenericDialogOptions = {}
  ): Promise<T | undefined> {
    // prevent multiple dialogs
    if (options.key && useDialogStore.getState().dialogs?.some(dialog => dialog.key === options.key)) {
      logger.warn('Two dialogs launched with same key: ' + options.key);
      return Promise.resolve(undefined);
    }
    if (options.title && useDialogStore.getState().dialogs?.some(dialog => dialog.title === options.title)) {
      logger.warn('Two dialogs launched with same title: ' + options.title);
      return Promise.resolve(undefined);
    }

    return this._wrapWithDialogPromise({
      content,
      type,
      // padding: true,
      closable: false,
      ...options,
    });
  }

  private _wrapWithDialogPromise<T extends DialogResponseType>(item: IDialog) {
    return new Promise<T>((resolve, reject) => {
      const itemWithPromise = {
        ...item,
        onPromiseResolve: resolve,
        onPromiseReject: reject,
      };
      const itemId = useDialogStore.getState().add(itemWithPromise);

      // NOTE: The timeout is needed here to allow to backdrop to render first
      // before the enter animation is triggered.
      // TODO: maybe changing the Backdrop from a transition to an animation would fix this issue
      // TODO: move into the dialog store, adding and opening the dialog should be an atomic operation
      setTimeout(() => {
        useDialogStore.getState().open(itemId);
      }, timeoutDefaultValue);
    });
  }
}
