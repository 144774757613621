import bemBlock from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

export interface INewPageWelcomeProps extends React.HTMLAttributes<HTMLDivElement> {}

const bem = bemBlock('new-page-welcome');
const bemModal = bemBlock('new-page-welcome-modal');

export default function NewPageWelcome({ className }: INewPageWelcomeProps) {
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)}>
      <div className={bem('text')}>
        <div>{t(translationKeys.NewPageTitle1)}</div>
        <div>{t(translationKeys.NewPageTitle2)}</div>
        <p>{t(translationKeys.NewPageDescription)}</p>
      </div>
      <div className={bem('image')}></div>
    </div>
  );
}

export function openNewPageWelcomeModal(onCancel: () => void, onNext: () => void, showCancelButton: boolean): void {
  UI.dialog(<NewPageWelcome />, {
    modalProps: { shouldCloseOnOverlayClick: false },
    closable: false,
    className: bemModal(),
    autowidth: false,
    buttons: ({ close }) => {
      const handleOnCancel = () => {
        close();
        onCancel();
      };
      const handleOnNext = () => {
        close();
        onNext();
      };
      return (
        <>
          {showCancelButton && (
            <Button variant="default-link" onClick={handleOnCancel}>
              <Translation>{t => t(translationKeys.NewPageNoThanks)}</Translation>
            </Button>
          )}
          <Button onClick={handleOnNext}>
            <Translation>{t => t(translationKeys.NewPageShowMe)}</Translation>
          </Button>
        </>
      );
    },
  });
}
