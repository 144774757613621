// convert to to enum
export enum ServiceModule {
  PROVIDE_MYSELF = 'provide-myself',
  GRUBHUB = 'grubhub',
  DOORDASH = 'doordash',
  MEAL_GIFT_CARD = 'meal-gift-card',
  GENERIC_GIFT_CARD = 'generic-gift-card',
}

export const isGiftCardServiceModule = (serviceModule: ServiceModule) =>
  serviceModule && serviceModule.endsWith('-gift-card');

export const isPartnerServiceModule = (serviceModule: ServiceModule) =>
  serviceModule == ServiceModule.GRUBHUB || serviceModule == ServiceModule.DOORDASH;
