import bemBlock from '@gik/core/utils/bemBlock';
import type { RasterAttachImageResult } from '@gik/core/utils/FileUtils';
import noop from '@gik/core/utils/noop';
import { RangeSlider } from '@gik/ui/RangeSlider';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { Rotate } from '@gik/ui/SvgIcon/GikIcons/Rotate';
import React from 'react';
import Cropper from 'react-easy-crop';
import type { Area, Point } from 'react-easy-crop/types';
import { Button } from '../Button';

export type ImageCropperBackgroundType = 'checkerboard' | 'auto';

export interface IImageCropperProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  image?: RasterAttachImageResult;
  fallback?: React.ReactNode;
  background?: ImageCropperBackgroundType;
  initialCrop?: Point;
  initialZoom?: number;
  initialRotation?: number;
  aspect?: number; // TODO we have an AspectRatio type, we could also use that one here
  onChange?(imageData: IImageCropData): void;
}

export interface IImageCropData {
  image: string;
  crop: Point;
  zoom: number;
  rotation: number;
  croppedAreaPixels: Area;
}

export function ImageCropper({
  image,
  aspect = null,
  fallback,
  background = 'auto',
  className,
  initialZoom,
  initialRotation,
  initialCrop,
  onChange = noop,
}: IImageCropperProps) {
  const bem = bemBlock('image-cropper');

  const [crop, setCrop] = React.useState<Point>(initialCrop || { x: 0, y: 0 });
  const [zoom, setZoom] = React.useState<number>(initialZoom || 1);
  const [rotation, setRotation] = React.useState<number>(initialRotation || 0);
  const [imageRatio, setImageRatio] = React.useState<number>(aspect);
  const mainRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!image || !mainRef) return;
    if (aspect == null) {
      // if aspect is null automatically determine it based on the image data
      setImageRatio(image.width / image.height);
    } else if (aspect == -1) {
      // if aspect is -1 automatically determine it based on the size of the cropper container
      const boundingRect: DOMRect = mainRef.current?.getBoundingClientRect();
      setImageRatio(boundingRect.width / boundingRect.height);
    } else {
      setImageRatio(aspect);
    }
  }, [image, aspect, mainRef, setImageRatio]);

  return (
    <div className={bem(null, [{ [`background-${background}`]: background }], className)} ref={mainRef}>
      <div className={bem('image')}>
        {image && (
          <Cropper
            image={image.base64}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={imageRatio}
            onCropChange={setCrop}
            onCropComplete={(_croppedArea, croppedAreaPixels) => {
              onChange({
                image: image.base64,
                crop,
                zoom,
                rotation,
                croppedAreaPixels,
              });
            }}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            showGrid={false}
          />
        )}
        {!image && fallback}
      </div>
      {image && (
        <div className={bem('toolbar')}>
          <div className={bem('sliderWrapper')}>
            <RangeSlider className={bem('zoomSlider')} value={zoom} min={1} max={3} step={0.1} onChange={setZoom} />
          </div>
          <Button
            onClick={() => {
              setRotation(rotation >= 360 ? 0 : rotation + 90);
            }}
            variant="primary-link"
            className={bem('rotateButton')}
            tooltip="Rotate"
          >
            <SvgIcon size="xl" Icon={Rotate} />
          </Button>
        </div>
      )}
    </div>
  );
}
