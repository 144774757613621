import type { FormSchemaEntry } from '@gik/ui/Form';

export type FirstNameFieldProps = {
  readOnly: boolean;
};

export const FirstNameField = (props?: FirstNameFieldProps): FormSchemaEntry => ({
  label: 'First name',
  name: 'firstName',
  type: props?.readOnly ? 'plaintext' : 'text',
  required: true,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'given-name', centerVertical: props?.readOnly },
});
