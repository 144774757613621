import { logger } from '@gik/analytics/utils/logger';

export function requestLogger(request: Request) {
  if (request?.method?.toLowerCase?.() !== 'get') {
    const requestId = request?.headers?.get?.('x-browser-request-id');
    logger.info(`${request?.method} ${request?.url}`, {
      request,
      ...(requestId
        ? {
            Properties: {
              requestId,
            },
          }
        : {}),
    });
  }
}
