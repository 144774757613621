import { isBrowser } from '@/utils/environment';
import bemBlock from '@gik/core/utils/bemBlock';
import { AnchorLink } from '@gik/ui/AnchorLink';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';

export interface IScrollNavBarItemProps {
  variant?: UIVariant | 'danger' | 'success' | 'warn' | 'purple';
  to: string;
  icon: React.ReactNode;
  label: React.ReactNode;
  activeKey: string;
  setActive: (id: string) => void;
  setInactive: (id: string) => void;
  offset?: number;
  containerId?: string;
}

export const scrollNavBarItemBlockName = 'scroll-navbar-item';

export const ScrollNavBarItem: React.FC<IScrollNavBarItemProps> = ({
  to,
  icon,
  label,
  activeKey,
  setActive,
  setInactive,
  variant = 'default',
  offset,
  containerId,
}): React.ReactElement => {
  const bem = bemBlock(scrollNavBarItemBlockName);

  const isActive = activeKey === null ? null : activeKey === to;
  const active = isActive === true;
  // const inactive = isActive === false;
  // const modifiers = [{ active }, { inactive }];

  // icon and border-bottom have the same active tint, text has darker tint
  // text has darker inactive tint than icon
  // const activeIconClass = [`tw-text-${variant}-${iconTint}`];
  // const inActiveIconClass = ['tw-text-neutral-700'];

  // const activeBorderClass = [`tw-border-${variant}-${iconTint}`];
  // const inActiveBorderClass = ['tw-border-transparent'];

  // const activeTextClass = [`tw-text-${variant}-${textTint}`];
  // const inActiveTextClass = ['tw-text-neutral-900'];

  // this is to avoid having 3 states: active, inactive and null-state (default)
  // make inactive and null-state equal
  // const iconClass = active ? activeIconClass : inActiveIconClass;
  // const borderClass = active ? activeBorderClass : inActiveBorderClass;
  // const textClass = active ? activeTextClass : inActiveTextClass;

  // NOTE: fix for incorrect highlighting active navitem by react-scroll
  // Link component stops propagation of click event
  // so separate div bem('wrapper') must be used
  const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
    if (!isBrowser()) return;

    const activeClass = '.gik-scroll-navbar-item__content--active';
    const elementWithScroll = '.gik-modal__content-wrapper';

    // must be outside of setTimeout
    const currentTarget = ev.currentTarget;

    setTimeout(() => {
      // must be inside of setTimeout, wait for scroll to finish, then test if it has active class
      const isActiveFinally = !!currentTarget?.querySelector(activeClass);
      !isActiveFinally && document?.querySelector(elementWithScroll)?.scrollBy(0, 2);
      // default scroll duration on AnchorLink is 300ms
    }, 400);
  };

  return (
    <li className={bem(null, [{ [`variant-${variant}`]: variant }, { active }])}>
      <AnchorLink
        className={bem('link')}
        onSetActive={() => setActive(to)}
        onSetInactive={() => setInactive(to)}
        to={to}
        spy={true}
        smooth={true}
        offset={offset}
        containerId={containerId}
      >
        <div className={bem('wrapper')} onClick={handleClick}>
          <div className={bem('spacing')} />
          <div className={bem('content')}>
            <div className={bem('icon')}>{icon}</div>
            <span className={bem('label')}>{label}</span>
          </div>
          <div className={bem('spacing')} />
        </div>
      </AnchorLink>
    </li>
  );
};
