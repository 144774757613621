import type { KeyedMutatorWithHeaders } from './swr/middlewares/swrWithHeadersMiddleware';

export function getBasicAuthString(username: string, password: string): string {
  return `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`;
}

export async function optimisticUpdate<T, H = Object>(
  newData: T,
  newHeaders: H,
  mutate: KeyedMutatorWithHeaders<T, H>,
  apiFunction?: () => Promise<Response> | Promise<void>,
  remutate = true
) {
  await mutate(newData, newHeaders, false);
  const result = await apiFunction();
  if (remutate) await mutate();
  return result;
}
