import { useQuery } from '@tanstack/react-query';
import { giftWalletAdminEndPoints as endPoints } from '@gik/platform-management-api-js';
import type {
  StrapiPagedDataSource,
  StrapiSearchParams,
  StrapiSingleDataSource,
} from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../../contexts';
import type { GiftNoteEntity } from '@gik/platform-management-api-js';
import type { ReactQueryOptions } from '../../../types/react-query';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';
import { useQueryDefaults } from '../../../client';

/**
 * List Order Notes
 */
export function useAdminGiftNotes(
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftNoteEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.giftNote.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.giftNote.list({ params })).data,
  });
}

/**
 * Get Order Note by id
 */
export function useAdminGiftNote(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<StrapiSingleDataSource<GiftNoteEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.giftNote.get(id);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.giftNote.get(id, {
          params,
        })
      ).data,
  });
}
