import TranslationUtils from '@gik/core/utils/TranslationUtils';

// TODO: normalize and sanitize names
const translations = {
  confirmationModalTitle: 'Confirmation',
  sendGiftCardModalTitle: 'Send Gift Card',
  claimModalTitleMultipleServices: 'How would you like to help?',
  claimModalTitleSingleService: 'This is how you can help',
  noDescriptionProvided: 'No description provided',
  providedBy: 'Provided by',
  questions: 'Questions regarding this help?',
  emailClaimOwner: 'Email Participant',
  thanksForPurchasing: 'Thanks for purchasing',
  claimOwnerPurchased: 'purchased',
  from: 'from',
  allDay: 'Anytime',

  addToGoogleCalendar: 'Google Calendar',
  addToICS: 'OUTLOOK or APPLE',
  addToCalendarModalTitle: 'Add to your calendar',

  claimConflictResolutionTitle: 'Wow.',
  claimConflictHasOptionsPaidDescription:
    'Your selected date has just been taken by someone else. Attach your gift card to another open request by <strong>choosing it below:</strong>',
  claimConflictHasOptionsUnpaidDescription:
    'Your selected date has just been taken by someone else.<br><br>Your purchase <strong>has not</strong> been completed yet.<br><br><strong>Please choose another date below.</strong>',
  claimConflictNoOptionsPaidDescription:
    'There was such high demand to fulfill this request that <strong>another supporter beat you to it.</strong><br><br>There are no other calendar requests to choose, but <strong>your gift and personal note are still on their way.</strong><br><br><em>Reach out to the Support Team with any questions.</em>',
  claimConflictNoOptionsUnpaidDescription:
    'Your selected date has just been taken by someone else and no similar requests are open.<br><br><strong>You can send the gift card anyway</strong>, but it will not appear on the Care Calendar (though it will still be appreciated).',

  claimConflictCancelButton: 'Cancel Instead',
  claimConflictDoNotSendButton: "Don't send",
  claimConflictSendAnywayButton: 'Send anyway',
  claimConflictDoneButton: 'Done',
  claimConflictDoNotAttachButton: "Don't attach to a date",
  claimConflictConfirmCancel: 'Are you sure you want to cancel? Your gift card will not be sent.',
  claimConflictConfirmDoNotAttach:
    "Are you sure you don't want to attach to a date? Your contribution will not show on the calendar.",
  claimConflictConfirmOkButtonText: 'confirm',
  claimConflictConfirmCancelButtonText: 'go back',
  claimConflictConfirmAbandonPurchaseTitle: 'Abandon Purchase?',
  claimConflictConfirmDoNotAttachTitle: "Don't Attach?",

  claimConflictsResolutionTitle: 'Wow, This page is super busy!',
  claimConflictsResolutionDescription:
    'We <b>could not complete</b> your purchase because your selected Calendar {{date}} have just been taken by someone else.',
};

export default {
  calendar: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('calendar', translations);
