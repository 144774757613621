import ExclamationOutlineIcon from '@heroicons/react/outline/ExclamationIcon';
import HeartOutlineIcon from '@heroicons/react/outline/HeartIcon';
import InformationCircleOutlineIcon from '@heroicons/react/outline/InformationCircleIcon';
import ExclamationIcon from '@heroicons/react/solid/ExclamationIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import React from 'react';

export function getCalendarAnnouncementIcon(iconName: string): SvgrComponent | React.FC {
  switch (iconName) {
    case 'InformationCircle':
      return InformationCircleIcon;
    case 'InformationCircleOutline':
      return InformationCircleOutlineIcon;
    case 'Heart':
      return HeartIcon;
    case 'HeartOutline':
      return HeartOutlineIcon;
    case 'Exclamation':
      return ExclamationIcon;
    case 'ExclamationOutline':
      return ExclamationOutlineIcon;
    default:
      return () => <span title={iconName}>⛔</span>;
  }
}
