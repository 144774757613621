import { useEnvStore } from '@gik/core/store/EnvStore';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import type { ProportionSetting } from '@gik/ui/KeepProportions';
import { KeepProportions } from '@gik/ui/KeepProportions';
import type { ImageProps as NextImageProps } from 'next/image';
import NextImage from 'next/image';
import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner';

type ImageBaseProps = {
  /**
   * url to image
   * This is optional because the url to the image could be fetched from the backend.
   * TODO: In that case this component should display itself in a loading state.
   */
  src?: string | string[];
  alt?: string;
  inline?: boolean;
  draggable?: boolean;
  loadingSpinner?: boolean;
  loadingSpinnerDelay?: number;
  fallback?: React.ReactNode;
  onLoadingComplete?: ImageOnLoadingCompleteCallback;
} & UIComponent &
  Omit<NextImageProps, 'src'>;

type ImageAsImgProps = ImageBaseProps & {
  fullWidth?: boolean;
};

type ImageAsBackgroundProps = ImageBaseProps & {
  asBackground: boolean;
  proportion: ProportionSetting;
};

export type ImageProps = ImageAsImgProps | ImageAsBackgroundProps;
export type ImageOnLoadingCompleteCallback = (result: { naturalWidth?: number; naturalHeight?: number }) => void;

const blockName = 'image';

/**
 * Image component.
 *
 * This component will take care of displaying a fallback if no image was provided.
 *
 * TODO: this could also show a loading spinner or skeleton while the image is loading.
 */
function ImageComp({
  width,
  height,
  layout,
  fallback,
  loadingSpinner,
  loadingSpinnerDelay = 500,
  onLoadingComplete,
  ...props
}: ImageProps): React.ReactElement {
  const bem = bemBlock(blockName);
  const isBackground = (props as ImageAsBackgroundProps).asBackground;
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const loadingTimeout = React.useRef<NodeJS.Timeout>();
  const next = useEnvStore(state => state.NEXT);

  React.useEffect(() => {
    if (isBackground || !loadingSpinner) return undefined;
    // set is loading to true after some delay to prevent the loading spinner from showing up a split second
    loadingTimeout.current = setTimeout(() => {
      setIsLoading(true);
    }, loadingSpinnerDelay);
    return () => clearTimeout(loadingTimeout.current);
  }, [loadingSpinnerDelay, loadingTimeout, isBackground, loadingSpinner]);

  const handleOnLoadingComplete: React.ReactEventHandler<React.SyntheticEvent<HTMLImageElement, Event>> =
    React.useCallback(
      event => {
        setIsLoading(false);

        const imgEl = event.target as HTMLImageElement;

        onLoadingComplete?.({ naturalWidth: imgEl?.width, naturalHeight: imgEl?.height });

        if (!loadingSpinner) return;
        clearTimeout(loadingTimeout.current);
      },
      [loadingSpinner, onLoadingComplete]
    );

  const handleOnNextLoadingComplete: ImageOnLoadingCompleteCallback = React.useCallback(
    result => {
      setIsLoading(false);

      onLoadingComplete?.(result);

      if (!loadingSpinner) return;
      clearTimeout(loadingTimeout.current);
    },
    [loadingSpinner, onLoadingComplete]
  );

  if (!props?.src) {
    return (
      <div className={bem(null, 'fallback', [props.className])}>
        {fallback || (
          <>
            image
            <br />
            coming
            <br />
            soon
          </>
        )}
      </div>
    );
  }

  let comp: React.ReactNode;

  // FIXME: refactor move this code to BackgroundImage
  // also, we should avoid using background images as they cannot be optimized by next/image
  // see https://nextjs.org/docs/api-reference/next/image
  if (isBackground) {
    const { src, className, style, proportion, ...otherProps } = props as ImageAsBackgroundProps;
    delete otherProps['inline'];
    delete otherProps['asBackground'];

    // FIXME: do not accept an array, let nextjs do the image optimisation
    let finalSrc: string;

    if (Array.isArray(src)) {
      finalSrc = src[0];
    } else {
      finalSrc = src;
    }

    // FIXME: fix base components inheritance
    comp = (
      <KeepProportions
        proportion={proportion}
        className={bem(
          null,
          [
            {
              'as-background': true,
            },
          ],
          [className]
        )}
      >
        <div
          style={
            finalSrc
              ? {
                  ...style,
                  backgroundImage: `url('${finalSrc}')`,
                }
              : style
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
        />
      </KeepProportions>
    );
  } else {
    const { src, className, style, fullWidth, alt, inline, ...otherProps } = props as ImageAsImgProps;
    delete otherProps['asBackground'];

    // FIXME: do not accept an array, let nextjs do the image optimisation
    let finalSrc: string;

    if (Array.isArray(src)) {
      finalSrc = src[0];
    } else {
      finalSrc = src;
    }

    // don't use a the next image component in storybook
    const shouldUseNextImage = next && ((width && height) || layout);

    if (!shouldUseNextImage) {
      comp = (
        <img
          className={bem(null, [{ 'full-width': fullWidth }, { inline }], [className])}
          alt={alt}
          style={style}
          src={finalSrc || null}
          onLoad={handleOnLoadingComplete}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
        />
      );
    } else {
      comp = (
        <NextImage
          className={bem(null, [{ next: true, 'full-width': fullWidth }, { inline }], [className])}
          width={width}
          height={height}
          alt={alt}
          src={finalSrc || null}
          layout={layout}
          onLoadingComplete={handleOnNextLoadingComplete}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(otherProps as any)}
        />
      );
    }
  }

  return (
    <>
      {comp}
      {isLoading && <LoadingSpinner center />}
    </>
  );
}

// only re-render image if the src changed
export const Image = React.memo(ImageComp, (prevProps, nextProps) => {
  return prevProps?.src === nextProps?.src;
});
