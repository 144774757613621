import { logger } from '@gik/analytics/utils/logger';
import { CalendarEvent, CalendarUtil } from '@gik/calendar';
import type {
  ICalendarAnnouncement,
  ICalendarEntry,
  ICalendarEntryBase,
  ICalendarEvent,
} from '@gik/calendar/models/Calendar';
import type { ICalendarAnnouncementType } from '@gik/calendar/models/CalendarAnnouncement';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import CalendarAnnouncement from '../Announcements/CalendarAnnouncement';

type CalendarDayItemProps = {
  event: ICalendarEvent;
  entries: ICalendarEntryBase[];
  canEdit: boolean;
  announcementTypes: ICalendarAnnouncementType[];
  eventTypes: IWordpressCalendarEventType[];
  resolvingClaim: boolean;
  isSamplePage: boolean;
  privateClaim?: boolean;
  onEditEvent?(event: ICalendarEvent, entry: ICalendarAnnouncement | ICalendarEntry): void;
  onClaimEvent?(event: ICalendarEvent, entry: ICalendarAnnouncement | ICalendarEntry, routeId: string): void;
};

function CalendarDayItemComp({
  event,
  entries,
  canEdit,
  announcementTypes,
  eventTypes,
  resolvingClaim,
  isSamplePage,
  privateClaim,
  onEditEvent,
  onClaimEvent,
}: CalendarDayItemProps) {
  function handleAnnouncementClick(
    ev: React.MouseEvent<HTMLDivElement>,
    event: ICalendarEvent,
    entry: ICalendarEntryBase
  ) {
    ev.stopPropagation();
    if (canEdit) {
      CalendarUtil.editAnnouncement(event, entry);
    } else {
      CalendarUtil.viewAnnouncement(event, entry);
    }
  }

  function customMoreButtonClick(
    ev: React.MouseEvent<HTMLDivElement>,
    event: ICalendarEvent,
    entry: ICalendarAnnouncement
  ) {
    ev.stopPropagation();
    CalendarUtil.viewAnnouncement(event, entry);
  }
  //get entry
  const filtered = entries.filter(item => item.id === event.entryId);

  if (filtered.length > 1) {
    return <div key={event.entryId}>Error: more than 1 item found</div>;
  }

  const entry = filtered[0];

  if (!entry) {
    return <div key={event.entryId}>{`entry ${event.entryId} not found`}</div>;
  }

  if (entry.typeId === CalendarEventTypeIds.Announcement) {
    const announcementEntry = entry as ICalendarAnnouncement;

    const announcementType = announcementTypes.find(item => item.id === announcementEntry.announcementTypeId);

    if (!announcementType) {
      logger.warn(
        `announcementType "${announcementEntry.announcementTypeId}" not found for entry "${announcementEntry.id}"`
      );
    }

    return (
      <CalendarAnnouncement
        key={announcementEntry.id}
        // data-id={announcementEntry.id}
        type={announcementType?.slug || 'info'}
        iconName={(announcementType?.acf.icon || 'Unknown') as string}
        startsAt={announcementEntry.startsAt}
        endsAt={announcementEntry.endsAt}
        allDay={announcementEntry.allDay}
        title={announcementEntry.title}
        description={announcementEntry.description}
        onClick={ev => handleAnnouncementClick(ev, event, announcementEntry)}
        customMoreButtonClick={ev => customMoreButtonClick(ev, event, announcementEntry)}
      />
    );
  } else {
    const calendarEntry = entry as ICalendarEntry;
    return (
      <CalendarEvent
        privateClaim={privateClaim}
        key={entry.id}
        // data-id={entry.id}
        eventTypes={eventTypes}
        event={event}
        entry={calendarEntry}
        onEdit={(event, entry) => onEditEvent(event, entry)}
        onClaim={onClaimEvent}
        resolvingClaim={resolvingClaim}
        isSamplePage={isSamplePage}
      />
    );
  }
}

export const CalendarDayItem = React.memo(
  withComponentErrorBoundary(CalendarDayItemComp),
  (prevProps, nextProps) => prevProps.event === nextProps.event
);
