import { isBrowser } from '@/utils/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { logger } from '@gik/analytics/utils/logger';
import { useEnvStore } from '@gik/core/store/EnvStore';

export default class ErrorLogger {
  static captureException(error: Error, message?: string): void {
    // TODO: when exceptions are caught, they should also automatically provide additional info - a unique error token for user+timestamp
    // This should be generated on the backend and provided to DataDog etc so that we can track errors across services.

    let errorMessage = error.message;
    if (message) {
      errorMessage = message + ': ' + errorMessage;
    }
    errorMessage = errorMessage || 'Unknown error';

    if (useEnvStore.getState().DD_LOGS_CLIENT_TOKEN && isBrowser()) {
      try {
        datadogLogs.logger.error(errorMessage, error);
      } catch (datadogError) {
        logger.error('Failed to log to datadog: ', datadogError);
        logger.error(error);
      }
    } else if (useEnvStore.getState().NODE_ENV !== 'test') {
      logger.error(error);
    }
  }

  static captureWarning(error: Error, message?: string): void {
    // TODO: when exceptions are caught, they should also automatically provide additional info - a unique error token for user+timestamp
    // This should be generated on the backend and provided to DataDog etc so that we can track errors across services.

    let errorMessage = error.message;
    if (message) {
      errorMessage = message + ': ' + errorMessage;
    }
    errorMessage = errorMessage || 'Unknown warning';

    if (useEnvStore.getState().DD_LOGS_CLIENT_TOKEN && isBrowser()) {
      try {
        datadogLogs.logger.warn(errorMessage, error);
      } catch (datadogError) {
        logger.error('Failed to log to datadog: ', datadogError);
        logger.error(error);
      }
    } else if (useEnvStore.getState().NODE_ENV !== 'test') {
      logger.warn(error);
    }
  }
}
