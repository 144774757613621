import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const PayPal = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 107 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M80.9015 16.804H75.2037C74.8145 16.804 74.4876 17.0842 74.4253 17.4734L72.1213 32.0914C72.0746 32.3872 72.2926 32.6362 72.5883 32.6362H75.515C75.7953 32.6362 76.0132 32.4339 76.0599 32.1692L76.7138 28.0282C76.776 27.639 77.103 27.3588 77.4921 27.3588H79.298C83.0498 27.3588 85.2137 25.5374 85.7897 21.9413C86.0388 20.3689 85.8053 19.1235 85.058 18.2673C84.2485 17.3177 82.8163 16.804 80.9015 16.804ZM81.5553 22.1437C81.2439 24.183 79.6872 24.183 78.1615 24.183H77.3053L77.9125 20.3689C77.9436 20.1354 78.146 19.9642 78.3795 19.9642H78.7687C79.7962 19.9642 80.7769 19.9642 81.2751 20.5558C81.5864 20.9138 81.6798 21.4275 81.5553 22.1437Z"
          fill="#009ADE"
        />
        <path
          d="M40.2386 16.804H34.5409C34.1517 16.804 33.8247 17.0842 33.7625 17.4734L31.4585 32.0914C31.4118 32.3872 31.6297 32.6362 31.9255 32.6362H34.6498C35.039 32.6362 35.3659 32.356 35.4282 31.9668L36.0509 28.0282C36.1132 27.639 36.4401 27.3588 36.8293 27.3588H38.6351C42.3869 27.3588 44.5509 25.5374 45.1269 21.9413C45.3759 20.3689 45.1424 19.1235 44.3952 18.2673C43.5857 17.3177 42.1534 16.804 40.2386 16.804ZM40.8925 22.1437C40.5811 24.183 39.0243 24.183 37.4987 24.183H36.6425L37.2496 20.3689C37.2808 20.1354 37.4831 19.9642 37.7167 19.9642H38.1058C39.1333 19.9642 40.1141 19.9642 40.6122 20.5558C40.9236 20.9138 41.017 21.4275 40.8925 22.1437Z"
          fill="#002F87"
        />
        <path
          d="M57.2851 22.0813H54.5608C54.3273 22.0813 54.1249 22.2525 54.0937 22.4861L53.9692 23.2489L53.7824 22.9687C53.1908 22.1124 51.8676 21.8167 50.5599 21.8167C47.5398 21.8167 44.9711 24.1051 44.4729 27.312C44.2083 28.9155 44.5819 30.4411 45.4848 31.4997C46.3255 32.4805 47.5086 32.8852 48.9253 32.8852C51.3538 32.8852 52.7082 31.3285 52.7082 31.3285L52.5837 32.0913C52.537 32.3871 52.7549 32.6362 53.0507 32.6362H55.5104C55.8996 32.6362 56.2265 32.3559 56.2888 31.9668L57.7677 22.6262C57.7988 22.3459 57.5809 22.0813 57.2851 22.0813ZM53.471 27.3899C53.2064 28.9466 51.9765 29.9897 50.3886 29.9897C49.5947 29.9897 48.9564 29.7406 48.5517 29.258C48.1469 28.7754 47.9912 28.106 48.1313 27.3432C48.3804 25.802 49.6414 24.7122 51.1982 24.7122C51.9766 24.7122 52.5993 24.9769 53.0196 25.4595C53.4399 25.9576 53.5956 26.6426 53.471 27.3899Z"
          fill="#002F87"
        />
        <path
          d="M97.9481 22.0813H95.2237C94.9902 22.0813 94.7878 22.2525 94.7567 22.4861L94.6322 23.2489L94.4454 22.9687C93.8538 22.1124 92.5305 21.8167 91.2229 21.8167C88.2027 21.8167 85.6341 24.1051 85.1359 27.312C84.8713 28.9155 85.2449 30.4411 86.1478 31.4997C86.9885 32.4805 88.1716 32.8852 89.5883 32.8852C92.0168 32.8852 93.3712 31.3285 93.3712 31.3285L93.2466 32.0913C93.1999 32.3871 93.4179 32.6362 93.7137 32.6362H96.1734C96.5626 32.6362 96.8895 32.3559 96.9517 31.9668L98.4307 22.6262C98.4618 22.3459 98.2439 22.0813 97.9481 22.0813ZM94.134 27.3899C93.8694 28.9466 92.6395 29.9897 91.0516 29.9897C90.2577 29.9897 89.6194 29.7406 89.2146 29.258C88.8099 28.7754 88.6542 28.106 88.7943 27.3432C89.0434 25.802 90.3044 24.7122 91.8611 24.7122C92.6395 24.7122 93.2622 24.9769 93.6825 25.4595C94.1029 25.9576 94.2585 26.6426 94.134 27.3899Z"
          fill="#009ADE"
        />
        <path
          d="M71.8254 22.0813H69.0855C68.8208 22.0813 68.5718 22.2058 68.4316 22.4238L64.6331 27.997L63.0297 22.6417C62.9363 22.2992 62.6249 22.0813 62.2669 22.0813H59.5737C59.2467 22.0813 59.0132 22.4082 59.1222 22.704L62.1423 31.5776L59.309 35.594C59.0911 35.9054 59.309 36.3413 59.6982 36.3413H62.4381C62.7028 36.3413 62.9363 36.2167 63.0919 35.9988L72.2146 22.8285C72.4325 22.5172 72.199 22.0813 71.8254 22.0813Z"
          fill="#002F87"
        />
        <path
          d="M101.17 17.2087L98.8353 32.0914C98.7886 32.3872 99.0065 32.6362 99.3023 32.6362H101.653C102.042 32.6362 102.369 32.356 102.431 31.9668L104.735 17.3488C104.782 17.053 104.564 16.804 104.268 16.804H101.637C101.404 16.804 101.202 16.9752 101.17 17.2087Z"
          fill="#009ADE"
        />
        <path
          d="M20.9515 17.7069C21.2629 15.7454 20.9515 14.4221 19.8929 13.2078C18.7253 11.8846 16.6237 11.3086 13.9305 11.3086H6.11554C5.57067 11.3086 5.10364 11.7134 5.01024 12.2582L1.7566 32.9009C1.69433 33.3057 2.00568 33.6793 2.42601 33.6793H7.25198L6.92506 35.7965C6.86279 36.1545 7.14301 36.4815 7.50106 36.4815H11.5642C12.0468 36.4815 12.4516 36.1234 12.5294 35.6564L12.5761 35.454L13.3389 30.5969L13.3856 30.3323C13.4635 29.8497 13.8682 29.5072 14.3508 29.5072H14.958C18.8966 29.5072 21.9945 27.9037 22.8975 23.2801C23.2711 21.3497 23.0843 19.7307 22.0879 18.5942C21.7766 18.2206 21.403 17.9404 20.9515 17.7069Z"
          fill="#009ADE"
        />
        <path
          d="M20.9515 17.7069C21.2629 15.7454 20.9515 14.4221 19.8929 13.2078C18.7253 11.8846 16.6237 11.3086 13.9305 11.3086H6.11554C5.57067 11.3086 5.10364 11.7134 5.01024 12.2582L1.7566 32.9009C1.69433 33.3057 2.00568 33.6793 2.42601 33.6793H7.25198L8.46625 25.9889L8.43512 26.2224C8.52853 25.6775 8.97999 25.2728 9.54042 25.2728H11.8289C16.3435 25.2728 19.8618 23.4358 20.8892 18.1428C20.9048 18.0027 20.9359 17.847 20.9515 17.7069Z"
          fill="#002169"
        />
        <path
          d="M9.75837 17.7225C9.80507 17.3955 10.023 17.1309 10.3032 16.9908C10.4278 16.9285 10.5679 16.8974 10.7236 16.8974H16.8572C17.5889 16.8974 18.2583 16.9441 18.881 17.0375C19.0522 17.0686 19.2235 17.0998 19.3947 17.1309C19.566 17.162 19.7217 17.2087 19.8773 17.2554C19.9552 17.271 20.033 17.3021 20.1108 17.3333C20.4222 17.4267 20.7024 17.5512 20.9515 17.6913C21.2629 15.7298 20.9515 14.4066 19.8929 13.1923C18.7253 11.8846 16.6237 11.3086 13.9305 11.3086H6.11554C5.57067 11.3086 5.10364 11.7134 5.01024 12.2582L1.7566 32.9009C1.69433 33.3057 2.00568 33.6793 2.42601 33.6793H7.25198L8.46625 25.9889L9.75837 17.7225Z"
          fill="#002F87"
        />
      </svg>
    );
  }
);

export const PayPalIcon = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M29.6463 13.6231C29.9675 11.5971 29.6463 10.2304 28.5541 8.97618C27.3496 7.60946 25.1813 7.01453 22.4027 7.01453H14.34C13.7779 7.01453 13.2961 7.43258 13.1997 7.99536L9.84291 29.3163C9.77867 29.7344 10.0999 30.1203 10.5335 30.1203H15.5125L15.1752 32.307C15.111 32.6769 15.4001 33.0145 15.7695 33.0145H19.9614C20.4593 33.0145 20.8769 32.6447 20.9572 32.1623L21.0054 31.9533L21.7924 26.9366L21.8406 26.6633C21.9209 26.1648 22.3385 25.8111 22.8364 25.8111H23.4628C27.5262 25.8111 30.7224 24.1549 31.6539 19.3794C32.0394 17.3856 31.8467 15.7134 30.8188 14.5396C30.4975 14.1537 30.1121 13.8642 29.6463 13.6231Z"
          fill="#009ADE"
        />
        <path
          d="M29.6463 13.6231C29.9675 11.5971 29.6463 10.2304 28.5541 8.97618C27.3496 7.60946 25.1813 7.01453 22.4027 7.01453H14.34C13.7779 7.01453 13.2961 7.43258 13.1997 7.99536L9.84291 29.3163C9.77867 29.7344 10.0999 30.1203 10.5335 30.1203H15.5125L16.7653 22.1772L16.7331 22.4184C16.8295 21.8556 17.2953 21.4375 17.8735 21.4375H20.2345C24.8922 21.4375 28.522 19.5402 29.5821 14.0733C29.5981 13.9286 29.6302 13.7678 29.6463 13.6231Z"
          fill="#002169"
        />
        <path
          d="M18.0983 13.6391C18.1465 13.3015 18.3714 13.0281 18.6605 12.8834C18.789 12.8191 18.9335 12.7869 19.0941 12.7869H25.4222C26.1771 12.7869 26.8677 12.8352 27.5102 12.9317C27.6868 12.9638 27.8635 12.996 28.0402 13.0281C28.2169 13.0603 28.3775 13.1085 28.5381 13.1568C28.6184 13.1728 28.6987 13.205 28.779 13.2372C29.1002 13.3336 29.3893 13.4623 29.6463 13.607C29.9675 11.581 29.6463 10.2143 28.5541 8.9601C27.3496 7.60946 25.1813 7.01453 22.4027 7.01453H14.34C13.7779 7.01453 13.2961 7.43258 13.1997 7.99536L9.84291 29.3163C9.77867 29.7344 10.0999 30.1203 10.5335 30.1203H15.5125L16.7653 22.1772L18.0983 13.6391Z"
          fill="#002F87"
        />
      </svg>
    );
  }
);
