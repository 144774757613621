import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import PageFeatureCard from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Accordion, AccordionItem } from '@gik/ui/Accordion';
import { Button } from '@gik/ui/Button';
import { Checkbox } from '@gik/ui/Checkbox';
import type { FormSchemaEntry, ValidationErrors } from '@gik/ui/Form';
import { validateForm } from '@gik/ui/Form';
import { Input } from '@gik/ui/Input';
import type { PopoverProps } from '@gik/ui/Popover';
import { WishlistLarge } from '@gik/ui/SvgIcon/GikIcons/WishlistLarge';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import dynamic from 'next/dynamic';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from 'react-use/lib/usePrevious';

const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
  ssr: false,
});

export type WishlistWithAmazonValues = {
  enabled: boolean;
  amazonWishlistEnabled: boolean;
  amazonValue: string;
  isAmazonValueValid: boolean;
};

export interface IWishlistCardWithAmazonProps {
  value: WishlistWithAmazonValues;
  onChange?: (values: WishlistWithAmazonValues) => void;
  inputName?: string;
  disabled?: boolean;
}

export const amazonWishlistUrlRegex = /^(?:https?:\/\/)?(?:www\.)?amazon\.com\/(?:\S+)/i;
export const amazonWishlistFormSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: 'amazonValue', // must match WishlistWithAmazonValues.amazonValue
    placeholder: 'https://www.amazon.com/baby-reg/wishlistname',
    required: false,
    customValidation: [
      {
        // returns false when form is valid
        validate: (value: string) => !amazonWishlistUrlRegex.test(value),
        message: i18n.t(translationKeys.invalidAmazonWishlistUrlValidation),
      },
    ],
  },
];

export const wishlistCardWithAmazonBlockName = 'wishlist-card-with-amazon';

export function WishlistCardWithAmazonComp({
  value,
  onChange = noop,
  inputName,
  className,
  disabled = false,
}: PropsWithClassName<IWishlistCardWithAmazonProps>) {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const [hasBlurred, setHasBlurred] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(null);

  const isMdUp = useBreakpoint(Breakpoint.MD);

  const prevIsMdUp = usePrevious(isMdUp);
  const prevIsAmazonValueValid = usePrevious(value.isAmazonValueValid);
  const prevHasBlurred = usePrevious(hasBlurred);
  const recalculateHeightFnRef = React.useRef<() => void>(noop);

  React.useEffect(() => {
    if (
      prevIsMdUp !== isMdUp ||
      prevIsAmazonValueValid !== value.isAmazonValueValid ||
      // initial validation error message appears onBlur
      (prevHasBlurred !== hasBlurred && !value.isAmazonValueValid)
    ) {
      recalculateHeightFnRef.current?.();
    }
  }, [isMdUp, prevIsMdUp, value, prevIsAmazonValueValid, prevHasBlurred, hasBlurred]);

  const bem = bemBlock(wishlistCardWithAmazonBlockName);
  const helpMeFindLink =
    'https://help.giveinkind.com/en/articles/3444341-how-do-i-add-my-amazon-wishlist-to-my-give-inkind-page';

  const schema = amazonWishlistFormSchema();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { customValidation: _, ...inputProps } = schema[0];

  // this runs on change
  const customValidateForm = async (values: WishlistWithAmazonValues): Promise<boolean> => {
    const errors: ValidationErrors = await validateForm(schema, values);
    const amazonFieldErrorMessage = errors?.amazonValue?.message;
    setError(amazonFieldErrorMessage);
    // if no message means valid
    return !amazonFieldErrorMessage;
  };

  return (
    <PageFeatureCard
      className={bem(null, null, className)}
      icon={WishlistLarge}
      title={t(translationKeys.featuresWishlistTitle)}
      description={t(translationKeys.featuresWishlistDescription)}
      asideOpen
      onChange={enabled =>
        onChange({
          ...value,
          enabled,
        })
      }
      value={value.enabled}
      inputName={inputName}
      disabled={disabled}
      footer={
        <section
          className={bem('footer', [
            { 'main-disabled': !value.enabled },
            { 'amazon-disabled': !value.amazonWishlistEnabled },
            { 'amazon-enabled': value.amazonWishlistEnabled },
          ])}
        >
          <div className={bem('checkbox-container')}>
            <Checkbox
              checked={value.amazonWishlistEnabled}
              onValueChange={amazonWishlistEnabled =>
                onChange({
                  ...value,
                  amazonWishlistEnabled,
                })
              }
              className={bem('checkbox')}
              label={t(translationKeys.featuresIncludeAmazonCheckboxLabel).toString()}
            />
            <Popover
              trigger={
                <SvgIcon className={bem('icon')} onClick={() => setIsPopoverOpen(true)} Icon={InformationCircleIcon} />
              }
              placement="top"
              isOpen={isPopoverOpen}
              onClose={() => setIsPopoverOpen(false)}
            >
              <div className={bem('popover-content')}>
                <p>{t(translationKeys.amazonWishlistPopover).toString()}</p>
                <Button variant="primary-link" href={helpMeFindLink} target="_blank" className={bem('learn-more')}>
                  {t(translationKeys.learnMore).toString()}
                </Button>
              </div>
            </Popover>
          </div>
          <Accordion
            className={bem('accordion')}
            allToggled={value.amazonWishlistEnabled}
            recalculateHeightCallback={fn => (recalculateHeightFnRef.current = fn)}
          >
            <AccordionItem>
              <Input
                {...inputProps}
                variant="default"
                size="base"
                className={bem('input')}
                value={value.amazonValue}
                onBlur={() => setHasBlurred(true)}
                hasError={hasBlurred && !!error}
                onValueChange={async amazonValue => {
                  const newValue = {
                    ...value,
                    amazonValue,
                  };
                  // important: always validate the latest value, and not value from previous change
                  const isAmazonValueValid = await customValidateForm(newValue);
                  onChange({ ...newValue, isAmazonValueValid });
                }}
              />
              {hasBlurred && error && (
                <div className={bem('validation-error', null, 'gik-form-group__error')}>{error}</div>
              )}
              <Button variant="primary-link" href={helpMeFindLink} target="_blank" className={bem('help-link')}>
                {t(translationKeys.featuresWishlistHelpLink).toString()}
              </Button>
            </AccordionItem>
          </Accordion>
        </section>
      }
    />
  );
}

export const WishlistCardWithAmazon = withComponentErrorBoundary(WishlistCardWithAmazonComp);
