import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { FrameBoneProps } from './FrameBone';
import { FrameBone } from './FrameBone';

const blockName = `${gikClassPrefix}-circle-bone`;

export interface CircleBoneBoneProps extends FrameBoneProps {}

export function CircleBone({ className, children }: CircleBoneBoneProps) {
  const blockClasses = classnames([blockName, className || '']);

  return <FrameBone className={blockClasses}>{children}</FrameBone>;
}
