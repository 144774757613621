import type { MouseEventProps, UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import React from 'react';
import { openSearch } from './SearchStore';

export type SearchButtonProps = {} & UIComponent & MouseEventProps;

function SearchButtonComp({ onClick }: SearchButtonProps) {
  const bem = useBemCN('search-button');
  return (
    <Button
      circle
      variant="default"
      {...bem()}
      onClick={ev => {
        openSearch('NavBar');
        onClick?.(ev);
      }}
    >
      <SvgIcon Icon={SearchIcon} />
    </Button>
  );
}

export const SearchButton = withComponentErrorBoundary(SearchButtonComp);
