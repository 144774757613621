import type {
  APIInfiniteResponseInterface,
  APIResponseInterface,
  BaseAPIResponseError,
} from '@gik/core/api/BaseAPIConfig';
import type { KyInterface } from '@gik/core/api/ky/types';
import { normalizeConfigMiddleware } from '@gik/core/api/swr/middlewares/normalizeConfigMiddleware';
import { swrLoggerMiddleware } from '@gik/core/api/swr/middlewares/swrLoggerMiddleware';
import {
  swrInfiniteWIthHeadersMiddleware,
  swrWithHeadersMiddleware,
} from '@gik/core/api/swr/middlewares/swrWithHeadersMiddleware';
import {
  deserializeInfiniteSWRKey,
  swrWithSerializableObjectKeys,
} from '@gik/core/api/swr/middlewares/swrWithSerializableObjectKeys';
import {
  swrDefaults,
  SWRInfiniteDefaultPerPage,
  SWRInfiniteDefaultPerPageParamName,
} from '@gik/core/api/swr/swrDefaults';
import type { GIKApiError } from '@gik/core/models/gik/APIError';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { Key, SWRConfiguration } from 'swr';
import useSWR from 'swr';
import type { SWRInfiniteConfiguration } from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';
import { KySWRFetcher } from './fetchers/kySWRFetcher';

export function buildSWRKey(path: Key, searchParams?: object) {
  return path && searchParams ? [path, searchParams] : path ? [path] : null;
}

export function buildSWRInfiniteKey(
  path: Key,
  searchParams?: object,
  index?: number,
  perPage = SWRInfiniteDefaultPerPage,
  perPageParamName = SWRInfiniteDefaultPerPageParamName
) {
  if (!path) return;
  const params = {
    ...searchParams,
    page: index + 1,
    ...(perPage ? { [perPageParamName]: perPage } : {}),
  };
  const search = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  return `${path}?${search}`;
}

export type GIKSWRConfiguration = {
  text?: boolean;
} & SWRConfiguration;

export function useKySWR<D, E extends BaseAPIResponseError = GIKApiError>(
  kyInstance: KyInterface,
  key: Key,
  searchParams: object,
  config: GIKSWRConfiguration = {}
): APIResponseInterface<D, E> {
  const useSuspense = useEnvStore(state => state.SUSPENSE_ENABLED);

  if (config?.suspense) {
    config.suspense = useSuspense === 'true';
  }

  const conf: SWRConfiguration = {
    ...swrDefaults,
    ...config,
    use: [swrWithSerializableObjectKeys, normalizeConfigMiddleware, swrWithHeadersMiddleware, swrLoggerMiddleware],
  };

  return useSWR(buildSWRKey(key, searchParams), KySWRFetcher<D>(kyInstance), conf) as APIResponseInterface<D, E>;
}

export function useKySWRInfinite<D, E extends BaseAPIResponseError = GIKApiError>(
  kyInstance: KyInterface,
  key: Key,
  searchParams: object,
  config: SWRInfiniteConfiguration = {},
  perPage = SWRInfiniteDefaultPerPage,
  perPageParamName = SWRInfiniteDefaultPerPageParamName
): APIInfiniteResponseInterface<D, E> {
  const useSuspense = useEnvStore(state => state.SUSPENSE_ENABLED);

  if (config?.suspense) {
    config.suspense = useSuspense === 'true';
  }

  const conf: SWRInfiniteConfiguration = {
    ...swrDefaults,
    ...config,
    use: [normalizeConfigMiddleware, swrInfiniteWIthHeadersMiddleware, swrLoggerMiddleware, deserializeInfiniteSWRKey],
  };

  const { data: _data, ...rest } = useSWRInfinite(
    index => buildSWRInfiniteKey(key, searchParams, index, perPage, perPageParamName),
    (key, searchParams) => KySWRFetcher<D>(kyInstance)(key, searchParams),
    conf
  ) as APIInfiniteResponseInterface<D, E>;

  return {
    data: _data ? [].concat(..._data) : [],
    ...rest,
  };
}
