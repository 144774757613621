import Head from '@/components/Head';
import { DATADOME_JS, DATADOME_TAGS } from '@gik/analytics/utils/DataDome';
import { PageHeadKeys } from '@gik/core/hocs/PageHeadKeys';
import { generateTitle } from '@gik/core/hocs/withTitle';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { isDevelopment } from '@gik/core/utils/environment';
import { noOverlayWorkaroundScript } from '@gik/core/utils/noOverlaayWorkaroundScript';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React from 'react';

export function BaseHeadTags() {
  const router = useRouter();
  const vercelEnv = useEnvStore(state => state.VERCEL_ENV);
  const vercelURL = useEnvStore(state => state.VERCEL_URL);

  const URL = React.useMemo(
    () => (vercelEnv == 'production' ? 'https://www.giveinkind.com' : vercelURL ? `https://${vercelURL}` : ''),
    [vercelEnv, vercelURL]
  );
  const href = React.useMemo(() => `${URL}${router.asPath != '/index' ? router.asPath : ''}`, [URL, router.asPath]);
  const dateNow = React.useMemo(() => new Date().toISOString(), []);
  const pathToFavicons = React.useMemo(() => '/core/img/favicon.ico', []);

  const description = React.useMemo(
    () =>
      'Set up a Give InKind Page to coordinate support from anywhere. Create a' +
      ' Care Calendar, add items to your Wishlist and connect your Crowdfunding - all for free.',
    []
  );

  return (
    <Head>
      <title key={PageHeadKeys.TITLE}>{generateTitle()}</title>

      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="referrer" content="no-referrer-when-downgrade" />

      {/* MS */}
      <meta name="application-name" content="GiveInKind" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content={pathToFavicons + '/ms-icon-144x144.png'} />

      {/* theme-color defines the top bar color */}
      <meta name="theme-color" content="#ffffff" />

      {/* Favicon */}
      <link rel="icon" type="image/png" sizes="192x192" href={pathToFavicons + '/android-icon-192x192.png'} />
      <link rel="icon" type="image/png" sizes="32x32" href={pathToFavicons + '/favicon-32x32.png'} />
      <link rel="icon" type="image/png" sizes="96x96" href={pathToFavicons + '/favicon-96x96.png'} />
      <link rel="icon" type="image/png" sizes="16x16" href={pathToFavicons + '/favicon-16x16.png'} />
      <link rel="shortcut icon" href={pathToFavicons + '/favicon.ico'} type="image/png" />

      {/* Apple */}
      <link rel="apple-touch-icon" sizes="57x57" href={pathToFavicons + '/apple-icon-57x57.png'} />
      <link rel="apple-touch-icon" sizes="60x60" href={pathToFavicons + '/apple-icon-60x60.png'} />
      <link rel="apple-touch-icon" sizes="72x72" href={pathToFavicons + '/apple-icon-72x72.png'} />
      <link rel="apple-touch-icon" sizes="76x76" href={pathToFavicons + '/apple-icon-76x76.png'} />
      <link rel="apple-touch-icon" sizes="114x114" href={pathToFavicons + '/apple-icon-114x114.png'} />
      <link rel="apple-touch-icon" sizes="120x120" href={pathToFavicons + '/apple-icon-120x120.png'} />
      <link rel="apple-touch-icon" sizes="144x144" href={pathToFavicons + '/apple-icon-144x144.png'} />
      <link rel="apple-touch-icon" sizes="152x152" href={pathToFavicons + '/apple-icon-152x152.png'} />
      <link rel="apple-touch-icon" sizes="180x180" href={pathToFavicons + '/apple-icon-180x180.png'} />

      {/* Facebook */}
      <meta property="fb:app_id" content="928334690612401" />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" key={PageHeadKeys.META_OG_TYPE} content="website" />
      <meta
        key={PageHeadKeys.META_OG_TITLE}
        property="og:title"
        content="Give InKind | Connecting People During Times of Need"
      />
      <meta key={PageHeadKeys.META_OG_DESCRIPTION} property="og:description" content={description} />
      <meta property="og:site_name" content="Give InKind" />

      {/* Twitter */}
      <meta name="twitter:card" key={PageHeadKeys.META_TWITTER_CARD} content="summary_large_image" />
      <meta name="twitter:site" content="@giveinkind" />
      <meta name="twitter:description" key={PageHeadKeys.META_TWITTER_DESCRIPTION} content={description} />
      <meta
        name="twitter:title"
        content="Give InKind | Connecting People During Times of Need"
        key={PageHeadKeys.META_TWITTER_TITLE}
      />
      <meta
        property="twitter:image"
        content={`${URL}/homepage/img/inkind-og-image.png`}
        key={PageHeadKeys.META_TWITTER_IMAGE}
      />

      <link rel="canonical" href={href} key={PageHeadKeys.LINK_CANONICAL} />
      <meta property="og:url" content={href} key={PageHeadKeys.META_OG_URL} />
      <meta property="og:image" content={`${URL}/homepage/img/inkind-og-image.png`} key={PageHeadKeys.META_OG_IMAGE} />
      <meta
        property="og:image:secure_url"
        content={`${URL}/homepage/img/inkind-og-image.png`}
        key={PageHeadKeys.META_OG_IMAGE_SECURE_URL}
      />
      <meta property="og:image:width" content="1200" key={PageHeadKeys.META_OG_IMAGE_WIDTH} />
      <meta property="og:image:height" content="627" key={PageHeadKeys.META_OG_IMAGE_HEIGHT} />

      <meta property="og:updated_time" content={dateNow} key={PageHeadKeys.META_OG_UPDATED_TIME} />
      <meta name="description" key={PageHeadKeys.META_DESCRIPTION} content={description} />

      {/* inkind-dev.vercel.app */}
      <meta
        name="google-site-verification"
        key={PageHeadKeys.GOOGLE_SITE_VERIFICATION_DEV_VERCEL_DOMAIN}
        content="unNxLIkepWvtFGqu2rS2CiEbycOYCuO3fnXkRBfdDr8"
      />
      {/* www.inkind.dev */}
      <meta
        name="google-site-verification"
        key={PageHeadKeys.GOOGLE_SITE_VERIFICATION_DEV_DOMAIN}
        content="2KItrsMNSrdSLAtckuqZmZ7CNHdW5MG4KvqVD5jqXuI"
      />

      <script src="https://apis.google.com/js/platform.js" async defer key={PageHeadKeys.GOOGLE_PLATFORM} />

      {isDevelopment && process.env.HIDE_DEV_ERROR_OVERLAY && (
        <script
          key={PageHeadKeys.HIDE_DEV_ERROR_OVERLAY}
          dangerouslySetInnerHTML={{ __html: noOverlayWorkaroundScript }}
        />
      )}

      {process.env.NEXT_PUBLIC_DATADOME_CLIENT_SIDE_KEY && (
        <>
          {/* datadome bot protection */}
          <Script key={PageHeadKeys.DATADOME_JS} strategy="lazyOnload" id="load-datadome">{`
        window.ddjskey = '${process.env.NEXT_PUBLIC_DATADOME_CLIENT_SIDE_KEY}'
        window.ddoptions = {
          endpoint: '${DATADOME_JS}'
        }
      `}</Script>
          <Script key={PageHeadKeys.DATADOME_TAGS} src={DATADOME_TAGS} strategy="lazyOnload" />
        </>
      )}
    </Head>
  );
}
