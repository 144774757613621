import Link from '@/components/Link';
import { useBemCN } from '@gik/core/utils/bemBlock';
// import { linkTo } from '@storybook/addon-links';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { PageRows } from '@gik/ui/gik/PageRows';
import { Input } from '@gik/ui/Input';
import React from 'react';
import type { InkindPageAPIModel } from '../../models/gik/InkindPage';
import routes from '../../routes';
import type { UIComponent } from '../../types/UI';

export type PageToolsProps = {} & UIComponent;

export function PageTools({ className, ...otherProps }: React.PropsWithChildren<PageToolsProps>): React.ReactElement {
  const bem = useBemCN('page-tools');
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const setInkindRouteId = useInkindStore(state => state.setInkindRouteId);

  function handleClick(page: InkindPageAPIModel) {
    setInkindRouteId(page.routeId);
    // linkTo('Packages/InkindPage/Pages', '_InkindPage')({ id: page.routeId });
  }

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <PageRows onClick={handleClick} />
      <div className="tw-p-2">
        <Link href={`${routes.inkinds}/this-does-not-exist`}>Link to non existing page</Link>
      </div>
      <div className="tw-p-2">
        Selected ID:
        <Input value={inkindRouteId} onValueChange={setInkindRouteId} />
      </div>
    </div>
  );
}
