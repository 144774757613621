import { useCalendarAnnouncementTypes } from '@gik/api/calendar/calendarAnnouncementType';
import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import { dateTimeFormat } from '@gik/core/utils/DateTimeUtils';
import { applyTimeToMoment } from '@gik/core/utils/moment';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { dateFormat } from '@gik/l10n';
import type { FormProps, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormError, FormField } from '@gik/ui/Form';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { FormApi, Mutator } from 'final-form';
import type { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeFromInt, timeToInt } from 'time-number';
import type { ICalendarEventTimeAndRepeatFormValues } from '../EventForm/CalendarEventForm';
import { CalendarTimeAndRepeatForm } from '../EventForm/CalendarTimeAndRepeatForm';
import { calendarTimeAndRepeatSchema } from '../EventForm/EventFormSchema';
import CalendarAnnouncement from './CalendarAnnouncement';
import { CalendarAnnouncementSelectList } from './CalendarAnnouncementSelectList';
import { translationKeys } from './i18n/en';

export enum CalendarAnnouncementFormFieldNames {
  Title = 'title',
  Description = 'description',
  AllDay = 'allDay',
  StartDate = 'startDate',
  StartTime = 'startTime',
  EndDate = 'endDate',
  EndTime = 'endTime',
  Repeat = 'repeat',
  Weekdays = 'weekday',
  AnnouncementTypeId = 'announcementTypeId',
  SendEmailToPageFollowers = 'sendEmailToPageFollowers',
}

export interface ICalendarAnnouncementFormValues extends ICalendarEventTimeAndRepeatFormValues {
  [CalendarAnnouncementFormFieldNames.Title]: string;
  [CalendarAnnouncementFormFieldNames.Description]: string;
  [CalendarAnnouncementFormFieldNames.AnnouncementTypeId]: number;
  [CalendarAnnouncementFormFieldNames.SendEmailToPageFollowers]?: boolean;
}

export const calendarAnnouncementFormSchema = async (
  formValues: ICalendarAnnouncementFormValues,
  isRepeatDisabled?: boolean
): Promise<FormSchemaEntry[]> => {
  const schema = [
    {
      type: 'text',
      name: CalendarAnnouncementFormFieldNames.Title,
      required: true,
      label: i18n.t(translationKeys.formTitleLabel),
      placeholder: i18n.t(translationKeys.formTitlePlaceholder),
      maxLength: 255,
      props: {
        maxLength: 255,
        maxLengthDisplay: true,
        maxLengthCentered: true,
      },
    },
    {
      type: 'textarea',
      name: CalendarAnnouncementFormFieldNames.Description,
      required: true,
      label: i18n.t(translationKeys.formDescriptionLabel),
      placeholder: i18n.t(translationKeys.formDescriptionPlaceholder),
      maxLength: 1000,
      props: {
        rows: 6,
        maxLength: 1000,
        maxLengthDisplay: true,
      },
    },
    {
      name: CalendarAnnouncementFormFieldNames.SendEmailToPageFollowers,
      type: 'checkbox',
      props: {
        label: i18n.t(translationKeys.formEmailToPageFollowersLabel),
      },
    },
    ...(await calendarTimeAndRepeatSchema(formValues, true, isRepeatDisabled)),
  ] as FormSchemaEntry[];

  return schema;
};

export interface ICalendarAnnouncementFormProps extends FormProps<Partial<ICalendarAnnouncementFormValues>> {
  initialValues?: Partial<ICalendarAnnouncementFormValues>;
  errorMessage?: string;
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onChange?: (values: ICalendarAnnouncementFormValues) => void;
  onSubmit?: (values: ICalendarAnnouncementFormValues) => void;
}

const emptyValues: ICalendarAnnouncementFormValues = {
  title: null,
  description: null,
  repeat: null,
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
  allDay: false,
  weekdays: null,
  announcementTypeId: null,
};

function CalendarAnnouncementFormComp({
  errorMessage,
  className,
  buttons,
  buttonsPortal,
  initialValues,
  onSubmit,
  onChange,
  onReset,
  ...otherProps
}: ICalendarAnnouncementFormProps): React.ReactElement {
  const bem = bemBlock('calendar-announcement-form');
  const { t } = useTranslation();

  const formId = 'CalendarAnnouncementForm';

  const [_initialValues, setInitialValues] = React.useState<ICalendarAnnouncementFormValues>();
  const [rerender, setRerender] = React.useState<boolean>(false);
  const setFieldValue = React.useRef<() => void>();

  const valuesRef = React.useRef<ICalendarAnnouncementFormValues>();

  const [schema, setSchema] = React.useState<FormSchemaEntry[]>();

  const [announcementTypeId, setAnnouncementTypeId] = React.useState<number>(initialValues.announcementTypeId);

  const { data: announcementTypes } = useCalendarAnnouncementTypes();

  React.useEffect(() => {
    (async () => {
      const schema = await calendarAnnouncementFormSchema(valuesRef.current);
      setSchema(schema);
    })();
  }, []);

  /**
   * Scroll error into view if there is one
   */
  React.useEffect(() => {
    if (errorMessage) {
      // if there is an error we need to wait one render cycle for the dom element to become available
      setTimeout(() => {
        const el = document.querySelector(`#${formId} .gik-form-error`);
        if (el) {
          el.scrollIntoView();
        }
      }, timeoutDefaultValue);
    }
  }, [errorMessage]);

  /**
   * Populate initial form values
   *
   * note: this potentialy needs to wait to get products by event type id if no services were provided
   */
  React.useEffect(() => {
    if (_initialValues) {
      return;
    }

    const values = {
      ...emptyValues,
      // startTime: timeToInt('12:00'),
      ...initialValues,
    };

    valuesRef.current = values;
    setInitialValues(values);
  }, [initialValues, _initialValues]);

  function handleTypeChange(id: number) {
    setAnnouncementTypeId(id);
  }

  function handleChange(values: ICalendarAnnouncementFormValues) {
    // handle startTime change
    if (
      (values.startTime !== undefined &&
        values.startTime !== null &&
        values.startTime !== valuesRef.current?.startTime &&
        parseInt(values.startTime) < timeToInt('23:00')) ||
      !values.endTime
    ) {
      setTimeout(() => {
        // set endTime to be one hour after the startTime
        setFieldValue.current('endTime', (parseInt(values.startTime) + timeToInt('01:00')).toString());
      }, timeoutDefaultValue);
    }

    // if startDate changes the endDate should be set to one month after the startDate (only if there is no initial endDate set when we are editing an existing entry)
    if (!valuesRef.current?.endDate || values.startDate !== valuesRef.current?.startDate) {
      setFieldValue.current('endDate', moment(values.startDate).add(1, 'month').format(dateFormat));
    }

    // if repeat changes rerender the form
    if (values.repeat !== valuesRef.current?.repeat) {
      formRerender();
    }

    valuesRef.current = values;
    setInitialValues(values);
    onChange?.(values);
  }

  async function handleSubmit(values: ICalendarAnnouncementFormValues) {
    values.announcementTypeId = announcementTypeId;
    onSubmit?.(values);
  }

  function formRerender() {
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, timeoutDefaultValue);
  }

  const updateStartTime: Mutator = (args, state, utils) => {
    utils.changeValue(state, 'startTime', () => args[0]);
  };
  const updateEndTime: Mutator = (args, state, utils) => {
    utils.changeValue(state, 'endTime', () => args[0]);
  };
  const updateEndDate: Mutator = (args, state, utils) => {
    utils.changeValue(state, 'endDate', () => args[0]);
  };

  const isLoading = !announcementTypes || !_initialValues;
  if (isLoading) {
    return <LoadingSpinner center />;
  }

  const announcementType = announcementTypes.find(item => item.id === announcementTypeId);

  let startDate = moment(valuesRef.current?.startDate);
  let endDate = moment(valuesRef.current?.endDate || valuesRef.current?.startDate);

  if (valuesRef.current && !valuesRef.current.allDay) {
    startDate = applyTimeToMoment(startDate.clone(), `${timeFromInt(valuesRef.current.startTime || 0)}:00.000`);
    endDate = applyTimeToMoment(
      endDate.isValid ? endDate.clone() : startDate.clone().add(1, 'month'),
      `${timeFromInt(valuesRef.current.endTime || 0)}:00.000`
    );
  }

  if (rerender || !schema) {
    return null;
  }

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      <CalendarAnnouncementSelectList value={announcementTypeId} onChange={handleTypeChange} />
      {announcementType !== undefined && (
        <>
          <label>Preview</label>
          <CalendarAnnouncement
            type={announcementType?.slug}
            iconName={announcementType?.acf.icon as string}
            startsAt={startDate.format(dateTimeFormat)}
            endsAt={endDate.format(dateTimeFormat)}
            allDay={valuesRef.current?.allDay}
            title={valuesRef.current?.title || t(translationKeys.formTitlePlaceholder)}
            description={valuesRef.current?.description || t(translationKeys.formDescriptionPlaceholder)}
            customMoreButtonClick={null}
            displayTime={valuesRef.current.repeat !== undefined && valuesRef.current.repeat !== null}
          />

          <Form
            className={bem('form', null, className)}
            schema={schema}
            vertical
            id={formId}
            // mutators={{ updateStartTime, updateEndTime, updateEndDate }}
            restoreAfterUpdate
            render={form => {
              // formApi = form.form;
              // formApiRef.current = form.form;
              setFieldValue.current = form.setFieldValue;
              const _buttons = buttons ? buttons(form, formId) : null;

              return (
                <>
                  <FormField name={CalendarAnnouncementFormFieldNames.Title} />
                  <FormField name={CalendarAnnouncementFormFieldNames.Description} />

                  <CalendarTimeAndRepeatForm formValues={valuesRef.current} />

                  <FormField name={CalendarAnnouncementFormFieldNames.SendEmailToPageFollowers} />

                  <FormError centered message={errorMessage} />

                  {renderPortal(_buttons, buttonsPortal)}
                </>
              );
            }}
            {...otherProps}
            onSubmit={handleSubmit}
            onChange={(values: ICalendarAnnouncementFormValues) => handleChange(values)}
            initialValues={{ ..._initialValues }}
          />
        </>
      )}
    </div>
  );
}

export const CalendarAnnouncementForm = withComponentErrorBoundary(CalendarAnnouncementFormComp);
