import { Analytics } from '@gik/analytics';
import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { useInkindSituations } from '@gik/api/situation/situation';
import { isEmailAddressAlreadyTaken } from '@gik/api/users/auth';
import type { IEmailAddressAlreadyTakenResponse } from '@gik/api/users/types';
import { useUser } from '@gik/api/users/user';
import { useSuggestedProducts } from '@gik/checkout/api';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { User } from '@gik/core/models/gik/User';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { useCreateFlowStore } from '@gik/create';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { CreateFormSteps } from '@gik/create/enums/CreateFormSteps';
import { EmailTakenStatus } from '@gik/create/enums/EmailTakenStatus';
import { FeatureCareCalendarInputNames } from '@gik/create/enums/FeatureCareCalendarInputNames';
import { FeatureDonationsInputNames } from '@gik/create/enums/FeatureDonationsInputNames';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { SituationStepInputNames } from '@gik/create/enums/SituationStepInputNames';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { isInkindPageRecipientInfoPartiallyComplete } from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import type { StepItem } from '@gik/ui/Steps';
import React from 'react';
import type { GetState, SetState } from 'zustand';
import create from 'zustand';
import shallow from 'zustand/shallow';
import { CreateHelpClickedProperties, CreateStartedStep } from '../enums/CreateAnalyticsEnums';
import { useCreateAnalyticsController } from './useCreateAnalyticsController';

interface ICreateAnalyticsStore {
  [x: `${string}-${CreateStartedStep}-completed`]: boolean;
  setStepCompleted: (idempotencyKey: string, step: CreateStartedStep) => void;
  isStepCompleted: (idempotencyKey: string, step: CreateStartedStep) => boolean;
}

export const useCreateAnalyticsStore = create<ICreateAnalyticsStore>(
  (set: SetState<ICreateAnalyticsStore>, get: GetState<ICreateAnalyticsStore>) => ({
    setStepCompleted: (idempotencyKey, step) => {
      set(state => ({ ...state, [`${idempotencyKey}-${step}-completed`]: true }));
    },
    isStepCompleted: (idempotencyKey, step) => {
      return get()[`${idempotencyKey}-${step}-completed`];
    },
  })
);

function fireCreateStartedEvent(eventData: IAnalyticsProps, userEmail?: string) {
  Analytics.fireEvent(
    AnalyticsEvents.CreateStarted,
    eventData,
    () => ['stepCompleted'],
    keys => keys.filter(key => key !== 'stepCompleted'),
    userEmail
  );
}

export default function useCreateAnalytics(steps: StepItem<CreateFormSteps>[]) {
  const { stepIndex, subStepIndex, emailTakenStatus, newUserLoginProvider } = useCreateFlowStore(
    state => ({
      stepIndex: state.stepIndex,
      subStepIndex: state.subStepIndex,
      emailTakenStatus: state.emailTakenStatus,
      newUserLoginProvider: state.newUserLoginProvider,
    }),
    shallow
  );

  const formValues = useCreateFlowStore(state => state.formValues);

  const { data: suggestedProducts } = useSuggestedProducts(formValues[SituationStepInputNames.Situations]);
  const { data: situations } = useInkindSituations({
    page: 1,
    perPage: 100,
  });

  const recipientType: RecipientType = formValues[AboutStepInputNames.RecipientType];
  const selectedSituations: number[] = formValues[SituationStepInputNames.Situations];
  const careCalendarEnabled: boolean = formValues[FeatureCareCalendarInputNames.CareCalendarEnabled];
  const wishlistEnabled: boolean = formValues[FeatureWishlistInputNames.WishlistEnabled];
  const donationsEnabled: boolean = formValues[FeatureDonationsInputNames.DonationsEnabled];
  const privacySettings = {
    // TODO: consider renaming these to be consistent
    allowCrawlers: String(formValues.searchEnginesEnabled),
    allowFlowers: String(formValues.flowersEnabled),
    allowPageSharing: String(formValues.pageSharingEnabled),
    allowPhoneCalls: String(formValues.phoneCallsEnabled),
    allowTexts: String(formValues.textMessagesEnabled),
    allowVisitors: String(formValues.visitorsEnabled),
    canFeature: String(formValues.featurePageEnabled),
    isPublic: String(formValues.showPageEnabled),
  };

  const [clickedTermsOfService, setClickedTermsOfService] = React.useState<boolean>(false);
  const [clickedPrivacyPolicy, setClickedPrivacyPolicy] = React.useState<boolean>(false);
  const [userClickedCareCalendarVideo, setUserClickedCareCalendarVideo] = React.useState<boolean>(false);
  const [userClickedWishlistVideo, setUserClickedWishlistVideo] = React.useState<boolean>(false);
  const [userClickedDonationsVideo, setUserClickedDonationsVideo] = React.useState<boolean>(false);
  const [userClickedPageNameIdeas, setUserClickedPageNameIdeas] = React.useState<boolean>(false);

  const stepIdToAnalyticsStepNameMap = {
    [CreateFormSteps.ABOUT]: CreateStartedStep.ABOUT,
    [CreateFormSteps.SITUATION]: CreateStartedStep.SITUATIONS,
    [CreateFormSteps.FEATURES]: null,
    [CreateFormSteps.PRIVACY]: CreateStartedStep.PRIVACY,
    [CreateFormSteps.PAGE]: null,
    [CreateFormSteps.FINISH]: CreateStartedStep.FINISH,
  };

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);
  const emailUserId = formValues[AboutStepInputNames.Email] || user?.emailAddress;

  function trackTermsOfService() {
    setClickedTermsOfService(true);
  }

  function trackPrivacyPolicy() {
    setClickedPrivacyPolicy(true);
  }

  function trackIntercomClicked() {
    let currentStepId: CreateFormSteps;

    try {
      currentStepId = steps[stepIndex]?.id;
    } catch (e) {
      logger.error(e);
    }

    let stepNumber;

    switch (currentStepId) {
      case CreateFormSteps.FEATURES:
        switch (subStepIndex) {
          case 0:
            stepNumber = CreateStartedStep.CARE_CALENDAR;
            break;
          case 1:
            stepNumber = CreateStartedStep.WISHLIST;
            break;
          case 2:
            stepNumber = CreateStartedStep.DONATIONS;
            break;
        }
        break;
      case CreateFormSteps.PAGE:
        switch (subStepIndex) {
          case 0:
            stepNumber = CreateStartedStep.PAGE;
            break;
          case 1:
            stepNumber = CreateStartedStep.RECIPIENT;
            break;
        }
        break;
      default:
        stepNumber = stepIdToAnalyticsStepNameMap[currentStepId];
    }

    Analytics.fireEvent(
      AnalyticsEvents.CreateHelpClicked,
      {
        [CreateHelpClickedProperties.STEP_NUMBER]: stepNumber,
      },
      keys => keys // single key - stepNumber
    );
  }

  function trackCalendarVideoPlayed() {
    setUserClickedCareCalendarVideo(true);
  }

  function trackWishlistVideoPlayed() {
    setUserClickedWishlistVideo(true);
  }

  function trackDonationsVideoPlayed() {
    setUserClickedDonationsVideo(true);
  }

  function trackClickedPageNameIdeas() {
    setUserClickedPageNameIdeas(true);
  }

  function hasRecipientAddedAddress(): boolean {
    const partialInkindPage: Partial<InkindPageAPIModel> = {
      address: formValues.address1 || formValues.address2,
      zip: formValues.postalCode,
      city: formValues.city,
      stateCode: formValues.state,
    };

    const isPartiallyComplete = isInkindPageRecipientInfoPartiallyComplete(partialInkindPage);
    return formValues.includeRecipientAddress == true && isPartiallyComplete;
  }

  async function trackOnFinish(user: User) {
    const currentStepId: CreateFormSteps = steps[stepIndex]?.id;
    const userTypeInfo: {
      userType?: string;
      emailPassword?: string;
      googleOAuth?: string;
      facebookOAuth?: string;
    } = {};
    let _emailTakenStatus: IEmailAddressAlreadyTakenResponse;

    if (currentStepId === CreateFormSteps.PAGE) {
      userTypeInfo.userType = 'ExistingUser';

      _emailTakenStatus = await isEmailAddressAlreadyTaken(user.id);

      if (_emailTakenStatus.facebook) {
        userTypeInfo.facebookOAuth = 'true';
      } else if (_emailTakenStatus.google) {
        userTypeInfo.googleOAuth = 'true';
      } else {
        userTypeInfo.emailPassword = 'true';
      }

      if (subStepIndex === 0) {
        fireCreateStartedEvent({
          stepCompleted: CreateStartedStep.PAGE,
          clickedPageNameIdeas: String(userClickedPageNameIdeas),
        });
      }

      fireCreateStartedEvent({
        stepCompleted: CreateStartedStep.RECIPIENT,
        recipientAddedAsPO: String(formValues[RecipientInfoStepInputNames.AddAsPageOrganizer]),
        recipientAddressAdded: String(hasRecipientAddedAddress()),
      });
    } else {
      // FIXME: duplicated calculation of userTypeInfo (see above)
      switch (emailTakenStatus) {
        case EmailTakenStatus.FREE:
          userTypeInfo.userType = 'NewUser';
          break;
        case EmailTakenStatus.GIK:
          userTypeInfo.userType = 'ExistingUser';
          userTypeInfo.emailPassword = 'true';
          break;
        case EmailTakenStatus.GOOGLE:
          userTypeInfo.userType = 'ExistingUser';
          userTypeInfo.googleOAuth = 'true';
          break;
        case EmailTakenStatus.FACEBOOK:
          userTypeInfo.userType = 'ExistingUser';
          userTypeInfo.facebookOAuth = 'true';
          break;
      }
    }

    fireCreateStartedEvent({
      stepCompleted: currentStepId,
      ...userTypeInfo,
      ...(newUserLoginProvider ? { newUserLoginProvider } : {}),
    });
  }

  const createFlowIdempotencyKey = useIdempotencyKeysStore(state => state.createFlowIdempotencyKey);
  const { setStepCompleted, isStepCompleted } = useCreateAnalyticsStore(state => ({
    setStepCompleted: state.setStepCompleted,
    isStepCompleted: state.isStepCompleted,
  }));

  const runIfStepNotCompleted = React.useCallback(
    (step: CreateStartedStep, fn: () => void) => {
      if (!isStepCompleted(createFlowIdempotencyKey, step)) {
        fn();
        setStepCompleted(createFlowIdempotencyKey, step);
      }
    },
    [createFlowIdempotencyKey, isStepCompleted, setStepCompleted]
  );

  // STEP COMPLETED events
  React.useEffect(() => {
    if (!steps || stepIndex === undefined) {
      return;
    }

    const donationInfoProvided = [];
    let currentStepId: CreateFormSteps;

    try {
      currentStepId = steps[stepIndex - 1]?.id;
    } catch (e) {
      logger.error(e);
    }

    switch (currentStepId) {
      case CreateFormSteps.ABOUT:
        runIfStepNotCompleted(CreateStartedStep.ABOUT, () => {
          fireCreateStartedEvent(
            {
              stepCompleted: CreateStartedStep.ABOUT,
              recipientType,
              clickedTermsOfService: String(clickedTermsOfService),
              clickedPrivacyPolicy: String(clickedPrivacyPolicy),
            },
            emailUserId
          );
        });
        break;
      case CreateFormSteps.SITUATION:
        runIfStepNotCompleted(CreateStartedStep.SITUATIONS, () => {
          fireCreateStartedEvent({
            stepCompleted: CreateStartedStep.SITUATIONS,
            situationIDs: selectedSituations.join(','),
            situationNames: situations
              .filter(situation => selectedSituations.includes(situation.id))
              .map(situation => situation.name)
              .join(','),
          });
        });
        break;
      case CreateFormSteps.FEATURES: // if this index changed this means user just finished DONATIONS
        runIfStepNotCompleted(CreateStartedStep.DONATIONS, () => {
          if (formValues[FeatureDonationsInputNames.DonationsValues]?.[PayPalFormInputNames.EmailAddress]) {
            donationInfoProvided.push('PayPal');
          }

          if (formValues[FeatureDonationsInputNames.DonationsValues]?.[GoFundMeFormInputNames.PageURL]) {
            donationInfoProvided.push('GoFundMe');
          }

          if (formValues[FeatureDonationsInputNames.DonationsValues]?.[VenmoFormInputNames.VenmoAddress]) {
            donationInfoProvided.push('Venmo');
          }

          if (formValues[FeatureDonationsInputNames.DonationsValues]?.[CashAppFormInputNames.CashAppAddress]) {
            donationInfoProvided.push('CashApp');
          }

          fireCreateStartedEvent({
            stepCompleted: CreateStartedStep.DONATIONS,
            donationsVideoPlayed: String(userClickedDonationsVideo),
            donationsEnabled: String(donationsEnabled),
            donationInfoProvided: donationInfoProvided.join(','),
          });
        });

        break;
      case CreateFormSteps.PRIVACY:
        runIfStepNotCompleted(CreateStartedStep.PRIVACY, () => {
          fireCreateStartedEvent({
            stepCompleted: CreateStartedStep.PRIVACY,
            ...privacySettings,
          });
        });
        break;
      case CreateFormSteps.PAGE:
        switch (recipientType) {
          case RecipientType.Myself: // if this index changed as MYSELF this means user just finished PAGE
            runIfStepNotCompleted(CreateStartedStep.PAGE, () => {
              fireCreateStartedEvent({
                stepCompleted: CreateStartedStep.PAGE,
                clickedPageNameIdeas: String(userClickedPageNameIdeas),
              });
            });
            // fire RECIPIENT step related events too
            runIfStepNotCompleted(CreateStartedStep.RECIPIENT, () => {
              fireCreateStartedEvent({
                stepCompleted: CreateStartedStep.RECIPIENT,
                recipientAddedAsPO: String(formValues[RecipientInfoStepInputNames.AddAsPageOrganizer]),
                recipientAddressAdded: String(hasRecipientAddedAddress()),
              });
            });
            break;
          default:
            // if this index changed NOT as MYSELF this means user just finished RECIPIENT
            runIfStepNotCompleted(CreateStartedStep.RECIPIENT, () => {
              fireCreateStartedEvent({
                stepCompleted: CreateStartedStep.RECIPIENT,
                recipientAddedAsPO: String(formValues[RecipientInfoStepInputNames.AddAsPageOrganizer]),
                recipientAddressAdded: String(hasRecipientAddedAddress()),
              });
            });
        }
        break;
      case CreateFormSteps.FINISH:
        runIfStepNotCompleted(CreateStartedStep.FINISH, () => {
          if (userId != null) {
            fireCreateStartedEvent({
              stepCompleted: CreateStartedStep.PRIVACY,
              ...privacySettings,
            });
          } else {
            fireCreateStartedEvent({
              stepCompleted: CreateStartedStep.FINISH,
            });
          }
        });
        break;
    }
    // eslint-disable-next-line
  }, [stepIndex]);

  // Sub Step completed events
  React.useEffect(() => {
    if (!steps || stepIndex === undefined) {
      return;
    }

    let currentStepId: CreateFormSteps;
    try {
      currentStepId = steps[stepIndex]?.id;
    } catch (e) {
      logger.error(e);
    }

    switch (currentStepId) {
      case CreateFormSteps.FEATURES:
        switch (subStepIndex - 1) {
          case 0:
            runIfStepNotCompleted(CreateStartedStep.CARE_CALENDAR, () => {
              fireCreateStartedEvent({
                stepCompleted: CreateStartedStep.CARE_CALENDAR,
                careCalendarEnabled: String(careCalendarEnabled),
                calendarVideoPlayed: String(userClickedCareCalendarVideo),
              });
            });
            break;
          case 1:
            runIfStepNotCompleted(CreateStartedStep.WISHLIST, () => {
              fireCreateStartedEvent({
                stepCompleted: CreateStartedStep.WISHLIST,
                wishlistEnabled: String(wishlistEnabled),
                wishlistVideoPlayed: String(userClickedWishlistVideo),
                userRemovedWishlistItemIDs: suggestedProducts
                  ? suggestedProducts
                      .filter(product => !formValues[FeatureWishlistInputNames.WishlistItems]?.includes(product.id))
                      .map(product => product.id)
                      .join(',')
                  : '',
              });
            });

            break;
        }
        break;
      case CreateFormSteps.PAGE:
        switch (recipientType) {
          case RecipientType.Myself:
            break;
          default:
            switch (subStepIndex - 1) {
              case 0:
                runIfStepNotCompleted(CreateStartedStep.PAGE, () => {
                  fireCreateStartedEvent({
                    stepCompleted: CreateStartedStep.PAGE,
                    clickedPageNameIdeas: String(userClickedPageNameIdeas),
                  });
                });
                break;
            }
        }
        break;
    }
    // eslint-disable-next-line
  }, [subStepIndex]);

  return {
    trackOnFinish,
    trackTermsOfService,
    trackPrivacyPolicy,
    trackIntercomClicked,
    trackCalendarVideoPlayed,
    trackWishlistVideoPlayed,
    trackDonationsVideoPlayed,
    trackClickedPageNameIdeas,
    ...useCreateAnalyticsController(),
  };
}
