import React from 'react';
import type { GikIconProps } from '.';
import { GikIconDefaultSize } from '.';

export const Rotate = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        width={size}
        height={size}
        ref={svgRef}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M4 4V9H4.582H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M4.8042 8.5C6.10249 5.83576 8.83685 4 12 4C16.4182 4 20 7.58172 20 12C20 16.4183 16.4182 20 12 20C9.03884 20 6.45346 18.3912 5.07022 16"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
);
