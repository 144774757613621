import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'jit-collect';

const translations = {
  defaultTitle: 'Information Required',
  defaultDescription: 'Please fill in the below fields before continuing.',
  completeUserProfileTitle: 'Complete Your Profile',
  completeUserProfileFirstNameDescription: 'In order to provide you with the best experience, we need your first name:',
  completeUserProfileLastNameDescription: 'In order to provide you with the best experience, we need your last name:',
  error: 'Failed to update. Please try again.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
