import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type {
  IWordpressCalendarEventType,
  IWordpressCalendarEventTypeOption,
} from '@gik/core/models/wordpress/WordpressCalendarEventType';
import React from 'react';
import { apiCap } from '..';

const path = 'cal-event-type';

export function getParams() {
  return {
    perPage: apiCap,
  };
}

export function useCalendarEventTypes(config?: SWRConfigInterface) {
  return useWPApi<IWordpressCalendarEventType[]>(path, getParams(), config);
}

/**
 * Get service categories by event type
 */
export function useCalendarEventTypeOptions(hideAnnouncement?: boolean) {
  const { data: events, ...otherProps } = useCalendarEventTypes();
  const [data, setData] = React.useState<IWordpressCalendarEventTypeOption<number>[]>(null);

  React.useEffect(() => {
    if (!events) return;

    let matchingTypes = events.sort(
      (a: IWordpressCalendarEventType, b: IWordpressCalendarEventType) =>
        parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order)
    );

    if (hideAnnouncement) {
      matchingTypes = matchingTypes.filter(item => item.id !== CalendarEventTypeIds.Announcement);
    }

    const options: IWordpressCalendarEventTypeOption<number>[] = matchingTypes?.map(item => ({
      label: item.name,
      slug: item.slug,
      className: 'gik-calendar-event-type--' + item.slug,
      value: item.id,
      image: item.acf.taxonomy_svg_icon,
      variant: item.acf.color_palette,
    }));

    setData(options);
  }, [events, hideAnnouncement, setData]);

  return {
    error: otherProps.error,
    isValidating: otherProps.isValidating,
    mutate: otherProps.mutate,
    data,
  };
}

export function usePetCareTypes(config?: SWRConfigInterface) {
  return useWPApi<IWordpressCalendarEventType[]>(`${path}/${CalendarEventTypeIds.PetCare}`, null, config);
}
