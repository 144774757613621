import type { Product } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import bemBlock from '@gik/core/utils/bemBlock';
import { formatCurrency } from '@gik/core/utils/format';
import { validator } from '@gik/core/utils/validator';
import i18n from '@gik/i18n';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { getProductMaxValue, getProductMinValue } from '@gik/ui/ProductPrice';

export function getProductDetailsFormSchema(
  product: Product,
  toggleValue: OLProductType,
  nameOnCard: boolean,
  anonymousOverride?: boolean,
  index = 0
): FormSchemaEntry[] {
  const minValue = getProductMinValue(product, toggleValue);
  const maxValue = getProductMaxValue(product, toggleValue);

  const variation = product?.variations?.find(variation =>
    variation.metaData.find(m => m.key == 'pg_product_type' && m.value == 'physical')
  );

  const faceplaces = variation?.metaData.find(m => m.key == 'pg_faceplates')
    ?.value as unknown as PerfectGiftFaceplate[];

  const bem = bemBlock('product-type-select');

  return [
    ...(product.isToggleable
      ? ([
          {
            name: `productType-${index}`,
            type: 'select-list',
            props: {
              name: 'productType',
              inline: true,
              variant: 'primary',
              className: 'gik-product-type-select',
              options: [
                { label: 'Physical', value: 'physical' },
                { label: 'Digital', value: 'digital' },
              ],
              render: (item, selected: boolean, disabled: boolean) => {
                return (
                  <Button
                    variant={selected ? 'primary' : 'default-light'}
                    className={bem('item', [{ selected }])}
                    noFocusStyling
                    disabled={disabled}
                  >
                    {item.label}
                  </Button>
                );
              },
            },
          },
        ] as FormSchemaEntry[])
      : []),

    {
      label: 'Value',
      hideLabel: true,
      name: `item-${index}`,
      type: 'product-price',
      required: product.type !== 'fixed',
      props: {
        product,
        toggleValue,
        enterKeyHint: 'next',
      },
      customValidation: [
        {
          validate: ({ price }) => product.type !== 'fixed' && validator.isEmpty(price),
          message: i18n.t('validation.required'),
        },
        {
          validate: ({ variationId }) => product.type === 'select' && !variationId,
          message: i18n.t('validation.requiredSelect'),
        },
        {
          validate: ({ price }) => product.type === 'input' && validator.isEmpty(price),
          message: i18n.t('validation.requiredInput'),
        },
        {
          validate: ({ price }) => product.type === 'input' && validator.minValueExclusive(minValue, price),
          message: i18n.t('validation.minValue', {
            count: formatCurrency(minValue, { decimals: false }),
          } as object),
        },
        {
          validate: ({ price }) => product.type === 'input' && validator.maxValueExclusive(maxValue, price),
          message: i18n.t('validation.maxValue', {
            count: formatCurrency(maxValue, { decimals: false }),
          } as object),
        },
      ],
    },

    {
      name: `anonymous-${index}`,
      type: 'checkbox',
      props: {
        label: 'Make my gift anonymous',
        disabled: anonymousOverride !== undefined,
      },
    },

    ...(product.isToggleable && faceplaces.length > 1 && product.variations && toggleValue === 'physical'
      ? ([
          {
            name: `uploadPhoto-${index}`,
            type: 'product-pg-upload',
            props: {
              product,
              toggleValue,
            },
          },
        ] as FormSchemaEntry[])
      : []),

    ...(product.isToggleable && faceplaces.length > 1 && toggleValue === 'physical'
      ? ([
          {
            name: `faceplate-${index}`,
            type: 'product-pg-faceplate',
            props: {
              product,
              toggleValue,
            },
          },
        ] as FormSchemaEntry[])
      : []),

    // ...(product.isToggleable && nameOnCard && toggleValue === 'physical'
    //   ? ([
    //       {
    //         label: 'Name On Card',
    //         name: `nameOnCard-${index}`,
    //         type: 'text',
    //         format: '^[a-zA-Z0-9\\s\\-\\.]*$',
    //         formatMessage: 'This field does not allow special characters',
    //         maxLength: 19,
    //         required: true,
    //       },
    //     ] as FormSchemaEntry[])
    //   : []),
    // ...(product.isToggleable && toggleValue === 'physical'
    //   ? ([
    //       {
    //         label: 'Custom Message',
    //         name: `customMessage-${index}`,
    //         type: 'text',
    //         format: '^[a-zA-Z0-9\\s\\-\\.]*$',
    //         formatMessage: 'This field does not allow special characters',
    //         maxLength: 19,
    //         required: true,
    //       },
    //     ] as FormSchemaEntry[])
    //   : []),
  ];
}
