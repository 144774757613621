import { PerfectgiftCarrierTypes } from '@gik/core/models/gik/Order';
import { formatCurrency } from '@gik/core/utils/format';
import type { ButtonElement } from '@gik/ui/Button/ButtonProps';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { noConsecutiveSpaces, noEmptySpaces, noSpecialCharacters } from '@gik/ui/Form/commonValidation';
import type React from 'react';
import { cardCarrierFormId } from '../CheckoutForm/CheckoutForm';

export const cardCarrierFormSchema: (
  greetingCardCarrierPrice: number,
  trifoldWithEnvelopeCarrierPrice: number
) => FormSchemaEntry[] = (greetingCardCarrierPrice: number, trifoldWithEnvelopeCarrierPrice: number) => [
  {
    label: 'Select how your gift card arrives',
    name: 'carrierType',
    type: 'radio-select',
    props: {
      schema: [
        {
          label: `Greeting Card (${formatCurrency(greetingCardCarrierPrice)})`,
          value: PerfectgiftCarrierTypes.GREETING_CARD,
        },
        {
          label: `Standard Trifold (${formatCurrency(trifoldWithEnvelopeCarrierPrice)})`,
          value: PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE,
        },
      ],
      name: 'carrierType',
      inline: true,
      variant: 'primary',
    },
  },
  {
    label: 'To',
    name: 'toName',
    type: 'text',
    customValidation: [noSpecialCharacters, noEmptySpaces, noConsecutiveSpaces],
    required: true,
    maxLength: 50,
  },
  {
    label: 'From',
    name: 'fromName',
    type: 'text',
    customValidation: [noSpecialCharacters, noEmptySpaces, noConsecutiveSpaces],
    required: true,
    maxLength: 50,
    props: {
      enterKeyHint: 'send',
      onKeyPress: (event: React.KeyboardEvent<ButtonElement>) => {
        if (event.key === 'Enter') {
          // NOTE: this does not work (leaving it here as a reference)
          // const formEl = document.getElementById(cardCarrierFormId) as HTMLFormElement;
          // if (formEl) formEl.submit();

          // Trigger the button element with a click
          const btnEl = document.getElementById(cardCarrierFormId + '-btn') as ButtonElement;
          if (btnEl) btnEl.click();
        }
      },
    },
  },
  {
    label: 'Message',
    name: 'message',
    type: 'text',
    required: true,
    minLength: 1,
    maxLength: 50,
    customValidation: [noEmptySpaces, noConsecutiveSpaces],
    props: {
      filterEmojis: true,
    },
  },
  {
    name: 'selectedCarrierDesign',
    type: 'product-pg-greeting-card-design',
  },
];
