import { useQuery } from '@tanstack/react-query';
import { giftWalletAdminEndPoints as endPoints } from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../../contexts';
import type {
  StrapiPagedDataSource,
  StrapiSearchParams,
  StrapiSingleDataSource,
} from '@gik/platform-management-api-js';
import type { StrapiEntity } from '@gik/platform-management-api-js';
import type { WalletEntity, WalletEntityAttrs } from '@gik/platform-management-api-js';
import type { ReactQueryOptions } from '../../../types/react-query';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';
import { useQueryDefaults } from '../../../client';

/**
 * List Wallets
 */
export function useAdminWallets(
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<WalletEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.wallet.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.wallet.list({
          params,
        })
      ).data,
  });
}

/**
 * Get Wallet by id
 */
export function useAdminWallet(
  id?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiSingleDataSource<WalletEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.wallet.get(id);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.wallet.get(id, { params })).data,
  });
}

/**
 * Get Wallet by uuid
 */
export function useAdminWalletByUuid(
  id?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<WalletEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => await client.admin.wallet.getByUuid(id, { params }),
  });

  // return response?.data?.[0];
}

/**
 * Get Wallet by customer id
 */
export function useAdminWalletByCustomerId(
  id?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiEntity & WalletEntityAttrs>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.wallet.getByCustomerId(id);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.admin.wallet.getByCustomerId(id, { params })).data,
  });
}
