import type { AxiosInstance, CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import { GiftResource } from './api/gift';
import { GiftNoteResource } from './api/giftNote';
import { WalletResource } from './api/wallet';
import { AdminOrderLogResource } from './api/admin/orderLog';
import { AdminWalletResource } from './api/admin/wallet';
import { AdminGiftNoteResource } from './api/admin/giftNote';
import { AdminGiftResource } from './api/admin/gift';
import { errorInterceptor } from './axios-interceptors/errorInterceptor';
import { v4 as uuidv4 } from 'uuid';
import { type StoreApi, type UseBoundStore } from 'zustand';

export * from './types/Gift';
export * from './types/GiftNote';
export * from './types/OrderLog';
export * from './types/Response';
export * from './types/Strapi';
export * from './types/Wallet';
export * from './api/endPoints';
export * from './api/admin/endPoints';

export const isServer = typeof window === 'undefined';

export type AppRequestHeadersInterceptorOptions = {
  useAppSessionStore?: UseBoundStore<StoreApi<unknown>>;
  useUserStore?: UseBoundStore<StoreApi<unknown>>;
  useAppLocalStore?: UseBoundStore<StoreApi<unknown>>;
  browserId?: string;
};

export type PlatformManagementJsClientOptions = {
  authorization?: string;
  errorInterceptor?: boolean;
  authInterceptor?: boolean;
  appRequestHeadersInterceptor?: AppRequestHeadersInterceptorOptions;

  browserIdInterceptor?: boolean;
  sessionIdInterceptor?: boolean;
  requestIdInterceptor?: boolean;
  projectPrefix?: string;
  systemPrefix?: string;
};

export class PlatformManagementJsClient {
  public client: AxiosInstance;

  public gift: GiftResource;

  // public orderLog: OrderLogResource;

  public giftNote: GiftNoteResource;

  public wallet: WalletResource;

  public admin: {
    gift: AdminGiftResource;
    orderLog: AdminOrderLogResource;
    giftNote: AdminGiftNoteResource;
    wallet: AdminWalletResource;
  };

  private defaultConfig: CreateAxiosDefaults = {
    withCredentials: true,
  };

  constructor(config: CreateAxiosDefaults = {}, options: PlatformManagementJsClientOptions = {}) {
    this.client = this.createHttpClient(config, options);
    this.gift = new GiftResource(this.client);
    // this.orderLog = new OrderLogResource(this.client);
    this.giftNote = new GiftNoteResource(this.client);
    this.wallet = new WalletResource(this.client);
    this.admin = {
      gift: new AdminGiftResource(this.client),
      orderLog: new AdminOrderLogResource(this.client),
      giftNote: new AdminGiftNoteResource(this.client),
      wallet: new AdminWalletResource(this.client),
    };
  }

  private createHttpClient(config: CreateAxiosDefaults = {}, options?: PlatformManagementJsClientOptions) {
    const httpClient = axios.create(Object.assign(this.defaultConfig, config));

    // register auth interceptor
    if (options?.authInterceptor !== false) {
      httpClient.interceptors.request.use(async _config => {
        const newConfig = _config;
        if (options?.authorization) {
          newConfig.headers['authorization'] = options?.authorization;

          if (localStorage) {
            const storeValue = localStorage.getItem('user-v2');
            const walletStoreStr = localStorage.getItem('wallet-redemption-store');

            if (storeValue) {
              const store = JSON.parse(storeValue);
              const walletStore = JSON.parse(walletStoreStr);
              const customerId = walletStore?.state?.customerId;
              const refreshToken = store?.state?.refreshToken;
              if (refreshToken) newConfig.headers['x-token'] = refreshToken;
              if (refreshToken) newConfig.headers['x-customer-id'] = customerId;
            }
          }
        }
        return newConfig;
      });
    }

    // register error interceptor
    if (options?.errorInterceptor === true) {
      httpClient.interceptors.response.use(response => response, errorInterceptor);
    }
    // console.log('options', options);

    // register app headers request interceptor
    if (options?.appRequestHeadersInterceptor) {
      httpClient.interceptors.request.use(async _config => {
        const opts = options.appRequestHeadersInterceptor;
        const newConfig = _config;

        // Add x-browser-request-id to the request header
        const requestKey = uuidv4();
        newConfig.headers['x-browser-request-id'] = requestKey;

        // Add x-browser-request-id to the request header
        const props = opts?.useAppSessionStore?.getState();

        // @ts-ignore
        const { browserSessionId } = props;

        newConfig.headers['x-browser-session-id'] = browserSessionId;

        const appLocalState = opts?.useAppLocalStore?.getState();
        // @ts-ignore
        newConfig.headers['x-browser-id'] = appLocalState?.browserId;

        const userState = opts?.useUserStore?.getState();
        // @ts-ignore
        newConfig.headers['x-user-id'] = userState.id;

        return newConfig;
      });
    }

    return httpClient;
  }
}
