import { isBrowser } from '@/utils/environment';
import type { IGiftCard } from '@gik/calendar/models/GiftCard';
import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import PlusCircleIcon from '@heroicons/react/solid/PlusCircleIcon';
import arrayMove from 'array-move';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GiftCardTile } from './GiftCardTile';
import { translationKeys } from './i18n/en';

const calendarEventDisclaimerAcceptedKey = 'gik-calendar-event-disclaimer-accepted';

export interface IGiftCardsPickerProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: Product[];
  loading?: boolean;
  disabled?: boolean;
  onChange?: (value: Product[]) => void;
  onEdit?: (selection: Product[]) => void;
}

function GiftCardsPickerComp({
  children,
  value = [],
  className,
  loading,
  disabled,
  onChange,
  onEdit,
  ...otherProps
}: IGiftCardsPickerProps): React.ReactElement {
  const bem = bemBlock('gift-card-picker');
  const { t } = useTranslation();

  const [calendarEventDisclaimerAccepted, setCalendarEventDisclaimerAccepted] = React.useState<boolean>(
    isBrowser() && localStorage.getItem(calendarEventDisclaimerAcceptedKey) === '1'
  );

  const idProp = 'id';

  const [selection, setSelection] = React.useState<Product[]>(value);

  function onSortEnd({ oldIndex, newIndex }) {
    const newSel = arrayMove(selection, oldIndex, newIndex);
    setSelection(newSel);
    onChange?.(newSel);
  }

  function handleDelete(item: IGiftCard) {
    const newSel = selection.filter(option => option[idProp] !== item[idProp]);
    setSelection(newSel);
    onChange?.(newSel);
  }

  function handleDismissFooterMessage() {
    localStorage.setItem(calendarEventDisclaimerAcceptedKey, '1');
    setCalendarEventDisclaimerAccepted(true);
  }

  React.useEffect(() => {
    if (!value) return;
    setSelection(value);
  }, [value]);

  const maxCards = 4;
  let emptyCardsCount = maxCards;
  if (selection) {
    emptyCardsCount = maxCards - selection?.length;
  }

  return (
    <div className={bem(null, [{ disabled }], className)} {...otherProps}>
      {loading ? (
        <LoadingSpinner center />
      ) : (
        <>
          <header className={bem('header')}>
            <span className={bem('title')}>{t(translationKeys.pickerTitle)}</span>
            <nav className={bem('toolbar')}>
              <Button pill variant="secondary" onClick={() => onEdit?.(selection)}>
                {t(translationKeys.pickerEditButton)}
              </Button>
            </nav>
          </header>
          <main className={bem('main')}>
            <div className={bem('grid')}>
              {selection?.map?.((item, index: number) => {
                return (
                  <GiftCardTile
                    key={index}
                    name={item.name}
                    thumbnail={item.gridImage}
                    className={bem('drag-item')}
                    deleteButton={selection?.length > 1}
                    onDelete={() => handleDelete(item)}
                  />
                );
              })}
              {emptyCardsCount > 0 &&
                [...Array(emptyCardsCount)].map((_value, count: number) => {
                  return (
                    <div key={'empty-' + count} className={bem('empty-tile')} onClick={() => onEdit?.(selection)}>
                      <main>
                        <KeepProportions proportion={50 / 79}>
                          <SvgIcon size="xl" Icon={PlusCircleIcon} />
                        </KeepProportions>
                      </main>
                      <footer>hidden</footer>
                    </div>
                  );
                })}
            </div>
          </main>
          {!calendarEventDisclaimerAccepted && (
            <footer className={bem('footer')}>
              {t(translationKeys.pickerDisclaimer)}{' '}
              <Button variant="primary-link" onClick={handleDismissFooterMessage}>
                {t(translationKeys.pickerDisclaimerDismiss)}
              </Button>
            </footer>
          )}
          {children}
        </>
      )}
    </div>
  );
}

// only re-render image if the value array changed
// export const GiftCardsPicker = React.memo(GiftCardsPickerComp, (prevProps, nextProps) => {
//   if (prevProps.disabled !== nextProps.disabled) {
//     return false;
//   }
//   if (prevProps.loading !== nextProps.loading) {
//     return false;
//   }
//   if (prevProps.className !== nextProps.className) {
//     return false;
//   }

//   if (!isEqual(prevProps.value, nextProps.value)) {
//     return false;
//   }

//   return true;
// });

export const GiftCardsPicker = GiftCardsPickerComp;
