import { gikClassPrefix } from '@gik/core/constants';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import type { INotificationProps } from '../Notification';
// import { Notification } from '../Notification/Notification';
import type { UINotificationVariant, UISize } from '../types';
import { useNotificationsContext } from './context';

const Notification = dynamic<INotificationProps>(
  () => import('@gik/ui/Notification/Notification').then(mod => mod.Notification),
  {
    ssr: false,
  }
);

export interface INotification {
  notificationId?: string; // used internally to keep track of notifications
  content: React.ReactNode;
  type?: UINotificationVariant;
  autoClose?: boolean;
  autoCloseTime?: number;
  key?: string;
}

export interface INotificationManagerProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Size is passed to each Notification component
   */
  size?: UISize;

  /**
   * Specifies if the notifications should be positioned at the bottom or top of the screen.
   */
  anchor?: 'top' | 'bottom';

  /**
   * Make this notification closable
   */
  autoClose?: boolean;

  /**
   * Number in milliseconds the notification should autoclose (if autoclose is set to true)
   */
  autoCloseTime?: number;

  portal?: HTMLElement;

  onClose?(notificationId: string): void;
}

/**
 * Notifications Manager
 *
 * Takes care of opening and closing notifications.
 */
export const Notifications = React.forwardRef<HTMLDivElement, INotificationManagerProps>(function Notifications(
  {
    size = 'base',
    anchor = 'top',
    className,
    autoClose = true,
    autoCloseTime = 3000,
    portal,
    onClose,
    ...otherProps
  }: INotificationManagerProps,
  ref
): React.ReactElement {
  const blockName = `${gikClassPrefix}-notifications`;
  let closeTimeout: ReturnType<typeof setTimeout>;

  const notificationsStore = useNotificationsContext();

  function _close(notificationId: string) {
    clearTimeout(closeTimeout);
    notificationsStore.remove(notificationId);
  }

  function handleClose(notificationId: string) {
    _close(notificationId);
    if (onClose) onClose(notificationId);
  }

  const blockClasses: string = classnames([
    { [blockName]: true },
    { [`${blockName}--${anchor}`]: anchor },
    { [`${blockName}--size-${size}`]: size },
    'no-print',
    className || '',
  ]);

  const wrapper = (
    <div ref={ref} className={blockClasses} {...otherProps}>
      <ul>
        <AnimatePresence initial={false}>
          {notificationsStore &&
            notificationsStore.notifications &&
            notificationsStore.notifications.map(item => {
              const { content, ...otherItemProps } = item;
              const itemAutoClose = item.autoClose !== undefined ? item.autoClose : autoClose;
              const itemAutoCloseTime = item.autoCloseTime !== undefined ? item.autoCloseTime : autoCloseTime;
              return (
                <motion.li
                  key={item.notificationId}
                  initial={{ opacity: 0, y: 50, scale: 0.3 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                  <Notification
                    {...otherItemProps}
                    onClose={handleClose}
                    autoClose={itemAutoClose}
                    autoCloseTime={itemAutoCloseTime}
                  >
                    {content}
                  </Notification>
                </motion.li>
              );
            })}
        </AnimatePresence>
      </ul>
    </div>
  );

  if (portal) renderPortal(wrapper, () => portal);
  return wrapper;
});
