import { useCalendarAnnouncementTypes } from '@gik/api/calendar/calendarAnnouncementType';
import type { ICalendarAnnouncementType } from '@gik/calendar/models/CalendarAnnouncement';
import type { IWordpressCalendarAnnouncementOption } from '@gik/core/models/wordpress/WordpressAnnouncementType';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SelectList } from '@gik/ui/SelectList';
import React from 'react';
import { CalendarAnnouncementTile } from './CalendarAnnouncementTile';

export interface ICalendarAnnouncementSelectListProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onSelect'> {
  value?: number;
  onChange?: (value: number, item: ICalendarAnnouncementType) => void;
}

export function CalendarAnnouncementSelectList({
  className,
  value,
  onChange = noop,
  ...otherProps
}: ICalendarAnnouncementSelectListProps): React.ReactElement {
  const bem = bemBlock('calendar-announcement-select-list');

  const [selection, setSelection] = React.useState<number>(value);
  // const selectionRef = React.useRef<number>(value);

  const { data: announcementTypes } = useCalendarAnnouncementTypes();
  const [options, setOptions] = React.useState<IWordpressCalendarAnnouncementOption[]>();

  React.useEffect(() => {
    if (!announcementTypes) return;
    const options: IWordpressCalendarAnnouncementOption[] = announcementTypes
      .sort((a, b) => {
        return a.acf.sort_order - b.acf.sort_order;
      })
      .map(item => ({
        label: item.name,
        slug: item.slug,
        iconName: item.acf.icon + 'Outline',
        // className: classNames(['gik-calendar-announcement-type--' + item.slug]),
        value: item.id,
      }));

    setOptions(options);
  }, [announcementTypes, setOptions]);

  /**
   * Set initial selection to the first item
   */
  React.useEffect(() => {
    if (announcementTypes && !selection) {
      const v = announcementTypes[0].id;
      setSelection(v);
      // selectionRef.current = v;
      onChange(
        v,
        announcementTypes.find(item => item.id === v)
      );
    }
  }, [selection, announcementTypes, onChange]);

  if (!options) return <LoadingSpinner center />;

  return (
    <SelectList<number>
      className={bem(null, null, className)}
      options={options}
      onChange={v => {
        setSelection(v as number);
        onChange(
          v as number,
          announcementTypes.find(item => item.id === v)
        );
      }}
      render={(item: IWordpressCalendarAnnouncementOption, selected: boolean) => {
        return (
          <CalendarAnnouncementTile
            type={item.slug}
            selected={selected}
            iconName={item.iconName as string}
            title={item.label}
            horizontal
          />
        );
      }}
      {...otherProps}
      value={selection}
    />
  );
}
