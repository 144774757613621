import { useBemCN } from '@gik/core/utils/bemBlock';
import { openIntercomWindow } from '@gik/core/utils/Intercom';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IIntercomButtonProps {
  onClick?: Function;
}

const blockName = 'intercom-button';

export function IntercomButton({ onClick, className }: PropsWithClassName<IIntercomButtonProps>) {
  const bem = useBemCN(blockName);
  const { t } = useTranslation();

  function handleOpenIntercom() {
    onClick?.();
    openIntercomWindow();
  }

  return (
    <Button {...bem(null, null, className)} variant={'default-light'} onClick={handleOpenIntercom} circle>
      <SvgIcon Icon={InterrogationRed} />
    </Button>
  );
}
