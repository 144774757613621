import { useBemCN } from '@gik/core/utils/bemBlock';
import { LoadingPopup } from '@gik/ui/gik/LoadingPopup/LoadingPopup';
import type { DeterminationType } from '@gik/ui/LoadingLinear';
import React from 'react';

interface ICreatingPageIndicatorProps {
  progress: number;
  type: DeterminationType;
}

export function CreatingPageIndicator({ progress, type }: ICreatingPageIndicatorProps) {
  const bem = useBemCN('creating-page-indicator');
  return (
    <LoadingPopup
      {...bem()}
      progress={(progress / 8.0) * 100}
      text="Please wait while we create your page..."
      type={type}
    />
  );
}
