import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InkindGroupSelector } from '../../InkindGroupSelector/InkindGroupSelector';
import { SectionTitleAndDescription } from '../utils/SectionTitleAndDescription';
import { PageTitleFieldSet } from './PageTitleFieldSet';

export interface IPageTitleSectionProps {
  id?: string;
  inkindPage?: InkindPageAPIModel;
}

export const pageTitleSectionBlockName = 'page-title-section';

const PageTitleSectionComp: React.FC<PropsWithClassName<IPageTitleSectionProps>> = ({
  id,
  inkindPage,
  className,
}): React.ReactElement => {
  const bem = bemBlock(pageTitleSectionBlockName);
  const { t } = useTranslation();

  return (
    <SectionTitleAndDescription
      id={id}
      className={bem(null, null, className)}
      title={t(translationKeys.pageTitle)}
      description={t(translationKeys.pageTitleDescription)}
    >
      <PageTitleFieldSet className={bem('fieldset')} />
      <p className={bem('max-length')}>{t(translationKeys.pageTitleMaxLength)}</p>
      <InkindGroupSelector inkindPage={inkindPage} />
    </SectionTitleAndDescription>
  );
};

export const PageTitleSection = withComponentErrorBoundary(PageTitleSectionComp);
