import React from 'react';

export default function getRandomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function useRandomInt(min: number, max: number) {
  const [randomInt] = React.useState(getRandomInt(min, max));

  return randomInt;
}
