import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import type { CashAppDonationFormValueType } from '@gik/create/components/DonationProvider/DonationProvider';
import DonationProvider, { DonationProviderType } from '@gik/create/components/DonationProvider/DonationProvider';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { cashAppFormSchema } from '@gik/create/formSchemas';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { CashAppSupportButton } from '@gik/inkind-page/components/Support/CashAppSupportButton';
import React from 'react';

export interface ICashAppDonationProviderProps {
  value: CashAppDonationFormValueType;
  onValueChange: (value: CashAppDonationFormValueType) => void;
  asForm?: boolean;
  trackingId?: AnalyticsEvents;
  variant?: 'create' | 'page-settings';
  recalculateHeightCallback?: () => void;
}

const blockName = 'cashapp-donation-provider';

export default function CashAppDonationProvider({
  value,
  onValueChange,
  asForm = false,
  trackingId,
  variant = 'create',
  recalculateHeightCallback,
}: ICashAppDonationProviderProps) {
  const bem = bemBlock(blockName);

  return (
    <DonationProvider
      asForm={asForm}
      trackingId={trackingId}
      schema={cashAppFormSchema()}
      className={bem('content')}
      provider={DonationProviderType.CashApp}
      target={i18n.t(translationKeys.CashAppTargetVariantCreate)}
      // description={
      //   variant === 'page-settings'
      //     ? i18n.t(translationKeys.CashbackDescriptionVariantPageSettings)
      //     : i18n.t(translationKeys.CashbackDescriptionVariantCreate)
      // }
      value={value}
      onValueChange={onValueChange}
      recalculateHeightCallback={recalculateHeightCallback}
      previewComponent={_value => (
        <CashAppSupportButton value={_value ?? value[CashAppFormInputNames.CashAppAddress]} />
      )}
    />
  );
}
