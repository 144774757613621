import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';

export interface IFieldSetProps extends React.HTMLAttributes<HTMLFieldSetElement> {
  legend?: string;
  disabled?: boolean;

  /**
   * form id this fieldset belogs to
   */
  form?: string;
}

const blockName = `${gikClassPrefix}-fieldset`;

export function FieldSet({
  className,
  children,
  legend,
  ...otherProps
}: React.PropsWithChildren<IFieldSetProps>): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);

  return (
    <fieldset className={blockClasses} {...otherProps}>
      {legend && <legend className={`${blockName}__legend`}>{legend}</legend>}
      {children}
    </fieldset>
  );
}
