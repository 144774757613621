import type { SetState } from 'zustand';
import create from 'zustand';

type IntercomStoreState = {
  readonly isBooted: boolean;
  setBooted(): void;
};

export const useIntercomStore = create<IntercomStoreState>((set: SetState<IntercomStoreState>) => ({
  isBooted: false,
  setBooted: () =>
    set(() => {
      return {
        isBooted: true,
      };
    }),
}));
