import { useAppStore } from '@gik/core/store/AppStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import SparklesIcon from '@heroicons/react/outline/SparklesIcon';
import React from 'react';

export async function updateAppNotification() {
  const bem = bemBlock('app-update-notification');
  useAppStore.setState({ isUpdating: true });

  function handleReload() {
    // refresh window to get the updated app
    location.reload();
  }

  UI.notify(
    <div className={bem()}>
      <span className={bem('title')}>New Version Available</span>
      <p className={bem('content')}>
        <a onClick={handleReload}>Reload now</a> to update Give InKind,
        <br />
        and continue uninterrupted.
      </p>
    </div>,
    {
      prepend: (
        <span className={bem('icon')}>
          <SvgIcon Icon={SparklesIcon} size={'lg'} />
        </span>
      ),
      autoClose: false,
      key: 'app-update',
    }
  );
}
