import type { ClaimConflictErrorDetails, ClaimConflictResolve } from '@gik/checkout/api';
import { useProducts } from '@gik/checkout/api';
import { CheckoutPromoCode } from '@gik/checkout/components/CheckoutPromoCode/CheckoutPromoCode';
import { gikClassPrefix } from '@gik/core/constants';
import type { CartItem, ShippingDetails } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import { formatCurrencyFallback } from '@gik/core/utils/formatCurrencyFallback';
import { isEmpty, maxValueExclusive, minValueExclusive } from '@gik/core/utils/validator';
import i18next from '@gik/i18n';
import WishlistIcon from '@gik/shop/assets/Wishlist-solid.svg';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { Button } from '@gik/ui/Button';
import { Decoder } from '@gik/ui/Decoder';
import { ApiErrorDisplay } from '@gik/ui/gik/ApiErrorDisplay';
import { Image } from '@gik/ui/Image';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { getProductMaxValue, getProductMinValue, ProductPrice } from '@gik/ui/ProductPrice';
import { FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import CalendarIcon from '@heroicons/react/solid/CalendarIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import loadable from '@loadable/component';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';

const Format = loadable.lib(() => import('@gik/core/utils/format'));

export type CartErrorsType = {
  id: string;
  message: string;
};

export type ProductListProps = UIComponent & {
  recipientName?: string;
  cart: CartItem[];
  skeletonItems?: number;
  shipping?: ShippingDetails;
  isShippingStep?: boolean;
  allowRemove?: boolean;
  conflicts?: ClaimConflictErrorDetails[];
  suggestedResolves?: ClaimConflictResolve[];
  inkindRouteId?: string;
  onRemove?: (cartItem: CartItem) => void;
  onClick?: (cartItem: CartItem) => void;
  onRemoveClaim?: (cartItem: CartItem) => void;
  onChangeDate?: (cartItem: CartItem, newDate: Moment) => void;
  onPriceChange?: (cartItem: CartItem, product: Product, newPrice: number, newVariationId?: number) => void;
  onSuggestedResolvesChange?: (suggestions: ClaimConflictResolve[]) => void;
  showPromoInput?: boolean;
  onSuccessfulPromo?(promoCodeId?: string): void;
};

const blockName = `${gikClassPrefix}-product-list`;
export function ProductList({
  className,
  cart,
  recipientName,
  shipping,
  inkindRouteId,
  isShippingStep,
  conflicts,
  suggestedResolves: initialSuggestedResolves,
  allowRemove = true,
  onRemove,
  onClick,
  onRemoveClaim,
  onChangeDate,
  onSuggestedResolvesChange,
  onPriceChange,
  showPromoInput = false,
  onSuccessfulPromo,
  ...otherProps
}: ProductListProps): React.ReactElement {
  const productIds = cart ? cart.map(item => item.productId) : [];
  const { data: products, error: productError, isValidating } = useProducts({ productIds });

  const [errors, setErrors] = React.useState<CartErrorsType[]>([]);

  const isError = productError;

  const bem = useBemCN('product-list');

  const [suggestedResolves, setSuggestedResolves] = React.useState<ClaimConflictResolve[]>([]);

  function isOutsideRange(day: Moment, conflict: ClaimConflictErrorDetails) {
    return !conflict.availableDates?.find(entry => moment(entry.instanceDate).isSame(day, 'day'));
  }

  const useEditableCart = useEnvStore(state => state.USE_EDITABLE_CART);

  const findNextAvailableDate = React.useCallback(
    (conflict: ClaimConflictErrorDetails, suggestions: ClaimConflictResolve[]) => {
      // find all the available dates for this conflict that match the entryId (which means the same giftcard)
      let nextAvailableDatesForThisGC = conflict?.availableDates;
      nextAvailableDatesForThisGC = conflict?.availableDates?.filter(item => {
        return !suggestions.some(
          s =>
            (s.date?.entryId === item.entryId && s.date?.instanceDate === item.instanceDate) ||
            cart.some(c => c.claimEntry?.id === item.entryId && c.claimEntry?.startsAt === item.instanceDate)
        );
      });

      return nextAvailableDatesForThisGC?.[0];
    },
    [cart]
  );

  const updateSuggestedDates = React.useCallback(() => {
    const suggestions = [];

    // loop through each conflicting claim and find a suitable available date for it
    conflicts?.forEach(conflict => {
      const nextAvailableDate = findNextAvailableDate(conflict, suggestions);

      suggestions.push({ id: conflict.id, date: nextAvailableDate });
    });

    setSuggestedResolves(suggestions);
    onSuggestedResolvesChange?.(suggestions);
  }, [conflicts, findNextAvailableDate, onSuggestedResolvesChange]);

  useEffectOnce(() => {
    updateSuggestedDates();
  });

  const handlePriceChange = React.useCallback(
    (cartItem: Partial<CartItem>, product: Product, newPrice: number, newVariationId: number) => {
      const _errors = errors.concat([]);

      const existingIndex = _errors.findIndex(item => item.id === cartItem.id);
      _errors.splice(existingIndex, 1);

      const minValue = getProductMinValue(product, product.type as OLProductType);
      const maxValue = getProductMaxValue(product, product.type as OLProductType);

      let error;

      if (isEmpty(newPrice)) {
        error = { message: 'required' };
      }

      if (product.type === 'input' && !error && minValue !== undefined) {
        if (minValueExclusive(minValue, newPrice)) {
          error = { message: 'minValue', params: { count: minValue.toString() } };
        }
      }
      if (product.type === 'input' && !error && maxValue !== undefined) {
        if (maxValueExclusive(maxValue, newPrice)) {
          error = { message: 'maxValue', params: { count: maxValue.toString() } };
        }
      }

      // auto translate message if it was auto generated
      if (error) {
        error.message = i18next.t('validation.' + error.message, error.params);
      }

      if (error) {
        _errors.push({ id: cartItem.id, error });
        setErrors(_errors);
        onPriceChange(cartItem as CartItem, product, newPrice, newVariationId, _errors);
        return;
      }

      setErrors(_errors);

      onPriceChange(cartItem as CartItem, product, newPrice, newVariationId, _errors);
    },
    [errors, onPriceChange]
  );

  function renderItem(cartItem: CartItem, index: number) {
    // const cartItem = cart?.find(item => item.productId === product.id);

    let matchingConflict: ClaimConflictErrorDetails;
    if (conflicts) {
      matchingConflict = conflicts?.find(conflict => conflict.id === cartItem.id);
      if (!matchingConflict) return null;
    }

    const nextAvailableDate = suggestedResolves?.find(item => item.id === matchingConflict.id)?.date;

    const product = products?.find(product => product.id === cartItem.productId);
    if (!product) return <div>product not found</div>;

    let image = null;
    if (product.gridImage) image = product.gridImage;

    const error = conflicts?.find(item => item.id === cartItem.id);

    const formError = errors?.find(item => item.id === cartItem.id);

    return (
      <div key={cartItem.id} {...bem(null, null, className)} {...otherProps}>
        <header>
          {cartItem.createClaimRequest && (
            <>
              <div {...bem('anon')}></div>
              {conflicts && matchingConflict ? (
                <div {...bem('date', [{ matchingConflict }])}>
                  {matchingConflict?.availableDates?.length > 0 && (
                    <>
                      <div {...bem('date-overwritten')}>
                        <CalendarIcon height={20} />
                        <OriginalDate cartItem={cartItem} />
                      </div>
                      <div>
                        <ChevronRightIcon height={24} />
                      </div>
                    </>
                  )}

                  <div
                    {...bem('date-new', [
                      {
                        matchingConflict: matchingConflict?.availableDates?.length !== 0,
                      },
                      {
                        noAvailableDate: matchingConflict?.availableDates?.length === 0,
                      },
                    ])}
                    // onClick={handleSelectNewDate}
                  >
                    <CalendarIcon height={20} />
                    <span>
                      {moment
                        .utc(nextAvailableDate?.instanceDate ?? cartItem.createClaimRequest.claimDate)
                        .format('MMM. DD, YYYY')}
                    </span>
                  </div>

                  {/* <Popover
                    noPad
                    trigger={
                      <div
                        {...bem('date-new', [
                          {
                            matchingConflict: matchingConflict?.availableDates?.length > 0,
                            noAvailableDate:
                              !matchingConflict?.availableDates || matchingConflict?.availableDates?.length == 0,
                          },
                        ])}
                        // onClick={handleSelectNewDate}
                      >
                        <CalendarIcon height={20} />{' '}
                        <span>
                          {moment.utc(nextAvailableDate.instanceDate).format('MMM. DD, YYYY')}
                        </span>
                      </div>
                    }
                  >
                    {({ close }) => {
                      return (
                        <DayPickerSingleDateController
                          focused
                          hideKeyboardShortcutsPanel
                          date={moment.utc(nextAvailableDate.instanceDate)}
                          // isDayHighlighted={(day: Moment) => isDayHighlighted(day, matchingConflict)}
                          isOutsideRange={(day: Moment) => isOutsideRange(day, matchingConflict)}
                          onDateChange={newDate => {
                            close();
                            onChangeDate?.(cartItem, newDate);
                          }}
                          onFocusChange={() => {}}
                          initialVisibleMonth={() => moment.utc(nextAvailableDate.instanceDate)}
                        />
                      );
                    }}
                  </Popover> */}
                </div>
              ) : (
                <div {...bem('date', [{ matchingConflict }])}>
                  <div>
                    <CalendarIcon height={20} />{' '}
                    <span>{moment.utc(cartItem.createClaimRequest.claimDate).format('MMM. DD, YYYY')}</span>
                  </div>
                </div>
              )}
            </>
          )}

          {cartItem.isWishlist && (
            <>
              <div {...bem('anon')}></div>
              <div {...bem('wishlist')}>
                <WishlistIcon /> <span>From Wishlist</span>
              </div>
            </>
          )}
        </header>
        <main onClick={() => onClick?.(cartItem)}>
          <figure>
            <Image src={image} />
          </figure>
          {!cartItem && <p>Product not found...</p>}
          {cartItem && (
            <>
              <div {...bem('title')}>
                <Decoder text={product.name} />
              </div>

              <div {...bem('price')}>
                {useEditableCart === 'true' ? (
                  <>
                    <ProductPrice
                      // ref={formRef}
                      compact
                      waitForInitialValue
                      value={cartItem}
                      product={product}
                      // toggleValue={product.type as OLProductType}
                      onChange={value => handlePriceChange(cartItem, product, value.price, value.variationId)}
                    />

                    {formError && <div className="gik-form-error">{formError.error.message}</div>}
                  </>
                ) : (
                  <Format fallback={formatCurrencyFallback(cartItem?.price || 0)}>
                    {({ formatCurrency }) => {
                      return formatCurrency(cartItem.price);
                    }}
                  </Format>
                )}
              </div>

              <div {...bem('toolbar')}>
                {allowRemove && (
                  <Button
                    variant="icon"
                    {...bem('btn-remove')}
                    onClick={ev => {
                      ev.stopPropagation();
                      onRemove(cartItem);
                    }}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>
            </>
          )}
        </main>
        {showPromoInput && (
          <footer>
            <CheckoutPromoCode onSuccessfulPurchase={onSuccessfulPromo} />
          </footer>
        )}
        {/* <footer>
          {error && (
            <div {...bem('error')}>
              {error.status} - next available date{' '}
              {moment(error.availableDates?.[0].instanceDate).format(dateDisplayFormat)} or{' '}
              <Button variant="danger-link" onClick={() => onRemoveClaim(cartItem)}>
                remove
              </Button>{' '}
              this claim from the order.
            </div>
          )}
        </footer> */}
      </div>
    );
  }

  if (isError) return <ApiErrorDisplay errors={[productError]} />;

  if (!products && isValidating) return <LoadingSpinner center />;

  return (
    <div>
      {cart?.map((cartItem, index) => {
        return renderItem(cartItem, index);
      })}
      {!products?.length && (
        <div {...bem('empty')}>No Gift Cards have been selected. Please add a Gift Card below to continue...</div>
      )}
    </div>
  );
}

const OriginalDate = React.memo(
  function OriginalDate({ cartItem }: { cartItem: CartItem }) {
    return <span>{moment.utc(cartItem.createClaimRequest.claimDate).format('MMM. DD, YYYY')}</span>;
  },
  () => true
);

export const ProductListSkeleton = ({ skeletonItems }: Partial<ProductListProps>) => {
  const bem = bemBlock(`product-list-skeleton`);
  return (
    <div className={bem()}>
      {Array.from(Array(skeletonItems).keys()).map(index => {
        return (
          <div key={index} className={bem('item')}>
            <FrameBone className={bem('image')} />
            <main>
              <ParagraphBone words={3} className={bem('title')} />
              <ParagraphBone words={20} className={bem('text')} />
            </main>
          </div>
        );
      })}
    </div>
  );
};
