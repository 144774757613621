import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const CashApp = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 98 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <g clipPath="url(#clip0_5975_22645)">
          <path
            d="M65.897 5.15589C66.2692 5.15589 66.6408 5.16162 67.0131 5.15398C67.3515 5.14762 67.5264 5.3213 67.6382 5.6254C68.2913 7.40356 68.9535 9.17854 69.6143 10.9542C70.565 13.5091 71.5164 16.0634 72.4684 18.6177C72.6829 19.1941 72.4601 19.5135 71.8356 19.5148C71.3146 19.516 70.7936 19.5167 70.2732 19.5148C69.8754 19.5129 69.7222 19.4041 69.5945 19.0313C69.2701 18.0846 68.942 17.1392 68.6297 16.1881C68.5684 16.0017 68.4791 15.9572 68.2939 15.9578C66.6249 15.9648 64.9559 15.9661 63.2868 15.9565C63.101 15.9553 63.0321 16.0202 62.9785 16.1811C62.665 17.1195 62.3432 18.0547 62.0233 18.9905C61.8771 19.4181 61.7468 19.5141 61.3088 19.5154C60.8197 19.5167 60.3306 19.5173 59.8415 19.5154C59.2477 19.5129 59.0287 19.2043 59.2394 18.6298C59.8224 17.0393 60.4156 15.4527 61.0081 13.8654C62.0233 11.1456 63.041 8.42656 64.0569 5.70747C64.2401 5.21697 64.3257 5.15335 64.8454 5.15271C65.1959 5.15271 65.5471 5.15271 65.8976 5.15271V5.15653L65.897 5.15589ZM63.7504 13.6612C63.8066 13.6758 63.8353 13.6898 63.864 13.6904C65.1174 13.6917 66.3714 13.6879 67.6248 13.6961C67.8699 13.6981 67.808 13.5994 67.7582 13.4493C67.2085 11.7812 66.6683 10.1099 66.1192 8.44119C66.0292 8.16699 65.9711 7.8788 65.8108 7.60142C65.1187 9.6366 64.4349 11.6457 63.7498 13.6612H63.7504Z"
            fill="white"
          />
          <path
            d="M76.6215 10.136C78.2446 8.146 81.1318 8.37185 82.601 9.49536C83.5964 10.2563 84.196 11.2633 84.4782 12.4613C84.8472 14.0289 84.7642 15.5697 84.0785 17.0438C82.8417 19.7031 80.0719 20.2216 78.1303 19.4003C77.5576 19.1579 77.193 18.8722 76.6643 18.2602C76.5794 18.3378 76.6247 18.4383 76.6241 18.5242C76.6203 19.782 76.6222 21.0397 76.6209 22.2975C76.6209 22.8179 76.4332 23.0037 75.9122 23.0056C75.4978 23.0068 75.0834 23.0087 74.669 23.0056C74.2323 23.0017 74.0299 22.8058 74.0063 22.37C74.0024 22.2962 74.0056 22.2218 74.0056 22.148C74.0056 18.0044 74.0056 13.8609 74.0056 9.7174C74.0056 9.03285 74.1614 8.87826 74.8478 8.87762C75.2303 8.87762 75.6127 8.87126 75.9952 8.87953C76.4172 8.88844 76.6107 9.08884 76.6203 9.51254C76.6247 9.70849 76.6209 9.9038 76.6209 10.1366L76.6215 10.136ZM76.6177 14.1777C76.6088 14.8101 76.7167 15.4202 76.9912 15.9909C77.4426 16.9293 78.182 17.4497 79.2426 17.4847C80.3222 17.5203 81.1248 17.0527 81.5673 16.0863C82.1356 14.8445 82.1432 13.5606 81.582 12.3124C80.7462 10.4554 78.3487 10.5903 77.4043 11.7309C76.8124 12.446 76.6266 13.2813 76.6177 14.1771V14.1777Z"
            fill="white"
          />
          <path
            d="M88.8195 18.1883C88.8195 19.5886 88.8195 20.8877 88.8195 22.1868C88.8195 22.8739 88.6873 23.0055 88.0079 23.0055C87.6146 23.0055 87.2213 23.0113 86.828 23.0036C86.4513 22.9966 86.2744 22.8185 86.2495 22.4412C86.2425 22.3363 86.2361 22.2307 86.2361 22.1251C86.2355 17.9688 86.2361 13.8132 86.2361 9.65695C86.2361 9.01566 86.3766 8.87697 87.0247 8.87634C87.3969 8.87634 87.7691 8.87379 88.1407 8.87634C88.6413 8.88016 88.8156 9.05256 88.8201 9.54116C88.8214 9.71993 88.8201 9.8987 88.8201 10.0768C88.9325 10.1226 88.9504 10.0208 88.9899 9.97377C89.6795 9.16008 90.5785 8.74337 91.625 8.66703C94.1528 8.48253 96.0555 9.88725 96.6812 12.4371C97.1288 14.2604 96.966 16.0373 95.9431 17.6564C94.7402 19.5618 92.3376 20.2769 90.2988 19.3837C89.7248 19.1324 89.2594 18.7437 88.8195 18.187V18.1883ZM94.163 14.1345C94.1994 13.6771 94.1151 13.1891 93.96 12.7145C93.5935 11.5929 92.7717 10.9497 91.6569 10.8975C90.5396 10.8447 89.5959 11.4122 89.1617 12.4587C88.6688 13.6465 88.6752 14.8566 89.2198 16.0227C89.7019 17.0559 90.5638 17.5407 91.7112 17.4815C92.7673 17.4274 93.4568 16.8612 93.8572 15.9101C94.0902 15.356 94.1873 14.7789 94.163 14.1351V14.1345Z"
            fill="white"
          />
          <path
            d="M20.628 4.88672C22.7082 4.91471 24.5528 5.72204 25.9282 7.49766C26.2551 7.91945 26.2072 8.20574 25.7564 8.48821C25.434 8.69052 25.109 8.88965 24.7846 9.08877C24.3753 9.34071 24.1512 9.30826 23.8626 8.92527C22.3188 6.87673 19.5598 7.01669 18.2075 7.97734C17.2281 8.6727 16.6911 9.64035 16.451 10.7842C16.1363 12.2818 16.209 13.7533 16.9075 15.1409C18.2477 17.8053 21.9504 18.1533 23.795 15.8012C23.8537 15.7262 23.9105 15.6498 23.9699 15.5754C24.18 15.3076 24.4258 15.2465 24.7233 15.41C25.1122 15.6237 25.4972 15.8458 25.8771 16.0754C26.2729 16.3147 26.3272 16.5946 26.0507 16.9738C24.5101 19.0872 22.3999 19.9441 19.8216 19.7399C18.4131 19.6279 17.1323 19.1654 16.0328 18.2646C14.6958 17.1697 13.9398 15.7357 13.6334 14.0613C13.2879 12.1743 13.4373 10.3351 14.2878 8.58936C15.432 6.23927 17.7165 4.88099 20.6274 4.88735L20.628 4.88672Z"
            fill="white"
          />
          <path
            d="M50.905 10.0749C51.0187 10.0412 51.0596 9.93558 51.1279 9.86242C52.4598 8.42399 54.9856 8.21086 56.4318 9.43236C57.2804 10.1493 57.6386 11.1214 57.6571 12.1871C57.696 14.4379 57.675 16.69 57.6724 18.9422C57.6724 19.2965 57.4521 19.5058 57.0882 19.5135C56.6419 19.523 56.1956 19.5179 55.7493 19.5154C55.273 19.5128 55.075 19.3156 55.0744 18.8353C55.0725 16.9534 55.0744 15.0716 55.0731 13.1903C55.0731 12.9053 55.0642 12.6216 55.0061 12.3391C54.8228 11.4459 54.2929 10.9936 53.369 10.9579C51.8966 10.9013 50.8827 11.9402 50.8718 13.5434C50.8603 15.2351 50.8693 16.9261 50.8686 18.6177C50.8686 18.7335 50.8629 18.8499 50.8552 18.9651C50.8323 19.3067 50.6579 19.5014 50.3253 19.5103C49.826 19.5237 49.326 19.5237 48.8267 19.5103C48.4935 19.5014 48.3121 19.3003 48.293 18.9657C48.2872 18.8709 48.2847 18.7755 48.2847 18.6807C48.2847 14.4201 48.2847 10.1602 48.2847 5.89958C48.2847 5.29646 48.429 5.15268 49.0336 5.15205C49.4269 5.15205 49.8202 5.14887 50.2136 5.15205C50.6892 5.1565 50.8674 5.33082 50.868 5.80287C50.8706 7.12425 50.868 8.44562 50.8712 9.76699C50.8712 9.86751 50.8354 9.97566 50.905 10.0743V10.0749Z"
            fill="white"
          />
          <path
            d="M33.8122 18.4567C32.4344 19.7819 30.5706 20.1236 28.9029 19.3894C27.7025 18.8607 27.034 17.769 27.0666 16.3891C27.0979 15.0588 27.744 14.151 29.0037 13.6509C29.8427 13.3176 30.7341 13.2463 31.612 13.1032C32.1949 13.0084 32.7951 12.9632 33.3034 12.6063C33.7548 12.2888 33.7995 11.6361 33.3927 11.2595C33.0531 10.9452 32.6285 10.8568 32.1911 10.8237C31.1069 10.741 30.0822 10.97 29.1072 11.4383C28.5689 11.6966 28.3595 11.6272 28.0958 11.0985C27.9783 10.8625 27.8564 10.6284 27.7478 10.3885C27.6016 10.066 27.677 9.79052 27.9847 9.63401C29.8804 8.674 31.8642 8.33809 33.9393 8.91639C35.3874 9.31974 36.2174 10.3752 36.2398 11.8645C36.2755 14.2012 36.2577 16.5386 36.2545 18.876C36.2538 19.343 36.0597 19.5122 35.5828 19.5154C35.2322 19.5179 34.8811 19.5166 34.5299 19.5154C33.9846 19.5135 33.8154 19.3417 33.8122 18.7901C33.8122 18.6959 33.8122 18.6018 33.8122 18.4574V18.4567ZM33.675 14.2916C33.0473 14.6676 32.3692 14.7369 31.7078 14.8584C31.3017 14.9329 30.895 14.9971 30.5061 15.1422C29.9015 15.3674 29.632 15.7656 29.6525 16.3999C29.6723 17.0266 29.9762 17.4255 30.5789 17.6112C30.9824 17.7353 31.3943 17.7321 31.8074 17.6717C32.8334 17.5222 33.5083 16.8389 33.6411 15.8191C33.705 15.3273 33.6609 14.8349 33.675 14.2916Z"
            fill="white"
          />
          <path
            d="M41.7834 8.64474C43.5476 8.64283 44.8412 9.01628 45.9943 9.83633C46.4157 10.136 46.4508 10.4286 46.1162 10.8167C45.9362 11.0254 45.7587 11.236 45.5722 11.4383C45.3098 11.7233 45.0551 11.7392 44.7301 11.5172C43.6025 10.7474 42.3702 10.5081 41.0351 10.8091C40.8461 10.8517 40.6757 10.9357 40.5282 11.0623C40.0678 11.4593 40.1189 12.0802 40.6463 12.3862C41.1628 12.6852 41.7496 12.7507 42.323 12.8481C43.1504 12.9887 43.9715 13.1503 44.7562 13.4563C45.8493 13.8819 46.6328 14.5753 46.7375 15.8165C46.8428 17.0641 46.4412 18.1081 45.4062 18.862C44.5807 19.4638 43.6229 19.6827 42.6211 19.7342C40.9611 19.8201 39.4114 19.4918 38.0508 18.4866C37.5981 18.152 37.5681 17.8867 37.9289 17.4586C38.0923 17.2645 38.2583 17.0718 38.425 16.8796C38.7359 16.5208 38.9607 16.5005 39.3546 16.781C40.4962 17.5934 41.7681 17.8256 43.1364 17.6055C43.3784 17.5667 43.605 17.4751 43.8094 17.3339C44.1465 17.101 44.2857 16.7721 44.2582 16.3764C44.232 15.989 43.9696 15.7771 43.6357 15.648C43.0087 15.4049 42.3434 15.3184 41.687 15.2001C40.8819 15.0544 40.0959 14.8616 39.3476 14.5098C37.1997 13.4989 37.3995 10.5005 39.0686 9.43362C40.0091 8.83242 41.0466 8.64856 41.7822 8.6441L41.7834 8.64474Z"
            fill="white"
          />
          <path
            d="M6.78934 3.00933C6.97003 3.00933 7.15072 3.00806 7.33142 3.00933C7.76815 3.01315 7.91053 3.18683 7.8205 3.60354C7.77198 3.83002 7.72728 4.05714 7.67301 4.28235C7.63215 4.45095 7.66471 4.54765 7.85243 4.61254C8.42962 4.81103 8.94744 5.12277 9.42184 5.50448C9.73662 5.75769 9.74747 5.97908 9.4627 6.27237C9.2852 6.45495 9.10642 6.63563 8.92318 6.81249C8.66139 7.0657 8.50241 7.07906 8.23041 6.85703C7.44571 6.21702 6.54352 5.94282 5.53725 6.00389C5.22759 6.02298 4.92813 6.09741 4.66891 6.28127C4.23154 6.59173 4.20728 7.15477 4.61208 7.50594C4.99518 7.83804 5.4766 7.97291 5.93631 8.13959C6.53139 8.35526 7.1271 8.57475 7.68259 8.87185C9.68809 9.94511 9.24562 12.3137 7.97055 13.2718C7.36973 13.7235 6.68973 13.9748 5.94844 14.0543C5.63239 14.088 5.44787 14.1573 5.38593 14.5302C5.24163 15.3947 5.29271 15.3527 4.36945 15.3477C4.11406 15.3464 3.8593 15.3515 3.6039 15.3464C3.2157 15.3381 3.07523 15.1543 3.15568 14.7694C3.19463 14.5836 3.20931 14.3889 3.27699 14.2146C3.37979 13.9493 3.26039 13.873 3.02926 13.8017C2.37161 13.6 1.78612 13.2622 1.27021 12.8073C0.922237 12.5007 0.913936 12.3149 1.2351 11.9949C1.42281 11.8079 1.60989 11.6202 1.80144 11.437C2.06578 11.1844 2.2337 11.1844 2.51527 11.4293C3.47812 12.2659 4.58909 12.5535 5.83288 12.2736C6.39603 12.147 6.68846 11.7799 6.70697 11.3212C6.7191 11.0107 6.58438 10.797 6.33473 10.6347C5.92099 10.365 5.44723 10.2339 4.99262 10.0622C4.42628 9.84777 3.86632 9.62765 3.34148 9.31909C1.54796 8.26492 1.74972 5.70425 3.45386 4.79194C4.00487 4.49739 4.58909 4.31862 5.21226 4.28172C5.41594 4.26963 5.51108 4.19774 5.55194 3.99543C5.77988 2.85601 5.6707 3.02206 6.78934 3.00933Z"
            fill="#42C545"
          />
        </g>
        <defs>
          <clipPath id="clip0_5975_22645">
            <rect width="95.9155" height="20" fill="white" transform="translate(1 3.00745)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

export const CashAppIcon = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <g clipPath="url(#clip0_3404_398)">
          <path
            d="M24.2567 5.63681C24.6828 5.63681 25.1088 5.63372 25.5349 5.63681C26.5646 5.64609 26.9003 6.06827 26.6881 7.08118C26.5736 7.63171 26.4683 8.18378 26.3403 8.73122C26.2439 9.14102 26.3207 9.37608 26.7633 9.53382C28.1243 10.0163 29.3452 10.7741 30.4638 11.7019C31.206 12.3174 31.2316 12.8555 30.5602 13.5685C30.1416 14.0123 29.7201 14.4515 29.288 14.8814C28.6708 15.4969 28.2959 15.5293 27.6546 14.9896C25.8043 13.4339 23.6771 12.7674 21.3045 12.9159C20.5743 12.9623 19.8682 13.1432 19.257 13.5901C18.2257 14.3448 18.1685 15.7134 19.123 16.567C20.0263 17.3742 21.1614 17.7021 22.2454 18.1072C23.6485 18.6315 25.0531 19.165 26.3629 19.8872C31.0916 22.496 30.0483 28.2534 27.0419 30.5823C25.6252 31.6802 24.0219 32.2911 22.274 32.4844C21.5288 32.5663 21.0937 32.7349 20.9477 33.6411C20.6074 35.7427 20.7279 35.6407 18.5509 35.6283C17.9487 35.6252 17.348 35.6376 16.7458 35.6252C15.8305 35.6051 15.4993 35.1582 15.689 34.2226C15.7808 33.771 15.8155 33.2978 15.975 32.8741C16.2174 32.2292 15.9359 32.0437 15.3909 31.8705C13.8403 31.3802 12.4597 30.5591 11.2433 29.4534C10.4228 28.708 10.4032 28.2564 11.1605 27.4786C11.6031 27.0239 12.0442 26.5677 12.4959 26.1224C13.1191 25.5084 13.5151 25.5084 14.179 26.1038C16.4493 28.1374 19.0688 28.8364 22.0015 28.1559C23.3293 27.8482 24.0188 26.9559 24.0625 25.8409C24.0911 25.0863 23.7734 24.5667 23.1848 24.1723C22.2092 23.5166 21.0922 23.1981 20.0203 22.7805C18.6849 22.2594 17.3646 21.7243 16.1271 20.9743C11.8982 18.4119 12.3739 12.1875 16.3921 9.96991C17.6913 9.25391 19.0688 8.81937 20.5382 8.72967C21.0184 8.70029 21.2427 8.52554 21.3391 8.03378C21.8765 5.26412 21.6191 5.66774 24.2567 5.63681Z"
            fill="#42C545"
          />
        </g>
        <defs>
          <clipPath id="clip0_3404_398">
            <rect width="40" height="40" fill="white" transform="translate(0.826416 0.632446)" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
