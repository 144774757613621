import { navigateTo } from '@/utils/navigateTo';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { PatchInkindPageRequest } from '@gik/core/models/gik/InkindPage';
import routes from '@gik/core/routes';
import bemBlock from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { PremiumPageUpgradeModalContent } from '../components/PremiumPageUpgradeModal/PremiumPageUpgradeModal';
import { getSiteSettings } from '@gik/api/siteSettings/siteSettings';

export type PremiumPageUpgradeModalInitiatedOn = 'supportersPage' | 'inkindPage' | 'inkindPageSettings' | 'profilePage';
export type PremiumPageUpgradeModalVariant = 'viewSupporters' | 'hideSuggestions' | 'generic' | 'thankYouMessages';

type PatchInkindAfterPremiumUpgrade = Pick<PatchInkindPageRequest, 'enableSuggestedArticles'>;

export type openPremiumPageUpgradeModalProps = {
  initiatedOn: PremiumPageUpgradeModalInitiatedOn;
  variant: PremiumPageUpgradeModalVariant;
  // bring the user back to this inkind page when the modal closes
  inkindRouteId?: string;
  inkindSlug?: string;
  onPurchase?: () => void;
  onClose?: () => void;
  patchInkindRequest?: PatchInkindAfterPremiumUpgrade;
};

export async function openPremiumPageUpgradeModal({
  initiatedOn,
  variant,
  inkindRouteId,
  inkindSlug,
  onPurchase,
  onClose,
  patchInkindRequest,
}: openPremiumPageUpgradeModalProps) {
  const bem = bemBlock('premium-page-upgrade-modal');
  const siteSettings = await getSiteSettings();
  const freePremiumPageUpgrades = siteSettings?.freePremiumPageUpgrades === 'true';

  if (inkindRouteId) {
    useInkindStore.getState().setInkindRouteId(inkindRouteId);
  }

  const footerId = 'PremiumPageUpgradeModalFooter';

  Analytics.fireEvent(
    AnalyticsEvents.PremiumPageUpgradeModalOpened,
    { initiatedOn, variant },
    () => ['initiatedOn'],
    () => ['variant']
  );

  // @ts-ignore
  if (useInkindStore.getState().isPremiumModalOpen) return;
  useInkindStore.getState().setPremiumModalOpen(true);

  return UI.dialog(
    ({ close }) => (
      <PremiumPageUpgradeModalContent
        buttonsPortal={() => document.getElementById(footerId)}
        initiatedOn={initiatedOn}
        variant={variant}
        onPurchase={onPurchase}
        patchInkindRequest={patchInkindRequest}
        closeModal={close}
        freePremiumPageUpgrades={freePremiumPageUpgrades}
        freePremiumPageUpgradesTriggerAuto
      />
    ),
    {
      className: bem(null, [
        { [`variant-${variant}`]: variant },
        { 'free-premium-page-upgrades': freePremiumPageUpgrades },
      ]),
      closable: true,
      shouldCloseOnOverlayClick: false,
      disableSystemStyling: true,
      autowidth: false,
      useBlur: false,
      footer: <ModalButtonFooter centeredButtons={false} id={footerId} />,
      backButtonOverrideEnabled: initiatedOn !== 'supportersPage',
      onClose: () => {
        useInkindStore.getState().setPremiumModalOpen(false);

        onClose?.();
        if (!useInkindStore.getState().hasPurchased && inkindRouteId && inkindSlug) {
          navigateTo(`${routes.inkinds}/${inkindRouteId}/${inkindSlug}`);
        }
        return null;
      },
    }
  );
}
