import { PartnerButton } from '@gik/calendar/components/PartnerButton/PartnerButton';
import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

interface DoorDashButtonProps {
  url: string;
  canOrderNow: boolean;
}

const blockName = 'grubhub-button';

const DoorDashButton: React.FC<DoorDashButtonProps> = props => {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  const orderNowCopy = t(translationKeys.orderOnGrubhub);
  const browseCopy = t(translationKeys.browseGrubhub);

  return (
    <PartnerButton
      className={bem()}
      url={props.url}
      canOrderNow={props.canOrderNow}
      orderNowCopy={orderNowCopy}
      browseCopy={browseCopy}
    />
  );
};

export default DoorDashButton;
