import { useInkind } from '@gik/api/inkinds/inkind';
import { useUserPages } from '@gik/api/inkinds/userPages';
import { followPage, unfollowPage } from '@gik/api/users/user';
import { signin } from '@gik/auth/utils/auth';
import { translationKeys as CalTranslationKeys } from '@gik/calendar/components/Calendar/i18n/en';
import { optimisticUpdate } from '@gik/core/api';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { isUserFollowingPage } from '@gik/inkind-page/utils/inkindPagePermissions';
import { Badge } from '@gik/ui/Badge';
import { HoverAnimation } from '@gik/ui/HoverAnimation';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import { useUserPages as useUserPagerAlgolia } from '@gik/user-profile/hooks/useUserPages';
import HeartOutlineIcon from '@heroicons/react/outline/HeartIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IFollowPageCTAProps {
  inkindRouteId: string;
  isSamplePage?: boolean;
  className?: string;
}

const blockName = 'follow-page-cta';

function FollowPageCTAComp({ inkindRouteId, className, isSamplePage }: IFollowPageCTAProps) {
  const bem = useBemCN(blockName);
  const { t } = useTranslation();

  const { data: inkindPage } = useInkind(inkindRouteId);
  const userId = useUserStore(state => state.id);
  const { data: userPages, mutate: mutateUserPages } = useUserPages(userId, { allPages: true });
  const isFollowing = isUserFollowingPage(inkindPage, userId, userPages);
  const { mutate: mutateAlgolia } = useUserPagerAlgolia();

  async function handleFollowPage(recursiveBreak?: boolean) {
    if (!userId && recursiveBreak) {
      return;
    }

    if (!userId) {
      await signin();
      await handleFollowPage(true);
    } else {
      await optimisticUpdate(
        [...(userPages ?? []), inkindPage],
        null,
        mutateUserPages,
        async () => await followPage(userId, inkindRouteId)
      );
    }
  }

  async function handleUnfollowPage() {
    const i = userPages?.findIndex(page => page?.routeId === inkindRouteId);
    const newUserPages = [...(userPages ?? [])];
    newUserPages.splice(i, 1);

    await optimisticUpdate(newUserPages, null, mutateUserPages, async () => await unfollowPage(userId, inkindRouteId));
    mutateAlgolia(); // update the user menu to now include the new page too
  }

  const heartIcon = <SvgIcon {...bem('heart-icon')} Icon={HeartIcon} />;
  const heartIconOutline = <SvgIcon {...bem('heart-icon-outline')} Icon={HeartOutlineIcon} />;

  async function handleClick() {
    if (isSamplePage) {
      return await UI.alert(t(CalTranslationKeys.samplePageWarning));
    }
    if (isFollowing) {
      return await handleUnfollowPage();
    } else {
      return await handleFollowPage();
    }
  }

  if (!inkindPage) return null;

  return (
    <>
      <div {...bem(null, [{ following: isFollowing }], className)}>
        {inkindPage && (
          <div>
            <HoverAnimation>
              <Badge variant="white" pill {...bem('button-follow-unfollow')} onClick={handleClick}>
                <div {...bem('icon-container')}>
                  {heartIcon}
                  {heartIconOutline}
                </div>
                <span {...bem('main-copy')}>
                  {t(
                    isFollowing ? translationKeys.FollowPageCTAFollowingLabel : translationKeys.FollowPageCTAFollowLabel
                  )}
                </span>
                <span {...bem('hover-copy')}>
                  {t(
                    isFollowing ? translationKeys.FollowPageCTAUnfollowLabel : translationKeys.FollowPageCTAFollowLabel
                  )}
                </span>
              </Badge>
            </HoverAnimation>
          </div>
        )}
      </div>
    </>
  );
}

export const FollowPageCTA = withComponentErrorBoundary(FollowPageCTAComp);
