import { gikClassPrefix } from '@gik/core/constants';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import { LayoutTypes } from '@gik/core/types/layouts';
import { AnchorLink } from '@gik/ui/AnchorLink/AnchorLink';
import { ExternalLink } from '@gik/ui/ExternalLink/ExternalLink';
import { Tooltip } from '@gik/ui/Tooltip';
import classnames from 'classnames';
import React from 'react';
import type { HTMLButtonType } from '../types';
import type { ButtonElement, IButtonProps } from './ButtonProps';

const blockName = `${gikClassPrefix}-button`;

// <div className="tw-ring-2 tw-ring-offset-2"/>

function ButtonComp(
  {
    type = 'a',
    externalLink = false,
    scrollLink = false,
    customIconSize,
    variant = 'primary',
    size = 'base',
    tabIndex = 0,
    block,
    append,
    prepend,
    children,
    focus,
    loading,
    className,
    squared,
    circle,
    pill,
    rounded,
    active,
    hover,
    disabled,
    uppercase = true,
    wide,
    onClick,
    onFocus,
    onBlur,
    onKeyPress,
    tooltip,
    allowTooltipOnMobile,
    tooltipClassName,
    contentClassName,
    href,
    fullWidth,
    autoHeight,
    preventClickWhenDisabled,
    noFocusStyling = false,
    layout = LayoutTypes.horizontal,
    hideLabelOnMobile,
    debounce,
    form,
    ...otherProps
  }: IButtonProps,
  forwardedRef: React.MutableRefObject<HTMLElement>
): React.ReactElement {
  const [focused, setFocused] = React.useState(false);
  const finalTabIndex = disabled ? -1 : tabIndex;

  const isMounted = useIsMounted();

  const ref = React.useRef();
  React.useImperativeHandle(forwardedRef, () => ref.current, [ref]);

  const debounceRef = React.useRef<NodeJS.Timeout>(null);
  if (debounce > 0) preventClickWhenDisabled = true;

  const handleClick = React.useCallback(
    (ev: React.MouseEvent<ButtonElement>): void => {
      // prevent clicking if button is disabled
      // TODO: prevent clicking if is loading
      if ((preventClickWhenDisabled && disabled) || loading) {
        ev.preventDefault();
        return;
      }

      if (debounce > 0) {
        clearTimeout(debounceRef.current);
        const originalDisabled = disabled;
        debounceRef.current = setTimeout(() => onClick?.(ev), debounce);
      } else {
        // trigger custom callback if provided
        onClick?.(ev);
      }

      // don't bother with a focus rectangle if it wasn't focused already
      // TODO: this does not work correctly, need to handle this in the mouseDown and mouseUp events
      // buttonRef?.blur && buttonRef.blur();
    },
    [debounce, disabled, loading, onClick, preventClickWhenDisabled]
  );

  const handleKeyPress = React.useCallback(
    (ev: React.KeyboardEvent<ButtonElement>): void => {
      if (ev.which === 32 || ev.which === 13) {
        // this will prevent scroll when the user uses spacebar to press the button
        ev.preventDefault();
        // trigger a click event when space or enter is pressed
        if (onClick) {
          // eslint-disable-next-line
          // @ts-ignore
          onClick(new MouseEvent('click'));
        }
        // trigger custom callback if provided
        if (onKeyPress) {
          onKeyPress(ev);
        }
      }
    },
    [onClick, onKeyPress]
  );

  const handleFocus = React.useCallback(
    (ev: React.FocusEvent<ButtonElement>): void => {
      // disabled buttons should not receive focus
      // note: onFocus should never be called in the first place
      // buttons in these states should have a tabIndex of -1
      if (disabled || loading) {
        return;
      }

      if (isMounted()) setFocused(true);
      if (onFocus) {
        onFocus(ev);
      }
    },
    [disabled, isMounted, loading, onFocus]
  );

  const handleBlur = React.useCallback(
    (ev: React.FocusEvent<ButtonElement>): void => {
      if (isMounted()) setFocused(false);
      if (onBlur) {
        onBlur(ev);
      }
    },
    [isMounted, onBlur]
  );

  // generate classnames based on properties
  // TODO: this can be refactored in various ways...
  // FIXME: migrate to useBemCN
  const classNames: string = classnames([
    { [blockName]: true },
    { [`${blockName}--${variant}`]: variant },
    { [`${blockName}--size-${size}`]: size },
    { [`${blockName}--active`]: active },
    { [`${blockName}--hover`]: hover },
    { [`${blockName}--block`]: block },
    { [`${blockName}--full-width`]: fullWidth },
    { [`${blockName}--auto-height`]: autoHeight },
    { [`${blockName}--disabled`]: disabled },
    { [`${blockName}--focus`]: focused || focus },
    { [`${blockName}--loading `]: loading },
    { [`${blockName}--pill `]: pill },
    { [`${blockName}--circle `]: circle },
    { [`${blockName}--squared `]: squared },
    { [`${blockName}--rounded `]: rounded },
    { [`${blockName}--wide `]: wide },
    { [`${blockName}--vertical `]: layout === LayoutTypes.vertical },
    { [`${blockName}--horizontal `]: layout === LayoutTypes.horizontal },
    { [`${blockName}--uppercase `]: uppercase },
    { [`${blockName}--no-focus-styling `]: noFocusStyling },
    { [`${blockName}--hide-label-mobile `]: hideLabelOnMobile },
    { [`${blockName}--custom-icon-size `]: customIconSize },
    { ['is-text']: variant && variant.endsWith('-text') },
    className || '',
  ]);

  // always wrap content in a span to avoid plain text which can not be position correctly
  const newChildren: React.ReactNode = (
    <>
      {prepend && <div className={`${blockName}__prepend`}>{prepend}</div>}
      {children && <div className={`${blockName}__content ${contentClassName}`}>{children}</div>}
      {append && <div className={`${blockName}__append`}>{append}</div>}
    </>
  );

  const commonProps = {
    tabIndex: finalTabIndex,
    className: classNames,
    onClick: handleClick,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyPress: handleKeyPress,
    ...otherProps,
    form: form,
  };

  const getType = () => {
    // determine tag to be rendered based on the input prop
    let tag = 'a';
    if (type === 'submit') {
      tag = 'button';
    } else if (type === 'button' || type === 'reset') {
      tag = 'button';
    }

    if (tag === 'a') {
      if (externalLink) {
        return (
          <ExternalLink href={href} {...commonProps}>
            {newChildren}
          </ExternalLink>
        );
      } else if (scrollLink) {
        // FIXME: fix base components inheritance
        return (
          <AnchorLink
            ref={ref}
            to={href.split('#')[1]}
            spy
            smooth
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(commonProps as any)}
          >
            {newChildren}
          </AnchorLink>
        );
      } else {
        // FIXME: fix base components inheritance
        return (
          <a
            ref={ref}
            href={href}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(commonProps as any)}
          >
            {newChildren}
          </a>
        );
      }
    } else if (tag === 'input') {
      // FIXME: fix base components inheritance
      return (
        <input
          ref={ref}
          type={type as HTMLButtonType}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(commonProps as any)}
        />
      );
    } else {
      // FIXME: fix base components inheritance
      return (
        <button
          ref={ref}
          type={type as HTMLButtonType}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(commonProps as any)}
        >
          {newChildren}
        </button>
      );
    }
  };

  if (tooltip) {
    return (
      <Tooltip
        text={tooltip}
        interactive={typeof tooltip !== 'string'}
        allowOnMobile={allowTooltipOnMobile}
        className={`${blockName}__tooltip ${tooltipClassName ?? ''}`}
      >
        {getType()}
      </Tooltip>
    );
  } else {
    return getType();
  }
}

export const Button = React.forwardRef(ButtonComp);

// Also export the component without a forwardRef.
// Used in storybook to extract component properties.
// export const ButtonNoRef = Button;
