import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { Group } from '@gik/core/models/gik/Group';
import type { PaginationParams } from '../pagination';

export type GroupsParams = {
  userId: string;
} & PaginationParams;

function getParams(params: GroupsParams) {
  return {
    page: 1,
    perPage: 10,
    ...params,
  };
}

export function useGroups(params: GroupsParams, config?: SWRConfigInterface) {
  return useApi<Group[]>(params.userId ? 'groups' : null, getParams(params), config);
}

export async function addInkindToGroup(groupId: string, inkindRouteId: string) {
  return await dotnetApi.put(`groups/${groupId}/inkinds/${inkindRouteId}`);
  // if (result.status !== StatusCodes.NO_CONTENT) {
  //   throw new UnhandledError(`Unexpected response code: ${result.status} ${result.statusText}`);
  // }
}

export async function removeInkindFromGroup(groupId: string, inkindRouteId: string) {
  return await dotnetApi.delete(`groups/${groupId}/inkinds/${inkindRouteId}`);
}
