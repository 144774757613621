import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import React from 'react';

export interface ITooltipProps extends TippyProps {
  text: string | React.ReactNode;
  allowOnMobile?: boolean;
  className?: string;
}

const blockName = 'tooltip';

export function Tooltip({
  text,
  allowOnMobile,
  className,
  children,
  ...otherProps
}: ITooltipProps): React.ReactElement {
  const bem = useBemCN(blockName);
  const isMd = useBreakpoint(Breakpoint.MD);

  if (allowOnMobile || isMd) {
    return (
      <Tippy
        {...bem(null, null, [className, 'tippy-v2'])}
        arrow
        inertia
        placement={'top'}
        theme="light-border"
        hideOnClick={false}
        content={text}
        {...otherProps}
      >
        {children}
      </Tippy>
    );
  } else {
    return children;
  }
}
