import { dateTimeFormat } from '@gik/core/utils/DateTimeUtils';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

/**
 * Backend returns dates as UTC, we need to accept them as UTC and display as is
 */
export function convertDateFromBackend(dateString: string): Moment {
  return moment.tz(dateString, 'UTC');
}

/**
 * Dates at the frontend include the timezone by default.
 * We need to strip away the timezone so our dates will not be converted to UTC at the backend.
 * We are basically forcing the local time to be UTC without converting it.
 */
export function convertDateToBackend(date: Moment): string {
  return date.format(dateTimeFormat) + '+00:00';
}
