import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const GoFundMe = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 129 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M124.405 18.2644C124.57 17.893 124.694 17.563 124.859 17.2535C124.921 17.1091 125.148 17.0472 125.292 17.1091C125.375 17.1504 125.354 17.2123 125.354 17.2535C125.354 17.893 125.354 18.5326 125.354 19.1515C125.354 19.2752 125.313 19.3165 125.189 19.3165C125.065 19.3165 125.024 19.2752 125.024 19.1515C125.024 18.6976 125.024 18.2437 125.024 17.8105C125.024 17.7486 125.044 17.6661 125.003 17.5836C124.859 17.9343 124.673 18.2231 124.57 18.5738C124.549 18.6563 124.529 18.7182 124.426 18.7182C124.322 18.7182 124.281 18.677 124.24 18.5945C124.137 18.3263 124.013 18.0581 123.889 17.8105C123.869 17.7693 123.848 17.6867 123.786 17.6661C123.745 17.7486 123.765 17.8311 123.765 17.9137C123.765 18.3263 123.765 18.7389 123.765 19.1721C123.765 19.2959 123.745 19.3578 123.6 19.3578C123.456 19.3578 123.456 19.2752 123.456 19.1721C123.456 18.5738 123.456 17.9549 123.456 17.3567C123.456 17.2741 123.394 17.1504 123.559 17.1504C123.683 17.1504 123.848 17.0678 123.93 17.2535C124.075 17.563 124.219 17.893 124.364 18.2025C124.364 18.1819 124.364 18.2025 124.405 18.2644Z"
          fill="#009758"
        />
        <path
          d="M122.115 18.3264C122.115 18.0789 122.094 17.8313 122.115 17.6044C122.136 17.3981 122.053 17.3362 121.868 17.3774C121.764 17.3981 121.661 17.3774 121.558 17.3774C121.455 17.3774 121.393 17.3568 121.393 17.233C121.393 17.0886 121.455 17.0886 121.558 17.0886C122.033 17.0886 122.528 17.0886 123.002 17.0886C123.126 17.0886 123.167 17.1299 123.167 17.2537C123.167 17.3774 123.105 17.3981 123.002 17.3981C122.878 17.3981 122.755 17.3981 122.631 17.3981C122.486 17.3774 122.425 17.4393 122.425 17.6044C122.425 18.1201 122.425 18.6152 122.425 19.131C122.425 19.2548 122.425 19.3373 122.26 19.3373C122.094 19.3373 122.115 19.2341 122.115 19.131C122.115 18.8422 122.115 18.5946 122.115 18.3264Z"
          fill="#009758"
        />
        <path
          d="M93.8729 23.1537C94.203 22.8649 94.5125 22.5967 94.8632 22.3698C95.8534 21.7509 96.9468 21.5652 98.1021 21.6684C98.6384 21.7096 99.1748 21.8334 99.6699 22.0603C100.392 22.3698 100.908 22.8855 101.341 23.5251C101.423 23.6282 101.444 23.6489 101.527 23.5251C102.269 22.4111 103.321 21.8128 104.621 21.6478C105.921 21.4827 107.117 21.7509 108.108 22.638C108.603 23.0918 108.891 23.6695 109.015 24.3503C109.098 24.7835 109.118 25.2374 109.118 25.6912C109.118 27.9605 109.118 30.2504 109.118 32.5197C109.118 32.7054 109.057 32.7673 108.871 32.7673C107.674 32.7673 106.478 32.7673 105.281 32.7673C105.116 32.7673 105.054 32.726 105.054 32.5403C105.054 30.7455 105.054 28.9507 105.054 27.1559C105.054 26.7227 105.013 26.2895 104.91 25.8769C104.559 24.4122 102.888 24.3915 102.146 25.0929C101.692 25.5262 101.547 26.0626 101.465 26.6608C101.403 27.1559 101.423 27.6717 101.423 28.1668C101.423 29.6315 101.423 31.0962 101.423 32.561C101.423 32.7054 101.382 32.7673 101.238 32.7673C100.021 32.7673 98.8035 32.7673 97.5863 32.7673C97.4213 32.7673 97.38 32.7054 97.38 32.561C97.38 30.7455 97.38 28.9301 97.38 27.1147C97.38 26.7433 97.3594 26.372 97.2975 26.0007C97.1737 25.3199 96.7611 24.9073 96.101 24.7216C95.317 24.5359 94.5331 24.8248 94.1205 25.4643C93.8729 25.8563 93.811 26.2895 93.811 26.7433C93.7904 28.6619 93.7904 30.5805 93.811 32.4991C93.811 32.7054 93.7491 32.7466 93.5635 32.7466C92.3669 32.7466 91.1704 32.7466 89.9739 32.7466C89.8088 32.7466 89.7676 32.7054 89.7676 32.5403C89.7676 29.0333 89.7676 25.5055 89.7676 21.9985C89.7676 21.8334 89.8295 21.7922 89.9739 21.7922C91.191 21.7922 92.3876 21.7922 93.6047 21.7922C93.7698 21.7922 93.811 21.854 93.811 21.9985C93.8729 22.3698 93.8729 22.7411 93.8729 23.1537Z"
          fill="#009758"
        />
        <path
          d="M79.2876 29.7348C79.432 29.7348 79.5764 29.7554 79.7208 29.7348C81.2062 29.5491 82.1964 28.0225 81.7632 26.599C81.2474 24.928 79.2669 24.3297 77.9054 25.2168C76.9151 25.8563 76.6263 26.7847 76.7707 27.713C76.9358 28.8477 78.0291 29.8173 79.2876 29.7348ZM81.7013 31.6327C81.4537 31.8803 81.2061 32.1072 80.938 32.3135C80.3191 32.7674 79.6176 32.9943 78.875 33.0768C77.6991 33.2006 76.6057 33.0149 75.5742 32.4579C73.9444 31.5708 72.8717 30.2299 72.5828 28.3526C72.3353 26.8259 72.521 25.3406 73.408 24.0203C74.357 22.6174 75.6773 21.751 77.369 21.5034C78.6274 21.3178 79.8033 21.5241 80.8761 22.1842C81.1649 22.3492 81.4124 22.5968 81.6806 22.8237C81.7219 22.7412 81.7013 22.6587 81.7013 22.5968C81.7013 20.1625 81.7013 17.7075 81.7013 15.2732C81.7013 15.0669 81.7632 15.0256 81.9488 15.0256C83.166 15.0256 84.3625 15.0256 85.5797 15.0256C85.7241 15.0256 85.786 15.0669 85.786 15.2319C85.786 21.0083 85.786 26.7847 85.786 32.5817C85.786 32.7261 85.7654 32.788 85.6003 32.788C84.3625 32.788 83.1454 32.788 81.9076 32.788C81.7425 32.788 81.7013 32.7467 81.7013 32.5817C81.7219 32.2516 81.7013 31.9422 81.7013 31.6327Z"
          fill="#009758"
        />
        <path
          d="M24.1027 24.7216C23.8964 24.7216 23.7107 24.7422 23.5044 24.7629C22.019 25.0517 20.8844 26.764 21.7096 28.3731C22.2666 29.4459 23.4012 30.0029 24.6597 29.7759C26.0831 29.5284 27.0115 28.1049 26.6814 26.7021C26.4751 25.6912 25.4023 24.6803 24.1027 24.7216ZM23.9995 21.4002C25.4642 21.4002 26.6608 21.5859 27.7748 22.1841C29.322 23.03 30.4154 24.2471 30.8074 26.0007C31.4056 28.6825 30.106 31.2613 27.6304 32.4372C26.7433 32.8498 25.8149 33.0561 24.8453 33.118C23.8138 33.1799 22.7823 33.1386 21.7715 32.8704C19.9354 32.3753 18.5326 31.3438 17.7486 29.5696C16.8822 27.6098 17.1091 25.2374 18.7183 23.5044C19.7291 22.4111 20.9669 21.8128 22.411 21.5446C22.9886 21.4415 23.5869 21.3796 23.9995 21.4002Z"
          fill="#009758"
        />
        <path
          d="M62.2473 23.1537C62.5361 22.8236 62.8456 22.5348 63.1963 22.2666C63.7739 21.854 64.4547 21.6477 65.1561 21.5858C66.0638 21.5033 66.9509 21.5239 67.7968 21.854C69.0964 22.3697 69.8598 23.3187 70.1073 24.7009C70.1692 25.0722 70.1898 25.4436 70.1898 25.8149C70.1898 28.0429 70.1898 30.2916 70.1898 32.5196C70.1898 32.7053 70.1486 32.7466 69.9629 32.7466C68.7664 32.7466 67.5492 32.7466 66.3527 32.7466C66.1464 32.7466 66.1257 32.6847 66.1257 32.499C66.1257 30.6629 66.1257 28.8269 66.1257 26.9908C66.1257 26.5989 66.0845 26.2275 66.002 25.8562C65.8576 25.2167 65.4656 24.8041 64.8261 24.6803C64.0009 24.4946 63.2582 24.6803 62.6805 25.3404C62.4124 25.6499 62.3092 26.0418 62.2679 26.4544C62.2061 26.9289 62.2267 27.3828 62.2267 27.8573C62.2267 29.4252 62.2267 30.993 62.2267 32.5609C62.2267 32.7259 62.1854 32.7672 62.0204 32.7672C60.8032 32.7672 59.5861 32.7672 58.3689 32.7672C58.2039 32.7672 58.1626 32.7259 58.1626 32.5609C58.1626 29.0538 58.1626 25.5261 58.1626 22.019C58.1626 21.8333 58.2039 21.7921 58.3895 21.8127C59.6067 21.8127 60.8032 21.8127 62.0204 21.8127C62.1854 21.8127 62.2473 21.854 62.2267 22.019C62.2473 22.3697 62.2473 22.7411 62.2473 23.1537Z"
          fill="#009758"
        />
        <path
          d="M55.5839 25.5881C55.5839 26.7434 55.6045 27.8987 55.5839 29.0333C55.5426 30.5187 54.8825 31.6533 53.5621 32.396C52.7576 32.8292 51.8911 33.0562 51.004 33.1593C50.3026 33.2418 49.6012 33.2625 48.8998 33.2212C47.6207 33.1593 46.3623 32.953 45.2483 32.2516C44.1962 31.5914 43.5979 30.6218 43.4535 29.3634C43.4329 29.0746 43.4122 28.7651 43.4122 28.4763C43.4122 26.3308 43.4122 24.1853 43.4122 22.0192C43.4122 21.8541 43.4535 21.7922 43.6392 21.7922C44.8563 21.7922 46.0529 21.7922 47.27 21.7922C47.4557 21.7922 47.497 21.8335 47.497 22.0192C47.497 24.0409 47.497 26.0626 47.497 28.0637C47.497 28.3938 47.5382 28.7033 47.662 29.0127C47.8889 29.5491 48.3428 29.8379 48.8792 29.9617C49.3743 30.0648 49.8694 30.0648 50.3645 29.8792C51.0247 29.6522 51.3754 29.1571 51.4785 28.4763C51.5198 28.1669 51.5198 27.8781 51.5198 27.5686C51.5198 25.7119 51.5198 23.8552 51.5198 22.0192C51.5198 21.8335 51.561 21.7922 51.7467 21.7922C52.9639 21.7922 54.1604 21.7922 55.3776 21.7922C55.5426 21.7922 55.5839 21.8335 55.5839 21.9985C55.5839 23.1951 55.5839 24.3916 55.5839 25.5881Z"
          fill="#009758"
        />
        <path
          d="M117.989 25.7944C118.691 25.7944 119.392 25.7944 120.093 25.7944C120.176 25.7944 120.279 25.815 120.238 25.6706C120.093 25.1136 119.846 24.6391 119.371 24.2884C118.897 23.9583 118.34 23.8964 117.783 23.9171C116.751 23.9583 115.967 24.6185 115.741 25.6294C115.699 25.7738 115.741 25.7944 115.885 25.7944C116.586 25.7944 117.288 25.7944 117.989 25.7944ZM119.825 28.0018H115.844C115.617 28.0018 115.596 28.0224 115.617 28.2494C115.637 28.4763 115.658 28.7032 115.72 28.9095C115.906 29.5903 116.318 30.0854 117.02 30.2917C117.618 30.4774 118.216 30.5186 118.835 30.333C119.268 30.2092 119.619 29.941 119.887 29.5697C119.97 29.4665 120.052 29.4046 120.176 29.4046C121.331 29.4046 122.507 29.4046 123.662 29.4046C123.848 29.4046 123.869 29.4665 123.807 29.6316C123.188 31.447 121.888 32.4991 120.073 32.953C119.475 33.0974 118.876 33.1593 118.257 33.1593C117.205 33.1799 116.153 33.0768 115.163 32.7054C113.224 31.9627 111.986 30.5805 111.615 28.5175C111.326 26.929 111.532 25.4231 112.419 24.0409C113.079 22.9887 114.028 22.246 115.204 21.8747C116.813 21.359 118.443 21.3177 120.052 21.7716C122.053 22.3492 123.25 23.7314 123.786 25.6912C123.972 26.3927 124.013 27.1147 123.992 27.8368C123.992 28.0018 123.889 27.9812 123.807 27.9812L119.825 28.0018Z"
          fill="#009758"
        />
        <path
          d="M34.892 28.8681C34.892 27.6509 34.892 26.4338 34.892 25.2166C34.892 25.0515 34.8507 25.0103 34.6857 25.0103C34.335 25.0309 33.9636 25.0103 33.6129 25.0103C33.4891 25.0103 33.4479 24.969 33.4479 24.8452C33.4479 23.8756 33.4479 22.906 33.4479 21.9571C33.4479 21.8127 33.4891 21.792 33.6129 21.792C33.9636 21.792 34.3143 21.792 34.665 21.792C34.8507 21.792 34.892 21.7301 34.8713 21.5651C34.8507 21.0906 34.8507 20.5955 34.8713 20.121C34.9126 19.1101 35.0776 18.1405 35.6346 17.2741C36.398 16.1394 37.4501 15.4174 38.791 15.1079C39.7194 14.9016 40.6477 14.9429 41.5554 15.2936C41.7205 15.3555 41.7824 15.438 41.7824 15.6237C41.7824 16.5727 41.7824 17.5423 41.7824 18.4912C41.7824 18.7594 41.7824 18.7594 41.5348 18.6563C41.1016 18.45 40.6683 18.3056 40.1939 18.3468C39.5337 18.3881 39.0798 18.8419 39.018 19.5021C38.9561 20.2035 38.9767 20.9049 38.9767 21.6064C38.9767 21.7508 39.0179 21.792 39.1624 21.792C39.9669 21.792 40.7921 21.792 41.5967 21.792C41.7411 21.792 41.803 21.8333 41.803 21.9983C41.803 22.9267 41.803 23.855 41.803 24.804C41.803 24.969 41.7617 25.0309 41.5761 25.0309C40.7921 25.0309 40.0082 25.0309 39.2242 25.0309C38.9354 25.0309 38.9561 24.9897 38.9561 25.2991C38.9561 27.6922 38.9561 30.1059 38.9561 32.4989C38.9561 32.7671 38.9561 32.7671 38.6879 32.7671C37.4913 32.7671 36.2742 32.7671 35.0776 32.7671C34.9126 32.7671 34.8713 32.7259 34.8713 32.5608C34.892 31.323 34.892 30.0852 34.892 28.8681Z"
          fill="#009758"
        />
        <path
          d="M126.241 4.89623C125.457 3.96788 123.642 3.74095 122.507 4.2567C122.259 4.35985 122.012 4.463 121.806 4.60741L115.018 9.53795C114.544 9.95055 114.09 10.4457 114.028 11.0646C113.966 11.6422 114.028 12.0961 114.523 12.6118C115.637 13.7465 117.638 13.7671 118.567 13.1894C119.433 12.6531 125.168 8.42394 126.117 7.72252L126.365 7.47496C126.654 7.10363 127.128 5.94835 126.241 4.89623Z"
          fill="#009758"
        />
        <path
          d="M97.6892 12.4467C98.1844 11.931 98.2462 11.4771 98.1844 10.8995C98.1225 10.2806 97.6686 9.80611 97.1941 9.37289L90.4069 4.44234C90.2006 4.2773 89.953 4.19478 89.7055 4.09163C88.5708 3.55525 86.7554 3.78218 85.9714 4.73116C85.0844 5.80391 85.5589 6.93856 85.8477 7.30989L86.0952 7.55745C87.0236 8.25887 92.7587 12.488 93.6251 13.0244C94.5947 13.5814 96.5752 13.5814 97.6892 12.4467Z"
          fill="#009758"
        />
        <path
          d="M106.168 11.1471C107.612 11.1471 108.83 10.4869 109.18 9.26978C109.222 9.10474 109.242 8.91908 109.242 8.75404L109.283 4.33924C109.283 4.29798 109.283 4.25672 109.283 4.23609C109.283 2.54443 107.922 1.7605 106.189 1.7605C104.477 1.7605 103.094 2.66821 103.074 4.35987V8.81593C103.074 8.98096 103.094 9.16663 103.115 9.33167C103.425 10.5695 104.662 11.1471 106.168 11.1471Z"
          fill="#009758"
        />
        <path
          d="M8.50631 29.776C9.88851 29.8172 11.1057 28.6826 11.1057 27.2385C11.1057 25.8356 10.0123 24.6597 8.52694 24.6597C6.93843 24.6597 5.9482 25.9801 5.9482 27.2179C5.92757 28.5382 7.06221 29.7966 8.50631 29.776ZM10.8994 31.5089C10.6931 31.6739 10.5487 31.8183 10.363 31.9421C9.64095 32.5197 8.81576 32.8292 7.88741 32.9117C6.5671 33.0355 5.30867 32.8086 4.19466 32.0865C2.83308 31.2407 2.06978 29.9823 1.82222 28.3938C1.61592 27.0734 1.76033 25.7738 2.37923 24.5772C3.28694 22.8649 4.68978 21.8334 6.62899 21.5446C7.63985 21.4002 8.63009 21.4827 9.57906 21.916C9.99166 22.1016 10.363 22.3905 10.6724 22.7205C10.7343 22.7824 10.7962 22.8649 10.8375 22.9268L10.8994 22.8856C10.8994 22.5968 10.8994 22.2873 10.8994 21.9985C10.8994 21.8541 10.92 21.7922 11.085 21.7922C12.3022 21.7922 13.5194 21.7922 14.7365 21.7922C14.8809 21.7922 14.9222 21.8334 14.9222 21.9779C14.9222 25.5675 14.9222 29.1571 14.9016 32.7467C14.9016 33.5719 14.7984 34.4177 14.4065 35.1604C13.8288 36.295 12.9211 37.0789 11.7865 37.5947C10.8994 38.0073 9.9504 38.193 8.98079 38.2342C7.78426 38.3167 6.58773 38.2136 5.45308 37.8216C4.00899 37.3059 2.87434 36.4188 2.13167 35.0572C2.00789 34.8097 1.92537 34.5208 1.86348 34.2527C1.84285 34.1082 1.86348 34.067 2.02852 34.067C3.03938 34.067 4.05025 34.067 5.06111 34.067C5.45308 34.067 5.82442 34.067 6.21639 34.067C6.29891 34.067 6.3608 34.067 6.40206 34.1701C6.64962 34.8303 7.16536 35.1191 7.80489 35.2016C8.44442 35.2841 9.06331 35.2223 9.66158 34.9128C10.4043 34.5002 10.7756 33.8607 10.8375 33.0355C10.92 32.5197 10.92 32.0452 10.8994 31.5089Z"
          fill="#009758"
        />
      </svg>
    );
  }
);

export const GoFundMeIcon = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 41 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M37.0177 20.7103C37.1636 20.382 37.2731 20.0901 37.419 19.8164C37.4737 19.6887 37.6744 19.634 37.8021 19.6887C37.8751 19.7252 37.8568 19.7799 37.8568 19.8164C37.8568 20.382 37.8568 20.9475 37.8568 21.4948C37.8568 21.6042 37.8203 21.6407 37.7109 21.6407C37.6014 21.6407 37.5649 21.6042 37.5649 21.4948C37.5649 21.0934 37.5649 20.6921 37.5649 20.309C37.5649 20.2543 37.5832 20.1813 37.5467 20.1083C37.419 20.4184 37.2548 20.6738 37.1636 20.984C37.1454 21.0569 37.1271 21.1117 37.0359 21.1117C36.9447 21.1117 36.9082 21.0752 36.8717 21.0022C36.7805 20.765 36.6711 20.5279 36.5616 20.309C36.5434 20.2725 36.5251 20.1995 36.4704 20.1813C36.4339 20.2543 36.4521 20.3272 36.4521 20.4002C36.4521 20.765 36.4521 21.1299 36.4521 21.513C36.4521 21.6225 36.4339 21.6772 36.3062 21.6772C36.1785 21.6772 36.1785 21.6042 36.1785 21.513C36.1785 20.984 36.1785 20.4367 36.1785 19.9076C36.1785 19.8347 36.1238 19.7252 36.2697 19.7252C36.3792 19.7252 36.5251 19.6522 36.5981 19.8164C36.7258 20.0901 36.8535 20.382 36.9812 20.6556C36.9812 20.6374 36.9812 20.6556 37.0177 20.7103Z"
          fill="#009758"
        />
        <path
          d="M34.9927 20.7651C34.9927 20.5462 34.9745 20.3273 34.9927 20.1266C35.011 19.9442 34.938 19.8894 34.7738 19.9259C34.6826 19.9442 34.5914 19.9259 34.5002 19.9259C34.409 19.9259 34.3542 19.9077 34.3542 19.7982C34.3542 19.6705 34.409 19.6705 34.5002 19.6705C34.9198 19.6705 35.3576 19.6705 35.7772 19.6705C35.8866 19.6705 35.9231 19.707 35.9231 19.8165C35.9231 19.9259 35.8684 19.9442 35.7772 19.9442C35.6677 19.9442 35.5583 19.9442 35.4488 19.9442C35.3211 19.9259 35.2664 19.9807 35.2664 20.1266C35.2664 20.5827 35.2664 21.0205 35.2664 21.4766C35.2664 21.586 35.2664 21.659 35.1204 21.659C34.9745 21.659 34.9927 21.5678 34.9927 21.4766C34.9927 21.2212 34.9927 21.0022 34.9927 20.7651Z"
          fill="#009758"
        />
        <path
          d="M10.0187 25.0338C10.3106 24.7784 10.5842 24.5413 10.8944 24.3406C11.77 23.7933 12.7369 23.6292 13.7584 23.7204C14.2328 23.7569 14.7071 23.8663 15.1449 24.067C15.7834 24.3406 16.2394 24.7967 16.6225 25.3622C16.6955 25.4534 16.7138 25.4717 16.7867 25.3622C17.4435 24.3771 18.3738 23.8481 19.5231 23.7021C20.6724 23.5562 21.7305 23.7933 22.6061 24.5778C23.0439 24.9791 23.2993 25.4899 23.4088 26.0919C23.4818 26.475 23.5 26.8763 23.5 27.2777C23.5 29.2844 23.5 31.3093 23.5 33.316C23.5 33.4802 23.4453 33.5349 23.2811 33.5349C22.223 33.5349 21.165 33.5349 20.1069 33.5349C19.9609 33.5349 19.9062 33.4984 19.9062 33.3342C19.9062 31.7471 19.9062 30.16 19.9062 28.5729C19.9062 28.1898 19.8697 27.8067 19.7785 27.4419C19.4684 26.1466 17.9907 26.1284 17.334 26.7486C16.9327 27.1317 16.805 27.606 16.732 28.1351C16.6773 28.5729 16.6955 29.029 16.6955 29.4668C16.6955 30.762 16.6955 32.0572 16.6955 33.3525C16.6955 33.4802 16.659 33.5349 16.5313 33.5349C15.455 33.5349 14.3787 33.5349 13.3024 33.5349C13.1564 33.5349 13.12 33.4802 13.12 33.3525C13.12 31.7471 13.12 30.1418 13.12 28.5364C13.12 28.2081 13.1017 27.8797 13.047 27.5513C12.9375 26.9493 12.5727 26.5845 11.9889 26.4203C11.2957 26.2561 10.6025 26.5115 10.2376 27.077C10.0187 27.4236 9.96398 27.8067 9.96398 28.2081C9.94574 29.9046 9.94574 31.6012 9.96398 33.2977C9.96398 33.4802 9.90925 33.5167 9.74507 33.5167C8.687 33.5167 7.62893 33.5167 6.57085 33.5167C6.42491 33.5167 6.38843 33.4802 6.38843 33.3342C6.38843 30.233 6.38843 27.1135 6.38843 24.0123C6.38843 23.8663 6.44316 23.8298 6.57085 23.8298C7.64717 23.8298 8.70524 23.8298 9.78155 23.8298C9.92749 23.8298 9.96398 23.8846 9.96398 24.0123C10.0187 24.3406 10.0187 24.669 10.0187 25.0338Z"
          fill="#009758"
        />
        <path
          d="M31.3443 27.3688C31.9646 27.3688 32.5848 27.3688 33.2051 27.3688C33.278 27.3688 33.3692 27.3871 33.3328 27.2594C33.2051 26.7668 32.9861 26.3472 32.5666 26.0371C32.147 25.7452 31.6544 25.6905 31.1619 25.7087C30.2497 25.7452 29.5565 26.329 29.3559 27.2229C29.3194 27.3506 29.3559 27.3688 29.4836 27.3688C30.1038 27.3688 30.7241 27.3688 31.3443 27.3688ZM32.9679 29.3208H29.4471C29.2464 29.3208 29.2282 29.339 29.2464 29.5397C29.2646 29.7404 29.2829 29.941 29.3376 30.1235C29.5018 30.7255 29.8666 31.1633 30.4869 31.3457C31.0159 31.5099 31.545 31.5464 32.0922 31.3822C32.4753 31.2728 32.7855 31.0356 33.0226 30.7072C33.0956 30.616 33.1686 30.5613 33.278 30.5613C34.2996 30.5613 35.3394 30.5613 36.361 30.5613C36.5252 30.5613 36.5435 30.616 36.4887 30.762C35.9414 32.3673 34.7922 33.2977 33.1868 33.699C32.6578 33.8267 32.1287 33.8814 31.5815 33.8814C30.6511 33.8997 29.7207 33.8085 28.8451 33.4801C27.1303 32.8234 26.0357 31.6011 25.7073 29.7769C25.4519 28.3722 25.6344 27.0405 26.4188 25.8182C27.0026 24.8878 27.8417 24.2311 28.8815 23.9027C30.3045 23.4467 31.7456 23.4102 33.1686 23.8115C34.9381 24.3223 35.9962 25.5446 36.4705 27.2776C36.6347 27.8979 36.6711 28.5364 36.6529 29.1748C36.6529 29.3208 36.5617 29.3025 36.4887 29.3025L32.9679 29.3208Z"
          fill="#009758"
        />
        <path
          d="M38.6414 8.88908C37.9481 8.06816 36.3428 7.86749 35.3395 8.32356C35.1205 8.41477 34.9016 8.50598 34.7192 8.63368L28.7174 12.9937C28.2978 13.3585 27.8965 13.7963 27.8417 14.3436C27.787 14.8544 27.8417 15.2558 28.2796 15.7118C29.2647 16.7152 31.0342 16.7334 31.8551 16.2226C32.6213 15.7483 37.6928 12.0086 38.5319 11.3883L38.7508 11.1694C39.0062 10.841 39.4258 9.81945 38.6414 8.88908Z"
          fill="#009758"
        />
        <path
          d="M13.3935 15.5659C13.8313 15.1098 13.8861 14.7085 13.8313 14.1977C13.7766 13.6504 13.3753 13.2308 12.9557 12.8477L6.95386 8.48773C6.77143 8.34179 6.55252 8.26882 6.33361 8.1776C5.33027 7.7033 3.72491 7.90396 3.03169 8.74313C2.24726 9.69174 2.66685 10.6951 2.92224 11.0235L3.14115 11.2424C3.96207 11.8626 9.03352 15.6024 9.79971 16.0767C10.6571 16.5692 12.4084 16.5692 13.3935 15.5659Z"
          fill="#009758"
        />
        <path
          d="M20.8912 14.4166C22.1682 14.4166 23.2445 13.8328 23.5546 12.7565C23.5911 12.6106 23.6093 12.4464 23.6093 12.3005L23.6458 8.39654C23.6458 8.36005 23.6458 8.32357 23.6458 8.30533C23.6458 6.80943 22.4418 6.11621 20.9094 6.11621C19.3953 6.11621 18.173 6.91889 18.1548 8.41478V12.3552C18.1548 12.5011 18.173 12.6653 18.1913 12.8113C18.4649 13.9058 19.5595 14.4166 20.8912 14.4166Z"
          fill="#009758"
        />
        <path
          d="M28.7377 17.375C30.325 18.1686 31.5247 19.1837 32.3737 20.3096H8.62002L8.63848 20.2911H8.60156C10.6133 17.578 14.563 15.7139 20.4692 15.7139C23.9944 15.7139 26.7075 16.3598 28.7377 17.375Z"
          fill="#009758"
        />
      </svg>
    );
  }
);
