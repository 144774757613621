import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import React from 'react';

export interface IKeepProportionsProps {
  proportion?: ProportionSetting;
  className?: string;
  src?: string;
  onImageClick?: () => void;
  containType?: string;
}

// FIXME: this variable is too common to be exported (perhaps prefix with "Proportion" ?)
export const Wide = 0.5625;
export const Portrait = 1.4375;
export const Square = 1;

export class Proportion {
  private readonly width: number;
  private readonly height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  public getProportion(): number {
    return this.height / this.width;
  }
}

export type ProportionSetting = Proportion | typeof Wide | typeof Square | number;

const blockName = 'keep-proportions';

export function KeepProportions({
  proportion = Wide,
  containType,
  children,
  src,
  onImageClick,
  className,
}: React.PropsWithChildren<IKeepProportionsProps>) {
  const bem = bemBlock(blockName);
  const mainRef = React.useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = React.useState<number>(null);
  const [parentWidth, setParentWidth] = React.useState<number>(null);

  const _proportion = (proportion as Proportion).getProportion
    ? (proportion as Proportion).getProportion()
    : (proportion as number);

  React.useEffect(() => {
    let parentEl: HTMLElement;

    function handleResize() {
      updateParentSizes();
      // update the parent sizes again with a small timeout to correct the sizing after initial render
      setTimeout(updateParentSizes, timeoutDefaultValue);
    }

    function updateParentSizes() {
      // temporarily set parentEl to width: 100%
      // const cachedWidth = parentEl.style.width;
      // parentEl.style.width = '100%';

      if (!parentEl) return;

      let widthContainer = parentEl;

      if (containType === 'modal') {
        widthContainer = parentEl.parentElement.parentElement;
      }

      setParentWidth(widthContainer.offsetWidth);
      // restore previous value
      // parentEl.style.width = cachedWidth;

      setParentHeight(parentEl.offsetHeight);
    }

    if (containType && mainRef && mainRef.current) {
      // contain to parent
      parentEl = mainRef.current.parentElement;

      if (parentEl) updateParentSizes();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return noop;
  }, [containType, mainRef]);

  let height: number = null;
  let width: number = null;
  if (parentHeight) {
    height = parentHeight;

    // calculate the width based on the height
    width = height * (1 + _proportion);

    // if width is wider than the parent then just use the parent's width
    if (width > parentWidth) width = parentWidth;
  }

  const mainStyle = {
    width: width !== null ? width + 'px' : null,
  };

  const wrapperStyle = {
    paddingBottom: `${_proportion * 100}%`,
    backgroundImage: src ? `url(${src})` : undefined,
  };

  const handleClick = React.useCallback(() => {
    if (!src) return;
    onImageClick();
  }, [src, onImageClick]);

  return (
    <div className={bem(null, null, className)} ref={mainRef} style={mainStyle}>
      <div className={bem('wrapper')} style={wrapperStyle} onClick={handleClick}>
        {children}
      </div>
    </div>
  );
}
