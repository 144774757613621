import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { DeterminationType, LoadingLinear } from '@gik/ui/LoadingLinear';
import React from 'react';

export const LoadingPopup = withComponentErrorBoundary(LoadingPopupComp);

export type ILoadingPopupProps = UIComponent & {
  progress: number;
  text: string;
  type: DeterminationType;
};

function LoadingPopupComp({ progress, text, type, className, ...otherProps }: ILoadingPopupProps) {
  const bem = useBemCN('loading-popup');

  return (
    <div {...bem(null, [{ type }], className)}>
      <p {...bem('message')}>{text}</p>
      <Switch value={type}>
        <Case value={DeterminationType.Determinate}>
          <LoadingLinear type={DeterminationType.Determinate} percentComplete={progress} />
        </Case>
        <Case value={DeterminationType.Indeterminate}>
          <LoadingLinear type={DeterminationType.Indeterminate} />
        </Case>
      </Switch>
    </div>
  );
}
