import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { ParagraphBone } from './ParagraphBone';

const skelBem = bemBlock(`h1-bone`);
interface H1BoneProps extends BaseBoneProps {
  center: boolean;
}

export function H1Bone({ className, center }: H1BoneProps) {
  return (
    <ParagraphBone words={4} minWidth={60} maxWidth={140} className={skelBem(undefined, [{ center }], className)} />
  );
}
