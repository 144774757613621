import { useUser } from '@gik/api/users/user';
import type { InkindOrganizerSettings, Organizer } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import { getTailwindConfig } from '@gik/core/utils/tailwind';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Avatar } from '@gik/ui/Avatar';
import { Button } from '@gik/ui/Button';
import { Checkbox } from '@gik/ui/Checkbox';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import { UI } from '@gik/ui/UIManager';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { inviteUserAsPageOrganizer } from '../api/organizers';

export const baseOrganizerItemBlockName = 'organizers-list-item';

export interface IBaseOrganizerItemProps {
  isOwner: boolean;
  isInvitee: boolean;
  className?: string;
  organizer: Organizer;
  onUpdateSettings: (isOwner: boolean, userId: string, settings: InkindOrganizerSettings) => Promise<void>;
  onRemoveOrganizer: (userId: string) => void;
  onCancelInvitation: (email: string) => void;
  routeId: string;
}

function BaseOrganizerItemComp({
  isOwner,
  isInvitee,
  className,
  organizer,
  onUpdateSettings,
  onRemoveOrganizer,
  onCancelInvitation,
  routeId,
}: React.PropsWithChildren<IBaseOrganizerItemProps>): JSX.Element {
  const [canEdit, setCanEdit] = React.useState<boolean>(false);
  const [notifications, setNotifications] = React.useState<boolean>(false);

  const { t } = useTranslation();
  const bem = bemBlock(baseOrganizerItemBlockName);

  const { data: user } = useUser(organizer.userId);

  const email = user?.emailAddress || organizer.email;

  React.useEffect(() => {
    setCanEdit(organizer.canEdit);
    setNotifications(organizer.notifications);
  }, [organizer]);

  const onClickCanEdit = async (canEdit: boolean) => {
    if (!isOwner) {
      await onUpdateSettings(isOwner, organizer.userId, {
        canEdit,
        notifications,
      });
    }
  };

  const onClickNotifications = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    console.log('ev.target.checked', ev.target.checked);
    await onUpdateSettings(isOwner, organizer.userId, {
      canEdit,
      notifications: ev.target.checked,
    });
  };

  const onClickRemove = async (e: React.MouseEvent) => {
    if (!isOwner) {
      e.preventDefault();
      // TODO: update messages
      const message = isInvitee
        ? `Cancel organizer invitation for ${email}?`
        : `Remove ${user?.fullName} as a page organizer?`;
      const title = isInvitee ? 'Remove invetee' : 'Remove organizer';
      const response = await UI.confirm(message, {
        title,
        padding: true,
      });

      if (response) {
        if (isInvitee) {
          onCancelInvitation(email);
        } else {
          onRemoveOrganizer(organizer.userId);
        }
      }
    }
  };

  // no need for optimistic update, background call with no visible effect
  const onResendClick = async (e: React.MouseEvent, organizer: Organizer) => {
    e.preventDefault();

    await inviteUserAsPageOrganizer(routeId, {
      emailAddress: email,
      canEdit: organizer.canEdit,
      notifications: organizer.notifications,
    });

    UI.success('Email Resent Successfully.');
  };

  const twConfig = getTailwindConfig();

  return (
    <section className={bem(null, null, className)}>
      <div className={bem('info')}>
        <Avatar className={bem('avatar')} imageUrl={user?.avatarThumbSmallURL} showBorder />
        <div className={bem('info-name')}>
          <div className={bem('name')}>{isInvitee ? email : user?.fullName}</div>
          {isOwner && <div className={bem('indicator')}>{t(translationKeys.pageOwner)}</div>}
          {isInvitee && (
            <div className={bem('invited')}>
              <span className={bem('invited-label')}>{t(translationKeys.invited)}</span>
              <Button variant="primary-link" className={bem('resend-link')} onClick={e => onResendClick(e, organizer)}>
                {t(translationKeys.resend)}
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isInvitee && (
        <>
          <div className={bem('action-cb-1')}>
            <Checkbox disabled={isOwner} checked={isOwner || canEdit} onValueChange={onClickCanEdit} />
          </div>
          <div className={bem('action-cb-2')}>
            <Checkbox checked={notifications} onChange={onClickNotifications} />
          </div>
        </>
      )}
      <div className={bem('action-trash')}>
        <Tooltip
          allowOnMobile={true}
          disabled={!isOwner}
          zIndex={twConfig.theme.zIndex.popovers}
          text={t(translationKeys.tooltip)}
        >
          <span>
            <SvgIcon className={bem('icon', [{ disabled: isOwner }])} Icon={TrashIcon} onClick={onClickRemove} />
          </span>
        </Tooltip>
      </div>
    </section>
  );
}

export const BaseOrganizerItem = withComponentErrorBoundary(BaseOrganizerItemComp);

export interface IOrganizerListItemProps {
  className?: string;
  organizer: Organizer;
  onUpdateSettings: (isOwner: boolean, userId: string, settings: InkindOrganizerSettings) => Promise<void>;
  onRemoveOrganizer: (userId: string) => void;
  onCancelInvitation: (email: string) => void;
  routeId: string;
}

export function OwnerListItem(props: React.PropsWithChildren<IOrganizerListItemProps>): JSX.Element {
  return <BaseOrganizerItem isOwner={true} isInvitee={false} {...props} />;
}

export function OrganizerListItem(props: React.PropsWithChildren<IOrganizerListItemProps>): JSX.Element {
  return <BaseOrganizerItem isOwner={false} isInvitee={false} {...props} />;
}

export function InviteeListItem(props: React.PropsWithChildren<IOrganizerListItemProps>): JSX.Element {
  return <BaseOrganizerItem isOwner={false} isInvitee={true} {...props} />;
}
