import type { FormSchemaEntry } from '@gik/ui/Form';

export const CityField = (): FormSchemaEntry => ({
  label: 'City',
  name: 'city',
  type: 'text',
  required: true,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'address-level2' },
});
