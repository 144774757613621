import type { Hit } from '@algolia/client-search';
import withSuspense from '@gik/core/components/suspended/withSuspense';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import routes from '@gik/core/routes';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import type { InkindAlgoliaObject } from '@gik/search/components';
import { FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import { useUserPages } from '@gik/user-profile/hooks/useUserPages';
import React from 'react';
import { PageRowItem } from './PageRowItem';

export type PageRowsProps = {
  skeleton?: boolean;
  skeletonItems?: number;
  onClick?(page: InkindPageAPIModel | Hit<InkindAlgoliaObject>): void;
} & UIComponent;

const skelBem = bemBlock(`page-rows-skeleton`);
const Skeleton = ({ skeletonItems = 4 }: Partial<PageRowsProps>): React.ReactElement => (
  <div className={`${skelBem()}`}>
    {Array.from(Array(skeletonItems).keys()).map(index => {
      return (
        <div key={index} className={skelBem('item')}>
          <FrameBone className={skelBem('image')} />
          <main>
            <ParagraphBone words={3} className={skelBem('text')} />
          </main>
        </div>
      );
    })}
  </div>
);

function PageRowsComp({ className, onClick }: React.PropsWithChildren<PageRowsProps>): React.ReactElement {
  const bem = bemBlock('page-rows');

  const { data: userPages } = useUserPages();

  const isLoading = !userPages;

  if (isLoading) return <Skeleton />;

  return (
    <ul className={bem(null, null, className)}>
      {userPages?.length > 0 &&
        userPages.map(page => {
          return (
            <PageRowItem
              key={page.objectID}
              page={page}
              className={bem(null, null, className)}
              onClick={onClick}
              href={`${routes.inkinds}/${page.objectID}/${page.slug}`}
            />
          );
        })}
    </ul>
  );
}

export const PageRows = withSuspense(PageRowsComp, Skeleton);
