import { Analytics } from '@gik/analytics';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import type { IGetGoFundMeCampaignPageStatusResponse } from '@gik/inkind-page/api/GoFundMeAPI';
import { useGoFundMeCampaign } from '@gik/inkind-page/api/GoFundMeAPI';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { NotInteractive } from '@gik/ui/NotInteractive/NotInteractive';
import React from 'react';
import useDebounce from 'react-use/lib/useDebounce';

export type IGoFundMeSupportWidgetProps = UIComponent & Pick<IGetGoFundMeCampaignPageStatusResponse, 'slug'>;

export function GoFundMeSupportWidget({ slug, className, ...otherProps }: IGoFundMeSupportWidgetProps) {
  const bem = useBemCN('go-fund-me-support-widget');

  return (
    <div {...otherProps} {...bem(null, null, className)}>
      <embed
        {...bem('widget')}
        src={`https://www.gofundme.com/mvc.php?route=widgets/mediawidget&fund=${slug}&image=0&coinfo=0&preview=0`}
        type="text/html"
        onClick={() => Analytics.fireDonateEvent('GoFundMe')}
      />
    </div>
  );
}

export function GoFundMeSupportWidgetPreview(props: IGoFundMeSupportWidgetProps) {
  const { slug, className, ...otherProps } = props;
  const bem = useBemCN('go-fund-me-support-widget');
  const [debouncedValue, setDebouncedValue] = React.useState<IGoFundMeSupportWidgetProps['slug']>(slug);
  useDebounce(() => setDebouncedValue(slug), 200, [slug]);
  const { data } = useGoFundMeCampaign(debouncedValue);

  if (slug && !data)
    return (
      <div {...bem('loading')}>
        <LoadingSpinner center />
      </div>
    );

  if (data?.valid) return <GoFundMeSupportWidget {...props} slug={data.slug} />;

  return (
    <div {...otherProps} {...bem(null, null, className)}>
      <NotInteractive {...bem('preview')}>
        <embed
          {...bem('preview')}
          src={`${getAssetsUrl()}inkind-page/gofundmesupportpreviewcontent.html`}
          type="text/html"
        />
      </NotInteractive>
    </div>
  );
}
