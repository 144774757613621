import { openPremiumPageUpgradeModal } from '@gik/checkout/utils/openPremiumPageUpgradeModal';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Checkbox } from '@gik/ui/Checkbox';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import type { UISize } from '@gik/ui/types';
import LockClosedIcon from '@heroicons/react/solid/LockClosedIcon';
import React from 'react';

export interface IGenericCardProps extends Pick<IPrivacySettingsCardProps, 'value' | 'onChange' | 'inputName'> {}

export interface IPrivacySettingsCardProps {
  icon?: SvgrComponent;
  title: string;
  description: React.ReactNode;
  value: boolean;
  inputName?: string;
  onChange?: (isToggled: boolean) => void;
  onClickDisabled?: () => void;
  note?: JSX.Element;
  checkboxSize?: UISize;
  locked?: boolean;
  disabled?: boolean;
  id?: string;
  prepend?: React.ReactNode;
}

export const privacySettingsCardBlockName = 'privacy-settings-card';

const PrivacySettingsCardComp: React.FC<PropsWithClassName<IPrivacySettingsCardProps>> = ({
  icon,
  title,
  description,
  value,
  disabled,
  note,
  checkboxSize = 'lg',
  className,
  id,
  inputName,
  locked,
  prepend,
  onChange,
  onClickDisabled,
}): JSX.Element => {
  const bem = bemBlock(privacySettingsCardBlockName);

  const handleToggle = async (toggled: boolean) => {
    if (locked) {
      openPremiumPageUpgradeModal({
        initiatedOn: 'inkindPageSettings',
        variant: 'hideSuggestions',
        onPurchase: () => onChange?.(toggled),
      });
      return;
    }
    onChange?.(toggled);
  };

  return (
    <section className={bem(null, null, className)} id={id}>
      <div className={bem('card')}>
        {prepend && <span className={bem('prepend')}>{prepend}</span>}
        {icon && <SvgIcon className={bem('icon')} Icon={icon} />}

        <div className={bem('text')}>
          <label htmlFor={inputName} className={bem('title')}>
            {title}
          </label>
          <p className={bem('description')}>{description}</p>
        </div>

        {locked && <SvgIcon className={bem('padlock')} Icon={LockClosedIcon} />}
        <Checkbox
          className={bem('checkbox')}
          size={checkboxSize}
          disabled={disabled}
          onClickDisabled={onClickDisabled}
          id={inputName}
          name={inputName}
          checked={value}
          onValueChange={toggled => handleToggle(toggled)}
        />
      </div>
      {note && <p className={bem('note')}>{note}</p>}
    </section>
  );
};

export const PrivacySettingsCard = withComponentErrorBoundary(PrivacySettingsCardComp);
