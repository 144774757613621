import { logger } from '@gik/analytics/utils/logger';
import type { Environment, EnvKey } from '@gik/core/store/EnvStore';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import type { FormRef } from '@gik/ui/Form';
import { Form } from '@gik/ui/Form';
import React from 'react';

const blockName = 'env-tool';

export function EnvTools() {
  const keysFilter = ['set', 'export', 'import'];
  const bem = useBemCN(blockName);
  const env = useEnvStore();
  const set = useEnvStore(state => state.set);
  const keys = Object.keys(env).filter(key => !keysFilter.includes(key));
  const formRef = React.useRef<FormRef>();

  React.useEffect(() => {
    const unsubscribe = useEnvStore.subscribe(env => {
      formRef.current?.resetForm({ values: env });
    });

    return () => void unsubscribe();
  }, []);

  function onClickSave() {
    const state = useEnvStore.getState().export();

    const envFormat = Object.keys(state).reduce((previousValue, key) => `${previousValue}${key}=${state[key]}\n`, '');
    const file = new Blob([envFormat], { type: 'application/x-envoy;charset=utf-8' });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(file);
    a.download = 'exported.env';
    a.click();
    URL.revokeObjectURL(a.href);
  }

  async function onClickLoad() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.env';

    input.addEventListener('change', function () {
      const fr = new FileReader();
      fr.onload = function () {
        const result = fr.result;

        try {
          const keyValue: string[] = (result as string).split('\n');
          const env: Environment = keyValue.reduce((previousValue, line) => {
            if (line.startsWith('#') || !line.length) return previousValue;

            return {
              ...previousValue,
              [line.split('=')[0]]: line.split('=')[1],
            };
          }, {}) as Environment;

          useEnvStore.getState().import(env);
        } catch (e) {
          logger.error(e);
        }
      };

      fr.readAsText(this.files[0]);
    });

    input.click();
  }

  return (
    <div {...bem(null, null, ['tw-flex', 'tw-flex-col', 'tw-gap-4', 'tw-p-4'])}>
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-start tw-content-start tw-gap-4">
        <Button className="tw-flex-1" onClick={onClickSave}>
          Export
        </Button>
        <Button className="tw-flex-1" onClick={onClickLoad}>
          Import
        </Button>
      </div>
      <Form
        ref={formRef}
        schema={keys.map(key => ({
          label: key,
          name: key,
          type: 'text',
          savable: true,
        }))}
        initialValues={env}
        onSubmitField={async (field, value) => {
          set(field.name as EnvKey, value);
        }}
      />
    </div>
  );
}
