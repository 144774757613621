import { logger } from '@gik/analytics/utils/logger';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import type { BillingDetails, CartItem, PerfectgiftCarrierTypes, ShippingDetails } from '@gik/checkout/types';
import { usePerfectGiftCarrierPrices } from '@gik/checkout/utils/usePerfectGiftCarrierPrices';
import type { PGGreetingCardDesign } from '@gik/core/models/wordpress/PGGreetingCardDesign';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import type { FormProps, FormRef } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import type { StripeCardElement } from '@stripe/stripe-js';
import type { FormApi, SubmissionErrors } from 'final-form';
import type { FormikProps } from 'formik';
import React from 'react';
import { cardCarrierFormId } from '../CheckoutForm/CheckoutForm';
import { cardCarrierFormSchema } from './cardCarrierFormSchema';

export interface CardCarrierFormValues extends BillingDetails {
  toName?: string;
  fromName?: string;
  message?: string;
  carrierType: PerfectgiftCarrierTypes;
  selectedCarrierDesign?: PGGreetingCardDesign;
}

export interface CardCarrierFormProps extends FormProps {
  initialValues?: Partial<CardCarrierFormValues>;
  initialOrders?: CartItem[];
  shippingDetails?: ShippingDetails;
  buttons?: (formRenderProps: FormikProps<object>) => React.ReactNode;
  buttonsPortal?: HTMLElement;

  /**
   * This is used when a stripe token is already available and an
   * obfuscated card number should be displayed
   */
  creditCardLast4?: string;

  setCardElement?(cardElement: StripeCardElement): void;

  onFieldFocus?(): void;

  onBeforeSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;

  onAfterSubmit?: (
    values: object,
    form: FormApi<object, object>
  ) => void | SubmissionErrors | Promise<SubmissionErrors>;

  /** Called when form validation succeeds but either stripe token generation or address validation fails */
  onBillingSubmitFail?();
}

const CardCarrierFormComp = React.forwardRef<FormRef, CardCarrierFormProps>(function CardCarrierFormComp(
  {
    initialValues,
    initialOrders,
    shippingDetails,
    creditCardLast4,
    submitButton,
    className,
    id,
    buttons,
    buttonsPortal,
    setCardElement,
    onSubmit,
    onBeforeSubmit,
    onAfterSubmit,
    onBillingSubmitFail,
    onChange,
    onFieldFocus,
    ...otherProps
  },
  ref
): React.ReactElement {
  const bem = useBemCN('card-carrier-form');
  const { greetingCardCarrierPrice, trifoldWithEnvelopeCarrierPrice } = usePerfectGiftCarrierPrices();
  const userId = useUserStore(state => state.id);

  const formRef = React.useRef<FormRef>();
  React.useImperativeHandle(ref, () => formRef.current);

  const handleSubmit = React.useCallback(
    async (values: CardCarrierFormValues, form: FormApi<object, object>): Promise<void> => {
      try {
        onBeforeSubmit?.(values, form);

        const response = onSubmit?.(values, form);

        onAfterSubmit?.(values, form);

        return response;
      } catch (err) {
        logger.error('Failed to submit form:' + err.message);
      }
    },
    [onAfterSubmit, onBeforeSubmit, onSubmit]
  );

  const schema = React.useMemo(() => {
    return cardCarrierFormSchema(greetingCardCarrierPrice, trifoldWithEnvelopeCarrierPrice);
  }, [greetingCardCarrierPrice, trifoldWithEnvelopeCarrierPrice]);

  const handleOnChange = React.useCallback(
    values => {
      onChange?.(values);
    },
    [onChange]
  );

  return (
    <Form
      {...otherProps}
      ref={ref => {
        formRef.current = ref;
        useCheckoutFormModalStore.getState().cardCarrierFormRef.current = ref;
      }}
      onSubmit={handleSubmit}
      schema={schema}
      onChange={handleOnChange}
      initialValues={initialValues}
      vertical
      id={id || cardCarrierFormId}
      {...bem(null, null, className)}
      restoreAfterUpdate={false}
      persist={false}
      render={renderProps => {
        const { isSubmitting, isValid } = renderProps;
        const { carrierType } = renderProps.values as CardCarrierFormValues;

        return (
          <>
            <div {...bem('preview')}>
              <div {...bem('custom-fields')}>
                {/* <FormField name="carrierType" /> */}
                <FormField name="toName" onFocus={onFieldFocus} />
                <FormField name="message" onFocus={onFieldFocus} />
                <FormField name="fromName" onFocus={onFieldFocus} />

                {/* {carrierType === PerfectgiftCarrierTypes.GREETING_CARD && (
                  <span {...bem('preview-title')}>Greeting Card Preview</span>
                )}
                {carrierType === PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE && (
                  <span {...bem('preview-title')}>Standard Trifold Preview</span>
                )} */}
              </div>
            </div>

            {/* {carrierType === PerfectgiftCarrierTypes.GREETING_CARD && <FormField name="selectedCarrierDesign" />} */}

            {submitButton && (
              <div className="tw-text-center tw-pt-4">
                <Button variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} wide>
                  CONTINUE
                </Button>
              </div>
            )}
            {renderPortal?.(buttons?.(renderProps), () => buttonsPortal)}
          </>
        );
      }}
    />
  );
});

export const CardCarrierForm = withComponentErrorBoundary(CardCarrierFormComp);
