import bemBlock from '@gik/core/utils/bemBlock';
import { extractVimeoVideoIdFromURL, extractYouTubeVideoIdFromURL, isYouTubeUrl } from '@gik/core/utils/extractVideoId';
import { UI } from '@gik/ui/UIManager';
import type { Duration } from 'moment';
import React from 'react';
import { LightboxMedia } from '../UIManager/UILightboxTypes';

interface BaseEmbeddedVideoProps {
  className?: string;
  url: string;
  autoPlay?: boolean;
  startAt?: Duration;
}

interface NoOverlayEmbeddedVideoProps extends BaseEmbeddedVideoProps {}

interface WithLightboxOverlayEmbeddedVideoProps extends BaseEmbeddedVideoProps {
  lightboxOverlay: true;
  lightboxOverlayVideoClassName?: string;
  lightboxTitle?: string;
  lightboxAutoplay?: boolean;
  trackFunction?: () => void;
}

export type EmbeddedVideoProps = NoOverlayEmbeddedVideoProps | WithLightboxOverlayEmbeddedVideoProps;

const blockName = `embedded-video`;

export function EmbeddedVideo(props: NoOverlayEmbeddedVideoProps);
export function EmbeddedVideo(props: WithLightboxOverlayEmbeddedVideoProps);

export function EmbeddedVideo(props: EmbeddedVideoProps) {
  const { url, className, autoPlay = false, startAt } = props as BaseEmbeddedVideoProps;
  const {
    lightboxOverlay,
    lightboxTitle,
    lightboxOverlayVideoClassName,
    lightboxAutoplay = true,
    trackFunction,
  } = props as WithLightboxOverlayEmbeddedVideoProps;

  const bem = bemBlock(blockName);

  function openLightbox() {
    if (lightboxOverlay) {
      trackFunction?.();

      UI.lightbox(
        [
          {
            url,
            mediaType: LightboxMedia.VIDEO,
            videoProps: {
              autoPlay: lightboxAutoplay,
            },
          },
        ],
        {
          title: lightboxTitle,
          className: lightboxOverlayVideoClassName,
        }
      );
    }
  }

  function getEmbeddedIframe() {
    if (isYouTubeUrl(url)) {
      const videoId = extractYouTubeVideoIdFromURL(url);
      return (
        <iframe
          className={bem(null, [{ 'with-lightbox-overlay': lightboxOverlay }], className)}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0${
            !lightboxOverlay && autoPlay ? '&muted=1&playsinline=1&autoplay=1' : ''
          }${!lightboxOverlay && startAt ? `&start=${startAt.asSeconds()}` : ''}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      );
    } else if (url?.includes('vimeo')) {
      const videoId = extractVimeoVideoIdFromURL(url);
      return (
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?badge=0&amp;autopause=${
            autoPlay ? '0' : '1'
          }&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    } else {
      return (
        <div>Media source not supported or not recognized. Please provide a valid YouTube or Vimeo link. {url}</div>
      );
    }
  }

  return (
    <>
      {lightboxOverlay ? (
        <div className={bem('wrapper')}>
          {lightboxOverlay && <div className={bem('lightbox-overlay')} onClick={openLightbox} />}
          {getEmbeddedIframe()}
        </div>
      ) : (
        getEmbeddedIframe()
      )}
    </>
  );
}
