import { ApiErrorDisplay } from '@gik/ui/gik/ApiErrorDisplay';
import React from 'react';
import type {
  APIResponseInterface,
  BaseAPIConfigurationObject,
  BaseAPIInvocation,
  BaseAPIResponseData,
  BaseAPIResponseError,
} from '../../api/BaseAPIConfig';
import { useDevStore } from '../../store/DevStore';

type APIResponseWithRequiredData<D, T = D> = APIResponseInterface<D> &
  Required<Pick<APIResponseInterface<D>, 'data'>> & { transformed: T };

export type CallableChildren<D, T = D> = (
  response: APIResponseWithRequiredData<D, T>
) => React.PropsWithChildren<{}>['children'];

// TODO: WIP we need to also support merging SWR hooks
export type FetchProviderProps<
  D extends BaseAPIResponseData,
  T = D,
  C extends BaseAPIConfigurationObject = BaseAPIConfigurationObject,
  E extends BaseAPIResponseError = BaseAPIResponseError
> = {
  fetch: BaseAPIInvocation<D, C, E>;
  transform?(data: D): T;
  loadingComponent?: React.ReactNode;
  errorComponent?({ errors: [Error] }): React.ReactElement;
  children: CallableChildren<D, T>;
};

export function FetchProvider<
  D extends BaseAPIResponseData,
  T = D,
  C extends BaseAPIConfigurationObject = BaseAPIConfigurationObject,
  E extends BaseAPIResponseError = BaseAPIResponseError
>({
  fetch,
  transform = data => data as unknown as T,
  children,
  loadingComponent,
  errorComponent = ({ errors } = { errors: null }) => <ApiErrorDisplay errors={errors} />,
}: FetchProviderProps<D, T, C, E>): JSX.Element {
  const response = fetch();
  const transformed = React.useMemo(() => {
    if (response?.data) {
      const transformed = transform(response.data);

      return {
        ...response,
        transformed,
      };
    } else {
      return undefined;
    }
  }, [transform, response]);

  const forceSkeleton = useDevStore(state => state.forceSkeletons);
  const isLoading = !transformed?.data || !!forceSkeleton;
  const hasError = !!transformed?.error;

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (hasError) {
    return errorComponent({ errors: [transformed.error] });
  }

  // need to make sure at this point to not forward loading or undefined data down to the children component.
  // it is expected that children renders only when data is available.
  if (children) {
    const rendered = children(transformed as APIResponseWithRequiredData<D, T>);
    if (rendered) {
      return <>{rendered}</>;
    } else {
      return null;
    }
  }

  return <p>No children rendered.</p>;
}
