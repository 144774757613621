import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'competitors';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'How does Give InKind measure up?',
  leadDescription:
    'Your situation is unique, and we are too. Give InKind is the only platform for you to organize any kind of help that is needed, and allows others to give their support, from anywhere — for free.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
