import type { bemFunction } from '@gik/core/utils/bemBlock';
import type { UISize } from '@gik/ui/types';
import React from 'react';

export interface IBaseBooleanInputProps extends React.HTMLAttributes<HTMLInputElement> {
  variant?: string;
  /**
   * Size the component on a fixed scale
   */
  size?: UISize;

  /**
   * Force display of focus state
   */
  focus?: boolean;

  /**
   * Auto focus the input field when the component is mounted
   */
  autoFocus?: boolean;

  /**
   * Put component in disabled mode
   */
  disabled?: boolean;

  /**
   * Checked state of the checkbox input
   */
  checked?: boolean;

  /**
   * value when used as an uncontrolled component
   */
  defaultChecked?: boolean;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * Label to add after the input
   */
  label?: React.ReactNode;

  /**
   * Label to add before the input
   */
  labelBefore?: React.ReactNode;

  /**
   * display the component in an error state
   */
  hasError?: boolean;

  /**
   * display the component in a warning state
   */
  hasWarning?: boolean;

  /**
   * display the component in a success state
   */
  hasSuccess?: boolean;

  /**
   * display the component as a block
   */
  block?: boolean;

  /**
   * display component in a loading state
   */
  loading?: boolean;

  /**
   * Called when the value changes.
   * This differs from the standard onChange callback for form elements.
   * It will not fire when the component is in a disabled state and it
   * will return the value as a string (instead of an entire onChange Event)
   */
  onValueChange?: (checked: boolean) => void;
  onClickDisabled?: () => void;

  /**
   * Name value used in the input element
   */
  name?: string;
}

export interface IExclusiveBaseBooleanInputProps {
  render: (inputProps) => JSX.Element;
  bem: bemFunction;
}

export function BaseBooleanInput(
  {
    variant,
    size,
    checked,
    defaultChecked,
    className,
    tabIndex,
    disabled,
    focus,
    autoFocus,
    block,
    label,
    labelBefore,
    hasError,
    hasWarning,
    hasSuccess,
    loading,
    onFocus,
    onBlur,
    onValueChange,
    onClickDisabled,
    render,
    bem,
    onChange,
    ...otherProps
  }: IBaseBooleanInputProps & IExclusiveBaseBooleanInputProps,
  ref: React.LegacyRef<HTMLInputElement>
) {
  const [controlled, setControlled] = React.useState(checked !== undefined);
  const [_checked, _setChecked] = React.useState(controlled ? undefined : defaultChecked);
  const [focused, setFocused] = React.useState(false);
  const [finalTabIndex, setTabIndex] = React.useState(tabIndex);

  let inputRef: HTMLInputElement;

  React.useEffect(() => {
    setControlled(checked !== undefined);
  }, [checked]);

  React.useEffect(() => {
    function focusInput() {
      inputRef.focus();
    }

    if (autoFocus) {
      focusInput();
    }
  }, [autoFocus, inputRef]);

  React.useEffect(() => {
    // force tabIndex to be -1 if component is in disabled state
    if (disabled) {
      setTabIndex(-1);
    } else {
      setTabIndex(tabIndex);
    }
  }, [disabled, tabIndex]);

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    // don't do anything if disabled
    if (disabled) {
      onClickDisabled?.();
      return;
    }

    if (!controlled) {
      _setChecked(ev.target.checked);
    }

    onValueChange?.(ev.target.checked);
    onChange?.(ev);

    // setTimeout(() => {
    //   if (!controlled) {
    //     _setChecked(ev.target.checked);
    //   }

    //   onValueChange?.(ev.target.checked);
    //   onChange?.(ev);
    // }, 10);

    // don't bother with a focus rectangle if it wasn't focused already
    inputRef.blur();
  }

  function handleFocus(ev: React.FocusEvent<HTMLInputElement>): void {
    // disabled components should not receive focus
    // note: onFocus should never be called in the first place
    // components in this state should have a tabIndex of -1
    if (disabled) {
      return;
    }

    setFocused(true);
    if (onFocus) {
      onFocus(ev);
    }
  }

  function handleBlur(ev: React.FocusEvent<HTMLInputElement>): void {
    setFocused(false);
    if (onBlur) {
      onBlur(ev);
    }
  }

  const isChecked = controlled ? checked : _checked;

  const inputProps = {
    ...otherProps,
    ref: (_ref: HTMLInputElement) => {
      inputRef = _ref;
      return ref;
    },
    type: 'checkbox',
    checked: isChecked,
    value: isChecked ? 'true' : 'false',
    disabled,
    className: bem('input', null, className),
    tabIndex: finalTabIndex,
    loading,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onClickDisabled,
  };

  const labelProps = {
    disabled,
    className: bem(
      null,
      [
        {
          [variant]: variant,
        },
        {
          [`size-${size}`]: size,
        },
        {
          [`checked`]: isChecked,
        },
        { block },
        { disabled },
        {
          [`focus`]: focused || focus,
        },
        {
          [`has-error`]: hasError,
        },
        {
          [`has-warning`]: hasWarning,
        },
        {
          [`has-success`]: hasSuccess,
        },
        { loading },
      ],
      className
    ),
  };

  return (
    <label {...labelProps}>
      {labelBefore && <div className={bem(`label-before`)}>{labelBefore}</div>}
      {render(inputProps)}
      {label && <div className={bem(`label`)}>{label}</div>}
    </label>
  );
}
