import bemBlock from '@gik/core/utils/bemBlock';
import { Image } from '@gik/ui/Image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export interface ICalendarEventTypePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
  iconUrl: string;
  editable: boolean;
  title: string;
  eventTypeSlug: string;
}

export function CalendarEventTypePicker({
  className,
  editable = true,
  iconUrl,
  title,
  eventTypeSlug,
  ...otherProps
}: ICalendarEventTypePickerProps): React.ReactElement {
  const bem = bemBlock('calendar-event-type-picker');
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      <div className={bem('title-wrapper')}>
        <Image className={bem('icon')} src={iconUrl} />
        <span className={bem('title', null, [`gik-calendar-event-type-theme--${eventTypeSlug}`])}>{title}</span>
      </div>
      {editable && <span className={bem('editButton')}>{t(translationKeys.pickerChangeButton)}</span>}
    </div>
  );
}
