import { logger } from '@gik/analytics/utils/logger';
import { emailRecipientInfo } from '@gik/checkout/api';
import { alertNoNonD2CUrlAvailable, openNonD2CUrl } from '@gik/checkout/utils/productUtils';
import type { Product } from '@gik/core/models/gik/Product';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { InterstitialEmailFormValues } from './ExternalProductInterstitial';
import { translationKeys } from './i18n/en';

interface ISendEmailPageProps {
  products?: Product[];
  inkindRouteId?: string;
}

export default function SendEmailPage({ products, inkindRouteId }: ISendEmailPageProps): React.ReactElement {
  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    async (values: InterstitialEmailFormValues) => {
      try {
        await emailRecipientInfo(products[0].id, {
          userEmail: values.email,
          subscribeToNewsletter: values.subscribeToNewsletter,
          inkindRouteId,
        });
      } catch (err) {
        // TODO: gracefully handle backend error ?
        logger.error(err);
      }

      const productNonD2CUrl = products && products.length ? products[0].productNonD2CUrl : null;
      if (productNonD2CUrl) {
        openNonD2CUrl(productNonD2CUrl);
      } else {
        alertNoNonD2CUrlAvailable();
      }
    },
    [inkindRouteId, products]
  );

  const schema: FormSchemaEntry[] = [
    { name: 'email', type: 'email', required: true, props: { placeholder: 'Email' } },
    {
      name: 'subscribeToNewsletter',
      type: 'checkbox',
      default: true,
      props: { label: t(translationKeys.emailKeepUpdated), variant: 'white' },
    },
  ];

  return (
    <div className="gik-external-product-interstitial-email">
      <p>{t(translationKeys.emailText).toString()}</p>
      <Form
        onSubmit={handleSubmit}
        schema={schema}
        vertical
        id="product-interstitial-email"
        restoreAfterUpdate
        render={({ isValid, isSubmitting }) => {
          return (
            <>
              <FormField name="email" />
              <FormField name="subscribeToNewsletter" />
              <Button variant="secondary" type="submit" loading={isSubmitting} disabled={!isValid}>
                {t(translationKeys.emailButton)}
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}
