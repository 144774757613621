import { addInkindToGroup, removeInkindFromGroup, useGroups } from '@gik/api/inkinds/groups';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { can } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { ISingleSelectProps, SelectOptionsType } from '@gik/ui/Select';
import { Select } from '@gik/ui/Select';
import { UI } from '@gik/ui/UIManager';
import he from 'he';
import React from 'react';

export type InkindGroupSelectorProps = {
  inkindPage?: InkindPageAPIModel;
} & ISingleSelectProps;

const emptyPlaceholder = 'Does not belong to a group';

export function InkindGroupSelector({
  children,
  className,
  inkindPage,
  ...otherProps
}: React.PropsWithChildren<InkindGroupSelectorProps>): React.ReactElement {
  const bem = useBemCN('inkind-group-selector');

  const userId = useUserStore(state => state.id);
  const inkindRouteId = inkindPage?.routeId;

  const [groupId, setGroupId] = React.useState<string>(inkindPage?.groupId);
  const [loading, setLoading] = React.useState<boolean>();

  const { data: groups } = useGroups({
    userId,
    page: 1,
    per_page: 10,
  });

  const handleRemoveFromGroup = React.useCallback(async () => {
    const confirmResponse = await UI.confirm('Are you sure you want to remove this InKind page from the group?');
    if (!confirmResponse) return false;

    const response = await removeInkindFromGroup(groupId, inkindRouteId);
    if (!response.ok) {
      UI.notifyError('Something went wrong trying to remove this InKind page from the group');
      return false;
    }

    UI.notifySuccess('InKind page successfully removed from the group');
    return true;
  }, [groupId, inkindRouteId]);

  const handleSwitchGroup = React.useCallback(
    async (newGroupId: string) => {
      if (groupId) {
        // confirm moving to another group
        const confirmResponse = await UI.confirm('Are you sure you want to move this InKind page to another group?');
        if (!confirmResponse) return false;

        // remove from current group first
        const removeResponse = await removeInkindFromGroup(groupId, inkindRouteId);
        if (!removeResponse.ok) {
          UI.notifyError('Something went wrong trying to move this inkind page to another group');
          return false;
        }
      } else {
        // confirm adding to a group
        const confirmResponse = await UI.confirm('Are you sure you want to add this page to the selected group?');
        if (!confirmResponse) return false;
      }

      // add to new group
      const addResponse = await addInkindToGroup(newGroupId, inkindRouteId);
      if (!addResponse.ok) {
        UI.notifyError('Something went wrong trying to move this inkind page to another group');
        return false;
      }

      UI.notifySuccess('InKind page successfully moved to the group');
      return true;
    },
    [groupId, inkindRouteId]
  );

  const handleChange = React.useCallback(
    async (value: string) => {
      // ---- remove from group
      if (!value) {
        const response = await handleRemoveFromGroup();
        if (response) {
          setGroupId(undefined);
        }
        return false;
      }

      // ---- change group
      const response = await handleSwitchGroup(value);
      if (!response) return false;

      setGroupId(value);
      return true;
    },
    [handleRemoveFromGroup, handleSwitchGroup]
  );

  const groupOptions = React.useMemo<SelectOptionsType[]>(() => {
    const emptyOption: SelectOptionsType = { label: emptyPlaceholder, value: null };
    let options: SelectOptionsType[] = [emptyOption];

    const groupOptions = groups
      ?.filter(item => {
        return can('manage', 'groups', { groupId: item.id });
      })
      .map(item => ({ label: he.decode(item.title), value: item.id } as SelectOptionsType));

    if (groupOptions) options = options.concat(groupOptions);

    return options;
  }, [groups]);

  // don't render anything if we don't have at least 2 options (the empty option and one group)
  if (!groupOptions || groupOptions?.length < 2) return null;

  return (
    <Select
      options={groupOptions}
      {...bem(null, null, className)}
      {...otherProps}
      value={groupId}
      placeholder={emptyPlaceholder}
      onChange={handleChange}
    ></Select>
  );
}
