import { useBemCN } from '@gik/core/utils/bemBlock';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';
import { useEffectOnce } from 'react-use';

export interface ILoadingSpinnerProps {
  variant?: UIVariant;
  className?: string;
  label?: string;
  center?: boolean;
  delay?: number;
}

export function LoadingSpinner({
  center,
  delay,
  className,
  label,
  variant = 'primary',
  ...otherProps
}: ILoadingSpinnerProps) {
  const bem = useBemCN('loading-spinner');

  const [ready, setReady] = React.useState(false);

  useEffectOnce(() => {
    if (delay > 0) {
      setTimeout(() => {
        setReady(true);
      }, delay);
    } else {
      {
        setReady(true);
      }
    }
  });

  if (!ready) return null;

  return (
    <div {...otherProps} {...bem(null, [{ center }, { [variant]: true }], [className])}>
      <div className="sk-bounce">
        <div className="sk-bounce-dot" />
        <div className="sk-bounce-dot" />
      </div>
      {label}
    </div>
  );
}
