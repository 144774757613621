import { buildSWRKey } from '@gik/core/api/swr/useKySWR';
import stringify from 'fast-json-stable-stringify';

function _serializeSWRKey(key) {
  return key ? stringify(key) : null;
}

export function serializeSWRKey(key: string, searchParams?: object): string {
  return _serializeSWRKey(buildSWRKey(key, searchParams));
}

export function swrWithSerializableObjectKeys(useSWRNext) {
  return (key, fetcher, config) => {
    return useSWRNext(_serializeSWRKey(key), k => fetcher(...JSON.parse(k)), config);
  };
}

export function deserializeInfiniteSWRKey(next) {
  return (key, fetcher, config) => {
    return next(
      key,
      _key => {
        const [pathname, _searchParams] = _key.split('?');
        const searchParams = Object.fromEntries(new URLSearchParams(_searchParams));
        return fetcher(pathname, searchParams);
      },
      config
    );
  };
}
