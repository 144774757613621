import { useInkind } from '@gik/api/inkinds/inkind';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import type { UIButtonVariant } from '@gik/ui/types';
import PencilIcon from '@heroicons/react/solid/PencilIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ICalendarAnnouncementViewProps } from './CalendarAnnouncementView';
import { CalendarAnnouncementView } from './CalendarAnnouncementView';

// import { deleteAnnouncement } from '@gik/calendar/utils/EventUtils';

export interface ICalendarAnnouncementViewWrapperProps extends ICalendarAnnouncementViewProps {
  buttonsPortal?: () => HTMLElement;
  typeContext: UIButtonVariant;
  entryId?: string; // only needed when delete buttons needs to be displayed
  onDone?(): void;
  onEdit?(): void;
  onDeleted?(): void;
}

function CalendarAnnouncementViewWrapperComp({
  startsAt,
  typeContext,
  // entryId,
  buttonsPortal,
  onDone = noop,
  onEdit = noop,
  // onDeleted = noop,
  ...otherProps
}: ICalendarAnnouncementViewWrapperProps) {
  const { t } = useTranslation();

  const userId = useUserStore(state => state.id);
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);
  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);

  // const [deleting, setDeleting] = React.useState<boolean>(false);
  // const [errorMessage, setErrorMessage] = React.useState<string>();

  // async function handleDelete() {
  //   setDeleting(true);
  //   let response: boolean;
  //   try {
  //     response = await deleteAnnouncement(entryId, startsAt);
  //   } catch (err) {
  //     logger.error(err);
  //     setErrorMessage(err.message);
  //     setDeleting(false);
  //     return;
  //   }

  //   setDeleting(false);
  //   if (response === false) return;
  //   onDeleted();
  // }

  const buttons = () => {
    if (canEdit) {
      return (
        <div className={'gik-form__actions-new'}>
          <section>
            {/* <Button variant="danger" onClick={handleDelete} loading={deleting} prepend={<SvgIcon Icon={TrashIcon} />}>
              {t(commonTranslationKeys.delete)}
            </Button> */}
            <Button variant="secondary" onClick={onEdit} prepend={<SvgIcon Icon={PencilIcon} />}>
              {t(commonTranslationKeys.edit)}
            </Button>
          </section>
          <section>
            <Button onClick={onDone}>{t(commonTranslationKeys.done)}</Button>
          </section>
        </div>
      );
    }

    return (
      <Button wide variant={typeContext} onClick={onDone}>
        {t(commonTranslationKeys.done)}
      </Button>
    );
  };

  return (
    <>
      <CalendarAnnouncementView startsAt={startsAt} {...otherProps} />
      {/* <FormError centered message={errorMessage} /> */}
      {renderPortal(buttons(), buttonsPortal)}
    </>
  );
}

export const CalendarAnnouncementViewWrapper = withComponentErrorBoundary(CalendarAnnouncementViewWrapperComp);
