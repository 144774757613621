import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import replacePath from '@gik/core/utils/replacePath';
import React from 'react';

export default function useViewFeaturedPageAnalytics(): void {
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('origin') && urlParams.get('origin') === 'homepage' && urlParams.has('category')) {
      const category = urlParams.get('category');

      // prevent duplication
      urlParams.delete('origin');
      urlParams.delete('category');

      replacePath(urlParams);

      const inkindPageUrl = window.location.href;

      Analytics.fireEvent(AnalyticsEvents.ViewFeaturedPage, {
        category,
        inkindPageUrl,
      });
    }
  }, []);
}
