import { useQuery } from '@tanstack/react-query';
import { giftWalletAdminEndPoints as endPoints } from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../../contexts';
import type { StrapiPagedDataSource, StrapiSearchParams } from '@gik/platform-management-api-js';
import type { GiftEntity } from '@gik/platform-management-api-js';
import type { ReactQueryOptions } from '../../../types/react-query';
import { createPathWithSearchParams } from '../../../utils/createPathWithSearchParams';
import { useQueryDefaults } from '../../../client';
import type { GyGiftEntity } from '@gik/giftya-api-js/types/api.gift.types';
import type { GyEgiftEntity } from '@gik/giftya-api-js/types/api.egift.types';

/**
 * List Gifts
 */
export function useAdminGifts(
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.admin.gift.list(params)).data,
  });
}

/**
 * Get Gift by id
 */
export function useAdminGift(id?: string, params?: StrapiSearchParams, options?: ReactQueryOptions<GiftEntity>) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.get(id);
  return useQuery({
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.admin.gift.get(id, { params })).data,
  });
}

/**
 * Get Gift by id detailed
 */
export function useAdminGiftDetailed(
  id?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<GiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.getDetailed(id);
  return useQuery({
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.admin.gift.getDetailed(id, { params })).data,
  });
}

export function useAdminGiftByUuid(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<GiftEntity>
) {
  const { client } = usePlatformManagementClient();
  // const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...options,

    queryFn: async () =>
      await client.admin.gift.getByUuid(id, {
        params,
      }),
  });
}

export function useAdminRemoteGift(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<GyGiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getRemoteGift(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.gift.getRemoteGift(id, {
          params,
        })
      ).data,
  });
}

export function useAdminRemoteEGift(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<GyEgiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getRemoteEGift(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.gift.getRemoteEGift(id, {
          params,
        })
      ).data,
  });
}

export function useAdminGiftsByCustomerId(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () =>
      (
        await client.admin.gift.list({
          ...params,
          filters: {
            customerId: {
              $eqi: id.toUpperCase(),
            },
            ...params?.filters,
          },
        })
      ).data,
  });
}
