import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import LockOpenIcon from '@heroicons/react/solid/LockOpenIcon';
import SparklesIcon from '@heroicons/react/solid/SparklesIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

export type PremiumPageUpgradeThankYouVariant = 'generic' | 'thankYouMessages';

interface IPremiumPageUpgradeThankYouProps {
  hasBorder?: boolean;
  showPromoMessage?: boolean;
  variant?: PremiumPageUpgradeThankYouVariant;
}

export default function PremiumPageUpgradeThankYou({
  showPromoMessage,
  variant = 'generic',
  hasBorder = true,
}: IPremiumPageUpgradeThankYouProps) {
  const { t } = useTranslation();
  const bem = useBemCN('premium-page-upgrade-thank-you');

  function renderPremiumBenefit(description: string, bemModifiers: string[] = []) {
    return (
      <div {...bem('premium-benefit', bemModifiers)}>
        <SvgIcon Icon={LockOpenIcon} />
        <p>
          <HTMLParser rawHtml={description} />
        </p>
      </div>
    );
  }

  return (
    <div {...bem(undefined, [{ [`variant-${variant}`]: variant }], [{ 'has-border': hasBorder }])}>
      <p {...bem('heading')}>{t(translationKeys.PremiumPageThankYouTitle)}</p>
      <p {...bem('subheading')}>{t(translationKeys.PremiumPageUnlockedBenefits)}</p>
      {renderPremiumBenefit(t(translationKeys.PremiumPageThankYouMessagesViewBenefit), ['thank-you-messages'])}
      {renderPremiumBenefit(t(translationKeys.PremiumPageSupporterViewBenefit))}
      {renderPremiumBenefit(t(translationKeys.PremiumPageRemoveSuggestionsBenefit))}

      {showPromoMessage && (
        <div {...bem('premium-benefit')}>
          <SvgIcon Icon={SparklesIcon} />
          <p>
            <strong>Pay it forward!</strong> Let a friend upgrade to premium, <strong>for FREE!</strong> A promo code
            has been sent to your email.
          </p>
        </div>
      )}

      <p {...bem('footer-message')}>
        {variant === 'thankYouMessages'
          ? t(translationKeys.PremiumPageThankYouFooterThankYouMessages).toString()
          : t(translationKeys.PremiumPageThankYouFooterMessage).toString()}
      </p>
    </div>
  );
}

export type openPremiumPageThankYouModalOptions = {
  showPromoMessage?: boolean;
  variant?: PremiumPageUpgradeThankYouVariant;
};

export function openPremiumPageThankYouModal({
  showPromoMessage = false,
  variant,
}: openPremiumPageThankYouModalOptions = {}) {
  const bem = bemBlock('premium-page-upgrade-thank-you-modal');
  return UI.dialog(
    () => {
      return <PremiumPageUpgradeThankYou hasBorder={false} showPromoMessage={showPromoMessage} variant={variant} />;
    },
    { closable: true, className: bem() }
  );
}
