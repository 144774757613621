import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import type { UIBoxDecoration, UIButtonVariant, UISize } from '../types';

export interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: UIButtonVariant;
  decoration?: UIBoxDecoration;
  padded?: boolean;

  /**
   * Size the button on a fixed scale
   */
  size?: UISize;

  /**
   * use circular shape (this will apply border-radius: 50% with a fixed width and height)
   */
  circle?: boolean;

  /**
   * use rounded corners (default)
   */
  rounded?: boolean;

  /**
   * use pill shaped corners
   */
  pill?: boolean;

  /**
   * use squared corners
   */
  squared?: boolean;

  active?: boolean;
  clickable?: boolean;

  block?: boolean;
}

export function Badge({
  children,
  className,
  decoration,
  variant = 'primary',
  padded,
  block,
  pill,
  circle,
  squared,
  rounded,
  active,
  clickable,
  size = 'base',
  ...otherProps
}: IBadgeProps): React.ReactElement {
  const bem = useBemCN('badge');

  return (
    <div
      {...otherProps}
      {...bem(
        null,
        [
          { [variant]: variant },
          { padded },
          { block },
          { [`size-${size}`]: size },
          { pill },
          { circle },
          { squared },
          { rounded },
          { active },
          { clickable },
          { [`decoration-${decoration}`]: decoration },
        ],
        className
      )}
    >
      {children}
    </div>
  );
}
