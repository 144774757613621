import { gikClassPrefix } from '@gik/core/constants';
import repeatElement from '@gik/core/utils/repeatElement';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { WordBone } from './WordBone';

const blockName = `${gikClassPrefix}-list-bone`;

export interface ListBoneProps extends BaseBoneProps {
  entries?: number;
  constantWidth?: boolean;
}

export function ListBone({ className, entries = 10, constantWidth = true }: ListBoneProps) {
  const blockClasses = classnames([blockName, className || '']);

  return (
    <ul className={blockClasses}>
      {repeatElement(
        Math.round(entries),
        <li>
          <WordBone width={constantWidth ? 100 : undefined} />
        </li>
      )}
    </ul>
  );
}
