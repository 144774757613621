import bemBlock from '@gik/core/utils/bemBlock';
import { Indicator } from '@gik/ui/Indicator';
import React from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from '../Tooltip';
import type { UISize, UIVariant } from '../types';

export interface StepItem<StepIdType> {
  id?: StepIdType;
  name: string;
  disabled?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  tooltip?: string;
  subSteps?: {
    id?: StepIdType;
  }[];
}

export interface StepsProps<StepIdType> {
  className?: string;
  stepNavClassName?: string;
  children: React.ReactNode;

  /**
   * style variant to use
   */
  variant?: UIVariant;

  /**
   * Size the component on a fixed scale
   */
  size?: UISize;

  /**
   * display component in a disabled state
   */
  disabled?: boolean;

  /**
   * display component as a circular class
   */
  circle?: boolean;

  /**
   * allow navigation back to a completed step
   */
  allowBackwardNavigation?: boolean;

  /**
   * allow navigation forward to a completed step
   */
  allowForwardNavigation?: boolean;

  /**
   * current step
   */
  index?: number;

  /**
   * current sub-step
   */
  subIndex?: number;

  renderHiddenTabs?: boolean;

  /**
   * An array of strings used as labels
   */
  steps: StepItem<StepIdType>[];

  navPortal?: HTMLElement;
  portal?: HTMLElement;

  hidden?: boolean;

  /**
   * Called when a completed step is clicked and `allowNav` is true.
   */
  onClick?(step: StepItem<StepIdType>, index: number, subIndex?: number): void;

  onStepProgression?(step: StepItem<StepIdType>, index: number): void;
}

export interface StepsRefProps<StepIdType> {
  gotoStep: (index: number) => void;
  setCompletedIndex: (index: number) => void;
}

export function StepsComp<StepIdType>(
  {
    children,
    className,
    stepNavClassName,
    variant = 'primary',
    size = 'base',
    steps,
    index = 0,
    subIndex = 0,
    disabled,
    circle,
    navPortal,
    portal,
    renderHiddenTabs,
    allowBackwardNavigation,
    allowForwardNavigation = true,
    hidden,
    onClick,
    onStepProgression,
    ...otherProps
  }: StepsProps<StepIdType>,
  forwardedRef: React.MutableRefObject<StepsRefProps<StepIdType>>
): React.ReactElement {
  const bem = bemBlock('steps');
  const [completedIndex, setCompletedIndex] = React.useState(index);

  React.useEffect(() => {
    if (index > completedIndex) {
      setCompletedIndex(index);
      onStepProgression?.(steps[index], index);
    }
  }, [index, completedIndex, setCompletedIndex, onStepProgression, steps]);

  function handleClick(goToIndex: number) {
    if (!allowBackwardNavigation) {
      return;
    }
    if (disabled) {
      return;
    }
    // don't allow click if this step hasn't been completed yet
    if (goToIndex > completedIndex) {
      return;
    }

    if (!allowForwardNavigation && goToIndex > index) {
      return;
    }

    if (onClick) {
      onClick(steps[goToIndex], goToIndex, subIndex);
    }
  }

  React.useImperativeHandle(forwardedRef, () => ({
    gotoStep: (index: number) => {
      return onClick?.(steps[index], index);
    },
    setCompletedIndex,
  }));

  // get children and sub steps (filter out undefined ones)
  const tabs = [];
  let childIndex = 0;
  React.Children.forEach(children, child => {
    if (child) {
      if (!steps?.[childIndex]?.subSteps) {
        tabs.push(
          <div key={childIndex}>
            <div key={childIndex} className={bem('content-wrapper', [{ active: childIndex === index }])}>
              {child}
            </div>
          </div>
        );
      } else {
        tabs.push(
          <div key={childIndex}>
            <div key={childIndex} className={bem('content-wrapper', [{ active: childIndex === index }])}>
              {(child as React.ReactElement).props.children.map((child, subChildIndex) => {
                return (
                  <div key={subChildIndex} className={bem('content-wrapper', [{ active: subIndex === subChildIndex }])}>
                    {child}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      childIndex++;
    }
  });
  const tab = tabs[index]?.[subIndex] || tabs[index] || null;

  const content = renderHiddenTabs ? tabs : tab;

  const nav = (
    <ul className={bem('nav')}>
      {steps.map((item, step: number) => {
        const stepClasses = bem(
          'item',
          [
            { completed: allowForwardNavigation ? step <= completedIndex : step <= index },
            { active: step == index },
            { disabled: item.disabled },
          ],
          stepNavClassName
        );

        const stepItem = (
          <li className={stepClasses}>
            <div className={bem('content')} onClick={() => handleClick(step)}>
              {item.prepend && <div className={bem('prepend')}>{item.prepend}</div>}
              {step == index && item.subSteps?.length > 0 ? (
                <div className={bem('substep-indicator-wrapper')}>
                  {item.name && <div className={bem('name')}>{item.name}</div>}
                  <Indicator
                    variant={variant}
                    size={'sm'}
                    className={bem('indicator')}
                    currentStep={subIndex}
                    totalSteps={item.subSteps.length}
                  />
                </div>
              ) : (
                item.name && <div className={bem('name')}>{item.name}</div>
              )}
              {item.append && <div className={bem('append')}>{item.append}</div>}
            </div>
          </li>
        );

        if (item.tooltip) {
          return (
            <Tooltip allowOnMobile key={item.name} text={item.tooltip}>
              {stepItem}
            </Tooltip>
          );
        }

        return stepItem;
      })}
    </ul>
  );

  function StepsNavWrapper(children) {
    return (
      <div
        className={bem(
          null,
          [
            { [variant]: variant },
            { [`size-${size}`]: size },
            { circle },
            { allowNav: allowBackwardNavigation },
            { disabled },
            { hidden },
          ],
          [className]
        )}
        {...otherProps}
      >
        {children}
      </div>
    );
  }

  return StepsNavWrapper(
    <>
      {navPortal ? ReactDOM.createPortal(StepsNavWrapper(nav), navPortal) : nav}
      {portal ? ReactDOM.createPortal(content, portal) : content}
    </>
  );
}

export function SubStep({ children }: React.PropsWithChildren<{}>) {
  return <div>{children}</div>;
}

export const Steps = React.forwardRef(StepsComp);
