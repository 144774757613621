import type { CacheOptions } from '@gik/core/api/base/hooks/WPURLEncoder';
import type { KyInterface } from '@gik/core/api/ky/types';
import { SWRInfiniteDefaultPerPage, SWRInfiniteDefaultPerPageParamName } from '@gik/core/api/swr/swrDefaults';
import type { Key } from 'swr';
import type {
  APIInfinitePagingConfig,
  APIInfiniteResponseInterface,
  APIResponseInterface,
  BaseAPIResponseData,
  DeepQueryParams,
  SWRConfigInterface,
} from '../BaseAPIConfig';
import { dotnetApi, dotnetApiCached, dotnetApiLongerTimeout } from '../ky/dotnetApi';
import type { GIKSWRConfiguration } from './useKySWR';
import { useKySWR, useKySWRInfinite } from './useKySWR';

/**
 * SWR Hook for querying the Give Inkind API
 *
 * @param key    a unique key string for the request (or a function / array / null)
 * @param searchParams (optional) query search parameters
 * @param config SWR hook configuration
 */
export function useApi<D extends BaseAPIResponseData>(
  key: Key,
  searchParams?: DeepQueryParams,
  config: GIKSWRConfiguration = {}
): APIResponseInterface<D> {
  return useKySWR<D>(dotnetApi as KyInterface, key, searchParams, config);
}

export function useApiLongerGETTimeout<D extends BaseAPIResponseData>(
  key: Key,
  searchParams?: DeepQueryParams,
  config: GIKSWRConfiguration = {}
): APIResponseInterface<D> {
  return useKySWR<D>(dotnetApiLongerTimeout as KyInterface, key, searchParams, config);
}

/**
 * SWR Hook for paginated querying the Give Inkind API
 *
 * @param key    a unique key string for the request (or a function / array / null)
 * @param searchParams (optional) query search parameters
 * @param config SWR hook configuration
 */
export function useApiInfinite<D extends BaseAPIResponseData>(
  key: Key,
  searchParams?: DeepQueryParams,
  config: SWRConfigInterface = {},
  {
    perPage = SWRInfiniteDefaultPerPage,
    perPageParamName = SWRInfiniteDefaultPerPageParamName,
  }: APIInfinitePagingConfig = {}
): APIInfiniteResponseInterface<D> {
  return useKySWRInfinite<D>(dotnetApi as KyInterface, key, searchParams, config, perPage, perPageParamName);
}

/**
 * SWR Hook for querying the Give Inkind API through the cache
 *
 * @param key    a unique key string for the request (or a function / array / null)
 * @param searchParams (optional) query search parameters
 * @param config SWR hook configuration
 */
export function useApiCached<D extends BaseAPIResponseData>(
  key: Key,
  searchParams?: DeepQueryParams & CacheOptions,
  config: GIKSWRConfiguration = {}
): APIResponseInterface<D> {
  return useKySWR<D>(dotnetApiCached as KyInterface, key, searchParams, config);
}
