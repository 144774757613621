import type { QueryClient, QueryKey, UseMutationOptions } from '@tanstack/react-query';

export const buildOptions = <TData, TError, TVariables, TContext, TKey extends QueryKey>(
  queryClient: QueryClient,
  queryKey?: TKey,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
): UseMutationOptions<TData, TError, TVariables, TContext> => {
  return {
    ...options,
    onSuccess: (...args) => {
      if (options?.onSuccess) {
        return options.onSuccess(...args);
      }

      if (queryKey !== undefined) {
        queryKey.forEach(key => {
          queryClient.invalidateQueries({ queryKey: key as QueryKey });
        });
      }
    },
  };
};
