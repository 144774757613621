export type PaginationParams = {
  page?: number;
  per_page?: number;
  sortBy?: string;
  sortDirection?: SORT_DIRECTION;
  search?: string;
};

export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}
