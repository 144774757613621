import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { InputProps } from './Input';
import { Input, inputBlockName } from './Input';
import type { InputNumberProps } from './InputNumber';

export interface InputPhoneProps extends InputProps {
  formatProps?: NumberFormatProps;

  onFormattedValueChange?(value: NumberFormatValues): void;
}

// block name for this component
const blockName = `${gikClassPrefix}-input-phone`;

/**
 * GIK Input component for formatted phone numbers
 */
export function InputPhone({
  className,
  onValueChange,
  onFormattedValueChange,
  formatProps = {},
  ...otherProps
}: InputNumberProps): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);

  // apply defaults for phone formatting
  if (formatProps.format === undefined) formatProps.format = '###-###-####';
  if (formatProps.mask === undefined) formatProps.mask = '#';
  // also pass the placeholder to the NumberFormat component
  formatProps.placeholder = otherProps.placeholder;
  formatProps.disabled = otherProps.disabled;

  function handleValueChange(values: NumberFormatValues): void {
    if (onFormattedValueChange) onFormattedValueChange(values);
    if (onValueChange) onValueChange(values.value);
  }

  delete otherProps.onChange;

  return (
    <Input
      className={blockClasses}
      customInput={customProps => (
        <NumberFormat
          className={`${inputBlockName}__input`}
          onValueChange={handleValueChange}
          onFocus={customProps.onFocus}
          onBlur={customProps.onBlur}
          {...formatProps}
          {...customProps}
        />
      )}
      {...otherProps}
    />
  );
}
