import React from 'react';

type ValueType = string | number;

export interface ISwitchProps {
  value: ValueType;
}

interface ISwitchContext {
  value: ValueType;
  compares: ValueType[];
}

const SwitchContext = React.createContext<ISwitchContext>(null);

export function Switch({ value, children }: React.PropsWithChildren<ISwitchProps>) {
  return <SwitchContext.Provider value={{ value, compares: [] }}>{children}</SwitchContext.Provider>;
}

export interface ICaseProps {
  value: ValueType;
  default?: boolean;
}

export function Case({ value: compareValue, children, ...otherProps }: React.PropsWithChildren<ICaseProps>) {
  const { value: switchValue, compares } = React.useContext(SwitchContext);

  if (!compares.includes(compareValue)) {
    compares.push(compareValue);
  }

  return (
    <>
      {switchValue == compareValue && children}
      {otherProps.default && <Default>{children}</Default>}
    </>
  );
}

export function Default({ children }: React.PropsWithChildren<{}>) {
  const { value, compares } = React.useContext(SwitchContext);

  return <>{!compares.includes(value) && children}</>;
}
