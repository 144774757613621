import { logger } from '@gik/analytics/utils/logger';
import { useCheckoutStore } from '@gik/checkout/store/CheckoutStore';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type Order from '@gik/core/models/gik/Order';
import { OrderCreationStatus } from '@gik/core/models/gik/Order';
import noop from '@gik/core/utils/noop';
import sleep from '@gik/core/utils/sleep';
import i18n from '@gik/i18n';
import type { Source, Stripe } from '@stripe/stripe-js';
import { StatusCodes } from 'http-status-codes';
import type { ResponsePromise } from 'ky';
import { v4 as uuidv4 } from 'uuid';
import type { CheckoutFormPayload } from '../components/CheckoutForm/CheckoutForm';
import type { AddressValidationRequest, PaymentConfirmationValues } from '../types';

const PATH = 'orders';

interface CreateOrderResponse {
  orderId: number;
  orderKey: string; // exists to stop bots from being able to crawl orders
}

export type ClaimConflictErrorDetails = {
  id: string; // unique id of the line item that has a conflict
  status: string; // reason for the claim conflict (could be 'conflict' or 'gone' or )
  availableDates: ClaimConflictAvailabledate[]; // exists to stop bots from being able to crawl orders
};

export type ClaimConflictResolve = {
  id: string; // unique id of the line item that has a conflict
  date: ClaimConflictAvailabledate;
};

export type ClaimConflictAvailabledate = {
  entryId: string;
  instanceDate: string;
};

export class ClaimConflictError extends Error {
  code = undefined;
  data = undefined;

  constructor(code: number, message: string, data: object) {
    super(message);
    this.code = code;
    this.data = data;
  }

  toString() {
    const data = {
      code: this.code,
      message: this.message,
      data: this.data,
    };
    return JSON.stringify(data);
  }
}

interface BadRequestResponse {
  error: string;
  field: string;
  message?: string;
}

export async function createSourceFromGooglePay(token: string) {
  return dotnetApi
    .post(`${PATH}/google-pay`, {
      method: 'POST',
      json: {
        stripeToken: token,
      },
    })
    .json<Source>();
}

export type OrderStatus = {
  text: string;
  progress: number;
  totalProgress: number;
};

class OrderService {
  public async placeOrder(
    stripe: Stripe,
    payload: CheckoutFormPayload,
    ignoreClaimConflict: boolean,
    updateCheckoutStatusText: (status: OrderStatus) => void = noop
  ): Promise<PaymentConfirmationValues> {
    if (payload.createClaimRequest && payload.messageToRecipient) {
      // copy the secure message to recipient into the claim so it can be viewed by the supporter
      payload.createClaimRequest.note = payload.messageToRecipient;
    }

    const { products, ...rest } = payload;

    // const tempCart = rest;
    // delete payload.products;

    // remove temporary data from the payload
    const finalPayload = { ...payload } as CheckoutFormPayload;
    finalPayload.ignoreClaimConflict = ignoreClaimConflict;
    finalPayload.products = products.concat([]);
    finalPayload.products = finalPayload.products.map(item => {
      item.anonymous = payload.anonymous;
      if (item.createClaimRequest) {
        item.createClaimRequest.privateClaim = payload.privateClaim;
      }

      if (!item.id) {
        item.id = uuidv4();
      }
      // if (item.claimEvent) {
      //   delete item.claimEvent.cartItem;
      //   delete item.claimEvent.product;
      // }
      return item;
    });

    updateCheckoutStatusText({
      text: 'PLACING ORDER',
      progress: 0,
      totalProgress: 3,
    });

    const createOrderRequest: ResponsePromise = dotnetApi.post(PATH, { method: 'POST', json: finalPayload });

    const createOrderResult = await createOrderRequest;
    const createOrderResultStatus = createOrderResult.status;

    if (createOrderResultStatus == StatusCodes.BAD_REQUEST) {
      const createOrderBadRequestResponse = await createOrderRequest.json<BadRequestResponse>();
      if (createOrderBadRequestResponse.error === 'invalid_format') {
        throw new Error(`The value for '${createOrderBadRequestResponse.field}' has an invalid format.`);
      }

      throw new Error(
        `Unknown bad request response.${
          createOrderBadRequestResponse.message ? `\n${createOrderBadRequestResponse.message}` : ''
        }`
      );
    } else if (createOrderResultStatus === StatusCodes.CONFLICT) {
      // handle conflict resolution
      const claimConflictErrorDetails = await createOrderResult.json<ClaimConflictErrorDetails[]>();

      throw new ClaimConflictError(createOrderResultStatus, 'conflicts', claimConflictErrorDetails);
    } else if (createOrderResultStatus === StatusCodes.GONE) {
      throw new Error(createOrderResultStatus.toString());
    }

    if (createOrderResultStatus !== StatusCodes.OK) {
      throw new Error(i18n.t('checkout.genericBackendError'));
    }

    const createOrderResponse = await createOrderRequest.json<CreateOrderResponse>();

    updateCheckoutStatusText({
      text: 'PROCESSING ORDER',
      progress: 1,
      totalProgress: 3,
    });

    const { orderId, orderKey } = createOrderResponse;

    let confirmedCardPayment = false;
    const maxAttempts = 50;
    const pollInterval = 1000;
    for (let i = 1; ; i++) {
      if (i > maxAttempts) {
        logger.error(`Max attempts reached for ${orderId}`);
        throw new Error(`Something went wrong. Please contact support with reference #${orderId}`);
      }
      await sleep(pollInterval);
      logger.info(`Polling order #${orderId} - ${i}`);

      let order: Order = null;
      try {
        const pollOrderRequest: ResponsePromise = dotnetApi.get(`${PATH}/${orderId}`, {
          timeout: 10000,
          retry: 0,
          searchParams: {
            orderKey,
            timezoneOffset: payload.timezoneOffset,
          },
        });
        order = await pollOrderRequest.json<Order>();

        if (order.creationStatusMessage) {
          updateCheckoutStatusText({
            text: order.creationStatusMessage,
            progress: 2,
            totalProgress: 3,
          });
        }
      } catch (error) {
        // if a poll fails we will retry, so just log the error
        logger.error(error);
        continue;
      }

      switch (order.creationStatus) {
        case OrderCreationStatus.Pending:
          if (order.paymentIntentClientSecret && !confirmedCardPayment) {
            const { paymentIntent, error } = await stripe.confirmCardPayment(order.paymentIntentClientSecret);
            logger.info('Confirm card payment result', { paymentIntent, error });
            confirmedCardPayment = true;
            if (error) {
              logger.error('Failed to confirm card payment', error);
              throw new Error(
                'Failed to confirm card payment:' +
                  error.code +
                  ' ' +
                  (error.message || 'unknown error') +
                  '\n\nPlease contact support providing order #' +
                  orderId +
                  ' for more information.'
              );
            } else if (paymentIntent.status !== 'succeeded') {
              throw new Error(
                'Unexpected payment intent status:' +
                  paymentIntent.status +
                  '\n\nPlease contact support providing order #' +
                  orderId +
                  ' for more information.'
              );
            } else {
              // 3d secure payment succeeded
              logger.info('3d secure payment succeeeded');
              // reset poll attempts
              i = 0;

              let retry = 0;
              const maxRetries = 3;

              do {
                // call the /resume endpoint to continue processing the payment
                sleep(1000);
                logger.info('3d secure payment calling resume endpoint');

                const resumeOrderResponse = await dotnetApi.post(`${PATH}/${order.id}/resume`, {
                  method: 'POST',
                  searchParams: {
                    orderKey,
                  },
                });

                const createOrderResult = resumeOrderResponse.status;
                if (createOrderResult !== StatusCodes.NO_CONTENT) {
                  retry++;

                  if (retry !== maxRetries) {
                    logger.warn('Failed to resume order, retying...', { retry });
                  } else {
                    logger.error('Failed to resume order', resumeOrderResponse);
                    throw new Error(
                      'Failed to resume order.\n\nPlease contact support providing order #' +
                        orderId +
                        ' for more information.'
                    );
                  }
                } else {
                  break;
                }
              } while (retry <= maxRetries);
            }
          }
          break;
        case OrderCreationStatus.Success:
          logger.info('Order successfully completed');

          useCheckoutStore.getState().setOrderTotal(null);
          useCheckoutStore.getState().setOrderTip(null);

          if (order.creationStatusMessage) {
            updateCheckoutStatusText({
              text: 'ORDER COMPLETED',
              progress: 3,
              totalProgress: 3,
            });

            // just give it some time for the animation to complete
            await sleep(1200);
          }
          return {
            productName: payload.products[0].name,
            orderDate: order.orderDate,
            orderId,
            orderKey,
            tip: order.tip ?? payload.tip,
            shipping: order.shipping,
            products: order.products,
            senderEmail: order.senderEmail,
            paymentMethod: order.paymentMethod,
            subtotal: order.subtotal,
            total: order.total,
            shippingCost: order.shippingCost,
            taxCost: order.taxCost,
            claims: order.claims,
          };
        case OrderCreationStatus.Fail:
          throw new Error(
            order.errorMessage + '\n\nPlease contact support providing order #' + orderId + ' for more information.'
          );
      }
    }
  }
}

abstract class OrderServicePromiseSingleton {
  private static ongoingOrder: Promise<PaymentConfirmationValues>;
  private static retyCount: number = 0;
  private static readonly maxRetryCount: number = 3;

  public static async placeOrder(
    stripe: Stripe,
    payload: CheckoutFormPayload,
    ignoreClaimConflict: boolean,
    updateCheckoutStatusText: (status: OrderStatus) => void = noop
  ): Promise<PaymentConfirmationValues> {
    if (OrderServicePromiseSingleton.ongoingOrder) return OrderServicePromiseSingleton.ongoingOrder;

    function onSuccess(response: PaymentConfirmationValues) {
      OrderServicePromiseSingleton.retyCount = 0;
      OrderServicePromiseSingleton.ongoingOrder = null;
      return response;
    }

    function onError(reason) {
      if (
        (reason?.name === 'TimeoutError' || reason?.name === 'TypeError') &&
        OrderServicePromiseSingleton.retyCount <= OrderServicePromiseSingleton.maxRetryCount
      ) {
        logger.error('order error timeout, retrying...');

        OrderServicePromiseSingleton.retyCount++;
        OrderServicePromiseSingleton.ongoingOrder = orderService
          .placeOrder(stripe, payload, ignoreClaimConflict, updateCheckoutStatusText)
          .then(onSuccess, onError);

        return OrderServicePromiseSingleton.ongoingOrder;
      } else {
        OrderServicePromiseSingleton.retyCount = 0;
        OrderServicePromiseSingleton.ongoingOrder = null;

        logger.error('order error', reason);

        throw reason;
      }
    }

    const orderService = new OrderService();
    OrderServicePromiseSingleton.ongoingOrder = orderService
      .placeOrder(stripe, payload, ignoreClaimConflict, updateCheckoutStatusText)
      .then(onSuccess, onError);

    return OrderServicePromiseSingleton.ongoingOrder;
  }
}

export async function placeOrder(
  stripe: Stripe,
  payload: CheckoutFormPayload,
  ignoreClaimConflict: boolean,
  updateCheckoutStatusText: (status: OrderStatus) => void = noop
): Promise<PaymentConfirmationValues> {
  return OrderServicePromiseSingleton.placeOrder(stripe, payload, ignoreClaimConflict, updateCheckoutStatusText);
}

export async function validateAddress(address: AddressValidationRequest): Promise<boolean> {
  for (let i = 0; i < 3; i++) {
    try {
      const validateAddressRequest: ResponsePromise = dotnetApi.post(`${PATH}/addresses/validate`, {
        method: 'POST',
        json: address,
      });
      const createOrderResult = (await validateAddressRequest).status;
      if (createOrderResult !== StatusCodes.OK) {
        return false;
      }
      return await validateAddressRequest.json<boolean>();
    } catch (error) {
      logger.error(error);
    }
  }
  return false;
}
