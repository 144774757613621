/**
 * Custom application errors
 */
export class CustomError extends Error {
  code = undefined;
  data = undefined;

  constructor(code: number, message: string, data: object) {
    super(message);
    this.code = code;
    this.data = data;
  }

  toString() {
    const data = {
      code: this.code,
      message: this.message,
      data: this.data,
    };
    return JSON.stringify(data);
  }
}
