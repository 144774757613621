import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import type { FeatureCareCalendarInputNames } from '@gik/create/enums/FeatureCareCalendarInputNames';
import type { FeatureDonationsInputNames } from '@gik/create/enums/FeatureDonationsInputNames';
import { FeaturesStepInputNames } from '@gik/create/enums/FeaturesStepInputNames';
import type { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { featureCareCalendarSchema, featureDonationsSchema, featureWishlistSchema } from '@gik/create/formSchemas';
import type { FormProps, FormRef, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import type { FormikProps } from 'formik';
import React from 'react';

export interface IBaseFeaturesFormValues extends FormProps {}

export interface ICreateFeaturesCareCalendarFormValues extends IBaseFeaturesFormValues {
  [FeatureCareCalendarInputNames.CareCalendarEnabled]: boolean;
}

export interface ICreateFeaturesWishlistFormValues extends IBaseFeaturesFormValues {
  [FeatureWishlistInputNames.WishlistEnabled]: boolean;
  [FeatureWishlistInputNames.WishlistItems]: number[];
}

export interface ICreateFeaturesDonationsFormValues extends IBaseFeaturesFormValues {
  [FeatureDonationsInputNames.DonationsEnabled]: boolean;
}

export interface IFeatureStepProps<T extends IBaseFeaturesFormValues> {
  title: string;
  description: string;
  finePrint?: string;
  icon: React.ReactNode;
  className?: string;
  formName: FeaturesStepInputNames;
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  formId?: string;
  onSubmit?: (values: T) => void;
  onChange?: (values: T) => void;
}

const blockName = 'feature-step';

function FeatureStep<T extends IBaseFeaturesFormValues>(
  {
    title,
    description,
    finePrint,
    icon,
    className,
    formName,
    formId,
    buttons,
    buttonsPortal,
    ...otherProps
  }: IFeatureStepProps<T>,
  ref
) {
  const bem = bemBlock(blockName);
  const formRef = React.useRef<FormRef>(null);
  const formValues = useCreateFlowStore(state => state.formValues);

  React.useImperativeHandle(ref, () => formRef.current);

  let schema: FormSchemaEntry[];
  switch (formName) {
    case FeaturesStepInputNames.CareCalendar:
      schema = featureCareCalendarSchema(formValues);
      break;
    case FeaturesStepInputNames.Wishlist:
      schema = featureWishlistSchema(formValues);
      break;
    case FeaturesStepInputNames.Donations:
      schema = featureDonationsSchema(formValues);
      break;
  }

  return (
    <Form
      schema={schema}
      ref={formRef}
      className={bem(null, null, className)}
      trackingId={AnalyticsEvents.CreateStarted}
      render={form => {
        return (
          <div>
            <section className={bem('title-wrapper')}>
              <div className={bem('icon')}>{icon}</div>
              <div className={bem('title')}>{title}</div>
            </section>

            <p className={bem('description')}>
              <HTMLParser rawHtml={description} />
            </p>
            {schema.map((field, key) => (
              <FormField key={key} className={bem('form-field')} name={field.name} center />
            ))}

            <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
            {finePrint && <p className={bem('fine-print')}>{finePrint}</p>}
          </div>
        );
      }}
      {...otherProps}
    />
  );
}

// prettier-ignore
export const CareCalendarFeatureStep = React.forwardRef<
  FormRef,
  IFeatureStepProps<ICreateFeaturesCareCalendarFormValues>
>(FeatureStep);

// prettier-ignore
export const WishlistFeatureStep = React.forwardRef<FormRef, IFeatureStepProps<ICreateFeaturesWishlistFormValues>>(
  FeatureStep
);

// prettier-ignore
export const DonationsFeatureStep = React.forwardRef<FormRef, IFeatureStepProps<ICreateFeaturesDonationsFormValues>>(
  FeatureStep
);
