import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const VisitorsOkOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M18.9309 11.3284C19.681 10.5783 20.1024 9.56087 20.1024 8.5C20.1024 7.43913 19.681 6.42172 18.9309 5.67157C18.1807 4.92143 17.1633 4.5 16.1024 4.5C15.0416 4.5 14.0242 4.92143 13.274 5.67157C12.5239 6.42172 12.1024 7.43913 12.1024 8.5C12.1024 9.56087 12.5239 10.5783 13.274 11.3284C14.0242 12.0786 15.0416 12.5 16.1024 12.5C17.1633 12.5 18.1807 12.0786 18.9309 11.3284Z"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.1255 17.8333V28.4999H12.1255L12.1255 15.8333"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.12555 4.5C4.12555 12.5 10.1255 15.8333 16.1255 16.5C22.1255 17.1667 28.1256 18.5 28.1256 28.5"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
);

export const VisitorsOk = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M12.1037 7.12132C12.6664 6.55871 12.9824 5.79565 12.9824 5C12.9824 4.20435 12.6664 3.44129 12.1037 2.87868C11.5411 2.31607 10.7781 2 9.98242 2C9.18677 2 8.42371 2.31607 7.8611 2.87868C7.29849 3.44129 6.98242 4.20435 6.98242 5C6.98242 5.79565 7.29849 6.55871 7.8611 7.12132C8.42371 7.68393 9.18677 8 9.98242 8C10.7781 8 11.5411 7.68393 12.1037 7.12132Z"
          fill="#7BAA52"
        />
        <path d="M9 18H11C12.1046 18 13 17.1046 13 16V10.5L7 9L7 16C7 17.1046 7.89543 18 9 18Z" fill="#7BAA52" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2C3.55228 2 4 2.44772 4 3C4 5.0508 4.75435 6.42155 5.83597 7.34292C6.95041 8.29227 8.48902 8.82596 10.1104 9.00612C11.8478 9.19916 13.8541 9.50392 15.4077 10.6548C17.0469 11.869 18 13.8683 18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 14.2983 15.2031 12.9922 14.2173 12.2619C13.1459 11.4683 11.6522 11.1897 9.88957 10.9939C8.01098 10.7852 6.04959 10.1522 4.53903 8.86541C2.99565 7.55068 2 5.61586 2 3C2 2.44772 2.44772 2 3 2Z"
          fill="#7BAA52"
        />
      </svg>
    );
  }
);
