import { useBemCN } from '@gik/core/utils/bemBlock';
import repeatElement from '@gik/core/utils/repeatElement';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { WordBone } from './WordBone';

export interface ParagraphBoneProps extends BaseBoneProps {
  words: number;
  minWidth?: number;
  maxWidth?: number;
  center?: boolean;
}

export function ParagraphBone({ className, center, words = 10, minWidth, maxWidth }: ParagraphBoneProps) {
  const bem = useBemCN('paragraph-bone');

  return (
    <div {...bem(null, [{ center }], className)}>
      {repeatElement(words, <WordBone minWidth={minWidth} maxWidth={maxWidth} />)}
    </div>
  );
}
