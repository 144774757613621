import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'email-confirmation';

// TODO: fill in missing translations
const translations = {
  title: 'Thanks for confirming your email!',
  description: 'Create a page to coordinate support, or find ways to help in the menu above.',

  createPageButton: 'Create A Page',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
