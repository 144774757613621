import { useBemCN } from '@gik/core/utils/bemBlock';
import { UI } from '@gik/ui/UIManager';
import React, { useEffect } from 'react';
import type { PaymentConfirmationValues } from '../../types';
import PremiumPageUpgradeThankYou from '../PremiumPageUpgradeThankYou/PremiumPageUpgradeThankYou';
import { PaymentConfirmation } from './PaymentConfirmation';

export interface PremiumPageUpgradePaymentConfirmationProps {
  values: PaymentConfirmationValues;
  className?: string;
  productName: string;
  showPromoMessage?: boolean;
  inkindRouteId?: string;
}

export function PremiumPageUpgradePaymentConfirmation({
  className,
  values,
  productName,
  showPromoMessage,
  ...otherProps
}: PremiumPageUpgradePaymentConfirmationProps): React.ReactElement {
  useEffect(() => {
    UI.notifyPremiumPageUnlocked();
  }, []);

  const bem = useBemCN('premium-page-upgrade-payment-confirmation');

  return (
    <>
      <PaymentConfirmation
        header={<PremiumPageUpgradeThankYou showPromoMessage={showPromoMessage} />}
        renderDefaultThankYou={false}
        values={values}
        productName={productName}
        shipping={false}
        {...bem(undefined, undefined, className)}
        {...otherProps}
      />
    </>
  );
}
