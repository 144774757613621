import bemBlock from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { EmbeddedVideo } from '@gik/ui/EmbeddedVideo';
import { StepCard, StepCardGroup } from '@gik/ui/gik/StepCard';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { UIFeaturesType } from '@gik/ui/types';
import React from 'react';

export interface ICareCalendarAsideProps {
  featureVideo: string;
  trackCalendarVideoPlayed?: () => void;
}

const blockName = 'care-calendar-aside';

export default function CareCalendarAside({ featureVideo, trackCalendarVideoPlayed }: ICareCalendarAsideProps) {
  const bem = bemBlock(blockName);

  return (
    <div className={bem()}>
      <div className={bem('title')}>{i18n.t(translationKeys.careCalendarHowItWorksTitle).toString()}</div>
      <StepCardGroup>
        <StepCard type={UIFeaturesType.CareCalendar}>
          <HTMLParser rawHtml={i18n.t(translationKeys.careCalendarHowItWorksDescription1)} />
        </StepCard>
        <StepCard type={UIFeaturesType.CareCalendar}>
          <HTMLParser rawHtml={i18n.t(translationKeys.careCalendarHowItWorksDescription2)} />
        </StepCard>
      </StepCardGroup>

      <KeepProportions className={bem('video')}>
        <EmbeddedVideo
          url={`https://www.youtube.com/watch?v=${featureVideo}`}
          lightboxOverlay
          lightboxTitle={i18n.t(translationKeys.careCalendarTitle)}
          trackFunction={trackCalendarVideoPlayed}
        />
      </KeepProportions>
    </div>
  );
}
