import type { FormSchemaEntry } from '@gik/ui/Form';
import type React from 'react';

export type StateFieldProps = {
  scrollParent: React.RefObject<HTMLElement>;
  autoscroll?: boolean;
};

export const StateField = ({ scrollParent, autoscroll }: StateFieldProps): FormSchemaEntry => ({
  label: 'State',
  name: 'state',
  type: 'select-us-states',
  required: true,
  props: {
    placeholder: 'State',
    autoComplete: 'address-level1',
    type: 'compact',
    isSearchable: true,
    scrollParent,
    autoscroll,
  },
});
