import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

type ErrorProps = {} & UIComponent;

export function Error({ children, className }: React.PropsWithChildren<ErrorProps>) {
  const bem = useBemCN('error');

  return <div {...bem(null, null, className)}>{children}</div>;
}
