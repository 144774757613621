import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { recipientInfoFormSchema } from '@gik/create/formSchemas';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormButtons } from '@gik/ui/Form';
import type { ValidationOptions } from '@gik/ui/Form/validation';
import { validateForm } from '@gik/ui/Form/validation';
import { PageSection } from '@gik/ui/gik/PageSection';
import type { ValidationErrors } from 'final-form';
import type { FormikProps } from 'formik';
import React from 'react';
import type { IRecipientInfoAddressFormValues } from './RecipientInfoAddressForm';
import RecipientInfoAddressForm, { recipientInfoAddressFormSchema } from './RecipientInfoAddressForm';
import type { IRecipientInfoFormValues } from './RecipientInfoForm';
import RecipientInfoForm from './RecipientInfoForm';

export interface IRecipientInfoStepProps extends FormProps {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: IRecipientFormValues) => void;
  onChange?: (values: IRecipientFormValues) => void;
}

export function recipientInfoValidator(formSchema) {
  return async function (values: IRecipientFormValues, opts: ValidationOptions<IRecipientFormValues>) {
    // run the default validator
    const errors: ValidationErrors = await validateForm(formSchema, values, opts);

    // remove some errors if the fields are hidden
    if (!values.includeRecipientAddress) {
      delete errors.address1;
      delete errors.address2;
      delete errors.city;
      delete errors.state;
      delete errors.postalCode;
    } else {
      if (errors.address1 || errors.city || errors.state || errors.postalCode) {
        // scroll to bottom when there are errors
        const el = document.querySelector('.gik-create-page .gik-modal__content-wrapper');
        if (el) el.scrollTop = el.scrollHeight;
      }
    }

    return errors;
  };
}

export interface IRecipientFormValues extends IRecipientInfoFormValues, IRecipientInfoAddressFormValues {}

const blockName = 'recipient-info-step';
export const formId = 'CreateRecipientInfoStepForm';

export default function RecipientInfoStep({
  className,
  buttons,
  buttonsPortal,
  onChange,
  onSubmit,
  ...otherProps
}: IRecipientInfoStepProps) {
  const bem = bemBlock(blockName);
  const { recipientFormRef } = React.useContext(CreatePageContext);
  const formValues = useCreateFlowStore(state => state.formValues);
  const setNextDisabled = useCreateFlowStore(state => state.setNextDisabled);

  const [values, setValues] = React.useState<IRecipientInfoAddressFormValues>();
  const formSchema = recipientInfoFormSchema(
    formValues[AboutStepInputNames.RecipientType] === RecipientType.Organization
  ).concat(
    recipientInfoAddressFormSchema(
      formValues[AboutStepInputNames.RecipientType] === RecipientType.Myself,
      values?.includeRecipientAddress
    )
  );

  const { stepIndex, subStepIndex } = useCreateFlowStore(state => ({
    stepIndex: state.stepIndex,
    subStepIndex: state.subStepIndex,
  }));

  const enabled =
    formValues[AboutStepInputNames.RecipientType] !== RecipientType.Myself && stepIndex == 2 && subStepIndex == 1;

  useFormNavigationObserver(recipientFormRef, enabled);

  function handleSubmit(values: IRecipientFormValues) {
    setValues(values);
    if (onSubmit) {
      onSubmit(values);
    }
  }

  function handleChange(values: IRecipientFormValues) {
    if (
      values.includeRecipientAddress === true &&
      (!values.address1 || !values.city || !values.state || !values.postalCode)
    ) {
      setNextDisabled(true);
    } else if (!values.includeRecipientAddress) {
      setNextDisabled(
        !values?.[RecipientInfoStepInputNames.RecipientName]?.trim().length ||
          !values?.[RecipientInfoStepInputNames.RecipientEmail]?.trim().length ||
          !values?.[RecipientInfoStepInputNames.RecipientEmailConfirm]?.trim().length
      );
    }

    setValues(values);
    onChange?.(values);
  }

  return (
    <Form
      ref={recipientFormRef}
      className={bem(null, null, [className, 'gik-form--white'])}
      schema={formSchema}
      initialValues={formValues}
      onSubmit={handleSubmit}
      onChange={handleChange}
      validate={recipientInfoValidator(formSchema)}
      trackingId={AnalyticsEvents.CreateStarted}
      vertical
      id={formId}
      render={(form: FormikProps<object>) => {
        return (
          <div>
            <PageSection className={bem('recipient-info')} contained={false} variant="aqua" gradientDarkening noPad>
              <RecipientInfoForm
                isOrganization={formValues[AboutStepInputNames.RecipientType] === RecipientType.Organization}
                form={form}
              />
            </PageSection>
            <PageSection className={bem('recipient-address')} contained={false} variant="solid-neutral" noPad>
              <RecipientInfoAddressForm
                isMyself={formValues[AboutStepInputNames.RecipientType] === RecipientType.Myself}
                form={form}
                values={values}
              />
            </PageSection>

            <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
          </div>
        );
      }}
      {...otherProps}
    />
  );
}
