import qs from 'qs';

type PropTransform = { from: string; to: string };
export type PropTransformMap = PropTransform[];

function swapSearchParams(sp, from, to) {
  if (!sp?.[from]) return sp;

  const value = sp[from];
  delete sp[from];
  sp[to] = value;

  return sp;
}

export function searchParamsTransform(options, map: PropTransformMap) {
  map?.forEach(({ from, to }) => {
    if (!options?.searchParams) return;
    if (typeof options.searchParams === 'string') {
      options.searchParams = qs.stringify(swapSearchParams(qs.parse(options.searchParams), from, to));
    } else {
      options.searchParams = swapSearchParams(options.searchParams, from, to);
    }
  });
}
