import type { FormSchemaEntry } from '@gik/ui/Form';

export const CountryField = (): FormSchemaEntry => ({
  label: 'Country',
  name: 'country',
  type: 'plaintext',
  default: 'US',
  text: 'United States (US)',
  required: true,
  props: { autoComplete: 'country-name' },
});
