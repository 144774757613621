import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import React from 'react';
import type { IScrollNavBarItemProps } from '.';
import { ScrollNavBarItem } from '.';

export const scrollNavBarBlockName = 'scroll-navbar';

export type ScrollNavItemsArray = Omit<IScrollNavBarItemProps, 'activeKey' | 'setActive' | 'setInactive'>[];

export interface IScrollNavBarProps extends Pick<IScrollNavBarItemProps, 'offset' | 'containerId'> {
  navItems: ScrollNavItemsArray;
}

export const ScrollNavBar: React.FC<PropsWithClassName<IScrollNavBarProps>> = ({
  navItems = [],
  offset,
  containerId,
  className,
}): React.ReactElement => {
  const bem = bemBlock(scrollNavBarBlockName);

  const firstNavItemKey = navItems.length > 0 ? navItems[0].to : null;
  const lastNavItemKey = navItems.length > 0 ? navItems[navItems.length - 1].to : null;

  // make first nav item selected initially
  const [activeKey, setActiveKey] = React.useState<string>(firstNavItemKey);

  function setActive(id: string): void {
    setActiveKey(id);
  }

  function setInactive(id: string): void {
    // if the scroll leaves the bounds of one of the outer elements it means none of the sections are active
    if (id === firstNavItemKey && activeKey === firstNavItemKey) {
      setActiveKey(null);
    }
    if (id === lastNavItemKey && activeKey === lastNavItemKey) {
      setActiveKey(null);
    }
  }

  return (
    <div className={bem(null, null, className)}>
      <ul className={bem('list')}>
        {navItems.map((item, i) => (
          <ScrollNavBarItem
            containerId={containerId}
            offset={offset}
            activeKey={activeKey}
            setActive={setActive}
            setInactive={setInactive}
            key={i}
            {...item}
          />
        ))}
      </ul>
    </div>
  );
};
