import { FullStory } from '@fullstory/browser';
import { useAppLocalStore } from '@gik/core/store/AppLocalStore';
import { useAppSessionStore } from '@gik/core/store/AppSessionStore';
import type { Options } from 'ky';

export function sessionKeyHeaderKyHook(request: Request, options: Options) {
  const { browserSessionId } = useAppSessionStore.getState();
  const { browserId } = useAppLocalStore.getState();

  if (!browserSessionId) return;
  request.headers.set('x-browser-session-id', browserSessionId);

  if (!browserId) return;
  request.headers.set('x-browser-id', browserId);

  try {
    const fullStorySession = FullStory('getSession', { format: 'url' });
    if (!fullStorySession) return;
    request.headers.set('x-fullstory-session', fullStorySession);
  } catch (e) {}
}
