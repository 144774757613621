import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { ISupporter } from '@gik/core/models/gik/ISupporter';
import { downloadResponse } from '..';

export function useSupporters(inkindRouteId: string, config?: SWRConfigInterface) {
  // important: the path must be null if there is no inkindRouteId yet, this is to prevent any loading from happening
  return useApi<ISupporter[]>(inkindRouteId ? `inkinds/${inkindRouteId}/supporters` : null, null, {
    ...(config ?? {}),
    revalidateOnMount: false,
  });
}

export async function downloadSupporters(inkindRouteId: string, supporterEmails: string[]) {
  const response = await dotnetApi.post(`inkinds/${inkindRouteId}/supporters/export`, {
    headers: {
      Accept: 'application/octet-stream',
    },
    json: {
      supporterEmails,
    },
  });

  downloadResponse(response);
}
