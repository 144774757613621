import { getCalendarEntriesPath } from '@gik/api/calendar/calendarEntries';
import type { ICalendarTimeAndRepeatValues } from '@gik/calendar/models/Calendar';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { StatusCodes } from 'http-status-codes';
import type { UnparentEventRequest } from './calendarEvents';

export interface ICalendarAnnouncementPayload extends ICalendarTimeAndRepeatValues {
  typeId: number;
  title: string; // only used by custom event types
  description: string;
  announcementTypeId: number; // for announcements
  sendEmailToPageFollowers: boolean;
}

export interface ICreateAnnouncementPayload extends ICalendarAnnouncementPayload {
  inkindRouteId: string;
}

export interface IUpdateAnnouncementPayload extends ICreateAnnouncementPayload, UnparentEventRequest {
  inkindRouteId: string;
}

export async function createAnnouncement(inkindRouteId: string, payload: ICreateAnnouncementPayload): Promise<string> {
  const response = await dotnetApi.post(getCalendarEntriesPath(inkindRouteId), {
    json: payload,
  });

  if (response.status === StatusCodes.CREATED) {
    const location = response.headers.get('location');
    return location?.split('/').slice(-1)[0];
  } else {
    throw new Error(`Unexpected response code: ${response.status} ${response.statusText}`);
  }
}
