import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'contact-page-organizers-for-address';

const translations = {
  description: 'Need the recipient’s address?',
  emailAnOrganizer: 'EMAIL AN ORGANIZER',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
