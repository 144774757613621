import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import { giftCardSuggestionBlockName } from '@gik/create';
import { Button } from '@gik/ui/Button';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import React from 'react';

export interface IGiftCardSuggestionProps {
  product: Product;
  showProductName?: boolean;
  onClickDismiss: () => void;
}

export default function GiftCardSuggestion({
  product,
  showProductName = false,
  onClickDismiss,
}: IGiftCardSuggestionProps) {
  const bem = bemBlock(giftCardSuggestionBlockName);

  return (
    <div className={bem()}>
      <Image className={bem('thumbnail')} src={product?.gridImage} />
      {showProductName && (
        <div className={bem('product-name')}>
          <HTMLParser rawHtml={product.name} />
        </div>
      )}

      <Button
        noFocusStyling
        className={bem('dismiss-button')}
        circle
        size="xs"
        variant="danger"
        onClick={onClickDismiss}
      >
        <SvgIcon Icon={TrashIcon} />
      </Button>
    </div>
  );
}
