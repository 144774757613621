export default {
  core: {
    ok: 'ok', // TODO: duplicated in common.ts
    cancel: 'cancel',
    disable: 'disable',
    back: 'back',
    next: 'next', // TODO: duplicated in common.ts
    finish: 'finish',
    close: 'close',
    update: 'update',
    showMore: 'Show more',
    showLess: 'Show less',
    showMoreCompact: 'More',
    showLessCompact: 'Less',
    continue: 'Continue',
    goBack: 'Go back',
  },
};
