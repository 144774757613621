import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import type { NextJSProcess } from '@gik/core/types/process';
import { cookies } from '@gik/core/utils/Cookies';
import * as _isMobile from 'is-mobile';

export function isMobile() {
  return _isMobile.isMobile();
}

export function isBrowser() {
  if (!process) return false;
  const nextjsProcess = process as NextJSProcess;
  return nextjsProcess.browser === true;
}

// TODO: remove this function once we are on a single environment and use the
export async function logout(): Promise<void> {
  // log user out from the userStore
  useUserStore.getState().logout();
  // also remove the cookie
  cookies.remove('userId');
}

// TODO: get route from a central Routes file
export function isHomepage(path?: string) {
  return (path ?? window.location.pathname) === routes.home;
}

// TODO: get route from a central Routes file
export function isResetPasswordPage(path?: string) {
  return (path ?? window.location.pathname) === routes.resetPassword;
}
