import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { Decoder } from '@gik/ui/Decoder';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys as loginFormTranslationKeys } from '../Login/i18n/en';

export type ISigninFlowAccountDeactivatedProps = UIComponent & {
  supportersFirstName: string;
  goToStartStep: () => void;
};

export function SigninFlowAccountDeactivated({
  supportersFirstName,
  goToStartStep,
  className,
  ...otherProps
}: ISigninFlowAccountDeactivatedProps) {
  const bem = useBemCN('sign-in-flow-account-deactivated-content');
  const { t } = useTranslation();

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1 {...bem('title')}>
        <Decoder text={t(translationKeys.loginContentTitle, { supportersFirstName })} />
      </h1>
      <p {...bem('copy')}>{t(translationKeys.deactivatedContentCopy).toString()}</p>

      <Button fullWidth onClick={goToStartStep} variant={'primary-link'}>
        {t(loginFormTranslationKeys.formUseDifferentEmail).toString()}
      </Button>
    </section>
  );
}
