import { v4 as uuidv4 } from 'uuid';
import type { GetState, SetState } from 'zustand';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface IdempotencyKeysStore {
  createFlowIdempotencyKey: string;
  updateCreateFlowIdempotencyKey: () => void;

  frontendTransactionId: string;
  updateFrontendTransactionId: () => void;
}

function createIdempotencyKey(): string {
  return uuidv4();
}

export const useIdempotencyKeysStore = create<IdempotencyKeysStore>(
  persist(
    (set: SetState<IdempotencyKeysStore>, get: GetState<IdempotencyKeysStore>) => ({
      createFlowIdempotencyKey: null,
      updateCreateFlowIdempotencyKey: () => set(() => ({ createFlowIdempotencyKey: createIdempotencyKey() })),

      frontendTransactionId: null,
      updateFrontendTransactionId: () => set(() => ({ frontendTransactionId: createIdempotencyKey() })),
    }),
    {
      name: 'idempotency-keys',
      getStorage: () => localStorage,
    }
  )
);

if (useIdempotencyKeysStore.getState().createFlowIdempotencyKey === null) {
  useIdempotencyKeysStore.getState().updateCreateFlowIdempotencyKey();
}
