import type { UIComponent } from '@gik/core/types/UI';
import { Button } from '@gik/ui/Button';
import React from 'react';

export type IApplePayButtonProps = {
  disabled?: boolean;
} & UIComponent;

export function ApplePayButton({ disabled }: IApplePayButtonProps) {
  let attempts = 0;

  function processPayment(paymentData) {
    // TODO: this should make a backend call to process the payment

    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // @todo pass payment token to your gateway to process payment
        const paymentToken = paymentData.paymentMethodData.tokenizationData.token;
        console.log(paymentData);

        if (attempts++ % 2 == 0) {
          reject(new Error('Every other attempt fails, next one should succeed'));
        } else {
          resolve({});
        }
      }, 500);
    });
  }

  return <Button disabled={disabled}>🍎 Pay</Button>;
}
