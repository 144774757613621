import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useServiceCategories, useServicesByEventTypeId } from '@gik/api/calendar/serviceProductBundle';
import { ServiceModule } from '@gik/calendar';
import { CalendarServiceIds } from '@gik/calendar/models/CalendarEventTypes';
import bemBlock from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { Checkbox } from '@gik/ui/Checkbox';
import type { FormProps, FormRef, FormSchemaEntry, ValidationErrors } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { FormApi } from 'final-form';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IWordpressServiceProductBundleOption } from '../Services';
import { CalendarServiceTile } from '../Services';
import { translationKeys } from './i18n/en';

export enum CalendarEventServicesFormFieldNames {
  Services = 'allowedServiceIds',
}

export interface ICalendarServicesFormValues {
  [CalendarEventServicesFormFieldNames.Services]?: number[];
}

export const calendarEventServicesFormSchema = (options: IWordpressServiceProductBundleOption[]): FormSchemaEntry[] => [
  {
    type: 'select-list',
    name: CalendarEventServicesFormFieldNames.Services,
    errorName: 'recipient type',
    required: true,
    requiredMessage: 'Please select at least one type',
    props: {
      className: 'gik-calendar-claim-type-select-list',
      multiple: true,
      options,
      render: (item: IWordpressServiceProductBundleOption, selected: boolean) => {
        return <CalendarServiceTile multiple horizontal selected={selected} image={item.image} label={item.label} />;
      },
    },
  },
];

export interface ICalendarEventServicesFormProps extends FormProps<ICalendarServicesFormValues> {
  eventTypeId: number;
  eventTypeName: string;
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
}

function CalendarEventServicesFormComp(
  {
    className,
    eventTypeName,
    buttons,
    buttonsPortal,
    eventTypeId,
    onSubmit,
    ...otherProps
  }: ICalendarEventServicesFormProps,
  ref: React.MutableRefObject<FormRef>
) {
  const bem = bemBlock('calendar-event-services-form');
  const { t } = useTranslation();

  // Local State
  const [options, setOptions] = React.useState<IWordpressServiceProductBundleOption[]>();

  // SWR
  const { data: serviceProductBundles } = useServiceCategories();
  const { data: matchingServices } = useServicesByEventTypeId(eventTypeId);

  // const [formValues, setFormValues] = React.useState<Partial<ICalendarServicesFormValues>>({});
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  // const { data: options } = useServiceProductBundles(eventTypeId);
  // const { data: eventTypes } = useCalendarEventTypes();

  const formId = 'CalendarServicesForm';
  const formApiRef = React.useRef<FormApi>();

  /**
   * Create a list of matching service categories when all data is ready
   */
  React.useEffect(() => {
    if (!serviceProductBundles || !matchingServices) return;

    const options: IWordpressServiceProductBundleOption[] = matchingServices.map(service => {
      const category = serviceProductBundles.find(item => item.id === service['service-category'][0]);

      return {
        label: service.acf.display_name || category.name,
        value: service.id,
        image: service.acf.taxonomy_svg_icon || category.acf.taxonomy_svg_icon,
      };
    });
    setOptions(options);
  }, [serviceProductBundles, matchingServices]);

  const schema = calendarEventServicesFormSchema(options);

  function customValidateForm(values: ICalendarServicesFormValues): ValidationErrors<ICalendarServicesFormValues> {
    const errors: ValidationErrors<ICalendarServicesFormValues> = {};

    if (
      (values && !values[CalendarEventServicesFormFieldNames.Services]) ||
      values[CalendarEventServicesFormFieldNames.Services]?.length < 1
    ) {
      errors[CalendarEventServicesFormFieldNames.Services] = {
        message: t(translationKeys.ServiceRequired),
        type: 'singleRequired',
      };
    }

    return errors;
  }

  function renderTitle() {
    return (
      <span className="gik-calendar-modal-section-title">
        {t(translationKeys.ServiceLabel, { name: eventTypeName }).toString()}
      </span>
    );
  }

  const handleFormChange = React.useCallback(
    (values: ICalendarServicesFormValues) => {
      if (values && values[CalendarEventServicesFormFieldNames.Services]?.length >= options?.length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    },
    [options]
  );

  const handleFormReady = React.useCallback(
    (form: FormikProps<ICalendarServicesFormValues>) => {
      if (form?.values && form?.values?.[CalendarEventServicesFormFieldNames.Services]?.length >= options?.length) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    },
    [options]
  );

  function fireSelectedServicesAnalytics(values: ICalendarServicesFormValues) {
    // TODO: move analytics to separate file
    // TODO: use in calendarServicesForm
    const matchingServiceIds = matchingServices.map(service => service.id);
    const selectedServiceIds = values[CalendarEventServicesFormFieldNames.Services];
    const services = matchingServices;

    const helpSelectedFields: { [key: string]: string } = {};
    const hasInPersonService = (serviceIds: number[]): boolean =>
      serviceIds.some(
        serviceId =>
          serviceId !== CalendarServiceIds.SeniorCarePhoneCall &&
          services.find(s => s.id === serviceId)?.acf.service_module === ServiceModule.PROVIDE_MYSELF
      );

    if (hasInPersonService(matchingServiceIds)) {
      helpSelectedFields.helpInPersonSelected = hasInPersonService(selectedServiceIds).toString();
    }

    const hasGiftCardService = (serviceIds: number[]): boolean =>
      serviceIds.some(
        serviceId =>
          [ServiceModule.MEAL_GIFT_CARD.toString(), ServiceModule.GENERIC_GIFT_CARD.toString()].indexOf(
            services.find(s => s.id === serviceId)?.acf.service_module
          ) >= 0
      );

    if (hasGiftCardService(matchingServiceIds)) {
      helpSelectedFields.helpGiftCardSelected = hasGiftCardService(selectedServiceIds).toString();
    }

    const hasOnlineService = (serviceIds: number[]): boolean =>
      serviceIds.some(serviceId => {
        const service = services.find(s => s.id === serviceId);
        return (
          service?.acf.service_module === ServiceModule.GRUBHUB ||
          service?.acf.service_module === ServiceModule.DOORDASH
        );
      });

    if (hasOnlineService(matchingServiceIds)) {
      helpSelectedFields.helpOrderOnlineSelected = hasOnlineService(selectedServiceIds).toString();
    }

    const hasPhoneCallService = (serviceIds: number[]): boolean =>
      serviceIds.some(serviceId => serviceId === CalendarServiceIds.SeniorCarePhoneCall);

    if (hasPhoneCallService(matchingServiceIds)) {
      helpSelectedFields.helpPhoneCallSelected = hasPhoneCallService(selectedServiceIds).toString();
    }

    Analytics.fireEvent(
      AnalyticsEvents.EventCreateStarted,
      {
        stepCompleted: 'HelpType',
        ...helpSelectedFields,
      },
      _ => ['stepCompleted'],
      keys => keys.filter(key => key != 'stepCompleted')
    );
  }

  function handleFormSubmit(values: object, form: FormApi) {
    fireSelectedServicesAnalytics(values);
    onSubmit?.(values, form);
  }

  function handleAllChange(checked: boolean, setFieldValue) {
    if (checked) {
      setFieldValue(
        CalendarEventServicesFormFieldNames.Services,
        options.map(item => item.value)
      );
    } else {
      setFieldValue(CalendarEventServicesFormFieldNames.Services, []);
    }
  }

  if (!options) return <LoadingSpinner />;

  return (
    <div className={bem('wrapper', null, className)}>
      {renderTitle()}

      <Form
        className={bem(null, null, className)}
        schema={schema}
        vertical
        id={formId}
        restoreAfterUpdate
        ref={ref}
        validate={customValidateForm}
        onSubmit={handleFormSubmit}
        onChange={handleFormChange}
        onReady={handleFormReady}
        render={form => {
          const { setFieldValue } = form;
          const _buttons = buttons ? buttons(form, formId) : null;

          return (
            <>
              <Checkbox
                className={bem('checkAll')}
                label="All of them!"
                checked={allSelected}
                onValueChange={value => handleAllChange(value, setFieldValue)}
              />
              <FormField
                center
                labelClassName={'gik-calendar-form-label'}
                name={CalendarEventServicesFormFieldNames.Services}
              />
              {renderPortal(_buttons, buttonsPortal)}
            </>
          );
        }}
        {...otherProps}
      />
    </div>
  );
}

export const CalendarServicesForm = React.forwardRef(CalendarEventServicesFormComp);
