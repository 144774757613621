import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const FlowersOkOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M16.4785 11.709C17.4791 10.6715 18.536 9.38873 20.1996 8.68084C23.0724 7.45842 26.6179 7.16675 26.6179 7.16675C26.6179 7.16675 27.6917 12.0169 24.5087 15.2504C21.3257 18.484 16.1255 17.7654 16.1255 17.7654"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M14.9995 10.0142C12.0847 7.16675 5.76933 7.16675 5.76933 7.16675C5.76933 7.16675 4.31193 12.048 7.71253 15.3023C11.1131 18.5566 17.4285 17.743 17.4285 17.743C17.4285 17.743 17.9143 12.8617 14.9995 10.0142Z"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M20.1255 8.4999C18.7922 5.16659 16.1256 4.49998 16.1256 4.49998C16.1256 4.49998 13.4589 5.16659 12.1256 8.49996"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M10.8922 21.1667C13.2022 21.2767 14.7922 23.1666 14.7922 23.1666C14.7922 23.1666 13.1921 25.1667 10.7922 25.1667C8.39229 25.1667 6.79245 23.1666 6.79245 23.1666C6.79245 23.1666 8.58222 21.0566 10.8922 21.1667Z"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M17.4589 17.8334C17.4589 17.8334 16.6296 16.6663 16.135 23.4883C15.9788 25.6432 17.7937 27.1794 19.0609 27.8525C21.8057 29.3101 25.4589 27.8525 25.4589 27.8525"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const FlowersOk = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M6.99997 13.5C8.0442 13.5 9.08845 14.227 9.62663 14.6687C9.84008 14.844 9.84008 15.1561 9.62663 15.3313C9.08845 15.7731 8.04419 16.5 6.99997 16.5C5.95574 16.5 4.91152 15.7731 4.37336 15.3313C4.15993 15.1561 4.15992 14.844 4.37336 14.6688C4.91151 14.227 5.95573 13.5 6.99997 13.5Z"
          fill="#7BAA52"
        />
        <path
          d="M10.0002 8.74612C8.5781 5.70479 4.85128 5.1326 3.49929 5.02495C3.22402 5.00303 3.00237 5.2229 3.02003 5.49847C3.08837 6.56513 3.39242 9.05954 4.74524 10.1406C6.49026 11.5352 7.81068 12 10.0002 12C12.1898 12 13.5101 11.5352 15.2551 10.1406C16.608 9.05954 16.9121 6.56513 16.9804 5.49847C16.9981 5.2229 16.7764 5.00303 16.5011 5.02495C15.1491 5.1326 11.4223 5.70479 10.0002 8.74612Z"
          fill="#7BAA52"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 11C10.5523 11 11 11.4477 11 12C11 13.3467 11.3368 14.3162 11.9203 14.9445C12.4913 15.5595 13.4406 16 15 16C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18C13.0594 18 11.5087 17.4405 10.4547 16.3055C9.41318 15.1838 9 13.6533 9 12C9 11.4477 9.44772 11 10 11Z"
          fill="#7BAA52"
        />
        <path
          d="M7 4.49994C7 4.49994 8.3006 2.76581 9.69073 2.12337C9.88714 2.03261 10.1129 2.03261 10.3093 2.12337C11.6994 2.76581 13 4.49994 13 4.49994C13 4.49994 11 4.99994 10 6.49994C9 4.99994 7 4.49994 7 4.49994Z"
          fill="#7BAA52"
        />
      </svg>
    );
  }
);
