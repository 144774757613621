import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-event-types';

const translations = {
  pickerChangeButton: 'change',
  announcementTypeNew: 'NEW!',
  announcementTypeTitle: 'No Help Needed?',
  announcementTypeDescription: 'Make an announcement instead',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
