import { timeoutDefaultValue } from '@gik/core/constants';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { motion } from 'framer-motion';
import React from 'react';

export interface IAnimateHeightProps {
  duration?: number;
  ease?: string;
  //eslint-disable-next-line
  variants?: any;
  isVisible?: boolean;
  onClosed?(): void;
}

export function AnimateHeight({
  duration = 300,
  ease = 'easeOut',
  variants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: { opacity: 0, height: 0 },
  },
  isVisible,
  children,
  ...other
}: React.PropsWithChildren<IAnimateHeightProps>) {
  const bem = useBemCN('animate-height');

  const [isOpen, setIsOpen] = React.useState<boolean>();

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(isVisible);
    }, timeoutDefaultValue);
  }, [isVisible]);

  return (
    <motion.div
      {...bem()}
      initial={'collapsed'}
      animate={isOpen ? 'open' : 'collapsed'}
      exit={'collapsed'}
      inherit={false}
      variants={variants}
      transition={{
        ease,
        duration: duration / 1000,
      }}
      {...other}
    >
      {children}
    </motion.div>
  );
}
