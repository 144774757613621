export const pageFeatureCardBlockName = 'page-feature-card';
export const careCalendarCardBlockName = 'care-calendar-card';
export const donationsCardBlockName = 'donations-card';
export const wishlistCardBlockName = 'wishlist-card';
export const giftCardSuggestionBlockName = 'gift-card-suggestion';
export const createWrapperBlockName = 'create-wrapper';

export * from './CareCalendarCard/CareCalendarCard';
export { default as CareCalendarCard } from './CareCalendarCard/CareCalendarCard';
export * from './CreateWrapper/CreateWrapper';
export { default as CreateWrapper } from './CreateWrapper/CreateWrapper';
export * from './DonationsCard/DonationsCard';
export { default as DonationsCard } from './DonationsCard/DonationsCard';
export * from './GiftCardSkeleton/GiftCardSkeleton';
export { default as GiftCardSkeleton } from './GiftCardSkeleton/GiftCardSkeleton';
export * from './GiftCardSuggestion/GiftCardSuggestion';
export { default as GiftCardSuggestion } from './GiftCardSuggestion/GiftCardSuggestion';
export * from './RecipientAddressReminder/openRecipientAddressReminder';
export * from './WishlistCard/WishlistCard';
export { default as WishlistCard } from './WishlistCard/WishlistCard';
