import { useSiteSettings } from '@gik/api/siteSettings/siteSettings';
import { useEnvStore } from '@gik/core/store/EnvStore';
import React from 'react';

export function useGooglePay() {
  const { data: siteSettings } = useSiteSettings();
  const { ENABLE_GOOGLE_PAY, GOOGLE_PAY_ENV } = useEnvStore(state => ({
    ENABLE_GOOGLE_PAY: state.ENABLE_GOOGLE_PAY,
    GOOGLE_PAY_ENV: state.GOOGLE_PAY_ENV,
  }));

  const isEnabled = React.useMemo(() => {
    return ENABLE_GOOGLE_PAY === 'true' && !!GOOGLE_PAY_ENV && siteSettings?.googlePayEnabled === 'true';
  }, [ENABLE_GOOGLE_PAY, GOOGLE_PAY_ENV, siteSettings]);

  return {
    isEnabled,
  };
}
