import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { BadRequest } from '@gik/api/HttpErrors';
import { login } from '@gik/api/users/auth';
import type { IUserLoginFormResponse } from '@gik/api/users/types';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import type { FormRef, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { FormError } from '@gik/ui/Form/FormError';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';
import { timeoutDefaultValue } from '@gik/core/constants';

const blockName = 'login-form';

const schema: FormSchemaEntry[] = [
  {
    placeholder: 'Email address',
    name: 'email',
    type: 'email',
    required: true,
    props: {
      variant: 'default-solid',
      autoComplete: 'username',
    },
  },
  {
    placeholder: 'Password',
    name: 'password',
    type: 'password',
    required: true,
    props: {
      variant: 'default-solid',
      autoComplete: 'current-password',
      autoFocus: true,
    },
  },
];

export interface IUserLoginFormValues {
  email?: string;
  password?: string;
  rememberMe?: boolean;
}

export interface ILoginFormProps {
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (response: IUserLoginFormResponse, values: IUserLoginFormValues) => void;
  onForgotPassword?: (initialValues: Partial<IForgotPasswordFormValues>) => void;
  onUseDifferentEmail?: () => void;
  onSignup?: () => void;
  onAttemptStart?: () => void;
  onAttemptEnd?: () => void;
  onChange?: (values: object) => void;
  hideEmailField?: boolean;
  hideSignup?: boolean;
  hidePasswordReset?: boolean;
  initialValues?: Partial<IUserLoginFormValues>;
  disableEmailField?: boolean;
  trackingId?: AnalyticsEvents;
}

const LoginFormComp = React.forwardRef<FormRef, ILoginFormProps>(function LoginFormComp(
  {
    onSuccess,
    onForgotPassword,
    onSignup,
    onChange,
    onAttemptStart,
    onAttemptEnd,
    hideEmailField = false,
    hideSignup = false,
    initialValues,
    trackingId,
    hidePasswordReset,
    disableEmailField,
    onUseDifferentEmail,
  },
  ref
) {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const formId = 'loginForm';
  const formRef = React.useRef<FormRef>();
  React.useImperativeHandle(ref, () => formRef.current);

  async function handleSubmit(values: IUserLoginFormValues) {
    onAttemptStart?.();
    setErrorMessage(null);
    try {
      const response = await login(values.email, values.password);

      const formResponse: IUserLoginFormResponse = {
        ...response,
        rememberMe: values.rememberMe,
      };
      if (onSuccess) {
        onSuccess(formResponse, values);
      }
    } catch (err) {
      switch (true) {
        case err instanceof BadRequest:
          setErrorMessage(err.message);
          break;
        default:
          setErrorMessage(err.message);
      }
    }
    onAttemptEnd?.();
  }

  function handleForgotClick() {
    onForgotPassword?.(initialValues);
  }

  function handleSignup() {
    onSignup?.();
  }

  const [wrongPassword, setWrongPassword] = React.useState(false);

  React.useEffect(() => {
    if (wrongPassword) {
      const input = document.getElementsByClassName('gik-form-group--password')[0].getElementsByTagName('input')[0];
      input.blur();
      setTimeout(() => {
        input.focus();
      }, timeoutDefaultValue);
    }
  }, [wrongPassword]);

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      initialErrors={wrongPassword ? { password: true } : {}}
      onSubmit={handleSubmit}
      onChange={onChange}
      schema={schema}
      vertical
      variant="new-default"
      className={bem()}
      id={formId}
      trackingId={trackingId}
      render={({ isSubmitting, isValid, setErrors, setTouched }) => {
        const isWrongPasswordError =
          hideSignup &&
          disableEmailField &&
          errorMessage &&
          errorMessage.toLocaleLowerCase().indexOf('invalid login attempt') > -1;

        setTimeout(() => {
          if (isWrongPasswordError)
            setTouched({ password: true }).then(() => {
              setWrongPassword(isWrongPasswordError);
              setErrors({ password: isWrongPasswordError });
            });
        }, timeoutDefaultValue);

        return (
          <>
            {(!hideSignup || !disableEmailField) &&
              errorMessage &&
              errorMessage.toLocaleLowerCase().indexOf('invalid login attempt') > -1 && (
                <FormError message={errorMessage} centered />
              )}
            <fieldset>
              {!hideEmailField && <FormField name="email" disabled={disableEmailField} />}
              <FormField name="password" />
              {isWrongPasswordError && (
                <FormError
                  className={bem('password-error')}
                  message={
                    <>
                      Incorrect password. Please try again or{' '}
                      <span onClick={handleForgotClick}>reset your password</span>.
                    </>
                  }
                  centered
                />
              )}
            </fieldset>

            <Button
              className={bem('btnSubmit')}
              variant="primary"
              type="submit"
              size="lg"
              loading={isSubmitting}
              disabled={!isValid}
              form={formId}
              fullWidth
            >
              {t(translationKeys.formSubmitButton).toString()}
            </Button>

            {!hideSignup && (
              <div className={bem('register')}>
                {t(translationKeys.formNoAccountSignup).toString()}{' '}
                <Button onClick={handleSignup} variant="primary-link">
                  {t(translationKeys.formNoAccountSignupLink).toString()}
                </Button>
              </div>
            )}

            <div className={bem('links')}>
              {!hidePasswordReset && (
                <Button variant="primary-link" onClick={handleForgotClick}>
                  {t(translationKeys.formForgotPassword).toString()}
                </Button>
              )}
              {onUseDifferentEmail && (
                <Button onClick={onUseDifferentEmail} variant={'primary-link'}>
                  {t(translationKeys.formUseDifferentEmail).toString()}
                </Button>
              )}
            </div>
          </>
        );
      }}
    />
  );
});

export const LoginForm = withComponentErrorBoundary(LoginFormComp);
