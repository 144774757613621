import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type INotInteractiveProps = UIComponent & {};

export function NotInteractive({ children, className }: React.PropsWithChildren<INotInteractiveProps>) {
  const bem = useBemCN('not-interactive');

  return <div {...bem(null, null, className)}>{children}</div>;
}
