import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { emailRecipientInfo } from '@gik/checkout/api';
import type { CheckoutFormInitiatedOnType } from '@gik/checkout/types';
import type { Product } from '@gik/core/models/gik/Product';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInApp, openExternalLink } from '@gik/core/utils/browser';
import { Button } from '@gik/ui/Button';
import { ScrollLink } from '@gik/ui/ScrollLink';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';
import DuplicateIcon from '@heroicons/react/outline/DuplicateIcon';
import ExternalLinkIcon from '@heroicons/react/solid/ExternalLinkIcon';
import MailIcon from '@heroicons/react/solid/MailIcon';
import TruckIcon from '@heroicons/react/solid/TruckIcon';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CopyInfoCard from './CopyInfoCard';
import { translationKeys } from './i18n/en';

interface IExternalProductPageProps {
  products?: Product[];
  inkindRouteId?: string;
  sourceName: string;
  externalLink: string;
  initiatedOn?: CheckoutFormInitiatedOnType;
  recipientEmail?: string;
  shippingAddressLines?: string[];
  onEmailLinkClicked?(): void;
}

export default function ExternalProductPage({
  products,
  inkindRouteId,
  sourceName,
  externalLink,
  initiatedOn,
  shippingAddressLines,
  recipientEmail,
  onEmailLinkClicked,
}: IExternalProductPageProps): React.ReactElement {
  const { t } = useTranslation();
  const hasRecipient = Boolean(shippingAddressLines || recipientEmail);
  const userStore = useUserStore();
  const userId = userStore.id;
  const bem = bemBlock(`external-product-interstitial-content`);

  const [submittingEmail, setSubmittingEmail] = React.useState<boolean>(false);

  const isInAppBrowser = isInApp();

  const handleExternalLinkClick = React.useCallback(
    async (ev: React.MouseEvent<HTMLElement>) => {
      // prevent double clicks
      if (submittingEmail) return;

      ev.preventDefault();

      setSubmittingEmail(true);

      // if this is an in-app browser and the user is logged in we will send them an email
      // see https://giveinkind.atlassian.net/browse/GIK-2071
      if (isInAppBrowser && userId) {
        try {
          await emailRecipientInfo(products[0].id, {
            userId,
            inkindRouteId,
          });
        } catch (err) {
          // TODO: gracefully handle backend error ?
          logger.error(err);
        }
      }

      Analytics.fireEvent(AnalyticsEvents.BuySelectExternal, {
        productNames: products.map(item => item.name).join(','),
        productIds: products.map(item => item.id).join(','),
        productCheckoutTypes: products?.map(item => item.checkoutType).join(','),
        inkindRouteId,
        userId, // id of the purchaser
        initiatedOn,
      });

      setSubmittingEmail(false);
      openExternalLink(externalLink);
    },
    [externalLink, initiatedOn, inkindRouteId, isInAppBrowser, products, submittingEmail, userId]
  );

  const handleCopyClicked = React.useCallback(() => {
    copy(externalLink);
    UI.notifySuccess(t(translationKeys.copied));
  }, [externalLink, t]);

  return (
    <div className={bem()}>
      <div className={bem('lead')}>
        <h1>{t(translationKeys.title)}</h1>
        <p>{t(translationKeys.source, { sourceName })}</p>
        <Button
          href={externalLink}
          onClick={handleExternalLinkClick}
          rel="noreferrer"
          target="_blank"
          variant="white"
          append={<SvgIcon Icon={ExternalLinkIcon} />}
          disabled={submittingEmail}
          size="xl"
        >
          {t(translationKeys.purchase)}
        </Button>
        {hasRecipient && (
          <ScrollLink className={bem('recipient-btn')} toId={'recipient-info'}>
            {t(translationKeys.recipientInfo)}
          </ScrollLink>
        )}
      </div>

      <div className={bem('not-working')}>
        <div>
          <p>{t(translationKeys.buttonNotWorking)}</p>
          <p>{t(translationKeys.copyPasteInfo, { sourceName })}</p>
        </div>
        <Button
          variant="primary-light"
          pill
          prepend={<SvgIcon Icon={DuplicateIcon} size="sm" />}
          size="sm"
          onClick={handleCopyClicked}
        >
          {t(translationKeys.copyLink)}
        </Button>
      </div>
      {hasRecipient && (
        <div className={bem('helpful-info')}>
          <h2>{t(translationKeys.helpfulInfo)}</h2>
          <a id="recipient-info">{/*Mobile anchor jump location*/}</a>
          {userId && isInAppBrowser && <span className={bem('email-msg')}>{t(translationKeys.emailLinkLoggedIn)}</span>}
          {!userId && isInAppBrowser && (
            <Button
              variant="primary-dark"
              pill
              prepend={<SvgIcon Icon={MailIcon} size="sm" />}
              size="sm"
              onClick={onEmailLinkClicked}
            >
              {t(translationKeys.emailLink)}
            </Button>
          )}
          <br />
          <div>
            <CopyInfoCard
              icon={TruckIcon}
              title={t(translationKeys.shippingAddress)}
              content={shippingAddressLines}
              inkindRouteId={inkindRouteId}
              recipientAddressRequired
            />

            {recipientEmail && (
              <CopyInfoCard
                icon={CreditCardIcon}
                title={t(translationKeys.recipientEmail)}
                content={[recipientEmail]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
