import { useBemCN } from '@gik/core/utils/bemBlock';
import { CopyButton } from '@gik/ui/CopyButton';
import classnames from 'classnames';
import type { Language } from 'prism-react-renderer';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/dracula';
import React from 'react';

interface CodeBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: unknown;
  wrap?: boolean;
  children?: string;
  language?: Language;
  copyButton?: boolean;
}

export function CodeBlock({
  children,
  wrap,
  className: classes,
  value,
  language = 'javascript',
  copyButton,
}: CodeBlockProps): React.ReactElement {
  const bem = useBemCN('code-block');

  const code = React.useMemo(() => {
    let strValue: string;
    if (value) strValue = JSON.stringify(value, null, 2);

    return (strValue || children || '') as string;
  }, [value, children]);

  return (
    <div {...bem(null, [{ wrap }])}>
      <Highlight {...bem('highlight')} theme={theme} {...defaultProps} code={code} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={classnames(className, classes)} style={{ ...style, padding: '20px' }}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
      {copyButton && (
        <div {...bem('copy-button')}>
          <CopyButton textToCopy={code} tooltip="Copy content" notificationText={`Value copied to clipboard`} />
        </div>
      )}
    </div>
  );
}
