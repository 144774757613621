import { AxiosError } from 'axios';
import type { AxiosResponseWithError, StrapiErrorResponse } from '../types/Response';

/**
 * Intercept thrown errors and return them as a response property instead
 */
export async function errorInterceptor(error: unknown) {
  if (error instanceof AxiosError) {
    const serverError: StrapiErrorResponse = error.response?.data;
    return { error: serverError, data: null } as AxiosResponseWithError<unknown>;
  }

  return { error, data: null };
}
