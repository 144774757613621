import { useInkind } from '@gik/api/inkinds/inkind';
import { gikClassPrefix } from '@gik/core/constants';
import { useInkindCan } from '@gik/core/store/permissions';
import { PageAvatar } from '@gik/ui/Avatar';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';

const blockName = `${gikClassPrefix}-inkind-list-item`;

export interface IInkindListItemProps {
  name: string;
  thumbnail: string;
  routeId: string;
}

export function InkindListItem({ name, thumbnail, routeId }: IInkindListItemProps): React.ReactElement {
  const { data: inkindPage } = useInkind(routeId);
  const canEdit = useInkindCan('manage', routeId, inkindPage?.groupId);

  return (
    <div className={blockName}>
      <PageAvatar
        circle={false}
        rounded
        imageUrl={thumbnail}
        showBorder={canEdit}
        variant={canEdit ? 'secondary' : 'default'}
      />
      <span>
        <HTMLParser rawHtml={name} />
      </span>
    </div>
  );
}
