import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IHoverAnimationProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const blockName = 'hover-animation';

function HoverAnimationComp({ children, className = '' }: IHoverAnimationProps) {
  const [isDown, setDown] = React.useState<boolean>(false);
  const [isHovered, setHovered] = React.useState<boolean>(false);

  const bem = bemBlock(blockName);

  const getChildren = (): JSX.Element[] => {
    if (Array.isArray(children)) {
      return children;
    } else {
      return [children];
    }
  };

  return (
    <>
      {React.Children.map(getChildren(), child => (
        <div
          className={bem(
            null,
            [
              {
                hover: isHovered,
              },
              {
                triggered: isDown,
              },
            ],
            [className]
          )}
        >
          <div className={bem('shadow')} />
          {React.cloneElement(child, {
            onMouseDown: function (e) {
              if (child.props.onMouseDown) {
                child.props.onMouseDown(e);
              }

              setDown(true);
            },

            onMouseUp: function (e) {
              if (child.props.onMouseUp) {
                child.props.onMouseUp(e);
              }

              setDown(false);
            },

            onMouseLeave: function (e) {
              if (child.props.onMouseLeave) {
                child.props.onMouseLeave(e);
              }

              setDown(false);
              setHovered(false);
            },
            onMouseEnter: function (e) {
              if (child.props.onMouseEnter) {
                child.props.onMouseEnter(e);
              }

              setHovered(true);
            },
            className: bem('target', null, child.props.className),
          })}
        </div>
      ))}
    </>
  );
}

export const HoverAnimation = React.memo(HoverAnimationComp);
