import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import React from 'react';

export type ISupportButtonProps = UIComponent & {
  href?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  variant?: 'paypal' | 'cashapp' | 'venmo';
};

export function SupportButton({ href, className, variant, icon, onClick }: ISupportButtonProps) {
  const bem = useBemCN('support-button');

  return (
    <Button
      {...bem(null, null, className)}
      block
      fullWidth
      variant={variant}
      type={'a'}
      externalLink
      href={href}
      onClick={() => onClick?.()}
      append={icon}
    >
      Donate via
    </Button>
  );
}
