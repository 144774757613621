import type { KyInterface } from '@gik/core/api/ky/types';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { Key } from 'swr';
import type { WordpressError } from '../../models/wordpress/WordpressError';
import type { APIResponseInterface, BaseAPIResponseData, DeepQueryParams, SWRConfigInterface } from '../BaseAPIConfig';
import { wpApi } from '../ky/wpApi';
import { useKySWR } from './useKySWR';

/**
 * Reusable code to create a SWPApi hook for WP endpoints
 * @param kyInstance the ky instance used in the hook
 */
export function makeWPApi(kyInstance: KyInterface) {
  /**
   * SWR Hook for querying the Give Inkind Wordpress API
   *
   * @param key    a unique key string for the request (or a function / array / null)
   * @param searchParams (optional) query search parameters
   */
  return function <D extends BaseAPIResponseData>(
    key: Key,
    searchParams?: DeepQueryParams,
    config: SWRConfigInterface = {}
  ): APIResponseInterface<D, WordpressError> {
    const useSuspense = useEnvStore(state => state.SUSPENSE_ENABLED);

    if (config?.suspense) {
      config.suspense = useSuspense === 'true';
    }

    const response = useKySWR<D, WordpressError>(kyInstance, key, searchParams, config);

    // FIXME: move to base.ts
    // handle 200 responses that are not actually OK responses
    if (response.data && response.data['statusCode'] && response.data['statusCode'] !== 200) {
      // return an error responseInterface instead

      const error: WordpressError = {
        name: 'Bad request (Custom)',
        statusCode: 430,
        message: `HTTP response is 200 but data status code is '${response.data['statusCode']}'.`,
        error: JSON.stringify(response.data),
      };

      // @ts-ignore
      return {
        data: undefined,
        error,
        mutate: response.mutate,
        isValidating: response.isValidating,
      };
    }

    return response;
  };
}

export const useWPApi = makeWPApi(wpApi as KyInterface);
