import TranslationUtils from '@gik/core/utils/TranslationUtils';

const translations = {
  required: 'The {{name}} field is required',
  requiredThis: 'This field is required',
  requiredEmail: 'The email address field is required',
  requiredPassword: 'The password field is required',
  requiredSelect: 'Please select a value',
  requiredInput: 'Please enter a value',
  minLength: 'Must be at least {{count}} characters',
  maxLength: 'Cannot be more than {{count}} characters',
  minLengthNumber: 'Must be at least {{count}} digits',
  maxLengthNumber: 'Cannot be more than {{count}} digits',
  minValue: 'This value must be at least {{count}}',
  maxValue: 'This value cannot be greater than {{count}}',
  number: 'This value must be a number',
  email: 'This is not a valid email address',
  format: 'The format is incorrect',
  url: 'This is not a valid link address',
  mustEqual: 'This value must match the {{name}} field',
  mustEqualCaseInsensitive: 'This value must match the {{name}} field',
  genericFormErrorFieldRequired: 'Please complete all fields to continue',
  genericFormErrorFieldError: 'Please fix all errors to continue',
};

export default {
  validation: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('validation', translations);
