import bemBlock from '@gik/core/utils/bemBlock';
import { Image } from '@gik/ui/Image';
import React from 'react';

export interface ISituationTileProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  image: string;
  selected?: boolean;
  horizontal?: boolean;
}

export default function SituationTile({ image, label, selected, horizontal, className }: ISituationTileProps) {
  const bem = bemBlock('situation-tile-alt');

  return (
    <div className={bem(null, [{ horizontal }, { vertical: !horizontal }, { selected }], className)}>
      <Image className={bem('image')} src={image} />
      <span className={bem('label')}>{label}</span>
    </div>
  );
}
