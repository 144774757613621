import type { UseMutationOptions } from '@tanstack/react-query';
import { usePlatformManagementClient } from '../../../contexts';
import type { PlatformManagementApiResponse, StrapiSingleDataSource } from '@gik/platform-management-api-js';
import type { OrderLogEntity } from '@gik/platform-management-api-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { buildOptions } from '../../../utils/buildOptions';

type CreatePayload = OrderLogEntity;
type UpdatePayload = Partial<OrderLogEntity>;

const uniqueName = 'orderLog';

/**
 * This hook is used to create a buyer
 */
export const useAdminOrderLogCreate = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>,
    Error,
    CreatePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_create`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>, Error, CreatePayload>(
    (payload: CreatePayload) => client.admin.orderLog.create(payload),
    buildOptions(queryClient, [key], options)
  );
};

/**
 * This hook is used to update a buyer
 */
export const useAdminOrderLogUpdate = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>,
    Error,
    UpdatePayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_update`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>, Error, UpdatePayload>(
    (payload: UpdatePayload) => client.admin.orderLog.update(payload.id.toString(), payload),
    buildOptions(queryClient, [key], options)
  );
};

/**
 * This hook is used to delete a buyer
 */
export const useAdminOrderLogDelete = (
  options?: UseMutationOptions<PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>, Error, string>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_delete`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<OrderLogEntity>>, Error, string>(
    (id: string) => client.admin.orderLog.delete(id),
    buildOptions(queryClient, [key], options)
  );
};
