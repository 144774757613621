import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import { ForgotPasswordForm } from '.';

const blockName = 'forgot-password-section';

export interface IForgotPasswordSectionProps {
  initialValues?: Partial<IForgotPasswordFormValues>;
  buttonsPortal?: () => HTMLElement;
  onSuccess?: () => void;
}

export function ForgotPasswordSection({ initialValues, buttonsPortal, onSuccess }: IForgotPasswordSectionProps) {
  const bem = bemBlock(blockName);

  return (
    <section className={bem()}>
      <ForgotPasswordForm buttonsPortal={buttonsPortal} onSuccess={onSuccess} initialValues={initialValues} />
    </section>
  );
}
