import { isBrowser } from '@/utils/environment';
import { useUser } from '@gik/api/users/user';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { sha1 } from '@gik/core/utils/sha1';
import { useRouter } from 'next/router';
import React from 'react';

export enum ActionTrackerId {
  CREATE_PAGE = 35797,
}

type IREIdentifyFunction = (event: 'identify', data: { customerId: string; customerEmail: string }) => void;
type IRETrackConversionFunction = (
  event: 'trackConversion',
  actionTrackerId: ActionTrackerId,
  data: { orderId: string; customerId: string; customerEmail: string },
  options: { verifySiteDefinitionMatch: boolean }
) => void;

type IRE = IREIdentifyFunction & IRETrackConversionFunction;
interface WindowWithImpactRadius extends Window {
  ire: IRE;
}

declare const window: WindowWithImpactRadius;

export function identify(customerId: string, customerEmail: string) {
  const useImpactRadius = useEnvStore.getState().USE_IMPACT_RADIUS === 'true';
  if (!isBrowser() || !useImpactRadius || !customerEmail) return;

  try {
    const customerEmailSha1 = sha1(customerEmail);
    window.ire?.('identify', { customerId, customerEmail: customerEmailSha1 });
  } catch (err) {
    console.error(`Failed to generate sha1 using email: ${customerEmail}`);
  }
}

export function trackConversion(
  actionTrackerId: ActionTrackerId,
  orderId: string,
  customerId: string,
  customerEmail: string
) {
  const useImpactRadius = useEnvStore.getState().USE_IMPACT_RADIUS === 'true';
  if (!isBrowser() || !useImpactRadius || !customerEmail) return;

  const customerEmailSha1 = sha1(customerEmail);
  window.ire?.(
    'trackConversion',
    actionTrackerId,
    { orderId, customerId, customerEmail: customerEmailSha1 },
    {
      verifySiteDefinitionMatch: true,
    }
  );
}

export function useImpactRadius() {
  const router = useRouter();
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const identifyFunction = React.useCallback(() => {
    if (!userId) return void identify('', '');
    if (userId && !user) return; // wait for user to load
    identify(user.id, user.emailAddress);
  }, [userId, user]);

  React.useEffect(() => {
    identifyFunction();
  }, [identifyFunction]);

  React.useEffect(() => {
    router.events.on('routeChangeComplete', identifyFunction);
    return () => {
      router.events.off('routeChangeComplete', identifyFunction);
    };
  }, [identifyFunction, router]);
}
