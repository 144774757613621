export const gikClassPrefix = 'gik';

/**
 * Specifies if the http client should fetch urls through the cache server.
 */
export const useHttpCache = true;

export const gikHasOverlayClass = `${gikClassPrefix}-has-overlay`;

// NOTE: I'm not sure the deduping interval should be this high, we might have to experiment with this.
export const SWRLongCachingOptions = {
  dedupingInterval: 20000,
  focusThrottleInterval: 30000,
};

export const defaultUserImagePath = '/core/img/default-profile-pic.jpg';
export const defaultPageImagePath = '/core/img/default-page-pic.jpg';

// TODO: use .env variable, BASE_URL should not be pointing at the API
export const fullDefaultPageImagePath = 'https://giveinkind.com' + defaultPageImagePath;

export const logFiltered: string[] = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications',
  'Route navigation aborted from',
  'Duplicate Pixel ID',
  'componentWillUpdate has been renamed',
  'You may test your Stripe.js integration over HTTP',
  'Loading chunk',
  'the given storage is currently unavailable',
  'Firing mixpanel event',
  'Legacy GTM event',
  'GA event',
  'Unexpected return value from a callback ref in form. A callback ref should not return a function',
  'You are overriding current access token',
];

export const USStates = [
  {
    abbr: 'AK',
    name: 'Alaska',
  },
  {
    abbr: 'AL',
    name: 'Alabama',
  },
  {
    abbr: 'AR',
    name: 'Arkansas',
  },
  {
    abbr: 'AS',
    name: 'American Samoa',
  },
  {
    abbr: 'AZ',
    name: 'Arizona',
  },
  {
    abbr: 'CA',
    name: 'California',
  },
  {
    abbr: 'CO',
    name: 'Colorado',
  },
  {
    abbr: 'CT',
    name: 'Connecticut',
  },
  {
    abbr: 'DC',
    name: 'D.C.',
  },
  {
    abbr: 'DE',
    name: 'Delaware',
  },
  {
    abbr: 'FL',
    name: 'Florida',
  },
  {
    abbr: 'GA',
    name: 'Georgia',
  },
  {
    abbr: 'GU',
    name: 'Guam',
  },
  {
    abbr: 'HI',
    name: 'Hawaii',
  },
  {
    abbr: 'IA',
    name: 'Iowa',
  },
  {
    abbr: 'ID',
    name: 'Idaho',
  },
  {
    abbr: 'IL',
    name: 'Illinois',
  },
  {
    abbr: 'IN',
    name: 'Indiana',
  },
  {
    abbr: 'KS',
    name: 'Kansas',
  },
  {
    abbr: 'KY',
    name: 'Kentucky',
  },
  {
    abbr: 'LA',
    name: 'Louisiana',
  },
  {
    abbr: 'MA',
    name: 'Massachusetts',
  },
  {
    abbr: 'MD',
    name: 'Maryland',
  },
  {
    abbr: 'ME',
    name: 'Maine',
  },
  {
    abbr: 'MI',
    name: 'Michigan',
  },
  {
    abbr: 'MN',
    name: 'Minnesota',
  },
  {
    abbr: 'MO',
    name: 'Missouri',
  },
  {
    abbr: 'MS',
    name: 'Mississippi',
  },
  {
    abbr: 'MT',
    name: 'Montana',
  },
  {
    abbr: 'NC',
    name: 'North Carolina',
  },
  {
    abbr: 'ND',
    name: 'North Dakota',
  },
  {
    abbr: 'NE',
    name: 'Nebraska',
  },
  {
    abbr: 'NH',
    name: 'New Hampshire',
  },
  {
    abbr: 'NJ',
    name: 'New Jersey',
  },
  {
    abbr: 'NM',
    name: 'New Mexico',
  },
  {
    abbr: 'NV',
    name: 'Nevada',
  },
  {
    abbr: 'NY',
    name: 'New York',
  },
  {
    abbr: 'OH',
    name: 'Ohio',
  },
  {
    abbr: 'OK',
    name: 'Oklahoma',
  },
  {
    abbr: 'OR',
    name: 'Oregon',
  },
  {
    abbr: 'PA',
    name: 'Pennsylvania',
  },
  {
    abbr: 'PR',
    name: 'Puerto Rico',
  },
  {
    abbr: 'RI',
    name: 'Rhode Island',
  },
  {
    abbr: 'SC',
    name: 'South Carolina',
  },
  {
    abbr: 'SD',
    name: 'South Dakota',
  },
  {
    abbr: 'TN',
    name: 'Tennessee',
  },
  {
    abbr: 'TX',
    name: 'Texas',
  },
  {
    abbr: 'UT',
    name: 'Utah',
  },
  {
    abbr: 'VA',
    name: 'Virginia',
  },
  {
    abbr: 'VT',
    name: 'Vermont',
  },
  {
    abbr: 'WA',
    name: 'Washington',
  },
  {
    abbr: 'WI',
    name: 'Wisconsin',
  },
  {
    abbr: 'WV',
    name: 'West Virginia',
  },
  {
    abbr: 'WY',
    name: 'Wyoming',
  },
];

export const timeoutDefaultValue = 100;
