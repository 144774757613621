import { useInkind } from '@gik/api/inkinds/inkind';
import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds, CalendarServiceCategoryIds } from '@gik/calendar/models/CalendarEventTypes';
import type { IProvideMyselfClaimDetails } from '@gik/calendar/models/ProvideMyselfClaimDetails';
import { useEnvStore } from '@gik/core/store/EnvStore';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormError, FormField } from '@gik/ui/Form';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import type { FormApi } from 'final-form';
import type { FormikProps } from 'formik';
import React from 'react';
import type { IClaimFormValues } from '../../CalendarClaimEvent';
import { CalendarClaimFooter } from '../../CalendarClaimFooter';
import { getCalendarClaimFormSchema } from '../schema';

export interface ProvideMyselfClaimFormProps extends Omit<FormProps, 'onSubmit'> {
  errorMessage?: React.ReactNode;

  entry?: ICalendarEntry;
  saving?: boolean;
  editEventButton?: boolean;
  unclaimButton?: boolean;
  backButton?: boolean;

  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: IClaimFormValues, form: FormApi) => void | Promise<void>;
  onBack?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onUnclaim?: () => void | Promise<void>;
  onEditEvent?: () => void | Promise<void>;
}

export interface IProvideMyselfClaimFormValues extends IClaimFormValues {}

export function ProvideMyselfClaimForm({
  className,
  errorMessage,
  entry,
  editEventButton,
  unclaimButton,
  backButton,
  saving,
  buttonsPortal,
  onBack = noop,
  onCancel = noop,
  onUnclaim = noop,
  onEditEvent = noop,
  ...otherProps
}: ProvideMyselfClaimFormProps): React.ReactElement {
  const bem = bemBlock('provide-myself-claim-form');
  const formId = 'ProvideMyselfClaimForm';

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const claim = entry.claim as IProvideMyselfClaimDetails;
  const isEditing = !!claim;

  const formSchema = React.useMemo(
    () => getCalendarClaimFormSchema(entry, CalendarServiceCategoryIds.ProvideMyself),
    [entry]
  );

  const privateClaimEnabled = useEnvStore(state => state.USE_ANONYMOUS_DONATIONS) === 'true';

  return (
    <Form
      key={JSON.stringify(saving)}
      schema={formSchema}
      initialValues={{
        carType: claim?.carType,
        description: claim?.description,
        note: claim?.note,
        privateClaim: claim?.privateClaim,
      }}
      vertical
      className={bem(null, null, className)}
      id={formId}
      restoreAfterUpdate
      {...otherProps}
      render={(form: FormikProps<object>) => {
        let _buttons;

        const fieldsDisabled = form.isSubmitting;
        const isSubmitting = form.isSubmitting || saving;

        if (isEditing) {
          _buttons = (
            <CalendarClaimFooter
              form={form}
              editEventButton={false}
              unclaimButton={unclaimButton}
              onEditEvent={onEditEvent}
              onUnclaim={onUnclaim}
              backButton={backButton}
              onBack={onBack}
            >
              <Button
                wide
                type="submit"
                key="save"
                form={formId}
                disabled={!form.isValid || isSubmitting}
                loading={isSubmitting}
                preventClickWhenDisabled
              >
                Save
              </Button>
            </CalendarClaimFooter>
          );
        } else {
          _buttons = (
            <CalendarClaimFooter onCancel={onCancel} backButton onBack={onBack}>
              <Button
                type="submit"
                wide
                form={formId}
                disabled={!form.isValid || isSubmitting}
                loading={isSubmitting}
                preventClickWhenDisabled
              >
                CONFIRM
              </Button>
            </CalendarClaimFooter>
          );
        }

        return (
          <>
            {entry.typeId === CalendarEventTypeIds.Transportation && (
              <FormField disabled={fieldsDisabled} name="carType" />
            )}
            <FormField disabled={fieldsDisabled} name="description" />

            <FormField disabled={fieldsDisabled} name="note" />

            {privateClaimEnabled && (
              <div className={bem('private-wrapper')}>
                <div>
                  <FormField disabled={fieldsDisabled} name="privateClaim" />
                </div>
                <div>
                  <Tooltip
                    allowOnMobile
                    text={'Your name will only be visible to the Page Organizer and the Recipient'}
                  >
                    <span className={'tw-text-neutral-600'}>
                      <SvgIcon Icon={InformationCircleIcon} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}

            {errorMessage && <FormError message={errorMessage} centered />}

            {renderPortal(_buttons, buttonsPortal)}
          </>
        );
      }}
    />
  );
}
