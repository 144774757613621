import { Breakpoint, useBreakpoint } from '@gik/core/hooks/CustomHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { dateDisplayFormat } from '@gik/l10n';
import ChevronLeftIcon from '@heroicons/react/outline/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import type { Moment } from 'moment';
import React from 'react';
import type { FocusedInputShape } from 'react-dates';
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';
import { SvgIcon } from '../SvgIcon/SvgIcon';

export type DateRangePickerProps = {
  className?: string;
  id?: string;
  startDate?: Moment;
  endDate?: Moment;
  allowPastDates?: boolean;
  onChange?(startDate: Moment, endDate: Moment): void;
};

export function DateRangePicker({
  className,
  id,
  startDate,
  endDate,
  allowPastDates,
  onChange = noop,
}: React.PropsWithChildren<DateRangePickerProps>) {
  const bem = bemBlock('date-range-picker');
  const [_startDate, _setStartDate] = React.useState<Moment>(startDate);
  const [_endDate, _setEndDate] = React.useState<Moment>(endDate);
  const [focused, setFocused] = React.useState<FocusedInputShape>();

  const isMdUp = useBreakpoint(Breakpoint.MD);

  function handleDatesChange({ startDate, endDate }) {
    _setStartDate(startDate);
    _setEndDate(endDate);
    onChange(startDate, endDate);
  }

  function handleFocusChange(focusedInput: FocusedInputShape) {
    setFocused(focusedInput);
  }

  return (
    <div className={bem(null, null, className)}>
      <ReactDateRangePicker
        startDate={_startDate} // momentPropTypes.momentObj or null,
        startDateId={`${id}_start_date`} // PropTypes.string.isRequired,
        endDate={_endDate} // momentPropTypes.momentObj or null,
        endDateId={`${id}_end_date`} // PropTypes.string.isRequired,
        onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
        focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        readOnly
        // id={name || `${blockName}${DatePicker.counter++}`} // PropTypes.string.isRequired,
        // date={date} // momentPropTypes.momentObj or null
        // onDateChange={handleDateChange} // PropTypes.func.isRequired
        // focused={focused} // PropTypes.bool
        onFocusChange={handleFocusChange} // PropTypes.func.isRequired
        hideKeyboardShortcutsPanel={true}
        enableOutsideDays={true}
        isOutsideRange={() => false}
        withPortal={true}
        numberOfMonths={isMdUp ? 2 : 1}
        displayFormat={dateDisplayFormat}
        navNext={<SvgIcon className="gik-datepicker-btn-next" Icon={ChevronRightIcon} />}
        navPrev={<SvgIcon className="gik-datepicker-btn-prev" Icon={ChevronLeftIcon} />}
      />
    </div>
  );
}

DateRangePicker.counter = 0;
