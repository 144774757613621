import { useInkind } from '@gik/api/inkinds/inkind';
import DoorDashButton from '@gik/calendar/components/DoorDashButton/DoorDashButton';
import type { PartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import { usePartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { getRecipientContactInformation } from '@gik/calendar/utils/getRecipientContactInformation';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { CopyAddressButton } from '@gik/ui/CopyButton';
import moment from 'moment';
import React from 'react';

type PartnerBoxInfoProps = UIComponent & {
  entry: ICalendarEntry;
  event: ICalendarEvent;
  partnerUrl: string;
  partnerName: string;
  timeline: PartnerTimeline;
};

export function PartnerInfoBox({
  className,
  entry,
  event,
  partnerUrl,
  partnerName,
  timeline,
  ...otherProps
}: PartnerBoxInfoProps): React.ReactElement {
  const bem = bemBlock('partner-info-box');

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const recipientContactInformation = getRecipientContactInformation(inkind);

  const { canOrderNow, orderTimelineText } = usePartnerTimeline(timeline, moment.utc(event.startsAt));
  const isEditing = !!entry.claim;

  const { address1, address2, cityAndState, postcode, fullName } = recipientContactInformation;
  const recipientAddressLine1 = [address1, address2].filter(v => v).join(', ');
  const recipientAddressLine2 = [cityAndState, postcode].join(' ');

  function renderOrderNow() {
    return (
      <div className={bem('order-box')}>
        <header>
          You can order from any delivery service you’d prefer, or{' '}
          <strong>order up to {orderTimelineText} in advance</strong> through our partner <strong>{partnerName}</strong>
        </header>
        <main>We’ll provide delivery address once you’re all signed up.</main>
      </div>
    );
  }

  function renderOrderLater() {
    return (
      <div className={bem('order-box')}>
        <header>
          You can order from any delivery service you’d prefer, or{' '}
          <strong>order up to {orderTimelineText} in advance</strong> through our partner <strong>{partnerName}</strong>
        </header>
        <main>We’ll remind you {orderTimelineText} prior to order, and provide delivery address after signing up.</main>
      </div>
    );
  }

  function renderEditingClaim() {
    return (
      <div className={bem('edit-claim')}>
        <p>
          <strong>Haven’t ordered yet?</strong> <br />
          Order up to {orderTimelineText} in advance of the claim date, using the provided address:
        </p>
        <address>
          <strong>{fullName}</strong>
          <br />
          {recipientAddressLine1}
          <br />
          {recipientAddressLine2}
        </address>
        <CopyAddressButton textToCopy={[recipientAddressLine1, recipientAddressLine2].join('\r\n')} />
        <br />
        <DoorDashButton url={partnerUrl} canOrderNow={canOrderNow} />
      </div>
    );
  }

  return (
    <div className={bem(null, ['provide-myself'], className)} {...otherProps}>
      {isEditing ? renderEditingClaim() : canOrderNow ? renderOrderNow() : renderOrderLater()}
    </div>
  );
}
