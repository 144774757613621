import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'reset-password';

// TODO: fill in missing translations
const translations = {
  title: 'Reset password',
  description: 'Enter a new password for your Give InKind account.',

  formSubmitButton: 'Reset',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
