import { defaultMapParameters } from '@gik/core/api/base/base';
import { searchParamsTransformKyHook } from '@gik/core/api/ky/hooks/searchParamsTransformKyHook';
import type { DeepQueryKyHeaderWPCacheOptionsKyInterface } from '@gik/core/api/ky/types';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { defaultGikApi } from './base';
import { WPURLEncoderKyHook } from './hooks/WPURLEncoderKyHook';

/**
 * Ky instance for querying the Give Inkind wordpress api
 */
export const wpApi = defaultGikApi.extend({
  prefixUrl: useEnvStore.getState().WP_API_URL,
  headers: {
    'X-Ky-Instance': 'wpApi',
  },
  hooks: {
    beforeRequest: [searchParamsTransformKyHook(defaultMapParameters), WPURLEncoderKyHook],
  },
}) as DeepQueryKyHeaderWPCacheOptionsKyInterface;

export const wpAcfV3Api = wpApi.extend({
  prefixUrl: useEnvStore.getState().WP_ACF_V3_API_URL,
  headers: {
    'X-Ky-Instance': 'wpAcfV3Api',
  },
}) as DeepQueryKyHeaderWPCacheOptionsKyInterface;
