import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'feature-steps';

// TODO: fill in missing translations
const translations = {
  // header
  createTitle: 'Create an InKind Page',
  createDescription: `<ul>
      <li>Easily create an InKind Page for yourself or someone else in just five minutes</li>
      <li>Share your story, photo, food and delivery preferences, and other important information</li>
      <li>Let people know if phone calls, texts, visitors, or flowers are ok</li>
    </ul>`,
  customizeTitle: 'Customize your InKind Page',
  customizeDescription: `<ul>
      <li>Add unlimited requests for meals, child care, gift cards, and other help — for free</li>
      <li>Build a Wishlist and connect Donation accounts to enable multiple ways to help</li>
      <li>Invite others to help co-manage your InKind Page and organize support</li>
      <li><strong><em style="color: #F58536">NEW!</em> Upgrade to a Premium Page</strong> in Page Settings for more options, and insights into your supporters.</li>
    </ul>`,
  shareTitle: 'Share your InKind Page',
  shareDescription: `<ul>
      <li>Invite friends and family from near and far to share in the support</li>
      <li>Post Updates with photos and videos to keep others up-to-date from one centralized location</li>
      <li>Receive the support that is needed from your community</li>
    </ul>`,
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
