import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import type { FormSchemaEntry } from '@gik/ui/Form';

export const pageTitleFieldSetSchema = (): FormSchemaEntry[] => [
  {
    name: 'pageTitle',
    type: 'text',
    required: true,
    minLength: 2,
    maxLength: 80,
    errorName: 'Page Title',
    placeholder: i18n.t(translationKeys.pageTitlePlaceholder),
    props: {
      maxLength: 80,
      maxLengthDisplay: true,
    },
  },
  // {
  //   name: 'groupId',
  //   type: 'select',
  //   required: false,
  //   placeholder: i18n.t(translationKeys.groupPlaceholder),
  //   props: {
  //     options: groupOptions,
  //   },
  // },
];
