import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PayPalDonationFormValueType } from '@gik/create/components/DonationProvider/DonationProvider';
import DonationProvider, { DonationProviderType } from '@gik/create/components/DonationProvider/DonationProvider';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { payPalFormSchema, paypalMeAtUsernameRegex, paypalMeUrlRegex } from '@gik/create/formSchemas';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { PayPalSupportButton } from '@gik/inkind-page/components/Support/PayPalSupportButton';
import React from 'react';

export interface IPayPalDonationProviderProps {
  value: PayPalDonationFormValueType;
  onValueChange: (value: PayPalDonationFormValueType) => void;
  asForm?: boolean;
  trackingId?: AnalyticsEvents;
  variant?: 'create' | 'page-settings';
  recalculateHeightCallback?: () => void;
}

const blockName = 'paypal-donation-provider';

export default function PayPalDonationProvider({
  value,
  onValueChange,
  asForm = false,
  trackingId,
  variant = 'create',
  recalculateHeightCallback,
}: IPayPalDonationProviderProps) {
  const bem = bemBlock(blockName);

  function handleOnValueChange(paypalFormValue: PayPalDonationFormValueType) {
    let changedValue = paypalFormValue.payPalValue;

    // automatically replace url/@username with paypal.me username
    const urlExec = paypalMeUrlRegex.exec(changedValue);
    if (urlExec?.length === 6) {
      changedValue = urlExec[5];
    }
    const atUsernameExec = paypalMeAtUsernameRegex.exec(changedValue);
    if (atUsernameExec?.length === 2) {
      changedValue = atUsernameExec[1];
    }
    paypalFormValue.payPalValue = changedValue;
    onValueChange(paypalFormValue);
  }

  return (
    <DonationProvider
      asForm={asForm}
      trackingId={trackingId}
      schema={payPalFormSchema()}
      className={bem('content')}
      provider={DonationProviderType.PayPal}
      target={i18n.t(translationKeys.PayPalInstructionsVariantCreate)}
      // description={
      //   variant === 'page-settings'
      //     ? i18n.t(translationKeys.PayPalInputDescriptionVariantPageSettings)
      //     : i18n.t(translationKeys.PayPalInputDescriptionVariantCreate)
      // }
      value={value}
      onValueChange={handleOnValueChange}
      recalculateHeightCallback={recalculateHeightCallback}
      previewComponent={_value => <PayPalSupportButton value={_value ?? value[PayPalFormInputNames.EmailAddress]} />}
    />
  );
}
