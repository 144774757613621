import bemBlock from '@gik/core/utils/bemBlock';
import type { HTMLAttributes } from 'react';
import React from 'react';

export interface IGridProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  cols?: number;
  fixed?: boolean;
}

export const Grid = React.forwardRef<HTMLDivElement, IGridProps>(function Grid(
  { cols, fixed = false, className, children, ...otherProps },
  ref
): React.ReactElement {
  const bem = bemBlock('grid');
  const blockClasses = bem(null, [{ [`${cols}-cols`]: cols !== undefined }, { fixed }], className);
  const divRef = React.useRef<HTMLDivElement>();
  React.useImperativeHandle(ref, () => divRef.current);

  return (
    <div {...otherProps} className={blockClasses} ref={divRef}>
      {children}
    </div>
  );
});
