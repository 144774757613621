import type { PaginationParams } from '@gik/api/pagination';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import { useDevStore } from '@gik/core/store/DevStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import type { ColumnWithPlugins } from '@gik/ui/Table';
import { Table } from '@gik/ui/Table';
import he from 'he';
import React from 'react';
import type { UIComponent } from '../../types/UI';

export type GroupToolsProps = {} & UIComponent;

interface WordPressGroup {
  id: string;
  slug: string;
  title: { rendered: string };
}

export function GroupTools({ className, ...otherProps }: React.PropsWithChildren<GroupToolsProps>): React.ReactElement {
  const bem = useBemCN('group-tools');

  const _columns: ColumnWithPlugins<WordPressGroup>[] = [
    {
      Header: 'Slug',
      accessor: 'slug',
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row }) => {
        return he.decode(row.original.title.rendered);
      },
    },
    {
      Header: 'Actions',
      accessor: 'id',
      id: 'actions',
      width: 80,
      disableSortBy: true,
      noPad: true,

      Cell: ({ row }) => {
        const handleSelect = React.useCallback(() => {
          useDevStore.getState().setGroupSlug(row.original.slug);
          useInkindStore.getState().setInkindGroupId(row.original.id);
        }, [row]);

        return (
          <React.Fragment key={row.id}>
            <Button variant="primary" size="xs" onClick={handleSelect}>
              View
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  function useFetch(_data: WordPressGroup[], _paginationProps: PaginationParams) {
    return useWPApi<WordPressGroup>('group' /*, { page, per_page }*/);
  }

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <Table
        variant="neutral"
        size="xs"
        columns={_columns}
        //onRowClick={handleRowClick}
        hasSortBy
        hasRemotePagination
        hasGlobalFilter
        fullWidth
        fetch={useFetch}
      />
    </div>
  );
}
