import type { InkindOrganizerSettings, Organizer } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InviteeListItem, OrganizerListItem, OwnerListItem } from './OrganizersListItem';

export const organizerListBlockName = 'organizers-list';

export interface IOrganizersListProps {
  pageOwner: Organizer;
  pageOrganizers: Organizer[];
  pageInvitees?: Organizer[];
  routeId: string;
  onRemoveOrganizer?: (userId: string) => void;
  onCancelInvitation?: (email: string) => void;
  onUpdateSettings?: (isOwner: boolean, userId: string, settings: InkindOrganizerSettings) => Promise<void>;
}

function OrganizersListComp({
  pageOwner,
  pageOrganizers,
  pageInvitees,
  routeId,
  onRemoveOrganizer,
  onCancelInvitation,
  onUpdateSettings,
  className,
}: React.PropsWithChildren<PropsWithClassName<IOrganizersListProps>>): JSX.Element {
  const bem = bemBlock(organizerListBlockName);
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)}>
      <section className={bem('header')}>
        <div />
        <div>{t(translationKeys.editLabel)}</div>
        <div>{t(translationKeys.emailLabel)}</div>
        <div />
      </section>

      <OwnerListItem
        key={pageOwner.userId}
        onRemoveOrganizer={onRemoveOrganizer}
        onCancelInvitation={onCancelInvitation}
        onUpdateSettings={onUpdateSettings}
        organizer={pageOwner}
        className={bem('owner-entry')}
        routeId={routeId}
      />

      {pageOrganizers &&
        pageOrganizers.map(organizer => (
          <OrganizerListItem
            key={organizer.userId}
            onRemoveOrganizer={onRemoveOrganizer}
            onCancelInvitation={onCancelInvitation}
            onUpdateSettings={onUpdateSettings}
            organizer={organizer}
            className={bem('confirmed-entry')}
            routeId={routeId}
          />
        ))}

      {pageInvitees &&
        pageInvitees.map(organizer => (
          <InviteeListItem
            key={organizer.email}
            onRemoveOrganizer={onRemoveOrganizer}
            onCancelInvitation={onCancelInvitation}
            onUpdateSettings={onUpdateSettings}
            organizer={organizer}
            className={bem('invite-entry')}
            routeId={routeId}
          />
        ))}
    </div>
  );
}

export const OrganizersList = withComponentErrorBoundary(OrganizersListComp);
