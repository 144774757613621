import React from 'react';

export * from './hooks/BreakpointHooks';
export * from './hooks/useIsRetina';

export const useStateWithPromise = initialState => {
  const [state, setState] = React.useState(initialState);
  const resolverRef = React.useRef(null);
  const resolverRefCurrent = resolverRef.current;

  React.useEffect(() => {
    if (resolverRefCurrent) {
      resolverRefCurrent(state);
      resolverRef.current = null;
    }
    /**
     * Since a state update could be triggered with the exact same state again,
     * it's not enough to specify state as the only dependency of this useEffect.
     * That's why resolverRef.current is also a dependency, because it will guarantee,
     * that handleSetState was called in previous render
     */
  }, [resolverRefCurrent, state]);

  const handleSetState = React.useCallback(
    stateAction => {
      setState(stateAction);
      return new Promise(resolve => {
        resolverRef.current = resolve;
      });
    },
    [setState]
  );

  return [state, handleSetState];
};
