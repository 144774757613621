import { recipientFullNameField } from '@gik/core/form/formSchemas';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import type { FormSchemaEntry } from '@gik/ui/Form';

const recipientFullNameCustomProps = {
  placeholder: 'Full Name',
} as FormSchemaEntry;

export const recipientInfoFieldSetSchema = (): FormSchemaEntry[] => [
  recipientFullNameField(recipientFullNameCustomProps),
  {
    name: 'address1',
    type: 'text',
    required: false,
    minLength: 2,
    maxLength: 255,
    placeholder: i18n.t(translationKeys.recipientAddress1Placeholder),
    props: { autoComplete: 'address-line1' },
  },
  {
    name: 'address2',
    type: 'text',
    required: false,
    minLength: 2,
    maxLength: 255,
    errorName: 'Address 2',
    placeholder: i18n.t(translationKeys.recipientAddress2Placeholder),
    props: { autoComplete: 'address-line2' },
  },
  {
    name: 'city',
    type: 'text',
    required: false,
    minLength: 2,
    maxLength: 255,
    placeholder: i18n.t(translationKeys.recipientCityPlaceholder),
    props: { autoComplete: 'address-level2' },
  },
  {
    name: 'state',
    type: 'select-us-states',
    required: false,
    placeholder: i18n.t(translationKeys.recipientStatePlaceholder),
    props: {
      autoComplete: 'address-level1',
      type: 'compact',
      isSearchable: true,
    },
  },
  {
    name: 'postalCode',
    type: 'number',
    required: false,
    width: '70px',
    minLength: 5,
    maxLength: 5,
    placeholder: i18n.t(translationKeys.recipientPostalCodePlaceholder),
    props: {
      autoComplete: 'postal-code',
      maxLength: 5,
      maxLengthDisplay: false,
    },
  },
  {
    name: 'recipientEmail',
    type: 'email',
    required: true,
    maxLength: 255,
    placeholder: i18n.t(translationKeys.recipientEmailPlaceholder),
  },
  {
    name: 'confirmRecipientEmail',
    type: 'email',
    required: true,
    mustEqualCaseInsensitive: 'recipientEmail',
    maxLength: 255,
    placeholder: i18n.t(translationKeys.recipientConfirmEmailPlaceholder),
  },
];
