import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'doordash-button';

const translations = {
  orderOnDoorDash: 'ORDER ON DOORDASH',
  browseDoorDash: 'BROWSE DOORDASH',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
