import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { RasterAttachImageResult } from '@gik/core/utils/FileUtils';
import FileUtils from '@gik/core/utils/FileUtils';
import { Button } from '@gik/ui/Button';
import { Image } from '@gik/ui/Image';
import type { IImageCropData } from '@gik/ui/ImageCropper';
import { ImageCropper } from '@gik/ui/ImageCropper';
import { VStack } from '@gik/ui/VStack/VStack';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import ExclamationCircleIcon from '@heroicons/react/outline/ExclamationCircleIcon';
import React from 'react';

export type PerfectgiftFaceplateUploaderProps = {
  fallback?: string;
  image: IImageCropData;
  rasterAttachImageResult: RasterAttachImageResult;
  onDone?: (image: IImageCropData, attachResult: RasterAttachImageResult) => void;
} & UIComponent;

export function PerfectgiftFaceplateUploader({
  children,
  fallback,
  image,
  rasterAttachImageResult,
  onDone,
  ...otherProps
}: React.PropsWithChildren<PerfectgiftFaceplateUploaderProps>): React.ReactElement {
  const bem = useBemCN('perfectgift-faceplate-uploader');

  const [attachResult, setAttachResult] = React.useState<RasterAttachImageResult>(rasterAttachImageResult);
  const [_image, setImage] = React.useState<IImageCropData>(image);
  const [loading, setLoading] = React.useState<boolean>(false);

  function handleChange(imageData: IImageCropData) {
    setImage(imageData);
  }

  async function uploadPhoto() {
    setAttachResult((await FileUtils.attachImage()) as RasterAttachImageResult);
  }

  const handleUsePhoto = React.useCallback(() => {
    onDone(_image, attachResult);
  }, [onDone, _image, attachResult]);

  React.useEffect(() => {
    if (!rasterAttachImageResult) uploadPhoto();
  }, [rasterAttachImageResult]);

  return (
    <div {...bem()}>
      <div {...bem('content-wrapper')}>
        <div {...bem('content')}>
          <ImageCropper
            aspect={1.56}
            image={attachResult}
            fallback={fallback && <Image src={fallback} />}
            onChange={handleChange}
            initialCrop={image?.crop}
            initialZoom={image?.zoom}
            initialRotation={image?.rotation}
          />
          <div>
            <p>
              Move the preview box to select your gift card background. Photos must adhere to our image guidelines
              provided by Visa or Mastercard.
            </p>
          </div>
        </div>
        <div {...bem('controls')}>
          <span {...bem('title')}>Adjust your Photo</span>

          <span {...bem('title-success')}>Your photo will be approved if it&apos;s:</span>
          <ul {...bem('list-success')}>
            <li>
              <CheckIcon width={16} /> <span>Less than 10MB</span>
            </li>
            <li>
              <CheckIcon width={16} /> <span>A personal photo that you own the rights to</span>
            </li>
          </ul>
          <br />
          <span {...bem('title-error')}>Your photo will be rejected if it contains:</span>
          <ul {...bem('list-error')}>
            <li>
              <ExclamationCircleIcon width={16} />
              <span>Company names or logos</span>
            </li>
            <li>
              <ExclamationCircleIcon width={16} />
              <span>Celebrities, athletes, cartoons</span>
            </li>
            <li>
              <ExclamationCircleIcon width={16} />
              <span>Anti-government behavior, violence, or nudity</span>
            </li>
            <li>
              <ExclamationCircleIcon width={16} />
              <span>Alcohol, tobacco, gambling, or firearms</span>
            </li>
            <li>
              <ExclamationCircleIcon width={16} />
              <span>Numbers, texts, or images that might cause confusion</span>
            </li>
          </ul>

          <VStack {...bem('toolbar')}>
            {_image && (
              <Button onClick={handleUsePhoto} loading={loading}>
                Use this photo
              </Button>
            )}
            <Button variant="default" onClick={uploadPhoto} loading={loading}>
              Upload Photo
            </Button>
          </VStack>
        </div>
      </div>
    </div>
  );
}
