import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const PageSharingOkOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M10.9789 13.8333H8.31224C7.60499 13.8333 6.92671 14.1143 6.42662 14.6144C5.92652 15.1145 5.64557 15.7928 5.64557 16.5L5.64557 25.8333C5.64557 26.5406 5.92652 27.2189 6.42662 27.719C6.92671 28.219 7.60499 28.5 8.31224 28.5L24.3122 28.5C25.0195 28.5 25.6978 28.219 26.1979 27.719C26.698 27.2189 26.9789 26.5406 26.9789 25.8333L26.9789 16.5C26.9789 15.7928 26.698 15.1145 26.1979 14.6144C25.6978 14.1143 25.0195 13.8333 24.3122 13.8333H21.6456M20.3122 8.5L16.3122 4.5M16.3122 4.5L12.3122 8.5M16.3122 4.5V21.8333"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const PageSharingOk = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99615 2C10.2614 2 10.5157 2.10536 10.7033 2.29289L13.0366 4.62623C13.4271 5.01675 13.4271 5.64992 13.0366 6.04044C12.6461 6.43096 12.0129 6.43096 11.6224 6.04044L10.9961 5.41421L10.9961 13.1111C10.9961 13.6634 10.5484 14.1111 9.99615 14.1111C9.44386 14.1111 8.99615 13.6634 8.99615 13.1111L8.99615 5.41421L8.36992 6.04044C7.9794 6.43096 7.34623 6.43096 6.95571 6.04044C6.56518 5.64992 6.56518 5.01675 6.95571 4.62623L9.28904 2.29289C9.47658 2.10536 9.73093 2 9.99615 2ZM3.52243 8.76292C4.00169 8.28366 4.65171 8.01441 5.32948 8.01441H6.88504C7.43732 8.01441 7.88504 8.46213 7.88504 9.01441C7.88504 9.5667 7.43732 10.0144 6.88504 10.0144H5.32948C5.18214 10.0144 5.04083 10.0729 4.93664 10.1771C4.83246 10.2813 4.77393 10.4226 4.77393 10.57L4.77393 15.4444C4.77393 15.5918 4.83246 15.7331 4.93664 15.8373C5.04083 15.9415 5.18214 16 5.32948 16L14.6628 16C14.8102 16 14.9515 15.9415 15.0557 15.8373C15.1598 15.7331 15.2184 15.5918 15.2184 15.4444V10.57C15.2184 10.4226 15.1598 10.2813 15.0557 10.1771C14.9515 10.0729 14.8102 10.0144 14.6628 10.0144H13.1073C12.555 10.0144 12.1073 9.5667 12.1073 9.01441C12.1073 8.46213 12.555 8.01441 13.1073 8.01441L14.6628 8.01441C15.3406 8.01441 15.9906 8.28366 16.4699 8.76292C16.9491 9.24218 17.2184 9.89219 17.2184 10.57V15.4444C17.2184 16.1222 16.9491 16.7722 16.4699 17.2515C15.9906 17.7308 15.3406 18 14.6628 18H5.32948C4.65171 18 4.00169 17.7308 3.52243 17.2515C3.04317 16.7722 2.77393 16.1222 2.77393 15.4444L2.77393 10.57C2.77393 9.89219 3.04317 9.24218 3.52243 8.76292Z"
          fill="#7BAA52"
        />
      </svg>
    );
  }
);
