import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const TextMessagesDisabledOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M11.6645 13.8333H11.6778M16.9978 13.8333H17.0111M22.3311 13.8333H22.3445M12.9978 21.8333H7.66447C6.95723 21.8333 6.27895 21.5523 5.77885 21.0522C5.27875 20.5521 4.9978 19.8738 4.9978 19.1666V8.49992C4.9978 7.79267 5.27875 7.1144 5.77885 6.6143C6.27895 6.1142 6.95723 5.83325 7.66447 5.83325H26.3311C27.0384 5.83325 27.7167 6.1142 28.2168 6.6143C28.7169 7.1144 28.9978 7.79267 28.9978 8.49992V19.1666C28.9978 19.8738 28.7169 20.5521 28.2168 21.0522C27.7167 21.5523 27.0384 21.8333 26.3311 21.8333H19.6645L12.9978 28.4999V21.8333Z"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M28.9978 4.5L4.9978 28.5" stroke="#EF5C84" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  }
);

export const TextMessagesDisabled = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 5V13C18 13.5304 17.7893 14.0391 17.4142 14.4142C17.0391 14.7893 16.5304 15 16 15H11L6 19V15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5ZM7 8H5V10H7V8ZM9 8H11V10H9V8ZM15 8H13V10H15V8Z"
            fill="#9EABAD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7071 0.292893C20.0976 0.683417 20.0976 1.31658 19.7071 1.70711L1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L18.2929 0.292893C18.6834 -0.0976311 19.3166 -0.0976311 19.7071 0.292893Z"
            fill="#EF5C84"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);
