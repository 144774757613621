import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import { PhoneCallsDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/PhoneCallsDisabled';
import { PhoneCallsOkOutline } from '@gik/ui/SvgIcon/GikIcons/PhoneCallsOk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import type { IAlternatePhoneNumberValues } from '../AlternatePhoneNumber/AlternatePhoneNumberForm';
import { openAlternatePhoneNumberModal } from '../AlternatePhoneNumber/AlternatePhoneNumberModalContent';
import type { IPrivacySettingsCardProps } from '../PrivacySettingsCard/PrivacySettingsCard';
import { PrivacySettingsCard } from '../PrivacySettingsCard/PrivacySettingsCard';

export interface IPhoneCallsCardProps extends Pick<IPrivacySettingsCardProps, 'value' | 'onChange' | 'inputName'> {
  onSubmitAlternateNumber?: (values: IAlternatePhoneNumberValues) => void;
  onRemoveAlternateNumber?: () => void;
  initialValues?: IAlternatePhoneNumberValues;
}

export const phoneCallsCardBlockName = 'phone-calls-card';

export const PhoneCallsCard: React.FC<PropsWithClassName<IPhoneCallsCardProps>> = ({
  className,
  value,
  onChange,
  inputName,
  onSubmitAlternateNumber,
  onRemoveAlternateNumber,
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation();
  const bem = bemBlock(phoneCallsCardBlockName);

  const [isClicked, setIsClicked] = React.useState<boolean>(false);

  // better to do this in effect than in handler
  React.useEffect(() => {
    if (isClicked) {
      if (!value) {
        onSubmitAlternateNumber && openAlternatePhoneNumberModal({ onSubmit: onSubmitAlternateNumber, initialValues });
      }
      setIsClicked(false);
    }
    // intentionally, don't pass other dependencies, it will trigger modal more than once
    // eslint-disable-next-line
  }, [isClicked, value]);

  const handleSubmitAlternateNumber = () => {
    if (onSubmitAlternateNumber) {
      openAlternatePhoneNumberModal({ onSubmit: onSubmitAlternateNumber, initialValues });
    }
  };

  const handleChange = (isToggled: boolean) => {
    setIsClicked(true);
    // don't call same setState 2 times in same handler
    if (onChange) {
      onChange(isToggled);
    }
  };

  const note = (
    <>
      {initialValues?.alternateNumber?.length > 0 ? (
        <>
          <span>{`${t(translationKeys.altNumber)} `}</span>
          <NumberFormat format="###.###.####" value={initialValues.alternateNumber} displayType="text" />
          <Button onClick={onRemoveAlternateNumber} variant="primary-link" className={bem('remove-link')}>
            {t(translationKeys.remove).toString()}
          </Button>
        </>
      ) : (
        <Button onClick={handleSubmitAlternateNumber} variant="primary-link">
          {t(translationKeys.includeAlternateNumber).toString()}
        </Button>
      )}
    </>
  );

  return (
    <PrivacySettingsCard
      className={bem(null, null, className)}
      title={t(translationKeys.phoneCallsTitle)}
      icon={value ? PhoneCallsOkOutline : PhoneCallsDisabledOutline}
      description={
        value
          ? t(translationKeys.phoneCallsDescription).toString()
          : t(translationKeys.phoneCallsDescriptionDisabled).toString()
      }
      value={value}
      onChange={handleChange}
      inputName={inputName}
      note={!value && note}
    />
  );
};
