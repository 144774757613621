import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-empty-view';

const translations = {
  UpcomingEventsTitle: 'This calendar has requests\non future months',
  UpcomingEventsText: 'Click below to go to the next\navailable request',

  PreviousEventsTitle: 'This calendar has requests\non previous months',
  PreviousEventsText: 'Click below to go to the next\navailable request',

  goToRequests: 'GO TO REQUESTS',
  viewPastRequests: 'VIEW PAST REQUESTS',

  noUpcomingTitle: 'This calendar has no upcoming requests',
  noUpcomingText: 'Follow the page to get notified when\nnew requests are added',
  noUpcomingTextFollowing: 'Since you follow this page, you will be notified when new requests are added.',

  OrganizerViewLeadText: 'Only you as an <mark>organizer</mark> can\nadd requests to this calendar.',
  OrganizerViewText: 'Add as many requests as you want to be\nclaimed by your supporters.',
  OrganizerViewFooter: 'You can turn this calendar off in your page settings above.',
  NoRequestsText: 'This calendar has no requests yet',
  NoRequestsText2: 'Follow the page to get notified\nwhen new requests are added',
  NoRequestsText2Following: 'Since you follow this page, you will be notified when new requests are added.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
