import { isBrowser } from '@/utils/environment';

export interface IScrollIntoViewOptions {
  /**
   * offset-y in px
   */
  offset?: number;
  block?: string;
  behavior?: string;
  /**
   * Container element that has scroll
   */
  container?: HTMLElement;
  useBoundingRect?: boolean;
}

export function getClientOffset(el, stopEl) {
  /// tail-called optimize
  function getOffset(el, stopEl, left = 0, top = 0) {
    if (el === stopEl) {
      return { left, top };
    }
    return getOffset(el.offsetParent, stopEl, left + el.offsetLeft, top + el.offsetTop);
  }
  return getOffset(el, stopEl);
}

/**
 * Same as Javascript scrollIntoView() but with custom offset and container. Only vertical scroll.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTo
 * @param element Element to scroll into view
 * @param options.offset Additional offset-y in px to scroll, default 0
 * @param options.container Custom scroll container, default html tag
 */
export const scrollIntoView = (element: HTMLElement, options?: IScrollIntoViewOptions): void => {
  if (!isBrowser()) return;

  const container = options?.container || document.documentElement;
  const offset = options?.offset || 0;

  if (options?.useBoundingRect) {
    const rect = getClientOffset(element, document.body);
    const navbar = document.querySelector('.gik-app-header-nav-bar') as HTMLElement;
    container.scrollTo({
      top: rect.top - (navbar?.offsetHeight || 0) + offset,
      left: 0,
      behavior: 'smooth',
    });
    return;
  }

  // NOTE: this will only work if element and container have common offsetParent (element with position !== static)
  let offsetYPosition = element.offsetTop - (container?.offsetTop || 0) + offset;
  const offsetXPosition = 0;

  if (options?.block === 'end') {
    // anchor item to bottom of the contianer
    offsetYPosition = offsetYPosition - (container?.offsetHeight || window.innerHeight) + element.offsetHeight + 10;
  }

  if (container) {
    container.scrollTo({
      top: offsetYPosition,
      left: offsetXPosition,
      behavior: (options.behavior as ScrollBehavior) ?? 'smooth',
    });
  }
};
