export type {
  HeadingUIVariant,
  HTMLButtonType,
  IconSize,
  PremiumVariant,
  UIBoxDecoration,
  UIBoxVariant,
  UIButtonSpecialVariants,
  UIButtonType,
  UIButtonVariant,
  UICheckboxVariant,
  UIContextVariant,
  UIGradient,
  UIInputVariant,
  UINotificationVariant,
  UISectionDecoration,
  UISelectVariant,
  UISize,
  UISwitchVariant,
  UIVariant,
} from './typesValues';

// TODO: the home page also use this new color coding for features but this was setup manually
// TODO: make it use the same syntax os the other types
export enum UIFeaturesType {
  CareCalendar = 'primary',
  Wishlist = 'danger',
  Donations = 'success',
  Updates = 'secondary',
}
