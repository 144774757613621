import type { FormSchemaEntry } from '@gik/ui/Form';

export type LastNameFieldProps = {
  readOnly: boolean;
  required?: boolean;
};

export const LastNameField = (props?: LastNameFieldProps): FormSchemaEntry => ({
  label: 'Last name',
  name: 'lastName',
  type: props?.readOnly ? 'plaintext' : 'text',
  required: props?.required,
  minLength: 2,
  maxLength: 255,
  props: { autoComplete: 'family-name', centerVertical: props?.readOnly },
});
