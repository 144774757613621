import { ReasonPhrases, StatusCodes } from 'http-status-codes';
import { CustomError } from './CustomError';

/**
 * A collection of throwable Http Errors
 */

/**
 * Error 400: bad request
 */
export class BadRequest extends CustomError {
  constructor(message = ReasonPhrases.BAD_REQUEST as string, data?: object) {
    super(StatusCodes.BAD_REQUEST, message, data);
  }
}

/**
 * Error 404: not found
 */
export class NotFound extends CustomError {
  constructor(message = ReasonPhrases.NOT_FOUND as string, data?: object) {
    super(StatusCodes.NOT_FOUND, message, data);
  }
}

/**
 * Error 500: internal server error
 */
export class InternalServerError extends CustomError {
  constructor(message = ReasonPhrases.INTERNAL_SERVER_ERROR as string, data?: object) {
    super(StatusCodes.INTERNAL_SERVER_ERROR, message, data);
  }
}

/**
 * Error 403: Forbidden
 */
export class ForbiddenError extends CustomError {
  constructor(message = ReasonPhrases.FORBIDDEN as string, data?: object) {
    super(StatusCodes.FORBIDDEN, message, data);
  }
}

/**
 * Error 403: Forbidden
 */
export class UnhandledError extends CustomError {
  constructor(message = 'Unhandled Error', data?: object) {
    super(StatusCodes.FORBIDDEN, message, data);
  }
}
