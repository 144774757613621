import { useInkind, useInkindOrganizers } from '@gik/api/inkinds/inkind';
import type { Organizer, OrganizerDetail } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import { Separator } from '@gik/ui/Separator';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { translationKeys } from './i18n/en';
import { PageOrganizerDisplay } from './PageOrganizerDisplay';

export interface IContactPageOrganizersProps {
  inkindRouteId: string;
}

export function ContactPageOrganizers({ inkindRouteId }: IContactPageOrganizersProps): React.ReactElement {
  const { data: inkindPage } = useInkind(inkindRouteId);
  const { data: organizerDetails } = useInkindOrganizers(inkindRouteId);

  const ownerAndOrganizers = React.useMemo(
    () => [inkindPage?.owner, ...(inkindPage?.organizers ?? [])].filter(v => v),
    [inkindPage]
  );

  return <ContactPageOrganizersContent organizers={ownerAndOrganizers} organizerDetails={organizerDetails} />;
}

export type IContactPageOrganizersContentProps = {
  organizers: Organizer[];
  organizerDetails?: OrganizerDetail[];
};
export function ContactPageOrganizersContent({ organizers, organizerDetails }: IContactPageOrganizersContentProps) {
  const bem = bemBlock('contact-page-organizers');

  return (
    <div className={bem('content')}>
      {organizers.map((organizer, index) => {
        return (
          <>
            <PageOrganizerDisplay
              userId={organizer.userId}
              isOwner={organizer.isOwner}
              organizerDetail={organizerDetails?.find(org => org.userId === organizer.userId)}
            />
            {index < organizers.length - 1 && <Separator variant="default" size="sm" />}
          </>
        );
      })}
    </div>
  );
}

export function openContactPageOrganizersModal(inkindRouteId: string): void {
  const bem = bemBlock('contact-page-organizers-modal');

  UI.dialog(() => <ContactPageOrganizers inkindRouteId={inkindRouteId} />, {
    title: i18n.t(translationKeys.modalTitle),
    padding: false,
    closable: true,
    centeredTitle: false,
    okText: i18n.t(translationKeys.okText),
  });
}
