import React from 'react';

export const useDelayedRender = (delay: number) => {
  const [delayed, setDelayed] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, []);
  return (fn: Function) => !delayed && fn();
};

export const DelayedRenderComp = ({ delay, children }) => useDelayedRender(delay)(() => children);
export const DelayedRender = React.memo(DelayedRenderComp);
