import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { FlowersDisabledOutline } from '@gik/ui/SvgIcon/GikIcons/FlowersDisabled';
import { FlowersOkOutline } from '@gik/ui/SvgIcon/GikIcons/FlowersOk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IGenericCardProps } from '../PrivacySettingsCard/PrivacySettingsCard';
import { PrivacySettingsCard } from '../PrivacySettingsCard/PrivacySettingsCard';

export const flowersCardBlockName = 'flowers-card';

export const FlowersCard: React.FC<PropsWithClassName<IGenericCardProps>> = ({
  className,
  value,
  onChange,
  inputName,
}): JSX.Element => {
  const { t } = useTranslation();
  const bem = bemBlock(flowersCardBlockName);

  return (
    <PrivacySettingsCard
      className={bem(null, null, className)}
      title={t(translationKeys.flowersTitle)}
      icon={value ? FlowersOkOutline : FlowersDisabledOutline}
      description={
        value
          ? t(translationKeys.flowersDescription).toString()
          : t(translationKeys.flowersDescriptionDisabled).toString()
      }
      value={value}
      onChange={onChange}
      inputName={inputName}
    />
  );
};
