import { Analytics } from '@gik/analytics';
import { logger } from '@gik/analytics/utils/logger';
import { useDevStore } from '@gik/core/store/DevStore';
import React from 'react';

export type ErrorFallbackProps = {
  error?: Error;
  errors?: Error[];
  maxHeight?: number;
};

export type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallback: React.FC<ErrorFallbackProps>;
  maxHeight?: number;
  noForce?: boolean;
};

export type ErrorBoundaryInfo = {
  componentStack: string;
};

export type ErrorBoundaryState = {
  // hasError: boolean;
  error: Error;
  errorInfo: ErrorBoundaryInfo;
};

/**
 * Error Boundary component
 *
 * see: https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private errorNoticeRef = React.createRef<boolean>() as React.MutableRefObject<boolean>;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorBoundaryInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    logger.error(error.stack);
  }

  render() {
    if (this.state.error || (useDevStore.getState().forceErrorBoundary && !this.props.noForce)) {
      // You can render any custom fallback UI
      const Comp = this.props.fallback;
      const props = { maxHeight: this.props.maxHeight, errors: [this.state.error] };

      if (!this.errorNoticeRef.current) {
        this.errorNoticeRef.current = true;
        Analytics.fireUserNotice(props.errors?.[0]?.message, 'errorBoundary', { component: this.props.children });
      }
      return <Comp {...props} error={props.errors?.[0]} />;
    }

    return this.props.children;
  }
}
