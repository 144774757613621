import bemBlock from '@gik/core/utils/bemBlock';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import type { FormProps, FormSchemaEntry, ValidationError } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import type { IRecipientAddressFormValues } from '@gik/ui/gik/RecipientAddressForm';
import { RecipientAddressForm, recipientAddressFormSchema } from '@gik/ui/gik/RecipientAddressForm';
import type { FormikProps } from 'formik';
import React from 'react';

export interface IRecipientInfoAddressFormProps extends Omit<FormProps, 'onSubmit' | 'onChange'> {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: IRecipientInfoAddressFormValues, form: FormikProps<object>) => void;
  onChange?: (values: IRecipientInfoAddressFormValues, form: FormikProps<object>) => void;
  form?: FormikProps<object>;
  values?: IRecipientInfoAddressFormValues;
  isMyself: boolean;
}

export interface IRecipientInfoAddressFormValues extends IRecipientAddressFormValues {
  includeRecipientAddress: boolean;
}

export interface IRecipientInfoAddressFormErrors {
  includeRecipientAddress?: ValidationError;
}

export enum recipientInfoAddressInputNames {
  IncludeRecipientAddress = 'includeRecipientAddress',
}

export const recipientAddressStepFormSchema = (isMyself: boolean): FormSchemaEntry[] => [
  {
    type: 'checkbox',
    name: recipientInfoAddressInputNames.IncludeRecipientAddress,
    props: {
      label: (
        <div>
          <span>
            {i18n
              .t(isMyself ? translationKeys.IncludeYourAddressLabel : translationKeys.IncludeRecipientAddressLabel)
              .toString()}
          </span>
          <br />
          <span>{i18n.t(translationKeys.IncludeRecipientAddressSublabel).toString()}</span>
        </div>
      ),
    },
  },
];

export const recipientInfoAddressFormSchema = (isMyself: boolean, enableRecipientAddress: boolean): FormSchemaEntry[] =>
  recipientAddressStepFormSchema(isMyself).concat(recipientAddressFormSchema(enableRecipientAddress));

const blockName = 'recipient-info-address-form';

export const formId = 'CreateRecipientInfoStepForm';

function RecipientInfoAddressForm(
  {
    className,
    form,
    onSubmit,
    onChange,
    values,
    buttons,
    buttonsPortal,
    isMyself,
    ...otherProps
  }: IRecipientInfoAddressFormProps,
  ref: React.Ref<unknown>
) {
  const bem = bemBlock(blockName);

  const [_values, _setValues] = React.useState<IRecipientInfoAddressFormValues>();

  let formRenderProps: FormikProps<object>;
  let submitForm: Function;

  React.useImperativeHandle(ref, () => ({
    submit(event) {
      submitForm(event);
    },
  }));

  function handleSubmit(values: IRecipientInfoAddressFormValues) {
    onSubmit?.(values, formRenderProps);
  }

  function handleChange(values: IRecipientInfoAddressFormValues) {
    _setValues(values);
    onChange?.(values, formRenderProps);
  }

  const fields = (form: FormikProps<object>, values: IRecipientInfoAddressFormValues) => {
    return (
      <div className="gik-form">
        <FormField name={recipientInfoAddressInputNames.IncludeRecipientAddress} inline />

        {values?.includeRecipientAddress && <RecipientAddressForm form={form} required={false} />}

        <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
      </div>
    );
  };

  if (form) {
    return fields(form, values);
  }

  return (
    <Form
      className={bem(null, null, [className, 'gik-form--white'])}
      schema={recipientAddressStepFormSchema(isMyself)}
      onSubmit={handleSubmit}
      onChange={handleChange}
      vertical
      id={formId}
      render={(form: FormikProps<object>) => {
        submitForm = form.handleSubmit;
        return fields(form, _values);
      }}
      {...otherProps}
    />
  );
}

export default React.forwardRef(RecipientInfoAddressForm);
