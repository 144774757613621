import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'gik-sample-pages';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'Sample Pages for...',

  // Situations
  birth: 'Birth',
  medical: 'Medial',
  grief_loss: 'Grief & Loss',
  general_support: 'General Support',
  navMore: '...And more',

  empty: 'No situations were found.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
