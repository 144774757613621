import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type VStackProps = {} & UIComponent;

export function VStack({
  children,
  className,
  ...otherProps
}: React.PropsWithChildren<VStackProps>): React.ReactElement {
  const bem = useBemCN('vstack');

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      {children}
    </div>
  );
}
