import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type TextEditableProps = {
  /**
   * Force display of focus state
   */
  focus?: boolean;

  /**
   * Auto focus the input field when the component is mounted
   */
  autoFocus?: boolean;

  /**
   * Put button in disabled mode
   */
  disabled?: boolean;

  /**
   * Value of the input field
   */
  value?: string;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * display the component in an error state
   */
  hasError?: boolean;

  /**
   * display the component in a warning state
   */
  hasWarning?: boolean;

  /**
   * Called when the value changes.
   * This differs from the standard onChange callback for form elements.
   * It will not fire when the component is in a disabled state and it
   * will return the value as a string (instead of an entire onChange Event)
   */
  onChange?: (value: string) => void;
} & React.HTMLAttributes<HTMLDivElement>;

function TextEditableComp(
  {
    value,
    className,
    tabIndex,
    disabled,
    focus,
    autoFocus,
    hasError,
    hasWarning,
    onFocus,
    onBlur,
    onChange,
    ...otherProps
  }: TextEditableProps,
  ref: React.LegacyRef<HTMLDivElement>
): React.ReactElement {
  const bem = useBemCN('text-editable');

  const [focused, setFocused] = React.useState(false);
  const [finalTabIndex, setTabIndex] = React.useState(tabIndex);

  let inputRef: HTMLDivElement;

  const handleInput = React.useCallback(
    e => {
      const el = e.target as HTMLDivElement;
      onChange?.(el.innerHTML);
    },
    [onChange]
  );

  React.useEffect(() => {
    inputRef.addEventListener('input', handleInput);
    return () => {
      inputRef.removeEventListener('input', handleInput);
    };
  }, [inputRef, handleInput]);

  React.useEffect(() => {
    if (autoFocus) inputRef.focus();
  }, [autoFocus, inputRef]);

  React.useEffect(() => {
    // force tabIndex to be -1 if component is in disabled state
    if (disabled) {
      setTabIndex(-1);
    } else {
      setTabIndex(tabIndex);
    }
  }, [disabled, tabIndex]);

  function handleChange(ev: React.ChangeEvent<HTMLDivElement>) {
    // don't do anything if disabled
    if (disabled) return;

    // if (onChange) onChange(ev.target.value);
  }

  function handleFocus(ev: React.FocusEvent<HTMLDivElement>): void {
    // disabled components should not receive focus
    // note: onFocus should never be called in the first place
    // buttons in these states should have a tabIndex of -1
    if (disabled) return;

    setFocused(true);
    if (onFocus) onFocus(ev);
  }

  function handleBlur(ev: React.FocusEvent<HTMLDivElement>): void {
    setFocused(false);
    if (onBlur) onBlur(ev);
  }

  const inputProps = {
    ref: (_ref: HTMLDivElement) => {
      if (!_ref) return null;
      inputRef = _ref;
      return ref as React.LegacyRef<HTMLDivElement>;
    },
    contentEditable: !disabled,
    disabled,
    ...otherProps,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    tabIndex: finalTabIndex,
  };

  return (
    <div {...bem(null, [{ disabled }, { focus }], className)} {...inputProps}>
      {value}
    </div>
  );
}

export const TextEditable = React.forwardRef(TextEditableComp);
