import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ICalendarServicesFormValues } from '../EventCreateForm/CalendarEventServicesForm';
import { CalendarServicesForm } from '../EventCreateForm/CalendarEventServicesForm';
import { CalendarEventTypeSelectList } from '../EventTypes';
import { translationKeys } from './i18n/en';

export interface ICalendarEventTypeSelectFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSubmit'> {
  eventTypeId?: number;
  hideAnnouncement?: boolean;
  onSubmit?: (eventTypeId: number, serviceIds: number[]) => void;
  buttonsPortal?: () => HTMLElement;
}

export default function CalendarEventTypeSelectForm({
  className,
  eventTypeId,
  hideAnnouncement,
  onSubmit = noop,
  buttonsPortal,
  ...otherProps
}: ICalendarEventTypeSelectFormProps): React.ReactElement {
  const bem = bemBlock('event-type-form');
  const { t } = useTranslation();

  const [selectedEventTypeId, setSelectedEventTypeId] = React.useState<number>(eventTypeId);
  const { data: eventTypes } = useCalendarEventTypes();
  const eventType = eventTypes.find(item => item.id === selectedEventTypeId);

  function onEventTypeChange(value: number) {
    setSelectedEventTypeId(value);
  }

  function handleBack() {
    setSelectedEventTypeId(undefined);
  }

  function onSubmitServicesForm(values: ICalendarServicesFormValues) {
    onSubmit(selectedEventTypeId, values.allowedServiceIds);
  }

  return (
    <div className={bem(null, null, [className])} {...otherProps}>
      {!selectedEventTypeId && (
        <>
          <span className={bem('title', null, 'gik-calendar-modal-section-title')}>
            {t(translationKeys.eventTypeSelectTitle)}
          </span>
          <CalendarEventTypeSelectList
            hideAnnouncement={hideAnnouncement}
            value={selectedEventTypeId || eventTypeId}
            onChange={value => onEventTypeChange(value)}
          />
        </>
      )}
      {selectedEventTypeId && (
        <>
          <CalendarServicesForm
            eventTypeId={selectedEventTypeId}
            eventTypeName={eventType?.name}
            onSubmit={onSubmitServicesForm}
            buttonsPortal={buttonsPortal}
            buttons={({ submitting, valid }, formId: string) => (
              <div className={'gik-drawer-actions'}>
                <section>
                  <Button variant={'default-light'} circle>
                    <SvgIcon Icon={ChevronLeftIcon} onClick={handleBack} />
                  </Button>
                </section>
                <section>
                  <Button type="submit" form={formId} disabled={!valid} loading={submitting}>
                    {t(commonTranslationKeys.save)}
                  </Button>
                </section>
              </div>
            )}
          />
        </>
      )}
    </div>
  );
}
