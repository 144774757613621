import Link from '@/components/Link';
import { gikClassPrefix } from '@gik/core/constants';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Decoder } from '@gik/ui/Decoder';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { AnimatedDivBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';

export type CartProductTileDirection = 'row' | 'column';

export interface ICartProductTileProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  imageUrl?: string;
  toolbar?: React.ReactNode;
  containImage?: boolean;
  direction?: CartProductTileDirection;
  title?: string;
  href?: string;
  hoverEffect?: boolean;
  selectable?: boolean;
  selected?: boolean;
  skeleton?: boolean;
  description?: string;
}

function CartProductTileComp({
  className,
  imageUrl,
  toolbar,
  containImage,
  selected,
  selectable,
  direction = 'column',
  title,
  href,
  hoverEffect = true,
  skeleton,
  description,
  ...otherProps
}: ICartProductTileProps): React.ReactElement {
  const [mouseHovering, setMouseHovering] = React.useState<boolean>(false);

  const bem = useBemCN('cart-product-tile');

  function onMouseEnter() {
    setMouseHovering(true);
  }

  function onMouseLeave() {
    setMouseHovering(false);
  }

  const image = (
    <>
      {skeleton ? (
        <AnimatedDivBone className={`${gikClassPrefix}-bg-image`} />
      ) : (
        <>
          {!containImage ? (
            <Image src={imageUrl} width={300} height={300} objectFit="cover" />
          ) : (
            <figure>
              <Image src={imageUrl} />
            </figure>
          )}
        </>
      )}
    </>
  );

  const tile = (
    <div
      {...bem(
        null,
        [
          { row: direction === 'row' },
          { col: direction === 'column' },
          { selected },
          { selectable },
          { hoverEffect },
          { 'shadow-border': true },
          // { hover: mouseHovering },
          { skeleton },
          { description: description?.length > 0 },
        ],
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...otherProps}
    >
      <div {...bem('wrapper')}>
        <div {...bem('box-shadow')} />
        {toolbar && <div {...bem('toolbar')}>{toolbar}</div>}
        {href ? (
          <div {...bem('image-wrapper')}>
            <a {...bem('link')}>{image}</a>
          </div>
        ) : (
          <div {...bem('image-wrapper')}>{image}</div>
        )}
        <main>
          {title && (
            <div {...bem('title')}>
              <Decoder text={title} />
            </div>
          )}
          {skeleton && (
            <div {...bem('title')}>
              <ParagraphBone words={3} />
            </div>
          )}
          {description && (
            <a {...bem('description')}>
              {skeleton ? (
                <ParagraphBone words={12} />
              ) : (
                <p>
                  <HTMLParser rawHtml={description} />
                </p>
              )}
            </a>
          )}
        </main>
      </div>
    </div>
  );

  if (href) {
    return <Link href={href}>{tile}</Link>;
  }

  return tile;
}

export const CartProductTile = React.memo(CartProductTileComp, (prevProps, nextProps) => {
  return (
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.toolbar === nextProps.toolbar &&
    prevProps.direction === nextProps.direction &&
    prevProps.description === nextProps.description &&
    prevProps.selected === nextProps.selected
  );
});
