import bemBlock from '@gik/core/utils/bemBlock';
import type { UISize, UIVariant } from '@gik/ui/types';
import React from 'react';

export enum DeterminationType {
  Determinate = 'determinate',
  Indeterminate = 'indeterminate',
}

interface IBaseLoadingLinearProps {
  variant?: UIVariant;
  className?: string;
  type?: DeterminationType;
  size?: UISize;
}

interface IIndeterminateLoadingLinearProps extends IBaseLoadingLinearProps {
  type?: DeterminationType.Indeterminate;
}

interface IDeterminateLoadingLinearProps extends IBaseLoadingLinearProps {
  type?: DeterminationType.Determinate;
  percentComplete: number;
}

export type ILoadingLinearProps = IIndeterminateLoadingLinearProps | IDeterminateLoadingLinearProps;

const blockName = 'loading-linear';

export function LoadingLinear(props: IIndeterminateLoadingLinearProps);
export function LoadingLinear(props: IDeterminateLoadingLinearProps);
export function LoadingLinear(props: ILoadingLinearProps) {
  const {
    variant = 'primary',
    className,
    type = DeterminationType.Indeterminate,
    size = 'base',
  } = props as ILoadingLinearProps;
  const { percentComplete } = props as IDeterminateLoadingLinearProps;
  const bem = bemBlock(blockName);

  const baseBem = bem(
    null,
    [
      {
        [type]: true,
      },
      {
        [variant]: true,
      },
      {
        [size]: true,
      },
    ],
    className
  );

  switch (type) {
    case DeterminationType.Indeterminate:
      return (
        <div className={baseBem}>
          <div className={bem('container')}>
            <div className={bem('bar', [{ bar1: true }])} />
            <div className={bem('bar', [{ bar2: true }])} />
          </div>
        </div>
      );
    case DeterminationType.Determinate:
      return (
        <div className={baseBem}>
          <div className={bem('container')}>
            <div className={bem('bar')} style={{ width: `${Math.max(0, Math.min(100, percentComplete))}%` }} />
          </div>
        </div>
      );
  }
}
