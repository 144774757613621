import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { DonationsValues } from '@gik/create';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import DonationsContainer from '@gik/create/components/DonationsContainer/DonationsContainer';
import type { IBaseFeaturesFormValues } from '@gik/create/components/FeatureStep/FeatureStep';
import { FeatureDonationsInputNames } from '@gik/create/enums/FeatureDonationsInputNames';
import type { FormProps } from '@gik/ui/Form';
import React from 'react';

export interface ICreateFeaturesDonationsAsideFormValues extends IBaseFeaturesFormValues {
  [FeatureDonationsInputNames.DonationsValues]: DonationsValues;
}

export interface IDonationsAsideProps extends FormProps {
  onChange?: (values: ICreateFeaturesDonationsAsideFormValues) => void;
  featureVideo: string;
  trackDonationsVideoPlayed: () => void;
}

const blockName = 'donations-aside';

export default function DonationsAside({
  onChange = noop,
  featureVideo,
  trackDonationsVideoPlayed,
}: IDonationsAsideProps) {
  const bem = bemBlock(blockName);
  const formValues = useCreateFlowStore(state => state.formValues);
  const { handleNext } = React.useContext(CreatePageContext);

  function handleChange(value: DonationsValues) {
    onChange({
      [FeatureDonationsInputNames.DonationsValues]: value,
    });
  }

  return (
    <div className={bem()}>
      <DonationsContainer
        asForm
        trackingId={AnalyticsEvents.CreateStarted}
        direction={'vertical'}
        value={formValues[FeatureDonationsInputNames.DonationsValues]}
        onChange={handleChange}
        variant="create"
        noFinePrint
      />
    </div>
  );
}
