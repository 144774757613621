import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { FieldSet, FormField } from '@gik/ui/Form';
import React from 'react';

export interface IPageTitleFieldSetProps {}

export interface PageTitleValues {
  pageTitle: string;
}

export const PageTitleFieldSetBlockName = 'page-title-fieldset';

const PageTitleFieldSetComp: React.FC<PropsWithClassName<IPageTitleFieldSetProps>> = ({ className }): JSX.Element => {
  const bem = bemBlock(PageTitleFieldSetBlockName);

  return (
    <FieldSet className={bem(null, null, className)}>
      <FormField name="pageTitle" />
    </FieldSet>
  );
};

export const PageTitleFieldSet = withComponentErrorBoundary(PageTitleFieldSetComp);
