import { logger } from '@gik/analytics/utils/logger';
import { useBrowserNavigation } from '@gik/core/hooks/useBrowserNavigation';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import Router, { useRouter } from 'next/router';
import React from 'react';
import { isBrowser } from './environment';

export type LinkOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
};

export function navigateTo(href: string, options: LinkOptions = { scroll: true }): void {
  if (isBrowser()) {
    logger.info('navigateTo', { href, options });
    Router.push(href, href, options);
  }
}

export function navigateBack(): void {
  if (isBrowser()) {
    const userId = useUserStore.getState().id;
    navigateTo(userId ? routes.userProfile : routes.home);
  }
}

function onRouteChangeComplete(...args) {
  logger.info('routeChangeComplete', args);
}

function onRouteChangeError(...args) {
  logger.info('routeChangeError', args);
}

function onBrowserNavigation(...args) {
  logger.info('Browser back/forward navigation', args);
}

export function useNavigationLogger() {
  useBrowserNavigation(onBrowserNavigation);

  const router = useRouter();

  React.useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    router.events.on('routeChangeError', onRouteChangeError);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      router.events.off('routeChangeError', onRouteChangeError);
    };
  }, [router]);
}
