import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { UISize, UIVariant } from '../types';
import { Radio } from './Radio';
import { RadioGroup } from './RadioGroup';

export interface RadioSchemaEntry {
  label?: string | React.ReactNode;
  labelBefore?: string;
  value?: string;
}

export interface RadioSelectProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  /**
   * Style variant to use
   * This property is passed to the Radio component
   */
  variant?: UIVariant;

  /**
   * Size the component on a fixed scale
   * This property is passed to the Radio component
   */
  size?: UISize;

  /**
   * Force display of focus state
   * This property is passed to the Radio component
   */
  focus?: boolean;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   * This property is passed to the Radio component
   */
  tabIndex?: number;

  /**
   * Put component in disabled mode
   * This property is passed to the Radio component
   */
  disabled?: boolean;

  /**
   * Display the radio components as inline to achieve a horizontal layout.
   * This property is passed on to the RadioGroup component
   */
  inline?: boolean;

  /**
   * name for the input component
   * This property is passed on to the RadioGroup component
   */
  name?: string;

  /**
   * Checked state of the radio input
   * This property is passed on to the RadioGroup component
   */
  value?: string;

  /**
   * Schema definition used to render out Radio components in the RadioGroup
   */
  schema: RadioSchemaEntry[];

  onChange?: (value: string) => void;
}

// block name for this component
const blockName = `${gikClassPrefix}-radio-select`;

export function RadioSelect({
  schema,
  className,
  value,
  name,
  inline,
  variant,
  size,
  disabled,
  focus,
  onChange,
}: RadioSelectProps): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);

  return (
    <RadioGroup inline={inline} name={name} value={value} onChange={onChange} className={blockClasses}>
      {schema.map((entry, index) => {
        return (
          <Radio
            key={typeof entry.label === 'string' ? entry.label : `${name}-${entry?.value}-${index}`}
            variant={variant}
            size={size}
            disabled={disabled}
            focus={focus}
            {...entry}
          />
        );
      })}
    </RadioGroup>
  );
}
