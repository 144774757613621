import { gikClassPrefix } from '@gik/core/constants';
import { removeEmojis } from '@gik/core/utils/Emoji';
import { htmlEncode } from '@gik/core/utils/htmlencode';
import noop from '@gik/core/utils/noop';
import { scrollIntoView } from '@gik/core/utils/scroll';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import classnames from 'classnames';
import he from 'he';
import React from 'react';
import type { UIInputVariant, UISize } from '../types';

type NativeInputType =
  | 'hidden'
  | 'text'
  | 'password'
  | 'number'
  | 'email'
  | 'tel'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'search'
  | 'url'
  | 'range'
  | 'time'
  | 'week';

// note: '| string' is added at the end to support react-final-form input types
type InputType = NativeInputType | 'numeric' | string;

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  /**
   * style variant to use
   */
  variant?: UIInputVariant;

  /**
   * Size the component on a fixed scale
   */
  size?: UISize;

  /**
   * force display of focus state
   */
  focus?: boolean;

  /**
   * Auto focus the input field when the component is mounted
   */
  autoFocus?: boolean;

  /**
   * select the contents of the input when focused
   */
  selectOnFocus?: boolean;

  /**
   * display component in a disabled state
   */
  disabled?: boolean;

  /**
   * value of the input field
   */
  value?: string;

  /**
   * Delay calling the valueChange callback by x amount of milliseconds
   */
  debounceValue?: number;

  /**
   * type of the input field
   */
  type?: InputType;

  /**
   * Append creates append blocks that are colored.
   */
  append?: React.ReactNode;

  /**
   * classNames to add to the append elements
   */
  appendClasses?: string;

  /**
   * Prepend creates prepend blocks that are colored.
   */
  prepend?: React.ReactNode;

  /**
   * classNames to add to the prepend elements
   */
  prependClasses?: string;

  /**
   * tabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * A function that can be passed to render out a custom input instead of the default one
   */
  // allow use of all here since the customInput could be any component
  // eslint-disable-next-line
  customInput?: (customProps: any) => React.ReactElement;

  /**
   * Limit the value to a maximum number of characters.
   */
  maxLength?: number;

  maxLengthDisplay?: boolean;

  /**
   * classNames for the character limit element
   */
  maxLengthClassName?: string;

  /**
   * if set to higher than 0 the component will indicator itself in a
   * warning state when the input length is nearing the maxLength.
   *
   * note: only works in combination with maxLength
   */
  maxLengthWarningSensitivity?: number;

  /**
   * center the max-length indicator
   */
  maxLengthCentered?: boolean;

  /**
   * display the component in an error state
   */
  hasError?: boolean;

  /**
   * display the component in a warning state
   */
  hasWarning?: boolean;

  /**
   * display the component in a success state
   */
  hasSuccess?: boolean;

  clearable?: boolean;

  collapse?: boolean;
  active?: boolean;

  pill?: boolean;
  squared?: boolean;
  filterEmojis?: boolean;

  focusScrollIntoView?: string;

  /**
   * Called when the value changes.
   * This differs from the standard onChange callback for form elements.
   * It will not fire when the component is in a disabled state and it
   * will return the value as a string (instead of an entire onChange Event)
   */
  onValueChange?: (value: string) => void;

  /**
   * Called when the input's value changes.
   */
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onClear?: () => void;

  // FIXME: shouldn't this be in the HTMLElement props?
  name?: string;
}

// block name for this component
export const inputBlockName = `${gikClassPrefix}-input`;

/**
 * GIK Input component
 */
function InputComp(
  {
    value,
    className,
    variant = 'default',
    size = 'base',
    tabIndex,
    disabled,
    focus,
    autoFocus,
    selectOnFocus,
    append,
    appendClasses = '',
    prepend,
    prependClasses = '',
    customInput,
    maxLength,
    maxLengthDisplay = maxLength > 0,
    maxLengthWarningSensitivity = 10,
    maxLengthClassName,
    maxLengthCentered,
    filterEmojis,
    hasError,
    hasWarning,
    hasSuccess,
    clearable,
    collapse,
    active,
    type,
    pill,
    squared,
    debounceValue,
    focusScrollIntoView,
    onFocus = noop,
    onBlur = noop,
    onChange = noop,
    onValueChange = noop,
    onClear = noop,
    name,
    ...otherProps
  }: InputProps,
  forwardedRef: React.MutableRefObject<HTMLInputElement>
): React.ReactElement {
  const [_value, _setValue] = React.useState<string>(
    (() => {
      try {
        return he.decode(value);
      } catch (e) {
        return value;
      }
    })()
  );
  const [focused, setFocused] = React.useState(false);
  const [finalTabIndex, setTabIndex] = React.useState(tabIndex);
  const [ref] = React.useState<React.MutableRefObject<HTMLInputElement>>(forwardedRef || React.createRef());

  const valueDebounce = React.useRef<NodeJS.Timeout>();

  const focusInput = React.useCallback(() => {
    ref.current.focus();
  }, [ref]);

  React.useEffect(() => {
    if (autoFocus && ref?.current) focusInput();
  }, [autoFocus, ref, focusInput]);

  React.useEffect(() => {
    if (active && ref?.current) focusInput();
  }, [active, ref, focusInput]);

  React.useEffect(() => {
    _setValue(
      (() => {
        try {
          return he.decode(value);
        } catch (e) {
          return value;
        }
      })()
    );
  }, [value]);

  React.useEffect(() => {
    // force tabIndex to be -1 if component is in disabled state
    if (disabled) {
      setTabIndex(-1);
    } else {
      setTabIndex(tabIndex);
    }
  }, [disabled, tabIndex]);

  function doValueChange(value): void {
    if (debounceValue) {
      if (valueDebounce.current) clearTimeout(valueDebounce.current);
      valueDebounce.current = setTimeout(() => onValueChange(value), debounceValue);
      return undefined;
    }

    onValueChange(value);
    return undefined;
  }

  function handleClear(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();
    const value = '';
    onValueChange(value);
    _setValue(value);
    onClear();
  }

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    // don't do anything if disabled
    if (disabled) return;

    let newValue = ev?.target?.value;
    if (filterEmojis) newValue = removeEmojis(newValue);
    // maxlength limit for type="number"
    if (type === 'number' && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
      // set the value on the event as well to prevent any change
    }

    if (ev?.target) ev.target.value = newValue;

    // Update the component state with the new value
    doValueChange(newValue);
    onChange(ev);
    _setValue(newValue);
  }

  function handleFocus(ev: React.FocusEvent<HTMLInputElement>): void {
    // disabled components should not receive focus
    // note: onFocus should never be called in the first place
    // components in this state should have a tabIndex of -1
    if (disabled) return;

    setFocused(true);
    if (onFocus) onFocus(ev);
    if (selectOnFocus) {
      // autoselect after 300ms (user should have released their click / tap by then)
      const target = ev.target;
      setTimeout(() => target.select(), 300);
    }

    if (focusScrollIntoView) {
      const containerEl = document.getElementsByClassName('gik-modal__content-wrapper')[0] as HTMLDivElement;

      const el = ref.current as HTMLDivElement;
      const formGroupEl = el.parentElement.parentElement.parentElement.parentElement.parentElement;

      scrollIntoView(formGroupEl, {
        offset: 0,
        block: 'end',
        container: containerEl,
        behavior: 'instant',
      });

      // when the virtual keyboard is open on a mobile device we need to also scroll the document
      setTimeout(() => {
        scrollIntoView(formGroupEl, {
          offset: 0,
          block: 'end',
          behavior: 'smooth',
        });
      }, 1000);

      // scroller.scrollTo('', {
      //   duration: 300,
      //   smooth: true,
      //   containerId: modalContentId,
      // });
    }
  }

  function handleBlur(ev: React.FocusEvent<HTMLInputElement>): void {
    setFocused(false);
    if (onBlur) onBlur(ev);
  }

  const hasMaxLength = maxLength > 0 && maxLengthDisplay && focused;
  const encodedValue = htmlEncode(_value);

  const charLength = encodedValue?.length;
  const maxLengthError = hasMaxLength && charLength > maxLength;
  const maxLengthWarning =
    hasMaxLength &&
    !maxLengthError &&
    maxLengthWarningSensitivity &&
    charLength >= maxLength - maxLengthWarningSensitivity;

  const shouldShowMaxLength = focused && value?.length > 0;

  const hasValue = charLength > 0;
  const collapsable = collapse && !active;

  const blockClasses = classnames([
    `${inputBlockName}__wrapper`,
    { [`${inputBlockName}--${variant}`]: variant },
    { [`${inputBlockName}--size-${size}`]: size },
    { [`${inputBlockName}--disabled`]: disabled },
    { [`${inputBlockName}--focus`]: focused || focus },
    { [`${inputBlockName}--has-prepend`]: prepend },
    { [`${inputBlockName}--has-append`]: append },
    { [`${inputBlockName}--max-length-centered`]: maxLengthCentered },
    { [`${inputBlockName}--has-max-length`]: hasMaxLength },
    { [`${inputBlockName}--has-error`]: hasError || maxLengthError },
    { [`${inputBlockName}--has-warning`]: hasWarning || maxLengthWarning },
    { [`${inputBlockName}--max-length-visible`]: shouldShowMaxLength },
    { [`${inputBlockName}--has-success`]: hasSuccess },
    { [`${inputBlockName}--pill`]: pill },
    { [`${inputBlockName}--squared`]: squared },
    { [`${inputBlockName}--has-value`]: hasValue },
    { [`${inputBlockName}--collapsable`]: collapsable },

    className || '',
  ]);

  const maxLengthClasses = classnames([
    `${inputBlockName}__max-length`,
    { [`${inputBlockName}__max-length--warning`]: maxLengthWarning },
    maxLengthClassName || '',
  ]);

  delete otherProps['vertical'];

  const inputProps = {
    value: _value,
    disabled,
    type,
    maxLength,
    name,
    tabIndex: finalTabIndex,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    ...otherProps,
    className: `${inputBlockName}__input`,
  };

  delete inputProps['centerVertical'];

  let InputComponent: React.ReactElement;
  if (customInput) {
    // allow a custom component to be provided that will act as the input field
    // don't pass refs to custom input because we are not sure if they support it
    delete inputProps['ref'];

    InputComponent = customInput(inputProps);
  } else {
    InputComponent = <input {...inputProps} ref={ref} />;
  }

  // hidden input fields should not render a wrapper
  if (type === 'hidden') return InputComponent;

  const prependElements = Array.isArray(prepend) ? prepend : [prepend];
  const prependBlocks = (
    <div className={`${inputBlockName}__prepend-wrapper`}>
      {prependElements.map((item, index) => {
        return (
          <div key={`prepend-${index}`} className={`${inputBlockName}__prepend ${prependClasses}`}>
            {item}
          </div>
        );
      })}
    </div>
  );

  const appendElements = Array.isArray(append) ? append : [append];
  const appendBlocks = (
    <div className={`${inputBlockName}__append-wrapper`}>
      {appendElements.map((item, index) => {
        return (
          <div key={`prepend-${index}`} className={`${inputBlockName}__append ${appendClasses}`}>
            {item}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={blockClasses}>
      <div className={`${inputBlockName}__input-wrapper`}>
        {prepend && prependBlocks}
        {InputComponent}
        {append && appendBlocks}
        {clearable && (!collapse || active) && (
          <div className={`${inputBlockName}__clear`} onClick={handleClear}>
            <SvgIcon Icon={CloseIcon} size="sm" />
          </div>
        )}
      </div>
      {hasMaxLength && (
        <div className={maxLengthClasses}>
          {charLength}/{maxLength}
        </div>
      )}
    </div>
  );
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(InputComp);

// Also export the component without a forwardRef.
// Used in storybook to extract component properties.
export const InputNoRef = InputComp;
