import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IStickyProps extends React.HTMLAttributes<HTMLDivElement> {
  yPos?: number;
  marginTop?: number;
}

export function Sticky({ children, marginTop = 0, className, ...otherProps }: IStickyProps): React.ReactElement {
  const bem = bemBlock('sticky');
  const blockClasses = bem(null, null, className);

  const style = {
    top: marginTop + 'px',
  };

  return (
    <div className={blockClasses} style={style} {...otherProps}>
      {children}
    </div>
  );
}
