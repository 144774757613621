import TranslationUtils from '@gik/core/utils/TranslationUtils';

export const namespace = 'app-footer';

const translations = {
  inkind: 'Give InKind',
  about: 'About Us',
  contact: 'Contact Us',
  support: 'Support',
  press: 'In the Press',
  careers: 'Careers',
  write: 'Write for Us',
  healthCare: 'For Healthcare',

  // Inkind Pages
  inkindPages: 'InKind Pages',
  create: 'Create a Page',
  find: 'Find a Page',
  howItWorks: 'How it Works',
  features: 'Features',
  compare: 'Compare Us',
  faq: 'Common Questions',

  // How Can I help
  howCanIHelp: 'How Can I Help?',
  browseSituations: 'Browse by Situation',
  blog: 'Articles',

  // Fine print
  tos: 'Terms of Service',
  privacy: 'Privacy Policy',
  attribution: 'Creative Commons',
};

export default {
  [namespace]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(namespace, translations);
