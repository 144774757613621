import { gikClassPrefix } from '@gik/core/constants';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import classnames from 'classnames';
import type { HTMLAttributes } from 'react';
import React from 'react';
import { SvgIcon } from '../SvgIcon/SvgIcon';

export interface CollapseProps extends HTMLAttributes<HTMLElement> {
  open?: boolean;
  defaultOpen?: boolean;
  title: string;
  visibleTitle?: string;
}

const blockName = `${gikClassPrefix}-collapse`;

export function Collapse({
  className,
  children,
  open,
  defaultOpen = false,
  onClick,
  title,
  visibleTitle,
  ...otherProps
}: CollapseProps): React.ReactElement {
  const controlled = open !== undefined;

  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  const blockClasses = classnames([blockName, className || '']);

  function handleHeaderClick(ev: React.MouseEvent<HTMLDivElement>) {
    if (onClick) onClick(ev);
    if (!controlled) setIsOpen(!isOpen);
  }

  // FIXME: fix base components inheritance
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={blockClasses} {...(otherProps as any)}>
      <header onClick={handleHeaderClick}>
        {isOpen && (
          <span className={`${blockName}__title`}>
            <SvgIcon Icon={ChevronDownIcon} size="sm" /> {visibleTitle || title}
          </span>
        )}
        {!isOpen && (
          <span className={`${blockName}__title`}>
            <SvgIcon Icon={ChevronRightIcon} size="sm" /> {title}
          </span>
        )}
      </header>
      {isOpen && <main>{children}</main>}
    </div>
  );
}
