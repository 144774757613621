import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import MailIcon from '@heroicons/react/solid/MailIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { openContactPageOrganizersModal } from '../ContactPageOrganizers/ContactPageOrganizers';
import { translationKeys } from './i18n/en';

export type ContactPageOrganizersForAddressVariant = 'default' | 'interstitial' | 'centered';

export interface IContactPageOrganizersForAddressProps {
  inkindRouteId: string;
  variant?: ContactPageOrganizersForAddressVariant;
}

export default function ContactPageOrganizersForAddress({
  inkindRouteId,
  variant = 'default',
}: IContactPageOrganizersForAddressProps): React.ReactElement {
  const bem = bemBlock('contact-page-organizers-for-address');
  const { t } = useTranslation();
  return (
    <div className={bem(null, [variant])}>
      <div className={bem('description')}>{t(translationKeys.description)}</div>
      <Button
        size="xs"
        uppercase
        pill
        className={bem('button')}
        onClick={() => openContactPageOrganizersModal(inkindRouteId)}
        prepend={<SvgIcon Icon={MailIcon} size="xs" />}
      >
        {t(translationKeys.emailAnOrganizer)}
      </Button>
    </div>
  );
}
