import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { Product } from '@gik/core/models/gik/Product';

const path = 'products';

function getParams(serviceProductBundleId: number) {
  return {
    serviceProductBundleIds: serviceProductBundleId,
  };
}

export function getProductsByServiceProductBundle(serviceProductBundleId: number) {
  return dotnetApi
    .get(path, {
      searchParams: getParams(serviceProductBundleId),
    })
    .json<Product[]>();
}

export function useProductsByServiceProductBundle(serviceProductBundleId?: number, config?: SWRConfigInterface) {
  return useApi<Product[]>(serviceProductBundleId ? path : null, getParams(serviceProductBundleId), config);
}
