import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'saved-articles';

// TODO: fill in missing translations
const translations = {
  title: 'Saved Articles',
  btnSeeAll: 'See All Articles',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
