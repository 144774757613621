import { logger } from '@gik/analytics/utils/logger';
import type { CartItem } from '@gik/core/models/gik/Order';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { default as _GooglePayButton } from '@google-pay/button-react';
import React from 'react';

export type ReadyToPayChangeResponse = {
  isButtonVisible: boolean;
  isReadyToPay: boolean;
};

export type IGooglePayButtonProps = {
  cart?: CartItem[];
  tip?: number;
  shipping?: number;
  total: number;
  onAuthorized: (paymentData: google.payments.api.PaymentData) => Promise<void>;
  onReadyToPayChange: (result: ReadyToPayChangeResponse) => void;
  disabled?: boolean;
} & UIComponent;

export function GooglePayButton({
  cart,
  tip,
  shipping,
  total,
  onAuthorized,
  onReadyToPayChange,
  disabled,
}: IGooglePayButtonProps) {
  const bem = useBemCN('google-pay-button');

  const GOOGLE_PAY_ENV = useEnvStore(state => state.GOOGLE_PAY_ENV) as google.payments.api.Environment;

  const processPayment = React.useCallback(
    function processPayment(paymentData: google.payments.api.PaymentData) {
      return onAuthorized?.(paymentData);
    },
    [onAuthorized]
  );

  const onPaymentAuthorized: google.payments.api.PaymentAuthorizedHandler = React.useCallback(
    function onPaymentAuthorized(paymentData) {
      if (disabled) return;
      return new Promise(function (resolve, reject) {
        // handle the response
        processPayment(paymentData)
          .then(function () {
            resolve({ transactionState: 'SUCCESS' });
          })
          .catch(function (err: Partial<google.payments.api.PaymentDataError>) {
            logger.error(err);

            resolve({
              transactionState: 'ERROR',
              error: {
                intent: 'PAYMENT_AUTHORIZATION',
                message: err.message,
                reason: err.reason ?? 'OTHER_ERROR',
              },
            });
          });
      });
    },
    [disabled, processPayment]
  );

  const paymentRequest = useGooglePayRequest(total, cart, tip, shipping);

  return (
    <div {...bem(null, [{ disabled }])}>
      <div {...bem('disabled-overlay')} />
      <_GooglePayButton
        style={{
          height: '46px',
        }}
        environment={GOOGLE_PAY_ENV}
        buttonSizeMode="fill"
        buttonType="plain"
        paymentRequest={paymentRequest}
        onPaymentAuthorized={onPaymentAuthorized}
        onReadyToPayChange={onReadyToPayChange}
      />
    </div>
  );
}

function useGooglePayRequest(total: number, cart?: CartItem[], tip?: number, shipping?: number) {
  const STRIPE_API_KEY = useEnvStore(state => state.STRIPE_API_KEY);
  const GOOGLE_PAY_MERCHANT_ID = useEnvStore(state => state.GOOGLE_PAY_MERCHANT_ID);
  const GOOGLE_PAY_MERCHANT_NAME = useEnvStore(state => state.GOOGLE_PAY_MERCHANT_NAME);
  const displayItems = useDisplayItems(cart, tip, shipping);

  return React.useMemo(() => {
    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      emailRequired: true,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL',
              phoneNumberRequired: true,
            },
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
          },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: {
              gateway: 'stripe',
              'stripe:version': '2018-10-31',
              'stripe:publishableKey': STRIPE_API_KEY,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: GOOGLE_PAY_MERCHANT_ID,
        merchantName: GOOGLE_PAY_MERCHANT_NAME,
      },
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPriceLabel: 'Total',
        totalPrice: total?.toString(),
        currencyCode: 'USD',
        countryCode: 'US',
        displayItems: displayItems,
      },
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
    };
  }, [
    GOOGLE_PAY_MERCHANT_ID,
    GOOGLE_PAY_MERCHANT_NAME,
    STRIPE_API_KEY,
    displayItems,
    total,
  ]) as google.payments.api.PaymentDataRequest;
}

const useDisplayItems = (cart?: CartItem[], tip?: number, shipping?: number) => {
  return React.useMemo(
    () =>
      cart
        ?.map(
          item =>
            ({
              type: 'LINE_ITEM',
              label: item.name,
              price: item.price?.toString(),
              status: 'FINAL',
            } as google.payments.api.DisplayItem)
        )
        .concat(
          tip
            ? [
                {
                  type: 'LINE_ITEM',
                  label: 'Give InKind Tip',
                  price: tip?.toString(),
                  status: 'FINAL',
                } as google.payments.api.DisplayItem,
              ]
            : []
        )
        .concat(
          shipping
            ? [
                {
                  type: 'SHIPPING_OPTION',
                  label: 'Shipping',
                  price: shipping?.toString(),
                  status: 'FINAL',
                } as google.payments.api.DisplayItem,
              ]
            : []
        ),
    [cart, shipping, tip]
  ) as google.payments.api.DisplayItem[];
};
