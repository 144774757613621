import { logger } from '@gik/analytics/utils/logger';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { CalendarUtil } from '@gik/calendar';
import { isGiftCardServiceModule } from '@gik/calendar/constants/services/ServiceModule';
import { translationKeys as calTranslationKeys } from '@gik/calendar/i18n/en';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import type { ICalendarClaimCartItem } from '@gik/calendar/models/Claim';
import { canSeeClaimOwner } from '@gik/calendar/utils/CalendarClaimUtils';
import { anonymousName } from '@gik/checkout/consts';
import type { BaseUserWithAvatar } from '@gik/core/models/gik/User';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInPastAllTimezones } from '@gik/core/utils/DateTimeUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { Decoder } from '@gik/ui/Decoder';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import { UI } from '@gik/ui/UIManager';
import { openUserInfoModal } from '@gik/user-profile/components/UserInfoModal/UserInfoModal';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import type { Moment } from 'moment';
import moment from 'moment';
import type { CSSProperties } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateTimeDisplay from '../DateTimeDisplay/DateTimeDisplay';
import { isAnnouncement } from '../EventForm/utils';
import { translationKeys } from './i18n/en';

export interface ICalendarEventProps extends React.HTMLAttributes<HTMLDivElement> {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  eventTypes?: IWordpressCalendarEventType[];
  selectedMonth?: Moment;
  resolvingClaim?: boolean;
  isSamplePage?: boolean;
  privateClaim?: boolean;
  onEdit?(event: ICalendarEvent, entry: ICalendarEntry): void;
  onClaim?(event: ICalendarEvent, entry: ICalendarEntry, routeId: string): void;
}

function CalendarEventComp({
  event,
  entry,
  eventTypes,
  className,
  resolvingClaim,
  isSamplePage,
  privateClaim,
  onEdit,
  onClaim,
  ...otherProps
}: ICalendarEventProps): React.ReactElement {
  const bem = bemBlock('calendar-event');
  const { t } = useTranslation();

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  const isClaimed = !!entry.claim || event.product;
  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);
  const isPastEvent = React.useMemo(() => isInPastAllTimezones(moment.utc(event.startsAt)), [event]);
  const isOnlyGiftCard = entry.allowGiftCards && entry.allowedServiceIds.length === 1;
  const isGiftCardClaim = entry.claim && isGiftCardServiceModule(entry.claim.serviceModule);
  const uneditable = isGiftCardClaim || (isClaimed && isPastEvent);

  const eventType = React.useMemo(() => {
    return calendarEventTypes?.find(type => type.id === entry.typeId);
  }, [calendarEventTypes, entry]);

  let giftCardThumbnail: string;
  const placeholderThumbnail = undefined; // TODO: set placeholder image here

  if (isGiftCardClaim && entry.claim?.cartItems?.length) {
    const cartItem: ICalendarClaimCartItem = entry.claim?.cartItems[0];
    giftCardThumbnail = cartItem.gridImage || cartItem.thumbnailUrl || cartItem.imageUrl;
    if (!giftCardThumbnail) {
      giftCardThumbnail = placeholderThumbnail;
    }
  }

  if (event.product) {
    giftCardThumbnail = event.product.gridImage;
    if (!giftCardThumbnail) {
      giftCardThumbnail = placeholderThumbnail;
    }
  }

  const handleClaim = React.useCallback(
    function handleClaim(entry: ICalendarEntry, event: ICalendarEvent) {
      onClaim(event, entry, inkind.routeId);
    },
    [inkind, onClaim]
  );

  const handleClick = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation();

      // don't do anything if this item is already claimed in the current cart
      if (event.product) return;

      if (isClaimed) return CalendarUtil.viewClaim(entry, event);

      // when anonymous users click the event it will attempt to claim the event
      if (!isPastEvent) return onClaim(event, entry, inkind.routeId);

      if (isPastEvent && !canEdit) return UI.notifyError("You can't sign up for requests in the past");

      if (!uneditable) return onEdit(event, entry);
    },
    [entry, event, inkind, isClaimed, isPastEvent, onClaim, onEdit, uneditable, canEdit]
  );

  const openSupporterInfoModal = React.useCallback(function openSupporterInfoModal(
    e: React.MouseEvent,
    supporter: BaseUserWithAvatar
  ) {
    e.stopPropagation();
    openUserInfoModal(supporter);
  }, []);

  if (!eventType) {
    logger.error(`event type with id "${entry.typeId}" not found`);
    return null;
  }

  if (!eventTypes || !inkind) {
    return null;
  }

  const _isAnnouncement = isAnnouncement(eventType.id);

  const clickable = true;

  let claimerFullname: string = null;
  if (canSeeClaimOwner(entry, user, inkind)) {
    claimerFullname = supporter?.firstName || '';
    if (supporter?.lastName) {
      claimerFullname += ' ' + supporter?.lastName;
    }
  }

  if (event.product) {
    claimerFullname = event.supporterName;
  }

  return (
    <div
      className={bem(
        null,
        [
          { organizer: canEdit },
          { clickable },
          { uneditable },
          { 'past-event': isPastEvent },
          { announcement: _isAnnouncement },
        ],
        [className, `gik-calendar-event-type-theme--${eventType?.slug}`]
      )}
      {...otherProps}
      onClick={handleClick}
    >
      <>
        <div className={bem('content-wrapper')}>
          <header className={bem('content-header')}>
            {((isClaimed && !isGiftCardClaim) || (!isClaimed && !isOnlyGiftCard) || (!isClaimed && isOnlyGiftCard)) && (
              <DateTimeDisplay
                shortTimes
                splitTimes
                hideDate
                startsAt={event.startsAt}
                endsAt={event.endsAt}
                allDay={entry.allDay && !isGiftCardClaim}
              />
            )}
            {isClaimed && isGiftCardClaim && (
              <div className={bem('box-giftcard')}>{t(translationKeys.giftCard).toString()}</div>
            )}
          </header>
          <main className={bem('content-main')}>
            <div className={bem('content-title')}>
              {entry.typeId === CalendarEventTypeIds.Other ? (
                <Image
                  alt={'icon'}
                  inline
                  className={bem('icon', null)}
                  src={eventType?.acf.simplified_svg_icon_alt || eventType?.acf.simplified_svg_icon}
                />
              ) : (
                <Image alt={'icon'} inline className={bem('icon', null)} src={eventType?.acf.simplified_svg_icon} />
              )}
              <div className={bem('label')}>
                <span className={bem('title')}>
                  {eventType.id === CalendarEventTypeIds.Other
                    ? <HTMLParser rawHtml={entry?.title} /> || 'No Title'
                    : eventType?.name}
                </span>
                {/* Todo get correct number of people from event data */}
                {entry.numberRequired && <span className={bem('number-of-people')}> for {entry.numberRequired}</span>}
                {/* {entry.typeId === CalendarEventTypeIds.Other && (
                    <div className={bem('custom-title')}>{entry.title}</div>
                  )} */}
              </div>
            </div>

            <div className={bem('date-mobile', null, 'gik-hidden-md')}>
              <DateTimeDisplay
                shortTimes
                hideDate
                startsAt={event.startsAt}
                endsAt={event.endsAt}
                allDay={entry.allDay}
              />
            </div>

            <div className={bem('content')}>
              {(isGiftCardClaim || event.product) && (
                <>
                  <div className={bem('giftcard', null)}>
                    <div
                      className={bem('thumbnail')}
                      style={{ backgroundImage: `url(${giftCardThumbnail})` } as CSSProperties}
                    />
                    <KeepProportions proportion={0.63}>
                      <Image alt={'icon'} className={bem('thumbnail')} src={giftCardThumbnail} />
                    </KeepProportions>
                  </div>
                  {/* <div className={bem('box-time', null, 'print tw-uppercase')}>
                    <span>Gift Card&nbsp;</span>
                  </div> */}
                </>
              )}

              {isClaimed && (
                <div className={bem('providedBy')}>
                  {entry.typeId !== CalendarEventTypeIds.PetCare &&
                    entry.typeId !== CalendarEventTypeIds.ChildCare &&
                    entry.typeId !== CalendarEventTypeIds.SeniorCare &&
                    entry.typeId !== CalendarEventTypeIds.HomeServices &&
                    entry.typeId !== CalendarEventTypeIds.Transportation && (
                      <div className={bem('providedBy-description')}>
                        <HTMLParser rawHtml={entry.claim?.description} />
                      </div>
                    )}
                  <div className={bem('providedBy-from')}>
                    <span>{t(calTranslationKeys.from).toString()}</span>{' '}
                    {canSeeClaimOwner(entry, user, inkind) || !!event.product ? (
                      <>
                        <Button
                          className={bem('name-link')}
                          variant={'default-link'}
                          onClick={e => openSupporterInfoModal(e, supporter)}
                        >
                          <Decoder text={claimerFullname} />
                        </Button>
                        {((entry.claim?.privateClaim && supporter?.id === userId) || privateClaim) && (
                          <span>
                            <Tooltip
                              text={
                                <>
                                  Only you can see your name.
                                  <br /> This reads &quot;from Anonymous&quot; to everyone else.
                                </>
                              }
                            >
                              <span className={'tw-ml-2'}>
                                <SvgIcon Icon={InformationCircleIcon} />
                              </span>
                            </Tooltip>
                          </span>
                        )}
                      </>
                    ) : (
                      anonymousName
                    )}
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
        <div className={bem('actions')}>
          {isClaimed && (
            <div className={bem('claimed')}>
              <SvgIcon Icon={CheckIcon} />
            </div>
          )}

          {!isClaimed && !isPastEvent && (
            <div className={bem('claim-actions', null, ['no-print'])}>
              <Button
                pill
                className="upper"
                onClick={async ev => {
                  ev.stopPropagation();

                  if (isSamplePage) {
                    return await UI.alert(t(translationKeys.samplePageWarning));
                  }

                  return handleClaim(entry, event);
                }}
              >
                {t(
                  resolvingClaim
                    ? translationKeys.calendarEventConflictResolutionClaimButton
                    : translationKeys.calendarEventClaimButton
                ).toString()}
              </Button>
            </div>
          )}

          {/* {event.userId && userId && event.userId.toLowerCase() !== userId.toLowerCase() && (
          <div className="claimed-check no-print">
            <SvgIcon Icon="CheckOutline" />
          </div>
        )} */}
        </div>
      </>
    </div>
  );
}

export const CalendarEvent = React.memo(
  withComponentErrorBoundary(CalendarEventComp),
  (prevProps, nextProps) => prevProps.event === nextProps.event
);
