import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { addEmailUserTags } from '@gik/api/emailUsers/emailUsers';
import { useUser } from '@gik/api/users/user';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type WordpressSituation from '@gik/core/models/wordpress/WordpressSituation';
import type { IWordpressCreateFlowCategory } from '@gik/core/models/wordpress/WordpressSituation';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { fieldHasError } from '@gik/core/utils/form';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { SituationStepInputNames } from '@gik/create/enums/SituationStepInputNames';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import type { ICreateFlowFormValues } from '@gik/create/models/CreateFlowFormValues';
import { useCreateFlowStore } from '@gik/create/store/CreateFlowStore';
import { Carousel, defaultSlideAnimationSpeed } from '@gik/ui/Carousel';
import type { FormErrors, FormProps } from '@gik/ui/Form';
import { Form, FormButtons, FormGroup } from '@gik/ui/Form';
import type { ValidationError } from '@gik/ui/Form/validation';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { Select } from '@gik/ui/Select';
import { SelectList } from '@gik/ui/SelectList';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type Slider from 'react-slick';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import SituationTile from './SituationTile';

export interface ISituationStepProps extends FormProps {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: ICreateSituationFormValues) => void;
  onChange?: (values: ICreateSituationFormValues) => void;
}

export interface ICreateSituationFormValues {
  [SituationStepInputNames.Situations]: number[];
}

export interface ICreateSituationFormErrors {
  [SituationStepInputNames.Situations]?: ValidationError;
}

export interface ISituationCardProps {
  label: string;
  value: number;
  image: string;
  selected?: boolean;
}

export const formId = 'CreateSituationStepForm';
export const selectedCategoryStorageKey = 'gik-create-form-selected-situation-category';

function SituationStep({ className, buttons, buttonsPortal, onSubmit, onChange, ...otherProps }: ISituationStepProps) {
  const bem = bemBlock('situation-step');
  const { t } = useTranslation();
  const situations = useCreateFlowStore(state => state.situations);
  const situationCategories = useCreateFlowStore(state => state.situationCategories);
  const selectedCategoryIndex = useCreateFlowStore(state => state.selectedCategoryIndex);
  const situationCarouselRef = useCreateFlowStore(state => state.situationCarouselRef);
  const setSituationCarouselRef = useCreateFlowStore(state => state.setSituationCarouselRef);
  const setSelectedCategoryIndex = useCreateFlowStore(state => state.setSelectedCategoryIndex);
  const { situationFormRef, footerSituationCarouselRef } = React.useContext(CreatePageContext);
  const isMd = useBreakpoint(Breakpoint.MD);
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const formValues = useCreateFlowStore(state => state.formValues);
  const clearWishlistSuggestions = useCreateFlowStore(state => state.clearWishlistSuggestions);

  const [selectedCategoryStorage, setSelectedCategoryStorage] = useLocalStorage<string>(selectedCategoryStorageKey);

  let situationSlug = selectedCategoryStorage;
  if (!situationSlug) {
    situationSlug = situationCategories[0].value;
  }

  const [selection, setSelection] = React.useState<number[]>(formValues.situations);
  const [selectedCategory, setSelectedCategory] = React.useState<string>(situationSlug);

  const categories: IWordpressCreateFlowCategory[] = situationCategories.map((item, index) => ({
    ...item,
    index,
  }));

  const debounce = React.useRef<NodeJS.Timeout>();

  function handleBeforeSlideChange(_oldSlide: number, nextSlide: number) {
    // we need to wait for the animation to finish, even when user switches slides again before the animation can finish
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      const matchingCategory = categories.find(item => item.value == categories[nextSlide].value);
      if (matchingCategory) {
        setSelectedCategoryIndex(nextSlide);
        setSelectedCategory(matchingCategory.value);

        footerSituationCarouselRef?.current?.slickGoTo?.(nextSlide);

        // also update local storage
        setSelectedCategoryStorage(matchingCategory.value);
      }
    }, defaultSlideAnimationSpeed);
  }

  function handleCategoryChange(value: string) {
    const itemIndex = categories.findIndex(item => item.value === value);
    setSelectedCategory(value);
    setSelectedCategoryIndex(itemIndex);
    // also update local storage
    setSelectedCategoryStorage(value);
    situationCarouselRef?.slickGoTo?.(itemIndex);
  }

  function customValidateForm(values: ICreateSituationFormValues) {
    const errors: FormErrors<{ situations: string }> = {};

    if (values?.situations?.length < 1) {
      errors.situations = { message: t(translationKeys.SituationRequired), type: 'singleRequired' };
    }

    return errors;
  }

  React.useEffect(() => {
    const index = situationCategories.findIndex(item => item.value === selectedCategory);
    setSelectedCategoryIndex(index);

    situationCarouselRef?.slickGoTo?.(index);

    return () => {
      setSituationCarouselRef(null);
      setSelectedCategoryIndex(null);
    };
    // onmount effect to set the initial selected category
    // eslint-disable-next-line
  }, []);

  function initialSetRef(el: Slider) {
    setSituationCarouselRef(el);

    // NOTE: this call should not be needed since we are already passing the initialSlide property to slick
    // however it seems there is a bug with slick where the next and prev arrows will not
    // behave correctly when this function isn't called along with an initialSlide property
    el?.slickGoTo?.(selectedCategoryIndex);
  }

  useFormNavigationObserver(situationFormRef);

  if (!situations) {
    // TODO: why aren't we using a skeleton here?
    return <LoadingSpinner />;
  }

  if (situations?.length === 0) {
    return <div className={bem('empty')}>No situations found.</div>;
  }

  const userType = formValues?.recipientType === RecipientType.Myself ? 'you' : 'they';

  return (
    <Form
      className={bem(null, null, className)}
      validate={customValidateForm}
      initialValues={formValues}
      onChange={(values: ICreateFlowFormValues) => {
        onChange({
          situations: values.situations,
        });
      }}
      vertical
      id={formId}
      ref={situationFormRef}
      trackingId={AnalyticsEvents.CreateStarted}
      onSubmit={(values: ICreateFlowFormValues) => {
        if (JSON.stringify(values.situations?.sort()) !== JSON.stringify(formValues.situations?.sort())) {
          clearWishlistSuggestions();
          delete values[FeatureWishlistInputNames.WishlistItems];

          const emailUserId = values.emailAddress || user?.emailAddress;
          if (emailUserId) {
            const situationSlugs = situations
              .filter(situation => values.situations.indexOf(situation.id) >= 0)
              .map(situation => situation.slug);
            const tags = [];

            situationSlugs.forEach(slug => {
              tags.push('situation-' + slug);
              tags.push('page-for-' + formValues.recipientType + '-situation-' + slug);
            });

            addEmailUserTags({
              userEmail: emailUserId,
              tags: tags,
            });
          }
        }

        onSubmit?.(values);
      }}
      render={(form: FormikProps<object>) => {
        return (
          <div className={bem('form-wrapper')}>
            {/* TODO: i18n */}
            <h2 className={bem('title', null, 'gik-create-section-title')}>What are {userType} needing support for?</h2>

            <div className={bem('select-wrapper')}>
              <Select
                className={bem('select')}
                value={selectedCategory}
                options={categories}
                onChange={item => {
                  handleCategoryChange(item as string);
                }}
              />
            </div>
            {/* TODO: i18n */}
            <p className={bem('tiles-instructions')}>Select as many as needed</p>

            <FormGroup
              fieldName={SituationStepInputNames.Situations}
              {...fieldHasError(form, SituationStepInputNames.Situations)}
              vertical
              center
              required
            >
              <div className={bem('carousel-wrapper')}>
                <Carousel
                  legacyIndicator
                  ref={el => {
                    if (el && !situationCarouselRef) {
                      initialSetRef(el);
                    }
                  }}
                  infinite={false}
                  variant={'primary'}
                  adaptiveHeight
                  className={bem('carousel')}
                  swipe={!isMd}
                  //arrowsVariant="primary"
                  arrows={false}
                  initialSlide={selectedCategoryIndex}
                  dots={false} // dots appear in the CreatePageNavigation instead and syncs with this carousel
                  beforeChange={handleBeforeSlideChange}
                >
                  {categories.map(category => {
                    const options: ISituationCardProps[] = situations
                      .filter((item: WordpressSituation) =>
                        item.acf.create_flow_categories?.find(item => item.value === category.value)
                      )
                      .map(item => ({
                        label: item.name,
                        value: item.id,
                        image: item.acf.taxonomy_svg_icon,
                      }));

                    return (
                      <div key={category.value}>
                        <SelectList<number>
                          multiple
                          value={selection}
                          options={options}
                          onChange={v => {
                            setSelection(v as number[]);
                            form.setFieldValue(SituationStepInputNames.Situations, v, true);
                          }}
                          render={(item: ISituationCardProps, selected: boolean) => {
                            return <SituationTile selected={selected} image={item.image} label={item.label} />;
                          }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </FormGroup>

            <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
          </div>
        );
      }}
      {...otherProps}
    />
  );
}

export default SituationStep;
