import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'start-inkind-card';

const translations = {
  title: 'Ease someone&apos;s life a little.',
  startTitle: 'Start an inkind page',
  description: 'Truly helpful, truly free.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
