import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const PageSharingDisabledOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M11.6644 13.8333H8.99778C8.29054 13.8333 7.61226 14.1143 7.11216 14.6144C6.61207 15.1145 6.33112 15.7928 6.33112 16.5L6.33112 25.8333C6.33112 26.5406 6.61207 27.2189 7.11216 27.719C7.61226 28.219 8.29054 28.5 8.99778 28.5L24.9978 28.5C25.705 28.5 26.3833 28.219 26.8834 27.719C27.3835 27.2189 27.6644 26.5406 27.6644 25.8333L27.6644 16.5C27.6644 15.7928 27.3835 15.1145 26.8834 14.6144C26.3833 14.1143 25.705 13.8333 24.9978 13.8333H22.3311M20.9978 8.5L16.9978 4.5M16.9978 4.5L12.9978 8.5M16.9978 4.5V21.8333"
          stroke="#BCC7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M28.9978 4.5L4.9978 28.5" stroke="#EF5C84" strokeWidth="2" strokeLinecap="round" />
      </svg>
    );
  }
);

export const PageSharingDisabled = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99615 2C10.2614 2 10.5157 2.10536 10.7033 2.29289L13.0366 4.62623C13.4271 5.01675 13.4271 5.64992 13.0366 6.04044C12.6461 6.43096 12.0129 6.43096 11.6224 6.04044L10.9961 5.41421L10.9961 13.1111C10.9961 13.6634 10.5484 14.1111 9.99615 14.1111C9.44386 14.1111 8.99615 13.6634 8.99615 13.1111L8.99615 5.41421L8.36992 6.04044C7.9794 6.43096 7.34623 6.43096 6.95571 6.04044C6.56518 5.64992 6.56518 5.01675 6.95571 4.62623L9.28904 2.29289C9.47658 2.10536 9.73093 2 9.99615 2ZM3.52243 8.76292C4.00169 8.28366 4.65171 8.01441 5.32948 8.01441H6.88504C7.43732 8.01441 7.88504 8.46213 7.88504 9.01441C7.88504 9.5667 7.43732 10.0144 6.88504 10.0144H5.32948C5.18214 10.0144 5.04083 10.0729 4.93664 10.1771C4.83246 10.2813 4.77393 10.4226 4.77393 10.57L4.77393 15.4444C4.77393 15.5918 4.83246 15.7331 4.93664 15.8373C5.04083 15.9415 5.18214 16 5.32948 16L14.6628 16C14.8102 16 14.9515 15.9415 15.0557 15.8373C15.1598 15.7331 15.2184 15.5918 15.2184 15.4444V10.57C15.2184 10.4226 15.1598 10.2813 15.0557 10.1771C14.9515 10.0729 14.8102 10.0144 14.6628 10.0144H13.1073C12.555 10.0144 12.1073 9.5667 12.1073 9.01441C12.1073 8.46213 12.555 8.01441 13.1073 8.01441L14.6628 8.01441C15.3406 8.01441 15.9906 8.28366 16.4699 8.76292C16.9491 9.24218 17.2184 9.89219 17.2184 10.57V15.4444C17.2184 16.1222 16.9491 16.7722 16.4699 17.2515C15.9906 17.7308 15.3406 18 14.6628 18H5.32948C4.65171 18 4.00169 17.7308 3.52243 17.2515C3.04317 16.7722 2.77393 16.1222 2.77393 15.4444L2.77393 10.57C2.77393 9.89219 3.04317 9.24218 3.52243 8.76292Z"
          fill="#9EABAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7071 0.292893C20.0976 0.683417 20.0976 1.31658 19.7071 1.70711L1.70711 19.7071C1.31658 20.0976 0.683417 20.0976 0.292893 19.7071C-0.0976311 19.3166 -0.0976311 18.6834 0.292893 18.2929L18.2929 0.292893C18.6834 -0.0976311 19.3166 -0.0976311 19.7071 0.292893Z"
          fill="#EF5C84"
        />
      </svg>
    );
  }
);
