import { useVisaUnallowedWords } from '@gik/checkout/utils/useVisaUnallowedWords';
import type { Product } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { sanitizeText } from '@gik/core/utils/Text';
import type { ButtonElement } from '@gik/ui/Button/ButtonProps';
import type { FormProps, FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { noConsecutiveSpaces, noEmptySpaces, noSpecialCharacters, unallowedWords } from '@gik/ui/Form/commonValidation';
import { Sticky } from '@gik/ui/Sticky';
import React from 'react';
import type { GreetingCardCarrierPreviewRefProps } from '../CardCarrier/CardCarrierEditor';
import { GreetingCardCarrierPreview } from '../CardCarrier/CardCarrierEditor';
import type { CardCarrierFormValues } from '../CardCarrier/CardCarrierForm';
import { cardCarrierFormId } from '../CheckoutForm/CheckoutForm';

export type GiftCardEditorFormValues = {
  nameOnCard?: string;
  customMessage?: string;
};

export type GiftCardEditorProps = FormProps & {
  active?: boolean;
  anonymous?: boolean;
  carrier?: CardCarrierFormValues;
  price?: number;
  nameOnCard?: string;
  customMessage?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  product: Product;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
} & UIComponent;

const getFormSchema = (words: string[]): FormSchemaEntry[] => [
  {
    label: 'Name On Card',
    name: `nameOnCard`,
    type: 'text',
    customValidation: [noSpecialCharacters, noEmptySpaces, noConsecutiveSpaces, unallowedWords(words)],
    maxLength: 19,
    required: true,
    props: {
      loading: words?.length === 0,
      focusScrollIntoView: 'bottom',
    },
  },
  {
    label: 'Custom Message',
    name: `customMessage`,
    type: 'text',
    maxLength: 19,
    customValidation: [noSpecialCharacters, noEmptySpaces, noConsecutiveSpaces, unallowedWords(words)],
    props: {
      loading: words?.length === 0,
      focusScrollIntoView: 'bottom',
      enterKeyHint: 'send',
      onKeyPress: (event: React.KeyboardEvent<ButtonElement>) => {
        if (event.key === 'Enter') {
          // Trigger the button element with a click
          const btnEl = document.getElementById(cardCarrierFormId + '-btn') as ButtonElement;
          if (btnEl) btnEl.click();
        }
      },
    },
  },
];

export function GiftCardEditor({
  children,
  className,
  price,
  product,
  anonymous,
  expiryMonth,
  cardImage,
  faceplate,
  carrier,
  nameOnCard,
  customMessage,
  active,
  id,
  onSubmit,
  onChange,
  buttons,
  buttonsPortal,
  ...otherProps
}: React.PropsWithChildren<GiftCardEditorProps>): React.ReactElement {
  const bem = useBemCN('gift-card-editor');

  const { data: basWordsList } = useVisaUnallowedWords();
  const schema = React.useMemo(() => getFormSchema(basWordsList), [basWordsList]);

  const [zoom, setZoom] = React.useState<number>(1);
  const [cardMarginTop, setCardMarginTop] = React.useState<number>(0);

  const greetingCardRef = React.useRef<GreetingCardCarrierPreviewRefProps>();

  const formId = id;

  const olCardWidth = 400;

  const handleResize = React.useCallback(() => {
    const screenWidth = window.screen.width - 64;
    let zoom = screenWidth / olCardWidth;
    const maxZoom = 1;
    if (zoom > maxZoom) zoom = maxZoom;

    if (window.innerWidth > 768) zoom = 0.8;
    setZoom(zoom);

    const el = document.querySelector('#giftcardeditor .gik-greeting-card-carrier-preview') as HTMLElement;
    if (!el) return;
    const cardHeight = el.offsetHeight;

    const mt = -cardHeight * 0.65 + 30;

    setCardMarginTop?.(mt);
  }, []);

  // const handleReady = React.useCallback(() => {}, [handleResize]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    setTimeout(() => {
      handleResize;
    }, 500);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  React.useEffect(() => {
    if (!active) return;

    setTimeout(() => {
      greetingCardRef.current?.setIsDone(true);
    }, 1000);
  }, [active, handleResize]);

  const handleChange = React.useCallback(
    values => {
      onChange?.({ ...values });
    },
    [onChange]
  );

  const handleSubmit = React.useCallback(
    (values, form) => {
      onSubmit?.({ ...values }, form);
    },
    [onSubmit]
  );

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <Form
        {...otherProps}
        onSubmit={handleSubmit}
        schema={schema}
        onChange={handleChange}
        initialValues={{
          nameOnCard: sanitizeText(nameOnCard),
          customMessage,
        }}
        vertical
        id={formId}
        {...bem(null, null, className)}
        render={renderProps => {
          const { isValid } = renderProps;
          // const { carrierType } = renderProps.values as GiftCardEditorFormValues;
          const formValues = renderProps.values as GiftCardEditorFormValues;

          return (
            <>
              <Sticky {...bem('sticky')} marginTop={cardMarginTop}>
                <GreetingCardCarrierPreview
                  renderButtons={false}
                  ref={greetingCardRef}
                  formValues={carrier}
                  cardImage={cardImage}
                  recipientName={carrier?.toName}
                  nameOnCard={formValues.nameOnCard}
                  customMessage={formValues.customMessage}
                  expiryMonth={expiryMonth}
                  faceplate={faceplate}
                  price={price}
                  open={true}
                  animateToDone={true}
                  // onReady={handleReady}
                  selector={'.gik-gift-card-editor .gik-greeting-card-carrier-preview__greeting-card'}
                />
              </Sticky>

              <div {...bem('custom-fields')}>
                <FormField name="nameOnCard" />
                <FormField name="customMessage" />
              </div>
              {renderPortal?.(buttons && buttons?.(isValid), () => buttonsPortal)}
            </>
          );
        }}
      />
    </div>
  );
}
