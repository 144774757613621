import { gikClassPrefix } from '@gik/core/constants';
import { useRandomInt } from '@gik/core/utils/getRandomInt';
import { Button } from '@gik/ui/Button';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { ParagraphBone } from './ParagraphBone';

const blockName = `${gikClassPrefix}-button-bone`;

export type ButtonBoneBoneProps = BaseBoneProps &
  Pick<IButtonProps, 'size' | 'squared' | 'circle' | 'pill' | 'rounded' | 'wide'> & {
    words?: number;
  };

export function ButtonBone({ className, words, ...buttonProps }: ButtonBoneBoneProps) {
  const blockClasses = classnames([blockName, className || '']);
  const randomDelta = useRandomInt(1, 2);

  return (
    <Button className={blockClasses} variant="default-outline" disabled {...buttonProps}>
      <ParagraphBone words={words ? words : randomDelta} />
    </Button>
  );
}
