export enum ExternalLoginProvider {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}

export enum LocalLoginProvider {
  EMAIL = 'email',
}

export type LoginProvider = LocalLoginProvider | ExternalLoginProvider;
export const LoginProvider = {
  ...ExternalLoginProvider,
  ...LocalLoginProvider,
};
