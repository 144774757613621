import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpAcfV3Api } from '@gik/core/api/ky/wpApi';
import { useWPAcfV3Api } from '@gik/core/api/swr/useWPAcfV3Api';
import type { IWordpressFeatureVideo } from '@gik/core/models/wordpress/WordpressFeatureVideo';

const path = 'options/options/feature_videos';

export interface IFeatureVideosResponse {
  feature_videos: IWordpressFeatureVideo[];
}

export function getFeatureVideos() {
  return wpAcfV3Api.get(path).json<IFeatureVideosResponse>();
}

export function useFeatureVideos(config?: SWRConfigInterface) {
  return useWPAcfV3Api<IFeatureVideosResponse>(path, null, config);
}
