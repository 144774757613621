import type { Product } from '@gik/core/models/gik/Product';
import noop from '@gik/core/utils/noop';
import type { ColumnWithPlugins, ITableProps } from '@gik/ui/Table';
import { Table } from '@gik/ui/Table';
import he from 'he';
import React from 'react';

export type ProductsTableProps = {
  data: Product[];
  onSelect?(slug: string): void;
} & Omit<ITableProps, 'onSelect'>;

export const ProductsTable = ({ data, onSelect = noop, ...otherProps }: ProductsTableProps) => {
  const _columns: ColumnWithPlugins<Product>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
      maxWidth: 100,
    },
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 400,
      Cell: ({ row }) => {
        return he.decode(row.original.name);
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 50,
      maxWidth: 100,
    },
    {
      Header: '#V',
      width: 40,
      maxWidth: 100,
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>{row.original.variations?.length}</span>;
      },
    },
    {
      Header: 'CheckoutType',
      accessor: 'checkoutType',
      width: 60,
      maxWidth: 100,
    },
  ];

  const columns: ColumnWithPlugins<Product>[] = _columns?.map(item => ({
    minWidth: 20,

    maxWidth: 200,
    sortType: 'basic',
    ...(item as ColumnWithPlugins<Product>),
  }));

  function handleRowClick(row) {
    onSelect(row.original.slug);
  }

  return data ? (
    <Table
      variant="neutral"
      size="xs"
      data={data}
      columns={columns}
      onRowClick={handleRowClick}
      hasFlexLayout
      hasSortBy
      hasPagination
      hasRowClick
      fullWidth
      {...otherProps}
    />
  ) : null;
};
