import { stripeTokenLocalStorage } from '@/constants';
import { navigateTo } from '@/utils/navigateTo';
import { Analytics } from '@gik/analytics';
import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { GiftCardClaimConflictResolution } from '@gik/calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import type { ClaimConflictErrorDetails, ClaimConflictResolve, OrderStatus } from '@gik/checkout/api';
import { ClaimConflictError, placeOrder } from '@gik/checkout/api';
import { translationKeys as checkoutTranslationKeys } from '@gik/checkout/i18n/en';
import type { PaymentConfirmationValues } from '@gik/checkout/types';
import { withCheckoutFormContext } from '@gik/checkout/utils/withCheckoutFormContext';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { openPrivacyPolicySheet } from '@gik/core/pages/Legal/PrivacyPolicy';
import { openTermsOfServiceSheet } from '@gik/core/pages/Legal/TermsOfService';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { sanitizeCarrierString } from '@gik/core/utils/Sanitize';
import { storage } from '@gik/core/utils/Storage';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { fireCompletedPurchaseLocalStorageKey } from '@gik/inkind-page/components/InkindPageViewRecorder/useCompletedPurchaseForPageAnalytics';
import { Button } from '@gik/ui/Button';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { MODAL_ANIMATION_LENGTH } from '@gik/ui/Modal';
import { UI } from '@gik/ui/UIManager';
import { useStripe } from '@stripe/react-stripe-js';
import React from 'react';
import { setTimeout } from 'timers';
import { PaymentConfirmation } from '../PaymentConfirmation/PaymentConfirmation';
import { PremiumPageUpgradePaymentConfirmation } from '../PaymentConfirmation/PremiumPageUpgradePaymentConfirmation';
import type { CheckoutFormPayload, CheckoutFormProps, CheckoutFormValues } from './CheckoutForm';
import { CheckoutForm } from './CheckoutForm';

export const errorStringsToCheckFor = ['invalid account.', 'your card has insufficient funds.'];

export type CheckoutFormWrapperProps = {
  onSuccess?(promoCodeId?: string): void;
  closeButtonOverrideRef?: React.MutableRefObject<() => Promise<boolean>>;

  onCloseSuccessfulPurchase?(): void;
  getAnalyticsProps?: (props: IAnalyticsProps) => IAnalyticsProps;
  cart?: CartItem[];
  recipientName?: string;
  customMessage?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  hasPGShipping?: boolean;
  buyForSomeoneElse?: boolean;
  isOpenLoop?: boolean;
  scrollToTop?(): void;
  setTitle?(title: string): void;
  setClosable?(closable: boolean): void;
} & CheckoutFormProps &
  UIComponent;

function CheckoutFormWrapperComp({
  children,
  className,
  buttonsPortal,
  inkindRouteId,
  cart,
  recipientName,
  customMessage,
  expiryMonth,
  cardImage,
  faceplate,
  hasPGShipping,
  isOpenLoop,
  buyForSomeoneElse,
  navigateToInkindPageAfterPurchase,
  onSuccess,
  onSuccessfulPurchase,
  onSubmit,
  getAnalyticsProps,
  onCloseSuccessfulPurchase,
  setClosable,
  closeButtonOverrideRef,
  scrollToTop,
  forceCheckoutType,
  ...otherProps
}: React.PropsWithChildren<CheckoutFormWrapperProps>): React.ReactElement {
  const bem = useBemCN('checkout-form-wrapper');

  const stripe = useStripe();
  const [checkoutErrorResponse, setCheckoutErrorResponse] = React.useState(null);
  const [claimErrors, setClaimErrors] = React.useState<ClaimConflictErrorDetails[]>();
  const [initialData, setInitialData] = React.useState<CheckoutFormValues>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const { data: inkind } = useInkind(inkindRouteId);

  const userId = useUserStore(state => state.id);
  const { data: user, mutate: mutateUser } = useUser(userId);

  const recipientNameSanitized = sanitizeCarrierString(inkind?.recipientFullName || '');
  const recipientNameParts = recipientNameSanitized.split(' ');
  const recipientFirstName = recipientNameParts.length > 0 ? recipientNameParts[0] : '';
  const recipientLastName = recipientNameParts.length > 1 ? recipientNameParts.slice(1).join(' ') : '';

  const {
    paymentConfirmationValues,
    setPaymentConfirmationValues,
    ignoreClaimConflict,
    setClaimConflicts,
    setGoneConflicts,
    setResolveGiftCardClaimConflictValues,
  } = useCalendarStore(state => ({
    paymentConfirmationValues: state.paymentConfirmationValues,
    setPaymentConfirmationValues: state.setPaymentConfirmationValues,
    ignoreClaimConflict: state.ignoreClaimConflict,
    setClaimConflicts: state.setClaimConflicts,
    setGoneConflicts: state.setGoneConflicts,
    setResolveGiftCardClaimConflictValues: state.setResolveGiftCardClaimConflictValues,
  }));

  React.useEffect(() => {
    // the donation product is donation to GIK, not to a page.
    // Gift boxes can be purchased for someone else outside of a page, in that case inkindRouteId won't be set.
    if (
      !inkind &&
      !isOpenLoop &&
      !cart?.some(entry => entry.checkoutType === 'donation' || (entry.checkoutType === 'giftbox' && !inkindRouteId))
    )
      return;

    // wait if the inkind page object is still loading
    if (!inkind && !buyForSomeoneElse) return;

    // wait if we have a user id but the user object hasn't loaded yet
    if (userId && !user) return;

    const billingAddress = user?.billingAddresses?.[0];

    setInitialData({
      billing: {
        country: 'US',
        firstName: billingAddress?.firstName || user?.firstName,
        lastName: billingAddress?.lastName || user?.lastName,
        state: billingAddress?.state || user?.state,
        email: billingAddress?.email || user?.emailAddress,
        phone: billingAddress?.phone,
        city: billingAddress?.city,
        companyName: billingAddress?.companyName,
        postalCode: billingAddress?.postalCode,
        address1: billingAddress?.address1,
        address2: billingAddress?.address2,
        stripeToken: stripeTokenLocalStorage ? storage.getWithExpiry('stripe-token') : undefined,
        saveAddress: true,
        paymentMethod: 'stripe',
      },
      shipping: {
        country: 'US',
        email: inkind?.recipientEmail,
        firstName: recipientFirstName,
        lastName: recipientLastName,
        address1: inkind?.address,
        address2: inkind?.address2,
        city: inkind?.city,
        postalCode: inkind?.zip,
        state: inkind?.stateCode,
      },
      summary: { subscribeToNewsletter: true },
      cart,
    });
  }, [inkind, recipientFirstName, recipientLastName, cart, user, userId, inkindRouteId, isOpenLoop, buyForSomeoneElse]);

  const handleSubmit = React.useCallback(
    async (payload: CheckoutFormPayload, updateCheckoutStatusText: (status: OrderStatus) => void) => {
      setCheckoutErrorResponse(undefined);
      setErrorMessage(undefined);

      // if carrier is available, use the carrier.toName as the shipping first and last name
      if (payload.carrier) {
        const recipientNameSanitized = sanitizeCarrierString(payload.carrier.toName || inkind?.recipientFullName || '');
        const recipientNameParts = recipientNameSanitized.split(' ');
        const recipientFirstName = recipientNameParts.length > 0 ? recipientNameParts[0] : '';
        const recipientLastName = recipientNameParts.length > 1 ? recipientNameParts.slice(1).join(' ') : '';

        payload.shipping.firstName = recipientFirstName;
        payload.shipping.lastName = recipientLastName;
      }

      payload.buyForSomeoneElse = buyForSomeoneElse;

      payload.forceCheckoutType = forceCheckoutType;

      // FIXME: logic repeated in CalendarClaimCheckoutForm, refactor
      let response: PaymentConfirmationValues;
      try {
        response = await placeOrder(stripe, payload, false, updateCheckoutStatusText);
      } catch (err) {
        // FIXME: there are no claim conflicts from a wishlist purchase, this shouldn't be here
        if (err instanceof ClaimConflictError) {
          Analytics.fireEvent(AnalyticsEvents.ClaimConflictsOpen, {
            userId,
            inkindRouteId,
            data: err.data,
          });

          setClaimErrors(err.data);
          return false;
        }
        if (err.stat)
          if (err.errors) {
            logger.error('Checkout errors', err.errors);
            // Analytics.fireUserNotice(errorMessage, 'errorCheckout');
            setClaimErrors(err.errors);
            return false;
          }

        logger.error('Checkout error', err);
        let errorMessage: string = err.message || i18n.t(checkoutTranslationKeys.genericBackendError);

        if (errorMessage.toLowerCase().startsWith('invalid account.')) {
          errorMessage =
            'This card number appears to be invalid. Please double-check your entry and try again. If you continue to receive this error, contact your card issuer.';
        }

        const shouldUseAnalytics = !errorStringsToCheckFor.some(error =>
          errorMessage.toLowerCase().includes(error.toLowerCase())
        );

        if (shouldUseAnalytics) {
          Analytics.fireUserNotice(errorMessage, 'errorCheckout');
        }

        setCheckoutErrorResponse(errorMessage);

        return false;
      }

      storage.remove('stripe-token');
      storage.remove('stripe-token-last-4-digits');

      Analytics.fireEvent(
        AnalyticsEvents.CheckoutCompletePurchase,
        getAnalyticsProps?.({
          total: response.total.toString(),
          tip: response.tip.toString(),
          orderID: response.orderId.toString(),
          deliveryMethod: payload.shipping?.deliveryMethod,
          shippingOptionName: payload.shipping?.shippingOptionName,
        })
      );

      const numberOfPhysical = payload.products.filter(p => p.productType === 'physical').length;

      setPaymentConfirmationValues({
        ...(paymentConfirmationValues || {}),
        ...response,
        products: payload.products,
        ...(payload.products.some(p => p.checkoutType === 'perfectgift' && p.productType === 'physical')
          ? {
              shippingCost: parseFloat(payload.shipping.shippingValue) * numberOfPhysical,
              shipping: payload.shipping,
              pgCarrier: payload.products.find(p => p.carrier?.carrierType != undefined).carrier.carrierType,
            }
          : {}),
        ...(payload?.products[0]?.checkoutType === 'perfectgift' && payload?.products[0]?.productType === 'physical'
          ? {
              shippingCost: parseFloat(payload.shipping.shippingValue) * numberOfPhysical,
              shipping: payload.shipping,
              pgCarrier: payload.products.find(p => p.carrier?.carrierType != undefined).carrier.carrierType,
            }
          : {}),
      });

      // FIXME: again, conflicts are only for calendar claim purchases, this shouldn't be here
      const claimConflicts = response.claims?.filter(claim => claim.status === 'Conflict');
      const goneConflicts = response.claims?.filter(claim => claim.status === 'Gone');

      if ((!claimConflicts || claimConflicts.length === 0) && (!goneConflicts || goneConflicts.length === 0)) {
        onSuccess?.();
        return true;
      }

      if (claimConflicts?.length > 0 && !ignoreClaimConflict) {
        setResolveGiftCardClaimConflictValues({
          checkoutFormPayload: payload,
          paymentConfirmation: response,
        });

        setClaimConflicts(claimConflicts);
      }

      if (goneConflicts?.length > 0) {
        setGoneConflicts(goneConflicts);
      }

      return true;
    },
    [
      buyForSomeoneElse,
      forceCheckoutType,
      getAnalyticsProps,
      ignoreClaimConflict,
      inkind,
      inkindRouteId,
      onSuccess,
      paymentConfirmationValues,
      setClaimConflicts,
      setGoneConflicts,
      setPaymentConfirmationValues,
      setResolveGiftCardClaimConflictValues,
      stripe,
      userId,
    ]
  );

  const handleSuccess = React.useCallback(() => {
    onSuccess?.();
    onSuccessfulPurchase?.();
  }, [onSuccess, onSuccessfulPurchase]);

  const handleSuccessfulPurchase = React.useCallback(
    (promoCode?: string) => {
      // refresh the calendar
      useCalendarStore.getState().reloadFunction(useCalendarStore.getState().selectedMonth);

      // setCheckoutFormModalOpen(false);
      UI.closeAllDialogs();
      onSuccessfulPurchase?.(promoCode);

      // NOTE: only used for premium page upgrade modal
      if (paymentConfirmationValues) {
        onCloseSuccessfulPurchase();
      }
    },
    [onSuccessfulPurchase, onCloseSuccessfulPurchase, paymentConfirmationValues]
  );

  const handleDone = React.useCallback(() => {
    if (inkindRouteId && navigateToInkindPageAfterPurchase) {
      // TODO: after SPA migration remove the below line + useCompletedPurchaseForPageAnalytics as SPA redirection will not interrupt events
      localStorage.setItem(fireCompletedPurchaseLocalStorageKey, 'true');
      navigateTo(`/inkinds/${inkindRouteId}`);
    }
    // must close all dialogs in order to close both the purchase confirmation modal and the choose a giftbox modal
    UI.closeAllDialogs();

    if (paymentConfirmationValues) {
      Analytics.fireEvent(AnalyticsEvents.CompletedPurchaseCloseModal, {});
      setTimeout(() => setPaymentConfirmationValues(null), MODAL_ANIMATION_LENGTH);
    }
  }, [inkindRouteId, navigateToInkindPageAfterPurchase, paymentConfirmationValues, setPaymentConfirmationValues]);

  const handleUpdateClaimConflicts = React.useCallback((suggestions: ClaimConflictResolve[]) => {
    setErrorMessage(null);
    setCheckoutErrorResponse(null);
    setClaimErrors(null);
  }, []);

  const { claimConflicts, goneConflicts, resolveGiftCardClaimConflictValues } = useCalendarStore(state => ({
    claimConflicts: state.claimConflicts,
    goneConflicts: state.goneConflicts,
    resolveGiftCardClaimConflictValues: state.resolveGiftCardClaimConflictValues,
  }));

  if (!initialData) return <LoadingSpinner />;

  const showGiftCardClaimConflictResolution =
    resolveGiftCardClaimConflictValues &&
    (claimConflicts?.filter(c => c.availableDates?.length > 0)?.length > 0 ||
      goneConflicts?.filter(c => c.availableDates?.length > 0)?.length > 0);

  if (paymentConfirmationValues) {
    return (
      <>
        {paymentConfirmationValues.products[0]?.checkoutType === 'gik-premium' ? (
          <PremiumPageUpgradePaymentConfirmation
            productName={paymentConfirmationValues.productName}
            values={paymentConfirmationValues}
            showPromoMessage={initialData.cart?.some(product => product.checkoutType === 'gik-premium')}
            inkindRouteId={inkindRouteId}
          />
        ) : showGiftCardClaimConflictResolution ? (
          <GiftCardClaimConflictResolution
            resolveGiftCardClaimConflictValues={resolveGiftCardClaimConflictValues}
            buttonsPortal={buttonsPortal}
            onSuccess={onSuccess}
            closeButtonOverrideRef={closeButtonOverrideRef}
            setModalClosable={setClosable}
          />
        ) : (
          <PaymentConfirmation
            productName={paymentConfirmationValues.productName}
            values={paymentConfirmationValues}
            shipping={paymentConfirmationValues.products.some(
              p => p.checkoutType === 'perfectgift' && p.productType === 'physical'
            )}
            inkindRouteId={inkindRouteId}
          />
        )}
        {!showGiftCardClaimConflictResolution &&
          renderPortal(
            <div className={'gik-form__actions-new gik-form__actions-new--centered'}>
              <Button key="btn-confirmation-done" variant="primary" onClick={handleDone}>
                Done
              </Button>
            </div>,
            () => buttonsPortal
          )}
      </>
    );
  }

  return (
    <div {...bem(null, null, className)}>
      <CheckoutForm
        {...otherProps}
        paymentConfirmationValues={paymentConfirmationValues}
        scrollToTop={scrollToTop}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        onSuccessfulPurchase={handleSuccessfulPurchase}
        showPromoInput={initialData.cart?.some(product => product.checkoutType === 'gik-premium')}
        initialData={initialData}
        errorMessage={checkoutErrorResponse || errorMessage}
        buttonsPortal={buttonsPortal}
        inkindRouteId={inkindRouteId}
        onBillingInfoChanged={mutateUser}
        onOpenTermsOfService={openTermsOfServiceSheet}
        onOpenPrivacyPolicy={openPrivacyPolicySheet}
        onUpdateClaimConflicts={handleUpdateClaimConflicts}
        setClosable={setClosable}
        hasPGShipping={hasPGShipping}
        recipientName={recipientName}
        customMessage={customMessage}
        expiryMonth={expiryMonth}
        cardImage={cardImage}
        faceplate={faceplate}
        buyForSomeoneElse={buyForSomeoneElse}
        claimErrors={claimErrors}
        forceCheckoutType={forceCheckoutType}
      />
    </div>
  );
}

export const CheckoutFormWrapper = withComponentErrorBoundary(withCheckoutFormContext(CheckoutFormWrapperComp));
