import bemBlock from '@gik/core/utils/bemBlock';
import { hideIntercomDefaultLauncher, showIntercomDefaultLauncher } from '@gik/core/utils/Intercom';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { ModalHeader } from '@gik/ui/Modal/ModalHeader';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { IInkindPageSettingsFormProps } from './InkindPageSettingsForm';
import { InkindPageSettingsForm } from './InkindPageSettingsForm';
import { InkindPageSettingsPremiumBadge } from './InkindPageSettingsPremiumBadge';
import { PageSettingsScrollNavBar } from './utils/PageSettingsScrollNavBar';

export const InkindPageSettingsModalBlockName = 'inkind-page-settings-modal';
const bem = bemBlock(InkindPageSettingsModalBlockName);

const modalFooterId = 'inkind-page-settings-modal-footer-id';
export const modalContentId = 'inkind-page-settings-modal-content-id';

export const openInkindPageSettingsModal = (props: IInkindPageSettingsFormProps): void => {
  const modalHeader = (closeFn: () => void) => (
    <ModalHeader
      closable={false}
      content={<PageSettingsScrollNavBar containerId={modalContentId} />}
      onClose={closeFn}
      title={i18n.t(translationKeys.pageSettings)}
      append={<InkindPageSettingsPremiumBadge />}
      onBack={null}
    />
  );

  // TODO: move this logic into UI.dialog as a property so it works on all modals
  hideIntercomDefaultLauncher();

  UI.dialog(
    ({ close }) => (
      <InkindPageSettingsForm {...props} buttonsPortal={() => document.getElementById(modalFooterId)} onClose={close} />
    ),
    {
      className: bem(),
      autowidth: false,
      buttons: () => [<div key="footer" id={modalFooterId} />],
      modalProps: {
        contentId: modalContentId,
        bodyOpenClassName: bem('body-modal-open'),
      },
      header: ({ close }) => modalHeader(close),
    }
  ).then(() => showIntercomDefaultLauncher());
};
