import { gikClassPrefix } from '@gik/core/constants';
import { useRandomInt } from '@gik/core/utils/getRandomInt';
import classnames from 'classnames';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';

const blockName = `${gikClassPrefix}-word-bone`;

export interface WordBoneProps extends BaseBoneProps {
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

export function WordBone({ className, width, minWidth = 15, maxWidth = 60 }: WordBoneProps) {
  const blockClasses = classnames([blockName, className || '']);
  const randomDelta = useRandomInt(0, 100);

  const getWidth = () => {
    return width ? width : `${minWidth + (randomDelta / 100) * (maxWidth - minWidth)}px`;
  };

  return (
    <div
      className={blockClasses}
      style={{
        width: getWidth(),
      }}
    >
      <div className={`${blockName}__bone`} />
    </div>
  );
}
