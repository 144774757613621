import { isEmpty } from '@gik/core/utils/validator';
import type { CustomValidation } from '@gik/ui/Form/types';

// please also see @gik/core/utils/validator for more validation functions

export const noEmptySpaces: CustomValidation = {
  validate: (value: string) => isEmpty(value?.trim()),
  message: 'This field does not allow only empty spaces',
};

export const noSpecialCharacters: CustomValidation = {
  validate: (value: string) => !/^[a-zA-Z0-9\s\-.]*$/.test(value),
  message: 'This field does not allow special characters',
};

export const noConsecutiveSpaces: CustomValidation = {
  validate: (value: string) => /\s{2,}/.test(value),
  message: 'This field does not allow consecutive spaces',
};

export const unallowedWords: (words: string[]) => CustomValidation = (words: string[] = []) => ({
  validate: (value: string) => words.some(word => value.toLowerCase().includes(word.toLowerCase())),
  message: 'This field contains words or phrases that are not permitted',
});
