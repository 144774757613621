import React from 'react';
import { useDevStore } from '../store/DevStore';
import { useLocalStorage } from 'react-use';
import { devToolsStorageKeyVisibility } from '../components/DevTools/DevTools';

/**
 * Useful for debugging or simply as a placeholder way to render objects with no
 * specific components capable of render it yet.
 * @param object Any object to be rendered
 */
export function asPlaceholderReactNode(data) {
  return (
    <div className="gik-placeholder-background">
      <pre
        style={{
          padding: 0,
          background: 'unset',
        }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
}

export function Debug({ data }: { data }) {
  const [devToolsEnabled] = useLocalStorage<boolean>(devToolsStorageKeyVisibility, false);
  const streamDebug = useDevStore(state => state.streamDebug);

  if (!(devToolsEnabled && streamDebug)) return null;
  return asPlaceholderReactNode(data);
}
