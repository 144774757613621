import { isBrowser } from '@/utils/environment';

/**
 * Abstraction around local storage with extra functionalities.
 */
export class Storage {
  public get(key: string) {
    if (!isBrowser()) {
      return null;
    }
    return localStorage.getItem(key);
  }
  public getJSON(key: string) {
    const str = this.get(key);
    if (str) return JSON.parse(str);
  }
  public set(key: string, value: string) {
    if (!isBrowser()) {
      return;
    }
    localStorage.setItem(key, value);
  }
  public remove(key: string) {
    if (!isBrowser()) {
      return;
    }
    localStorage.removeItem(key);
  }

  /**
   * Storing Items with Expiry Time
   *
   * @param key   unique key
   * @param value value to be stored
   * @param ttl   time to live in seconds
   */
  public setWithExpiry(key: string, value: string, ttl: number) {
    if (!isBrowser()) {
      return;
    }
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl * 1000,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Getting the value of a key that was stored with an expiry.
   *
   * @param key unique key
   */
  public getWithExpiry(key: string) {
    if (!isBrowser()) {
      return;
    }
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}

export const storage = new Storage();
