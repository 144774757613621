import TranslationUtils from '@gik/core/utils/TranslationUtils';

// TODO: normalize and sanitize names
const translations = {
  title: 'HTTP ERROR 404',
  subTitle: '(NOT FOUND)',
  description:
    "Sorry, we couldn't find what you were looking for. Sad face :(.\nMake a selection in the navigation above or the footer on the bottom.",
  searchPlaceholder: 'Search pages, products & articles',
};

export default {
  notfound: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('notfound', translations);
