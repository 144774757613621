import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'grubhub-button';

const translations = {
  orderOnGrubhub: 'ORDER ON GRUBHUB',
  browseGrubhub: 'BROWSE GRUBHUB',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
