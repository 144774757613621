import type { ISignInFlowCalloutBlockProps } from '@gik/auth/components/SignInFlow/SignInFlowCallout';
import { SignInFlowCallout } from '@gik/auth/components/SignInFlow/SignInFlowCallout';
import type { ISignInFlowContentProps } from '@gik/auth/components/SignInFlow/SignInFlowContent';
import { SignInFlowContent } from '@gik/auth/components/SignInFlow/SignInFlowContent';
import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type ISignInFlowProps = UIComponent & BreakpointType & ISignInFlowContentProps & ISignInFlowCalloutBlockProps;

export function SignInFlow({
  callout,
  startOn,
  copyVariant,
  routeId,
  className,
  breakpoint,
  onSuccess,
  onForgotPassword,
  initialValues,
  trackingId,
  headerRef,
  ...otherProps
}: ISignInFlowProps) {
  const bem = useBemCN('sign-in-flow');

  return (
    <article {...bem(null, breakpointBem(breakpoint), className)} {...otherProps}>
      <main {...bem('content-container')}>
        <SignInFlowContent
          startOn={startOn}
          copyVariant={copyVariant}
          routeId={routeId}
          trackingId={trackingId}
          onSuccess={onSuccess}
          onForgotPassword={onForgotPassword}
          initialValues={initialValues}
          headerRef={headerRef}
        />
      </main>
      <aside {...bem('callout-block-container')}>
        <SignInFlowCallout callout={callout} />
      </aside>
    </article>
  );
}
