import { stripeTokenLocalStorage } from '@/constants';
import { Analytics } from '@gik/analytics';
import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useSiteSettings } from '@gik/api/siteSettings/siteSettings';
import { useUser } from '@gik/api/users/user';
import { isBillingFormFilledOut, scrollToPayment } from '@gik/calendar/utils/CalendarModals';
import { useProducts } from '@gik/checkout/api';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import type { CheckoutFormInitiatedOnType } from '@gik/checkout/types';
import { openCheckoutFormModal } from '@gik/checkout/utils/openCheckoutFormModal';
import { openExternalProductInterstitialModal } from '@gik/checkout/utils/openExternalProductInterstitialModal';
import { alertNoNonD2CUrlAvailable, openNonD2CUrl } from '@gik/checkout/utils/productUtils';
import { useApi } from '@gik/core/api/swr/useApi';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import { useUserStore } from '@gik/core/store/UserStore';
import sleep from '@gik/core/utils/sleep';
import { storage } from '@gik/core/utils/Storage';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { InkindPageViewHistoryItem } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import isInkindPageRecipientInfoComplete from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import { withStripe } from '@gik/ui/Stripe/withStripe';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { CheckoutFormValues } from '../CheckoutForm/CheckoutForm';
import InkindPicker from '../InkindPicker/InkindPicker';

export interface CheckoutLauncherProps {
  onSuccessfulPurchase?(promoCodeId?: string): void;
  onCloseSuccessfulPurchase?(): void;
  onCloseCheckout?(): boolean;
  cart?: CartItem[];
  cachedProducts?: Product[]; // used to avoid requesting products again on the wishlist
  inkindPageViewHistory?: InkindPageViewHistoryItem[];
  // TODO: add loading state
  child(onClick: (ev: React.MouseEvent<HTMLElement>) => void, isLoading: boolean): React.ReactElement;

  // these could re-use the CheckoutFormProps
  initiatedOn: CheckoutFormInitiatedOnType;
  initialInkindRouteId?: string;
  skipProductsCheckoutStep?: boolean;
  hideProductsCheckoutStep?: boolean;
  navigateToInkindPageAfterPurchase?: boolean;

  onPickAlternativeVariation?: () => Promise<void>;

  hasPhysicalCard?: boolean;
  cardImage?: string;
}

function CheckoutLauncherComp({
  initiatedOn,
  initialInkindRouteId,
  skipProductsCheckoutStep,
  hideProductsCheckoutStep,
  hasPhysicalCard,
  cart,
  inkindPageViewHistory,
  cachedProducts,
  navigateToInkindPageAfterPurchase,
  cardImage,
  onSuccessfulPurchase,
  onPickAlternativeVariation,
  onCloseSuccessfulPurchase,
  onCloseCheckout,
  child,
}: CheckoutLauncherProps): React.ReactElement {
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);
  const { data: uncachedProducts } = useProducts({
    productIds: cachedProducts ? null : cart?.length ? cart?.map(item => item.productId) : null,
  });

  const { data: siteSettings } = useSiteSettings();

  // @ts-ignore
  const recipientName = cart?.[0]?.recipientName;
  const customMessage = cart?.[0]?.customMessage;
  const expiryMonth = cart?.[0]?.expiryMonth;
  const faceplate = cart?.[0]?.faceplate;

  const products = cachedProducts || uncachedProducts;

  const [shouldPerformAction, setShouldPerformAction] = React.useState<boolean>(false);
  const [chooseInkindPopoverOpen, setChooseInkindPopoverOpen] = React.useState(false);
  const [selectedInkindRouteId, setSelectedInkindRouteId] = React.useState(initialInkindRouteId);
  const { data: selectedInkind, mutate: reloadSelectedInkind } = useInkind(selectedInkindRouteId);

  const isLoading = !products?.length || (userId && !user);

  const productNonD2CUrl = products?.[0].productNonD2CUrl;
  const disableInterstitial = products?.[0].disableInterstitial;

  const isOpenLoop = products?.[0]?.isPerfectgiftProduct && products?.[0]?.isToggleable;
  const hasNonD2CProduct = products?.some(product => product.isNonD2C);
  const hasGiftbox = products?.some(product => product.checkoutType === 'giftbox');
  const isDonation = products?.some(product => product.checkoutType === 'donation');
  const hasGikPremium = products?.some(product => product.checkoutType === 'gik-premium');
  const hasVirtualProduct = products?.some(product => product.checkoutType === 'virtual-product'); // GIK virtual product with vendor
  const hasShipping = hasGiftbox || hasVirtualProduct;
  const hasIntegratedCheckout = hasNonD2CProduct || hasShipping || hasGikPremium || isDonation;

  const [initialData, setInitialData] = React.useState<CheckoutFormValues>(null);

  const { data: wcStatus } = useApi<boolean>(`orders/wc-status`);

  // if wcStatus is off (killswitch), then the interstitial should always be used
  let forceInterstitial: boolean;

  if (wcStatus === false) {
    if (!user || !user.emailAddress) {
      forceInterstitial = true;
    } else {
      if (!user.emailAddress.endsWith('giveinkind.com') && !user.emailAddress.endsWith('codiv.tech')) {
        forceInterstitial = true;
      } else {
        forceInterstitial = false;
      }
    }
  }

  const getAnalyticsProps = React.useCallback(
    (props: IAnalyticsProps = {}): IAnalyticsProps => {
      return {
        productNames: products?.map(item => item.name).join(','),
        productIds: products?.map(item => item.id).join(','),
        productCheckoutTypes: products?.map(item => item.checkoutType).join(','),
        inkindRouteId: selectedInkindRouteId,
        userId, // id of the purchaser
        initiatedOn,
        ...props,
      };
    },
    [initiatedOn, products, selectedInkindRouteId, userId]
  );

  const openExternalProductInterstitial = React.useCallback(() => {
    openExternalProductInterstitialModal({
      sourceName: ((products || [])[0]?.sources || [])[0]?.name || 'Unknown',
      externalLink: productNonD2CUrl,
      products: products,
      inkindRouteId: selectedInkindRouteId,
      initiatedOn: initiatedOn,
      recipientEmail: !hasGiftbox && selectedInkind ? selectedInkind.recipientEmail : undefined,
      shippingAddressLines: selectedInkind
        ? [
            selectedInkind.recipientFullName,
            selectedInkind.address,
            selectedInkind.address2,
            `${selectedInkind.location} ${selectedInkind.zip}`,
          ]
        : undefined,
      onClose: () => UI.closeAllDialogs(),
    });
  }, [hasGiftbox, initiatedOn, productNonD2CUrl, products, selectedInkind, selectedInkindRouteId]);

  const openExternalProduct = React.useCallback(() => {
    if (disableInterstitial) {
      const productNonD2CUrl = products?.length ? products[0].productNonD2CUrl : null;
      if (productNonD2CUrl) {
        openNonD2CUrl(productNonD2CUrl);
      } else {
        alertNoNonD2CUrlAvailable();
      }
    } else {
      openExternalProductInterstitial();
    }
  }, [disableInterstitial, products, openExternalProductInterstitial]);

  const openInterstitial = React.useCallback(
    (withInfo = false) => {
      setChooseInkindPopoverOpen(false);
      if (withInfo === false) setSelectedInkindRouteId(null);
      openExternalProductInterstitial();
      Analytics.fireEvent(AnalyticsEvents.BuySelectPage, getAnalyticsProps());
    },
    [getAnalyticsProps, openExternalProductInterstitial]
  );

  const handleForceInterstitial = React.useCallback(() => {
    if (!hasIntegratedCheckout) {
      openExternalProduct();
    } else {
      openInterstitial(true);
    }
  }, [hasIntegratedCheckout, openExternalProduct, openInterstitial]);

  const getInitialData = React.useCallback(() => {
    // FIXME: when creating an inkind page, should specify recipient first and last name.
    // from Rigo:
    // - For gift boxes that's ok. All that means is that the middle name and last name will potentially be together in the last name field.
    // - Not an issue that can easily be solved as a lot of people put "The Smith Family" as the recipient name

    const recipientNameParts = (selectedInkind?.recipientFullName || '').split(' ');
    const recipientFirstName = recipientNameParts.length > 0 ? recipientNameParts[0] : '';
    const recipientLastName = recipientNameParts.length > 1 ? recipientNameParts.slice(1).join(' ') : '';

    const billingAddress = user?.billingAddresses?.[0];

    return {
      billing: {
        country: 'US',
        firstName: billingAddress?.firstName || user?.firstName,
        lastName: billingAddress?.lastName || user?.lastName,
        state: billingAddress?.state || user?.state,
        email: billingAddress?.email || user?.emailAddress,
        phone: billingAddress?.phone,
        city: billingAddress?.city,
        companyName: billingAddress?.companyName,
        postalCode: billingAddress?.postalCode,
        address1: billingAddress?.address1,
        address2: billingAddress?.address2,
        stripeToken: stripeTokenLocalStorage ? storage.getWithExpiry('stripe-token') : undefined,
        saveAddress: true,
      },
      shipping: {
        country: 'US',
        email: selectedInkind?.recipientEmail,
        firstName: recipientFirstName,
        lastName: recipientLastName,
        address1: selectedInkind?.address,
        address2: selectedInkind?.address2,
        city: selectedInkind?.city,
        postalCode: selectedInkind?.zip,
        state: selectedInkind?.stateCode,
      },
      summary: { subscribeToNewsletter: true },
      cart,
    } as CheckoutFormValues;
  }, [cart, user, selectedInkind]);

  const handleCompletePurchase = React.useCallback(async () => {
    await sleep();
    if (await isBillingFormFilledOut()) {
      useCheckoutFormModalStore.getState().billingFormRef.current?.submitForm();
    } else {
      scrollToPayment();
    }
  }, []);

  const openCheckoutModal = React.useCallback(
    (inkindRouteId: string, buyForSomeoneElse = false) => {
      openCheckoutFormModal({
        cart,
        recipientName,
        customMessage,
        expiryMonth,
        cardImage,
        faceplate,
        inkindRouteId,
        inkindPageViewHistory,
        isOpenLoop,
        buyForSomeoneElse,
        hasCardCarrier: hasPhysicalCard,
        hasPhysicalCard: hasPhysicalCard,
        onClose: () => onCloseCheckout?.(),
        onCompletePurchase: handleCompletePurchase,
        contentId: 'CheckoutFormContent',
        formProps: {
          initialData: getInitialData(),
          navigateToInkindPageAfterPurchase,
          skipProducts: skipProductsCheckoutStep,
          hideProductsStep: hideProductsCheckoutStep,
          shippingEditable: hasShipping && (!selectedInkind || !isInkindPageRecipientInfoComplete(selectedInkind)),
          shipping: hasShipping || hasPhysicalCard,
          hasShipping: hasGiftbox,
          hasPGShipping: hasPhysicalCard,
          inkindRouteId,
          initiatedOn,
          onSuccessfulPurchase,
          getAnalyticsProps,
        },
      });
    },
    [
      cart,
      recipientName,
      customMessage,
      expiryMonth,
      cardImage,
      faceplate,
      inkindPageViewHistory,
      isOpenLoop,
      hasPhysicalCard,
      handleCompletePurchase,
      getInitialData,
      navigateToInkindPageAfterPurchase,
      skipProductsCheckoutStep,
      hideProductsCheckoutStep,
      hasShipping,
      selectedInkind,
      hasGiftbox,
      initiatedOn,
      onSuccessfulPurchase,
      getAnalyticsProps,
      onCloseCheckout,
    ]
  );

  const handleTriggerPopover = React.useCallback(() => {
    if (!hasIntegratedCheckout && (!inkindPageViewHistory || !inkindPageViewHistory.length || initialInkindRouteId)) {
      openExternalProduct();
    } else if ((initialInkindRouteId || isDonation) && hasIntegratedCheckout) {
      if (forceInterstitial) {
        handleForceInterstitial();
      } else {
        // buying for a page - launch checkout modal
        openCheckoutModal(initialInkindRouteId);
      }
    } else {
      setChooseInkindPopoverOpen(true);
    }
    Analytics.fireEvent(AnalyticsEvents.BuyClick, getAnalyticsProps());
  }, [
    forceInterstitial,
    getAnalyticsProps,
    handleForceInterstitial,
    hasIntegratedCheckout,
    initialInkindRouteId,
    inkindPageViewHistory,
    isDonation,
    openExternalProduct,
    openCheckoutModal,
  ]);

  const onBuyForSomeoneElse = React.useCallback(() => {
    Analytics.fireEvent(AnalyticsEvents.BuyForSomeoneElse, getAnalyticsProps());
    setChooseInkindPopoverOpen(false);

    if (hasGiftbox || hasVirtualProduct || (isOpenLoop && siteSettings?.enableOLPurchaseForSomeoneElse == 'true')) {
      // setBuyForSomeoneElse(true);
      openCheckoutModal(initialInkindRouteId, true);
      // setCheckoutFormModalOpen(true);
    } else if (productNonD2CUrl) {
      if (disableInterstitial) {
        openNonD2CUrl(productNonD2CUrl);
      } else {
        openInterstitial();
      }
    } else {
      alertNoNonD2CUrlAvailable();
    }
  }, [
    getAnalyticsProps,
    hasGiftbox,
    hasVirtualProduct,
    isOpenLoop,
    siteSettings,
    productNonD2CUrl,
    openCheckoutModal,
    initialInkindRouteId,
    disableInterstitial,
    openInterstitial,
  ]);

  React.useEffect(() => {
    (async () => {
      if (!selectedInkind || !shouldPerformAction) return;

      if (
        cart?.[0]?.checkoutType === CheckoutType.Perfectgift &&
        cart?.[0]?.productType === 'physical' &&
        !isInkindPageRecipientInfoComplete(selectedInkind)
      ) {
        const recipientNameParts = (selectedInkind?.recipientFullName || '').split(' ');
        const recipientFirstName = recipientNameParts.length > 0 ? recipientNameParts[0] : '';
        const recipientLastName = recipientNameParts.length > 1 ? recipientNameParts.slice(1).join(' ') : '';

        if (
          await UI.confirm(
            <>
              <p>
                The page organizer has not provided an address for {recipientFirstName || selectedInkind.title}.<br />
                You can still send your support in the form of a digital gift card.
              </p>
            </>,
            {
              title: `We don’t have ${recipientFirstName || selectedInkind.title}’s address`,
              autowidth: true,
              okText: `Purchase ${cart?.[0]?.name}`,
              className: 'gik-checkout-launcher__shipping-not-complete',
            }
          )
        ) {
          await onPickAlternativeVariation?.();
          return void setShouldPerformAction(true);
        } else {
          return void setShouldPerformAction(false);
        }
      }

      openCheckoutModal(selectedInkindRouteId);

      Analytics.fireEvent(AnalyticsEvents.BuySelectPage, getAnalyticsProps());

      if (forceInterstitial) {
        handleForceInterstitial();
        return;
      }

      if (!hasIntegratedCheckout) {
        openExternalProduct();
      } else {
        // setCheckoutFormModalOpen(true);
        openCheckoutModal(selectedInkindRouteId);
      }

      setShouldPerformAction(false);
    })();
  }, [
    cart,
    forceInterstitial,
    getAnalyticsProps,
    handleForceInterstitial,
    hasIntegratedCheckout,
    hasPhysicalCard,
    hasShipping,
    onPickAlternativeVariation,
    openCheckoutModal,
    openExternalProduct,
    selectedInkind,
    selectedInkindRouteId,
    shouldPerformAction,
  ]);

  const onInkindPageChosen = React.useCallback(
    (inkindRouteId: string) => {
      setChooseInkindPopoverOpen(false);
      setSelectedInkindRouteId(inkindRouteId);
      setShouldPerformAction(true);
      reloadSelectedInkind();
    },
    [reloadSelectedInkind]
  );

  return (
    <InkindPicker
      buyForSomeoneElseEnabled
      popoverTrigger={child(handleTriggerPopover, isLoading)}
      inkindPageViewHistory={inkindPageViewHistory}
      isOpen={chooseInkindPopoverOpen}
      productNonD2CUrl={productNonD2CUrl}
      disableInterstitial={disableInterstitial}
      onClose={() => setChooseInkindPopoverOpen(false)}
      onBuyForSomeoneElse={onBuyForSomeoneElse}
      onInkindPageChosen={onInkindPageChosen}
    />
  );
}

export const CheckoutLauncher = withComponentErrorBoundary(withStripe(CheckoutLauncherComp));
