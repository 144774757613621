import { SaveAddressField } from '@gik/core/form/fields/SaveBillingAddressField';
import type { FormSchemaEntry } from '@gik/ui/Form';
import type { RefObject } from 'react';
import { ShippingFormSchema } from '../ShippingForm/ShippingFormSchema';

export const BillingFormSchema: (
  ref?: RefObject<HTMLElement>,
  isLoggedIn?: boolean,
  nameReadOnly?: boolean,

  storedBillingDetails?: boolean,
  autoscroll?: boolean
) => FormSchemaEntry[] = (ref, isLoggedIn, nameReadOnly, storedBillingDetails, autoscroll) =>
  ShippingFormSchema({
    ref,
    hasEmail: true,
    emailReadOnly: isLoggedIn,
    nameReadOnly,
    lastNameRequired: true,
    autoscroll,
  }).concat([SaveAddressField(storedBillingDetails)]);
