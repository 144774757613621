import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export type HStackProps = {
  wrap?: boolean;
} & UIComponent;

export function HStack({
  children,
  wrap,
  className,
  ...otherProps
}: React.PropsWithChildren<HStackProps>): React.ReactElement {
  const bem = useBemCN('hstack');

  return (
    <div {...bem(null, [{ wrap }], className)} {...otherProps}>
      {children}
    </div>
  );
}
