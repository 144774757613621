import { useInkindsInfinite } from '@gik/api/inkinds/inkinds';
import { useUserPages } from '@gik/api/inkinds/userPages';
import type { APIWithPagination } from '@gik/core/api/BaseAPIConfig';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { can } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { fuzzysearch } from '@gik/core/utils/string';
import type { InkindPageViewHistoryItem } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import { Button } from '@gik/ui/Button';
import type { IInkindListItemProps } from '@gik/ui/gik/InkindListItem';
import { InkindListItem } from '@gik/ui/gik/InkindListItem';
import { InfiniteLoaderSWR } from '@gik/ui/InfiniteLoader/InfiniteLoaderSWR';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { PopoverListItem } from '@gik/ui/PopoverListItem';
import { SearchInput } from '@gik/ui/SearchInput';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';

export type IInkindPickerContentProps = {
  buyForSomeoneElseEnabled?: boolean;
  inkindPageViewHistory: InkindPageViewHistoryItem[];
  onInkindPageChosen(inkindRouteId: string): void;
  onBuyForSomeoneElse(): void;
} & UIComponent;

export default function InkindPickerContent({
  onInkindPageChosen,
  onBuyForSomeoneElse,
  inkindPageViewHistory,
  buyForSomeoneElseEnabled,
  className,
}: IInkindPickerContentProps) {
  const historyRouteIds = inkindPageViewHistory ? inkindPageViewHistory.map(h => h.inkindRouteId) : [];
  const userId = useUserStore(state => state.id);

  const bem = useBemCN('inkind-picker');

  const [searchStr, setSearchStr] = React.useState<string>();

  const { data: userPages, isValidating: isLoadingUserPages } = useUserPages(userId, { search: searchStr });
  const pageRouteIds = userPages?.map(page => page.routeId);

  const pageIds = [...(pageRouteIds || []), ...(historyRouteIds ? historyRouteIds.slice(0, 4) : [])]?.filter(
    (v, i, a) => a.indexOf(v) === i // remove duplicates
  );

  function useFetch(params?: APIWithPagination) {
    // retrieve inkind page data for the user's pages and the page routeIds that were found in the historyRouteIds
    return useInkindsInfinite(pageIds, params);
  }

  function transform(data: InkindPageAPIModel[]): IInkindListItemProps[] {
    let result = data
      ?.map(item => ({
        name: item.title,
        thumbnail: item.thumb_small,
        isOrganizer: can('manage', 'inkinds', {
          inkindRouteId: item.routeId,
          groupId: item.groupId,
        }),
        routeId: item.routeId,
      }))
      .sort((a, b) => pageIds.indexOf(a.routeId) - pageIds.indexOf(b.routeId));

    // filter out pages from browsing history that do not match the search query
    if (result && searchStr) {
      result = result?.filter(item => fuzzysearch(searchStr, item.name));
    }

    return result;
  }

  return (
    <div {...bem(null, null, className)}>
      <div {...bem('header')}>
        <h6 {...bem('title')}>{buyForSomeoneElseEnabled ? 'BUYING THIS FOR A PAGE?' : 'BUY FOR A PAGE'}</h6>
        {buyForSomeoneElseEnabled && (
          <PopoverListItem
            prepend={<p {...bem('external-link')}>Buying for someone else</p>}
            icon={ChevronRightIcon}
            onClick={onBuyForSomeoneElse}
            {...bem('external-link')}
          />
        )}
      </div>
      <main>
        <InfiniteLoaderSWR<InkindPageAPIModel, IInkindListItemProps>
          perPage={4}
          search={searchStr}
          fetch={useFetch}
          transform={transform}
          header={() => {
            return (
              <SearchInput
                variant="default-solid"
                placeholder="Search pages"
                debounceValue={300}
                value={searchStr}
                {...bem('search')}
                onValueChange={setSearchStr}
              />
            );
          }}
          footer={({ data, error, hasMore, isLoading, loadMore, totalItems }) => {
            const hasMoreResults = hasMore && data?.length > 0;

            if (!hasMoreResults && !error) return null;

            return (
              <div {...bem('footer')}>
                {hasMoreResults && (
                  <>
                    <Button variant="primary-link" disabled={isLoading} onClick={loadMore}>
                      + {totalItems - data.length} More Results
                    </Button>
                  </>
                )}

                {error && <div className="gik-form-error">{error}</div>}
              </div>
            );
          }}
        >
          {({ data, transformed, isLoading }) => {
            if (isLoading && data?.length === 0) return <LoadingSpinner center />;
            if (data?.length === 0) return <div> no results for &quot;{searchStr}&quot;</div>;

            return (
              <div>
                {transformed?.map(item => {
                  return (
                    <PopoverListItem
                      hover
                      key={item.routeId}
                      prepend={<InkindListItem {...item} />}
                      icon={ChevronRightIcon}
                      onClick={() => onInkindPageChosen(item.routeId)}
                    />
                  );
                })}
                {isLoading && <LoadingSpinner center />}
              </div>
            );
          }}
        </InfiniteLoaderSWR>
      </main>
      <footer {...bem('cannot-find-page')}>
        <p>Only pages you&apos;ve chosen to follow, or have recently visited are listed here.</p>
      </footer>
    </div>
  );
}
