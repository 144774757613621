import TranslationUtils from '@gik/core/utils/TranslationUtils';

// TODO: normalize and sanitize names
const translations = {
  TermsDisclaimer1: 'By continuing, you agree to the Give InKind',
  TermsDisclaimer2: 'terms',
  TermsDisclaimer3: 'and',
  TermsDisclaimer4: 'privacy policy',
  TermsDisclaimer5: 'We never sell or give your information to any third party.',

  AboutTitle: 'About',
  AboutStepRecipientTypeLabel: 'Who is this page for?',
  AboutStepEmailLabel: 'What email would you like to use to manage this page?',
  AboutErrorNotification: 'Enter your email & choose a recipient to continue',

  CreatePageCancelConfirmationTitle: 'Cancel Page?',
  CreatePageCancelConfirmation: "Are you sure you want to cancel creating this InKind Page? We'll save your place.",
  CreatePageCancelConfirmationCancelButton: 'No',
  CreatePageCancelConfirmationOkButton: 'Yes',

  SituationTitle: 'Situation',
  FeaturesTitle: 'Features',
  PrivacyTitle: 'Privacy',
  PageTitle: 'Page',
  FinishTitle: 'Finish',
  PageTitleErrorNotification: 'Please name your page',

  CreateTitle: 'Your InKind Page',
  careCalendarTitle: 'Care Calendar',
  careCalendarCreateStepDescription: 'Need help with meals, pet care, child care, transportation, and more?',
  careCalendarDescription: 'Schedule and coordinate meal and help sign-ups on your calendar',
  careCalendarHowItWorksTitle: 'The Care Calendar works in two steps:',
  careCalendarHowItWorksDescription1:
    '<span class="tw-font-bold">You as Page Organizer add requests</span>—as many as needed, for any type of support, to be provided exactly when needed.',
  careCalendarHowItWorksDescription2:
    '<span class="tw-font-bold">Visitors to your page send support by clicking “I’ll Help”</span> on the request that works best for them.',

  careCalendarVideoTitle: 'Using the Care Calendar',
  FeaturesCalendarErrorNotification: 'Please select if you’d like to start a Care Calendar, Yes or No',

  FundraisingTitle: 'Fundraising',
  FundraisingCreateStepDescription:
    'Provide supporters with a way to donate to your favorite fundraiser?<span>*</span>',
  FundraisingDescription: 'Add your favorite fundraising method, and allow visitors to donate.',
  FundraisingFinePrint: '*exisiting account or campaign required',
  FundraisingNotes: 'existing account or campaign required',
  FundraisingSkip: 'Skip for now',
  FundraisingSkipDescription: 'You can add this information later in Page Settings',
  FundraisingVideoTitle: 'Connecting Donations',
  FeaturesFundraisingErrorNotification: 'Please select if you’d like to receive donations, Yes or No',

  PayPalInstructionsVariantCreate: `Recipient’s PayPal.me Name`,
  PayPalInputDescriptionVariantCreate:
    'Your page will display a button linking to PayPal.\nWe don’t add any fees; standard PayPal fees apply.',
  PayPalInputDescriptionVariantPageSettings: 'Adds a button link to PayPal.me',
  PaypalInvalidName: 'Invalid Paypal.me name',
  PayPalHelpLinkText: 'Need help finding the name?',

  GoFundMeTargetVariantCreate: `Recipient’s GoFundMe URL`,
  GoFundMeDescriptionVariantCreate:
    'This will display the GoFundMe campaign tracker.\nWe don’t add any fees; standard GoFundMe fees apply.',
  GoFundMeDescriptionVariantPageSettings: 'Displays the GoFundMe campaign tracker',

  VenmoTargetVariantCreate: `Recipient’s Venmo user name`,
  VenmoDescriptionVariantCreate:
    'This will display the Venmo campaign tracker.\nWe don’t add any fees; standard Venmo fees apply.',
  VenmoDescriptionVariantPageSettings: 'Displays the Venmo campaign tracker',
  VenmoInvalidName: 'Invalid Venmo user name',

  CashAppTargetVariantCreate: `Recipient’s $Cashtag`,
  CashAppDescriptionVariantCreate:
    'This will display the CashApp campaign tracker.\nWe don’t add any fees; standard CashApp fees apply.',
  CashAppDescriptionVariantPageSettings: 'Displays the CashApp campaign tracker',
  CashAppInvalidName: 'Invalid $Cashtag',

  WishlistTitle: 'Wishlist',
  WishlistCreateStepDescription: 'Want to give supporters easy options for helping from anywhere?',
  WishlistDescription: 'Your supporters will appreciate knowing exactly how to help.',
  WishlistSuggestionsBefore: 'We’ve added a few gift cards to your Wishlist already:',
  WishlistSuggestionsAfter: 'You can add more items or edit existing ones from your page at anytime.',
  WishlistHowItWorksDescription1:
    '<span class="tw-font-bold">We’ve added these four gift cards</span> to get your Wishlist started.',
  WishlistHowItWorksDescription2:
    '<span class="tw-font-bold">Add or remove items</span> from your Wishlist at any time.',
  WishlistHowItWorksDescription3:
    '<span class="tw-font-bold">You can even add a link to an Amazon Wishlist</span> in your Page Settings.',
  WishlistVideoTitle: 'Using the Wishlist',
  FeaturesWishlistErrorNotification: 'Please select if you’d like to start a wishlist, Yes or No',

  WrapperTitle: 'What kind of support is needed?',
  WrapperDescription:
    'Every situation is unique. Select as many kinds of support as are needed now, and make changes anytime. <span class="tw-font-bold">At least one required</span>.',

  CantDisableAll: 'You must have at least one feature turned on to create an InKind page.',
  CantDisableCareCalendarAndWishlist: 'Please enable either the calendar or wishlist.',

  PageNameTitle: 'Name your page',
  PageNameDescription:
    '<span class="tw-font-bold">Make it descriptive</span> by including words your supporters would recognize or search for.',
  PageNamePlaceholder: 'e.g. Love for Layla, Supporting the Garcias',
  PageNameIdeasButton: 'Page Name Ideas',

  OrganizationInfoTitle: 'Organization Info',
  OrganizationInfoDescription: 'What is its name and where can we send electronic gift cards?',

  RecipientNamePlaceholder: "Recipient's name",
  RecipientEmailPlaceholder: "Recipient's email",
  RecipientEmailConfirmPlaceholder: "Confirm recipient's email",
  OrganizationNamePlaceholder: "Organization's name",
  OrganizationEmailPlaceholder: "Organization's email",
  OrganizationEmailConfirmPlaceholder: "Confirm organization's email",
  AddAsPageOrganizerLabel: 'Add them as a Page Organizer',

  RecipientsInfoTitle: 'Who is this page for?',
  RecipientsInfoDescription: 'What’s the recipient’s name and where can we send electronic gift cards?',
  RecipientsInfoDescription2: 'We won’t notify them of the page, unless you add them as a Page Organizer.',
  IncludeRecipientAddressLabel: 'Include recipient’s address for deliveries',
  IncludeYourAddressLabel: 'Include your address for deliveries',
  IncludeRecipientAddressSublabel: 'or add later in Page Settings or Calendar setup.',

  SituationRequired: 'Please select at least one situation.',
  SituationsErrorNotification: 'Choose at least one situation to continue',
  GeneralSuggestionsTitle: 'New Beginnings',

  PersistenceDialogTitle: 'Welcome back!',
  PersistenceDialogBody: 'Would you like to continue where you left off?',
  PersistenceDialogOkText: 'Continue',
  PersistenceDialogCancelText: 'Start over',

  SignUpStepTitle: 'Almost done!',
  SignUpStepDescription:
    'To save your page, a Give InKind account is needed. Enter the information requested here to create an account for <span class="tw-font-bold">{{emailAddress}}</span>',
  SwitchAddress: 'Use a different email address',
  ExternalLoginSeparator: 'or sign-up using',

  LoginTitle: 'Login to your account',
  ExternalLoginDescription:
    '<span class="tw-font-bold">{{emailAddress}}</span> uses your {{provider}} account to login to Give InKind.',

  ReminderOk: 'Add',
  ReminderCancel: 'Not now',

  RecipientAddressReminderTitle: 'Add Recipient Address?',
  RecipientAddressReminderDescription:
    'Would you like to add a recipient address now? This makes it easier for supporters to send meals or provide other support.',

  ChangeEmail: 'Change Email',
  ChangeEmailDescription: 'Enter the email address you’d like to use to login to Give InKind',
  InvalidGoFundMeURL: 'Invalid GoFundMe URL',

  MissingFeatureSelection: 'Please choose YES or NO for the {{name}} selection.',
};

export default {
  create: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('create', translations);
