import { useCalendarAnnouncementTypes } from '@gik/api/calendar/calendarAnnouncementType';
import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useInkind } from '@gik/api/inkinds/inkind';
import type {
  ICalendarAnnouncement,
  ICalendarEntry,
  ICalendarEntryBase,
  ICalendarEvent,
} from '@gik/calendar/models/Calendar';
import { InView } from '@gik/core/components/InView';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInPastAllTimezones } from '@gik/core/utils/DateTimeUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { UI } from '@gik/ui/UIManager';
import type { Moment } from 'moment';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarDayItem } from './CalendarDayItem';
import { translationKeys } from './i18n/en';

export interface ICalendarDayProps extends React.HTMLAttributes<HTMLDivElement> {
  date?: Moment;
  entries?: ICalendarEntryBase[];
  events?: ICalendarEvent[];
  resolvingClaim?: boolean;
  privateClaim?: boolean;
  onAddEvent?(date: Moment): void;
  onEditEvent?(event: ICalendarEvent, entry: ICalendarAnnouncement | ICalendarEntry): void;
  onClaimEvent?(event: ICalendarEvent, entry: ICalendarAnnouncement | ICalendarEntry, routeId: string): void;
}

function CalendarDayComp({
  date,
  className,
  entries,
  events,
  resolvingClaim,
  privateClaim,
  onAddEvent,
  onEditEvent,
  onClaimEvent,
  ...otherProps
}: ICalendarDayProps): React.ReactElement {
  const bem = bemBlock('calendar-day');
  const { t } = useTranslation();

  const { data: eventTypes } = useCalendarEventTypes();
  const { data: announcementTypes } = useCalendarAnnouncementTypes();

  const userId = useUserStore(state => state.id);
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);

  const isSamplePage = useInkindStore(state => state.isSamplePage);

  const handleDayClick = React.useCallback(async () => {
    if (isSamplePage) {
      return await UI.alert(t(translationKeys.samplePageWarning));
    }
    return onAddEvent(date);
  }, [date, isSamplePage, onAddEvent, t]);

  const isEmpty = !events || !events.length;

  const isLoading = !announcementTypes || !eventTypes;
  if (isLoading) {
    return <LoadingSpinner center />;
  }

  return (
    <div
      className={bem(null, [{ empty: isEmpty }, { clickable: canEdit }], className)}
      {...otherProps}
      onClick={handleDayClick}
    >
      <header className={bem('header')}>
        <span className={bem('date')}>
          <span>
            <div className={bem('date-day-name')}>{date.format('ddd')}</div>
            <div className={bem('date-month')}>{date.format('MMM')}</div>
          </span>
          <div className={bem('date-day')}>{date.format('D')}</div>
        </span>
      </header>
      {!isEmpty ? (
        <div className={bem('list')}>
          {events.map(event => {
            const isPastEvent = isInPastAllTimezones(moment.utc(event.startsAt));
            return (
              <InView
                key={event.entryId + event.startsAt}
                className={bem('in-view', [{ 'past-event': isPastEvent }])}
                data-id={event.entryId}
              >
                <CalendarDayItem
                  privateClaim={privateClaim}
                  event={event}
                  entries={entries}
                  canEdit={canEdit}
                  eventTypes={eventTypes}
                  announcementTypes={announcementTypes}
                  onEditEvent={onEditEvent}
                  onClaimEvent={onClaimEvent}
                  resolvingClaim={resolvingClaim}
                  isSamplePage={isSamplePage}
                />
              </InView>
            );
          })}
        </div>
      ) : (
        <div className={bem('no-requests')}>
          <span>{t(translationKeys.calendarDayNoRequest).toString()}</span>
        </div>
      )}
    </div>
  );
}

export const CalendarDay = withComponentErrorBoundary(CalendarDayComp);
