import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import PencilIcon from '@heroicons/react/solid/PencilIcon';
import TrashIcon from '@heroicons/react/solid/TrashIcon';
import type { FormikProps } from 'formik';
import React from 'react';

export interface ICalendarClaimFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  form?: FormikProps<object>;
  backButton?: boolean;
  cancelButton?: boolean;
  unclaimButton?: boolean;
  closeButton?: boolean;
  editEventButton?: boolean;
  saving?: boolean;
  onBack?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onClose?: () => void | Promise<void>;
  onEditEvent?: () => void | Promise<void>;
  onUnclaim?: () => void | Promise<void>;
}

export function CalendarClaimFooter({
  form,
  backButton,
  cancelButton,
  unclaimButton,
  closeButton,
  editEventButton,
  children,
  saving,
  onBack = noop,
  onCancel = noop,
  onClose = noop,
  onEditEvent = noop,
  onUnclaim = noop,
  className,
}: ICalendarClaimFooterProps): React.ReactElement {
  const bem = bemBlock('calendar-claim-footer');

  const isLoading = form?.isSubmitting || saving;

  return (
    <div className={bem(null, null, className)}>
      <section>
        {backButton && (
          <Button variant={'default-light'} onClick={onBack} circle disabled={isLoading} loading={isLoading}>
            <SvgIcon Icon={ChevronLeftIcon} />
          </Button>
        )}

        {unclaimButton && (
          <Button
            variant={'danger'}
            onClick={onUnclaim}
            disabled={isLoading}
            loading={isLoading}
            prepend={<SvgIcon Icon={TrashIcon} />}
          >
            Unclaim
          </Button>
        )}
      </section>
      <section>
        {cancelButton && (
          <Button variant={'default'} onClick={onCancel} disabled={isLoading} loading={isLoading}>
            Cancel
          </Button>
        )}
        {editEventButton && (
          <Button
            variant={'secondary'}
            onClick={onEditEvent}
            disabled={isLoading}
            loading={isLoading}
            prepend={<SvgIcon Icon={PencilIcon} />}
            hideLabelOnMobile={unclaimButton && editEventButton}
          >
            Edit Request
          </Button>
        )}
        {closeButton && (
          <Button variant={'default'} onClick={onClose} disabled={isLoading} loading={isLoading}>
            Close
          </Button>
        )}
        {children}
      </section>
    </div>
  );
}
