import bemBlock from '@gik/core/utils/bemBlock';
import { PageSection } from '@gik/ui/gik/PageSection';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';
import type { UIComponent } from '../../types/UI';

export type LegalPageProps = {
  text?: string;
} & UIComponent;

export function LegalPage({ text }: LegalPageProps): React.ReactElement {
  const bem = bemBlock('legal');

  return (
    <PageSection className={bem()}>
      <HTMLParser rawHtml={text} />
    </PageSection>
  );
}
