import { gikHasOverlayClass } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import useEvent from 'react-use/lib/useEvent';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import type { DrawerRenderCallback, IDrawerProps, IDrawerRenderProps } from './DrawerTypes';

export function Drawer({
  className,
  variant = 'white',
  placement = 'left',
  isOpen,
  closeOnBackdropClick = true,
  backdrop = true,
  padded,
  shadow,
  closeBtn = true,
  onClose = noop,
  onClosed = noop,
  onOpen = noop,
  children,
  portal,
  fixed,
  header,
  footer,
  centeredTitle,
  contentRef,
  closeAnimationLength = 1000,
  openAnimationLength = 1000,
  autoScrollContentTo,
  title,
}: React.PropsWithChildren<IDrawerProps>): React.ReactElement {
  const bem = bemBlock('drawer');

  const [scrolledToTop, setScrolledToTop] = React.useState<boolean>(false);
  const [scrolledToBottom, setScrolledToBottom] = React.useState<boolean>(false);

  const footerRef = React.useRef<HTMLDivElement>();

  let _header = header;
  let _footer = footer;

  const open = React.useCallback(() => {
    document.body.classList.add(gikHasOverlayClass);
    onOpen();
  }, [onOpen]);

  const close = React.useCallback(() => {
    if (!isOpen) return;
    onClose();
  }, [onClose, isOpen]);

  const renderProps: IDrawerRenderProps = {
    close,
    open,
  };

  // if the header is not a valid react element we assume it to be a callback function that accepts renderProps
  if (_header && !React.isValidElement(_header)) {
    _header = (_header as DrawerRenderCallback)(renderProps);
  }

  // if the header is not a valid react element we assume it to be a callback function that accepts renderProps
  if (_footer && !React.isValidElement(_footer)) {
    _footer = (_footer as DrawerRenderCallback)(renderProps);
  }

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  // FIXME: remove 'any' type
  // eslint-disable-next-line
  const hasAutoScrollIdProp = (autoScroll: any): autoScroll is { id: string } => {
    return typeof autoScroll?.id === 'string';
  };
  const _autoScrollContentTo = hasAutoScrollIdProp(autoScrollContentTo) ? autoScrollContentTo.id : autoScrollContentTo;

  function handleClose(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();
    close();
  }

  function handleBackdropClick(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();
    if (closeOnBackdropClick) {
      close();
    }
  }

  function handleClosed() {
    document.body.classList.remove(gikHasOverlayClass);
    onClosed();
  }

  function handleContainerClick(ev: React.MouseEvent<HTMLDivElement>) {
    ev.stopPropagation();
  }

  function onResize() {
    _updateMoreContentIndicatorCallback();
  }

  useEvent('resize', onResize);

  // const [modalRef] = useMeasure();
  let _contentRef: HTMLDivElement;

  const _updateMoreContentIndicator = () => {
    if (!_contentRef) {
      return;
    }

    const _scrolledToTop = _contentRef.scrollTop === 0;
    const _scrolledToBottom = _contentRef.scrollTop + _contentRef.clientHeight > _contentRef.scrollHeight - 1;

    if (_scrolledToTop != scrolledToTop) {
      setScrolledToTop(_scrolledToTop);
    }
    if (_scrolledToBottom != scrolledToBottom) {
      setScrolledToBottom(_scrolledToBottom);
    }
  };

  const _updateMoreContentIndicatorCallback = React.useCallback(_updateMoreContentIndicator, [
    _contentRef,
    scrolledToBottom,
    scrolledToTop,
  ]);

  useUpdateEffect(() => {
    _updateMoreContentIndicator();
    setTimeout(() => _updateMoreContentIndicator(), Math.max(closeAnimationLength, openAnimationLength));
  });

  React.useEffect(() => {
    if (!isOpen || !_autoScrollContentTo || !scrollContainerRef?.current) return;

    const autoScrollOptions = {
      duration: 300,
      smooth: true,
      container: scrollContainerRef.current,
    };

    switch (_autoScrollContentTo) {
      case 'top':
        scroll.scrollToTop(autoScrollOptions);
        break;
      case 'bottom':
        scroll.scrollToBottom(autoScrollOptions);
        break;
      default:
        scroller.scrollTo(_autoScrollContentTo, autoScrollOptions);
    }
  }, [isOpen, _autoScrollContentTo]);

  React.useEffect(() => {
    if (isOpen) {
      open();
    } else {
      close();
    }
  }, [isOpen, open, close]);

  React.useEffect(() => {
    _updateMoreContentIndicatorCallback();
  }, [_updateMoreContentIndicatorCallback]);

  let startX = '0';
  let startY = '0';
  if (placement === 'left') startX = '-100%';
  if (placement === 'right') startX = '100%';
  if (placement === 'top') startY = '-100%';
  if (placement === 'bottom') startY = '100%';

  const variants = {
    start: { x: startX, y: startY },
    end: { x: 0, y: 0 },
  };

  const drawer = (
    <div
      className={bem(
        null,
        [
          { open: isOpen },
          { [placement]: placement },
          { shadow },
          { padded },
          { 'title-centered': centeredTitle },
          { fixed },
          { [variant]: variant },
        ],
        className
      )}
    >
      {backdrop && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={bem('backdrop')}
              onClick={handleBackdropClick}
            />
          )}
        </AnimatePresence>
      )}

      <AnimatePresence onExitComplete={handleClosed}>
        {isOpen && (
          <motion.div
            initial={'start'}
            variants={variants}
            animate={'end'}
            exit={'start'}
            transition={{
              damping: 5,
            }}
            className={bem('box')}
            onClick={handleContainerClick}
          >
            <div className={bem('header')}>
              {closeBtn && (
                <div className={bem('close')} onClick={handleClose}>
                  <SvgIcon Icon={CloseIcon} />
                </div>
              )}
              {title && <div className={bem('title')}>{title}</div>}
            </div>
            <div className={`more-content-indicator top ${!scrolledToTop ? 'visible' : ''}`} />
            <div
              ref={element => {
                _contentRef = element;
                if (contentRef) {
                  contentRef.current = element;
                }
                if (scrollContainerRef) {
                  scrollContainerRef.current = element;
                }
                _updateMoreContentIndicator();
              }}
              onScroll={_updateMoreContentIndicator}
              className={bem('content')}
            >
              {children}
            </div>
            <div className={`more-content-indicator bottom ${_footer && !scrolledToBottom ? 'visible' : ''}`} />
            {_footer && (
              <div className={bem('footer')} ref={footerRef}>
                {_footer}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  if (portal) return ReactDOM.createPortal(drawer, portal);

  return drawer;
}
