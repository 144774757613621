import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Facebook = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 41 40" width={size} height={size} fill="none" ref={svgRef} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9902 20.1025C36.9902 10.8415 29.4625 3.33398 20.1765 3.33398C10.8905 3.33398 3.36279 10.8415 3.36279 20.1025C3.36279 28.4722 9.51133 35.4094 17.5494 36.6673V24.9497H13.2803V20.1025H17.5494V16.4082C17.5494 12.2056 20.0595 9.88419 23.9002 9.88419C25.7397 9.88419 27.6639 10.2117 27.6639 10.2117V14.3383H25.5437C23.455 14.3383 22.8037 15.6309 22.8037 16.957V20.1025H27.4668L26.7214 24.9497H22.8037V36.6673C30.8417 35.4094 36.9902 28.4722 36.9902 20.1025Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);

export const FacebookWhite = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 41 40" width={size} height={size} fill="none" ref={svgRef} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9902 20.1025C36.9902 10.8415 29.4625 3.33398 20.1765 3.33398C10.8905 3.33398 3.36279 10.8415 3.36279 20.1025C3.36279 28.4722 9.51133 35.4094 17.5494 36.6673V24.9497H13.2803V20.1025H17.5494V16.4082C17.5494 12.2056 20.0595 9.88419 23.9002 9.88419C25.7397 9.88419 27.6639 10.2117 27.6639 10.2117V14.3383H25.5437C23.455 14.3383 22.8037 15.6309 22.8037 16.957V20.1025H27.4668L26.7214 24.9497H22.8037V36.6673C30.8417 35.4094 36.9902 28.4722 36.9902 20.1025Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
