/**
 * Head keys properties used by NextJs to enable overriding already defined properties.
 * Using those guarantees no duplicate entries
 */
export enum PageHeadKeys {
  TITLE,
  LINK_CANONICAL,
  META_DESCRIPTION,
  META_KEYWORDS,
  META_OG_TITLE,
  META_OG_DESCRIPTION,
  META_OG_URL,
  META_OG_IMAGE,
  META_OG_IMAGE_SECURE_URL,
  META_OG_IMAGE_WIDTH,
  META_OG_IMAGE_HEIGHT,
  META_OG_UPDATED_TIME,
  META_OG_TYPE,
  META_OG_ARTICLE_AUTHOR,
  META_OG_ARTICLE_SECTION,
  META_OG_ARTICLE_PUBLISH_TIME,
  META_OG_ARTICLE_UPDATE_TIME,
  META_TWITTER_TITLE,
  META_TWITTER_DESCRIPTION,
  META_TWITTER_CARD,
  META_TWITTER_IMAGE,
  GOOGLE_SITE_VERIFICATION_DEV_VERCEL_DOMAIN,
  GOOGLE_SITE_VERIFICATION_DEV_DOMAIN,
  GOOGLE_PLATFORM,
  HIDE_DEV_ERROR_OVERLAY,
  DATADOME_JS,
  DATADOME_TAGS,
  META_ROBOTS,
}
