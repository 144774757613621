import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'stream';

const translations = {
  commentCount: '{{count}} comment',
  commentCount_plural: '{{count}} comments',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
