import { useEnvStore } from '@gik/core/store/EnvStore';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

export let stripePromise: Promise<Stripe | null>;

export function withStripe<P>(WrappedComponent: React.FC<P>): React.FC<P> {
  return function WrappedWithStripe(props: P) {
    const stripeApiKey = useEnvStore(state => state.STRIPE_API_KEY);

    if (!stripePromise)
      stripePromise = loadStripe(stripeApiKey, {
        apiVersion: '2020-08-27', // TODO: remove after upgrading version in stripe dashboard
      });
    return (
      <Elements stripe={stripePromise}>
        <WrappedComponent {...(props as P)} />
      </Elements>
    );
  };
}
