import type { Product } from '@gik/core/models/gik/Product';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import moment from 'moment';
import React from 'react';

function matchingTerms(product: Product, toggle: OLProductType) {
  if (!product) return [];
  const regex = /(\d+)-month/;

  const [matchingVariation] =
    product.variations?.filter(v => v.metaData?.find(m => m.key == 'pg_product_type' && m.value === toggle)) || [];

  const { value: productTerms } = matchingVariation?.metaData?.find(m => m.key === 'pg_product_terms') || {};

  const match = (productTerms as string)?.match(regex);
  const months = match?.[1] ?? undefined;

  return [months];
}

export function getPGOLExpirationMonth(product: Product, toggle: OLProductType) {
  const [matchingTerm] = matchingTerms(product, toggle);
  if (!matchingTerm) return;

  const currentDate = moment();
  return currentDate.add(matchingTerm, 'months').format('M/YY');
}

export function usePGOLExpirationMonth(product: Product, toggle: OLProductType) {
  const [matchingTerm] = React.useMemo(() => matchingTerms(product, toggle), [product, toggle]);
  if (!matchingTerm) return;

  const currentDate = moment();
  return currentDate.add(matchingTerm, 'months').format('M/YY');
}
