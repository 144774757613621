import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { SvgIcon } from '../SvgIcon';
import type { UIVariant } from '../typesValues';

export interface IPopoverMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SvgrComponent;
  variant?: UIVariant;
  fullWidth?: boolean;
}

export const PopoverMenuItem: React.FC<IPopoverMenuItemProps> = ({
  children,
  icon,
  variant = 'default',
  fullWidth,
  className,
  ...otherProps
}) => {
  const bem = useBemCN('popover-menu-item');

  return (
    <div {...bem(null, [{ [variant]: variant }], className)} {...otherProps}>
      {icon && (
        <div {...bem('icon')}>
          <SvgIcon Icon={icon} size="sm" />
        </div>
      )}
      <span {...bem('label', [{ fullWidth }])}>{children}</span>
    </div>
  );
};
