import { Analytics } from '@gik/analytics';
import type { IAnalyticsProps } from '@gik/analytics/utils/Analytics';
import type { AnalyticsEvents } from '@gik/analytics/utils/Events';

export function calendarEventMutatedAnalytics(
  analyticsEventName: AnalyticsEvents.eventCreated | AnalyticsEvents.eventUpdated,
  eventId: string,
  allDay: boolean,
  startsAt: string,
  endsAt: string,
  repeat: string,
  eventDescription: string,
  eventTitle: string,
  eventTypeId: number,
  calendarEventTypeName: string,
  sendEmailToPageFollowers: boolean,
  nonAnnouncementProps?: {
    allowGiftCards: boolean;
    giftCardsUpdated: boolean;
    allowedGiftCardIds: number[];
    deliveryInstructions: string;
    dropoffLocation: string;
    emergencyContactInfo: string;
    numberRequired?: number;
    petCareTypeId?: number;
    pickupLocation: string;
  },
  announcementProps?: { announcementTypeId: number }
) {
  const wasProvided = (value: string) => ((value?.length || 0) > 0).toString();

  const otherProps: IAnalyticsProps = {};
  if (nonAnnouncementProps) {
    otherProps.allowGiftCards = nonAnnouncementProps.allowGiftCards.toString();
    otherProps.giftCardsUpdated = nonAnnouncementProps.giftCardsUpdated.toString();
    otherProps.allowedGiftCardIds = nonAnnouncementProps.allowedGiftCardIds?.join(',') || '';
    otherProps.deliveryInstructionsProvided = wasProvided(nonAnnouncementProps.deliveryInstructions);
    otherProps.dropoffLocationProvided = wasProvided(nonAnnouncementProps.dropoffLocation);
    otherProps.emergencyContactInfoProvided = wasProvided(nonAnnouncementProps.emergencyContactInfo);
    otherProps.numberRequired = nonAnnouncementProps.numberRequired?.toString();
    otherProps.petCareTypeId = nonAnnouncementProps.petCareTypeId?.toString();
    otherProps.pickupLocationProvided = wasProvided(nonAnnouncementProps.pickupLocation);
  }
  if (announcementProps) {
    otherProps.announcementTypeId = announcementProps.announcementTypeId.toString();
  }

  Analytics.fireEvent(
    analyticsEventName,
    {
      eventId,
      allDay: allDay.toString(),
      startsAt: startsAt,
      endsAt: endsAt || '',
      repeat: repeat || '',
      eventDescriptionProvided: wasProvided(eventDescription),
      eventTitleProvided: wasProvided(eventTitle),
      eventTypeId: eventTypeId.toString(),
      calendarEventType: calendarEventTypeName,
      sendEmailToPageFollowers: (sendEmailToPageFollowers || false).toString(),
      ...otherProps,
    },
    actionKeys => actionKeys,
    _ => []
  );
}
