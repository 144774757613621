import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CalendarIcon from '@heroicons/react/solid/CalendarIcon';
import React from 'react';
import type { IAddToCalendarLinks } from '.';
import { openAddToCalendarModal } from './AddToCalendarModal';

export interface IAddToCalendarButtonProps extends IButtonProps {
  links: IAddToCalendarLinks;
  text?: React.ReactNode;
}

export function AddToCalendarButton({
  className,
  text = 'Add to calendar',
  links,
  ...otherProps
}: IAddToCalendarButtonProps): React.ReactElement {
  const bem = useBemCN('add-to-calendar-button');

  function handleAddToCalendar() {
    openAddToCalendarModal(links);
  }

  return (
    <Button
      {...bem(null, null, className)}
      size={'sm'}
      variant={'primary-outline'}
      pill
      append={<SvgIcon size="lg" Icon={CalendarIcon} />}
      onClick={handleAddToCalendar}
      {...otherProps}
    >
      {text}
    </Button>
  );
}
