import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const TextMessagesOkOutline = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          d="M10.7922 13.8333H10.8055M16.1255 13.8333H16.1389M21.4589 13.8333H21.4722M12.1255 21.8333H6.79222C6.08497 21.8333 5.40669 21.5523 4.9066 21.0522C4.4065 20.5521 4.12555 19.8738 4.12555 19.1666V8.49992C4.12555 7.79267 4.4065 7.1144 4.9066 6.6143C5.40669 6.1142 6.08497 5.83325 6.79222 5.83325H25.4589C26.1661 5.83325 26.8444 6.1142 27.3445 6.6143C27.8446 7.1144 28.1256 7.79267 28.1256 8.49992V19.1666C28.1256 19.8738 27.8446 20.5521 27.3445 21.0522C26.8444 21.5523 26.1661 21.8333 25.4589 21.8333H18.7922L12.1255 28.4999V21.8333Z"
          stroke="#7BAB52"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export const TextMessagesOk = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 5V13C18 13.5304 17.7893 14.0391 17.4142 14.4142C17.0391 14.7893 16.5304 15 16 15H11L6 19V15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H16C16.5304 3 17.0391 3.21071 17.4142 3.58579C17.7893 3.96086 18 4.46957 18 5ZM7 8H5V10H7V8ZM9 8H11V10H9V8ZM15 8H13V10H15V8Z"
          fill="#7BAB52"
        />
      </svg>
    );
  }
);
