import { isBrowser } from '@/utils/environment';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useDevStore } from '@gik/core/store/DevStore';
import bemBlock from '@gik/core/utils/bemBlock';
import getRandomInt from '@gik/core/utils/getRandomInt';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import type { IRecipientInfoAddressFormValues } from '@gik/create/components/RecipientInfoStep/RecipientInfoAddressForm';
import RecipientInfoAddressForm, {
  recipientInfoAddressFormSchema,
} from '@gik/create/components/RecipientInfoStep/RecipientInfoAddressForm';
import { recipientInfoValidator } from '@gik/create/components/RecipientInfoStep/RecipientInfoStep';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { PageNameStepInputNames } from '@gik/create/enums/PageNameStepInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { pageNameStepSchema } from '@gik/create/formSchemas';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import { Button } from '@gik/ui/Button';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import { PageSection } from '@gik/ui/gik/PageSection';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import LightBulbIcon from '@heroicons/react/solid/LightBulbIcon';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface INameYourPageStepProps extends FormProps {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: ICreateNameYourPageFormValues) => void;
  onChange?: (values: ICreateNameYourPageFormValues) => void;
  trackClickedPageNameIdeas?: () => void;
}

export interface ICreateNameYourPageFormValues {
  [PageNameStepInputNames.PageName]: string;
}

interface IRecipientFormValues extends ICreateNameYourPageFormValues, IRecipientInfoAddressFormValues {}

const blockName = 'name-your-page-step';
export const formId = 'CreateNameYourPageStepForm';

export default function NameYourPageStep({
  className,
  buttons,
  buttonsPortal,
  onChange,
  onSubmit,
  trackClickedPageNameIdeas,
  ...otherProps
}: INameYourPageStepProps) {
  const bem = bemBlock(blockName);

  const { t } = useTranslation();
  const { pageFormRef } = React.useContext(CreatePageContext);
  const formValues = useCreateFlowStore(state => state.formValues);
  const setNextDisabled = useCreateFlowStore(state => state.setNextDisabled);

  const [values, setValues] = React.useState<IRecipientInfoAddressFormValues>();
  const openPageNameDrawer = useCreateFlowStore(state => state.openPageNameDrawer);

  const isMyself = formValues[AboutStepInputNames.RecipientType] === RecipientType.Myself;
  const schema = pageNameStepSchema().concat(recipientInfoAddressFormSchema(isMyself, values?.includeRecipientAddress));

  const { stepIndex, subStepIndex } = useCreateFlowStore(state => ({
    stepIndex: state.stepIndex,
    subStepIndex: state.subStepIndex,
  }));

  const enabled =
    formValues[AboutStepInputNames.RecipientType] === RecipientType.Myself ||
    (formValues[AboutStepInputNames.RecipientType] !== RecipientType.Myself && stepIndex == 2 && subStepIndex == 0);

  useFormNavigationObserver(pageFormRef, enabled);

  function handleShowPageNameIdeas() {
    trackClickedPageNameIdeas?.();
    openPageNameDrawer();
  }

  function handleSubmit(values: IRecipientFormValues) {
    setValues(values);
    if (onSubmit) {
      onSubmit(values);
    }
  }

  const handleChange = React.useCallback(
    function handleChange(values: IRecipientFormValues) {
      if (
        values.includeRecipientAddress === true &&
        (!values.address1 || !values.city || !values.state || !values.postalCode)
      ) {
        setNextDisabled(true);
      } else if (!values.includeRecipientAddress) {
        setNextDisabled(!values?.[PageNameStepInputNames.PageName]?.trim().length);
      }

      setValues(values);
      onChange?.(values);
    },
    [onChange, setNextDisabled]
  );

  return (
    <PageSection
      className={bem(null, [{ myself: isMyself }], className)}
      noPad
      variant="aqua"
      gradientDarkening
      contained={false}
    >
      <Form
        ref={pageFormRef}
        className={bem('form', null, ['gik-form--white'])}
        schema={schema}
        initialValues={
          isBrowser() && localStorage.getItem('gik-devtools') === 'true' && useDevStore.getState().prefixNewPages
            ? {
                ...formValues,
                [PageNameStepInputNames.PageName]: `InKindTest ${getRandomInt(1000, 9999)}${
                  formValues?.[PageNameStepInputNames.PageName] ? ` ${formValues[PageNameStepInputNames.PageName]}` : ''
                }`,
              }
            : formValues
        }
        vertical
        id={formId}
        onSubmit={handleSubmit}
        onChange={handleChange}
        validate={recipientInfoValidator(schema)}
        trackingId={AnalyticsEvents.CreateStarted}
        render={(form: FormikProps<object>) => {
          return (
            <div>
              <PageSection className={bem('name-your-page-section')} noPadTop noPadBottom>
                <h2 className="gik-create-section-title">{t(translationKeys.PageNameTitle).toString()}</h2>
                <p className={bem('page-name-description')}>
                  <HTMLParser rawHtml={t(translationKeys.PageNameDescription)} />
                </p>
                <FormField name={PageNameStepInputNames.PageName} center />

                <Button
                  className={bem('ideasBtn')}
                  pill
                  variant="primary-vdark"
                  size="sm"
                  prepend={<SvgIcon Icon={LightBulbIcon} />}
                  onClick={() => handleShowPageNameIdeas()}
                >
                  {t(translationKeys.PageNameIdeasButton).toString()}
                </Button>
              </PageSection>

              {isMyself && (
                <PageSection
                  className={bem('recipient-address')}
                  contained={false}
                  variant="solid-neutral"
                  noPadTop
                  noPadBottom
                >
                  <RecipientInfoAddressForm isMyself={isMyself} form={form} values={values} />
                </PageSection>
              )}

              <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
            </div>
          );
        }}
        {...otherProps}
      />
    </PageSection>
  );
}
