import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type { WordpressError } from '@gik/core/models/wordpress/WordpressError';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import type IWordpressServiceCategory from '@gik/core/models/wordpress/WordpressServiceCategory';
import React from 'react';
import type { SWRConfiguration, SWRResponse } from 'swr';
import { useCalendarEventTypes } from './calendarEventType';
import { useServices } from './service';

const path = 'service-category';

export function getServiceCategories() {
  return wpApi.get(path);
}

export function useServiceCategories(config?: SWRConfigInterface) {
  return useWPApi<IWordpressServiceCategory[]>(path, null, config);
}

/**
 * Get services by event type
 */
export function useServicesByEventTypeId(eventTypeId?: number, config?: SWRConfiguration) {
  const response = useServiceCategories(config);
  const serviceCategories = response.data;
  const { data: services } = useServices(config);
  const { data: eventTypes } = useCalendarEventTypes(config);
  const [data, setData] = React.useState<IWordpressService[]>(null);

  /**
   * Create a list of matching service categories when all data is ready
   */
  React.useEffect(() => {
    if (!serviceCategories || !services || eventTypeId === undefined) return;

    // get services for the requested event type
    const matchingServices = services
      ?.filter(item => {
        return item.acf.preview_mode !== true;
      })
      .sort(
        (a: IWordpressService, b: IWordpressService) =>
          parseInt(a.acf.sort_order || '0') - parseInt(b.acf.sort_order || '0')
      )
      .filter(item => {
        return item['cal-event-type']?.indexOf(eventTypeId) > -1;
      })
      .filter(item => item.acf.allow_new_entries !== false);

    setData(matchingServices);
  }, [serviceCategories, services, eventTypes, eventTypeId]);

  return {
    error: response.error,
    isValidating: response.isValidating,
    data,
  } as SWRResponse<IWordpressService[], WordpressError>;
}
