import he from 'he';
import React from 'react';

export interface IDecoderProps {
  text: string;
}

export function Decoder({ text }: IDecoderProps) {
  return <>{he.decode(text)}</>;
}
