import type { IPartnerClaimURL } from '@gik/calendar/models/PartnerClaimDetails';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';

export interface ICalendarEventClaimBasePayload extends Partial<IPartnerClaimURL> {
  description?: string;
  note?: string;
  serviceSlug?: string;
  carType?: string;
}
export interface ICalendarEventClaimUpdatePayload extends Partial<ICalendarEventClaimCreatePayload> {}
export interface ICalendarEventClaimCreatePayload extends ICalendarEventClaimBasePayload {
  calendarEntryId: string;
  claimDate: string;
  originalClaimDate?: string;
  // test if it is possible to claim without actually claiming
  test?: boolean;
  // attach claim to existing order (claim conflict resolution)
  orderId?: number;
  orderKey?: string;
  lineItemId?: string;
  privateClaim?: boolean;
}

export interface ICalendarEventUnclaimOrderPayload {
  orderId?: number;
  orderKey?: string;
  lineItemId?: string;
}

export async function claimExistsForEvent(entryId: string): Promise<boolean> {
  const url = `claims`;
  const response = await dotnetApi.get(url, {
    searchParams: {
      entryIds: entryId.toLowerCase(),
      fields: 'id',
    },
  });
  const claims = (await response.json()) as { id: string }[];
  return claims.length > 0;
}
export async function unclaim(claimId: string): Promise<Response> {
  const url = `claims/${claimId}`;
  return await dotnetApi.delete(url);
}
export async function updateClaim(
  claimId: string,
  updateClaimRequest: ICalendarEventClaimUpdatePayload
): Promise<Response> {
  const url = `claims/${claimId}`;
  return dotnetApi.patch(url, { json: updateClaimRequest });
}

export async function createClaim(createClaimRequest: ICalendarEventClaimCreatePayload): Promise<Response> {
  const url = `claims`;
  return await dotnetApi.post(url, { json: createClaimRequest });
}

export async function unclaimOrder(createClaimRequest: ICalendarEventUnclaimOrderPayload): Promise<Response> {
  const url = `claims/unclaim-order`;
  return await dotnetApi.delete(url, { json: createClaimRequest });
}

export async function testClaim(createClaimRequest: ICalendarEventClaimCreatePayload): Promise<Response> {
  return createClaim({
    ...createClaimRequest,
    test: true,
  });
}
