import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'auth';

// TODO: fill in missing translations
const translations = {
  modalTitle: 'Login',
  socialLoginTitle: 'Login using:',
  socialSignupTitle: 'Sign-up using:',

  formTitle: '...or login with email:',
  formNoAccountSignup: 'New to Give InKind?',
  formNoAccountSignupLink: 'Sign-up here',
  formForgotPassword: 'Forgot Password?',
  formRememberMe: 'Remember me',
  formUseDifferentEmail: 'Use a different email',

  formSubmitButton: 'Sign in',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
