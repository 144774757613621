import type { Hit } from '@algolia/client-search';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { InkindAlgoliaObject } from '@gik/search/components';
import React from 'react';

export function getInkindAPIOrAlgolia(inkindPageOrAlgolia: InkindPageAPIModel | Hit<InkindAlgoliaObject>) {
  const inkindPage = inkindPageOrAlgolia as InkindPageAPIModel;
  const inkindAlgolia = inkindPageOrAlgolia as Hit<InkindAlgoliaObject>;

  const slug = inkindPage?.slug ?? inkindAlgolia?.slug;
  const title = inkindPage?.title ?? inkindAlgolia?.pageTitle;
  const imgSrc = inkindPage?.imgSrc ?? inkindAlgolia?.mediumImageUrl;
  const routeId = inkindPage?.routeId ?? inkindAlgolia?.objectID;
  const groupId = inkindPage?.groupId ?? inkindAlgolia?.groupIds?.filter(g => g !== 'giveinkind')?.[0];
  const location = inkindPage?.location ?? inkindAlgolia?.recipientCity + ', ' + inkindAlgolia?.recipientState;
  const isPremium = inkindPage?.isPremium ?? inkindAlgolia?.isPremium;
  const shortUrl = inkindPage?.shortUrl ?? inkindAlgolia?.shortUrl;
  const url = inkindPage?.url ?? inkindAlgolia?.url;
  const ownerId = inkindPage?.owner?.userId ?? inkindPage?.ownerData?.userId ?? inkindAlgolia?.ownerId;
  const organizers = inkindPage?.organizers ?? inkindAlgolia?.organizersIds?.map(o => ({ userId: o })) ?? [];

  return {
    slug,
    title,
    imgSrc,
    routeId,
    groupId,
    location,
    isPremium,
    shortUrl,
    url,
    ownerId,
    organizers,
  };
}

export function useInkindAPIOrAlgolia(inkindPageOrAlgolia: InkindPageAPIModel | Hit<InkindAlgoliaObject>) {
  return React.useMemo(() => getInkindAPIOrAlgolia(inkindPageOrAlgolia), [inkindPageOrAlgolia]);
}
