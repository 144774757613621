import { useUser } from '@gik/api/users/user';
import type { OrganizerDetail } from '@gik/core/models/gik/InkindPage';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { copyWithNotification } from '@gik/core/utils/LinkUtils';
import { Avatar } from '@gik/ui/Avatar';
import { Button } from '@gik/ui/Button';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export type PageOrganizerDisplayProps = {
  userId: string;
  isOwner: boolean;
  organizerDetail: OrganizerDetail;
} & UIComponent;

export function PageOrganizerDisplay({
  children,
  className,
  userId,
  isOwner,
  organizerDetail,
  ...otherProps
}: React.PropsWithChildren<PageOrganizerDisplayProps>): React.ReactElement {
  const bem = useBemCN('page-organizer-display');
  const { t } = useTranslation();

  const { data: user } = useUser(userId);

  const email = organizerDetail?.email;

  const currentUserId = useUserStore(state => state.id);

  const emailComponent = (
    <Button
      {...bem('email')}
      variant="primary-link"
      onClick={() => copyWithNotification(email, t(translationKeys.emailCopied))}
    >
      {email}
    </Button>
  );
  const pageOwnerComponent = isOwner && <span {...bem('owner')}>{t(translationKeys.pageOwner)}</span>;

  if (!user) return <LoadingSpinner />;

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <div {...bem('contact-line')} key={email}>
        <main>
          <Avatar {...bem('avatar')} imageUrl={user.avatarThumbSmallURL} circle />
          <div {...bem('contact-line-content')}>
            <div {...bem('top')}>
              <span {...bem('name')}>{user.fullName}</span>
              {pageOwnerComponent}
            </div>
            {currentUserId && emailComponent}
          </div>
        </main>
      </div>
    </div>
  );
}
