import { recipientFullNameField } from '@gik/core/form/formSchemas';
import { gofundmeRegex, gofundmeShortRegex } from '@gik/core/utils/GoFundMe';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { FeatureCareCalendarInputNames } from '@gik/create/enums/FeatureCareCalendarInputNames';
import { FeatureDonationsInputNames } from '@gik/create/enums/FeatureDonationsInputNames';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PageNameStepInputNames } from '@gik/create/enums/PageNameStepInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { translationKeys } from '@gik/create/i18n/en';
import type { ICreateFlowFormValues } from '@gik/create/models/CreateFlowFormValues';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { translationKeys as validationTranslationKeys } from '@gik/ui/Form/i18n/en';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import React from 'react';
import type { IRecipientTypeCardProps } from './components/AboutStep/AboutStep';
import SituationTile from './components/SituationStep/SituationTile';

export const paypalMeUrlRegex =
  /(http:\/\/|https:\/\/)?(www\.)?paypal.(com|me)?\/(paypalme\/)?([a-z|A-Z|0-9|\-|_]+)\/?/;
export const paypalMeAtUsernameRegex = /^@([a-z|A-Z|0-9|\-|_]+)/;
export const paypalMeUsernameRegex = /^[a-zA-Z0-9\-_]+$/;
export const cashtagRegex = /^\S(?=.*[A-Za-z])[A-Za-z\d]{1,19}$/;
export const venmoRegex = /^@[a-zA-Z0-9_-]{5,30}$/;

export const aboutStepSchema = (isUserLoggedIn: boolean): FormSchemaEntry[] => [
  {
    label: i18n.t(translationKeys.AboutStepRecipientTypeLabel),
    type: 'select-list',
    name: AboutStepInputNames.RecipientType,
    errorName: 'recipient type',
    required: true,
    props: {
      options: [
        {
          label: 'Myself',
          value: RecipientType.Myself,
          image: getAssetsUrl() + 'core/img/recipient/myself.svg',
        },
        {
          label: 'Someone Else',
          value: RecipientType.SomeoneElse,
          image: getAssetsUrl() + 'core/img/recipient/someone-else.svg',
        },
        {
          label: 'Organization',
          value: RecipientType.Organization,
          image: getAssetsUrl() + 'core/img/recipient/organization.svg',
        },
      ],
      render: (item: IRecipientTypeCardProps, selected: boolean) => {
        return <SituationTile horizontal selected={selected} image={item.image} label={item.label} />;
      },
    },
  },
  {
    label: i18n.t(translationKeys.AboutStepEmailLabel),
    type: 'email',
    required: !isUserLoggedIn,
    name: AboutStepInputNames.Email,
    errorName: 'email',
  },
];

export const featureCareCalendarSchema: (formValues: Partial<ICreateFlowFormValues>) => FormSchemaEntry[] = () => [
  {
    type: 'yes-no',
    name: FeatureCareCalendarInputNames.CareCalendarEnabled,
    errorName: 'Care Calendar',
    requiredMessage: i18n.t(validationTranslationKeys.requiredThis),
    required: true,
  },
];

export const featureWishlistSchema: (formValues: Partial<ICreateFlowFormValues>) => FormSchemaEntry[] = () => [
  {
    type: 'yes-no',
    name: FeatureWishlistInputNames.WishlistEnabled,
    errorName: 'Wishlist',
    requiredMessage: i18n.t(validationTranslationKeys.requiredThis),
    required: true,
  },
];

export const featureDonationsSchema: (formValues: Partial<ICreateFlowFormValues>) => FormSchemaEntry[] = () => [
  {
    type: 'yes-no',
    name: FeatureDonationsInputNames.DonationsEnabled,
    errorName: 'Donations',
    requiredMessage: i18n.t(validationTranslationKeys.requiredThis),
    required: true,
  },
];

export const privacyStepSchema: FormSchemaEntry[] = [];

export const pageNameStepSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: PageNameStepInputNames.PageName,
    required: true,
    maxLength: 80,
    errorName: 'Page Name',
    props: {
      placeholder: i18n.t(translationKeys.PageNamePlaceholder),
    },
  },
];

// todo move this function definition to where the schema is used and pass it down to the schema as a function argument
function handleAddAsPageOrganizerInfo(ev: React.MouseEvent<HTMLDivElement>) {
  ev.stopPropagation();
  ev.preventDefault();
  // TODO: i18n
  UI.info(
    <div>
      <p>To allow the recipient to edit your page and send Thank You notes, add them as a Page Organizer.</p>
      <p>Additional Page Organizers can be invited later in the Page Settings.</p>
    </div>,
    {
      title: 'Adding Page Organizers',
      closable: false,
      className: 'gik-modal--add-as-page-organizer-info',
      buttons: ({ close }) => [
        <Button key="close" onClick={close}>
          Got It
        </Button>,
      ],
    }
  );
}

const recipientFullNameCustomProps = (isOrganization: boolean) =>
  ({
    placeholder: i18n.t(
      isOrganization ? translationKeys.OrganizationNamePlaceholder : translationKeys.RecipientNamePlaceholder
    ),
  } as FormSchemaEntry);

export const recipientInfoFormSchema = (isOrganization = false): FormSchemaEntry[] => [
  recipientFullNameField(recipientFullNameCustomProps(isOrganization)),
  {
    type: 'email',
    name: RecipientInfoStepInputNames.RecipientEmail,
    required: true,
    placeholder: i18n.t(
      isOrganization ? translationKeys.OrganizationEmailPlaceholder : translationKeys.RecipientEmailPlaceholder
    ),
  },
  {
    type: 'email',
    name: RecipientInfoStepInputNames.RecipientEmailConfirm,
    required: true,
    mustEqualCaseInsensitive: RecipientInfoStepInputNames.RecipientEmail,
    placeholder: i18n.t(
      isOrganization
        ? translationKeys.OrganizationEmailConfirmPlaceholder
        : translationKeys.RecipientEmailConfirmPlaceholder
    ),
  },
  {
    type: 'checkbox',
    name: RecipientInfoStepInputNames.AddAsPageOrganizer,
    props: {
      label: (
        <span className="tw-inline-block">
          <>
            {i18n.t(translationKeys.AddAsPageOrganizerLabel)}&nbsp;&nbsp;
            <SvgIcon Icon={InformationCircleIcon} onClick={handleAddAsPageOrganizerInfo} />
          </>
        </span>
      ),
    },
  },
];

export const pageSetupStepSchema: FormSchemaEntry[] = [];

export const payPalFormSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: PayPalFormInputNames.EmailAddress,
    placeholder: 'name',
    required: false,
    prepend: 'paypal.me/',
    customValidation: [
      {
        // returns false when field is valid
        validate: (value: string) =>
          !(paypalMeUsernameRegex.test(value) || paypalMeAtUsernameRegex.test(value) || paypalMeUrlRegex.test(value)),
        message: i18n.t(translationKeys.PaypalInvalidName),
      },
    ],
  },
];

export const goFundMeFormSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: GoFundMeFormInputNames.PageURL,
    placeholder: 'gofundme.com/f/page',
    required: false,
    customValidation: [
      {
        validate: (value: string) => !gofundmeRegex.test(value) && !gofundmeShortRegex.test(value),
        message: i18n.t(translationKeys.InvalidGoFundMeURL),
      },
    ],
  },
];

export const venmoFormSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: VenmoFormInputNames.VenmoAddress,
    placeholder: '@username',
    required: false,
    customValidation: [
      {
        validate: (value: string) => !venmoRegex.test(value),
        message: i18n.t(translationKeys.VenmoInvalidName),
      },
    ],
  },
];

export const cashAppFormSchema: () => FormSchemaEntry[] = () => [
  {
    type: 'text',
    name: CashAppFormInputNames.CashAppAddress,
    placeholder: '$Cashtag',
    required: false,
    customValidation: [
      {
        validate: (value: string) => !cashtagRegex.test(value),
        message: i18n.t(translationKeys.CashAppInvalidName),
      },
    ],
  },
];
