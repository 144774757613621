import { useFullStory } from '@gik/analytics/hooks/useFullStory';
import { useDatadog } from './useDatadog';
import { useGoogleAnalytics } from './useGoogleAnalytics';
import { useGoogleTagManager } from './useGoogleTagManager';
import { useMixPanel } from './useMixPanel';

export function useAnalytics() {
  useFullStory();
  useMixPanel();
  useDatadog();
  useGoogleAnalytics();
  useGoogleTagManager();
}
