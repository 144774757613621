export enum AnalyticsEvents {
  Share = 'Share',
  Donate = 'Donate',
  User_Registered_GTM = 'User_Registered_GTM',
  CalendarAddRequestClicked = 'CalendarAddRequestClicked',
  CheckoutCompletePurchase = 'CheckoutCompletePurchase',
  UnclaimedEventViewed = 'UnclaimedEventViewed',
  ViewClaimConflict = 'ViewClaimConflict',
  ResolveClaimConflict = 'ResolveClaimConflict',
  EventCreateStarted = 'EventCreateStarted',
  eventClaimed = 'eventClaimed',
  eventUnclaimed = 'eventUnclaimed',
  RecipientAddAddressPromptOpen = 'RecipientAddAddressPromptOpen',
  RecipientAddAddressPromptSkipped = 'RecipientAddAddressPromptSkipped',
  RecipientAddAddressPromptAdded = 'RecipientAddAddressPromptAdded',
  eventCreated = 'eventCreated',
  eventUpdated = 'eventUpdated',
  EventCreateCancelled = 'EventCreateCancelled',
  CalendarEventClaimModalOpened = 'CalendarEventClaimModalOpened',
  CalendarEventClaimModalClosed = 'CalendarEventClaimModalClosed',
  LoadCardCarrierCheckout = 'LoadCardCarrierCheckout',
  LoadGiftCardCheckout = 'LoadGiftCardCheckout',
  LoadShippingCheckout = 'LoadShippingCheckout',
  LoadFinishCheckout = 'LoadFinishCheckout',
  CompletedPurchaseCloseModal = 'CompletedPurchaseCloseModal',
  BuySelectPage = 'BuySelectPage',
  BuyClick = 'BuyClick',
  BuyForSomeoneElse = 'BuyForSomeoneElse',
  BuySelectExternal = 'BuySelectExternal',
  PremiumPageUpgrade = 'PremiumPageUpgrade', // see below
  PremiumPageUpgradeModalOpened = 'PremiumPageUpgradeModalOpened',
  CreatePageLoginSignupFailed = 'CreatePageLoginSignupFailed',
  CreateClicked = 'CreateClicked',
  CreateStarted = 'CreateStarted',
  CreateFinished = 'CreateFinished',
  CreateHelpClicked = 'CreateHelpClicked',
  Create_Page_GTM = 'Create_Page_GTM',
  CreatePageFailed = 'CreatePageFailed',
  SupporterDetailsViewed = 'SupporterDetailsViewed',
  SupportersExported = 'SupportersExported',
  ResendTangoCard = 'ResendTangoCard',
  InkindPageTourFinished = 'InkindPageTourFinished',
  ViewFeaturedPage = 'ViewFeaturedPage',
  OpenSendAGiftBoxModal = 'OpenSendAGiftBoxModal',
  OpenShareInkindModal = 'OpenShareInkindModal',
  SearchButtonClicked = 'SearchButtonClicked',
  JITDataCollectionModalOpened = 'JITDataCollectionModalOpened',
  VisaCTAClicked = 'VisaCTAClicked',
  Search = 'Search',
  CheckoutError = 'CheckoutError',
  AddFromWishlistStep = 'AddFromWishlistStep',
  AddFromCalendarStep = 'AddFromCalendarStep',
  AddFromCalendarClaimStep = 'AddFromCalendarClaimStep',
  UserNotice = 'user_notice',
  AddFromCalendarOpen = 'AddFromCalendarOpen',
  AddFromCalendarClaimDate = 'AddFromCalendarClaimDate',
  AddFromCalendarBuyClick = 'AddFromCalendarBuyClick',
  AddFromCalendarCardCarrier = 'AddFromCalendarCardCarrier',
  AddFromCalendarGiftCard = 'AddFromCalendarGiftCard',
  AddFromCalandarLoadCheckout = 'AddFromCalandarLoadCheckout',
  AddFromWishlistOpen = 'AddFromWishlistOpen',
  AddFromWishlistBuyClick = 'AddFromWishlistBuyClick',
  AddFromWishlistCardCarrier = 'AddFromWishlistCardCarrier',
  AddFromWishlistGiftCard = 'AddFromWishlistGiftCard',
  AddFromWishlistLoadCheckout = 'AddFromWishlistLoadCheckout',
  LoadFinishCheckoutRemovedGiftCard = 'LoadFinishCheckoutRemovedGiftCard',
  ClaimConflictsOpen = 'ClaimConflictsOpen',
  ClaimConflictsRemoveGiftCard = 'ClaimConflictsRemoveGiftCard',
  ClaimConflictsUndoRemoved = 'ClaimConflictsUndoRemoved',
  ClaimConflictsResolved = 'ClaimConflictsResolved',
  CheckoutPlaceOrder = 'CheckoutPlaceOrder',
}

export enum DataLayerEvents {
  CreateClicked = 'create_clicked',
  SearchButtonClicked = 'search_button_clicked',
  CreateHelpClicked = 'create_help_clicked',
  CreateStarted = 'create_started',
  InkindPageTourFinished = 'tour_finished',
  UnclaimedEventViewed = 'unclaimed_event_viewed',
  PremiumPageUpgradeModalOpened = 'premium_modal',
  PremiumPageUpgraded = 'premium_upgrade',
  ResendTangoCard = 'resend_card',
  SupporterDetailsViewed = 'supporter_details',
  SupportersExported = 'supporters_export',
  VisaCTAClicked = 'visa_cta_clicked',
  Search = 'view_search_results',
  CheckoutError = 'checkout_error',
}

export const analyticsToDataLayerEvents = {
  [AnalyticsEvents.CreateClicked]: DataLayerEvents.CreateClicked,
  [AnalyticsEvents.SearchButtonClicked]: DataLayerEvents.SearchButtonClicked,
  [AnalyticsEvents.CreateHelpClicked]: DataLayerEvents.CreateHelpClicked,
  [AnalyticsEvents.CreateStarted]: DataLayerEvents.CreateStarted,
  [AnalyticsEvents.InkindPageTourFinished]: DataLayerEvents.InkindPageTourFinished,
  [AnalyticsEvents.UnclaimedEventViewed]: DataLayerEvents.UnclaimedEventViewed,
  [AnalyticsEvents.PremiumPageUpgradeModalOpened]: DataLayerEvents.PremiumPageUpgradeModalOpened,
  [AnalyticsEvents.PremiumPageUpgrade]: DataLayerEvents.PremiumPageUpgraded,
  [AnalyticsEvents.ResendTangoCard]: DataLayerEvents.ResendTangoCard,
  [AnalyticsEvents.SupporterDetailsViewed]: DataLayerEvents.SupporterDetailsViewed,
  [AnalyticsEvents.SupportersExported]: DataLayerEvents.SupportersExported,
  [AnalyticsEvents.VisaCTAClicked]: DataLayerEvents.VisaCTAClicked,
  [AnalyticsEvents.Search]: DataLayerEvents.Search,
  [AnalyticsEvents.CheckoutError]: DataLayerEvents.CheckoutError,
} as const;
