import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import { apiCap } from '..';

const path = 'service';

function getParams() {
  return {
    perPage: apiCap,
    filter: {
      meta_query: {
        0: {
          key: 'preview_mode',
          value: 0,
        },
      },
    },
  };
}

export function getServices() {
  return wpApi
    .get(path, {
      searchParams: getParams(),
    })
    .json<IWordpressService>();
}

export function useServices(config?: SWRConfigInterface) {
  return useWPApi<IWordpressService[]>(path, getParams(), config);
}
