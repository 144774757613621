import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { IAlternatePhoneNumberValues } from './AlternatePhoneNumberForm';
import { AlternatePhoneNumberForm } from './AlternatePhoneNumberForm';

export interface IAlternatePhoneNumberModalContentProps {
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: IAlternatePhoneNumberValues) => void;
  initialValues?: IAlternatePhoneNumberValues;
  closeFn?: () => void;
}

export const alternatePhoneNumberModalContentBlockName = 'alternate-phone-number-modal-content';
const bem = bemBlock(alternatePhoneNumberModalContentBlockName);

const modalFooterId = 'alternate-phone-number-modal-footer-id';

export const AlternatePhoneNumberModalContent: React.FC<IAlternatePhoneNumberModalContentProps> = ({
  onSubmit,
  initialValues,
  buttonsPortal,
  closeFn,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <p className={bem('description')}>{t(translationKeys.alternatePhoneNumberDescription)}</p>
      <AlternatePhoneNumberForm
        buttonsPortal={buttonsPortal}
        closeFn={closeFn}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onCancel={() => closeFn()}
      />
    </>
  );
};

export const openAlternatePhoneNumberModal = (props: IAlternatePhoneNumberModalContentProps): void => {
  UI.info(
    ({ close }) => (
      <AlternatePhoneNumberModalContent
        {...props}
        buttonsPortal={() => document.getElementById(modalFooterId)}
        closeFn={close}
      />
    ),
    {
      closable: false,
      title: i18n.t(translationKeys.alternatePhoneNumberTitle),
      className: bem(),
      footer: () => <div key="footer" id={modalFooterId} className="gik-modal__toolbar" />,
    }
  );
};
