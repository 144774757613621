import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type { BrandCategory, IWordpressBrandCategory } from '@gik/calendar/models/BrandCategory';
import React from 'react';
import he from 'he';

const path = 'brand-category';

function getParams() {
  return {
    _fields: ['id', 'name', 'parent'],
    'proxy-cms-pages': 'all',
  };
}

export function useBrandCategories(config?: SWRConfigInterface & { disabled?: boolean }) {
  const { data: wpBrandCategories } = useWPApi<IWordpressBrandCategory[]>(
    config?.disabled ? null : path,
    getParams(),
    config
  );

  return React.useMemo(() => {
    if (!wpBrandCategories) return null;

    const brandCategories = wpBrandCategories
      .map(c => ({
        ...c,
        name: he.decode(c.name),
      }))
      .filter(c => c.parent === 0)
      .map(c => c as BrandCategory);
    brandCategories.forEach(c => {
      c.children = wpBrandCategories.filter(_c => _c.parent === c.id).map(c => c as BrandCategory);
    });

    return brandCategories;
  }, [wpBrandCategories]);
}
