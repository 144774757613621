import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'benefits-list';

// TODO: fill in missing translations
const translations = {
  emotionalTitle: 'Emotional Support',
  emotionalDescription: 'A way to show that their village is here for them',
  informationalTitle: 'Informational Support',
  informationalDescription: 'Articles and guides that tell people exactly how to help show so you don’t have to',
  tangibleTitle: 'Tangible Support',
  tangibleDescription: 'Easily answers the question, “How can I help?”',
  belongingTitle: 'Belonging Support',
  belongingDescription: 'Never go through an important moment alone',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
