import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useApi } from '@gik/core/api/swr/useApi';

export interface ICalendarEntriesResponse {
  events: ICalendarEvent[];
  entries: ICalendarEntry[];
  lastActiveMonth?: string;
}

export enum EventLogType {
  FollowPage = 0,
  UnfollowPage = 1,
  Purchase = 2,
  CreateClaim = 3,
  Unclaim = 4,
  TangoCardFulfillment = 5,
  CreatePage = 6,
  CreateCalendarEntry = 7,
  DeactivatePage = 8,
  ThankYou = 9,
  PerfectgiftFulfillment = 10,
  PerfectgiftOrderCreation = 11,
}

export interface IEventLogEntry {
  Id: string;
  createdDateTime: string;
  eventType: EventLogType;
  inkindRouteId: string;
  userEmailAddress: string;
  userFullName: string;
  userFirstName: string;
  userLastName: string;
  userId: string;
  calendarEntryId: string;
  calendarClaimId: number;
  importVersion: number;
  privateClaim: boolean;
  anonymous: boolean;
}

const path = 'inkinds';

export type CalendarEventsParams = {
  fromDate: string;
  toDate: string;
};

export function getParams(params: CalendarEventsParams) {
  return {
    ...params,
  };
}

export function useCalendarEvents(inkindRouteId: string, params: CalendarEventsParams, config?: SWRConfigInterface) {
  const isValid = inkindRouteId && params.fromDate !== 'Invalid date' && params.toDate !== 'Invalid date';

  return useApi<ICalendarEntriesResponse>(
    isValid ? `${path}/${inkindRouteId}/calendar` : null,
    getParams(params),
    config
  );
}

// {inkindRouteId}/calendarEntry/{entryId}/claimId/{claimId}/historyEntry
export function useCalendarEventClaimHistory(
  inkindRouteId: string,
  entry: ICalendarEntry,
  config?: SWRConfigInterface
) {
  return useApi<IEventLogEntry[]>(
    entry && inkindRouteId ? `${path}/${inkindRouteId}/claimId/${entry.claim.id}/historyEntry` : null,
    null,

    {
      ...(config ?? {}),
      loadingTimeout: 0,
    }
  );
}

export function useAvailableCalendarEvents(
  inkindRouteId: string,
  calendarEventTypeId: number,
  config?: SWRConfigInterface
) {
  return useApi<ICalendarEntriesResponse>(
    inkindRouteId && calendarEventTypeId !== undefined ? `${path}/${inkindRouteId}/calendar` : null,
    {
      availableOnly: true,
      noCache: true,
      calendarEventTypeId,
    },
    config
  );
}
