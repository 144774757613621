import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import type { Cell, Row } from 'react-table';
import { useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import type { UITableVariant } from './types';

export interface IStickyTableProps<T extends object> extends React.HTMLAttributes<HTMLTableElement> {
  data: T[];
  // TODO: fix typings, remove eslint-disable-next-line
  // eslint-disable-next-line
  columns?: any[];
  fullWidth?: boolean;
  scrollable?: boolean;
  useStickyHeader?: boolean;
  caption?: React.ReactNode;
  variant?: UITableVariant;
}

export function StickyTable<T extends object>({
  data,
  columns,
  className,
  fullWidth,
  scrollable,
  useStickyHeader,
  caption,
  variant,
  ...otherProps
}: IStickyTableProps<T>): React.ReactElement {
  const bem = bemBlock('sticky-table');

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      ...otherProps,
      columns,
      data,
    },
    useSticky
  );

  return (
    <div className={bem('wrapper', [{ scrollable }, { 'full-width': fullWidth }])}>
      <table
        {...getTableProps()}
        className={bem(null, [{ 'full-width': fullWidth }, { [variant]: variant }, 'sticky'], className)}
      >
        <caption>{caption}</caption>
        {headerGroups && (
          <thead className={bem('thead')}>
            {
              // TODO: fix typings, remove eslint-disable-next-line
              // eslint-disable-next-line
              headerGroups.map((headerGroup: any, index: number) => {
                const rowProps = headerGroup.getHeaderGroupProps();
                return (
                  <tr className={bem('tr')} {...rowProps} key={index}>
                    {headerGroup.headers.map((column, colIndex: number) => {
                      const colProps = column.getHeaderProps();
                      const colClasses = bem('th', [column?.accessor, column?.variant]);

                      const colStyle = {
                        ...column?.style,
                        ...colProps?.style,
                      };
                      return (
                        <th style={colStyle} className={colClasses} key={colIndex}>
                          {useStickyHeader && (
                            <StickyContainer>
                              <Sticky>
                                {({ style }) => {
                                  return (
                                    <div style={style} className={bem('th-inner')}>
                                      {column.render('Header')}
                                    </div>
                                  );
                                }}
                              </Sticky>
                            </StickyContainer>
                          )}
                          {!useStickyHeader && <div className={bem('th-inner')}>{column.render('Header')}</div>}
                        </th>
                      );
                    })}
                  </tr>
                );
              })
            }
          </thead>
        )}
        <tbody className={bem('tbody')} {...getTableBodyProps()}>
          {rows.map((row: Row<T>, rowIndex: number) => {
            prepareRow(row);
            return (
              <tr className={bem('tr')} key={rowIndex} {...row.getRowProps()}>
                {row.cells.map((cell: Cell<T>, cellIndex: number) => {
                  const colProps = cell.getCellProps();
                  const column = columns[cellIndex];
                  const colClasses = bem('td', [column?.accessor, column?.variant]);
                  if (colProps?.style) delete colProps.style.zIndex;
                  const colStyle = {
                    ...column?.style,
                    ...colProps?.style,
                  };
                  return (
                    <td {...colProps} style={colStyle} className={colClasses} key={cellIndex}>
                      <div className={bem('td-inner')}>{cell.render('Cell')}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>

        {/* TODO: Footer groups not working properly, should add more stories for the table component and make this work as it should */}
        {/* {footerGroups && (
          <tfoot>
            {footerGroups.map((footerGroup, footerIndex) => (
              <tr key={footerIndex} {...footerGroup.getHeaderGroupProps()} className="tr">
                {footerGroup.headers.map((column, columnIndex) => (
                  <td key={columnIndex} {...column.getHeaderProps()} className="td">
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )} */}
      </table>
    </div>
  );
}
