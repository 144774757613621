import { translationKeys } from '@gik/auth/components/Signup/i18n/en';
import type { IWordpressCreateFlowCategory } from '@gik/core/models/wordpress/WordpressSituation';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import { EmailTakenStatus } from '@gik/create/enums/EmailTakenStatus';
import { useCreateFlowStore } from '@gik/create/store/CreateFlowStore';
import i18n from '@gik/i18n';
import coreTranslationKeys from '@gik/i18n/en/core';
import { Button } from '@gik/ui/Button';
import { Carousel } from '@gik/ui/Carousel';
import { IntercomButton } from '@gik/ui/gik/IntercomButton/IntercomButton';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';

export interface ICreatePageNavigationProps {
  stepIndex: number;
  className?: string;
  hasNext?: boolean;
  trackIntercomClicked?: () => void;
}

const blockName = 'create-page-navigation';

export default function CreatePageNavigation({
  stepIndex,
  className,
  hasNext,
  trackIntercomClicked,
}: ICreatePageNavigationProps) {
  const bem = bemBlock(blockName);
  const nextDisabled = useCreateFlowStore(state => state.nextDisabled);
  const finishButtonSubmitting = useCreateFlowStore(state => state.finishButtonSubmitting);
  const { handleBack, handleNext, handleFinish } = React.useContext(CreatePageContext);
  const { footerSituationCarouselRef } = React.useContext(CreatePageContext);
  const situationCarouselRef = useCreateFlowStore(state => state.situationCarouselRef);
  const emailTakenStatus = useCreateFlowStore(state => state.emailTakenStatus);
  const userId = useUserStore(state => state.id);

  const selectedCategoryIndex = useCreateFlowStore(state => state.selectedCategoryIndex);
  const situationCategories = useCreateFlowStore(state => state.situationCategories);

  const [categories, setCategories] = React.useState<IWordpressCreateFlowCategory[]>();

  React.useEffect(() => {
    const matchingCategories: IWordpressCreateFlowCategory[] = situationCategories?.map((item, index) => ({
      ...item,
      index,
    }));
    setCategories(matchingCategories);
  }, [situationCategories, setCategories]);

  return (
    <div className={bem('wrapper', [{ 'has-dots': stepIndex === 1 }])}>
      {situationCarouselRef && categories && stepIndex === 1 && (
        <Carousel
          initialSlide={selectedCategoryIndex}
          variant="primary"
          asNavFor={situationCarouselRef}
          dots={true}
          legacyIndicator
          arrows={false}
          ref={footerSituationCarouselRef}
        >
          {categories.map(category => (
            <div key={category.value}>1</div>
          ))}
        </Carousel>
      )}
      <div className={bem(null, null, className)}>
        <div className={bem('left-group')}>
          <IntercomButton onClick={() => void trackIntercomClicked?.()} />
        </div>

        <div className={bem('right-group')}>
          <Button
            className={bem('btn-back')}
            variant={'default-light'}
            onClick={handleBack}
            disabled={stepIndex <= 0}
            prepend={<SvgIcon Icon={ChevronLeftIcon} />}
          >
            {i18n.t(coreTranslationKeys.core.back).toString()}
          </Button>
          {hasNext && (
            <Button
              className={bem('btn-next')}
              onClick={handleNext}
              disabled={nextDisabled}
              append={<SvgIcon Icon={ChevronRightIcon} />}
            >
              {i18n.t(coreTranslationKeys.core.next).toString()}
            </Button>
          )}
          {!hasNext && (
            <Button onClick={handleFinish} disabled={nextDisabled} loading={finishButtonSubmitting}>
              {i18n
                .t(
                  emailTakenStatus === EmailTakenStatus.FREE && !userId
                    ? translationKeys.formSubmitButton
                    : coreTranslationKeys.core.finish
                )
                .toString()}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
