import { isMobile } from '@/utils/environment';
import { calendarEventClaimFormContentId, checkoutFormContentId } from '@gik/calendar/utils/CalendarModals';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import type { ButtonElement } from '@gik/ui/Button/ButtonProps';
import { Input } from '@gik/ui/Input';
import { Popover } from '@gik/ui/Popover';
import { PopoverMenu } from '@gik/ui/PopoverMenu';
import { Separator } from '@gik/ui/Separator';
import { SvgIcon } from '@gik/ui/SvgIcon';
import type { UISize } from '@gik/ui/typesValues';
import ChevronDownIcon from '@heroicons/react/outline/ChevronDownIcon';
import type { KeyboardEventHandler } from 'react';
import React, { type ReactElement } from 'react';
import { scroller } from 'react-scroll';
import { setTimeout } from 'timers';
import { v4 as uuidv4 } from 'uuid';

export interface SelectOptionsType {
  label: string;
  value: string;
  searchTags?: string[];
  icon?: React.ReactNode;
}

type ISelectPropsBase = UIComponent & {
  options: SelectOptionsType[];
  name?: string;
  filterable?: boolean;
  filterPlaceholder?: string;
  autoComplete?: string;
  placeholder?: string;
  loading?: boolean;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  hasError?: boolean;
  hasWarning?: boolean;
  hasSuccess?: boolean;
  disabled?: boolean;
  handlerIcon?: SvgrComponent;
  size?: UISize;
  autoSelectValueOnExactSearchMatch?: boolean;
  autoscroll?: boolean;
};

export type ISingleSelectProps = ISelectPropsBase & {
  value: string;
  onChange: (value: string) => void;
  multi?: false | undefined | null;
};

export type IMultiSelectProps = ISelectPropsBase & {
  value: string[];
  onChange: (value: string[]) => void;
  multi: true;
  clearable?: boolean;
};

export type ISelectProps = IMultiSelectProps | ISingleSelectProps;

export type ISelectRef = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

function SelectComp(props: ISingleSelectProps, ref: React.Ref<ISelectRef>): ReactElement | null;
function SelectComp(props: IMultiSelectProps, ref: React.Ref<ISelectRef>): ReactElement | null;
function SelectComp(
  {
    className,
    options,
    filterable,
    multi,
    name,
    autoComplete,
    placeholder = 'Select...',
    filterPlaceholder = 'Search...',
    loading,
    onFocus,
    onBlur,
    hasError,
    hasWarning,
    hasSuccess,
    disabled,
    handlerIcon = ChevronDownIcon,
    size,
    autoSelectValueOnExactSearchMatch = true,
    autoscroll,
    ...otherProps
  }: ISelectProps,
  ref: React.Ref<ISelectRef>
): ReactElement | null {
  const bem = useBemCN('select');
  const uniqueId = React.useMemo(() => uuidv4(), []);

  const { value: singleValue, onChange: singleOnChange, ...divProps } = otherProps as ISingleSelectProps;
  const { value: multiValue, onChange: multiOnChange, clearable = true } = otherProps as IMultiSelectProps;

  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [suggested, setSuggested] = React.useState<SelectOptionsType>();
  const [autofilled, setAutofilled] = React.useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>();

  const hasNoValue = multi ? !multiValue?.length : !singleValue;

  const open = React.useCallback(() => {
    if (dontOpen.current) return;

    if (autoscroll)
      scroller.scrollTo(uniqueId, {
        duration: 300,
        offset: -32,
        smooth: true,
        container:
          document.getElementById(checkoutFormContentId) ?? document.getElementById(calendarEventClaimFormContentId),
      });

    setIsOpen(true);

    if (hasNoValue) return;

    setTimeout(() => {
      let value = singleValue;

      if (multi) {
        value = multiValue?.[0];
      }

      const scrollToValueId = `${uniqueId}-option-${value}`;
      const scrollContainerId = `${uniqueId}-options-container`;

      if (autoscroll)
        scroller.scrollTo(scrollToValueId, {
          container: document.getElementById(scrollContainerId),
        });
    });
  }, [autoscroll, hasNoValue, multi, multiValue, singleValue, uniqueId]);

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSearch('');
    setSuggested(null);

    if (focusEvent.current) {
      onBlur?.(focusEvent.current);
    }
  }, [onBlur]);

  const toggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = React.useCallback(
    (option: SelectOptionsType) => {
      if (multi) {
        if (multiValue?.includes(option.value)) {
          multiOnChange?.(multiValue?.filter(v => v !== option.value));
        } else {
          multiOnChange?.([...new Set([...(multiValue ?? []), option.value])]);
        }
      } else {
        singleOnChange?.(option.value);
        close();
      }

      setAutofilled(false);
    },
    [close, multi, multiOnChange, multiValue, singleOnChange]
  );

  const filter = React.useCallback(
    (search: string) => (option: SelectOptionsType) => {
      return (
        option?.label?.toLowerCase?.().startsWith(search?.toLowerCase()) ||
        option?.value?.toLowerCase().startsWith(search?.toLowerCase()) ||
        option?.searchTags?.some(tag => tag.toLowerCase().startsWith(search?.toLowerCase()))
      );
    },
    []
  );

  const dontOpen = React.useRef(false);
  const autocomplete = React.useCallback(
    (search: string, from?: string) => {
      dontOpen.current = true;
      setTimeout(() => {
        dontOpen.current = false;
      }, 500);

      if (!search?.length) {
        if (multi) multiOnChange?.([]);
        else singleOnChange?.(null);

        setAutofilled(false);
      }

      const results = options?.filter(filter(search));
      const exactMatch = results?.find(
        r =>
          r.value.toLowerCase() === search.toLowerCase() ||
          r.searchTags?.some(tag => tag.toLowerCase() === search.toLowerCase())
      );
      let result: SelectOptionsType;

      if (results?.length === 1) {
        result = results[0];
      } else if (exactMatch) {
        result = exactMatch;
      }

      if (!result) return;
      setAutofilled(true);
      setTimeout(() => {
        setAutofilled(true);
      }, 500);

      if (multi) {
        multiOnChange?.([exactMatch?.value]);
      } else {
        singleOnChange?.(exactMatch?.value);
      }
    },
    [multi, multiOnChange, singleOnChange, options, filter]
  );

  const handleKeyDown: KeyboardEventHandler<ButtonElement> = React.useCallback(() => {
    open();
  }, [open]);

  React.useImperativeHandle(
    ref,
    () => ({
      open,
      close,
      toggle,
    }),
    [open, close, toggle]
  );

  const focusEvent = React.useRef<React.FocusEvent<ButtonElement, Element>>();
  const handleButtonBlur: React.FocusEventHandler<ButtonElement> = React.useCallback(
    e => {
      if (open) {
        focusEvent.current = e;
        return;
      }

      onBlur?.(e);
    },
    [onBlur, open]
  );

  function findNextTabStop(el: HTMLElement) {
    const universe = document.querySelectorAll('input, button, select, textarea, a[href], a.gik-button');
    const elIndex = Array.prototype.indexOf.call(universe, el);

    const list = Array.prototype.filter.call(universe, function (item: HTMLElement, index: number) {
      return index > elIndex && item.tabIndex >= 0;
    });

    return list?.[0] ?? el;
  }

  const handleSearchChange = React.useCallback(
    (search: string) => {
      setSearch(search);

      if (!autoSelectValueOnExactSearchMatch || search?.length == 0) return;

      const results = options?.filter(filter(search));
      const exactMatch = results?.find(r => r.value.toLowerCase() === search.toLowerCase());
      let result: SelectOptionsType;

      if (results?.length === 1) {
        result = results[0];
      } else if (exactMatch) {
        result = exactMatch;
      }

      if (!result) return;

      setSuggested(result);
    },
    [autoSelectValueOnExactSearchMatch, filter, options]
  );

  const handleInputEnterKeyDown: KeyboardEventHandler<HTMLInputElement> = React.useCallback(
    e => {
      const onSelectEnter = e.key === 'Enter' && suggested;
      const onSelectTab = e.key === 'Tab' || (e.key === 'Enter' && isMobile());

      if (onSelectEnter || onSelectTab) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (onSelectEnter) {
        handleChange(suggested);
        setTimeout(() => findNextTabStop(buttonRef.current)?.focus?.(), timeoutDefaultValue);
      }

      if (onSelectTab) {
        close();
        setTimeout(() => findNextTabStop(buttonRef.current)?.focus?.(), timeoutDefaultValue);
      }
    },
    [close, handleChange, suggested]
  );

  const handleFocus: React.FocusEventHandler<ButtonElement> = React.useCallback(
    e => {
      if (isMobile()) {
        open();
      }

      onFocus?.(e);
    },
    [onFocus, open]
  );

  const label = React.useMemo(
    () =>
      multi
        ? multiValue?.length !== 1 &&
          multiValue?.[0] != null &&
          (multiValue?.map(v => options.find(o => o.value === v)?.label) ?? []).join(', ')
        : singleValue != null && options?.find(o => o.value == singleValue)?.label,
    [multi, multiValue, options, singleValue]
  );

  return (
    <div
      {...divProps}
      {...bem(
        null,
        [
          { 'has-error': hasError },
          { 'has-warning': hasWarning },
          { 'has-success': hasSuccess },
          { disabled },
          { open: isOpen },
          { autofilled },
        ],
        className
      )}
      id={uniqueId}
    >
      <input
        {...bem('autocomplete')}
        name={name}
        autoComplete={autoComplete}
        tabIndex={-1}
        onChange={e => autocomplete(e.target.value)}
      />

      {/* some browsers will autocomplete this input instead */}
      <input
        {...bem('tabindex-and-keyboard-trigger')}
        onFocus={open}
        onChange={e => autocomplete(e.target.value, 'tabindex-and-keyboard-trigger')}
      />

      <Popover
        placement={'bottom-start'}
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start'],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true,
              boundary: 'viewport',
            },
          },
        ]}
        trigger={
          <Button
            {...bem('select-trigger')}
            ref={buttonRef}
            variant={'white-outline'}
            onClick={open}
            append={<SvgIcon Icon={handlerIcon} />}
            loading={loading}
            fullWidth
            onFocus={handleFocus}
            onBlur={handleButtonBlur}
            onKeyDown={handleKeyDown}
            id={otherProps.id}
            disabled={disabled}
            size={size}
            preventClickWhenDisabled
            tabIndex={-1}
          >
            {hasNoValue && placeholder}
            {label}
          </Button>
        }
        isOpen={isOpen}
        onClose={close}
        hasArrowIndicator={false}
        noPad
      >
        <PopoverMenu>
          {filterable && (
            <section {...bem('header-container')}>
              <PopoverMenu.Item>
                <Input
                  value={search}
                  onValueChange={handleSearchChange}
                  placeholder={filterPlaceholder}
                  onKeyDown={handleInputEnterKeyDown}
                  autoFocus
                  clearable
                />
              </PopoverMenu.Item>
              <Separator thickness={'thin'} size={'xs'} />
            </section>
          )}

          <section {...bem('options-container')} id={`${uniqueId}-options-container`}>
            {options?.filter(filter(search))?.map(option => (
              <PopoverMenu.Item
                {...bem('menu-item', [
                  {
                    selected: multi
                      ? multiValue?.includes(option.value)
                      : options?.find(o => o.value == singleValue) === option,
                  },
                  {
                    suggested: suggested?.value === option.value,
                  },
                ])}
                key={option.value}
                onClick={() => handleChange(option)}
                id={`${uniqueId}-option-${option.value}`}
              >
                {option.icon && <span {...bem('icon')}>{option.icon}</span>}
                {option.label}
              </PopoverMenu.Item>
            ))}
          </section>

          {multi && clearable && (
            // TODO: this section needs to be sticky to the bottom of the popover
            <section {...bem('footer-container')}>
              <Separator thickness={'thin'} size={'xs'} />
              <PopoverMenu.Item fullWidth>
                <div {...bem('footer-actions')}>
                  <Button variant={'danger'} onClick={() => singleOnChange(null)}>
                    Clear
                  </Button>
                  <Button onClick={close}>Done</Button>
                </div>
              </PopoverMenu.Item>
            </section>
          )}
        </PopoverMenu>
      </Popover>
    </div>
  );
}

const _SelectComp = React.forwardRef<ISelectRef, ISelectProps>(SelectComp);

export const Select = withComponentErrorBoundary(_SelectComp);
