import { isBrowser } from '@/utils/environment';
import { AuthTool } from '@gik/core/components/DevTools/AuthTool';
import { EnvTools } from '@gik/core/components/DevTools/EnvTools';
import { StatusTool } from '@gik/core/components/DevTools/StatusTool';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { TabbedView } from '@gik/ui/TabbedView/TabbedView';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import React from 'react';
import { useEffectOnce, useLocalStorage } from 'react-use';
import { useDevStore } from '../../store/DevStore';
import type { UIComponent } from '../../types/UI';
import { useBemCN } from '../../utils/bemBlock';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorBoundaryFallback } from '../ErrorBoundary/ErrorBoundaryFallback';
import { AppTools } from './AppTools';
import { GroupTools } from './GroupTools';
import { PageTools } from './PageTools';
import { ShopTools } from './ShopTools';

export type DevToolsProps = {} & UIComponent;

export type DevToolsContext = {
  onlyRenderWhenClicked: boolean;
  forceSkeletons: boolean;
  forceViewportFallback: boolean;
};

export enum DevToolKeys {
  onlyRenderWhenClicked = 'gik-onlyRenderWhenClicked',
  forceSkeletons = 'gik-forceSkeletons',
  forceViewportFallback = 'gik-forceViewportFallback',
}

export const devToolsStorageKeyVisibility = 'gik-devtools';
export const DevTools = ({ className, ...otherProps }: React.PropsWithChildren<DevToolsProps>) => {
  const bem = useBemCN('devtools');
  const [_menuVisible, _setMenuVisible] = useLocalStorage<boolean>(devToolsStorageKeyVisibility, false);

  const isOpen = useDevStore(state => state.isOpen);
  const setIsOpen = useDevStore(state => state.setIsOpen);

  const activeTab = useDevStore(state => state.activeTab);
  const setActiveTab = useDevStore(state => state.setActiveTab);

  const menuVisible = React.useRef<boolean>(_menuVisible);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function toggleVisibility() {
    const newVis = !menuVisible.current;
    _setMenuVisible(newVis);
    menuVisible.current = newVis;

    // also toggle intercom visibility
    if (isBrowser()) {
      const intercomEl = document.getElementById('intercom-container-body');
      if (intercomEl) {
        if (newVis) {
          intercomEl.style.display = 'hidden';
        } else {
          intercomEl.style.display = 'block';
        }
      }
    }
  }

  useEffectOnce(() => {
    window.top['GikDevTools'] = () => toggleVisibility();

    // get initial visibility from storage
    if (isBrowser() && localStorage.getItem(devToolsStorageKeyVisibility) === 'true') {
      menuVisible.current = true;
      _setMenuVisible(true);
    }
  });

  if (!isBrowser()) return null;

  if (_menuVisible !== true) return null;

  return (
    <div {...bem(null, [{ open: isOpen }], [className])} {...otherProps}>
      <div {...bem('container')}>
        <div {...bem('inner')}>
          <header {...bem('header')} onClick={toggleOpen}>
            <span {...bem('logo')}>
              <SvgIcon Icon={HeartIcon} />
            </span>
          </header>
          <main {...bem('main', null, ['tw-space-y-2'])}>
            <ErrorBoundary fallback={ErrorBoundaryFallback} noForce>
              <TabbedView
                activeTab={activeTab}
                onChange={(_tab, index) => {
                  setActiveTab(index);
                }}
                tabs={[
                  {
                    title: `App`,
                    content: <AppTools />,
                  },
                  {
                    title: `Status`,
                    content: <StatusTool />,
                  },
                  {
                    title: `Shop`,
                    content: <ShopTools />,
                  },
                  {
                    title: `Pages`,
                    content: <PageTools />,
                  },
                  {
                    title: `Groups`,
                    content: <GroupTools />,
                  },
                  {
                    title: 'Auth',
                    content: <AuthTool />,
                  },
                  {
                    title: 'Environment',
                    content: <EnvTools />,
                  },
                ]}
              />
            </ErrorBoundary>
          </main>
        </div>
      </div>
    </div>
  );
};
