import type { APIWithPagination, APIWithSlug } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type { WordpressTaxonomy } from '@gik/core/models/wordpress/WordpressTaxonomy';
import type { GiveTaxonomyType } from '@gik/shop/types/GiveTaxonomyType';
import type { SWRConfiguration } from 'swr';
import { apiCap } from '..';

export const taxonomiesFields =
  'id,name,link,slug,parent,acf.taxonomy_svg_icon,sample_page_url.count,acf.page_name_suggestions,acf.taxonomy_svg_icon_alt,acf.tax_thumb_image,acf.weighting,acf.header_copy,acf.variant,acf.sample_inkind_page_url,acf.create_flow_categories';

export interface TaxonomiesParams extends APIWithSlug, APIWithPagination {
  include?: number[];
  parent?: number;
}

function getPath(taxonomyType: GiveTaxonomyType): string {
  switch (taxonomyType) {
    case 'situation':
      return 'situation';
    case 'recipient':
      return 'recipient';
    case 'category':
      return 'product_cat';
  }
}

function getParams(options: TaxonomiesParams) {
  return {
    _fields: taxonomiesFields,
    orderby: 'name',
    perPage: apiCap,
    page: 1,
    ...options,
  };
}

export function getTaxonomies(taxonomyType: GiveTaxonomyType, params?: TaxonomiesParams) {
  return wpApi
    .get(getPath(taxonomyType), {
      searchParams: getParams(params),
    })
    .json<WordpressTaxonomy[]>();
}

export function useTaxonomies(taxonomyType: GiveTaxonomyType, params?: TaxonomiesParams, config?: SWRConfiguration) {
  const path = getPath(taxonomyType);
  return useWPApi<WordpressTaxonomy[]>(path, getParams(params), config);
}
