import { useQuery } from '@tanstack/react-query';
import { giftWalletEndPoints as endPoints } from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../contexts';
import { type StrapiSearchParams } from '@gik/platform-management-api-js';
import type { WalletEntity } from '@gik/platform-management-api-js';
import { createPathWithSearchParams } from '../../utils/createPathWithSearchParams';

import { useQueryDefaults } from '../../client';
import { type ReactQueryOptions } from '../../types/react-query';

/**
 * Get Wallet by uuid
 */
export function useWalletByUuid(id: string, params?: StrapiSearchParams, options?: ReactQueryOptions<WalletEntity>) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getByUuid(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => {
      const response = await client.wallet.getByUuid(id, { params });
      return response?.data?.data?.[0];
    },
  });
}

/**
 * Get Wallet by customer id
 */
export function useWalletByCustomerId(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<WalletEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.wallet.getByCustomerId(id);

  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => {
      return (await client.wallet.getByCustomerId(id, { params })).data;
    },
  });
}

/**
 * Get Wallet by customer id
 */
export function useWalletSupporters(
  id: string,
  giftTypeId: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<WalletEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.wallet.getSupporters(id, giftTypeId);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    queryKey: [key],
    queryFn: async () => {
      const response = await client.wallet.getSupporters(id, giftTypeId, { params });
      return response.data;
    },
  });
}
