import { isBrowser } from '@/utils/environment';
import type React from 'react';
import ReactDOM from 'react-dom';

export function renderPortalWithRef(node: React.ReactNode, ref: React.MutableRefObject<HTMLElement>) {
  if (isBrowser() && ref && ref.current) return ReactDOM.createPortal(node, ref.current);
  return node;
}

export function renderPortal(node: React.ReactNode, portal: () => Element) {
  if (isBrowser() && portal && portal()) return ReactDOM.createPortal(node, portal());
  return node;
}
