// TODO: move this to l10n package
// TODO: get rid of moment
import type { Moment } from 'moment';
import moment from 'moment';

export default class DateTimeUtils {
  static readonly DateFormat = 'YYYY-MM-DD HH:mmZ';
  static readonly MonthFormat = 'YYYY-MM';
  static readonly Time12HourFormat = 'h:mmA';
  static readonly EmailDateFormat = 'M/D/YYYY';
}

// // TODO: the date formats should be dynamic based on user settings.
export const dateFormat = 'MMM D, YYYY';
export const dateTimeFormat = 'MMM D YYYY, h:mm:ss a';
export const timeFormat = 'h:mm:ss a';

// allow 12h behind current date in UTC timezone
export const isInPastAllTimezones = (date: Moment) => {
  if (!date) return false;
  return date.startOf('day').isBefore(moment.utc?.().subtract(12, 'hours').startOf('day'));
};
