import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Carousel } from '@gik/ui/Carousel/Carousel';
import { Image } from '@gik/ui/Image/Image';
import { KeepProportions, Square } from '@gik/ui/KeepProportions';
import { FrameBone } from '@gik/ui/SkeletonLoader';
import { UI } from '@gik/ui/UIManager';
import { LightboxMedia } from '@gik/ui/UIManager/UILightboxTypes';
import React from 'react';
import type { Settings } from 'react-slick';

const sliderSettings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
};

export type ProductPreviewProps = {
  product: Product;
  hasPhysicalCard?: boolean;
} & UIComponent;

export function ProductPreviewComp({ product, hasPhysicalCard }: ProductPreviewProps) {
  const bem = useBemCN('product-preview');

  const giftCardImage = React.useMemo(() => {
    if (product.isToggleable && hasPhysicalCard) {
      return product?.physicalImage ?? product?.images?.[0];
    }

    if (product.isToggleable && !hasPhysicalCard) {
      return product?.digitalImage ?? product?.images?.[0];
    }

    return product?.images?.[0];
  }, [hasPhysicalCard, product]);

  if (!product) return <ProductPreviewSkeleton />;

  function handleClick() {
    UI.lightbox(
      product?.images.map(m => ({
        mediaType: LightboxMedia.IMAGE,
        url: m,
      })),
      {
        mode: 'bright',
      }
    );
  }

  return (
    <section {...bem()}>
      {product.checkoutType === CheckoutType.TangoCard ||
      product.checkoutType === CheckoutType.Perfectgift ||
      product.checkoutType === CheckoutType.GiftyaPlatform ? (
        <img {...bem('image', 'tc')} alt="Product image" src={giftCardImage} />
      ) : (
        <Carousel
          variant="primary"
          {...bem('slider', [{ 'has-dots': product?.images?.length > 0 }])}
          {...sliderSettings}
          aspectRatio={Square}
          onImageClicked={handleClick}
        >
          {product?.images?.map((imageUrl, i) => (
            <KeepProportions key={i} proportion={Square}>
              <Image
                key={i}
                width={400}
                height={400}
                asBackground
                {...bem('image')}
                alt="Product image"
                src={imageUrl}
              />
            </KeepProportions>
          ))}
        </Carousel>
      )}
    </section>
  );
}

export const ProductPreview = withComponentErrorBoundary(ProductPreviewComp);

export function ProductPreviewSkeleton() {
  const bem = useBemCN('product-preview-skeleton');
  return (
    <div {...bem()}>
      <FrameBone />
    </div>
  );
}
