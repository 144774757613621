import { isBrowser } from '@/utils/environment';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import { updateUserWelcomeScreenLastSeen, useUser } from '@gik/api/users/user';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import { hideIntercomDefaultLauncher, showIntercomDefaultLauncher } from '@gik/core/utils/Intercom';
import { getTailwindConfig } from '@gik/core/utils/tailwind';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { isInkindPageNew } from '@gik/inkind-page/utils/isInkindPageNew';
import userHasSeenInkindTourForThisPage from '@gik/inkind-page/utils/userHasSeenInkindTourForThisPage';
import { JoyrideTooltip } from '@gik/ui/Joyride';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { CallBackProps, Step } from 'react-joyride';
import { openNewPageWelcomeModal } from '../NewPageWelcome/NewPageWelcome';

const twConfig = getTailwindConfig();

const Joyride = dynamic(() => import('react-joyride'));

export interface InkindPageTourProps {
  selectors: IInkindPageTourSelectors;
  forceOpen?: boolean;
}

// NOTE: limited to id's
export interface IInkindPageTourSelectors {
  pageSettings?: string;
  privacyPreferences?: string;
  addUpdate?: string;
  addStory?: string;
  donations?: string;
  addCalendarRequestDesktop?: string;
  addCalendarRequestMobile?: string;
  addItemsWishlist?: string;
  shareButton?: string;
  supportersButton?: string;
  forceShow?: boolean;
}

// NOTE: set selectors (ids) in a single place and import them (except mvc)
// and not hardcode them in multiple places
// use dedicated ids and not classes which might change in the future during styling
export const inkindPageTourSelectors: IInkindPageTourSelectors = {
  pageSettings: 'page-settings-page-button-anchor',
  privacyPreferences: 'privacy-preferences-page-tour-id', // icons container
  addUpdate: 'add-update-page-tour-id',
  addStory: 'add-story-page-tour-id',
  donations: 'donations-page-tour-id', // container around buttons
  addCalendarRequestDesktop: 'add-calendar-request-desktop-page-tour-id', // 767px+
  addCalendarRequestMobile: 'add-calendar-request-mobile-page-tour-id',
  addItemsWishlist: 'add-items-wishlist-page-tour-id',
  shareButton: 'share-button-page-tour-id',
  supportersButton: 'supporters-button-page-tour-id',
};

// Ensure tour can only be launched once per page load
let hasLaunchedTour = false;

function InkindPageTourComp({ selectors, forceOpen }: InkindPageTourProps): React.ReactElement {
  const { t } = useTranslation();
  const isMd = useBreakpoint(Breakpoint.MD);

  const userStore = useUserStore();
  const userId = userStore.id;
  const { data: user, mutate: mutateUser } = useUser(userId);
  const [showTour, setShowTour] = React.useState<boolean>(false);

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkindPage } = useInkind(inkindRouteId);

  const getSteps = (inkindPage: InkindPageAPIModel): Step[] => {
    if (!inkindPage) {
      return [];
    }

    const customSpotlightProps = {
      // styles for steps with white background
      // we need initially darker orange to get secondary[600] after mix-blend-mode: hard-light is applied
      styles: { spotlight: { borderRadius: '8px', border: '2px solid rgb(163 81 0)' } },
      spotlightPadding: 8,
    };

    const steps: Step[] = [
      {
        // always
        target: selectors.pageSettings,
        content: t(translationKeys.pageSettingsContent).toString(),
        // Page Settings uses pill button
        styles: { spotlight: { borderRadius: '9999px' } },
      },
      {
        // always
        target: selectors.privacyPreferences,
        content: t(translationKeys.privacyPreferencesContent).toString(),
        ...customSpotlightProps,
      },
      {
        // always
        target: selectors.addUpdate,
        content: t(translationKeys.updatesContent).toString(),
      },
      {
        // always, "Add Page Description" button
        target: selectors.addStory,
        content: t(translationKeys.storySpecialNotesContent).toString(),
      },
      {
        // buttons container
        target: inkindPage.payPal && selectors.donations,
        content: t(translationKeys.fundraisingContent).toString(),
        ...customSpotlightProps,
      },
      {
        // Calendar Add Request button, desktop
        target: inkindPage.isCalendar && isMd && selectors.addCalendarRequestDesktop,
        content: t(translationKeys.calendarContent).toString(),
      },
      {
        // Calendar Plus icon button, mobile
        target: inkindPage.isCalendar && !isMd && selectors.addCalendarRequestMobile,
        content: t(translationKeys.calendarContent).toString(),
      },
      {
        // Add Items button
        target: inkindPage.wishList && selectors.addItemsWishlist,
        content: t(translationKeys.wishlistContent).toString(),
      },
      {
        target: selectors.supportersButton,
        content: t(translationKeys.supportersContent).toString(),
      },
      {
        target: selectors.shareButton,
        content: t(translationKeys.sharingContent).toString(),
      },
    ];

    return steps
      .filter(step => step.target)
      .map(step => {
        // NOTE: offsets in <Joyride floaterProps={{... don't do anything, need to set it here
        // when offset is negative placement needs to be 'top' for tooltip to appear bellow the button
        step.offset = -2;
        step.placement = 'top';
        step.disableBeacon = true;
        step.target = `#${step.target}`; // NOTE: only id's can be used this way
        return step;
      });
  };

  const canEdit = useInkindCan('manage', inkindRouteId, inkindPage?.groupId);
  const hasUserSeenInkindTourForAnyPage = user && !!user?.welcomeScreenLastSeen;
  const hasUserSeenInkindTourForThisPage = user && userHasSeenInkindTourForThisPage(user, inkindPage);

  const isPageNew = isInkindPageNew(inkindPage);
  const showCancelButton = hasUserSeenInkindTourForAnyPage || true; // FIXME: remove || true after overlay is added

  useEffect(() => {
    if (!user || !inkindPage || !canEdit || hasLaunchedTour) {
      return;
    }
    // skip execution if we got an anonymous user object (which means the user is switching accounts)
    if (!user.emailAddress) return;

    const urlParams = new URLSearchParams(window.location.search);
    const shouldShowDialog =
      forceOpen ||
      urlParams.has('tour') ||
      (!hasUserSeenInkindTourForThisPage && (isPageNew || !hasUserSeenInkindTourForAnyPage));

    if (!shouldShowDialog) {
      return;
    }

    setTimeout(async () => {
      const handleNext = () => {
        setShowTour(true);
      };

      const handleCancel = async () => {
        showIntercomDefaultLauncher();

        await updateUserWelcomeScreenLastSeen(userId);
        await mutateUser();
      };

      hideIntercomDefaultLauncher();
      openNewPageWelcomeModal(handleCancel, handleNext, showCancelButton);
    }, 1000);
    // hasLaunchedTour should be set to true immediatly since we are not cancelling the timeout
    hasLaunchedTour = true;
  }, [
    inkindPage,
    canEdit,
    forceOpen,
    hasUserSeenInkindTourForThisPage,
    hasUserSeenInkindTourForAnyPage,
    isPageNew,
    showCancelButton,
    userId,
    user,
    mutateUser,
  ]);

  const handleJoyride = async (data: CallBackProps) => {
    const { status, lifecycle, step /*, type*/ } = data;

    const { LIFECYCLE, STATUS } = await import('react-joyride');

    // FIXME: this seems to fire twice
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      (async () => {
        await updateUserWelcomeScreenLastSeen(userId);
        await mutateUser();

        Analytics.fireEvent(
          AnalyticsEvents.InkindPageTourFinished,
          { status, inkindRouteId },
          () => ['status'],
          keys => keys.filter(key => key !== 'status')
        );

        showIntercomDefaultLauncher();
      })();
    }

    // fix for imperfect overlay positioning on first step
    // doesn't work in storybook, only in legacy app
    // select first step and it's tooltip lifecycle
    if (
      isBrowser() &&
      status === STATUS.RUNNING &&
      lifecycle === LIFECYCLE.TOOLTIP &&
      step.target === `#${selectors.pageSettings}`
    ) {
      // doesn't work without 500ms+ delay
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    }
  };

  // NOTE: 70px to compensate navbar height plus additional offset
  // maybe set different value for mobile and desktop
  const scrollOffset = 170;
  const steps = getSteps(inkindPage);

  return (
    <>
      {showTour && (
        <Joyride
          floaterProps={{
            hideArrow: false,
            offset: 200,
            options: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 200],
                  },
                },
              ],
            },
          }}
          run={true}
          showSkipButton={false}
          hideBackButton={false}
          tooltipComponent={props => <JoyrideTooltip {...props} showSkipButton={showCancelButton} />}
          spotlightPadding={0}
          steps={steps}
          callback={handleJoyride}
          scrollOffset={scrollOffset}
          disableOverlay={false}
          disableOverlayClose={true}
          styles={{ options: { zIndex: twConfig.theme.zIndex.max } }}
        />
      )}
    </>
  );
}

export const InkindPageTour = InkindPageTourComp;
