import bemBlock from '@gik/core/utils/bemBlock';
import type { IBaseBooleanInputProps } from '@gik/ui/BaseBooleanInput/BaseBooleanInput';
import { BaseBooleanInput } from '@gik/ui/BaseBooleanInput/BaseBooleanInput';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import MinusIcon from '@heroicons/react/outline/MinusIcon';
import React from 'react';
import type { UICheckboxVariant } from '../types';

export interface CheckboxProps extends IBaseBooleanInputProps {
  /**
   * Style variant to use
   */
  variant?: UICheckboxVariant;

  /**
   * Put checkbox in indeterminate state
   * see https://css-tricks.com/indeterminate-checkboxes
   */
  indeterminate?: boolean;

  /**
   * A custom icon to display instead of the default checkmark.
   */
  icon?: React.ReactNode;

  /**
   * A custom icon to display instead of the default checkmark.
   */
  indeterminateIcon?: React.ReactNode;
}

/**
 * GIK Checkbox component
 */
function CheckboxComp(
  { indeterminate, variant = 'primary', size = 'base', icon, indeterminateIcon, ...otherProps }: CheckboxProps,
  ref: React.LegacyRef<HTMLInputElement>
): React.ReactElement {
  const bem = bemBlock('checkbox');

  const props = {
    size,
    variant,
    ...otherProps,
  };

  return (
    <BaseBooleanInput
      {...props}
      bem={bem}
      render={inputProps => (
        <div
          className={bem(`wrapper`)}
          onClick={() => {
            if (inputProps.disabled) inputProps.onClickDisabled?.();
          }}
        >
          <input
            ref={ref as React.LegacyRef<HTMLInputElement>}
            // FIXME: why is `indeterminate` in `input` unknown now?
            /* eslint-disable-next-line react/no-unknown-property */
            indeterminate={indeterminate?.toString()}
            {...inputProps}
          />
          <div className={bem(`icon`)}>
            {!indeterminate && (icon || <SvgIcon Icon={CheckIcon} />)}
            {indeterminate && (indeterminateIcon || <SvgIcon Icon={MinusIcon} />)}
          </div>
          <div className={bem(`border`)} />
          <div className={bem(`background`)} />
        </div>
      )}
    />
  );
}

export const Checkbox = React.forwardRef(CheckboxComp);

export const CheckboxNoRef = CheckboxComp;
