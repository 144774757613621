import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useServices } from '@gik/api/calendar/service';
import { Calendar } from '@gik/calendar';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { useProducts } from '@gik/checkout/api';
import type { CartItem } from '@gik/core/models/gik/Order';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import he from 'he';
import moment from 'moment';
import React from 'react';
import useEffectOnce from 'react-use/lib/useEffectOnce';

export type AddFromCalendarSectionProps = UIComponent & {
  inkindRouteId: string;
  pageName: string;
  selectedId?: number;
  privateClaim?: boolean;
  buttonsPortal?: HTMLElement;
  currentCart?: CartItem[];
  buttons?: (isFormValid: boolean) => React.ReactNode;
  onSelect?: (id: number) => void;
  onClaimEvent?(event: ICalendarEvent, entry: ICalendarEntry, routeId: string, serviceSlug: string): void;
};

export function AddFromCalendarSection({
  children,
  className,
  inkindRouteId,
  selectedId,
  currentCart,
  pageName,
  privateClaim,
  onSelect,
  buttons,
  onClaimEvent,
  buttonsPortal,
  ...otherProps
}: React.PropsWithChildren<AddFromCalendarSectionProps>): React.ReactElement {
  const bem = useBemCN('add-from-calendar-section');

  const productIds = currentCart?.map(cartItem => cartItem.productId);

  const { data: products, error: productError } = useProducts({ productIds });

  const setSelectedMonth = useCalendarStore(state => state.setSelectedMonth);

  useEffectOnce(() => {
    setSelectedMonth(moment().format(DateTimeUtils.MonthFormat));
  });

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const { data: services } = useServices();

  function handleClaimEvent(event: ICalendarEvent, entry: ICalendarEntry, routeId: string) {
    const eventType = calendarEventTypes?.find(type => type.id === entry.typeId);

    const matchingServices = services
      ?.filter(item => {
        return item.acf.preview_mode !== true;
      })
      .sort(
        (a: IWordpressService, b: IWordpressService) =>
          parseInt(a.acf.sort_order || '0') - parseInt(b.acf.sort_order || '0')
      )
      .filter(item => {
        return item['cal-event-type']?.indexOf(eventType.id) > -1;
      })
      .filter(item => {
        return item.slug.indexOf('gift-card') > -1;
      });

    const serviceSlug = matchingServices?.[0]?.slug;

    onClaimEvent?.(event, entry, routeId, serviceSlug);
  }

  if (!services || !calendarEventTypes) return <LoadingSpinner />;

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <span className="gik-modal-add-from-title">{he.decode(pageName)}&apos;s Care Calendar</span>
      <p>Tap “I’ll Help” to add another gift to your order.</p>
      <Calendar
        noInitialMonth
        inkindRouteId={inkindRouteId}
        disableCreate
        onClaimEvent={handleClaimEvent}
        claimSelection
        hidePastDays
        giftCardsOnly
        privateClaim={privateClaim}
        currentCart={currentCart}
        products={products}
      />
      {renderPortal?.(buttons && buttons?.(!isNaN(selectedId)), () => buttonsPortal)}
    </div>
  );
}
