import { isBrowser as _isBrowser } from '@/utils/environment';
import type { LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { FullStory } from '@fullstory/browser';
import { logFiltered } from '@gik/core/constants';
import { useAppLocalStore } from '@gik/core/store/AppLocalStore';
import { useAppSessionStore } from '@gik/core/store/AppSessionStore';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { getAppVersion, useAppVersion } from '@gik/core/utils/appVersion';
import { useEffect } from 'react';

interface Properties {
  isBrowser: boolean;
  browserId?: string;
  browserSessionId?: string;
  fullStorySession?: string;
  userId?: string;
  cookieEnabled?: boolean;
  appVersion?: string;
}

function enrichLogEvent(optionalParams?: { Properties?: object }): { Properties: Properties } {
  const isBrowser = _isBrowser();
  const Properties: Properties = {
    ...(optionalParams?.Properties ?? {}),
    isBrowser,
  };

  if (!isBrowser) return { Properties };

  Properties.cookieEnabled = navigator?.cookieEnabled;
  Properties.appVersion = getAppVersion();

  const userId = useUserStore.getState().id;
  if (userId) Properties.userId = userId;

  const { browserSessionId } = useAppSessionStore.getState();
  const { browserId } = useAppLocalStore.getState();
  if (browserSessionId) Properties.browserSessionId = browserSessionId;
  if (browserId) Properties.browserId = browserId;

  try {
    const fullStorySession = FullStory('getSession', { format: 'url' });
    if (fullStorySession) Properties.fullStorySession = fullStorySession;
  } catch (e) {
    // ignore
  }

  return { Properties };
}

export function useDatadog() {
  const version = useAppVersion();
  const datadogLogsClientToken = useEnvStore(state => state.DD_LOGS_CLIENT_TOKEN);
  const datadogRumClientToken = useEnvStore(state => state.DD_RUM_CLIENT_TOKEN);
  const datadogRumApplicationId = useEnvStore(state => state.DD_RUM_APPLICATION_ID);
  const datadogServiceName = useEnvStore(state => state.DD_SERVICE_NAME);

  useEffect(() => {
    if (datadogLogsClientToken && datadogServiceName) {
      datadogLogs.init({
        clientToken: datadogLogsClientToken,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['warn', 'error'], // this property is unknown in the typings but is available here https://docs.datadoghq.com/logs/log_collection/javascript/
        sampleRate: 100,
        site: 'datadoghq.com',
        service: datadogServiceName,
        version,
        beforeSend: event => {
          if (logFiltered.some(filter => event.message?.toLowerCase?.().includes(filter?.toLowerCase?.())))
            return false;

          event.Properties = {
            ...enrichLogEvent().Properties,
            ...(typeof event?.Properties === 'object' ? event.Properties : {}),
          };
        },
      } as LogsInitConfiguration);
    }

    if (datadogRumClientToken && datadogRumApplicationId && datadogServiceName) {
      datadogRum.init({
        clientToken: datadogRumClientToken,
        applicationId: datadogRumApplicationId,
        sampleRate: parseInt(useEnvStore.getState().DD_RUM_SAMPLE_RATE || '1'),
        trackInteractions: true,
        site: 'datadoghq.com',
        service: datadogServiceName,
        version,
      });
    }
  }, [version, datadogLogsClientToken, datadogRumApplicationId, datadogRumClientToken, datadogServiceName]);
}
