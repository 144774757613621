import { isBrowser } from '@/utils/environment';
import bemBlock from '@gik/core/utils/bemBlock';
import { LottieOptions } from '@gik/ui/Animation';
import dynamic from 'next/dynamic';
import React from 'react';
import type { Options } from 'react-lottie';

const Lottie = dynamic(() => import('react-lottie'));

function buildThresholdList(thresholdStep: number) {
  const thresholds = [];

  for (let i = 1.0; i <= thresholdStep; i++) {
    const ratio = i / thresholdStep;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}

const blockName = 'animation';

export const loopedMode = 'looped';
export const singlePausedMode = 'singlePaused';
export const onScrollMode = 'onScroll';

type Looped = typeof loopedMode;
//type SinglePaused = typeof singlePausedMode;
type OnScroll = typeof onScrollMode;

export type AnimationMode = Looped | /*| SinglePaused*/ OnScroll;

interface BaseAnimationProps {
  // TODO: fix typings, remove eslint-disable-next-line
  // eslint-disable-next-line
  data: any;
  mode: AnimationMode;
  assetsPrepend?: string;
  className?: string;
}

interface LoopedAnimationProps extends BaseAnimationProps {
  mode: Looped;
}

/*interface SinglePausedAnimationProps extends BaseAnimationProps {
  mode: SinglePaused;
}*/

interface OnScrollAnimationProps extends BaseAnimationProps {
  mode: OnScroll;
  thresholdStep?: number;
  rootMargin?: number;
  intersectionRatioThreshold?: number;
}

export type AnimationProps = LoopedAnimationProps | /*| SinglePausedAnimationProps*/ OnScrollAnimationProps;

function AnimationComp(props: AnimationProps): React.ReactElement {
  const { data, mode = loopedMode, assetsPrepend, className } = props as BaseAnimationProps;
  const { thresholdStep = 20, rootMargin = 0, intersectionRatioThreshold = 0.25 } = props as OnScrollAnimationProps;

  const bem = bemBlock(blockName);

  const [isPlaying, setPlaying] = React.useState<boolean>(mode !== 'onScroll');

  const intersectionRef = React.useRef<HTMLDivElement>(null);
  const observer = React.useRef(
    isBrowser()
      ? new IntersectionObserver(
          entries => {
            if (mode === 'onScroll') {
              entries.forEach(entry => {
                if (entry?.isIntersecting && entry?.intersectionRatio >= intersectionRatioThreshold && !isPlaying) {
                  setPlaying(true);
                }
              });
            }
          },
          {
            root: null,
            rootMargin: `${rootMargin}px`,
            threshold: buildThresholdList(thresholdStep),
          }
        )
      : null
  );

  React.useEffect(() => {
    if (mode === onScrollMode && intersectionRef?.current && !observer?.current?.root) {
      observer.current.observe(intersectionRef.current);
    }
    // eslint-disable-next-line
  }, [intersectionRef?.current, observer?.current]);

  React.useEffect(() => {
    return () => {
      if (mode === onScrollMode && observer?.current) {
        // eslint-disable-next-line
        observer.current.disconnect();
      }
    };
  }, [mode, observer]);

  // TODO: fix typings, remove eslint-disable-next-line
  // eslint-disable-next-line
  const getData = (): any => {
    if (assetsPrepend) {
      return LottieOptions.withAssetsPrepend(data, assetsPrepend);
    } else {
      return data;
    }
  };

  // TODO: fix typings, remove eslint-disable-next-line
  // eslint-disable-next-line
  const getOptions = (data: any): Options => {
    switch (mode) {
      case loopedMode:
        return LottieOptions.looped(data);
      //case singlePausedMode:
      case onScrollMode:
        return LottieOptions.singlePaused(data);
    }
  };

  return (
    <div ref={intersectionRef} className={bem(null, null, className)}>
      <Lottie options={getOptions(getData())} isStopped={!isPlaying} />
    </div>
  );
}

export const Animation = React.memo(AnimationComp);
