import type { Options } from 'react-lottie';

type PartialLottieAsset = Partial<{
  p: string;
  u: string;
}>;

export type PartialLottieAnimation = Partial<{
  assets: PartialLottieAsset[];
}>;

export function looped(animationData): Options {
  return {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
}

export function singlePaused(animationData): Options {
  return {
    loop: false,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      // needed for shadows
      scaleMode: {
        width: '200%',
        height: '200%',
        x: '-50%',
        y: '-50%',
      },
    },
  };
}

export function onScroll(animationData): Options {
  return {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      // needed for shadows
      scaleMode: {
        width: '200%',
        height: '200%',
        x: '-50%',
        y: '-50%',
      },
    },
  };
}

export function withAssetsPrepend(animationData: PartialLottieAnimation, assetsPrepend: string) {
  return {
    ...animationData,
    assets: animationData.assets.map(asset =>
      asset.p
        ? {
            ...asset,
            u: assetsPrepend,
          }
        : asset
    ),
  };
}
