import { useEnvStore } from '@gik/core/store/EnvStore';

/**
 * Headers that will be added to every api request
 */
export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Origin: useEnvStore.getState().BASE_URL,
};

export const defaultOptions = {
  timeout: 20000,
  retry: 3,
};

export const defaultMapParameters = [
  {
    from: 'perPage',
    to: 'per_page',
  },
];
