import { useGeneralPageNameSuggestions } from '@gik/api/acf/gik-settings/generalPageNameSuggestions';
import { useTaxonomies } from '@gik/api/give/taxonomies';
import withSuspense from '@gik/core/components/suspended/withSuspense';
import bemBlock from '@gik/core/utils/bemBlock';
import type { ValidationError } from '@gik/ui/Form';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import React from 'react';

export interface IPageNameIdeasProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  onSelect?: (name: string) => void;
  situationIds: number[];
}

const blockName = 'page-name-ideas';

export interface ICreateAboutFormValues {
  recipientType: string;
  email: string;
}

export interface ICreateAboutFormErrors {
  recipientType?: ValidationError;
  email?: ValidationError;
}

export const formId = 'CreateNameYourPageStepForm';

function PageNameIdeasSkeleton(props: Partial<IPageNameIdeasProps>) {
  const bem = bemBlock('page-name-ideas-skeleton');
  return (
    <div className={bem(null, ['loading'], props.className)}>
      <LoadingSpinner center />
    </div>
  );
}

function PageNameIdeas({ className, situationIds, onSelect, ...otherProps }: IPageNameIdeasProps) {
  const bem = bemBlock(blockName);

  const { data: situations, error: situationsError } = useTaxonomies('situation', { include: situationIds });
  const { data: generalSuggestions, error: generalSuggestionsError } = useGeneralPageNameSuggestions();

  const pageNameSuggestions = React.useMemo(
    () =>
      situationIds
        ?.map(id => situations?.find(item => item?.id === id))
        ?.map(situation => situation?.acf.page_name_suggestions)
        ?.flat(),
    [situationIds, situations]
  );

  const suggestions = React.useMemo(() => {
    return [
      ...(pageNameSuggestions ?? []).map(item => item?.suggestion),
      ...(generalSuggestions?.page_name_suggestions ?? []).map(item => item?.suggestion),
    ];
  }, [generalSuggestions, pageNameSuggestions]);

  // eslint-disable-next-line no-constant-condition
  if (!situations || !generalSuggestions) {
    return <PageNameIdeasSkeleton className={className} />;
  }

  const isLoading = !situations || !generalSuggestions;
  const isError = isLoading && (situationsError || generalSuggestionsError);

  if (isLoading) return <PageNameIdeasSkeleton />;
  if (isError) return <PageNameIdeasSkeleton />;

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      <ul className={bem('list')}>
        {suggestions.map((suggestion, i) => (
          <li key={`${i}-${suggestion}`} onClick={() => onSelect(suggestion)}>
            {suggestion}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withSuspense(PageNameIdeas, PageNameIdeasSkeleton);
