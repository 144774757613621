import { useInkind } from '@gik/api/inkinds/inkind';
import { CalendarClaimFooter } from '@gik/calendar';
import type { IAddToCalendarLinks } from '@gik/calendar/components/AddToCalendarSelector';
import { AddToCalendarButton } from '@gik/calendar/components/AddToCalendarSelector/AddToCalendarButton';
import ContactPageOrganizersForAddress from '@gik/calendar/components/ContactPageOrganizersForAddress';
import type { PartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import { usePartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import type { IPartnerClaimURL } from '@gik/calendar/models/PartnerClaimDetails';
import type { IRecipientContactInformation } from '@gik/calendar/models/RecipientContactInformation';
import { getRecipientContactInformation } from '@gik/calendar/utils/getRecipientContactInformation';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { CopyAddressButton } from '@gik/ui/CopyButton';
import { DismissableText } from '@gik/ui/DismissableText/DismissableText';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CheckIcon from '@heroicons/react/outline/CheckIcon';
import he from 'he';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

type PartnerClaimSuccessProps = UIComponent &
  IPartnerClaimURL & {
    entry: ICalendarEntry;
    event: ICalendarEvent;
    addToCalendarLinks: IAddToCalendarLinks;
    buttonsPortal?: () => HTMLElement;
    isFollowing: boolean;
    timeline: PartnerTimeline;
    partnerButton: React.ReactElement;
    partnerName: string;
    orderDaysBefore: number;
  };

const bem = bemBlock('partner-claim-success');

export function PartnerClaimSuccess({
  className,
  entry,
  event,
  partnerUrl,
  buttonsPortal,
  addToCalendarLinks,
  isFollowing,
  timeline,
  partnerButton,
  partnerName,
  orderDaysBefore,
  ...otherProps
}: PartnerClaimSuccessProps): React.ReactElement {
  // store previous following value as this will update as soon as the user claims the item
  const [prevIsFollowing] = useState(isFollowing);
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);
  const { t } = useTranslation();
  const recipientContactInformation = inkind ? getRecipientContactInformation(inkind) : null;

  const { canOrderNow } = usePartnerTimeline(timeline, moment.utc(event.startsAt));
  const eventDate = moment.utc(event.startsAt).format('MMMM Do');
  const eventTime = !entry.allDay
    ? (
        moment.utc(event.startsAt).format(DateTimeUtils.Time12HourFormat) +
        '-' +
        moment.utc(event.endsAt).format(DateTimeUtils.Time12HourFormat)
      ).toLowerCase()
    : '';

  const _buttons = (
    <CalendarClaimFooter>
      <div className="tw-flex tw-flex-row tw-gap-2 tw-align-middle">
        <DismissableText className="tw-text-xs tw-text-right tw-text-gray-50 tw-mt-2">
          You can schedule the meal up to 5 days in
          <br />
          advance on the final step of your order.
        </DismissableText>
        {partnerButton}
      </div>
    </CalendarClaimFooter>
  );

  if (!recipientContactInformation) return null;

  return (
    <div className={bem(null, ['partner'], className)} {...otherProps}>
      <div className={bem('check')}>
        <SvgIcon Icon={CheckIcon} size="2xl" />
      </div>
      <span className={bem('title')}>Meal claimed!</span>

      <AddToCalendarButton links={addToCalendarLinks} />

      {!prevIsFollowing && <p className={bem('description-muted')}>{t(translationKeys.youAreNowFollowing)}</p>}

      {canOrderNow ? (
        <RenderOrderNowContent
          recipientContactInformation={recipientContactInformation}
          eventDate={eventDate}
          eventTime={eventTime}
          inkindRouteId={inkindRouteId}
          partnerName={partnerName}
        />
      ) : (
        <RenderOrderLaterContent
          event={event}
          recipientContactInformation={recipientContactInformation}
          eventDate={eventDate}
          eventTime={eventTime}
          orderDaysBefore={orderDaysBefore}
        />
      )}

      {renderPortal(_buttons, buttonsPortal)}
    </div>
  );
}

interface IRenderOrderNowContentProps {
  recipientContactInformation: IRecipientContactInformation;
  eventDate: string;
  eventTime: string;
  inkindRouteId: string;
  partnerName: string;
}

const RenderOrderNowContent = ({
  recipientContactInformation,
  eventDate,
  eventTime,
  inkindRouteId,
  partnerName,
}: IRenderOrderNowContentProps) => {
  const { t } = useTranslation();
  const { address1, address2, cityAndState, postcode, fullName } = recipientContactInformation;
  const recipientAddressLine1 = [address1, address2].filter(v => v).join(', ');
  const recipientAddressLine2 = [cityAndState, postcode].join(' ');

  return (
    <div className={bem('order-now')}>
      <strong>Be sure to order your meal for:</strong>
      <div className={bem('date', ['highlight'])}>
        {eventDate}, {eventTime}
      </div>

      <p className={bem('description-muted')}>{t(translationKeys.emailedConfirmation)}</p>

      {address1 ? (
        <>
          <p>Copy and paste the recipient’s address into {partnerName} search to find their local favorites:</p>
          <address>
            <strong>{he.decode(fullName)}</strong>
            <br />
            {he.decode(recipientAddressLine1)}
            <br />
            {he.decode(recipientAddressLine2)}
          </address>
          <CopyAddressButton
            variant="default-light"
            textToCopy={[recipientAddressLine1, recipientAddressLine2].join('\r\n')}
          />
          <br />
        </>
      ) : (
        <ContactPageOrganizersForAddress inkindRouteId={inkindRouteId} />
      )}
    </div>
  );
};

interface IRenderOrderLaterContentProps {
  event: ICalendarEvent;
  recipientContactInformation: IRecipientContactInformation;
  eventDate: string;
  eventTime: string;
  orderDaysBefore: number;
}

const RenderOrderLaterContent = ({
  event,
  recipientContactInformation,
  eventDate,
  eventTime,
  orderDaysBefore,
}: IRenderOrderLaterContentProps) => {
  const eventCanOrderFromDate = moment.utc(event.startsAt).startOf('day').subtract(orderDaysBefore, 'days');
  return (
    <div className={bem('order-later')}>
      <strong className={bem('date')}>
        For {eventDate}, {eventTime}
      </strong>

      <p>
        You’ll be able to schedule your meal
        <br /> delivery on{' '}
        <span className={bem('date-inline', ['highlight'])}>
          {moment.utc(eventCanOrderFromDate).format('MMMM Do')},{' '}
          {moment.utc(eventCanOrderFromDate).format('YYYY').toUpperCase()}
        </span>
        .
      </p>

      {recipientContactInformation.address1 ? (
        <>
          <p className={bem('note-muted')}>we’ll also email you a reminder and the delivery address</p>
          <p>
            See what’s available in {recipientContactInformation.cityAndState}, so you can be ready on ordering day...
          </p>
        </>
      ) : (
        <>
          <p className={bem('note-muted')}>we’ll also email you a reminder</p>
        </>
      )}
    </div>
  );
};
