import bemBlock from '@gik/core/utils/bemBlock';
import type { IBaseBooleanInputProps } from '@gik/ui/BaseBooleanInput/BaseBooleanInput';
import { BaseBooleanInput } from '@gik/ui/BaseBooleanInput/BaseBooleanInput';
import type { UISwitchVariant } from '@gik/ui/types';
import React from 'react';

export interface ISwitchProps extends IBaseBooleanInputProps {
  /**
   * Style variant to use
   */
  variant?: UISwitchVariant;
}

const blockName = `switch`;

function SwitchComp(
  { variant = 'default', size = 'base', ...otherProps }: ISwitchProps,
  ref: React.LegacyRef<HTMLInputElement>
) {
  const bem = bemBlock(blockName);

  const props = {
    variant,
    size,
    indeterminate: false,
    ...otherProps,
  };

  return (
    <BaseBooleanInput
      {...props}
      bem={bem}
      render={({ indeterminate: _, ...inputProps }) => (
        <div className={bem(`wrapper`)}>
          <input ref={ref as React.LegacyRef<HTMLInputElement>} {...inputProps} />
          <div className={bem(`handle`)} />
        </div>
      )}
    />
  );
}

export const Switch = React.forwardRef(SwitchComp);
