import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import { pageFeatureCardBlockName } from '@gik/create/components';
import { Accordion } from '@gik/ui/Accordion';
import { AccordionItem } from '@gik/ui/Accordion/AccordionItem';
import { Checkbox } from '@gik/ui/Checkbox';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';

export interface IPageFeatureCardProps {
  icon: SvgrComponent | React.FC;
  title: string;
  description: string;
  notes?: string;
  inputName?: string;
  asideOpen?: boolean;
  onChange?: (isToggled: boolean) => void;
  value: boolean;
  disabled?: boolean;
  footer?: React.ReactNode;
  recalculateHeightCallback?: (fn: () => void) => void;
  noSecondaryContentBackground?: boolean;
}

export default function PageFeatureCard({
  icon,
  title,
  description,
  notes,
  children,
  className,
  inputName,
  asideOpen = true,
  onChange = noop,
  value,
  disabled,
  footer,
  recalculateHeightCallback,
  noSecondaryContentBackground,
}: PropsWithClassName<React.PropsWithChildren<IPageFeatureCardProps>>) {
  const bem = bemBlock(pageFeatureCardBlockName);

  function handleToggle(toggled: boolean) {
    onChange(toggled);
  }

  return (
    <section className={bem(null, [{ disabled: !value }], className)}>
      <div className={bem('main')}>
        <SvgIcon className={bem('icon')} Icon={icon} />
        <section className={bem('copy-container')}>
          <label htmlFor={inputName} className={bem('title')}>
            {title}
          </label>
          <p className={bem('description')}>{description}</p>
        </section>

        <Checkbox
          className={bem('checkbox')}
          size="xl"
          id={inputName}
          name={inputName}
          checked={value}
          onValueChange={toggled => handleToggle(toggled)}
          disabled={disabled}
        />

        {notes && <p className={bem('notes')}>{notes}</p>}
      </div>

      <div className={bem('secondary-content', [{ 'no-secondary-content-background': noSecondaryContentBackground }])}>
        {footer}
        {children && (
          <Accordion allToggled={value && asideOpen} recalculateHeightCallback={recalculateHeightCallback}>
            <AccordionItem>{children}</AccordionItem>
          </Accordion>
        )}
      </div>
    </section>
  );
}
