import type { UseMutationOptions } from '@tanstack/react-query';
import { usePlatformManagementClient } from '../../contexts';
import type {
  GiftEntity,
  PlatformManagementApiResponse,
  StrapiSingleDataSource,
  WalletMergeGiftsForCardLinkingPayload,
} from '@gik/platform-management-api-js';
import type { GiftRedeemPayload, WalletDeactivatePayload, WalletEntity } from '@gik/platform-management-api-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { buildOptions } from '../../utils/buildOptions';

const uniqueName = 'wallet';

export const useWalletActivate = (options?: UseMutationOptions<boolean, Error, string>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, string>(
    (customerId: string) => client.wallet.activateByCustomerId(customerId),
    buildOptions(queryClient, [key], options)
  );
};

export const useWalletDeactivate = (options?: UseMutationOptions<boolean, Error, WalletDeactivatePayload>) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_deavtivate`;

  return useMutation<boolean, Error, WalletDeactivatePayload>(
    (payload: WalletDeactivatePayload) => client.wallet.deactivateByCustomerId(payload.customerId, payload.markAsUsed),
    buildOptions(queryClient, [key], options)
  );
};

export const useWalletRedeem = (
  id: string,
  params?: object,
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>,
    Error,
    GiftRedeemPayload
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_redeem`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>, Error, GiftRedeemPayload>(
    (payload: GiftRedeemPayload) => client.wallet.redeem(id, payload, { params }),
    buildOptions(queryClient, [key], options)
  );
};

export const useWalletUpdate = (
  options?: UseMutationOptions<
    PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>,
    Error,
    Partial<WalletEntity>
  >
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_update`;

  return useMutation<PlatformManagementApiResponse<StrapiSingleDataSource<WalletEntity>>, Error, Partial<WalletEntity>>(
    (payload: Partial<WalletEntity>) => {
      const { customerId, ...rest } = payload;
      return client.wallet.updateByCustomerId(customerId.toString(), rest);
    },
    buildOptions(queryClient, [key], options)
  );
};

export const useMergeGiftsForCardLinking = (
  giftUuid: string,
  options?: UseMutationOptions<PlatformManagementApiResponse<GiftEntity>, Error, WalletMergeGiftsForCardLinkingPayload>
) => {
  const { client } = usePlatformManagementClient();
  const queryClient = useQueryClient();
  const key = `${uniqueName}_merge-gifts-for-card-linking`;

  return useMutation<PlatformManagementApiResponse<GiftEntity>, Error, WalletMergeGiftsForCardLinkingPayload>(
    (payload: WalletMergeGiftsForCardLinkingPayload) => client.wallet.mergeGiftsForCardLinking(giftUuid, payload),
    buildOptions(queryClient, [key], options)
  );
};
