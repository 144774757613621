import { logger } from '@gik/analytics/utils/logger';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { confirmCloseCheckoutModal } from '@gik/calendar/utils/CalendarModals';
import type { CheckoutFormStepId } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { tryToNavigateToPaymentStep } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { openPrivacyPolicySheet } from '@gik/core/pages/Legal/PrivacyPolicy';
import { openTermsOfServiceSheet } from '@gik/core/pages/Legal/TermsOfService';
import type { InkindPageViewHistoryItem } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { withStripe } from '@gik/ui/Stripe';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { CheckoutFormWrapperProps } from '../components/CheckoutForm/CheckoutFormWrapper';
import { CheckoutFormWrapper } from '../components/CheckoutForm/CheckoutFormWrapper';
import { useCheckoutStore } from '../store/CheckoutStore';
import { useInkind } from '@gik/api/inkinds/inkind';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { ProductCheckoutType } from '@gik/models/gik/Product';
import { useProducts } from '@gik/checkout/api';

const CheckoutFormWrapperComp = withStripe(CheckoutFormWrapper);

export interface openCheckoutFormModalProps {
  onCloseSuccessfulPurchase?(): void;
  onCompletePurchase?(): Promise<void>;
  onClose?(): boolean;
  cart?: CartItem[];
  inkindRouteId: string;
  cachedProducts?: Product[]; // used to avoid requesting products again on the wishlist
  inkindPageViewHistory?: InkindPageViewHistoryItem[];
  formProps: CheckoutFormWrapperProps;
  recipientName?: string;
  customMessage?: string;
  contentId?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  hasPhysicalCard?: boolean;
  hasCardCarrier?: boolean;
  buyForSomeoneElse?: boolean;
  isOpenLoop?: boolean;
}

export function openCheckoutFormModal({
  cart,
  inkindRouteId,
  recipientName,
  customMessage,
  expiryMonth,
  cardImage,
  faceplate,
  hasCardCarrier,
  hasPhysicalCard,
  isOpenLoop,
  contentId,
  buyForSomeoneElse,
  onClose,
  onCloseSuccessfulPurchase,
  onCompletePurchase,
  formProps,
}: openCheckoutFormModalProps) {
  const submitSucceeded = React.createRef<boolean>() as React.MutableRefObject<boolean>;
  const closeButtonOverride = React.createRef<() => Promise<boolean>>() as React.MutableRefObject<
    () => Promise<boolean>
  >;

  const currentStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;
  const completedStep = React.createRef<CheckoutFormStepId>() as React.MutableRefObject<CheckoutFormStepId>;

  function confirmCloseModal() {
    return confirmCloseCheckoutModal(handleOpenTerms, handleOpenPrivacyPolicy);
  }

  async function handleOpenTerms() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openTermsOfServiceSheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  async function handleOpenPrivacyPolicy() {
    // close top most dialog (the cancel purchase modal)
    UI.closeDialog();

    // open the terms and conditions modal sheet
    openPrivacyPolicySheet({
      onClosed: async () => {
        const response = await confirmCloseModal();

        if (response === false) {
          return UI.closeAllDialogs();
        }

        if (response === true) {
          await tryToNavigateToPaymentStep(currentStep);
          await onCompletePurchase?.();

          return false;
        }
      },
    });
  }

  // the donation product is donation to GIK, not to a page.
  // Giftboxes can be purchased for anyone, not just pages.
  if (
    !inkindRouteId &&
    !isOpenLoop &&
    !cart?.some(entry => entry.checkoutType === 'donation' || entry.checkoutType === 'giftbox')
  ) {
    logger.error('inkindRouteId property is missing');
  }
  if (!cart) logger.error('cart property is missing');

  const title = cart?.some(
    product =>
      product.checkoutType === CheckoutType.TangoCard ||
      product.checkoutType === CheckoutType.Perfectgift ||
      product.checkoutType === CheckoutType.GiftyaPlatform
  )
    ? 'Send Gift Card'
    : cart?.some(product => product.checkoutType === 'giftbox')
      ? 'Send Gift Box'
      : cart?.some(product => product.checkoutType === 'gik-premium')
        ? 'Upgrade Your Page'
        : cart?.some(product => product.checkoutType === 'donation')
          ? cart?.[0]?.name
          : `Send ${cart?.[0]?.name ?? 'Product'}`; // virtual product

  return UI.dialog(
    ({ close, contentRef, footerRef, headerRef, setTitle, setClosable, setOnBack, setFooterClass, scrollToTop }) => {
      return (
        <WalletSystemVerify routeId={inkindRouteId} cart={cart}>
          {({ forceCheckoutType, convertedCart }) => (
            <CheckoutFormWrapperComp
              inkindRouteId={inkindRouteId}
              stepsNavPortal={headerRef.current}
              buttonsPortal={footerRef.current}
              recipientName={recipientName}
              customMessage={customMessage}
              expiryMonth={expiryMonth}
              cardImage={cardImage}
              faceplate={faceplate}
              hasPhysicalCard={hasPhysicalCard}
              hasCardCarrier={hasCardCarrier}
              buyForSomeoneElse={buyForSomeoneElse}
              onCloseSuccessfulPurchase={() => {
                close();
                onCloseSuccessfulPurchase();
              }}
              closeButtonOverrideRef={closeButtonOverride}
              onSuccess={() => {
                submitSucceeded.current = true;

                // refresh the calendar
                useCalendarStore?.getState?.()?.reloadFunction?.(useCalendarStore.getState().selectedMonth);
              }}
              forceCheckoutType={forceCheckoutType}
              scrollToTop={scrollToTop}
              setTitle={setTitle}
              setClosable={setClosable}
              setOnDialogBack={setOnBack}
              setDialogFooterClass={setFooterClass}
              cart={convertedCart ?? cart}
              isOpenLoop={isOpenLoop}
              onStepChange={step => {
                currentStep.current = step;
              }}
              onStepProgression={step => {
                completedStep.current = step;
              }}
              {...formProps}
            />
          )}
        </WalletSystemVerify>
      );
    },
    {
      title,
      closable: true,
      autowidth: false,
      contentId,
      onClose: async () => {
        const cart = useCheckoutStore.getState().cart;

        if (closeButtonOverride.current) {
          await closeButtonOverride.current();
          return false;
        }

        if (completedStep.current === 'payment' && submitSucceeded.current != true && cart.length > 0) {
          const response = await confirmCloseModal();

          if (response === undefined) {
            return false;
          }

          if (response === true) {
            await tryToNavigateToPaymentStep(currentStep);
            onCompletePurchase?.();
            return false;
          }
        }

        // reset Checkoutform store
        // wait for modal to close then reset the form to avoid flickering
        setTimeout(() => {
          useCheckoutStore.getState().setCart([]);
          useCalendarStore.getState().setClaimFailErrorCode(null);
          useCalendarStore.getState().setPaymentConfirmationValues(undefined);
        }, 800);

        return onClose();
      },
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      },
      className: 'gik-modal-checkout-new',
      footer: ({ footerClass }) => {
        return <ModalButtonFooter centeredButtons={false} className={footerClass} />;
      },
    }
  );
}

interface IWalletSystemVerifyChildrenProps {
  forceCheckoutType?: ProductCheckoutType;
  convertedCart?: CartItem[];
}
interface IWalletSystemVerifyProps {
  routeId: string;
  cart: CartItem[];
  children?: (props: IWalletSystemVerifyChildrenProps) => React.ReactNode;
}
function WalletSystemVerify({ routeId, cart, children }: IWalletSystemVerifyProps) {
  const { data: inkindPage, isValidating } = useInkind(routeId);

  const unsupportedPageNoWallet = React.useMemo(
    () =>
      inkindPage &&
      cart &&
      !inkindPage.isWalletFeatureActive &&
      cart.some(product => product.checkoutType === CheckoutType.GiftyaPlatform),
    [inkindPage, cart]
  );

  const { data: convertedProducts, isValidating: isConvertedCartValidating } = useProducts({
    productIds: unsupportedPageNoWallet ? cart.map(c => c.productId) : [],
    forceCheckoutType: CheckoutType.Perfectgift,
  });

  const convertedCart = React.useMemo(() => {
    if (!unsupportedPageNoWallet || !convertedProducts) return null;

    return cart.map(c => {
      const match = convertedProducts.find(p => p.id === c.productId);

      return {
        ...c,
        checkoutType: match.checkoutType,
        id: match.acf['pg_brand_uuid'],
        variationId: match.variations[0].id,
      };
    });
  }, [cart, convertedProducts, unsupportedPageNoWallet]);

  if (
    !inkindPage ||
    (!!unsupportedPageNoWallet && !!inkindPage && !convertedProducts) ||
    (!!unsupportedPageNoWallet && !convertedCart)
  ) {
    return <LoadingSpinner center />;
  }

  return children?.({
    forceCheckoutType: unsupportedPageNoWallet ? CheckoutType.Perfectgift : undefined,
    convertedCart: convertedCart,
  });
}
