import React from 'react';

export const useIsMounted = (): (() => boolean) => {
  const [_isMounted, setIsMounted] = React.useState<boolean>(false);
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    setIsMounted(true);
    return function cleanup(): void {
      isMounted.current = false;
      setIsMounted(false);
    };
  }, []);
  const checker = React.useCallback((): boolean => {
    return isMounted.current || _isMounted;
  }, [_isMounted]);
  return checker;
};
