import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { SupportButton } from '@gik/inkind-page/components/Support/SupportButton';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { CashApp } from '@gik/ui/SvgIcon/BrandIcons/CashApp';
import React from 'react';

export type ICashAppSupportButtonProps = UIComponent & {
  value?: string;
  onClick?: () => void;
};

export function CashAppSupportButton({ value, className, onClick }: ICashAppSupportButtonProps) {
  const bem = useBemCN('cashapp-support-button');

  return (
    <SupportButton
      {...bem(null, null, className)}
      variant={'cashapp'}
      href={`https://cash.app/${value}`}
      onClick={onClick}
      icon={
        <SvgIcon
          style={{
            width: '84px',
            height: '32px',
          }}
          Icon={CashApp}
        />
      }
    />
  );
}
