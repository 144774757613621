import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import type { ICreateFeaturesWishlistFormValues } from '@gik/create/components/FeatureStep/FeatureStep';
import { WishlistFeatureStep } from '@gik/create/components/FeatureStep/FeatureStep';
import { FeaturesStepInputNames } from '@gik/create/enums/FeaturesStepInputNames';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import type { FormProps } from '@gik/ui/Form';
import { Wishlist } from '@gik/ui/SvgIcon/GikIcons/Wishlist';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';

export interface IWishlistStepProps extends FormProps {
  onSubmit?: (values: ICreateFeaturesWishlistFormValues) => void;
  onChange?: (values: ICreateFeaturesWishlistFormValues) => void;
}

const blockName = 'wishlist-step';

export default function WishlistStep({ ...otherProps }) {
  const bem = bemBlock(blockName);
  const { wishlistFormRef } = React.useContext(CreatePageContext);
  const { stepIndex, subStepIndex } = useCreateFlowStore(state => ({
    stepIndex: state.stepIndex,
    subStepIndex: state.subStepIndex,
  }));

  const enabled = stepIndex == 3 && subStepIndex == 1;
  useFormNavigationObserver(wishlistFormRef, enabled);

  return (
    <WishlistFeatureStep
      ref={wishlistFormRef}
      formName={FeaturesStepInputNames.Wishlist}
      className={bem()}
      icon={<SvgIcon className={bem('icon')} Icon={Wishlist} />}
      title={i18n.t(translationKeys.WishlistTitle)}
      description={i18n.t(translationKeys.WishlistCreateStepDescription)}
      {...otherProps}
    />
  );
}
