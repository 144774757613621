import bemBlock from '@gik/core/utils/bemBlock';
import repeatElement from '@gik/core/utils/repeatElement';
import type { UISize, UIVariant } from '@gik/ui/types';
import React from 'react';

export type IndicatorVariantSpecialTypes = 'primary-default' | 'secondary-default';

export interface IIndicatorProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement> {
  currentStep: number;
  totalSteps: number;
  variant?: UIVariant | IndicatorVariantSpecialTypes;
  size?: UISize;
  className?: string;
  onIndicatorClick?: (step: number, ev?: React.MouseEvent<HTMLLIElement>) => void;
}

const blockName = 'indicator';

export function Indicator({
  currentStep,
  totalSteps,
  variant = 'default',
  size = 'base',
  className,
  onIndicatorClick,
  ...otherProps
}: IIndicatorProps) {
  const bem = bemBlock(blockName);

  function handleIndicatorClick(step: number, ev: React.MouseEvent<HTMLLIElement>) {
    ev?.stopPropagation();
    onIndicatorClick?.(step, ev);
  }

  if (totalSteps > 1) {
    return (
      <ul className={bem(null, [{ [variant]: variant }, { [size]: true }], className)} {...otherProps}>
        {repeatElement(totalSteps, i => (
          <li
            className={bem('step', [{ active: currentStep === i }])}
            onClickCapture={ev => handleIndicatorClick(i, ev)}
          />
        ))}
      </ul>
    );
  } else {
    return null;
  }
}
