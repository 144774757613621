import { isBrowser } from '@/utils/environment';
import { gikClassPrefix } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import XIcon from '@heroicons/react/solid/XIcon';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';
import { SvgIcon } from '../SvgIcon/SvgIcon';

const blockName = `${gikClassPrefix}-interstitial`;
const bem = bemBlock(blockName, '');

export const animVariants = {
  initial: { opacity: 0, top: '-100%' },
  enter: { opacity: 1, top: '0%', transition: { duration: 0.5, ease: 'easeOut' } },
  exit: {
    top: '-100%',
    opacity: 0,
    transition: { duration: 0.5, ease: 'easeOut' },
  },
};

export interface InterstitialProps {
  isOpen: boolean;
  onClose?(): void;
  children?: React.ReactNode;
  contentClassName?: string;
}

export function Interstitial({ isOpen, onClose, children, contentClassName }: InterstitialProps): React.ReactElement {
  const portalRef = React.useRef<HTMLDivElement>(null);

  if (!isBrowser()) return null;

  const portalContainer = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          animate="enter"
          exit="exit"
          variants={animVariants}
          ref={portalRef}
          className={bem('portal', isOpen ? 'open' : '')}
        >
          <div className={classNames([{ [bem('content')]: true }, { [contentClassName]: contentClassName }])}>
            <div className={bem('close')} onClick={onClose}>
              <SvgIcon Icon={XIcon} size="lg" onClick={onClose} />
            </div>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return ReactDOM.createPortal(portalContainer, document.body);
}
