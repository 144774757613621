import React from 'react';

export default function repeatElement(
  repeatAmount: number,
  element: JSX.Element | ((index: number) => JSX.Element),
  fragment?: boolean
): React.ReactNode | [];
export default function repeatElement(
  repeatAmount: number,
  element: JSX.Element | ((index: number) => JSX.Element),
  fragment: true
): React.ReactNode;
export default function repeatElement<T>(
  repeatAmount: number,
  element: JSX.Element | ((index: number) => JSX.Element),
  fragment: false
): [];
export default function repeatElement(repeatAmount, element, fragment = true) {
  function main() {
    return Array.from(Array(repeatAmount).keys()).map((_, i) =>
      React.cloneElement(typeof element === 'function' ? element(i) : element, {
        key: i,
      })
    );
  }

  return fragment ? <>{main()}</> : main();
}
