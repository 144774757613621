import classnames from 'classnames';
import React from 'react';
import { gikClassPrefix } from '../constants';

type Element = string;
type Modifiers = string | Array<string | object>;
type ClassNames = string | object | Array<string | object>;

export type bemFunction = (element?: Element, modifiers?: Modifiers, classNames?: ClassNames) => string;

export default function bemBlock(blockName: string, prefix = `${gikClassPrefix}-`): bemFunction {
  return function bem(element?, modifiers?, classNames?): string {
    const block = `${prefix}${blockName}`;

    const blockElement = element ? `${block}__${element}` : block;
    const blockModifiers = modifiers
      ? Array.isArray(modifiers)
        ? classnames(
            modifiers
              .filter(m => m)
              .map(m => {
                if (typeof m === 'string') {
                  return `${blockElement}--${m}`;
                } else {
                  const key = Object.keys(m)[0];
                  return { [`${blockElement}--${key}`]: m[key] };
                }
              })
          )
        : `${blockElement}--${modifiers}`
      : '';

    if (classNames) {
      if (Array.isArray(classNames)) {
        // @ts-ignore
        return classnames([blockElement, blockModifiers, ...classNames]);
      } else {
        // @ts-ignore
        return classnames([blockElement, blockModifiers, classNames]);
      }
    } else {
      return classnames([blockElement, blockModifiers]);
    }
  };
}

export function useBemCN(
  blockName: string,
  prefix?: string
): (...bemFunctionArgs: Parameters<bemFunction>) => {
  className: ReturnType<bemFunction>;
} {
  return React.useCallback(
    function (...bemFunctionArgs) {
      const bem = bemBlock(blockName, prefix);
      return {
        className: bem(...bemFunctionArgs),
      };
    },
    [blockName, prefix]
  );
}
