import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import type { IDialogRenderProps } from '@gik/ui/Dialogs/DialogTypes';
import { translationKeys as dialogTranslationKeys } from '@gik/ui/Dialogs/i18n/en';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { UI } from '@gik/ui/UIManager';
import he from 'he';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export interface ModifyClaimedEventConfirmationProps {
  claimOwnerFirstName: string;
  claimOwnerLastName: string;
  modificationType: 'Edit' | 'Delete';
}

interface DialogCallbackProps {
  renderProps: IDialogRenderProps;
}

const blockName = 'modify-claimed-event-confirmation';
const bem = bemBlock(blockName);

function ModifyClaimedEventConfirmation({
  renderProps,
  claimOwnerFirstName,
  claimOwnerLastName,
  modificationType,
}: ModifyClaimedEventConfirmationProps & DialogCallbackProps): React.ReactElement {
  const { t } = useTranslation();
  const schema: FormSchemaEntry[] = [
    {
      name: 'note',
      type: 'textarea',
      required: true,
      maxLength: 255,
      props: {
        placeholder: t(translationKeys.noteToClaimOwner, { claimOwnerFirstName }),
        autoGrow: true,
      },
    },
  ];

  function handleSubmit({ note }: { note: string }) {
    renderProps.close(note);
  }

  return (
    <Form
      id={blockName}
      restoreAfterUpdate
      schema={schema}
      render={() => (
        <>
          <p>
            {t(modificationType === 'Edit' ? translationKeys.editInformation : translationKeys.deleteInformation, {
              claimOwnerFirstName: he.decode(claimOwnerFirstName),
              claimOwnerLastName: he.decode(claimOwnerLastName),
            })}
          </p>
          <section>
            <FormField name="note" />
          </section>
        </>
      )}
      onSubmit={handleSubmit}
    />
  );
}

export function openModifyClaimedEventConfirmation(props: ModifyClaimedEventConfirmationProps): Promise<string> {
  return UI.dialog(
    (renderProps: IDialogRenderProps) => (
      <ModifyClaimedEventConfirmation
        {...{
          ...props,
          renderProps,
        }}
      />
    ),
    {
      title: i18n.t(translationKeys.title, { modificationType: props.modificationType }),
      closable: true,
      className: bem(),
      buttons: ({ close }) => {
        return [
          <Button key="cancel" variant="default" onClick={() => close(null)}>
            <Translation>{t => t(dialogTranslationKeys.confirmCancelButton)}</Translation>
          </Button>,
          <Button key="confirm" variant="danger" type="submit" form={blockName}>
            <Translation>
              {t => t(props.modificationType === 'Edit' ? translationKeys.editAndSend : translationKeys.deleteAndSend)}
            </Translation>
          </Button>,
        ];
      },
    }
  );
}
