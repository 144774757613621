import bemBlock from '@gik/core/utils/bemBlock';
import { useCreateFlowStore } from '@gik/create';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import type { ICreateFeaturesCareCalendarFormValues } from '@gik/create/components/FeatureStep/FeatureStep';
import { CareCalendarFeatureStep } from '@gik/create/components/FeatureStep/FeatureStep';
import { FeaturesStepInputNames } from '@gik/create/enums/FeaturesStepInputNames';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import type { FormProps } from '@gik/ui/Form';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CalendarIcon from '@heroicons/react/outline/CalendarIcon';
import React from 'react';

export interface ICareCalendarStepProps extends FormProps {
  onSubmit?: (values: ICreateFeaturesCareCalendarFormValues) => void;
  onChange?: (values: ICreateFeaturesCareCalendarFormValues) => void;
}

const blockName = 'care-calendar-step';

export default function CareCalendarStep({ ...otherProps }) {
  const bem = bemBlock(blockName);

  const { careCalendarFormRef } = React.useContext(CreatePageContext);
  const { stepIndex, subStepIndex } = useCreateFlowStore(state => ({
    stepIndex: state.stepIndex,
    subStepIndex: state.subStepIndex,
  }));

  const enabled = stepIndex == 3 && subStepIndex == 0;
  useFormNavigationObserver(careCalendarFormRef, enabled);

  return (
    <CareCalendarFeatureStep
      ref={careCalendarFormRef}
      className={bem()}
      formName={FeaturesStepInputNames.CareCalendar}
      icon={<SvgIcon className={'icon'} Icon={CalendarIcon} />}
      title={i18n.t(translationKeys.careCalendarTitle)}
      description={i18n.t(translationKeys.careCalendarCreateStepDescription)}
      {...otherProps}
    />
  );
}
