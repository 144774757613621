import { isBrowser } from '@/utils/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { useEnvStore } from '@gik/core/store/EnvStore';

export type LogOptions = {
  // NOTE: should always shift at least one line since the first line is just the word "Error" which can be ignored.
  // it can be ignored because this is just how a stacktrack starts if you capture if from a `new Error().stack` call.
  linesToShift: number;
  printAllLines?: boolean;
};

// TODO: start using Winston

type ErrorLevel = 'info' | 'warn' | 'error' | 'debug';
function loggerWrapper(
  level: ErrorLevel,
  message: unknown,
  optionalParams?: object,
  options: LogOptions = { linesToShift: 3 }
): void {
  const stackTrace = new Error().stack;

  const lines = stackTrace.split('\n');

  Array.from({ length: options?.linesToShift }, () => {
    lines.shift();
  });

  let newStackTrace = '\n';
  if (level === 'error' || options?.printAllLines) {
    newStackTrace += lines.join('\n');
  } else {
    newStackTrace += lines[0];
  }

  const extraParams = {
    ...(optionalParams ?? {}),
  };

  // eslint-disable-next-line
  console[level]?.(message + newStackTrace, extraParams);

  if (!isBrowser()) return;

  datadogLogs.logger[level]?.(message as string, extraParams);
}

function info(message: unknown, optionalParams?: object): void {
  // TODO: set log level instead of LOGGER_ENABLED
  if (useEnvStore.getState().LOGGER_ENABLED === 'true') {
    loggerWrapper('info', message, optionalParams);
  }
}

function warn(message: unknown, optionalParams?: object): void {
  loggerWrapper('warn', message, optionalParams);
}

function debug(message: unknown, optionalParams?: object): void {
  loggerWrapper('debug', message, optionalParams);
}

function error(message: unknown, optionalParams?: object): void {
  loggerWrapper('error', message, optionalParams);
}

function time(startTime: Date, message: unknown, optionalParams?: object): void {
  if (!startTime) return;

  const timeElapsed = new Date().getMilliseconds() - startTime.getMilliseconds();
  loggerWrapper('info', message + ` ${timeElapsed}ms`, optionalParams, { linesToShift: 3 });
}

export const logger = {
  info,
  error,
  warn,
  debug,
  time,
  log: info,
};
