import type { UIComponent } from '@gik/core/types/UI';
import { sanitizeText, wrapUrlsWithAnchorTags } from '@gik/core/utils/Text';
import React from 'react';
import { HTMLParser } from '../HTMLParser';
import { nl2br } from '@gik/core/utils/string';

export type TextProps = {
  parseUrls?: boolean;
  html?: boolean;
} & UIComponent;

export function Text({ children, className, parseUrls, html }: React.PropsWithChildren<TextProps>): React.ReactElement {
  const elements = Array.isArray(children) ? children : [children];

  return (
    <>
      {elements.map((element, index) => {
        if (typeof element === 'string') {
          let output = html ? element : sanitizeText(element);
          if (parseUrls) output = wrapUrlsWithAnchorTags(output);
          if (parseUrls || html) return <HTMLParser key={index} rawHtml={nl2br(output)} />;
          return output;
        }

        return <React.Fragment key={index}>{element}</React.Fragment>;
      })}
    </>
  );
}
