import { useTerms } from '@gik/api/legal';
import { FetchProvider } from '@gik/core/components/DynamicComponent/FetchProvider';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import type { IDrawerProps } from '@gik/ui/Drawer/DrawerTypes';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type WordpressLegal from '../../models/wordpress/WordpressLegal';
import { ErrorPageSection } from '../Error/ErrorPageSection';
import { LegalPage } from './LegalPage';
import { LegalSkeleton } from './LegalSkeleton';

export function openTermsOfServiceSheet(props?: IDrawerProps) {
  return UI.modalSheet(() => <TermsOfService />, {
    ...props,
    title: 'Terms of Service',
    padded: true,
  });
}

function TermsOfServiceComp() {
  const useFetch = () => {
    return useTerms(true);
  };

  return (
    <FetchProvider<WordpressLegal[], string>
      fetch={useFetch}
      transform={data => data[0]?.content.rendered}
      loadingComponent={<LegalSkeleton />}
    >
      {({ transformed: text }) => <LegalPage text={text} />}
    </FetchProvider>
  );
}

export const TermsOfService = withComponentErrorBoundary(TermsOfServiceComp, { ErrorFallback: ErrorPageSection });
