import type { InkindPageAPIModel } from '../models/gik/InkindPage';

export const gofundmeRegex = /^(http(s)?:\/\/)?(www\.)?gofundme\.com\/f\/(.)/;
export const gofundmeShortRegex = /^(http(s)?:\/\/)?gofund\.me\/(.)/;

export function extractCampaignSlug(url: string) {
  if (url?.includes('gofund.me')) return url;
  return url?.split('f/')?.[1]?.split('?')[0] || '';
}

export function getGoFundMeURL(inkindPage: InkindPageAPIModel) {
  const url = inkindPage?.financialContributions?.goFundMeUrl;

  if (!url) return '';
  if (url.includes('gofund.me') || url.includes('f/')) return url;
  return 'https://gofundme.com/f/' + url;
}
