import Head from '@/components/Head';
import { siteTitle } from '@/constants';
import { PageHeadKeys } from '@gik/core/hocs/PageHeadKeys';
import he from 'he';
import React from 'react';

const titleSuffix = siteTitle;
const titleSeparator = ' - ';

export type TitleWithFallback = {
  title: string;
  fallback?: string;
};

export function generateTitle(title?: string | TitleWithFallback, withSuffix = true) {
  let finalTitle: string;

  if (typeof title == 'object') {
    finalTitle = title?.title || title?.fallback;
  } else if (typeof title == 'string') {
    finalTitle = title;
  }

  return finalTitle ? `${he.decode(finalTitle)}${withSuffix ? `${titleSeparator}${titleSuffix}` : ''}` : titleSuffix;
}

export function withTitle(title?: string | TitleWithFallback, component?) {
  return (
    <>
      <Head>
        <title key={PageHeadKeys.TITLE}>{generateTitle(title)}</title>
      </Head>
      {component}
    </>
  );
}
