import { CityField } from '@gik/core/form/fields/CityField';
import { CompanyNameField } from '@gik/core/form/fields/CompanyNameField';
import { CountryField } from '@gik/core/form/fields/CountryField';
import { EmailAddressField } from '@gik/core/form/fields/EmailAddressField';
import { FirstNameField } from '@gik/core/form/fields/FirstNameField';
import { LastNameField } from '@gik/core/form/fields/LastNameField';
import { StateField } from '@gik/core/form/fields/StateField';
import { StreetAddress1Field, StreetAddress2Field } from '@gik/core/form/fields/StreetAddressField';
import { ZipCodeField } from '@gik/core/form/fields/ZipCodeField';
import type { FormSchemaEntry } from '@gik/ui/Form';
import type { RefObject } from 'react';

export type ShippingFormSchemaProps = {
  ref?: RefObject<HTMLElement>;
  hasEmail?: boolean;
  emailReadOnly?: boolean;
  nameReadOnly?: boolean;
  lastNameRequired?: boolean;
  autoscroll?: boolean;
};

export const ShippingFormSchema: (props: ShippingFormSchemaProps) => FormSchemaEntry[] = ({
  ref,
  hasEmail,
  emailReadOnly,
  nameReadOnly,
  lastNameRequired,
  autoscroll,
}) => {
  const fields = [
    FirstNameField({ readOnly: nameReadOnly }),
    LastNameField({ readOnly: nameReadOnly, required: lastNameRequired }),
    CompanyNameField(),
    CountryField(),
    StreetAddress1Field(),
    StreetAddress2Field(),
    CityField(),
    StateField({ scrollParent: ref, autoscroll }),
    ZipCodeField(),
  ];
  if (hasEmail) fields.push(EmailAddressField({ readOnly: emailReadOnly }));
  return fields;
};
