import type { IAddToCalendarLinks } from '@gik/calendar/components/AddToCalendarSelector';
import { PartnerClaimSuccess } from '@gik/calendar/components/ClaimEvent/services/partner/PartnerClaimSuccess';
import DoorDashButton from '@gik/calendar/components/DoorDashButton/DoorDashButton';
import { PartnerTimeline, usePartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import type { IPartnerClaimURL } from '@gik/calendar/models/PartnerClaimDetails';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import moment from 'moment';
import React from 'react';

type DoorDashClaimSuccessProps = UIComponent &
  IPartnerClaimURL & {
    entry: ICalendarEntry;
    event: ICalendarEvent;
    addToCalendarLinks: IAddToCalendarLinks;
    buttonsPortal?: () => HTMLElement;
    isFollowing: boolean;
  };

export function DoorDashClaimSuccess({
  className,
  entry,
  event,
  partnerUrl,
  addToCalendarLinks,
  isFollowing,
  ...otherProps
}: DoorDashClaimSuccessProps): React.ReactElement {
  const bem = useBemCN('doordash-claim-success');
  const timeline = PartnerTimeline.FIVE_DAYS_PRIOR;
  const { canOrderNow } = usePartnerTimeline(timeline, moment.utc(event.startsAt));
  const partnerButton = <DoorDashButton url={partnerUrl} canOrderNow={canOrderNow} />;
  const partnerName = 'DoorDash';

  return (
    <PartnerClaimSuccess
      {...bem()}
      entry={entry}
      event={event}
      partnerUrl={partnerUrl}
      addToCalendarLinks={addToCalendarLinks}
      isFollowing={isFollowing}
      timeline={timeline}
      partnerButton={partnerButton}
      partnerName={partnerName}
      orderDaysBefore={7}
      {...otherProps}
    />
  );
}
