import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import type { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import type { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import type { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { ExternalLink } from '@gik/ui/ExternalLink';
import type { FormSchemaEntry, ValidationErrors } from '@gik/ui/Form';
import { Form, validateForm } from '@gik/ui/Form';
import { Input } from '@gik/ui/Input';
import React from 'react';
import usePrevious from 'react-use/lib/usePrevious';

export enum DonationProviderType {
  PayPal = 'paypal',
  GoFundMe = 'gofundme',
  Venmo = 'venmo',
  CashApp = 'cashapp',
}

export interface IDonationProviderProps {
  schema: FormSchemaEntry[];
  provider: DonationProviderType;
  target: string;
  className: string;
  value: DonationFormValueType;
  onValueChange: (value: DonationFormValueType) => void;
  asForm?: boolean;
  trackingId?: AnalyticsEvents;
  recalculateHeightCallback?: () => void;
  previewComponent?: (value: string, isValid: boolean) => React.ReactNode;
  previewType?: 'widget' | 'button';
}

export type PayPalDonationFormValueType = {
  [PayPalFormInputNames.EmailAddress]: string;
  isPayPalValueValid: boolean;
};

export type GoFundMeDonationFormValueType = {
  [GoFundMeFormInputNames.PageURL]: string;
  isGoFundMeValueValid: boolean;
};

export type VenmoDonationFormValueType = {
  [VenmoFormInputNames.VenmoAddress]: string;
  isVenmoValueValid: boolean;
};

export type CashAppDonationFormValueType = {
  [CashAppFormInputNames.CashAppAddress]: string;
  isCashAppValueValid: boolean;
};

export type DonationFormValueType =
  | PayPalDonationFormValueType
  | GoFundMeDonationFormValueType
  | VenmoDonationFormValueType
  | CashAppDonationFormValueType;

const blockName = 'donation-provider';

export default function DonationProvider({
  schema,
  provider,
  target,
  className,
  value,
  onValueChange,
  asForm = false,
  trackingId,
  recalculateHeightCallback = noop,
  previewComponent,
  previewType = 'button',
}: IDonationProviderProps) {
  const bem = bemBlock(blockName);
  const ctx = React.useContext(CreatePageContext);

  // validation for onChange
  const [hasBlurred, setHasBlurred] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>(null);
  const [isValid, setValid] = React.useState<boolean>(false);
  const [_value, setValue] = React.useState<string>(null);
  const prevHasBlurred = usePrevious(hasBlurred);

  React.useEffect(() => {
    if (prevHasBlurred !== hasBlurred && error) {
      recalculateHeightCallback?.();
    }
  }, [recalculateHeightCallback, prevHasBlurred, hasBlurred, error]);

  const customValidateForm = async (values: DonationFormValueType, fieldName: string): Promise<boolean> => {
    const errors: ValidationErrors = await validateForm(schema, values);
    const payPalOrGofundmeFieldErrorMessage = errors?.[fieldName]?.message;
    setError(payPalOrGofundmeFieldErrorMessage);
    return !payPalOrGofundmeFieldErrorMessage;
  };

  const preview = previewComponent?.(_value, isValid);

  // TODO: remove asForm ? conditional logic - we should not have to support two different methods
  return (
    <div className={bem(null, [{ [provider]: provider }], className)}>
      <main>
        <p className={bem('target')}>{target}:</p>
        <div className={bem('form-wrapper')}>
          {asForm ? (
            <Form
              ref={ref => {
                if (ctx) {
                  switch (provider) {
                    case DonationProviderType.GoFundMe:
                      ctx.donationsFormAsideGoFundMeRef.current = ref;
                      break;
                    case DonationProviderType.PayPal:
                      ctx.donationsFormAsidePayPalRef.current = ref;
                      break;
                    case DonationProviderType.Venmo:
                      ctx.donationsFormAsideVenmoRef.current = ref;
                      break;
                    case DonationProviderType.CashApp:
                      ctx.donationsFormAsideCashAppRef.current = ref;
                      break;
                  }
                }
              }}
              schema={schema}
              onChange={onValueChange}
              initialValues={value}
              trackingId={trackingId}
            />
          ) : (
            <>
              {schema.map(schemaItem => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { customValidation: _, ...field } = schemaItem;
                const fieldName = field.name;

                return (
                  <React.Fragment key={fieldName}>
                    <Input
                      {...field}
                      type={fieldName === PayPalFormInputNames.EmailAddress ? 'email' : 'text'}
                      size="base"
                      variant="default"
                      hasError={hasBlurred && !!error}
                      onBlur={() => setHasBlurred(true)}
                      value={value[fieldName]}
                      onValueChange={async fieldValue => {
                        const newValue = {
                          ...value,
                          [fieldName]: fieldValue,
                        };
                        const isFieldValid = await customValidateForm(newValue, fieldName);
                        setValid(isFieldValid);
                        setValue(fieldValue);
                        const validValue =
                          fieldName === PayPalFormInputNames.EmailAddress
                            ? { isPayPalValueValid: isFieldValid }
                            : { isGoFundMeValueValid: isFieldValid };
                        onValueChange({ ...newValue, ...validValue });
                      }}
                    />
                    {hasBlurred && error && (
                      <div className={bem('validation-error', null, 'gik-form-group__error')}>{error}</div>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
        <div className={bem('help-link')}>
          <ExternalLink
            href={'https://help.giveinkind.com/en/articles/6273442-all-fundraising-options-for-your-inkind-page'}
          >
            Need help connecting fundraising?
          </ExternalLink>
        </div>
      </main>
      {preview && (
        <aside>
          <p className={bem('widget-desc')}>
            {previewType === 'button'
              ? 'Displays this button on your page'
              : 'Displays this widget' + ' on' + ' your page'}
          </p>
          {preview}
        </aside>
      )}
    </div>
  );
}
