import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import type { IPetCareOptions } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import DateTimeUtils from '@gik/core/utils/DateTimeUtils';
import i18n from '@gik/i18n';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import type { FormSchemaEntry } from '@gik/ui/Form';
import moment from 'moment';
import { timeToInt } from 'time-number';
import type { ICalendarEventFormValues, ICalendarEventTimeAndRepeatFormValues } from './CalendarEventForm';
import { translationKeys } from './i18n/en';
import { isFoodType } from './utils';

/**
 * Generate select options from calendarEventTypes
 */
export function getCalendarEventTypeOptions() {
  return useCalendarStore.getState().calendarEventTypes?.map(item => {
    return {
      label: item.name,
      value: item.id.toString(),
      image: item.acf.taxonomy_svg_icon,
    };
  });
}

export const calendarEventTypeField = (): FormSchemaEntry => ({
  name: 'typeId',
  type: 'select',
  placeholder: 'What type of help is needed?',
  // requiredMessage: 'requiredThis',
  // required: true,
  label: 'Request Type',
  props: {
    options: getCalendarEventTypeOptions(),
    block: true,
  },
});

export const calendarTimeAndRepeatSchema = async (
  formValues: ICalendarEventTimeAndRepeatFormValues,
  isAnnouncement?: boolean,
  isRepeatDisabled?: boolean
): Promise<FormSchemaEntry[]> => {
  const startDateLabel =
    formValues?.repeat !== -1 && formValues?.repeat !== null
      ? i18n.t(translationKeys.startDateLabel)
      : i18n.t(translationKeys.dateLabel);

  const { repeatOptions } = await import('@gik/calendar/constants/repeatConstants');

  const hasRepeat = formValues?.repeat !== undefined && formValues?.repeat !== null && formValues?.repeat !== -1;

  return [
    {
      name: 'startDate',
      label: startDateLabel,
      type: 'date-picker',
      placeholder: i18n.t(translationKeys.startDatePlaceholder),
      default: moment().format(DateTimeUtils.DateFormat),
      required: true,
      props: {
        autoComplete: 'off',
      },
    },
    {
      name: 'endDate',
      label: i18n.t(translationKeys.endDateLabel),
      type: 'date-picker',
      placeholder: i18n.t(translationKeys.endDatePlaceholder),
      default: null,
      required: hasRepeat,
      props: {
        autoComplete: 'off',
      },
    },
    {
      name: 'allDay',
      type: 'checkbox',
      default: false,
      props: {
        label: isAnnouncement ? i18n.t(translationKeys.noSpecificTimeLabel) : i18n.t(translationKeys.allDayLabel),
      },
    },
    {
      name: 'startTime',
      label: i18n.t(translationKeys.startTimeLabel),
      type: 'select-time',
      default: timeToInt('12:00'),
    },
    {
      name: 'endTime',
      label: i18n.t(translationKeys.endTimeLabel),
      type: 'select-time',
      default: timeToInt('13:00'),
    },
    {
      name: 'weekdays',
      required: formValues?.repeat === 10,
      type: 'weekday-selector',
    },
    {
      name: 'repeat',
      type: 'select',
      label: i18n.t(translationKeys.repeatLabel),
      placeholder: i18n.t(translationKeys.repeatPlaceholder),
      // requiredMessage: 'requiredThis',
      required: true,
      props: {
        disabled: isRepeatDisabled,
        options: repeatOptions,
      },
    },
  ];
};

export async function getEventFormSchema(
  typeId: number,
  formValues: ICalendarEventFormValues,
  petCareTypes: IPetCareOptions[],
  giftCardsOnly: boolean,
  isRepeatDisabled?: boolean
) {
  const _isFoodType = isFoodType(typeId);

  let descriptionLabel = 'Description';
  let numberRequiredLabel = 'How many people?';

  let deliveryInstructionsPlaceholder: string;
  let descriptionPlaceholder = 'Description, special instructions, or dietary needs (optional)';

  let emergencyContactLabel = 'Contact Info';
  let emergencyContactPlaceholder = 'Name/number of any contacts (optional)';

  if (_isFoodType) {
    descriptionPlaceholder = 'Favorite restaurants, dietary needs, or other special instructions (optional)';
    deliveryInstructionsPlaceholder =
      'Where to leave dinner, how to notify the meal has been delivered. Do NOT include sensitive info like door codes (optional)';
  }

  if (typeId === CalendarEventTypeIds.Groceries) {
    descriptionPlaceholder = 'Preferred stores, dietary needs, or other special instructions (optional)';
    deliveryInstructionsPlaceholder =
      'Where to leave dinner, how to notify the meal has been delivered. Do NOT include sensitive info like door codes (optional)';
  }
  if (typeId === CalendarEventTypeIds.HomeServices) {
    descriptionPlaceholder = 'Description, cleaning supplies, equipment, special skills etc. (optional)';
    descriptionLabel = 'What needs to be done around the house?';
  }
  if (typeId === CalendarEventTypeIds.ChildCare) {
    numberRequiredLabel = 'How many kids?';
    descriptionPlaceholder = 'Childcare instructions, pickup times, etc. (optional)';
  }
  if (typeId === CalendarEventTypeIds.Transportation) {
    numberRequiredLabel = 'How many passengers?';
    descriptionPlaceholder = 'Special instructions, vehicle accessibility, luggage etc. (optional)';
  }
  if (typeId === CalendarEventTypeIds.PetCare) {
    descriptionPlaceholder = 'Type and number of pets, feeding instructions, grooming, medications, etc. (optional)';
    numberRequiredLabel = 'How many pets?';
  }
  if (typeId === CalendarEventTypeIds.ChildCare || typeId === CalendarEventTypeIds.PetCare) {
    emergencyContactLabel = 'Emergency Contact';
    emergencyContactPlaceholder = 'Name/number of any emergency contacts (optional)';
  }
  if (typeId === CalendarEventTypeIds.SeniorCare) {
    descriptionPlaceholder = 'Instructions, medications, or skills required for care (optional)';
    emergencyContactPlaceholder = 'Name/number of the person to be called, or any emergency contacts (optional)';
  }

  if (giftCardsOnly) {
    descriptionLabel = 'Note to supporters';
    descriptionPlaceholder = 'Anything you’d like to share with your supporters about this request (optional)';
  }

  const schema: FormSchemaEntry[] = [
    {
      name: 'typeId',
      type: 'hidden',
    },
    {
      name: 'allowedServiceIds',
      type: 'hidden',
    },
    {
      name: 'numberRequired',
      type: 'number',
      required: false,
      requiredMessage: 'requiredThis',
      label: numberRequiredLabel,
      maxLength: 4,
      props: {
        min: 1 || undefined,
        maxLength: 4,
        maxLengthDisplay: false,
      },
    },
    {
      name: 'title',
      type: 'text',
      label: i18n.t(translationKeys.titleLabel),
      // requiredMessage: 'requiredThis',
      required: typeId === CalendarEventTypeIds.Other,
      maxLength: 35,
      props: {
        maxLength: 35,
        maxLengthDisplay: true,
      },
    },
    {
      name: 'description',
      type: 'textarea',
      placeholder: descriptionPlaceholder,
      label: descriptionLabel,
      maxLength: 200,
      props: {
        noresize: true,
        maxLength: 200,
        maxLengthDisplay: true,
        rows: 7,
      },
    },
    {
      name: 'pickupLocation',
      type: 'textarea',
      placeholder: i18n.t(commonTranslationKeys.optionalPlaceholder),
      label: i18n.t(translationKeys.pickupLocationLabel),
      maxLength: 200,
      props: {
        noresize: true,
        maxLength: 200,
        maxLengthDisplay: true,
        rows: 7,
      },
    },
    {
      name: 'dropoffLocation',
      type: 'textarea',
      placeholder: i18n.t(commonTranslationKeys.optionalPlaceholder),
      label: i18n.t(translationKeys.dropOffLocationLabel),
      maxLength: 200,
      props: {
        noresize: true,
        maxLength: 200,
        maxLengthDisplay: true,
        rows: 7,
      },
    },
    {
      name: 'emergencyContactInfo',
      type: 'textarea',
      placeholder: emergencyContactPlaceholder,
      label: emergencyContactLabel,
      maxLength: 200,
      props: {
        noresize: true,
        maxLength: 200,
        maxLengthDisplay: true,
        rows: 7,
      },
    },
    {
      name: 'deliveryInstructions',
      type: 'textarea',
      placeholder: deliveryInstructionsPlaceholder,
      label: i18n.t(translationKeys.deliveryInstructionsLabel),
      maxLength: 200,
      props: {
        noresize: true,
        maxLength: 200,
        maxLengthDisplay: true,
        rows: 7,
      },
    },
    {
      name: 'allowGiftCards',
      type: 'checkbox',
      props: {
        disabled: formValues?.allowedServiceIds?.length == 1 && formValues?.allowedServiceIds?.[0] === 0,
        label: i18n.t(translationKeys.allowGiftCardsLabel),
      },
    },
    {
      name: 'sendEmailToPageFollowers',
      type: 'checkbox',
      props: {
        label: i18n.t(translationKeys.formEmailToPageFollowersLabel),
      },
    },
    ...(await calendarTimeAndRepeatSchema(formValues, false, isRepeatDisabled)),
  ];

  if (typeId === CalendarEventTypeIds.PetCare) {
    if (!petCareTypes) throw new Error('petCareTypes is missing while trying to render a petcare event form');
    schema.push({
      name: 'petCareTypeId',
      type: 'select',
      placeholder: i18n.t(translationKeys.petCareTypeIdPlaceholder),
      label: i18n.t(translationKeys.petCareTypeIdLabel),
      props: {
        options: petCareTypes.map(item => ({ value: item.id, label: item.name })),
      },
    });
  }

  return schema;
}
