import bemBlock from '@gik/core/utils/bemBlock';
import { useRandomInt } from '@gik/core/utils/getRandomInt';
import repeatElement from '@gik/core/utils/repeatElement';
import { PageSection } from '@gik/ui/gik/PageSection';
import { ButtonBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';
import { Breakpoint, useBreakpoint } from '../../hooks/hooks/BreakpointHooks';

export const legalSkeletonBlockName = 'legal-skeleton';

export const LegalSkeleton: React.FC = (): JSX.Element => {
  const bem = bemBlock(legalSkeletonBlockName);
  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);
  const wordsInParagraphDesktop = useRandomInt(100, 130);
  const wordsInParagraphMobile = useRandomInt(50, 70);

  return (
    <PageSection className={bem()}>
      {/* Give inkind Inc */}
      <div>{repeatElement(isSmDown ? 2 : 4, <ButtonBone className={bem('title-bone')} />)}</div>
      {/* Legal document title */}
      <div>{repeatElement(isSmDown ? 1 : 3, <ButtonBone className={bem('title-bone')} />)}</div>

      <ParagraphBone words={isSmDown ? 3 : 7} className={bem('subtitle')} />

      {repeatElement(
        isSmDown ? 3 : 5,
        <ParagraphBone
          words={isSmDown ? wordsInParagraphMobile : wordsInParagraphDesktop}
          className={bem('paragraph')}
        />
      )}
    </PageSection>
  );
};
