import { useServiceCategories, useServicesByEventTypeId } from '@gik/api/calendar/serviceProductBundle';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import type IWordpressServiceCategory from '@gik/core/models/wordpress/WordpressServiceCategory';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { ISelectListProps } from '@gik/ui/SelectList';
import { SelectList } from '@gik/ui/SelectList';
import React from 'react';
import type { IWordpressServiceProductBundleOption } from '../Services';
import { EventServicesTile } from './EventServicesTile';

export interface IEventServicesSelectListProps extends Omit<ISelectListProps<number>, 'onChange' | 'render'> {
  eventTypeId?: number;
  allowedServiceIds: number[];
  onChange?: (id: number, item: IWordpressService, category: IWordpressServiceCategory) => void;
}

export function EventServicesSelectList({
  multiple,
  value,
  className,
  eventTypeId,
  allowedServiceIds,
  onChange = noop,
  ...otherProps
}: IEventServicesSelectListProps): React.ReactElement {
  const bem = bemBlock('event-services-select-list');
  const isMd = useBreakpoint(Breakpoint.MD);

  // Local State
  const [options, setOptions] = React.useState<IWordpressServiceProductBundleOption[]>();

  // SWR
  const { data: serviceProductBundles } = useServiceCategories();
  const { data: matchingServices } = useServicesByEventTypeId(eventTypeId);

  /**
   * Create a list of matching service categories when all data is ready
   */
  React.useEffect(() => {
    if (!serviceProductBundles || !matchingServices || !allowedServiceIds) return;

    const options: IWordpressServiceProductBundleOption[] = matchingServices
      // filter allowed service ids
      .filter(item => allowedServiceIds.indexOf(item.id) > -1)
      ?.sort((a: IWordpressService, b: IWordpressService) => parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order))
      .map(service => {
        const category = serviceProductBundles.find(item => item.id === service['service-category'][0]);

        return {
          label: service.acf.claim_display_name || category.name,
          value: service.id,
          image: service.acf.taxonomy_svg_icon || category.acf.taxonomy_svg_icon,
        };
      });
    setOptions(options);
  }, [serviceProductBundles, matchingServices, allowedServiceIds]);

  const isLoading = !options;
  if (isLoading) return <LoadingSpinner center />;

  const hasSelection = !!value;
  const isHorizontal = hasSelection || !isMd;

  return (
    <SelectList<number>
      {...otherProps}
      value={value}
      multiple={multiple}
      options={options}
      onChange={(v: number) => {
        const selectedService = matchingServices.find(item => v === item.id);
        if (!selectedService) {
          throw new Error(`service id "${v}" not found`);
        }
        const category = serviceProductBundles.find(item => item.id === selectedService['service-category'][0]);
        onChange(v, selectedService, category);
      }}
      render={(item: IWordpressServiceProductBundleOption, selected: boolean) => {
        return (
          <EventServicesTile
            multiple={multiple}
            horizontal={isHorizontal || !isMd}
            selected={selected}
            compact={!!value}
            image={item.image}
            label={item.label}
            value={item.value}
          />
        );
      }}
      className={bem(null, [{ horizontal: value }, { vertical: !isHorizontal }, { hasSelection: value }], className)}
    />
  );
}
