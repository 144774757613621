import type { SWRConfiguration } from 'swr';

export const swrDefaults: SWRConfiguration = {
  refreshInterval: 0,
  // auto revalidate when window gets focused
  revalidateOnFocus: false,
  // enable or disable automatic revalidation when component is mounted
  // (by default revalidation occurs on mount when fallbackData is not set, use this flag to force behavior)
  revalidateOnMount: undefined,
  // automatically revalidate when the browser regains a network connection (via navigator.onLine)
  revalidateOnReconnect: false,
  // polling when the window is invisible
  refreshWhenOffline: false,
  // polling when the browser is offline
  refreshWhenHidden: false,
  // retry on errors (including timeouts)
  shouldRetryOnError: true,
};

export const SWRInfiniteDefaultPerPage = 10;
export const SWRInfiniteDefaultPerPageParamName = 'per_page';
