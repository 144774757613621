import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import type { RadioGroupContextProps } from './RadioGroupContext';

export interface RadioGroupProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  name?: string;
  value?: string;
  defaultValue?: string;
  inline?: boolean;
  onChange?: (value: string) => void;
}

export const RadioGroupContext = React.createContext<RadioGroupContextProps>(null);

export function RadioGroup({
  className,
  children,
  name,
  value,
  inline,
  defaultValue,
  onChange,
  ...otherProps
}: RadioGroupProps): React.ReactElement {
  const _value = value || defaultValue;

  const bem = useBemCN('radio-group');

  return (
    <RadioGroupContext.Provider
      value={{
        name,
        value: _value,
        onChange,
      }}
    >
      <div {...otherProps} {...bem(null, [{ inline }], className)}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
}
