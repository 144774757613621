import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { addEmailUserTags, emailUserTags } from '@gik/api/emailUsers/emailUsers';
import { usePrivacyPolicy, useTerms } from '@gik/api/legal';
import { useUser } from '@gik/api/users/user';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import type { RecipientType } from '@gik/create/enums/RecipientType';
import { aboutStepSchema } from '@gik/create/formSchemas';
import { useFormNavigationObserver } from '@gik/create/hooks/useFormNavigationObserver';
import { translationKeys } from '@gik/create/i18n/en';
import type { ICreatePageContext } from '@gik/create/interfaces/ICreatePageContext';
import { useCreateFlowStore } from '@gik/create/store/CreateFlowStore';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import type { FormProps } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import type { ValidationError } from '@gik/ui/Form/validation';
import type { FormikProps } from 'formik';
import React from 'react';

export interface IAboutStepProps extends FormProps {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: ICreateAboutFormValues) => void;
  onChange?: (values: ICreateAboutFormValues) => void;
  trackTermsOfService?: () => void;
  trackPrivacyPolicy?: () => void;
}

export interface IRecipientTypeCardProps {
  label: string;
  value: number;
  image: string;
  selected?: boolean;
}

export interface ICreateAboutFormValues {
  [AboutStepInputNames.RecipientType]: RecipientType;
  [AboutStepInputNames.Email]: string;
}

export interface ICreateAboutFormErrors {
  [AboutStepInputNames.RecipientType]?: ValidationError;
  [AboutStepInputNames.Email]?: ValidationError;
}

const blockName = 'about-step';
export const formId = 'CreateAboutStepForm';
export const legalDrawerLoading = 'legalDrawerLoading';

export default function AboutStep({
  className,
  buttons,
  buttonsPortal,
  trackTermsOfService,
  trackPrivacyPolicy,
  onSubmit,
  ...otherProps
}: IAboutStepProps) {
  const bem = bemBlock(blockName);
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);
  const schema = aboutStepSchema(!!userId);

  const { openLegalTextDrawer, legalTextDrawerType } = useCreateFlowStore(state => ({
    legalTextDrawerType: state.legalTextDrawerType,
    openLegalTextDrawer: state.openLegalTextDrawer,
  }));

  const shouldFetchTerms = legalTextDrawerType === 'termsOfService';
  const { data: termsArray } = useTerms(shouldFetchTerms);

  const shouldFetchPrivacyPolicy = legalTextDrawerType === 'privacyPolicy';
  const { data: privacyPolicyArray } = usePrivacyPolicy(shouldFetchPrivacyPolicy);

  React.useEffect(() => {
    const legalText = (termsArray || privacyPolicyArray)?.[0];
    const legalTitle = legalText?.title?.rendered;
    const legalContent = legalText?.content?.rendered;
    legalTitle && legalContent && openLegalTextDrawer(legalTitle, legalContent, legalTextDrawerType);
  }, [termsArray, privacyPolicyArray, legalTextDrawerType, openLegalTextDrawer]);

  const context = React.useContext<ICreatePageContext>(CreatePageContext) || ({} as ICreatePageContext);
  const { aboutFormRef } = context;

  function handleClickOpenTOS() {
    trackTermsOfService?.();
    openLegalTextDrawer(legalDrawerLoading, legalDrawerLoading, 'termsOfService');
  }

  function handleClickOpenPP() {
    trackPrivacyPolicy?.();
    openLegalTextDrawer(legalDrawerLoading, legalDrawerLoading, 'privacyPolicy');
  }

  function handleSubmit(values: ICreateAboutFormValues) {
    const emailUserId = values.emailAddress || user?.emailAddress;
    if (emailUserId) {
      addEmailUserTags({
        userEmail: emailUserId,
        tags: [emailUserTags.createPageReminders, 'page-for-' + values[AboutStepInputNames.RecipientType]],
      });
    }
    onSubmit(values);
  }

  function handleChangeRecipientType() {
    setTimeout(() => {
      // wait a second and then focus the email field if it hasn't been filled in yet
      const inputEl = aboutFormRef.current?.formEl?.querySelector(
        `input[name="${AboutStepInputNames.Email}"]`
      ) as HTMLInputElement;
      if (inputEl && !inputEl.value) inputEl.focus();
    }, 1000);
  }

  useFormNavigationObserver(aboutFormRef);

  return (
    <Form
      className={bem(null, null, className)}
      onSubmit={handleSubmit}
      schema={schema}
      vertical
      id={formId}
      ref={aboutFormRef}
      trackingId={AnalyticsEvents.CreateStarted}
      render={(form: FormikProps<object>) => {
        return (
          <>
            <FormField
              name={AboutStepInputNames.RecipientType}
              labelClassName={'gik-create-section-title'}
              onChange={handleChangeRecipientType}
              center
            />
            {!userId && (
              <FormField name={AboutStepInputNames.Email} labelClassName={'gik-create-section-title'} center />
            )}

            <p className={bem('terms-disclaimer')}>
              {i18n.t(translationKeys.TermsDisclaimer1)}{' '}
              <Button onClick={handleClickOpenTOS} variant="primary-link">
                {i18n.t(translationKeys.TermsDisclaimer2).toString()}
              </Button>{' '}
              {i18n.t(translationKeys.TermsDisclaimer3)}{' '}
              <Button onClick={handleClickOpenPP} variant="primary-link">
                {i18n.t(translationKeys.TermsDisclaimer4).toString()}
              </Button>
              {'. '}
              {i18n.t(translationKeys.TermsDisclaimer5)}
            </p>

            <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
          </>
        );
      }}
      {...otherProps}
    />
  );
}
