import type { EmbeddedVideoProps } from '../EmbeddedVideo';

export enum LightboxMedia {
  IMAGE = 'image',
  VIDEO = 'video',
}

export interface ILightboxBaseMediaType {
  mediaType: LightboxMedia;
  url: string;
  caption?: string;
}

export interface ILightboxImageMediaType extends ILightboxBaseMediaType {
  mediaType: LightboxMedia.IMAGE;
}

export interface ILightboxVideoMediaType extends ILightboxBaseMediaType {
  mediaType: LightboxMedia.VIDEO;
  videoProps?: Partial<EmbeddedVideoProps>;
}

export type LightboxMediaType = ILightboxImageMediaType | ILightboxVideoMediaType;
