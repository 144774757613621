import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import type { IPopoverMenuItemProps } from './PopoverMenuItem';
import { PopoverMenuItem } from './PopoverMenuItem';

export interface IPopoverMenuProps extends React.PropsWithChildren<{}> {
  className?: string;
}

export type PopoverMenuElements = {
  Item: React.FC<IPopoverMenuItemProps>;
};

export const PopoverMenu: React.FC<IPopoverMenuProps> & PopoverMenuElements = ({
  children,
  className,
  ...otherProps
}) => {
  const bem = useBemCN('popover-menu');

  return (
    <ul {...bem(null, null, className)} {...otherProps}>
      {children}
    </ul>
  );
};

PopoverMenu.Item = PopoverMenuItem;
