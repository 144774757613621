import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories } from '@gik/api/calendar/serviceProductBundle';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { CalendarUtil } from '@gik/calendar';
import { ResendButton } from '@gik/calendar/components/ResendButton/ResendButton';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import { canSeeClaimOwner, getRequestDetailsTabs } from '@gik/calendar/utils/CalendarClaimUtils';
import { useProducts } from '@gik/checkout/api';
import { anonymousName } from '@gik/checkout/consts';
import type { BaseUserWithAvatar } from '@gik/core/models/gik/User';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInPastAllTimezones } from '@gik/core/utils/DateTimeUtils';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import {
  isInkindPageOrganizer,
  isInkindPageOwner,
  useInkindPageOwnerIsRecipient,
} from '@gik/inkind-page/utils/inkindPagePermissions';
import { Avatar } from '@gik/ui/Avatar';
import { Button } from '@gik/ui/Button';
import { Decoder } from '@gik/ui/Decoder';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { LineClamp } from '@gik/ui/LineClamp';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { TabbedView } from '@gik/ui/TabbedView';
import { openUserInfoModal } from '@gik/user-profile/components/UserInfoModal/UserInfoModal';
import PencilIcon from '@heroicons/react/solid/PencilIcon';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarClaimHeader from './CalendarClaimHeader';
import { translationKeys } from './i18n/en';

export interface ICalendarClaimViewProps extends React.HTMLAttributes<HTMLDivElement> {
  event?: ICalendarEvent;
  entry: ICalendarEntry;
  buttonsPortal?: () => HTMLElement;
  onClose?(): void;
  onFailed?(): void;
  onSuccess?(): void;
  onEditSuccess?(): void;
  onUnclaim?(): void;
  onRefreshCalendar?(): void;
}

interface ISupporterInfoSectionProps {
  entry: ICalendarEntry;
  bottomBorder?: boolean;
}

function SupporterInfoSection({ entry, bottomBorder = false }: ISupporterInfoSectionProps) {
  const bem = bemBlock('calendar-claim-event');

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  function openSupporterInfoModal(supporter: BaseUserWithAvatar) {
    openUserInfoModal(supporter);
  }

  let claimerFullname: string = null;
  if (canSeeClaimOwner(entry, user, inkind)) {
    claimerFullname = supporter?.firstName || '';
    if (supporter?.lastName) {
      claimerFullname += ' ' + supporter?.lastName;
    }
  }

  return (
    <section className={bem('event-claim', [{ 'bottom-border': bottomBorder }])}>
      <Avatar imageUrl={supporter?.avatarThumbSmallURL} />
      <div>
        <span className={bem('provided-by')}>Provided by</span>
        <Button
          noFocusStyling={!canSeeClaimOwner(entry, user, inkind)}
          className={bem('provided-by-name')}
          variant={'default-extra-dark-link'}
          onClick={() => canSeeClaimOwner(entry, user, inkind) && openSupporterInfoModal(supporter)}
        >
          {canSeeClaimOwner(entry, user, inkind) ? <Decoder text={claimerFullname} /> : anonymousName}
        </Button>
      </div>
    </section>
  );
}

export default function CalendarClaimView({
  entry,
  event,
  className,
  buttonsPortal,
  onClose = noop,
  ...otherProps
}: ICalendarClaimViewProps): React.ReactElement {
  // FIXME: block name is wrongly named, CSS properties are being inherited from another component
  const bem = bemBlock('calendar-claim-event');
  const { t } = useTranslation();

  const claim = entry.claim;

  const [buttonPortalRef, setButtonPortalRef] = React.useState<HTMLElement>();

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const { data: services } = useServices();
  const { data: serviceCategories } = useServiceCategories();

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  const eventType = calendarEventTypes?.find(type => type.id === entry.typeId);

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId);
  const isLoading = !calendarEventTypes || !services || !serviceCategories || !buttonPortalRef;

  const isClaimer = userId && supporter?.id === userId;
  const isPastEvent = React.useMemo(() => isInPastAllTimezones(moment.utc(event.startsAt)), [event]);

  React.useEffect(() => {
    // trigger a rerender when the button portal resolves to an html element
    setButtonPortalRef(buttonsPortal());
  }, [buttonPortalRef, buttonsPortal]);

  const hasContactInformation = React.useCallback(function hasContactInformation(typeId: number) {
    return (
      typeId === CalendarEventTypeIds.PetCare ||
      typeId === CalendarEventTypeIds.ChildCare ||
      typeId === CalendarEventTypeIds.SeniorCare ||
      typeId === CalendarEventTypeIds.HomeServices ||
      typeId === CalendarEventTypeIds.Transportation
    );
  }, []);

  const handleEditEvent = React.useCallback(
    function handleEditEvent() {
      onClose();
      setTimeout(() => {
        CalendarUtil.edit(event, entry);
      }, 500);
    },
    [entry, event, onClose]
  );

  const handleEditClaim = React.useCallback(
    function handleEditClaim() {
      onClose();
      setTimeout(() => {
        CalendarUtil.claim(event, entry, inkind.routeId);
      }, 500);
    },
    [entry, event, inkind, onClose]
  );

  // TODO: only show note if there is a page organizer that is also the recipient of the inkind page.
  const ownerIsRecipient = useInkindPageOwnerIsRecipient(inkind);
  const showNotetoRecipient = canEdit && ownerIsRecipient;

  const requestDetailTabs = getRequestDetailsTabs(entry, canEdit, isClaimer);

  const contactInfo = hasContactInformation(entry.typeId);

  const petCareEventType = calendarEventTypes.find(item => item.id === CalendarEventTypeIds.PetCare);
  const petCareType = petCareEventType?.acf.pet_care_types?.find(item => item.id === entry.petCareTypeId?.toString());

  function renderlabel() {
    return (
      <>
        {entry.typeId === CalendarEventTypeIds.SeniorCare &&
          entry.claim.serviceSlug === 'phone-call-adult-senior-care' && (
            <span className={bem('claim-type-title')}>Phone Call</span>
          )}
        {petCareType && entry.typeId === CalendarEventTypeIds.PetCare && (
          <span className={bem('claim-type-title')}>{petCareType?.name} </span>
        )}
        {!contactInfo && (
          <p className={bem('claim-description')}>
            <HTMLParser rawHtml={claim.description} />
          </p>
        )}
        {(canEdit || isClaimer) && (
          <>
            {contactInfo && claim.description && (
              <>
                <span className={bem('claim-description-title')}>Contact Info: </span>
                <p className={bem('claim-description')}>
                  <HTMLParser rawHtml={claim.description} />
                </p>
              </>
            )}
            {entry.typeId === CalendarEventTypeIds.Transportation && claim.carType && (
              <>
                <span className={bem('claim-description-title')}>
                  {isClaimer ? 'You' : 'They'}&apos;ll be arriving in:{' '}
                </span>
                <p className={bem('claim-description')}>
                  <HTMLParser rawHtml={claim.carType} />
                </p>
              </>
            )}
          </>
        )}
      </>
    );
  }

  let _buttons = (
    <div className={bem('buttons')}>
      <section>
        {isClaimer && !isPastEvent && (
          <Button variant="secondary" onClick={handleEditClaim} prepend={<SvgIcon Icon={PencilIcon} />}>
            Edit Claim
          </Button>
        )}
      </section>
      <section>
        {canEdit && !isPastEvent && (
          <Button variant="secondary" onClick={handleEditEvent} prepend={<SvgIcon Icon={PencilIcon} />}>
            Edit Request
          </Button>
        )}
      </section>
    </div>
  );

  if (canEdit && !isClaimer && !isPastEvent) {
    _buttons = (
      <>
        <Button wide variant="secondary" onClick={handleEditEvent} prepend={<SvgIcon Icon={PencilIcon} />}>
          Edit Request
        </Button>
      </>
    );
  }

  if ((!canEdit && !isClaimer) || entry.claim.serviceCategorySlug === 'gift-card') {
    _buttons = (
      <>
        <Button wide variant="primary" onClick={onClose}>
          Close
        </Button>
      </>
    );
  }

  const cartItem = entry.claim?.cartItems?.length ? entry.claim.cartItems[0] : null;
  const { data: products } = useProducts({ productIds: [cartItem?.productId] });

  const isPhysical = React.useMemo(() => {
    return (
      cartItem?.productId &&
      products?.[0]?.variations
        .find(variation => variation.id === cartItem?.variationId)
        ?.metaData?.find(m => m.key === `pg_product_type` && m.value === 'physical') != null
    );
  }, [cartItem, products]);

  const customMoreButton = (clamped: boolean) => (
    <Button variant="secondary" size="xs" pill className={bem('btn-more')}>
      <>
        {clamped && t(translationKeys.moreButton)}
        {!clamped && t(translationKeys.lessButton)}
      </>
    </Button>
  );

  const isPageOwnerOrOrganizer =
    isInkindPageOwner(inkind.owner.userId, userId) || isInkindPageOrganizer(inkind, userId);

  if (isLoading) {
    return <LoadingSpinner center />;
  }

  return (
    <div className={bem(null, null, [className, `gik-calendar-event-type-theme--${eventType?.slug}`])} {...otherProps}>
      <CalendarClaimHeader entry={entry} event={event} eventType={eventType} />

      {entry.claim.serviceCategorySlug === 'gift-card' ? (
        <>
          <div className={bem('giftcard-view')}>
            <div className={bem('image-container')}>
              <Image
                src={cartItem?.gridImage || cartItem?.thumbnailUrl || cartItem?.imageUrl}
                alt="Gift card thumbnail"
              />
              {isPageOwnerOrOrganizer && (
                <ResendButton
                  className={bem('resend')}
                  inkindRouteId={inkind.routeId}
                  entry={entry}
                  physical={isPhysical}
                />
              )}
            </div>
            <main>
              <SupporterInfoSection entry={entry} />
            </main>
          </div>
        </>
      ) : (
        <>
          <main>
            <SupporterInfoSection entry={entry} bottomBorder />
            {renderlabel()}
            {showNotetoRecipient && claim.note && (
              <div className={bem('notes')}>
                <span className={bem('notes-title')}>PERSONAL NOTE</span>

                <div className={bem('notes-description')}>
                  <LineClamp customButton={customMoreButton} lines={2} fadeOut={false} horizontal>
                    <HTMLParser rawHtml={claim.note} />
                  </LineClamp>
                </div>
              </div>
            )}

            <TabbedView tabs={requestDetailTabs} />
          </main>
        </>
      )}

      {renderPortal(_buttons, buttonsPortal)}
      {/* {claimConflict && <ClaimFailedMessage />} */}
    </div>
  );
}
