import { GiftCardClaimConflictResolution } from '@gik/calendar';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { dateDisplayFormat } from '@gik/l10n';
import { Button } from '@gik/ui/Button';
import moment from 'moment';
import React from 'react';
import type { PaymentConfirmationValues } from '../../types';
import { PaymentConfirmation } from './PaymentConfirmation';

export interface ClaimPaymentConfirmationProps extends React.HTMLAttributes<HTMLDivElement> {
  values: PaymentConfirmationValues;
  productName: string;
  shipping?: boolean;
  purchasedClaimedItem: boolean;
  purchasedDeletedItem: boolean;
  eventDate: string;
  entry: ICalendarEntry;
  event: ICalendarEvent;
  eventType: IWordpressCalendarEventType;
  inkindRouteId?: string;
  buttonsPortal?: () => HTMLElement;
  onDone?: () => void;
  onSuccess?: () => void;
  closeButtonOverrideRef?: React.MutableRefObject<() => Promise<boolean>>;
  setModalClosable?(closable: boolean): void;
}

export function ClaimPaymentConfirmation({
  className,
  values,
  productName,
  shipping,
  eventType,
  eventDate,
  purchasedClaimedItem,
  purchasedDeletedItem,
  entry,
  event,
  buttonsPortal,
  onDone = noop,
  onSuccess,
  closeButtonOverrideRef,
  setModalClosable,
  ...otherProps
}: ClaimPaymentConfirmationProps): React.ReactElement {
  const bem = useBemCN('claim-payment-confirmation');
  // const header = <CalendarClaimHeader {...bem('header')} entry={entry} eventType={eventType} event={event} />;

  const { claimConflicts, goneConflicts, resolveGiftCardClaimConflictValues } = useCalendarStore(state => ({
    claimConflicts: state.claimConflicts,
    goneConflicts: state.goneConflicts,
    resolveGiftCardClaimConflictValues: state.resolveGiftCardClaimConflictValues,
  }));

  const _buttons = (
    <div className="gik-form__actions-new gik-form__actions-new--centered">
      <Button onClick={onDone}>Done</Button>
    </div>
  );

  const renderFulfillmentDescription = React.useCallback(
    (fulfillmentTimeString: string) => (
      <>
        {claimConflicts
          ?.filter(c => c.availableDates?.length > 0)
          ?.map(conflict => {
            return (
              <>
                <br />
                <br />
                Someone claimed the {moment(conflict.createClaimRequest.claimDate).format(dateDisplayFormat)} request
                just before you did. Your gift card has still been sent, however it will not be visible on the calendar.
                If you wish to request a refund, please contact support within 24 hours.
              </>
            );
          })}
        {goneConflicts
          ?.filter(c => c.availableDates?.length > 0)
          ?.map(conflict => {
            return (
              <>
                <br />
                <br />
                It looks like the {moment(conflict.createClaimRequest.claimDate).format(dateDisplayFormat)} request was
                deleted by an organizer of this page. Your gift card has still been sent, however it will not be visible
                on the calendar. If you wish to request a refund, please contact support within 24 hours.
              </>
            );
          })}
      </>
    ),
    [claimConflicts, goneConflicts]
  );

  if (resolveGiftCardClaimConflictValues && (claimConflicts?.length > 0 || goneConflicts?.length > 0)) {
    return (
      <GiftCardClaimConflictResolution
        resolveGiftCardClaimConflictValues={resolveGiftCardClaimConflictValues}
        buttonsPortal={buttonsPortal?.()}
        onSuccess={onSuccess}
        closeButtonOverrideRef={closeButtonOverrideRef}
        setModalClosable={setModalClosable}
      />
    );
  }

  return (
    <>
      <PaymentConfirmation
        values={values}
        productName={productName}
        shipping={shipping}
        renderFulfillmentDescription={renderFulfillmentDescription}
        {...bem(undefined, undefined, className)}
        {...otherProps}
      />
      {renderPortal(_buttons, buttonsPortal)}
    </>
  );
}
