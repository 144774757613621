export * from 'react-table';
export * from './KeyValueTable';
export * from './StickyTable';
export * from './Table';
export * from './TableFilters';
import type { CSSProperties } from 'react';
import type { Column, ColumnInterface } from 'react-table';

export interface ColumnStyleInterface {
  style: CSSProperties;
}

export type ColumnWithStyle<D extends object = {}> = ColumnInterface<D> &
  ColumnStyleInterface & {
    style: CSSProperties;
  };

export type GIKColumn<D extends object = {}> = Column<D> | ColumnWithStyle<D>;
