import { logger } from '@gik/analytics/utils/logger';
import { errorLogger } from '@gik/core/api/base/hooks/errorLogger';
import { timers } from '@gik/core/utils/TimerUtil';

export const errorLoggerKyHook = (request: Request, _options, response: Response) => {
  const requestId = request.headers.get('x-browser-request-id');

  if (timers[requestId]) {
    const timeElapsed = new Date().getMilliseconds() - timers[requestId].getMilliseconds();

    const message = `request to ${request.method} ${request.url} took ${timeElapsed}ms`;

    if (timeElapsed >= 10000) {
      logger.error(message);
    } else if (timeElapsed >= 2000) {
      logger.warn(message);
    }
  }

  errorLogger(response, request);
};
