import type { ICalendarEventClaimCreatePayload } from '@gik/api/calendar/calendarClaims';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { User } from '@gik/core/models/gik/User';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';
import { isFoodType } from '../components/EventForm/utils';
import type { ICalendarEntry, ICalendarEvent } from '../models/Calendar';
import { CalendarEventTypeIds } from '../models/CalendarEventTypes';

export function getRequestDetailsTabs(entry: ICalendarEntry, canEdit?: boolean, isClaimer?: boolean) {
  const requestDetailTabs = [
    {
      title: `Description`,
      content: entry.description ? (
        <div>
          <HTMLParser rawHtml={entry.description} />
        </div>
      ) : (
        <div className="gik-text-muted">{'No description was provided for this request.'}</div>
      ),
    },
  ];

  if (isFoodType(entry.typeId)) {
    requestDetailTabs.push({
      title: `Delivery Instructions`,
      content: entry.deliveryInstructions ? (
        <div>
          <HTMLParser rawHtml={entry.deliveryInstructions} />
        </div>
      ) : (
        <div className="gik-text-muted">{'No delivery instructions were provided for this request.'}</div>
      ),
    });
  }

  if (canEdit || isClaimer) {
    if (entry.emergencyContactInfo) {
      const title = entry.typeId === CalendarEventTypeIds.SeniorCare ? `Contact Info` : `Emergency Contact`;
      requestDetailTabs.push({
        title,
        content: (
          <div>
            <HTMLParser rawHtml={entry.emergencyContactInfo} />
          </div>
        ),
      });
    }
    if (entry.pickupLocation) {
      requestDetailTabs.push({
        title: `Pickup`,
        content: (
          <div>
            <HTMLParser rawHtml={entry.pickupLocation} />
          </div>
        ),
      });
    }
    if (entry.dropoffLocation) {
      requestDetailTabs.push({
        title: `Dropoff`,
        content: (
          <div>
            <HTMLParser rawHtml={entry.dropoffLocation} />
          </div>
        ),
      });
    }
  }

  return requestDetailTabs;
}

export function canSeeClaimOwner(entry: ICalendarEntry, user: User, inkind: InkindPageAPIModel) {
  if (!entry) return false;

  const isUserPageRecipient = user != null && user.emailAddress == inkind.recipientEmail;
  const isUserPO = user != null && inkind.organizers.find(organizer => organizer.userId == user.id);
  const isUserClaimOwner = user != null && user.id == entry.claim?.supporterId;
  const canUserSeePrivateClaimerInfo = isUserPageRecipient || isUserPO || isUserClaimOwner;

  return entry.claim && (!entry.claim?.privateClaim || (entry.claim?.privateClaim && canUserSeePrivateClaimerInfo));
}

export function createClaimRequestFromClaimEvent(
  event: ICalendarEvent,
  serviceSlug?: string
): ICalendarEventClaimCreatePayload {
  if (!event) return null;

  return {
    calendarEntryId: event.entryId,
    claimDate: event.startsAt,
    serviceSlug,
  };
}
