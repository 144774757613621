import type { IOAuthUserLoginResponse, IUserLoginResponse } from '@gik/api/users/types';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import { OAuthErrorModalContent } from '@gik/auth/components/Login/OAuthErrorModalContent';
import type { ExternalLoginProvider, LoginProvider } from '@gik/auth/utils/LoginProvider';
import { LocalLoginProvider } from '@gik/auth/utils/LoginProvider';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';
import type { IUserLoginFormValues } from './LoginForm';
import { LoginForm } from './LoginForm';

const blockName = 'login-form-section';

export interface ILoginFormSectionProps {
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (values: IUserLoginResponse | IOAuthUserLoginResponse) => void;
  onForgotPassword?: () => void | Promise<void>;
  onSignup?: () => void | Promise<void>;
  oauthErrorMessage?: string;
  initialValues?: Partial<IUserLoginFormValues>;
  provider?: LoginProvider;
}

function LoginFormSectionComp({
  buttonsPortal,
  onSuccess,
  onForgotPassword,
  onSignup,
  oauthErrorMessage: initialOauthErrorMessage,
  initialValues,
  provider,
}: ILoginFormSectionProps) {
  const [oauthErrorMessage, setErrorMessage] = React.useState<string>();

  const bem = bemBlock(blockName);
  const { t } = useTranslation();

  // TODO: GIK-2477 show error message with the option that matches the correct provider
  const errorMessageButtons = (
    <>
      <Button block onClick={() => setErrorMessage(undefined)}>
        Try again
      </Button>
    </>
  );
  // FIXME: I had to do this to trigger a re-render, as buttonsPortal() returns undefined on first render
  React.useEffect(() => {
    setErrorMessage(initialOauthErrorMessage);
  }, [initialOauthErrorMessage]);

  return (
    <section className={bem()}>
      <OAuthErrorModalContent
        errorMessageButtons={errorMessageButtons}
        oauthErrorMessage={oauthErrorMessage}
        buttonsPortal={buttonsPortal}
      />
      {!oauthErrorMessage && (
        <>
          {(!provider || provider !== LocalLoginProvider.EMAIL) && (
            <ExternalLoginForm onSuccess={onSuccess} filter={provider as ExternalLoginProvider} />
          )}
          {!provider && <div className={bem('title')}>{t(translationKeys.formTitle).toString()}</div>}
          {(!provider || provider === LocalLoginProvider.EMAIL) && (
            <LoginForm
              buttonsPortal={buttonsPortal}
              onSuccess={onSuccess}
              onForgotPassword={onForgotPassword}
              onSignup={onSignup}
              initialValues={initialValues}
            />
          )}
        </>
      )}
    </section>
  );
}

export const LoginFormSection = withComponentErrorBoundary(LoginFormSectionComp);
