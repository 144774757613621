import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import React from 'react';
import type { TooltipRenderProps } from 'react-joyride';

const bem = bemBlock('joyride-tooltip');

export interface IJoyrideTooltipProps extends TooltipRenderProps {
  showSkipButton: boolean;
}

function JoyrideTooltipComp({
  continuous,
  index,
  step,
  skipProps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  showSkipButton,
  isLastStep,
}: IJoyrideTooltipProps) {
  const isFirstStep = index === 0;

  return (
    <div className={bem()} {...tooltipProps}>
      {step.title && <span className={bem('title')}>{step.title}</span>}
      <p className={bem('content')}>{step.content}</p>

      <div className={bem('actions')}>
        <div>
          {showSkipButton && (
            <Button size="sm" circle variant="default-light" className={bem('btn-skip')} {...skipProps}>
              <SvgIcon Icon={CloseIcon} />
            </Button>
          )}
        </div>

        <div className={bem('actions-right')}>
          {index > 0 && (
            <Button circle variant="white" className={bem('btn-back')} {...backProps}>
              <SvgIcon Icon={ChevronLeftIcon} />
            </Button>
          )}
          {!isLastStep && continuous && (
            <Button circle variant="white" className={bem('btn-next')} {...primaryProps}>
              <SvgIcon Icon={ChevronRightIcon} />
            </Button>
          )}
          {!isLastStep && !continuous && (
            <Button circle variant="white" className={bem('btn-close', [{ flashing: isFirstStep }])} {...closeProps}>
              <SvgIcon Icon={ChevronRightIcon} />
            </Button>
          )}
          {isLastStep && (
            <Button circle variant="primary" {...closeProps}>
              <SvgIcon Icon={CheckIcon} className={bem('check-icon')} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export const JoyrideTooltip = React.memo(
  JoyrideTooltipComp,
  (prevProps, nextProps) => prevProps.index === nextProps.index
);
