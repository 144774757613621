import { useSuggestedProducts } from '@gik/checkout/api';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { DonationsValues, WishlistValues } from '@gik/create';
import { CareCalendarCard, createWrapperBlockName, DonationsCard, WishlistCard } from '@gik/create';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { UI } from '@gik/ui/UIManager';
import React from 'react';

export type CreateValues = {
  careCalendarEnabled: boolean;
  wishlistValues: WishlistValues;
  donationValues: DonationsValues;
};

export interface ICreateWrapperProps {
  values: CreateValues;
  onChange?: (values: CreateValues) => void;
  selectedSituationIds: number[];
}

export default function CreateWrapper({ values, onChange = noop, selectedSituationIds }: ICreateWrapperProps) {
  const bem = bemBlock(createWrapperBlockName);

  const { data: suggestedProducts } = useSuggestedProducts(selectedSituationIds);

  function notifyCantDisableAll() {
    UI.notify(i18n.t(translationKeys.CantDisableAll));
  }

  function isCareCalendarDisabled() {
    return !values.wishlistValues.enabled && !values.donationValues.enabled;
  }

  function isWishlistDisabled() {
    return !values.careCalendarEnabled && !values.donationValues.enabled;
  }

  function isDonationDisabled() {
    return !values.careCalendarEnabled && !values.wishlistValues.enabled;
  }

  function handleClickCareCalendar(careCalendarEnabled: boolean) {
    if (!isCareCalendarDisabled()) {
      onChange({
        ...values,
        careCalendarEnabled,
      });
    } else {
      notifyCantDisableAll();
    }
  }

  function handleClickWishlist(wishlistValues: WishlistValues) {
    if (!isWishlistDisabled()) {
      onChange({
        ...values,
        wishlistValues,
      });
    } else {
      notifyCantDisableAll();
    }
  }

  function handleClickDonations(donationValues: DonationsValues) {
    if (!isDonationDisabled()) {
      onChange({
        ...values,
        donationValues,
      });
    } else {
      notifyCantDisableAll();
    }
  }

  return (
    <section className={bem()}>
      <p className={bem('title')}>{i18n.t(translationKeys.WrapperTitle).toString()}</p>
      <p className={bem('description')}>{HTMLParser({ rawHtml: i18n.t(translationKeys.WrapperDescription) })}</p>

      <CareCalendarCard
        className={bem('feature-card')}
        value={values.careCalendarEnabled}
        onChange={handleClickCareCalendar}
        inputName="isCalendar"
        disabled={isCareCalendarDisabled()}
      />

      <WishlistCard
        className={bem('feature-card')}
        suggest={true}
        suggestedProducts={suggestedProducts}
        value={values.wishlistValues}
        onChange={handleClickWishlist}
        inputName={'wishlist.selected'}
        disabled={isWishlistDisabled()}
      />

      <DonationsCard
        className={bem('feature-card')}
        value={values.donationValues}
        onChange={handleClickDonations}
        inputName={'financialContributions.selected'}
        disabled={isDonationDisabled()}
      />
    </section>
  );
}
