import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { InputProps } from './Input';
import { Input, inputBlockName } from './Input';

export interface InputNumberProps extends InputProps {
  formatProps?: NumberFormatProps;

  onFormattedValueChange?(value: NumberFormatValues): void;
}

// block name for this component
const blockName = `${gikClassPrefix}-input-number`;

/**
 * GIK Input component for formatted numbers
 */
export function InputNumber({
  className,
  onValueChange,
  onFormattedValueChange,
  formatProps = {},
  ...otherProps
}: InputNumberProps): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);

  // use thousands separator by default
  if (formatProps.thousandSeparator === undefined) formatProps.thousandSeparator = true;
  // also pass the placeholder to the NumberFormat component
  formatProps.placeholder = otherProps.placeholder;
  formatProps.disabled = otherProps.disabled;

  console.log('formatProps', formatProps);

  function handleValueChange(values: NumberFormatValues): void {
    if (onFormattedValueChange) onFormattedValueChange(values);
    if (onValueChange) onValueChange(values.value);
  }

  delete otherProps.onChange;

  // prettier-ignore
  // prettier will condense this... remove when more props are added
  return (
    <Input
      className={blockClasses}
      customInput={(customProps) =>
        <NumberFormat
          className={`${inputBlockName}__input`}
          onValueChange={handleValueChange}
          {...customProps}
          value={customProps.value ?? ""}
          {...formatProps}
        />
      }
      {...otherProps}
    />
  );
}
