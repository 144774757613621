import { useCalendarEventTypes } from '@gik/api/calendar/calendarEventType';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { isGiftCardServiceModule } from '@gik/calendar/constants/services/ServiceModule';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds, CalendarServiceCategorySlugs } from '@gik/calendar/models/CalendarEventTypes';
import { canSeeClaimOwner } from '@gik/calendar/utils/CalendarClaimUtils';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Image } from '@gik/ui/Image';
import { Text } from '@gik/ui/Text/Text';
import React from 'react';
import { AddToCalendarButton } from '../AddToCalendarSelector/AddToCalendarButton';
import DateTimeDisplay from '../DateTimeDisplay/DateTimeDisplay';
import { isFoodType } from '../EventForm/utils';

export interface ICalendarClaimHeaderProps extends React.HTMLAttributes<HTMLElement> {
  event: ICalendarEvent;
  entry: ICalendarEntry;
  eventType: IWordpressCalendarEventType;
  serviceSlug?: string;
  serviceCategorySlug?: string;
  showServiceType?: boolean;
  showPetCareType?: boolean;
  hideTime?: boolean;
}

export default function CalendarClaimHeader({
  entry,
  event,
  eventType,
  className,
  serviceSlug,
  serviceCategorySlug,
  showServiceType,
  showPetCareType,
  hideTime,
  ...otherProps
}: ICalendarClaimHeaderProps): React.ReactElement {
  const bem = bemBlock('calendar-claim-header');

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);
  const userId = useUserStore(state => state.id);

  const { data: user } = useUser(userId);
  const { data: supporter } = useUser(canSeeClaimOwner(entry, user, inkind) ? entry.claim.supporterId : null);

  const { data: calendarEventTypes } = useCalendarEventTypes();
  const petCareEventType = calendarEventTypes?.find(item => item.id === CalendarEventTypeIds.PetCare);
  const petCareType =
    eventType === petCareEventType &&
    petCareEventType?.acf.pet_care_types.find(item => item.id === entry.petCareTypeId?.toString());

  const isGiftCardClaim = entry.claim && isGiftCardServiceModule(entry.claim.serviceModule);
  const isClaimer = userId && supporter?.id === userId;

  function getAllowedServiceTitle() {
    switch (serviceCategorySlug) {
      case CalendarServiceCategorySlugs.ProvideMyself:
        if (isFoodType(eventType.id) || eventType.id === CalendarEventTypeIds.Groceries) {
          return 'Drop-Off Only';
        } else {
          if (serviceSlug === 'phone-call-adult-senior-care') {
            return 'Phone Calls Only';
          } else {
            return 'In-Person Only';
          }
        }

      case CalendarServiceCategorySlugs.LocalRestaurant:
        return 'Meal Delivery Only';
      case CalendarServiceCategorySlugs.GiftCard:
        return 'Gift Cards Only';
      default:
        return 'Unknown Service Type';
    }
  }

  const iconClasses = bem('icon', [
    {
      '2-lines': showServiceType || (showPetCareType && petCareType),
    },
  ]);

  return (
    <header className={bem(null, null, className)} {...otherProps}>
      <section className={bem('label-content')}>
        <div className={iconClasses}>
          {entry.typeId === CalendarEventTypeIds.Other ? (
            <Image inline src={eventType?.acf.taxonomy_svg_icon_alt} />
          ) : (
            <Image inline src={eventType?.acf.taxonomy_svg_icon} />
          )}
        </div>

        <div className={bem('label')}>
          <span className={bem('title')}>
            <Text>{entry.typeId === CalendarEventTypeIds.Other ? entry.title : eventType?.name}</Text>
          </span>

          {entry.numberRequired && <span className={bem('number-of-people')}> for {entry.numberRequired}</span>}
          {showServiceType && <div className={bem('allowedService')}>{getAllowedServiceTitle()}</div>}
          {showPetCareType && petCareType && <div className={bem('allowedService')}>{petCareType.name}</div>}
        </div>
      </section>
      <section className={bem('date-content')}>
        {isClaimer && !isGiftCardClaim && entry.claim && (
          <AddToCalendarButton text="Add" tooltip="Add to calendar" links={entry.claim?.addToCalendarLinks} />
        )}

        <DateTimeDisplay
          shortTimes
          startsAt={event.startsAt}
          endsAt={event.endsAt}
          allDay={entry.allDay}
          hideTime={hideTime}
          dateTimeSpacer={false}
        />
      </section>
    </header>
  );
}
