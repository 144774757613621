import { useInkindOrganizers } from '@gik/api/inkinds/inkind';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import React from 'react';

/**
 * Check if a user is the owner of the inkind page.
 */
export function isInkindPageOwner(ownerId: string, userId: string): boolean {
  return userId !== undefined && ownerId === userId;
}

type InkindPageOrganizer = { organizers?: { userId: string }[] };
/**
 * Check if a user is an organizer of the inkind page.
 */
export function isInkindPageOrganizer(inkindPage: InkindPageOrganizer, userId: string): boolean {
  return userId !== undefined && inkindPage?.organizers?.findIndex(o => o.userId === userId) >= 0;
}

type InkindPageOwnerOrOrganizer = Required<Pick<InkindPageAPIModel, 'owner' | 'organizers'>>;

export function inkindPageOwnerIsRecipient(inkindPage: InkindPageAPIModel): boolean {
  return (
    inkindPage &&
    (inkindPage.owner.email === inkindPage.recipientEmail ||
      inkindPage.organizers.findIndex(organizer => organizer.email === inkindPage.recipientEmail) > -1)
  );
}

export function useInkindPageOwnerIsRecipient(inkindPage: InkindPageAPIModel) {
  const { data: organizerDetails } = useInkindOrganizers(inkindPage.routeId);

  return React.useMemo(() => {
    if (!inkindPage) return null;

    const owner = organizerDetails?.find(org => org.userId === inkindPage.owner.userId);

    const ownerIsRecipient = inkindPage.recipientEmail === owner?.email;
    return ownerIsRecipient;
  }, [inkindPage, organizerDetails]);
}

export function isUserFollowingPage(
  inkindPage: InkindPageAPIModel,
  userId: string,
  userPages: InkindPageAPIModel[]
): boolean {
  return userId && inkindPage && userPages && !!userPages?.find(page => page.routeId === inkindPage.routeId);
}
