import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSearchParams,
  StrapiSingleDataSource,
} from '../types/Response';
import BaseResource from './base';
import { giftWalletEndPoints } from './endPoints';
import type {
  GiftEntity,
  GiftSendThankYouMessagePayload,
  SendEGiftEmailPayload,
  GiftActivityLog,
  GiftHistory,
} from '../types/Gift';
import type { GyCreditTransaction } from '@gik/giftya-api-js/types/api.transaction.types';
import type { GyGiftEntity } from '@gik/giftya-api-js/types/api.gift.types';
import type { GyEgiftEntity } from '@gik/giftya-api-js/types/api.egift.types';
import type { GyLinkNewCardsResponse } from '@gik/giftya-api-js/types/api.card.types';

const gift = giftWalletEndPoints.gift;

type Entity = GiftEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<GiftEntity>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class GiftResource extends BaseResource {
  /**
   * Strapi list
   */
  list(params: StrapiSearchParams, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.list(), {
      ...options,
      params: {
        ...params,
        ...options?.params,
      },
    });
  }

  /**
   * Strapi get
   */
  // get(id: string, options?: AxiosRequestConfig) {
  //   return this.client.get<GiftEntity, SingleResponseType>(gift.get(id), options);
  // }

  /**
   * Strapi get detailed
   */
  getDetailed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, SingleResponseType>(gift.getByUuidDetailed(id), options);
  }

  /**
   * Strapi create
   */
  // create(payload: Entity, options?: AxiosRequestConfig) {
  //   return this.client.post<SingleDataType, SingleResponseType>(gift.create(), payload, options);
  // }

  /**
   * Strapi update
   */
  // update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
  //   return this.client.put<SingleDataType, SingleResponseType>(gift.update(id), payload, options);
  // }

  /**
   * Strapi delete
   */
  // delete(id: string, options?: AxiosRequestConfig) {
  //   return this.client.delete<SingleDataType, SingleResponseType>(gift.delete(id), options);
  // }

  /**
   * Strapi get by uuid
   */
  getByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, SingleResponseType>(gift.getByUuid(id), options);
  }

  getActivityLog(giftId: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, StrapiPagedDataSource<GiftActivityLog>>(
      gift.getActivityLog(giftId),
      options
    );
  }

  getGiftHistory(giftId: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, StrapiPagedDataSource<GiftHistory>>(gift.getGiftHistory(giftId), options);
  }

  getCardTransactionHistoryByGyGiftid(gyGiftId: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, PlatformManagementApiResponse<StrapiPagedDataSource<GyCreditTransaction>>>(
      gift.getCardTransactionHistoryByGyGiftid(gyGiftId),
      options
    );
  }

  listByCustomerIdRedeemableGrouped(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(
      gift.listByCustomerIdRedeemableGrouped(id),
      options
    );
  }

  listByCustomerIdRedeemable(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdRedeemable(id), options);
  }

  listByCustomerIdMore(id: string, baseGiftSourceProductId: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(
      gift.listByCustomerIdMore(id, baseGiftSourceProductId),
      options
    );
  }

  listByCustomerIdUnused(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdUnused(id), options);
  }

  listByCustomerIdActive(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdActive(id), options);
  }

  listByCustomerIdUsed(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdUsed(id), options);
  }

  listByCustomerIdActiveLinked(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdActiveLinked(id), options);
  }

  listByCustomerIdUsedLinked(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerIdUsedLinked(id), options);
  }

  listByCustomerIdLinkedToCard(id: string, cardId: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(
      gift.listByCustomerIdLinkedToCard(id, cardId),
      options
    );
  }

  usePaymentCardsByCustomerId(customerId: string, cardId: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(
      gift.usePaymentCardsByCustomerId(customerId, cardId),
      options
    );
  }

  listByCustomerId(id: string, options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(gift.listByCustomerId(id), options);
  }

  /**
   * Get remote gift details
   */
  async getRemoteGift(uuid: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyGiftEntity>>(
      gift.getRemoteGift(uuid),
      options
    );

    return response;
  }

  async getRemoteEGift(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<null, StrapiSingleDataSource<GyEgiftEntity>>(
      gift.getRemoteEGift(id),
      options
    );

    return response;
  }

  /**
   * Strapi create
   */
  cancel(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, boolean>(gift.cancel(id), undefined, options);
  }

  sendEmail(id: string, payload: Partial<SendEGiftEmailPayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<boolean>>(gift.sendEmail(id), payload, options);
  }

  resendLegacyGift(id: string, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<boolean>>(gift.resendLegacyGift(id), options);
  }

  sendThankYouMessage(id: string, payload: Partial<GiftSendThankYouMessagePayload>, options?: AxiosRequestConfig) {
    return this.client.post<null, PlatformManagementApiResponse<StrapiSingleDataSource<GiftEntity>>>(
      gift.sendThankYouMessage(id),
      payload,
      options
    );
  }

  markAsUsedByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.markAsUsedByUuid(id),
      undefined,
      options
    );
  }

  markAsUnusedByUuid(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.markAsUnusedByUuid(id),
      undefined,
      options
    );
  }

  unlinkFromCard(id: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.unlinkFromCard(id),
      undefined,
      options
    );
  }

  getCardLinkingSession(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<GyLinkNewCardsResponse>>(
      gift.getCardLinkingSession(giftUuid),
      undefined,
      options
    );
  }

  invalidateCardLinkingSession(sessionid: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.invalidateCardLinkingSession(sessionid),
      undefined,
      options
    );
  }

  reactivateCard(giftUuid: string, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, PlatformManagementApiResponse<boolean>>(
      gift.reactivateCard(giftUuid),
      undefined,
      options
    );
  }
}
