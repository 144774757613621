import { useInkind, useInkindOrganizers } from '@gik/api/inkinds/inkind';
import { optimisticUpdate } from '@gik/core/api';
import type { InkindOrganizerSettings, Organizer, OrganizerInvitation } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitleAndDescription } from '../utils/SectionTitleAndDescription';
import {
  cancelOrganizerInvitation,
  inviteUserAsPageOrganizer,
  removePageOrganizer,
  setOrganizerSettings,
  setOwnerSettings,
  usePagePendingInvitees,
} from './api/organizers';
import { InviteSection } from './InviteSection/InviteSection';
import { OrganizersList } from './OrganizersList/OrganizersList';

export const organizersSectionBlockName = 'organizers-section';

export interface IOrganizersSectionProps {
  id?: string;
  routeId: string;
}

function OrganizersSectionComp({
  routeId,
  id,
  className,
}: React.PropsWithChildren<PropsWithClassName<IOrganizersSectionProps>>): JSX.Element {
  const { t } = useTranslation();
  const bem = bemBlock(organizersSectionBlockName);

  const [pageOwner, setPageOwner] = React.useState<Organizer>(null);
  const [pageOrganizers, setPageOrganizers] = React.useState<Organizer[]>(null);
  const [pageInvitees, setPageInvitees] = React.useState<Organizer[]>(null);

  const { data: inkindPage, mutate: mutateInkindPage } = useInkind(routeId);
  const { data: invitees, mutate: mutateInvitees } = usePagePendingInvitees(routeId);
  const { data: organizerDetails } = useInkindOrganizers(routeId);

  React.useEffect(() => {
    const _pageOwner = inkindPage?.owner;
    const _pageOrganizers = inkindPage?.organizers.filter(organizer => !organizer.pendingInvitation);

    setPageOwner(_pageOwner);
    setPageOrganizers(_pageOrganizers);
    setPageInvitees(invitees);
  }, [inkindPage, invitees]);

  // Triggered by input field and checkboxes
  const handleSendInvitation = async (invitation: OrganizerInvitation) => {
    // only invitee can appear right away in the list
    // organizer must be confirmed through email
    //
    // better not to do optimistic update because at this point
    // only server knows if email exists in database
    // client can't know if this is new invitee or organizer
    // just refetch both
    //
    // backend returns newest invitee at the top
    await inviteUserAsPageOrganizer(routeId, invitation);
    mutateInkindPage();
    mutateInvitees();
  };

  // Triggered by the delete (trash) icon
  const handleCancelInvitation = (emailAddress: string): void => {
    const newInviteesArray = invitees.filter(invitee => invitee.email !== emailAddress);
    optimisticUpdate(newInviteesArray, null, mutateInvitees, () => cancelOrganizerInvitation(routeId, emailAddress));
  };

  // Triggered by the delete (trash) icon
  const handleRemoveOrganizer = (userId: string): void => {
    const newOrganizers = inkindPage.organizers.filter(organizer => organizer.userId !== userId);
    const newInkindPage = { ...inkindPage, organizers: newOrganizers };
    optimisticUpdate(newInkindPage, null, mutateInkindPage, () => removePageOrganizer(routeId, userId));
  };

  // Triggered by checkboxes
  const handleUpdateSettings = async (
    isOwner: boolean,
    userId: string,
    settings: InkindOrganizerSettings
  ): Promise<void> => {
    console.log('handleUpdateSettings', isOwner, userId, settings);
    if (isOwner) {
      const newOwner = { ...inkindPage.owner, ...settings };
      const newInkindPage = { ...inkindPage, owner: newOwner };

      await optimisticUpdate(newInkindPage, null, mutateInkindPage, () => setOwnerSettings(routeId, settings));
    } else {
      const organizerToUpdate = inkindPage.organizers.find(organizer => organizer.userId === userId);
      const newOrganizer = { ...organizerToUpdate, ...settings };

      // update array item without mutation
      const index = inkindPage.organizers.findIndex(organizer => organizer.userId === userId);
      const newOrganizers = [
        ...inkindPage.organizers.slice(0, index),
        newOrganizer,
        ...inkindPage.organizers.slice(index + 1),
      ];
      const newInkindPage = { ...inkindPage, organizers: newOrganizers };

      await optimisticUpdate(newInkindPage, null, mutateInkindPage, () =>
        setOrganizerSettings(routeId, userId, settings)
      );
    }
    UI.notify(t(translationKeys.organizerUpdated));
  };

  return (
    <SectionTitleAndDescription
      id={id}
      className={bem(null, null, className)}
      title={t(translationKeys.organizersTitle)}
      description={t(translationKeys.organizersDescription)}
    >
      <InviteSection
        pageOwner={pageOwner}
        pageOrganizers={pageOrganizers}
        pageInvitees={pageInvitees}
        routeId={routeId}
        organizerDetails={organizerDetails}
        onSendInvitation={handleSendInvitation}
      />
      {pageOwner && pageOrganizers && pageInvitees ? (
        <OrganizersList
          onCancelInvitation={handleCancelInvitation}
          onRemoveOrganizer={handleRemoveOrganizer}
          onUpdateSettings={handleUpdateSettings}
          pageOwner={pageOwner}
          pageOrganizers={pageOrganizers}
          pageInvitees={pageInvitees}
          routeId={routeId}
        />
      ) : (
        <LoadingSpinner center />
      )}
    </SectionTitleAndDescription>
  );
}

export const OrganizersSection = withComponentErrorBoundary(OrganizersSectionComp);
