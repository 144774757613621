import { isBrowser } from '@/utils/environment';
import { logger } from '@gik/analytics/utils/logger';
import { resetPassword } from '@gik/api/users/auth';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import { Error } from '@gik/ui/Error';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

const blockName = 'reset-password-form';

const schema: FormSchemaEntry[] = [
  {
    name: 'newPassword',
    label: 'New Password',
    type: 'password',
    required: true,
    minLength: 8,
    maxLength: 255,
  },
  {
    label: 'Confirm New Password',
    name: 'newPasswordConfirm',
    type: 'password',
    required: true,
    mustEqual: 'newPassword',
    minLength: 8,
    maxLength: 255,
  },
];

export interface IResetPasswordFormValues {
  email: string;
  newPassword: string;
  rememberMe: boolean;
}

export interface IResetPasswordFormProps {
  buttonsPortal?: () => HTMLElement;
  onSuccess?: () => void;
}

function ResetPasswordFormComp({ onSuccess = noop, buttonsPortal }: IResetPasswordFormProps) {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const urlParams = isBrowser() ? new URLSearchParams(window.location.search) : undefined;
  let userId: string;
  let code: string;
  let invalidParams = false;

  if (urlParams) {
    userId = urlParams.get('userId');
    code = urlParams.get('code');

    if (!userId) {
      logger.warn('Password Reset Form: userId not found in the url');
      invalidParams = true;
    }
    if (!code) {
      logger.warn('Password Reset Form: code not found in the url');
      invalidParams = true;
    }
  }

  async function handleSubmit(values: IResetPasswordFormValues) {
    setErrorMessage(null);

    const response = await resetPassword(values.newPassword, userId, code);

    if (response.ok) {
      onSuccess();
    } else {
      const error = await response.json<{ message: string }>();
      setErrorMessage(error.message);
    }
  }

  const formId = 'resetPasswordForm';

  if (invalidParams) {
    return <Error>The Reset Password link you used is invalid, please try a different one.</Error>;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      schema={schema}
      vertical
      className={bem('form')}
      id={formId}
      render={({ isValid, isSubmitting }) => {
        const buttons = (
          <>
            <Button
              className={bem('btnSubmit')}
              variant="primary"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              form={formId}
            >
              {t(translationKeys.formSubmitButton).toString()}
            </Button>
          </>
        );

        return (
          <>
            <FormField name="newPassword" />
            <FormField name="newPasswordConfirm" />

            {errorMessage && <div className="gik-form__form-error">{errorMessage}</div>}

            {buttonsPortal && ReactDOM.createPortal(buttons, buttonsPortal())}
            {!buttonsPortal && buttons}
          </>
        );
      }}
    />
  );
}

export const ResetPasswordForm = withComponentErrorBoundary(ResetPasswordFormComp);
