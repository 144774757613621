import type { CheckoutFormPayload } from '@gik/checkout/components/CheckoutForm/CheckoutForm';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import React from 'react';

interface TaxCalculation {
  subtotal: string;
  subtotal_tax: string;
  tax_rate: string;
}

interface ProductTaxCalculation extends TaxCalculation {
  id: number;
}

export interface ShippingTaxCalculation extends TaxCalculation {
  name: string;
}

interface OrderTaxCalculation {
  shipping: ShippingTaxCalculation[];
  products: {
    items: ProductTaxCalculation[];
    total: TaxCalculation;
  };
}

export function useOrderTaxCalculation(
  enabled: boolean,
  payload: Partial<CheckoutFormPayload>
): OrderTaxCalculation | undefined {
  const [taxCalculation, setTaxCalculation] = React.useState<OrderTaxCalculation>(undefined);

  React.useEffect(() => {
    if (enabled) {
      (async () => {
        const result = await dotnetApi.post('orders/calculate', {
          json: payload,
        });
        setTaxCalculation(await result.json());
      })();
    }
  }, [enabled, payload]);
  return taxCalculation;
}
