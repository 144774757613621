import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { openInkindPageSettingsModal } from '@gik/inkind-page/components/InkindPageSettingsModal/InkindPageSettingsModal';
import { pageSettingsNavItemIds } from '@gik/inkind-page/components/InkindPageSettingsModal/utils/PageSettingsScrollNavBar';
import { useDialogStore } from '@gik/ui/Dialogs';
import type moment from 'moment';
import { checkReminder, getReminderTriggerKey, openReminder } from '../Reminder/openReminder';

const blockName = 'recipient-address-reminder-modal';

export enum RecipientAddressReminderStorageKeyNames {
  timestamp = 'gik-recipient-address-reminder-triggered-timestamp',
  count = 'gik-recipient-address-reminder-triggered-count',
}

export function openRecipientAddressReminder(inkindRouteId: string) {
  // don't open if a dialog is already opened
  const dialogs = useDialogStore.getState().dialogs;
  if (dialogs.length) return;

  function handleAdd() {
    openInkindPageSettingsModal({ inkindRouteId, scrollToSectionId: pageSettingsNavItemIds.recipient });
  }

  openReminder(
    blockName,
    i18n.t(translationKeys.RecipientAddressReminderTitle),
    i18n.t(translationKeys.RecipientAddressReminderDescription),
    handleAdd
  );
}

/**
 * opens the recipientAddress reminder modal if needed
 */
export function checkRecipientAddressReminder(
  inkindRouteId: string,
  pageCreatedDate: moment.Moment,
  allowMultipleReminders = false
) {
  checkReminder(
    () => openRecipientAddressReminder(inkindRouteId),
    'recipient-address-reminder',
    pageCreatedDate,
    getReminderTriggerKey(RecipientAddressReminderStorageKeyNames.timestamp, inkindRouteId),
    getReminderTriggerKey(RecipientAddressReminderStorageKeyNames.count, inkindRouteId),
    1,
    [24, 48],
    allowMultipleReminders
  );
}
