import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { claimsApi } from '@gik/api/calendar';
import type { ICalendarAnnouncementPayload } from '@gik/api/calendar/calendarAnnouncements';
import type { CreateEventPayload, CreateUpdateEventPayload } from '@gik/api/calendar/calendarEvents';
import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds } from '@gik/calendar/models/CalendarEventTypes';
import type { ICalendarClaim } from '@gik/calendar/models/Claim';
import type { IWordpressCalendarEventType } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import { useUserStore } from '@gik/core/store/UserStore';
import { applyTimeToMoment } from '@gik/core/utils/moment';
import { dateTimeFormatDetailed } from '@gik/l10n';
import { UI } from '@gik/ui/UIManager';
import moment from 'moment';
import type { Frequency, Options, Weekday } from 'rrule';
import * as _RRule from 'rrule';
import { timeFromInt, timeToInt } from 'time-number';
import type { ICalendarAnnouncementFormValues } from '../Announcements';
import { CalendarAnnouncementFormFieldNames } from '../Announcements';
import type { ICalendarEventFormValues } from './CalendarEventForm';

export async function calendarTimeValuesToPayload(values: ICalendarEventFormValues | ICalendarAnnouncementFormValues) {
  try {
    let startDate = moment(values.startDate);
    let endDate = moment(values.endDate);

    const RRuleFreq = (await import('rrule')).Frequency;
    const weekdays: Weekday[] = [];
    let interval = 1;
    const repeat = Number.parseInt(values.repeat as unknown as string);
    let rule;

    if (repeat !== -1) {
      let freq: Frequency;
      const weekMap = [
        _RRule.RRuleSet.MO,
        _RRule.RRuleSet.TU,
        _RRule.RRuleSet.WE,
        _RRule.RRuleSet.TH,
        _RRule.RRuleSet.FR,
        _RRule.RRuleSet.SA,
        _RRule.RRuleSet.SU,
      ];

      switch (repeat) {
        case 10:
          // create weekday strings
          values.weekdays.map((weekday, index) => {
            if (weekday) weekdays.push(weekMap[index]);
          });

          freq = RRuleFreq.WEEKLY;

          break;
        case 11: // every other day
          freq = RRuleFreq.DAILY;
          interval = 2;

          break;
        default:
          freq = repeat || RRuleFreq.WEEKLY;
      }

      const ruleOpts: Partial<Options> = {
        byweekday: weekdays,
        freq,
        interval,
      };
      rule = new _RRule.RRule(ruleOpts);
    } else {
      endDate = startDate;
    }

    // set times on start / end dates
    if (!values.allDay) {
      startDate = applyTimeToMoment(startDate.clone(), `${timeFromInt(values.startTime)}:00.000`);
      // TODO: if before startDate, add an extra day? (event passes midnight)
      endDate = applyTimeToMoment(endDate.clone(), `${timeFromInt(values.endTime)}:00.000`);
    } else {
      startDate = applyTimeToMoment(startDate.clone(), `00:00:00.000`);
      endDate = applyTimeToMoment(endDate.clone(), `00:00:00.000`);
    }

    // if end date is before start Date then ad one day to it
    if (endDate.isBefore(startDate) || endDate.isSame(startDate)) {
      endDate.add(1, 'day');
    }

    return {
      startsAt: startDate.format(dateTimeFormatDetailed),
      endsAt: endDate.format(dateTimeFormatDetailed),
      repeat: rule ? rule.toString() : '',
      sendEmailToPageFollowers: values[CalendarAnnouncementFormFieldNames.SendEmailToPageFollowers],
      allDay: values.allDay,
    };
  } catch (err) {
    console.error(err);
  }
}

export async function calendarAnnouncementFormValuesToPayload(
  values: ICalendarAnnouncementFormValues
): Promise<ICalendarAnnouncementPayload> {
  const repeatProps = await calendarTimeValuesToPayload(values);

  const payload = {
    title: values.title,
    description: values.description,
    announcementTypeId: values.announcementTypeId,
    ...repeatProps,
  };

  // remove null values
  Object.keys(payload).forEach(key => {
    if (payload[key] === null) delete payload[key];
  });

  // delete endsAt if this is an allday event without a repeat
  if (payload.allDay && !repeatProps.repeat) delete payload.endsAt;

  return {
    ...payload,
    typeId: undefined, // to be filled later
  };
}

export async function calendarEventFormValuesToPayload(
  values: ICalendarEventFormValues,
  giftCardsOnly: boolean
): Promise<CreateEventPayload> {
  const repeatProps = await calendarTimeValuesToPayload(values);

  const formValues = { ...values, petCareTypeId: parseInt(values.petCareTypeId) };

  const payload: CreateUpdateEventPayload = {
    ...formValues,
    ...repeatProps,
    typeId: values.eventTypeId,
    inkindRouteId: undefined, // to be filled later
  };

  delete payload['eventTypeId'];
  delete payload['startDate'];
  delete payload['startTime'];
  delete payload['endDate'];
  delete payload['endTime'];

  if (!payload.allowGiftCards) {
    delete payload.allowedGiftCardIds;
  }

  if (giftCardsOnly) {
    payload.allDay = true;
  }

  payload.petCareTypeId = parseInt(values.petCareTypeId);

  // delete endsAt if this is an allday event without a repeat
  if (payload.allDay && !repeatProps.repeat) delete payload.endsAt;

  // remove null values
  Object.keys(payload).forEach(key => {
    if (payload[key] === null) delete payload[key];
  });

  return {
    ...payload,
  };
}

export function afterClaimSucceeded(
  entry: ICalendarEntry,
  claim: Pick<ICalendarClaim, 'serviceCategorySlug' | 'serviceSlug'>,
  eventType: IWordpressCalendarEventType
) {
  const userId = useUserStore.getState().id;

  const isEditing = !!entry.claim;
  // NOTE: this event name is deliberately lower cased for backwards compatibility
  Analytics.fireEvent(AnalyticsEvents.eventClaimed, {
    isUpdate: isEditing.toString(),
    serviceCategorySlug: claim.serviceCategorySlug,
    serviceSlug: claim.serviceSlug,
    calendarEventType: entry.typeName,
    calendarEventTypeCategory: eventType.acf.event_category.value,
    userId,
  });
}

export async function unclaimCalendarEvent(
  entry: ICalendarEntry,
  calendarEventTypes: IWordpressCalendarEventType[],
  userId: string
) {
  const response = await claimsApi.unclaim(entry.claim?.id);
  if (response.ok) {
    const calendarEventType = calendarEventTypes.find(type => type.id === entry.typeId);
    // NOTE: this event name is deliberately lower cased for backwards compatibility
    Analytics.fireEvent(AnalyticsEvents.eventUnclaimed, {
      serviceCategorySlug: entry.claim.serviceCategorySlug,
      serviceSlug: entry.claim.serviceSlug,
      calendarEventType: entry.typeName,
      calendarEventTypeCategory: calendarEventType.acf.event_category.value,
      userId: userId,
      // TODO: add claim ID (and change to soft delete of claims)
    });
  } else {
    logger.error(`Failed to unclaim ${entry.claim?.id} for entry ${entry.id}`, response);
    UI.alert('Please try again.', { title: 'Unclaim failed' });
  }

  return response;
}

export function isFoodType(typeId: number): boolean {
  return (
    typeId === CalendarEventTypeIds.Breakfast ||
    typeId === CalendarEventTypeIds.Dinner ||
    typeId === CalendarEventTypeIds.Lunch
  );
}

export function isPetType(typeId: number): boolean {
  return typeId === CalendarEventTypeIds.PetCare;
}

export function isAnnouncement(typeId: number): boolean {
  return typeId === CalendarEventTypeIds.Announcement;
}

export function getStartTimeByTypeId(typeId: number) {
  // update time if type is breakfast, lunch or dinner
  switch (typeId) {
    case CalendarEventTypeIds.Breakfast:
      return timeToInt('08:00');
    case CalendarEventTypeIds.Lunch:
      return timeToInt('11:00');
    case CalendarEventTypeIds.Dinner:
      return timeToInt('17:00');
    default:
      return timeToInt('12:00');
  }
}
