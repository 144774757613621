import { gikClassPrefix, timeoutDefaultValue } from '@gik/core/constants';
import { htmlEncode } from '@gik/core/utils/htmlencode';
import classnames from 'classnames';
import React from 'react';
import type { UISize, UIVariant } from '../types';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Style variant to use
   */
  variant?: UIVariant;

  /**
   * Size the component on a fixed scale
   */
  size?: UISize;

  /**
   * Force display of focus state
   */
  focus?: boolean;

  /**
   * Auto focus the input field when the component is mounted
   */
  autoFocus?: boolean;

  /**
   * Automatically size the height according to the text content
   */
  autoGrow?: boolean;

  /**
   * Put button in disabled mode
   */
  disabled?: boolean;

  /**
   * Disable builtin browser resize
   */
  noresize?: boolean;

  /**
   * Value of the input field
   */
  value?: string;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * Limit the value to a maximum number of characters.
   */
  charLimit?: number;

  /**
   * Display a character count and limit below the input field
   */
  charLimitVisible?: boolean;

  /**
   * classNames for the character limit element
   */
  charLimitClassName?: string;

  /**
   * Limit the value to a maximum number of characters.
   */
  maxLength?: number;

  maxLengthDisplay?: boolean;

  /**
   * classNames for the character limit element
   */
  maxLengthClassName?: string;

  /**
   * if set to higher than 0 the component will display itself in a
   * warning state when the input length is nearing the maxLength.
   *
   * note: only works in combination with maxLength
   */
  maxLengthWarningSensitivity?: number;

  limitInputToMaxLength?: boolean;

  /**
   * display the component in an error state
   */
  hasError?: boolean;

  /**
   * display the component in a warning state
   */
  hasWarning?: boolean;

  /**
   * Called when the value changes.
   * This differs from the standard onChange callback for form elements.
   * It will not fire when the component is in a disabled state and it
   * will return the value as a string (instead of an entire onChange Event)
   */
  onValueChange?: (name: string) => void;
}

// block name for this component
const blockName = `${gikClassPrefix}-textarea`;

function TextAreaComp(
  {
    value,
    className,
    variant = 'default',
    size,
    tabIndex,
    disabled,
    focus,
    autoFocus,
    autoGrow,
    noresize,
    maxLength,
    maxLengthDisplay,
    maxLengthWarningSensitivity = 10,
    maxLengthClassName,
    hasError,
    hasWarning,
    limitInputToMaxLength,
    onFocus,
    onBlur,
    onValueChange,
    onChange,
    ...otherProps
  }: TextAreaProps,
  ref: React.LegacyRef<HTMLTextAreaElement>
): React.ReactElement {
  const [focused, setFocused] = React.useState(false);
  const [finalTabIndex, setTabIndex] = React.useState(tabIndex);

  let inputRef: HTMLTextAreaElement;

  const fitVertical = React.useCallback(() => {
    if (!inputRef) return;

    inputRef.style.height = 'auto';
    if (inputRef.scrollHeight) inputRef.style.height = `${inputRef.scrollHeight}px`;
  }, [inputRef]);

  React.useEffect(() => {
    if (autoFocus) inputRef.focus();
  }, [autoFocus, inputRef]);

  React.useEffect(() => {
    // force tabIndex to be -1 if component is in disabled state
    if (disabled) {
      setTabIndex(-1);
    } else {
      setTabIndex(tabIndex);
    }
  }, [disabled, tabIndex]);

  React.useEffect(() => {
    if (autoGrow) {
      fitVertical();
    } else if (inputRef) {
      inputRef.style.height = null;
    }
  }, [autoGrow, fitVertical, inputRef]);

  function handleChange(ev: React.ChangeEvent<HTMLTextAreaElement>) {
    // don't do anything if disabled
    if (disabled) return;

    let newValue = ev.target.value;

    // limit the value to the maxlength
    if (limitInputToMaxLength) {
      newValue = newValue.substring(0, maxLength);
    }

    onValueChange?.(newValue);
    ev.target.value = newValue;
    onChange?.(ev);
    if (autoGrow) {
      fitVertical();
      setTimeout(() => {
        fitVertical;
      }, timeoutDefaultValue);
    }
  }

  function handleFocus(ev: React.FocusEvent<HTMLTextAreaElement>): void {
    // disabled components should not receive focus
    // note: onFocus should never be called in the first place
    // buttons in these states should have a tabIndex of -1
    if (disabled) return;

    setFocused(true);
    onFocus?.(ev);
  }

  function handleBlur(ev: React.FocusEvent<HTMLTextAreaElement>): void {
    setFocused(false);
    onBlur?.(ev);
  }

  const hasMaxLength = maxLengthDisplay && maxLength > 0;

  const encodedValue = htmlEncode(value);

  const charLength = encodedValue ? encodedValue.length : 0;
  const maxLengthError = hasMaxLength && charLength > maxLength;
  const maxLengthWarning =
    hasMaxLength &&
    !maxLengthError &&
    maxLengthWarningSensitivity &&
    charLength >= maxLength - maxLengthWarningSensitivity;

  const shouldShowMaxLength = focused && encodedValue?.length > 0;

  const blockClasses = classnames([
    `${blockName}__wrapper`,
    { [`${blockName}--${variant}`]: variant },
    { [`${blockName}--size-${size}`]: size },
    { [`${blockName}--disabled`]: disabled },
    { [`${blockName}--noresize`]: noresize },
    { [`${blockName}--focus`]: focused || focus },
    { [`${blockName}--has-max-length`]: hasMaxLength },
    { [`${blockName}--has-error`]: hasError || maxLengthError },
    { [`${blockName}--has-warning`]: hasWarning || maxLengthWarning },
    { [`${blockName}--max-length-visible`]: shouldShowMaxLength },
    { [`${blockName}--has-autogrow`]: autoGrow },
    className || '',
  ]);

  const maxLengthClasses = classnames([
    `${blockName}__max-length`,
    { [`${blockName}__max-length--warning`]: maxLengthWarning },
    maxLengthClassName || '',
  ]);

  const inputProps = {
    ref: (_ref: HTMLTextAreaElement) => {
      if (!_ref) return null;
      inputRef = _ref;
      return ref as React.LegacyRef<HTMLTextAreaElement>;
    },
    value,
    disabled,
    className: `${blockName}__textarea`,
    ...otherProps,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    tabIndex: finalTabIndex,
  };

  const textArea = <textarea {...inputProps} />;

  return (
    <div className={blockClasses}>
      {textArea}
      {hasMaxLength && (
        <div className={maxLengthClasses}>
          {charLength}/{maxLength}
        </div>
      )}
    </div>
  );
}

export const TextArea = React.forwardRef(TextAreaComp);
