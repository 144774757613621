import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import { extractCampaignSlug } from '@gik/core/utils/GoFundMe';
import type { GoFundMeDonationFormValueType } from '@gik/create/components/DonationProvider/DonationProvider';
import DonationProvider, { DonationProviderType } from '@gik/create/components/DonationProvider/DonationProvider';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { goFundMeFormSchema } from '@gik/create/formSchemas';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { GoFundMeSupportWidgetPreview } from '@gik/inkind-page/components/Support/GoFundMeSupportWidget';
import React from 'react';

export interface IGoFundMeDonationProviderProps {
  value: GoFundMeDonationFormValueType;
  onValueChange: (value: GoFundMeDonationFormValueType) => void;
  asForm?: boolean;
  trackingId?: AnalyticsEvents;
  variant?: 'create' | 'page-settings';
  recalculateHeightCallback?: () => void;
}

const blockName = 'gofundme-donation-provider';

export default function GoFundMeDonationProvider({
  value,
  onValueChange,
  asForm = false,
  trackingId,
  variant = 'create',
  recalculateHeightCallback,
}: IGoFundMeDonationProviderProps) {
  const bem = bemBlock(blockName);

  return (
    <DonationProvider
      asForm={asForm}
      trackingId={trackingId}
      schema={goFundMeFormSchema()}
      className={bem('content')}
      provider={DonationProviderType.GoFundMe}
      target={i18n.t(translationKeys.GoFundMeTargetVariantCreate)}
      // description={
      //   variant === 'page-settings'
      //     ? i18n.t(translationKeys.GoFundMeDescriptionVariantPageSettings)
      //     : i18n.t(translationKeys.GoFundMeDescriptionVariantCreate)
      // }
      value={value}
      onValueChange={onValueChange}
      recalculateHeightCallback={recalculateHeightCallback}
      previewComponent={(_value, isValid) => {
        setTimeout(() => recalculateHeightCallback?.(), timeoutDefaultValue);
        return (
          <GoFundMeSupportWidgetPreview slug={extractCampaignSlug(_value ?? value[GoFundMeFormInputNames.PageURL])} />
        );
      }}
      previewType="widget"
    />
  );
}
