import { logger } from '@gik/analytics/utils/logger';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { defaultKyHeaders } from '@gik/core/api/ky/base';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { GIKApiError } from '@gik/core/models/gik/APIError';
import type UserRegistrationData from '@gik/core/models/gik/auth/UserRegistrationData';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { StatusCodes } from 'http-status-codes';
import ky from 'ky';
import { CustomError } from '../CustomError';
import { CustomStatusCodes } from '../CustomStatusCodes';
import { BadRequest } from '../HttpErrors';
import type {
  IEmailAddressAlreadyTakenResponse,
  IFacebookAccessToken,
  IOAuthUserLoginResponse,
  IUserLoginResponse,
  IUserSignUpResponse,
  UserRegistrationAPIRequest,
} from './types';

export function getBrowserInfo() {
  return {
    browserInfo: {
      userAgent: window.navigator.userAgent,
    },
  };
}

export async function login(emailAddress: string, password: string): Promise<IUserLoginResponse> {
  const response = await dotnetApi.post('authentication/login', {
    json: {
      emailAddress,
      password,
      //RememberMe: rememberMe,
      ...getBrowserInfo(),
    },
  });

  if (!response.ok) {
    switch (response.status) {
      case StatusCodes.BAD_REQUEST:
        throw new BadRequest('Invalid login attempt.');
      default:
        throw response;
    }
  }

  const userLoginResponse: IUserLoginResponse = await response.json();

  return userLoginResponse;
}

export async function facebookCodeExchange(redirectUri: string, code: string): Promise<IFacebookAccessToken> {
  const response = await dotnetApi.post('authentication/oauthLogin/exchange-code', {
    json: {
      redirectUri,
      code,
      ...getBrowserInfo(),
    },
  });

  if (!response.ok) {
    logger.error('Failed to exchange code', response);
    throw response;
  }

  return await response.json<IFacebookAccessToken>();
}

export async function oauthLogin(
  provider: ExternalLoginProvider,
  accessToken: string,
  inkindRouteId: string,
  subscribeToNewsletter: boolean
): Promise<IOAuthUserLoginResponse> {
  const response = await dotnetApi.post('authentication/oauthLogin', {
    json: {
      provider,
      accessToken,
      inkindRouteId,
      subscribeToNewsletter,
      ...getBrowserInfo(),
    },
  });

  if (!response.ok) {
    logger.error('Failed to login with oauth', response);
    switch (response.status) {
      case StatusCodes.BAD_REQUEST:
        throw new BadRequest('Invalid login attempt.');
      case CustomStatusCodes.IncorrectProvider:
      case CustomStatusCodes.IncompleteOAuthInfo:
        throw new CustomError(response.status, response.statusText, undefined);
      default:
        throw response;
    }
  }

  const userLoginResponse: IOAuthUserLoginResponse = await response.json();

  return userLoginResponse;
}

export async function requestResetPassword(emailAddress: string): Promise<boolean | GIKApiError> {
  try {
    const response = await dotnetApi.post(`users/forgot-password`, {
      json: {
        emailAddress,
      },
    });

    if (!response.ok) {
      return (await response.json()) as GIKApiError;
    }

    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
}

export async function resetPassword(newPassword: string, userId: string, code: string) {
  return await dotnetApi.post(`users/reset-password`, {
    json: {
      userId,
      newPassword,
      code,
    },
  });
}

export async function userSignUp({
  emailAddress,
  firstName,
  lastName,
  password,
  inkindRouteId,
  subscribeToNewsletter,
}: UserRegistrationData): Promise<IUserSignUpResponse> {
  const requestData: UserRegistrationAPIRequest = {
    RegisterEmail: emailAddress,
    Firstname: firstName,
    Lastname: lastName,
    RegisterPassword: password,
    subscribeToNewsletter,
    inkindRouteId,
    ...getBrowserInfo(),
  };

  const response = await dotnetApi.post(`users/register`, {
    json: requestData,
  });

  if (!response.ok) {
    throw (await response.json()) as IUserSignUpResponse;
  }

  return (await response.json()) as IUserSignUpResponse;
}

export async function isEmailAddressAlreadyTaken(email: string): Promise<IEmailAddressAlreadyTakenResponse> {
  const response = await dotnetApi.get(`users/exist`, {
    searchParams: {
      email,
    },
  });

  if (!response.ok) {
    throw await response.json();
  }

  return (await response.json()) as IEmailAddressAlreadyTakenResponse;
}

export function useEmailAlreadyTaken(email: string, config?: SWRConfigInterface) {
  return useApi<IEmailAddressAlreadyTakenResponse>(
    email ? `users/exist` : null,
    {
      email,
    },
    config
  );
}

export async function refreshJWTToken() {
  const refreshToken = useUserStore.getState().refreshToken;

  // need to not use the dotnetApi because the ky hooks in it would conflict with this logic.
  const { accessToken } = await ky
    .post('authentication/refresh', {
      json: {
        refreshToken,
      },
      headers: defaultKyHeaders,
      timeout: 20000,
      retry: 3,
      prefixUrl: useEnvStore.getState().DOTNET_API_URL,
    })
    .json<{ accessToken: string }>();

  useUserStore.getState().setAccessToken(accessToken);

  return { accessToken };
}
