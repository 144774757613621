import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IUserSignUpResponse } from '@gik/api/users/types';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import type { IUserSignupFormValues } from '@gik/auth/components/Signup/SignupForm';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Separator } from '@gik/ui/Separator';
import React from 'react';
import { SignupForm } from '.';

const blockName = 'signup-section';

export interface ISignupSectionProps {
  buttonsPortal?: () => HTMLElement;
  onSuccess?: (doneResponse: IUserSignUpResponse) => void | Promise<void>;
  onIncorrectProvider?: (email: string) => Promise<void>;

  onLogin?: () => void;
  initialValues?: Partial<IUserSignupFormValues>;
  trackingId?: AnalyticsEvents;
}

function SignupSectionComp({
  buttonsPortal,
  onSuccess,
  onIncorrectProvider,
  onLogin,
  initialValues,
  trackingId,
}: ISignupSectionProps) {
  const bem = bemBlock(blockName);

  return (
    <section className={bem()}>
      <ExternalLoginForm vertical onSuccess={onSuccess} onIncorrectProvider={onIncorrectProvider} />
      <Separator variant="neutral-300" thickness="thin">
        <span className="tw-text-neutral-700">or</span>
      </Separator>
      <SignupForm
        buttonsPortal={buttonsPortal}
        onSuccess={onSuccess}
        onLogin={onLogin}
        initialValues={initialValues}
        disableEmailField
        trackingId={trackingId}
      />
    </section>
  );
}

export const SignupSection = withComponentErrorBoundary(SignupSectionComp);
