import { useCalendarEventTypeOptions } from '@gik/api/calendar/calendarEventType';
import type { IWordpressCalendarEventTypeOption } from '@gik/core/models/wordpress/WordpressCalendarEventType';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SelectList } from '@gik/ui/SelectList';
import React from 'react';
import { CalendarEventTypeTile } from './CalendarEventTypeTile';
import { CalendarEventTypeTileAnnouncement } from './CalendarEventTypeTileAnnouncement';

export interface ICalendarEventTypeSelectListProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'onSelect'> {
  value?: number;
  hideAnnouncement?: boolean;
  onChange?: (value: number, item: IWordpressCalendarEventTypeOption<number>) => void;
}

export function CalendarEventTypeSelectList({
  className,
  hideAnnouncement,
  onChange = noop,
  ...otherProps
}: ICalendarEventTypeSelectListProps): React.ReactElement {
  const bem = bemBlock('calendar-event-type-select-list');

  const [selection, setSelection] = React.useState<number>();
  const { data: options } = useCalendarEventTypeOptions(hideAnnouncement);

  if (!options) return <LoadingSpinner center />;

  return (
    <SelectList<number>
      className={bem(null, null, className)}
      value={selection}
      options={options}
      onChange={v => {
        const item = options.find(item => item.value === v);
        setSelection(v as number);
        onChange(v as number, item);
      }}
      render={(item: IWordpressCalendarEventTypeOption<number>, selected: boolean) => {
        const itemBem = bemBlock('calendar-event-type-select-list-item');
        if (item.slug === 'announcement') {
          return (
            <CalendarEventTypeTileAnnouncement
              className={itemBem(null)}
              selected={selected}
              image={item.image}
              label={item.label}
              horizontal
            />
          );
        } else {
          return (
            <CalendarEventTypeTile
              variant={item.slug}
              className={itemBem(null)}
              selected={selected}
              image={item.image}
              label={item.label}
              horizontal
            />
          );
        }
      }}
      {...otherProps}
    />
  );
}
