import type { Organizer, OrganizerDetail, OrganizerInvitation } from '@gik/core/models/gik/InkindPage';
import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import * as validator from '@gik/core/utils/validator';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry, ValidationError, ValidationErrors } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import React from 'react';

export const inviteSectionBlockName = 'invite-section';

export enum OrganizerInviteFormFieldNames {
  EmailAddress = 'emailAddress',
  CanEdit = 'canEdit',
  Notifications = 'notifications',
}

const FieldNames = OrganizerInviteFormFieldNames;

export const inviteFormSchema: () => FormSchemaEntry[] = () => [
  {
    name: FieldNames.EmailAddress,
    required: true,
    type: 'email',
  },
  {
    name: FieldNames.CanEdit,
    type: 'checkbox',
    props: {
      label: 'Can edit page',
    },
  },
  {
    name: FieldNames.Notifications,
    type: 'checkbox',
    props: {
      label: 'Email All Notifications',
    },
  },
];

interface InviteSectionErrors extends ValidationErrors {
  emailAddress?: ValidationError;
  canEdit?: ValidationError;
  notifications?: ValidationError;
}

export interface IInviteSectionProps {
  pageOwner: Organizer;
  pageOrganizers: Organizer[];
  pageInvitees: Organizer[];
  organizerDetails?: OrganizerDetail[];
  routeId: string;
  onSendInvitation: (invitation: OrganizerInvitation) => void | Promise<void>;
}

function InviteSectionComp({
  // for custom validation
  pageOwner,
  pageOrganizers,
  pageInvitees,
  organizerDetails,
  onSendInvitation,
  className,
}: React.PropsWithChildren<PropsWithClassName<IInviteSectionProps>>): JSX.Element {
  const bem = bemBlock(inviteSectionBlockName);

  const customValidateForm = (values: OrganizerInvitation): InviteSectionErrors => {
    const errors: InviteSectionErrors = {};

    if (validator.isEmpty(values.emailAddress)) {
      errors.emailAddress = { message: i18n.t('validation.requiredEmail') };
    }

    if (validator.isString(values.emailAddress) && !validator.isEmail(values.emailAddress as string)) {
      errors.emailAddress = { message: i18n.t('validation.email') };
    }

    const pageOwnerObject = organizerDetails?.find(org => org.userId === pageOwner?.userId);

    const isAlreadyOwner = pageOwnerObject && pageOwnerObject.email.localeCompare(values.emailAddress) === 0;

    const isAlreadyOrganizer =
      pageOrganizers && organizerDetails?.some(organizer => organizer.email.localeCompare(values.emailAddress) === 0);

    if (isAlreadyOwner || isAlreadyOrganizer) {
      errors.emailAddress = { message: i18n.t(translationKeys.emailAlreadyOrganizerValidation) };
    }

    const isAlreadyInvited =
      pageInvitees && pageInvitees.some(organizer => organizer.email.localeCompare(values.emailAddress) === 0);

    if (isAlreadyInvited) {
      errors.emailAddress = { message: i18n.t(translationKeys.emailAlreadyInvitedValidation) };
    }

    return errors;
  };

  const handleSubmit = async (values: OrganizerInvitation) => {
    await onSendInvitation(values);
  };

  // manual Form because of custom validation for email
  return (
    <div className={bem(null, null, className)}>
      <Form
        onSubmit={handleSubmit}
        schema={inviteFormSchema()}
        validate={customValidateForm}
        initialValues={{ canEdit: true, notifications: true, emailAddress: '' }}
        vertical
        id="page-settings-invite"
        restoreAfterUpdate
        render={props => {
          const { isValid, isSubmitting, errors } = props;

          return (
            <>
              <div className={bem('input')}>
                <FormField name={FieldNames.EmailAddress} />
              </div>

              <div className={bem('checkbox')}>
                <FormField name={FieldNames.CanEdit} />
                <FormField name={FieldNames.Notifications} />
              </div>

              <div className={bem('button')}>
                <Button variant="primary" type="submit" loading={isSubmitting} disabled={!isValid}>
                  {i18n.t(translationKeys.inviteOrganizersButton) as string}
                </Button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

export const InviteSection = withComponentErrorBoundary(InviteSectionComp);
