import { isBrowser } from '@/utils/environment';
import { Button } from '@gik/ui/Button';
import { UI } from '@gik/ui/UIManager';
import React from 'react';

/* set of Regex to compare against user agent in order to detect any kind of embedded in app browser */
const IAB = [
  /Instagram/,
  /FB_IAB/,
  /FBAN/,
  /FBIOS/,
  /FBAV/,
  /FBBV/,
  /FBDV/,
  /FBMD/,
  /FBSN/,
  /FBSV/,
  /FBSS/,
  /FBID/,
  /FBLC/,
  /FBOP/,
  /FBRV/,
];

export function useIsInApp() {
  return React.useMemo(() => isInApp(), []);
}

export function isInApp(): boolean {
  if (!isBrowser()) return false;

  const { userAgent } = navigator;
  return IAB.some(value => value.test(userAgent));
}

export function useExitInAppBrowser() {
  const isInApp = useIsInApp();

  React.useEffect(() => {
    if (isInApp) {
      // attempt to exit for android;
      const currentLocation = location.origin + location.pathname;
      // @ts-ignore
      window.location = `intent:${currentLocation}#Intent;end`;

      UI.dialog(
        <div>
          <p>You are using an unsupported browser, please click below to open in your default browser.</p>
          <Button tag={'a'} href={`intent:${currentLocation}#Intent;end`}>
            Open in external browser
          </Button>
        </div>,
        {
          title: 'Unsupported browser',
          closable: false,
          shouldCloseOnOverlayClick: false,
        }
      );
    }
  }, [isInApp]);
}

/**
 * This function handles opening external links in a consistent way
 */
export function openExternalLink(url: string): void {
  window.open(url, isInApp() ? '_self' : '_blank');
}

export function openNewBrowserTab(url: string): void {
  window.open(url, isInApp() ? '_self' : '_blank');
}

const noPrintClassName = 'no-print';
const printClassName = 'print';

export function printElement(elem) {
  const printContents = elem.innerHTML;

  Array.from(document.body.children).forEach(child => child.classList.add(noPrintClassName));

  const div = document.createElement('div');
  div.innerHTML = printContents;
  div.className = printClassName;

  document.body.append(div);

  setTimeout(function () {
    window.print();
    document.body.removeChild(div);
    Array.from(document.body.children).forEach(child => child.classList.remove(noPrintClassName));
  }, 250);
}

export function isTouchDevice() {
  return window.ontouchstart !== undefined;
}

export function nativeShareSupported() {
  return !!navigator.share;
}
