import { recipientFullNameField } from '@gik/core/form/formSchemas';
import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { Checkbox } from '@gik/ui/Checkbox';
import type { FormProps, FormSchemaEntry, ValidationError } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import type { IRecipientAddressFormErrors, IRecipientAddressFormValues } from '@gik/ui/gik/RecipientAddressForm';
import { RecipientAddressForm, recipientAddressFormSchema } from '@gik/ui/gik/RecipientAddressForm';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export interface IRecipientAddressPromptFormProps extends FormProps {
  buttonsPortal?: () => HTMLElement;
  isRecipientFullNameRequired?: boolean;
  onSkip?: () => Promise<void>;
  isDontAskAgain?: boolean;
  setIsDontAskAgain?: (checked: boolean) => void;
}

export interface IRecipientAddressPromptFormValues extends IRecipientAddressFormValues {
  recipientFullName: string;
}

export interface IRecipientAddressPromptFormErrors extends IRecipientAddressFormErrors {
  recipientFullName: ValidationError;
}

export enum recipientAddressPromptInputNames {
  RecipientFullName = 'recipientFullName',
}

const recipientFullNameCustomProps = (isRecipientFullNameRequired: boolean) =>
  ({
    name: recipientAddressPromptInputNames.RecipientFullName,
    required: isRecipientFullNameRequired,
  } as FormSchemaEntry);

export const recipientAddressPromptFormSchema: (isRecipientFullNameRequired?: boolean) => FormSchemaEntry[] = (
  isRecipientFullNameRequired = false
) => [recipientFullNameField(recipientFullNameCustomProps(isRecipientFullNameRequired))];

export const recipientAddressPromptFormBlockName = 'recipient-address-prompt-form';
const formId = 'recipient-address-prompt-form';

export function RecipientAddressPromptForm({
  buttonsPortal,
  className,
  isRecipientFullNameRequired = false,
  onSkip,
  isDontAskAgain,
  setIsDontAskAgain,
  ...otherProps
}: IRecipientAddressPromptFormProps): React.ReactElement {
  const { t } = useTranslation();
  const bem = bemBlock(recipientAddressPromptFormBlockName);

  const schema: FormSchemaEntry[] = recipientAddressPromptFormSchema(isRecipientFullNameRequired).concat(
    recipientAddressFormSchema(true)
  );

  const buttons = (form: FormikProps<object>) => (
    <>
      <Checkbox
        checked={isDontAskAgain}
        onValueChange={setIsDontAskAgain}
        className={bem('dont-ask-checkbox')}
        label={t(translationKeys.dontAskAgain)}
      />
      <Button className={bem('skip-button')} onClick={onSkip} variant="default">
        {t(translationKeys.skipButton)}
      </Button>
      <Button
        className={bem('submit-button')}
        variant="primary"
        type="submit"
        loading={form.isSubmitting}
        disabled={!form.isValid}
        form={formId}
      >
        {t(translationKeys.submitButton)}
      </Button>
    </>
  );

  return (
    <Form
      id={formId}
      restoreAfterUpdate
      className={bem(null, null, className)}
      schema={schema}
      render={(form: FormikProps<object>) => (
        <>
          <FormField name={recipientAddressPromptInputNames.RecipientFullName} />
          <RecipientAddressForm form={form} required={true} />

          <FormButtons buttons={() => buttons(form)} buttonsPortal={buttonsPortal} form={form} formId={formId} />
        </>
      )}
      {...otherProps}
    />
  );
}
