import type { CheckoutFormInitiatedOnType } from '@gik/checkout/types';
import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import { Carousel } from '@gik/ui/Carousel';
import { Interstitial } from '@gik/ui/Interstitial';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import ExternalProductPage from '../components/ExternalProductInterstitial/ExternalProductPage';
import SendEmailPage from '../components/ExternalProductInterstitial/SendEmailPage';

export interface openExternalProductInterstitialModalProps {
  onClose(): void;
  sourceName: string;
  externalLink: string;
  shippingAddressLines?: string[];
  recipientEmail?: string;
  products?: Product[];
  inkindRouteId?: string;
  initiatedOn?: CheckoutFormInitiatedOnType;
}

export function openExternalProductInterstitialModal({
  sourceName,
  externalLink,
  shippingAddressLines,
  recipientEmail,
  products,
  initiatedOn,
  inkindRouteId,
  onClose,
}: openExternalProductInterstitialModalProps) {
  const bem = bemBlock(`external-product-interstitial`);

  const carouselSettings = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return UI.dialog(
    ({ close, contentRef, footerRef, headerRef }) => {
      return (
        <Interstitial contentClassName={bem()} isOpen={true} onClose={onClose}>
          <div className="gik-external-product-interstitial-carousel">
            <Carousel {...carouselSettings} legacyIndicator>
              <ExternalProductPage
                products={products}
                sourceName={sourceName}
                inkindRouteId={inkindRouteId}
                initiatedOn={initiatedOn}
                externalLink={externalLink}
                recipientEmail={recipientEmail}
                shippingAddressLines={shippingAddressLines}
              />
              <SendEmailPage products={products} inkindRouteId={inkindRouteId} />
            </Carousel>
          </div>
        </Interstitial>
      );
    },
    {
      closable: true,
      autowidth: false,
      modalProps: {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
      },
      className: 'gik-modal-checkout-new',
      footer: <ModalButtonFooter centeredButtons={true} />,
    }
  );
}
