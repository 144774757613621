import { timeoutDefaultValue } from '@gik/core/constants';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';

export const StatusTool = withComponentErrorBoundary(StatusToolComp);

export type IStatusToolProps = UIComponent & {};

function StatusToolComp({ className, ...otherProps }: IStatusToolProps) {
  const bem = useBemCN('status-tool');

  const [focus, setFocus] = React.useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps,use-effect-no-deps/use-effect-no-deps
  React.useEffect(() => {
    const interval = setInterval(() => {
      setFocus(document.activeElement.toString());
    }, timeoutDefaultValue);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div {...otherProps} {...bem(null, null, className)}>
      <p>HTML Focus: {focus}</p>
    </div>
  );
}
