import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

interface ICenterFixedProps {
  className?: string;
}
export function CenterFixed({ children, className }: React.PropsWithChildren<ICenterFixedProps>) {
  const bem = bemBlock('center-fixed');
  return <div className={bem(null, null, className)}>{children}</div>;
}
