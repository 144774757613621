import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

export interface IAlternatePhoneNumberFormProps {
  buttonsPortal?: () => HTMLElement;
  initialValues?: IAlternatePhoneNumberValues;
  onSubmit?: (values: IAlternatePhoneNumberValues) => void;
  onCancel?: () => void;
  closeFn?: () => void;
}

export interface IAlternatePhoneNumberValues {
  alternateNumber: string;
  alternateNumberOwnerName: string;
}

export const alternatePhoneNumberFormBlockName = 'alternate-phone-number-form';

const schema: () => FormSchemaEntry[] = () => [
  {
    name: 'alternateNumber',
    type: 'phone',
    required: true,
    minLength: 10,
    props: { maxLengthDisplay: false, placeholder: i18n.t(translationKeys.phoneNumberPlaceholder) },
  },
  {
    name: 'alternateNumberOwnerName',
    type: 'text',
    required: true,
    minLength: 2,
    maxLength: 255,
    props: { placeholder: i18n.t(translationKeys.ownerPlaceholder) },
  },
];

const AlternatePhoneNumberFormComp: React.FC<PropsWithClassName<IAlternatePhoneNumberFormProps>> = ({
  onSubmit,
  initialValues,
  onCancel,
  closeFn,
  buttonsPortal,
}): JSX.Element => {
  const bem = bemBlock(alternatePhoneNumberFormBlockName);
  const { t } = useTranslation();

  const handleSubmit = formData => {
    onSubmit?.(formData);
    closeFn?.();
  };

  return (
    <Form
      initialValues={initialValues}
      schema={schema()}
      vertical
      className={bem()}
      id="alt-phone-number-form"
      restoreAfterUpdate
      render={({ isValid, isSubmitting, values }) => {
        const buttons = (
          <>
            <Button variant="default" onClick={onCancel} className={bem('button-cancel')}>
              {t(translationKeys.alternatePhoneNumberNotNow)}
            </Button>
            <Button
              className={bem('button-submit')}
              variant="primary"
              type="submit"
              form="alt-phone-number-form"
              onClick={() => handleSubmit(values)}
              loading={isSubmitting}
              disabled={!isValid}
              preventClickWhenDisabled
            >
              {t(translationKeys.alternatePhoneNumberSubmit)}
            </Button>
          </>
        );

        return (
          <>
            <FormField name="alternateNumber" />
            <FormField name="alternateNumberOwnerName" />
            {buttonsPortal ? ReactDOM.createPortal(buttons, buttonsPortal()) : buttons}
          </>
        );
      }}
    />
  );
};
export const AlternatePhoneNumberForm = withComponentErrorBoundary(AlternatePhoneNumberFormComp);
