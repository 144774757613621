import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import type { RecipientType } from '@gik/create/enums/RecipientType';
import { recipientInfoFormSchema } from '@gik/create/formSchemas';
import { translationKeys } from '@gik/create/i18n/en';
import type { FormProps, ValidationError } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import type { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IRecipientInfoFormProps extends Omit<FormProps, 'onSubmit' | 'onChange'> {
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
  onSubmit?: (values: IRecipientInfoFormValues, form: FormikProps<object>) => void;
  onChange?: (values: IRecipientInfoFormValues, form: FormikProps<object>) => void;
  form: FormikProps<object>;
  isOrganization?: boolean;
}

export interface IRecipientInfoFormValues {
  recipientType: RecipientType;
  email: string;
  [RecipientInfoStepInputNames.AddAsPageOrganizer]: boolean;
}

export interface IRecipientInfoFormErrors {
  recipientType?: ValidationError;
  email?: ValidationError;
}

const blockName = 'recipient-info-form';

export const formId = 'CreateRecipientInfoStepForm';

function RecipientInfoForm(
  {
    className,
    form,
    onSubmit = noop,
    onChange = noop,
    buttons,
    buttonsPortal,
    isOrganization = false,
    ...otherProps
  }: IRecipientInfoFormProps,
  ref: React.Ref<unknown>
) {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  let formRenderProps: FormikProps<object>;
  let submitForm: Function;

  React.useImperativeHandle(ref, () => ({
    submit(event) {
      submitForm(event);
    },
  }));

  function handleSubmit(values: IRecipientInfoFormValues) {
    onSubmit(values, formRenderProps);
  }

  function handleChange(values: IRecipientInfoFormValues) {
    onChange(values, formRenderProps);
  }

  const fields = (form: FormikProps<object>) => {
    const values = form.values;
    return (
      <div className="gik-form">
        <h2 className="gik-create-section-title">
          {t(isOrganization ? translationKeys.OrganizationInfoTitle : translationKeys.RecipientsInfoTitle).toString()}
        </h2>
        <p>
          <HTMLParser
            rawHtml={t(
              isOrganization ? translationKeys.OrganizationInfoDescription : translationKeys.RecipientsInfoDescription
            )}
          />
        </p>

        <FormField name={RecipientInfoStepInputNames.RecipientName} />
        <FormField name={RecipientInfoStepInputNames.RecipientEmail} />
        <FormField name={RecipientInfoStepInputNames.RecipientEmailConfirm} />

        {!isOrganization && (
          <p>
            <HTMLParser rawHtml={t(translationKeys.RecipientsInfoDescription2)} />
          </p>
        )}

        <FormField
          name={RecipientInfoStepInputNames.AddAsPageOrganizer}
          disabled={
            !values ||
            !values[RecipientInfoStepInputNames.RecipientName] ||
            !values[RecipientInfoStepInputNames.RecipientEmail] ||
            !values[RecipientInfoStepInputNames.RecipientEmailConfirm]
          }
        />

        <FormButtons buttons={buttons} buttonsPortal={buttonsPortal} form={form} formId={formId} />
      </div>
    );
  };

  if (form) {
    return fields(form);
  }

  return (
    <Form
      className={bem(null, null, [className, 'gik-form--white'])}
      onSubmit={handleSubmit}
      onChange={handleChange}
      schema={recipientInfoFormSchema(isOrganization)}
      vertical
      id={formId}
      render={(form: FormikProps<object>) => {
        formRenderProps = form;
        submitForm = form.handleSubmit;
        return fields(form);
      }}
      {...otherProps}
    />
  );
}

export default React.forwardRef(RecipientInfoForm);
