import { useQuery } from '@tanstack/react-query';
import { giftWalletEndPoints as endPoints } from '@gik/platform-management-api-js';
import { usePlatformManagementClient } from '../../contexts';
import type { StrapiPagedDataSource, StrapiSearchParams } from '@gik/platform-management-api-js';
import type { GiftActivityLog, GiftEntity } from '@gik/platform-management-api-js';
import type { ReactQueryOptions } from '../../types/react-query';
import { createPathWithSearchParams } from '../../utils/createPathWithSearchParams';
import { useQueryDefaults } from '../../client';
import type { GyGiftEntity } from '@gik/giftya-api-js/types/api.gift.types';
import type { GyCreditTransaction } from '@gik/giftya-api-js/types/api.transaction.types';
import type { GyEgiftEntity } from '@gik/giftya-api-js/types/api.egift.types';
import type { GyLinkNewCardsResponse } from '@gik/giftya-api-js/types/api.card.types';

/**
 * List Gifts
 */
export function useGifts(params?: StrapiSearchParams, options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.list(), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.gift.list(params)).data,
  });
}

/**
 * Get Gift by id
 */
// export function useGift(id?: string, params?: StrapiSearchParams, options?: ReactQueryOptions<GiftEntity>) {
//   const { client } = usePlatformManagementClient();
//   const key = endPoints.gift.getByUuid(id);
//   return useQuery({
//     ...options,
//     // @ts-ignore
//     queryKey: [key],
//     queryFn: async () => (await client.gift.get(id, { params })).data,
//   });
// }

/**
 * Get Gift by id detailed
 */
export function useGiftgetByUuidDetailed(
  uuid?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<GiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.getByUuidDetailed(uuid);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.gift.getDetailed(uuid, { params })).data,
  });
}

export function useGiftByUuid(uuid?: string, params?: Record<string, never>, options?: ReactQueryOptions<GiftEntity>) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getByUuid(uuid), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.gift.getDetailed(uuid, { params })).data,
  });
}

/**
 * Get Gift activity log
 */
export function useGiftActivityLog(
  giftId?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftActivityLog>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.getActivityLog(giftId);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.gift.getActivityLog(giftId, { params })).data,
  });
}

/**
 * Get Gift history
 */
export function useGiftHistory(
  giftId?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftActivityLog>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.getGiftHistory(giftId);
  return useQuery({
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () => (await client.gift.getActivityLog(giftId, { params })).data,
  });
}

/**
 * Get Gift Linked Card Transaction History
 */
export function useCardTransactionHistoryByGyGiftid(
  giftId?: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GyCreditTransaction>>
) {
  const { client } = usePlatformManagementClient();
  const key = endPoints.gift.getCardTransactionHistoryByGyGiftid(giftId);
  return useQuery({
    ...options,
    queryKey: [key],
    queryFn: async () => (await client.gift.getCardTransactionHistoryByGyGiftid(giftId, { params })).data,
  });
}

export function useRemoteGift(
  uuid?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<GyGiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getRemoteGift(uuid), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.getRemoteGift(uuid, {
          params,
        })
      ).data,
  });
}

export function useRemoteEGift(
  id?: string,
  params?: Record<string, never>,
  options?: ReactQueryOptions<GyEgiftEntity>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.getRemoteEGift(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.getRemoteEGift(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerId(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerId(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerId(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdRedeemableGrouped(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdRedeemableGrouped(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdRedeemableGrouped(id, {
          params,
        })
      ).data,
  });
}
export function useGiftsByCustomerIdRedeemable(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdRedeemable(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdRedeemable(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdMore(
  id: string,
  baseGiftSourceProductId: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdMore(id, baseGiftSourceProductId), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdMore(id, baseGiftSourceProductId, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdUnused(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdUnused(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdUnused(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdActive(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdActive(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdActive(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdUsed(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdUsed(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdUsed(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdActiveLinked(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdActiveLinked(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdActiveLinked(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdUsedLinked(
  id: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdUsedLinked(id), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdUsedLinked(id, {
          params,
        })
      ).data,
  });
}

export function useGiftsByCustomerIdLinkedToCard(
  id: string,
  cardId: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.listByCustomerIdLinkedToCard(id, cardId), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.listByCustomerIdLinkedToCard(id, cardId, {
          params,
        })
      ).data,
  });
}

export function usePaymentCardsByCustomerId(
  customerId: string,
  cardId: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<StrapiPagedDataSource<GiftEntity>>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.usePaymentCardsByCustomerId(customerId, cardId), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.usePaymentCardsByCustomerId(customerId, cardId, {
          params,
        })
      ).data,
  });
}

export function useGiftCardLinkingSession(
  giftUuid: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<GyLinkNewCardsResponse>
) {
  const { client } = usePlatformManagementClient();
  // const key = createPathWithSearchParams(endPoints.gift.getCardLinkingSession(giftUuid), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    // queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.getCardLinkingSession(giftUuid, {
          params,
        })
      ).data,
  });
}

export function useInvalidateGiftCardLinkingSession(
  sessionId: string,
  params?: StrapiSearchParams,
  options?: ReactQueryOptions<boolean>
) {
  const { client } = usePlatformManagementClient();
  const key = createPathWithSearchParams(endPoints.gift.invalidateCardLinkingSession(sessionId), params);
  return useQuery({
    ...useQueryDefaults,
    ...options,
    // @ts-ignore
    queryKey: [key],
    queryFn: async () =>
      (
        await client.gift.invalidateCardLinkingSession(sessionId, {
          params,
        })
      ).data,
  });
}
