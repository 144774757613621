import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpAcfV3Api } from '@gik/core/api/ky/wpApi';
import { useWPAcfV3Api } from '@gik/core/api/swr/useWPAcfV3Api';
import type { IWordpressPageNameSuggestion } from '@gik/core/models/wordpress/WordpressSituation';

const path = 'options/options/page_name_suggestions';

export interface IGeneralPageNameSuggestionsResponse {
  page_name_suggestions: IWordpressPageNameSuggestion[];
}

export function getGeneralPageNameSuggestions() {
  return wpAcfV3Api.get(path).json<IGeneralPageNameSuggestionsResponse>();
}

export function useGeneralPageNameSuggestions(config?: SWRConfigInterface) {
  return useWPAcfV3Api<IGeneralPageNameSuggestionsResponse>(path, null, config);
}
