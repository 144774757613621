import TranslationUtils from '@gik/core/utils/TranslationUtils';

const prefix = 'crm-supporters';

const translations = {
  returnToInkindPage: 'Return To InKind Page',
  supporters: 'Supporters',
  supportersNote: `<strong>Note:</strong> We are unable to track activity on third-party sites, such as GoFundMe,
  <br class="gik-hidden-lg-down" /> PayPal, or external sites used to fulfill Wishlist purchases.`,
  pageOrganizer: 'Page organizer',
  noSupportYet: 'No support given yet',
  noResultsForFilter: 'No results for "{{filter}}"',
  emptyTitle: 'Your first supporter, coming soon.',
  pendingFulfillment: 'PENDING',
  // TODO: move to core
  tangoCardFulfillmentInfo: 'Gift Cards are typically fulfilled and sent to the recipient in 24 hours',
  emptyDescription:
    'Share your Give InKind Page below to let<br class="gik-hidden-md-down" /> everyone know how to help and support.',
  exportFiltered: 'This List',
  exportAll: 'Full List',
  paginationDetailsSupporters: 'Showing {{current}} of {{total}} Supporters',
  paginationDetailsInvitees: 'Showing {{current}} of {{total}} Invitees',
  eventFollow: 'Started following your InKind Page',
  eventUnfollow: 'Stopped following your InKind Page',
  eventClaim: `Claimed {{name}} for <strong>{{date}}</strong>`,
  eventUnclaim: `Unclaimed {{name}} for <strong>{{date}}</strong>`,
  thankYouSent: `Thank You sent by {{name}}`,
  eventGiftedCalendar: 'Gifted ${{price}} {{name}} for {{typeName}} on <strong>{{date}}</strong>',
  eventGifted: 'Gifted ${{price}} {{name}}',
  premiumPurchased: 'Purchased Premium Page Upgrade',
  inviteSupporters: 'Invite Supporters',
  invite: 'Invite',
  resendInvitation: 'Resend Selected',
  removeInvitee: 'Remove Selected',
};

export default {
  [prefix]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(prefix, translations);
