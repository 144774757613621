import { isBrowser } from '@/utils/environment';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { UI } from '@gik/ui/UIManager';
import copy from 'copy-to-clipboard';

// TODO: this can probably live somewhere else
export function extractInkindPageRouteId(): string {
  if (!isBrowser()) return null;
  if (window.location.pathname.includes('/inkinds/') && !window.location.pathname.endsWith('/wishlist')) {
    return window.location.pathname.split('/')[2];
  } else {
    return undefined;
  }
}
// TODO: this can probably live somewhere else
export function copyWithNotification(textToCopy: string, notificationText: string) {
  copy(textToCopy);
  UI.notifySuccess(notificationText);
}

export function getBaseUrl(): string {
  if (!isBrowser()) return '/';

  // TODO: review after full SPA integration
  return useEnvStore.getState().BASE_URL || '/';
}

/**
 * Retrieve assets hosted on the GIK domain (e.g. user profile images)
 */
export function getAssetsUrl(): string {
  if (!isBrowser()) return '/';

  // Our React app is not hosted in the same location
  // as the MVC website. e.g. blob storage.
  // Locally, it will be /react/dist/.
  // if no `window.__root` then it means it's storybook so we can use `/`
  // TODO: review after full SPA integration
  //  vercel provides process.env.VERCEL_URL for absolute URL to where this app is deployed to
  return useEnvStore.getState().ASSETS_URL || '/';
}

/**
 * Retrieve assets hosted in the React app blob storage (e.g. assets copied by webpack)
 */
export function getCDNAssetsUrl(): string {
  if (!isBrowser()) return '/';

  // note: right now CDN_ASSETS_URL should always match BASE_URL, but that might change in the future
  return useEnvStore.getState().CDN_ASSETS_URL || '/';
}
