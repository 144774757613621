import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import type { BaseBoneProps } from './BaseBone';
import { ParagraphBone } from './ParagraphBone';

const skelBem = bemBlock(`breadcrumbs-bone`);
interface BreadCrumbsBoneProps extends BaseBoneProps {
  words?: number;
}

export function BreadCrumbsBone({ className, words = 4 }: BreadCrumbsBoneProps) {
  return (
    <ParagraphBone words={words} minWidth={40} maxWidth={80} className={skelBem(undefined, undefined, className)} />
  );
}
