import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { InkindOrganizerSettings, Organizer, OrganizerInvitation } from '@gik/core/models/gik/InkindPage';

const PATH = 'inkinds';

export async function inviteUserAsPageOrganizer(routeId: string, invitation: OrganizerInvitation): Promise<void> {
  const url = `${PATH}/${routeId}/organizers`;
  await dotnetApi.post(url, { json: invitation });
}

export async function cancelOrganizerInvitation(routeId: string, emailAddress: string): Promise<void> {
  const url = `${PATH}/${routeId}/invitees`;
  await dotnetApi.delete(url, { json: { emailAddress } });
}

export async function setOrganizerSettings(
  routeId: string,
  userId: string,
  settings: InkindOrganizerSettings
): Promise<void> {
  const url = `${PATH}/${routeId}/organizers/${userId}`;
  await dotnetApi.put(url, { json: settings });
}

export async function setOwnerSettings(routeId: string, settings: InkindOrganizerSettings): Promise<void> {
  const url = `${PATH}/${routeId}/owner`;
  await dotnetApi.put(url, { json: settings });
}

export async function removePageOrganizer(routeId: string, userId: string): Promise<void> {
  const url = `${PATH}/${routeId}/organizers/${userId}`;
  await dotnetApi.delete(url);
}

export function getPagePendingInvitees(inkindRouteId: string) {
  return dotnetApi.get(`${PATH}/${inkindRouteId}/pendingInvitations`);
}

export function usePagePendingInvitees(inkindRouteId?: string, config?: SWRConfigInterface) {
  return useApi<Organizer[]>(inkindRouteId ? `${PATH}/${inkindRouteId}/pendingInvitations` : null, null, config);
}
