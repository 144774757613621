import TranslationUtils from '@gik/core/utils/TranslationUtils';
const blockName = 'tips-sample-page';

const translations = {
  // header
  title: 'Tips & Sample InKind Pages',
  samplePages: 'Sample InKind Pages',
  leadDescription:
    'Whether you are looking to start an InKind Page for yourself or someone else, or are looking for ways to support a loved one, Give InKind is here to help guide you every step of the way. See some helpful tips and sample InKind Pages below.',

  pageTitle: 'InKind Page Tips',

  organizersTitle: 'For Organizers',
  organizersDescription:
    'Setting up an InKind Page is a great way to kick off support. You’ll just need a few minutes and a few details to get started.',

  organizersFAQ1Title: 'Gather the information you’ll need to set up your InKind Page',
  organizersFAQ1Content:
    'You’ll want to know an email and street address for deliveries, any dietary and privacy preferences and what kinds of help are needed most. <a href="/benefits#how-others-are-using-inkind">See how others use Give InKind to organize support in different situations</a>.',
  organizersFAQ2Title: 'Invite a co-organizer',
  organizersFAQ2Content:
    'Whether the InKind Page is for yourself or someone else, inviting a co-organizer can help you keep track of support.',
  organizersFAQ3Title: 'Add a Photo and Story to your InKind Page to help generate support',
  organizersFAQ3Content:
    'When Supporters come to your InKind Page, it helps to personalize it with a photo and story to let them know who and what the InKind Page is for.',
  organizersFAQ4Title: 'Use the Special Notes section for important information',
  organizersFAQ4Content:
    'The Special Notes section is a great place to include food allergies and preferences, delivery instructions, important links, or other helpful information for supporters. It helps to be specific.',
  organizersFAQ5Title: 'Use the Wishlist as a <a href="/articles/build-gift-card-train">Gift Card Train</a>',
  organizersFAQ5Content:
    'Picture a meal train, but with gift cards. Give InKind has hundreds of thoughtful and meaningful gifts, hand curated for each situation, and includes over 200 gift cards to choose from. Add them to your Wishlist to let people know what would be most helpful.',
  organizersFAQ6Title: 'Connect your GoFundMe or Paypal account, <em>or<em> both',
  organizersFAQ6Content:
    'The benefit of connecting both your GoFundMe and Paypal is to give your supporters options. But at the end of the day, do what your InKind Page recipient is most comfortable with.',
  organizersFAQ7Title: 'Share your InKind Page to spread the word',
  organizersFAQ7Content:
    'Invite others to share in the support by emailing the InKind Page link to friends and family or share the link on your preferred social media channel. For Instagram, paste the link in your profile to allow people to directly click on it.',
  organizersFAQ8Title: 'Contact our Support Team if you have additional questions',
  organizersFAQ8Content:
    'Do you have more questions about creating an InKind Page? Chat with a live Give InKind Representative (hyperlink to chat). We’re on your team.',

  supportersTitle: 'For Supporters',
  supportersDescription:
    'It can be really hard to know how to help. Every situation is different, but InKind Pages make it easy to understand what’s needed most.',

  supportersFAQ1Title: 'Explore the different ways to help',
  supportersFAQ1Content:
    "An InKind Page will tell you exactly what's needed. Choose something that is also meaningful to you, whether it's sending a meal, purchasing a thoughtful item off their Wishlist, or sending a donation to help with financial needs (or when in doubt, send a Gift Box).",
  supportersFAQ2Title: 'Read details carefully',
  supportersFAQ2Content:
    'The InKind Page Story and Special Notes sections often contain important details such as dietary restrictions, food delivery instructions, etc.',
  supportersFAQ3Title: 'Learn more about their situation and how to support them',
  supportersFAQ3Content:
    'Read an article or guide from our <a href="/articles">blog</a> that can help you understand what someone is going through, with actionable ideas on ways to show up.',
  supportersFAQ4Title: 'Follow your loved one’s InKind Page',
  supportersFAQ4Content:
    'Stay up-to-date with your loved one by clicking “Follow Page” on their InKind Page to receive notifications of news and updates.',

  recipientsTitle: 'For Recipients',
  recipientsDescription:
    'Being open to support can be the hardest part! We’re here to normalize showing up for one another - because we’re stronger together.',

  recipientsFAQ1Title: 'It’s ok to receive help',
  recipientsFAQ1Content:
    'It can be hard to allow others to help. Receiving help is just as much for the person wanting to help as it is to the person receiving it. Supporting one another is innate in all of us. So if you have people wanting to help, let them.',
  recipientsFAQ2Title: 'Don’t be afraid to share what you need',
  recipientsFAQ2Content:
    'We’ve made it as easy as 1-2-3. Start by thinking of three tasks someone could help you with and add them to your Care Calendar. Then, see if you can add three gift cards to your Wishlist. Finally, share your InKind Page with friends and family and get the support that you need. Your supporters will appreciate knowing how to help and you’ll have a few less things to worry about.',
  recipientsFAQ3Title: 'Delegate organizing your InKind Page to someone else if people are asking how to help',
  recipientsFAQ3Content:
    'Ask a friend or family member to help setup or manage your InKind Page. They can share updates with your Supporters, and you can focus on you.',
  recipientsFAQ4Title: 'Read our curated content for additional support',
  recipientsFAQ4Content:
    'Give InKind has situation-specific content to help you through life’s important or challenging moments. Read stories about people with similar life experiences on our <a href="/articles">blog</a>.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
