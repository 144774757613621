import Link from '@/components/Link';
import type { Hit } from '@algolia/client-search';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { useInkindCan } from '@gik/core/store/permissions';
import bemBlock from '@gik/core/utils/bemBlock';
import type { InkindAlgoliaObject } from '@gik/search/components';
import { PageAvatar } from '@gik/ui/Avatar';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { useInkindAPIOrAlgolia } from '@gik/user-profile/hooks/useInkindAPIOrAlgolia';
import React from 'react';

export type PageRowItemProps = {
  page: InkindPageAPIModel | Hit<InkindAlgoliaObject>;
  className: string;
  href?: string;
  onClick?(page: InkindPageAPIModel | Hit<InkindAlgoliaObject>): void;
};

export function PageRowItem({ page, onClick, className, href }: PageRowItemProps): React.ReactElement {
  const bem = bemBlock('page-row-item');
  const { title, imgSrc, routeId, groupId } = useInkindAPIOrAlgolia(page);
  const canEdit = useInkindCan('manage', routeId, groupId);

  return (
    <li className={bem(null, [{ organizer: canEdit }], className)}>
      <Link href={href} className={bem('container')} onClick={() => onClick?.(page)}>
        <PageAvatar className={bem('avatar')} imageUrl={imgSrc} circle={false} rounded />
        <span className={bem('title')}>
          <HTMLParser rawHtml={title} />
        </span>
      </Link>
    </li>
  );
}
