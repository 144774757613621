/*import { deleteEvent } from '@gik/api/calendar/calendarEvents';
import { translationKeys as announcementTranslationKeys } from '@gik/calendar/components/Announcements/i18n/en';
import i18n from '@gik/i18n';
import { translationKeys as commonTranslationKeys } from '@gik/i18n/en/common';
import { UI } from '@gik/ui/UIManager';*/
import { timeToInt } from 'time-number';
import { CalendarEventTypeIds } from '../models/CalendarEventTypes';

export function getStartTimeByTypeId(typeId: number) {
  // update time if type is breakfast, lunch or dinner
  switch (typeId) {
    case CalendarEventTypeIds.Breakfast:
      return timeToInt('08:00');
    case CalendarEventTypeIds.Lunch:
      return timeToInt('11:00');
    case CalendarEventTypeIds.Dinner:
      return timeToInt('17:00');
    default:
      return timeToInt('12:00');
  }
}

/*export async function deleteAnnouncement(entryId: string, instanceDate?: string) {
  const shouldDelete = await UI.confirm(i18n.t(announcementTranslationKeys.deleteAnnouncementConfirmation), {
    title: i18n.t(announcementTranslationKeys.deleteAnnouncementConfirmationTitle),
    okButtonProps: { variant: 'danger' },
    okText: i18n.t(commonTranslationKeys.delete),
  });

  if (!shouldDelete) return false;

  return await deleteEvent(entryId, {
    instanceDate,
  });
}*/
