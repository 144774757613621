import type { ClaimConflictResolve, OrderStatus } from '@gik/checkout/api';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { StripeCardElement } from '@stripe/stripe-js';
import React from 'react';

export type CheckoutFormProgress = 0 | 1 | 2 | 3;

export interface ICheckoutFormModalContext {
  isSubmitting?: boolean;
  setIsSubmitting?(value: boolean): void;
  isSubmitted?: boolean;
  setIsSubmitted?(value: boolean): void;
  anonymous: boolean;
  setAnonymous?(value: boolean): void;
  selectedShippingOptionName: string;
  setSelectedShippingOptionName?(value: string): void;
  privateClaim: boolean;
  setPrivateClaim?(value: boolean): void;
  cardElement?: StripeCardElement;
  setCardElement?(cardElement: StripeCardElement): void;
  modalContentRef: React.MutableRefObject<HTMLDivElement>;

  messageToRecipient: string;
  setMessageToRecipient?(value: string): void;

  suggestedResolves: ClaimConflictResolve[];
  setSuggestedResolves?(value: ClaimConflictResolve[]): void;

  mainCart?: CartItem[];
  setMainCart?(cart: CartItem[]): void;

  orderStatus?: OrderStatus;
  setOrderStatus?(value: OrderStatus): void;
}

export const CheckoutFormModalContext = React.createContext<ICheckoutFormModalContext>(null);
