import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type {
  IOAuthUserLoginResponse,
  IUserLoginFormResponse,
  IUserLoginResponse,
  IUserSignUpResponse,
} from '@gik/api/users/types';
import { auth, LoginForm, SignupForm } from '@gik/auth';
import ExternalLoginForm from '@gik/auth/components/ExternalLogin/ExternalLoginForm';
import { performLogin } from '@gik/auth/utils/auth';
import { ExternalLoginProvider, LocalLoginProvider } from '@gik/auth/utils/LoginProvider';
import bemBlock from '@gik/core/utils/bemBlock';
import sleep from '@gik/core/utils/sleep';
import { useCreateFlowStore } from '@gik/create';
import type { ICreateAboutFormValues } from '@gik/create/components/AboutStep/AboutStep';
import { CreatePageContext } from '@gik/create/components/CreatePage/CreatePage';
import CreatePageSplitLayout from '@gik/create/components/CreatePageSplitLayout/CreatePageSplitLayout';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { EmailTakenStatus } from '@gik/create/enums/EmailTakenStatus';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import coreTranslationKeys from '@gik/i18n/en/core';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormButtons, FormField } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { UI } from '@gik/ui/UIManager';
import React from 'react';

export interface IFinishStepProps {
  onSubmit?: (values: Pick<ICreateAboutFormValues, AboutStepInputNames.Email>) => void;
  onSuccess?: (provider?: ExternalLoginProvider) => void;
}

const blockName = 'finish-step';

export default function FinishStep({ onSubmit, onSuccess }: IFinishStepProps) {
  const bem = bemBlock(blockName);
  const emailTakenStatus = useCreateFlowStore(state => state.emailTakenStatus);
  const buttonsRef = React.useRef();
  // prettier-ignore
  const [changeEmailFormValues, setChangeEmailFormValues] = React.useState<{
    [AboutStepInputNames.Email]: string;
    confirmEmail: string;
  }>();

  React.useEffect(() => {
    if (changeEmailFormValues) {
      onSubmit?.({ [AboutStepInputNames.Email]: changeEmailFormValues[AboutStepInputNames.Email] });
    }
    // eslint-disable-next-line
  }, [changeEmailFormValues]);

  const url = new URL(window.location.toString());
  url.searchParams.set('finish', 'true');

  async function openChangeEmailModal() {
    const schema: FormSchemaEntry[] = [
      {
        type: 'email',
        placeholder: 'Email',
        name: AboutStepInputNames.Email,
      },
    ];

    const formId = bem('change-email');

    // TODO we should abstract this into a UI.formDialog or something
    await UI.confirm(
      <div className={bem('change-email-content')}>
        <div className={bem('change-email-description')}>
          {i18n.t(translationKeys.ChangeEmailDescription).toString()}
        </div>
        <Form
          schema={schema}
          trackingId={AnalyticsEvents.CreateStarted}
          onSubmit={setChangeEmailFormValues}
          id={formId}
          render={form => {
            return (
              <>
                {schema.map((field, i) => (
                  <FormField key={i} name={field.name} />
                ))}

                <FormButtons
                  buttons={({ submitting, valid }) => (
                    <Button
                      type="submit"
                      onClick={() => UI.closeDialog()}
                      form={formId}
                      disabled={submitting || !valid}
                    >
                      {i18n.t(coreTranslationKeys.core.update).toString()}
                    </Button>
                  )}
                  buttonsPortal={() => buttonsRef.current}
                  form={form}
                  formId={formId}
                />
              </>
            );
          }}
        />
      </div>,
      {
        title: i18n.t(translationKeys.ChangeEmail),
        buttons: () => {
          return (
            <div ref={buttonsRef} className={bem('change-email-buttons')}>
              <Button variant="default" onClick={() => UI.closeDialog()}>
                {i18n.t(coreTranslationKeys.core.cancel).toString()}
              </Button>
            </div>
          );
        },
      }
    );
  }

  // TODO: remove success and all translations etc.
  switch (emailTakenStatus) {
    case EmailTakenStatus.FREE:
      return <SignUpStep onSuccess={onSuccess} openChangeEmailModal={openChangeEmailModal} />;
    case EmailTakenStatus.GIK:
      return <LoginStep onSuccess={onSuccess} openChangeEmailModal={openChangeEmailModal} />;
    case EmailTakenStatus.FACEBOOK:
      return (
        <FacebookLogin
          onSuccess={() => onSuccess(ExternalLoginProvider.FACEBOOK)}
          openChangeEmailModal={openChangeEmailModal}
        />
      );
    case EmailTakenStatus.GOOGLE:
      return (
        <GoogleLogin
          onSuccess={() => onSuccess(ExternalLoginProvider.GOOGLE)}
          openChangeEmailModal={openChangeEmailModal}
        />
      );
    default:
      return (
        <div className={bem('loading')}>
          <LoadingSpinner center />
        </div>
      );
  }
}

function SignUpStep({ onSuccess, openChangeEmailModal }) {
  const bem = bemBlock(blockName);
  const formValues = useCreateFlowStore(state => state.formValues);
  const setNewUserLoginProvider = useCreateFlowStore(state => state.setNewUserLoginProvider);
  const setFinishButtonSubmitting = useCreateFlowStore(state => state.setFinishButtonSubmitting);
  const { finishFormRef } = React.useContext(CreatePageContext);
  const setNextDisabled = useCreateFlowStore(state => state.setNextDisabled);
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);

  function handleSuccess(response: IUserSignUpResponse) {
    setNewUserLoginProvider(LocalLoginProvider.EMAIL);
    performLogin(response.userId, response.refreshToken, response.refreshTokenHash, response.redirectUrl);
    onSuccess();
  }

  const handleChange = React.useCallback(async () => {
    await sleep(200);

    // setNextDisabled(!finishFormRef?.current?.isValid);
  }, [finishFormRef, setNextDisabled]);

  return (
    <CreatePageSplitLayout className={bem(null, [{ signup: true }])}>
      <main>
        <div className={bem('signup-title')}>{i18n.t(translationKeys.SignUpStepTitle).toString()}</div>
        <div className={bem('signup-description')}>
          <HTMLParser
            rawHtml={i18n.t(translationKeys.SignUpStepDescription, { emailAddress: formValues.emailAddress })}
          />
        </div>
        <Button className={bem('switch-address')} onClick={openChangeEmailModal} variant={'primary-link'}>
          {i18n.t(translationKeys.SwitchAddress).toString()}
        </Button>

        <ExternalLoginForm
          actionLabel="Sign-up with"
          onSuccess={handleSuccess}
          onLoadingStart={() => {
            setFormDisabled(true);
          }}
          onLoadingFinish={() => {
            setFormDisabled(false);
          }}
        />
      </main>
      <aside>
        <div className={bem('signup-form-wrapper')}>
          <SignupForm
            onSuccess={handleSuccess}
            onAttemptStart={() => setFinishButtonSubmitting(true)}
            onAttemptEnd={() => setFinishButtonSubmitting(false)}
            onChange={handleChange}
            ref={finishFormRef}
            initialValues={{ email: formValues.emailAddress, subscribeToNewsletter: true }}
            trackingId={AnalyticsEvents.CreateStarted}
            hideEmailField
            hideButtons
            disabled={formDisabled}
          />
        </div>
      </aside>
    </CreatePageSplitLayout>
  );
}

function LoginStep({ onSuccess, openChangeEmailModal }) {
  const bem = bemBlock(blockName);
  const formValues = useCreateFlowStore(state => state.formValues);
  const setFinishButtonSubmitting = useCreateFlowStore(state => state.setFinishButtonSubmitting);
  const setNextDisabled = useCreateFlowStore(state => state.setNextDisabled);
  const { finishFormRef } = React.useContext(CreatePageContext);

  function handleSuccess(response: IUserLoginFormResponse) {
    performLogin(response.userId, response.refreshToken, response.refreshTokenHash, response.redirectUrl);
    onSuccess();
  }

  function handleChange() {
    // setNextDisabled(!finishFormRef?.current?.isValid);
  }

  return (
    <div className={bem(null, [{ login: true }])}>
      <div className={bem('login-title')}>{i18n.t(translationKeys.LoginTitle).toString()}</div>
      <div className={bem('login-email-address')}>{formValues.emailAddress}</div>
      <Button onClick={openChangeEmailModal} className={bem('login-switch-address')} variant={'primary-link'}>
        {i18n.t(translationKeys.SwitchAddress).toString()}
      </Button>

      <LoginForm
        onSuccess={handleSuccess}
        onAttemptStart={() => setFinishButtonSubmitting(true)}
        onAttemptEnd={() => setFinishButtonSubmitting(false)}
        onChange={handleChange}
        ref={finishFormRef}
        initialValues={{ email: formValues.emailAddress, rememberMe: true }}
        trackingId={AnalyticsEvents.CreateStarted}
        onForgotPassword={() => auth.resetPassword()}
        hideEmailField
        hideSignup
      />
    </div>
  );
}

function ExternalLogin({
  provider,
  openChangeEmailModal,
  onSuccess,
}: {
  provider: ExternalLoginProvider;
  openChangeEmailModal: () => Promise<void>;
  onSuccess: (response: IUserLoginResponse | IOAuthUserLoginResponse) => void;
}) {
  const bem = bemBlock(blockName);
  const formValues = useCreateFlowStore(state => state.formValues);

  function handleSuccess(response: IUserLoginResponse | IOAuthUserLoginResponse) {
    performLogin(response.userId, response.refreshToken, response.refreshTokenHash, response.redirectUrl);
    onSuccess(response);
  }

  return (
    <div className={bem(null, [{ login: true }, { [provider]: true }])}>
      <div className={bem('login-title')}>{i18n.t(translationKeys.LoginTitle)}</div>
      <div className={bem('external-login-email-address')}>
        <HTMLParser
          rawHtml={i18n.t(translationKeys.ExternalLoginDescription, {
            emailAddress: formValues.emailAddress,
            provider,
          })}
        />
      </div>

      <Button onClick={openChangeEmailModal} className={bem('login-switch-address')} variant={'primary-link'}>
        {i18n.t(translationKeys.SwitchAddress)}
      </Button>

      <ExternalLoginForm filter={provider} onSuccess={handleSuccess} />
    </div>
  );
}

const FacebookLogin = ({ openChangeEmailModal, onSuccess }) => (
  <ExternalLogin
    provider={ExternalLoginProvider.FACEBOOK}
    openChangeEmailModal={openChangeEmailModal}
    onSuccess={onSuccess}
  />
);

const GoogleLogin = ({ openChangeEmailModal, onSuccess }) => (
  <ExternalLogin
    provider={ExternalLoginProvider.GOOGLE}
    openChangeEmailModal={openChangeEmailModal}
    onSuccess={onSuccess}
  />
);
