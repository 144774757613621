import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'inkind-uses';

const translations = {
  title: 'How others are using Give InKind',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
