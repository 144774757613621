import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Switch } from '@gik/ui/Switch';
import React from 'react';

export type DevToggleValueProps = {
  label: string;
  value: boolean;
  onChange?(value: boolean): void;
} & UIComponent;

export const DevToggleValue = ({
  className,
  value,
  onChange = noop,
  label,
  ...otherProps
}: React.PropsWithChildren<DevToggleValueProps>) => {
  const bem = useBemCN('dev-toggle-value');

  const [_value, _setValue] = React.useState<boolean>(value);

  function handleValueChange(newValue: boolean) {
    _setValue(newValue);
    onChange(newValue);
  }

  return (
    <div {...bem(null, null, [className])} {...otherProps}>
      <Switch labelBefore={label} checked={_value} onValueChange={handleValueChange} />
    </div>
  );
};
