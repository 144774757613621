import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import repeatElement from '@gik/core/utils/repeatElement';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import { ButtonBone, FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import { InterrogationRed } from '@gik/ui/SvgIcon/GikIcons/InterrogationRed';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import type { IInkindPageSettingsFormProps } from './InkindPageSettingsForm';
import { buttonsNonModalPortal, buttonsNonModalPortalId } from './InkindPageSettingsForm';

export const inkindPageSettingsSkeletonBlockName = 'inkind-page-settings-skeleton';

export const InkindPageSettingsSkeleton: React.FC<Partial<IInkindPageSettingsFormProps>> = ({
  buttonsPortal,
  onClose,
}): React.ReactElement => {
  const bem = bemBlock(inkindPageSettingsSkeletonBlockName);
  const { t } = useTranslation();
  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);

  const modalFooter = buttonsPortal && buttonsPortal();
  const nonModalFooter = buttonsNonModalPortal && buttonsNonModalPortal();

  const skeletonButtons = (
    <footer className={bem('footer', [{ 'is-not-modal': !buttonsPortal }])}>
      <Button
        key="deactivate-page"
        variant="danger"
        onClick={noop}
        prepend={<SvgIcon Icon={TrashIcon} />}
        disabled={true}
      >
        {t(translationKeys.deletePage)}
      </Button>
      <Button variant="default-light" disabled={true} onClick={noop} circle>
        <SvgIcon Icon={InterrogationRed} />
      </Button>
      <Button key="cancel" onClick={() => onClose && onClose()} variant="default">
        {t(translationKeys.cancel)}
      </Button>
      <Button key="save" variant="primary" type="submit" disabled={true} onClick={noop}>
        {t(translationKeys.save)}
      </Button>
    </footer>
  );

  return (
    <>
      <div className={bem(null, [{ 'is-not-modal': !buttonsPortal }])}>
        <ParagraphBone words={2} className={bem('title-1')} />
        <ParagraphBone words={isSmDown ? 5 : 9} className={bem('description-1')} />
        <FrameBone className={bem('input')} />
        <ParagraphBone words={3} className={bem('description-2')} />

        <ParagraphBone words={2} className={bem('title-2')} />
        <ParagraphBone words={isSmDown ? 5 : 9} className={bem('description-3')} />
        {repeatElement(
          2,
          <FrameBone className={bem('card')}>
            <FrameBone className={bem('icon')} />
            <div className={bem('card-text')}>
              <ParagraphBone words={2} />
              <ParagraphBone words={isSmDown ? 3 : 5} />
            </div>
            <ButtonBone words={1} pill className={bem('switch')} />
          </FrameBone>
        )}
      </div>
      <div id={buttonsNonModalPortalId} />
      {/* modalFooter, nonModalFooter checks are required or it will throw runtime error "Target container is not a DOM element" */}
      {modalFooter && ReactDOM.createPortal(skeletonButtons, modalFooter)}
      {!modalFooter && nonModalFooter && ReactDOM.createPortal(skeletonButtons, nonModalFooter)}
    </>
  );
};
