import { WPURLEncoder } from '@gik/core/api/base/hooks/WPURLEncoder';
import { useEnvStore } from '@gik/core/store/EnvStore';

// TODO: rename to CacheURLEncoderKyHook
export async function WPURLEncoderKyHook(request: Request, options) {
  const isRetry = request.url.includes(useEnvStore.getState().API_CACHE_URL);

  if (isRetry) return request;

  if (
    (request.url.startsWith('https://local') &&
      useEnvStore.getState().DISABLE_CACHED_LOCAL_DOTNET_REQUESTS === 'true') ||
    request.url.includes(useEnvStore.getState().API_CACHE_URL)
  ) {
    return request;
  }

  const { url: _wpUrl, ...otherOptions } = request;
  const path = _wpUrl.split('?')[0];
  const cacheUrl = WPURLEncoder(path, options.searchParams, options.bypassCache, options.qsStringifyOptions);

  const newRequest = new Request(cacheUrl.toString(), {
    ...otherOptions,
    ...options,
    headers: new Headers(request.headers),
  });

  return newRequest;
}
