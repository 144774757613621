import type { FormikProps } from 'formik';
import React from 'react';
import type { FormVariant } from '../typesValues';
import type { FormSchemaEntry } from './types';

export interface FormContextProps {
  schema?: FormSchemaEntry[];
  vertical?: boolean;
  disabled?: boolean;
  variant?: FormVariant;
  form: FormikProps<Object>;
}

// function useFormContext(state: GIKFormState): GIKFormState {
//   const [schema] = React.useState(state.schema);
//   const [vertical] = React.useState(state.vertical);
//   const [disabled] = React.useState(state.disabled);
//   const [variant] = React.useState(state.variant);
//   return { schema, vertical, disabled, variant };
// }

export const FormContext = React.createContext<FormContextProps>(null);
