import { auth } from '@gik/auth';
import { ErrorPageSection } from '@gik/core/pages/Error/ErrorPageSection';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { Button } from '@gik/ui/Button';
import { PageSection } from '@gik/ui/gik/PageSection';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ResetPasswordForm } from '.';
import { translationKeys } from './i18n/en';

const blockName = 'reset-password-section';

export interface IForgotPasswordSectionProps {
  buttonsPortal?: () => HTMLElement;
}

function ResetPasswordSectionComp({ buttonsPortal }: IForgotPasswordSectionProps) {
  const bem = useBemCN(blockName);
  const { t } = useTranslation();
  const [success, setSuccess] = React.useState<boolean>(false);

  function handleSuccess() {
    setSuccess(true);
  }

  if (success) {
    return (
      <PageSection {...bem('success-section')}>
        <h1 {...bem('success-title')}>Your password has been reset</h1>
        <Button onClick={() => auth.signin()}>Click here to login</Button>
      </PageSection>
    );
  }

  return (
    <PageSection {...bem()}>
      <h1 {...bem('title')}>{t(translationKeys.title).toString()}</h1>
      <p>{t(translationKeys.description).toString()}</p>
      <ResetPasswordForm buttonsPortal={buttonsPortal} onSuccess={handleSuccess} />
    </PageSection>
  );
}

export const ResetPasswordSection = withComponentErrorBoundary(ResetPasswordSectionComp, {
  ErrorFallback: ErrorPageSection,
});
