import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';

export interface ITableRemotePaginationProps<T extends object> {
  className?: string;
  pages: number;
  page: number;
  perPage: number;
  total?: number;
  onNext?: () => void;
  onPrev?: () => void;
  onGoto?: (num: number) => void;
  onChangePageSize?: (num: number) => void;
  customDetailsDisplay(props): React.ReactElement;
}

export function TableRemotePagination<T extends object>({
  className,
  customDetailsDisplay,
  pages,
  page,
  total,
  perPage,
  onNext = noop,
  onPrev = noop,
  onGoto = noop,
  onChangePageSize = noop,
}: ITableRemotePaginationProps<T>) {
  const bem = useBemCN('table-pagination');

  let totalDisplayedDots = 3; // note: this should be an odd number
  if (totalDisplayedDots > pages) totalDisplayedDots = pages;
  const maxDisplay = pages - totalDisplayedDots;

  const endReached = page >= maxDisplay;

  const dotsOnEachSide = Math.floor(totalDisplayedDots / 2);

  let startNum = page - dotsOnEachSide;
  // stay at position 0 if we haven't gone far enough yet
  if (startNum < totalDisplayedDots - dotsOnEachSide) startNum = 0;
  // stay at end position if we have gone too far
  if (endReached) startNum = maxDisplay;

  if (pages < 2) return null;

  return (
    <div {...bem(null, null, className)}>
      <span {...bem('page-indicator')}>
        {customDetailsDisplay ? (
          customDetailsDisplay({
            pages,
            page,
            perPage,
            total,
            // following properties ensures compatibility with TablePagination
            state: {
              pageIndex: page,
              pageSize: perPage,
              pages,
            },
            preGlobalFilteredRows: {
              length: total,
            },
          })
        ) : (
          <>
            Page{' '}
            <strong>
              {page + 1} of {pages}
            </strong>{' '}
          </>
        )}
      </span>

      <div {...bem('page-buttons')}>
        <select
          {...bem('page-size-selector')}
          value={perPage}
          onChange={e => {
            onChangePageSize(parseInt(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(num => (
            <option key={num} value={num}>
              Show {num}
            </option>
          ))}
        </select>

        <Button
          variant="white"
          {...bem('btn-prev')}
          circle
          size="sm"
          onClick={() => onPrev()}
          // disabled={!canPreviousPage}
        >
          <SvgIcon Icon={ChevronLeftIcon} />
        </Button>
        {page > totalDisplayedDots - 1 && (
          <>
            <a {...bem('btn-page')} onClick={() => onGoto(0)}>
              1
            </a>
            <a {...bem('btn-dots')}>
              <span>&hellip;</span>
            </a>
          </>
        )}
        {[...Array(totalDisplayedDots)].map((_v, index) => {
          const pageNum = startNum + index;
          return (
            <a key={index} {...bem('btn-page', [{ active: page === pageNum }])} onClick={() => onGoto(pageNum)}>
              {pageNum + 1}
            </a>
          );
        })}
        {pages > totalDisplayedDots && page < pages - totalDisplayedDots && (
          <>
            <a {...bem('btn-dots')}>
              <span>&hellip;</span>
            </a>
            <a {...bem('btn-page')} onClick={() => onGoto(pages - 1)}>
              {pages}
            </a>
          </>
        )}
        <Button
          variant="white"
          {...bem('btn-next')}
          circle
          size="sm"
          onClick={() => onNext()}
          // disabled={!canNextPage}
        >
          <SvgIcon Icon={ChevronRightIcon} />
        </Button>
      </div>
    </div>
  );
}
