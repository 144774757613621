import type { AxiosRequestConfig } from 'axios';
import type {
  PlatformManagementApiResponse,
  StrapiPagedDataSource,
  StrapiSingleDataSource,
} from '../../types/Response';
import BaseResource from '../base';

import type { OrderLogEntity } from '../../types/OrderLog';
import { giftWalletAdminEndPoints } from './endPoints';
const orderLog = giftWalletAdminEndPoints.orderLog;

type Entity = OrderLogEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class AdminOrderLogResource extends BaseResource {
  list(options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(orderLog.list(), options);
  }

  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, SingleResponseType>(orderLog.get(id), options);
  }

  create(payload: Entity, options?: AxiosRequestConfig) {
    return this.client.post<SingleDataType, SingleResponseType>(orderLog.create(), payload, options);
  }

  update(id: string, payload: Partial<Entity>, options?: AxiosRequestConfig) {
    return this.client.put<SingleDataType, SingleResponseType>(orderLog.update(id), payload, options);
  }

  delete(id: string, options?: AxiosRequestConfig) {
    return this.client.delete<SingleDataType, SingleResponseType>(orderLog.delete(id), options);
  }

  async getByUuid(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<SingleDataType, SingleResponseType>(orderLog.list(), {
      ...options,
      ...{
        params: {
          filters: {
            uuid: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }

  async getByOrderId(id: string, options?: AxiosRequestConfig) {
    const response = await this.client.get<SingleDataType, SingleResponseType>(orderLog.list(), {
      ...options,
      ...{
        params: {
          filters: {
            orderId: {
              $eq: id,
            },
          },
          ...options?.params,
        },
      },
    });

    // @ts-ignore
    return response?.data?.data?.[0];
  }
}
