import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'common';

const translations = {
  ok: 'Ok', // TODO: duplicated in core.ts
  save: 'Save',
  delete: 'Delete',
  next: 'Next', // TODO: duplicated in core.ts
  prev: 'Prev',
  exit: 'Exit',
  edit: 'Edit',
  done: 'Done',
  share: 'Share',
  export: 'Export',
  optionalPlaceholder: '(optional)',
  send: 'Send',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
