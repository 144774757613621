import { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import { FacebookWhite } from '@gik/ui/SvgIcon/BrandIcons/Facebook';
import { GoogleWhite } from '@gik/ui/SvgIcon/BrandIcons/Google';

interface ILoginProvider {
  icon: SvgrComponent;
  className: string;
  label: string;
  value: ExternalLoginProvider;
}

// TODO: make an end-point to get this info from the server so we have it centralized there instead of repeating it here
// MVC/_ExternalRegisterListPartial.cshtml:5
// var loginProviders = Context.GetOwinContext().Authentication.GetExternalAuthenticationTypes();

const LoginProviders: ILoginProvider[] = [
  {
    icon: FacebookWhite,
    className: 'facebook-login',
    label: 'Facebook',
    value: ExternalLoginProvider.FACEBOOK,
  },
  {
    icon: GoogleWhite,
    className: 'google-login',
    label: 'Google',
    value: ExternalLoginProvider.GOOGLE,
  },
];

export default LoginProviders;
