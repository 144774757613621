import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { IUserLoginResponse } from '@gik/api/users/types';
import { SignupSection } from '@gik/auth';
import { translationKeys } from '@gik/auth/components/SignInFlow/i18n/en';
import type { IUserSignupFormValues } from '@gik/auth/components/Signup/SignupForm';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ISignInFlowSignUpContentProps = UIComponent & {
  goToStartStep: () => void;
  initialValues?: Partial<IUserSignupFormValues>;
  trackingId?: AnalyticsEvents;
  onSuccess(values: IUserLoginResponse);
  onIncorrectProvider?: (email: string) => Promise<void>;
};

export function SignInFlowSignUpContent({
  goToStartStep,
  initialValues,
  className,
  trackingId,
  onSuccess,
  onIncorrectProvider,
  ...otherProps
}: ISignInFlowSignUpContentProps) {
  const bem = useBemCN('sign-in-flow-sign-up-content');
  const { t } = useTranslation();

  return (
    <section {...bem(null, null, className)} {...otherProps}>
      <h1 {...bem('title')}>{t(translationKeys.signupContentTitle).toString()}</h1>
      <p {...bem('copy')}>
        {t(translationKeys.signupContentCopy1)}
        <Button onClick={goToStartStep} variant={'primary-link'}>
          {t(translationKeys.signupContentCopy2).toString()}
        </Button>
        {t(translationKeys.signupContentCopy3)}
      </p>

      <SignupSection
        initialValues={initialValues}
        trackingId={trackingId}
        onSuccess={onSuccess}
        onIncorrectProvider={onIncorrectProvider}
      />
    </section>
  );
}
