import { gikClassPrefix } from '@gik/core/constants';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import classnames from 'classnames';
import React from 'react';

export const hintBlockName = `${gikClassPrefix}-hint`;

export function Hint({ children, className = '' }: PropsWithClassName<React.PropsWithChildren<{}>>) {
  const classNames = classnames([hintBlockName, className]);

  return <span className={classNames}>{children}</span>;
}
