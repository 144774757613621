import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'recipient-address-prompt';

const translations = {
  modalTitle: 'Add Address',
  title: 'Recipient Address',
  description:
    'A delivery address for this calendar has not been added to share with your supporters when they sign up for requests.',
  submitButton: 'Next',
  skipButton: 'Skip',
  dontAskAgain: 'Don’t ask again',
  whyAddAddressTitle: 'Why add an address?',
  whyAddAddressDescription1:
    'When provided, we share an address with supporters to deliver or drop off calendar requests.',
  whyAddAddressDescription2:
    'If not provided, any supporters <span class="tw-font-bold tw-italic">without</span> the recipient’s address will need to contact a page organizer to obtain it, which might discourage people from helping, or overwhelm page organizers with address requests.',
  whyAddAddressButton: 'Got it',
  skipDialogTitle: 'Are you sure?',
  skipDialogDescription:
    'Supporters without the recipient’s address will need to contact a page organizer to obtain it.',
  skipDialogCancelButton: 'Add Address',
  skipDialogOkButton: 'Yes, Skip',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
