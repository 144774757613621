import type { Product } from '@gik/core/models/gik/Product';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import repeatElement from '@gik/core/utils/repeatElement';
import { GiftCardSuggestion } from '@gik/create';
import { GiftCardSkeleton, wishlistCardBlockName } from '@gik/create/components';
import PageFeatureCard from '@gik/create/components/PageFeatureCard/PageFeatureCard';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { Wishlist } from '@gik/ui/SvgIcon/GikIcons/Wishlist';
import React from 'react';

export type WishlistValues = {
  enabled: boolean;
  wishlistProducts?: Product[] | undefined;
};

export interface IWishlistCardProps {
  onChange?: (values: WishlistValues) => void;
  value: WishlistValues;
  suggestedProducts?: Product[];
  inputName?: string;
  disabled?: boolean;
  suggest?: boolean;
}

export default function WishlistCard({
  onChange = noop,
  value,
  suggestedProducts,
  className,
  inputName,
  disabled = false,
  suggest = false,
}: PropsWithClassName<IWishlistCardProps>) {
  const bem = bemBlock(wishlistCardBlockName);
  const productLimit = 4;

  const [selectedProducts, setSelectedProducts] = React.useState<Product[]>(undefined);

  React.useEffect(() => {
    if (suggest) {
      setSelectedProducts(suggestedProducts?.slice(0, productLimit));
    }
    // eslint-disable-next-line
  }, [suggestedProducts]);

  React.useEffect(() => {
    const values: WishlistValues = {
      enabled: value.enabled,
    };

    if (suggest) {
      values.wishlistProducts = selectedProducts || [];
    }

    onChange(values);
    // eslint-disable-next-line
  }, [selectedProducts]);

  function handleDismiss(i: number) {
    setSelectedProducts([...selectedProducts.slice(0, i), ...selectedProducts.slice(i + 1, selectedProducts.length)]);
  }

  return (
    <PageFeatureCard
      className={bem(null, null, className)}
      icon={Wishlist}
      title={i18n.t(translationKeys.WishlistTitle)}
      description={i18n.t(translationKeys.WishlistDescription)}
      asideOpen={suggest && (selectedProducts === undefined || selectedProducts?.length > 0)}
      onChange={enabled =>
        onChange({
          enabled: enabled,
          wishlistProducts: selectedProducts || [],
        })
      }
      value={value.enabled}
      inputName={inputName}
      disabled={disabled}
    >
      <section className={bem('suggestions-container')}>
        <p className={bem('suggestions-before')}>{i18n.t(translationKeys.WishlistSuggestionsBefore).toString()}</p>

        <section className={bem('suggestions')}>
          {selectedProducts !== undefined
            ? selectedProducts.map((product, i) => (
                <GiftCardSuggestion key={i} product={product} onClickDismiss={() => handleDismiss(i)} />
              ))
            : repeatElement(4, <GiftCardSkeleton />)}
        </section>

        <p className={bem('suggestions-after')}>{i18n.t(translationKeys.WishlistSuggestionsAfter).toString()}</p>
      </section>
    </PageFeatureCard>
  );
}
