import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'signup';

// TODO: fill in missing translations
const translations = {
  socialTitle: 'Sign-up using:',

  title: '...or sign-up using email:',
  loginText: 'Already have an account? Login',

  formSubmitButton: 'Sign up',

  requiredAcceptsTermsConditions: 'Please accept out terms & conditions',
  subscribeToNewsletter: 'Notify me of feature updates, and other ideas for helping',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
