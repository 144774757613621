import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarEventTypeSelectList } from '../EventTypes/CalendarEventTypesSelectList';
import { translationKeys } from './i18n/en';

export interface ICalendarEventTypeFormProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value?: number;
  onChange?: (value: number) => void;
}

export function CalendarEventTypeForm({ value, onChange, className }: ICalendarEventTypeFormProps) {
  const bem = bemBlock('event-type-form');
  const { t } = useTranslation();

  return (
    <div className={bem(null, null, className)}>
      <span className={bem('title', null, 'gik-calendar-modal-section-title')}>
        {t(translationKeys.eventTypeTitleLabel)}
      </span>
      <CalendarEventTypeSelectList value={value} onChange={onChange} />
    </div>
  );
}
