import type { AxiosRequestConfig } from 'axios';
import type { PlatformManagementApiResponse, StrapiPagedDataSource, StrapiSingleDataSource } from '../types/Response';
import BaseResource from './base';
import { giftWalletEndPoints } from './endPoints';
import type { GiftNoteEntity } from '../types/GiftNote';

const giftNote = giftWalletEndPoints.giftNote;

type Entity = GiftNoteEntity;
type SingleDataType = StrapiSingleDataSource<Entity>;
type SingleResponseType = PlatformManagementApiResponse<SingleDataType>;
type PaginatedDataType = StrapiPagedDataSource<Entity>;
type PaginatedResponseType = PlatformManagementApiResponse<PaginatedDataType>;

export class GiftNoteResource extends BaseResource {
  /**
   * Strapi list
   */
  list(options?: AxiosRequestConfig) {
    return this.client.get<PaginatedDataType, PaginatedResponseType>(giftNote.list(), options);
  }

  /**
   * Strapi get
   */
  get(id: string, options?: AxiosRequestConfig) {
    return this.client.get<SingleDataType, SingleResponseType>(giftNote.get(id), options);
  }
}
