import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'saved-products';

// TODO: fill in missing translations
const translations = {
  title: 'Saved Products',
  btnSeeAll: 'See All Products',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
