import type { Product } from '@gik/core/models/gik/Product';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { PGGreetingCardDesign } from '@gik/core/models/wordpress/PGGreetingCardDesign';
import type { RasterAttachImageResult } from '@gik/core/utils/FileUtils';
import { PerfectgiftFaceplateSelector } from '@gik/shop/components/PerfectgiftFaceplateSelector/PerfectgiftFaceplateSelector';
import { PerfectgiftFaceplateUploader } from '@gik/shop/components/PerfectgiftFaceplateUploader/PerfectgiftFaceplateUploader';
import { PerfectgiftGreetingCardSelector } from '@gik/shop/components/PerfectgiftGreetingCardSelector/PerfectgiftGreetingCardSelector';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { useOpenLoopInfo } from '@gik/shop/hooks/useOpenLoopInfo';
import { Button } from '@gik/ui/Button';
import type { IImageCropData } from '@gik/ui/ImageCropper';
import { UI } from '@gik/ui/UIManager';
import { VStack } from '@gik/ui/VStack/VStack';
import PhotographIcon from '@heroicons/react/outline/PhotographIcon';
import UploadIcon from '@heroicons/react/solid/UploadIcon';
import React from 'react';

export type IPerfectgiftFaceplateButtonsProps = {
  toggleValue: OLProductType;
  product: Product;
  value;
  onChange;
};

export type IPerfectgiftFaceplateUploaderResult = {
  image: IImageCropData;
  attachResult: RasterAttachImageResult;
};

export type IPerfectgiftGreetingCardButtonProps = {
  value: PGGreetingCardDesign;
  onChange: (value: PGGreetingCardDesign) => void;
};

export function PerfectgiftUploadButton({ product, toggleValue, value, onChange }: IPerfectgiftFaceplateButtonsProps) {
  const { designUploader } = useOpenLoopInfo(product, toggleValue);
  if (!designUploader) return null;

  function handleUploadPhoto() {
    UI.dialog(
      ({ close }) => (
        <PerfectgiftFaceplateUploader
          image={value?.image}
          rasterAttachImageResult={value?.attachResult}
          onDone={(image, attachResult) => {
            onChange?.({ image, attachResult });
            close();
          }}
        />
      ),
      {
        title: 'Upload a photo',
        closable: true,
        className: 'perfectgift-photo-uploader',
      }
    );
  }

  return (
    <Button variant="secondary" onClick={handleUploadPhoto} fullWidth>
      <UploadIcon /> Upload a photo
    </Button>
  );
}

export function PerfectgiftFaceplateButton({
  product,
  toggleValue,
  value,
  onChange,
}: IPerfectgiftFaceplateButtonsProps) {
  const { hasPhysicalCard } = useOpenLoopInfo(product, toggleValue);
  if (!hasPhysicalCard) return null;

  function handleSelectCardDesign() {
    UI.dialog(
      ({ close }) => (
        <PerfectgiftFaceplateSelector
          product={product}
          productType={toggleValue}
          value={value?.uuid}
          onSelect={(faceplate: PerfectGiftFaceplate) => {
            onChange?.(faceplate);
            close();
          }}
        />
      ),
      {
        title: 'Select a design',
        closable: true,
      }
    );
  }

  return (
    <VStack>
      <Button variant="secondary" onClick={handleSelectCardDesign} fullWidth>
        <PhotographIcon /> &nbsp; Choose a design
      </Button>
    </VStack>
  );
}

export function PerfectgiftGreetingCardButton({ value, onChange }: IPerfectgiftGreetingCardButtonProps) {
  function handleSelectGreetingCardDesign() {
    UI.dialog(
      ({ close }) => (
        <PerfectgiftGreetingCardSelector
          value={value}
          onSelect={(design: PGGreetingCardDesign) => {
            onChange?.(design);
            close();
          }}
        />
      ),
      {
        title: 'Select a design',
        closable: true,
      }
    );
  }

  return (
    <VStack>
      <Button variant="secondary" onClick={handleSelectGreetingCardDesign} fullWidth>
        <PhotographIcon /> &nbsp; Choose a greeting card design
      </Button>
    </VStack>
  );
}
