import { defaultPageImagePath, defaultUserImagePath } from '@gik/core/constants';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';
import { CircleBone } from '../SkeletonLoader';
import type { UISize, UIVariant } from '../types';

export interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: UIVariant;

  /**
   * Size the button on a fixed scale
   */
  size?: UISize;

  /**
   * use circular shape (this will apply border-radius: 50% with a fixed width and height)
   */
  circle?: boolean;

  /**
   * use rounded corners (default)
   */
  rounded?: boolean;

  /**
   * use pill shaped corners
   */
  pill?: boolean;

  /**
   * use squared corners
   */
  squared?: boolean;

  loading?: boolean;
  isImpersonating?: boolean;

  imageUrl?: string;

  showBorder?: boolean;
  fallbackImageUrl?: string;

  toolbar?: React.ReactElement;
}

export function PageAvatar(props: IAvatarProps) {
  return <Avatar fallbackImageUrl={defaultPageImagePath} {...props} />;
}

export function AuthorAvatar(props: IAvatarProps) {
  const bem = useBemCN('author-avatar');
  return <Avatar {...props} {...bem(null, null, props.className)} />;
}

export function Avatar({
  children,
  className,
  loading,
  isImpersonating,
  variant = 'default',
  imageUrl,
  pill,
  circle = true,
  squared,
  rounded,
  size = 'base',
  showBorder = false,
  toolbar,
  fallbackImageUrl = defaultUserImagePath,
  ...otherProps
}: IAvatarProps): React.ReactElement {
  const bem = bemBlock('avatar');
  const [imageNotFound, setImageNotFound] = React.useState<boolean>();
  const isMounted = useIsMounted();
  const blockClasses = bem(
    null,
    [
      { [variant]: variant },
      { [`size-${size}`]: size },
      { pill },
      { circle },
      { squared },
      { rounded },
      { loading: loading },
      { impersonating: isImpersonating },
      { 'show-border': showBorder },
    ],
    className
  );

  React.useEffect(() => {
    if (imageUrl) {
      const img = document.createElement('img');
      img.onerror = function () {
        if (!isMounted()) return;
        setImageNotFound(true);
      };

      img.onload = function () {
        if (!isMounted()) return;
        setImageNotFound(false);
      };

      img.src = imageUrl;
    }
  }, [imageUrl, isMounted]);

  return (
    <div
      className={blockClasses}
      {...otherProps}
      style={{
        backgroundImage: `url(${imageUrl ? (imageNotFound ? fallbackImageUrl : imageUrl) : fallbackImageUrl})`,
      }}
    >
      {loading && <CircleBone />}
      {!loading && children}
      {toolbar && <div className={bem('toolbar')}>{toolbar}</div>}
    </div>
  );
}
