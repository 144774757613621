import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'coreFormSchemas';

const translations = {
  recipientFullNamePlaceholder: 'Recipient’s Name',
  fieldHasInvalidCharacters: 'The character ’/’ is not allowed. Please update recipient name.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
