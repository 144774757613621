import bemBlock from '@gik/core/utils/bemBlock';
import type { PropsWithClassName } from '@gik/core/utils/ReactUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { FieldSet, FormField } from '@gik/ui/Form';
import React from 'react';

export interface IRecipientInfoFieldSetProps {}

export interface RecipientInfoValues {
  recipientFullName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  recipientEmail: string;
  confirmRecipientEmail: string;
}

export const recipientInfoFieldSetBlockName = 'recipient-info-fieldset';

const RecipientInfoFieldSetComp: React.FC<PropsWithClassName<IRecipientInfoFieldSetProps>> = ({
  className,
}): JSX.Element => {
  const bem = bemBlock(recipientInfoFieldSetBlockName);

  return (
    <FieldSet className={bem(null, null, className)}>
      <FormField name="recipientFullName" />
      <FormField name="address1" />
      <FormField name="address2" />
      <div className={bem('inline-fields')}>
        <FormField name="city" />
        <FormField name="state" />
        <FormField name="postalCode" />
      </div>
      <FormField className={bem('email-1')} name="recipientEmail" />
      <FormField className={bem('email-2')} name="confirmRecipientEmail" />
    </FieldSet>
  );
};

export const RecipientInfoFieldSet = withComponentErrorBoundary(RecipientInfoFieldSetComp);
