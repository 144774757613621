import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { initialGreetingCard } from '@gik/checkout/consts';
import { useCheckoutFormModalStore } from '@gik/checkout/store/CheckoutFormModalStore';
import type { ShippingDetails } from '@gik/checkout/types';
import { PerfectgiftCarrierTypes } from '@gik/checkout/types';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { PGGreetingCardDesign } from '@gik/core/models/wordpress/PGGreetingCardDesign';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { formatCurrency } from '@gik/core/utils/format';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { sanitizePGRecipientName } from '@gik/core/utils/Sanitize';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import { PerfectgiftFaceplatePreview } from '@gik/shop/components/PerfectgiftFaceplatePreview/PerfectgiftFaceplatePreview';
import { PerfectgiftGreetingCardSelector } from '@gik/shop/components/PerfectgiftGreetingCardSelector/PerfectgiftGreetingCardSelector';
import { Button } from '@gik/ui/Button';
import type { FormProps, FormRef } from '@gik/ui/Form';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { UI } from '@gik/ui/UIManager';
import { Zoom } from '@gik/ui/Zoom';
import BookOpenIcon from '@heroicons/react/outline/BookOpenIcon';
import ColorSwatchIcon from '@heroicons/react/outline/ColorSwatchIcon';
import XIcon from '@heroicons/react/solid/XIcon';
import React from 'react';
import { animateScroll } from 'react-scroll';
import useLatest from 'react-use/lib/useLatest';
import type { CardCarrierFormValues } from './CardCarrierForm';
import { CardCarrierForm } from './CardCarrierForm';

export interface CardCarrierEditorProps extends FormProps {
  inkindRouteId: string;
  shippingDetails?: ShippingDetails;
  nameOnCard?: string;
  customMessage?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  price?: number;
  anonymous?: boolean;
  anonymousOverride?: boolean;
  greetingCardUrl?: string;
  toName?: string;
  fromName?: string;
  buttons?: (isFormValid?: boolean) => React.ReactNode;
  buttonsPortal?: HTMLElement;
  onChooseDesign?: () => void;
  onReady?: () => void;
}

export type GreetingCardCarrierEditorRefProps = {
  open: () => void;
  close: () => void;
  scrollToTop: () => void;
  isOpen: () => boolean;
};

function CardCarrierEditorComp(
  {
    inkindRouteId,
    shippingDetails,
    submitButton,
    className,
    nameOnCard,
    customMessage,
    greetingCardUrl,
    toName,
    fromName,
    expiryMonth,
    cardImage,
    faceplate,
    price,
    anonymous,
    anonymousOverride,
    id,
    onSubmit,
    onChange,
    onChooseDesign,
    buttons,
    buttonsPortal,
    ...otherProps
  }: CardCarrierEditorProps,
  forwardedRef: React.MutableRefObject<GreetingCardCarrierEditorRefProps>
): React.ReactElement {
  const bem = useBemCN('card-carrier-editor');
  const { data: inkindPage } = useInkind(inkindRouteId);

  const carrierToName = sanitizePGRecipientName(inkindPage?.recipientFullName);

  const [formValues, setFormValues] = React.useState<CardCarrierFormValues>();
  const [isCardOpen, setIsCardOpen] = React.useState<CardCarrierFormValues>();
  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);

  const greetingCardRef = React.useRef<GreetingCardCarrierPreviewRefProps>();

  const handleChange = React.useCallback(
    values => {
      setFormValues(values);
      onChange?.({ ...values });
    },
    [onChange]
  );

  const handleSubmit = React.useCallback(
    (values, form) => {
      onSubmit?.({ ...values }, form);
    },
    [onSubmit]
  );

  // const handleChangeState = React.useCallback(open => {
  //   console.log('handleChangeState', open);
  //   setIsCardOpen(open);
  // }, []);

  const handleChooseDesign = React.useCallback(() => {
    let openDelay = 0;

    if (greetingCardRef.current.isOpen()) {
      openDelay = 500;
    }

    setTimeout(() => {
      UI.dialog(
        ({ close }) => (
          <PerfectgiftGreetingCardSelector
            value={useCheckoutFormModalStore.getState().cardCarrierFormRef?.current?.values['selectedCarrierDesign']}
            onSelect={(design: PGGreetingCardDesign) => {
              useCheckoutFormModalStore
                .getState()
                .cardCarrierFormRef?.current?.setFieldValue('selectedCarrierDesign', design);
              close();
            }}
          />
        ),
        {
          title: 'Select a design',
          closable: true,
        }
      );
    }, openDelay);
  }, []);

  React.useImperativeHandle(forwardedRef, () => ({
    open: () => greetingCardRef?.current?.open(),
    close: () => greetingCardRef?.current?.close(),
    scrollToTop: () => greetingCardRef?.current?.scrollToTop(),
    isOpen: () => greetingCardRef?.current?.isOpen(),
  }));

  const handleFieldFocus = React.useCallback(() => {
    greetingCardRef.current?.open();
  }, []);

  const formRef = React.createRef<FormRef<CardCarrierFormValues>>();

  const resetFormValues = React.useCallback(() => {
    if (formRef.current?.resetForm && formRef.current?.resetForm instanceof Function)
      formRef.current?.resetForm({
        values: {
          carrierType: PerfectgiftCarrierTypes.GREETING_CARD,
          toName: toName || carrierToName,
          fromName: (() => (anonymousOverride !== undefined ? anonymousOverride : anonymous))()
            ? 'anonymous'
            : fromName || user?.fullName,
          message: customMessage,
          selectedCarrierDesign: {
            url: greetingCardUrl || initialGreetingCard,
            enabled: true,
          },
        },
      });
  }, [anonymous, anonymousOverride, carrierToName, customMessage, formRef, fromName, greetingCardUrl, toName, user]);

  React.useEffect(() => {
    resetFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anonymous, anonymousOverride]);

  const loading = (userId && !user) || (inkindRouteId && !inkindPage);

  if (loading)
    return (
      <div {...bem(null, null, className)} {...otherProps}>
        <LoadingSpinner />
      </div>
    );

  return (
    <div {...bem(null, null, className)}>
      <Switch value={formValues?.carrierType}>
        <Case value={PerfectgiftCarrierTypes.TRIFOLD_WITH_ENVELOPE}>
          <TrifoldCarrierPreview
            formValues={formValues}
            cardImage={cardImage}
            recipientName={formValues?.toName}
            nameOnCard={nameOnCard}
            customMessage={customMessage}
            expiryMonth={expiryMonth}
            faceplate={faceplate}
            shippingDetails={shippingDetails}
          />
        </Case>
        <Case default value={PerfectgiftCarrierTypes.GREETING_CARD}>
          <GreetingCardCarrierPreview
            ref={greetingCardRef}
            formValues={formValues}
            cardImage={cardImage}
            recipientName={formValues?.toName}
            nameOnCard={nameOnCard}
            customMessage={customMessage}
            expiryMonth={expiryMonth}
            faceplate={faceplate}
            price={price}
            onChooseDesign={handleChooseDesign}
            selector=".gik-card-carrier-editor .gik-greeting-card-carrier-preview__greeting-card"
            // onChangeState={handleChangeState}
            // onReady={handleReady}
          />
        </Case>
      </Switch>
      <CardCarrierForm
        ref={formRef}
        id={id}
        onChange={handleChange}
        onFieldFocus={handleFieldFocus}
        initialValues={{
          carrierType: PerfectgiftCarrierTypes.GREETING_CARD,
          toName: toName || carrierToName,
          fromName: (() => (anonymousOverride !== undefined ? anonymousOverride : anonymous))()
            ? 'anonymous'
            : fromName || user?.fullName,
          message: customMessage,
          selectedCarrierDesign: {
            url: greetingCardUrl || initialGreetingCard,
            enabled: true,
          },
        }}
        onSubmit={handleSubmit}
        {...otherProps}
      />

      {renderPortal?.(buttons && buttons?.(), () => buttonsPortal)}
    </div>
  );
}

// export const GreetingCardCarrierPreview = React.forwardRef(GreetingCardCarrierPreviewComp);

export const CardCarrierEditor = React.forwardRef(CardCarrierEditorComp);

interface ITrifoldCarrierPreviewProps
  extends Pick<
    CardCarrierEditorProps,
    'shippingDetails' | 'expiryMonth' | 'faceplate' | 'cardImage' | 'customMessage'
  > {
  formValues: CardCarrierFormValues;
  nameOnCard: string;
  recipientName: string;
}
function TrifoldCarrierPreview({
  formValues,
  shippingDetails,
  expiryMonth,
  faceplate,
  cardImage,
  recipientName,
  nameOnCard,
  customMessage,
}: ITrifoldCarrierPreviewProps) {
  const bem = useBemCN('card-carrier-editor');
  const carrierId = 'f4nime';
  const trifoldWidth = 607;
  const screenWidth = window.screen.width - 16;

  const recipientFullName = recipientName;

  return (
    <Zoom levelXs={screenWidth / trifoldWidth} level={1}>
      <KeepProportions proportion={1.29392446634} {...bem('preview-wrapper')}>
        <div {...bem('preview')}>
          <img
            alt={'Gift card carrier front'}
            {...bem('underlay')}
            src={`${getAssetsUrl()}shop/PG_openloop_gift_carrier-front_${carrierId}.png`}
          />

          <div {...bem('from-address')}>
            <address>
              Customer Service <br />
              495 Mansfield Avenue <br />
              Pittsburgh, PA 15205 <br />
            </address>
          </div>

          <div {...bem('to-address')}>
            <address>
              {recipientFullName ? (
                <>
                  {recipientFullName} <br />
                </>
              ) : (
                <>
                  Recipient Name <br />
                </>
              )}
              {shippingDetails.address1 ? (
                <>
                  {shippingDetails.address1}
                  <br />
                </>
              ) : (
                <>
                  Address 1<br />
                </>
              )}
              {shippingDetails.address2 && (
                <>
                  {shippingDetails.address2}
                  <br />
                </>
              )}
              {shippingDetails.city || 'City'}, {shippingDetails.state || 'State'}{' '}
              {shippingDetails.postalCode || 'Zip Code'} <br />
            </address>
          </div>

          <PerfectgiftFaceplatePreview
            {...bem('card-preview')}
            expiryMonth={expiryMonth}
            faceplate={faceplate}
            customImage={cardImage}
            recipientName={nameOnCard}
            customMessage={customMessage}
            showMode={'faceplate'}
            overlay={'visa'}
          />

          <div {...bem('custom-fields')}>
            <div>To: {recipientName}</div>
            <div>From: {formValues?.fromName}</div>
            <div>Message: {formValues?.message}</div>
          </div>
        </div>
      </KeepProportions>
    </Zoom>
  );
}

export type GreetingCardCarrierPreviewRefProps = {
  open: () => void;
  close: () => void;
  scrollToTop: () => void;
  isOpen: () => boolean;
  setIsDone: (done: boolean) => void;
};

interface IGreetingCardCarrierPreviewProps
  extends Pick<CardCarrierEditorProps, 'price' | 'expiryMonth' | 'faceplate' | 'cardImage' | 'customMessage'> {
  formValues: CardCarrierFormValues;
  recipientName: string;
  nameOnCard: string;
  selector?: string;
  open?: boolean;
  renderButtons?: boolean;
  animateToDone?: boolean;
  onChooseDesign?: () => void;
  onChangeState?: (open: boolean) => void;
  onReady?: () => void;
}

function GreetingCardCarrierPreviewComp(
  {
    formValues,
    expiryMonth,
    faceplate,
    cardImage,
    recipientName,
    nameOnCard,
    customMessage,
    price,
    renderButtons = true,
    open = false,
    selector = '.gik-greeting-card-carrier-preview__greeting-card',
    animateToDone,
    onChooseDesign,
    onChangeState,
    onReady,
  }: IGreetingCardCarrierPreviewProps,
  forwardedRef: React.MutableRefObject<GreetingCardCarrierPreviewRefProps>
) {
  const bem = useBemCN('greeting-card-carrier-preview');
  const [isOpen, setOpen] = React.useState<boolean>(open);
  const [isDone, setIsDone] = React.useState<boolean>(false);
  const isOpenLatest = useLatest(isOpen);
  const isDoneLatest = useLatest(isDone);

  const scrollToTop = React.useCallback(() => {
    const el = document.getElementsByClassName('gik-modal__content-wrapper');
    if (!el) return;

    animateScroll.scrollToTop({ containerId: 'CheckoutFormContent', duration: 300 });

    // scroller.scrollTo('modalContentWrapper', {
    //   duration: 300,
    //   smooth: true,
    //   containerId: 'modalContentWrapper',
    // });
  }, []);

  React.useImperativeHandle(forwardedRef, () => ({
    open: handleOpen,
    close: handleClose,
    scrollToTop,
    isOpen: () => isOpenLatest.current,
    setIsDone: (done: boolean) => {
      setIsDone(done);
      setTimeout(handleResize, timeoutDefaultValue);
    },
  }));

  const handleResize = React.useCallback(() => {
    const screenWidth = window.screen.width;

    const cards = document.querySelectorAll(selector) as NodeListOf<HTMLElement>;
    if (!cards) return;

    cards.forEach(card => {
      const cardWidth = card.offsetWidth;
      let zoom = 0.7;

      if (window.innerWidth > 768) zoom = 1;

      // we need to factor in the zoom level with the card width since we need the zoomed out width
      const cardWidthZoomed = cardWidth * zoom;

      const overflow = cardWidthZoomed - screenWidth;
      let offset = null;

      if (overflow > 0 && isOpenLatest.current === true) {
        offset = overflow / 2;
      }

      let xPos = offset;
      let yPos = 0;
      let zPos = 0;

      if (isOpenLatest.current) {
        xPos = 0;
      }

      if (isDoneLatest.current) {
        xPos = -152;
        yPos = 72;
        zPos = 300;
      }

      let transform = '';

      if (xPos !== null) transform = `translateX(${xPos}px)`;

      if (isDoneLatest.current) transform = `perspective(500px) translate3d(${xPos}px, ${yPos}px, ${zPos}px)`;

      card.style.transform = transform;
      card.style['zoom'] = zoom;
    });
  }, [isDoneLatest, isOpenLatest, selector]);

  const toggleDone = React.useCallback(() => {
    if (isDone) {
      setIsDone(false);
    } else {
      setIsDone(true);
    }

    setTimeout(handleResize, timeoutDefaultValue);
  }, [handleResize, isDone]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const setIsOpen = React.useCallback(
    (open: boolean) => {
      setOpen(open);
      onChangeState?.(open);
      setTimeout(() => {
        handleResize();
      }, timeoutDefaultValue);
    },
    [onChangeState, handleResize]
  );

  const handleOpen = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  React.useEffect(() => {
    onReady?.();
  }, [animateToDone, handleResize, onChangeState, onReady, open]);

  const handleChooseDesign = React.useCallback(() => {
    setIsOpen?.(false);
    onChooseDesign?.();
  }, [onChooseDesign, setIsOpen]);

  const handleWrapperClick = React.useCallback(() => {
    if (renderButtons) setIsOpen(!isOpen);
  }, [isOpen, renderButtons, setIsOpen]);

  return (
    <div {...bem(null, [{ opened: isOpen }])}>
      <div {...bem('greeting-card-wrapper')}>
        <div {...bem('greeting-card')} onClick={handleWrapperClick}>
          <div {...bem('flip-cover')}>
            <div
              {...bem('cover')}
              style={{
                background: `url(${formValues?.selectedCarrierDesign.url})`,
              }}
            />

            <div
              {...bem('content-panel-left')}
              style={{
                background: `url(${getAssetsUrl()}shop/greeting-card-panel-left.png)`,
              }}
            >
              <div>
                <p>
                  <strong>To:</strong> {recipientName}
                </p>

                <p>
                  <i>{formValues?.message}</i>
                </p>
              </div>
              <div>
                <p>
                  <strong>From:</strong> {formValues?.fromName}
                </p>
                <p>
                  <strong>Value:</strong> {formatCurrency(price)}
                </p>
              </div>
            </div>
          </div>
          <div
            {...bem('content-panel-right')}
            style={{
              background: `url(${getAssetsUrl()}shop/greeting-card-panel-right.png)`,
            }}
          >
            <Zoom level={0.45}>
              <PerfectgiftFaceplatePreview
                {...bem('greeting-card-card-preview')}
                expiryMonth={expiryMonth}
                faceplate={faceplate}
                customImage={cardImage}
                recipientName={nameOnCard}
                customMessage={customMessage}
                showMode={'faceplate'}
                overlay={'visa'}
              />
            </Zoom>
          </div>
        </div>
      </div>
      {renderButtons && (
        <div {...bem('buttons')}>
          {/* uncomment to add a button to toggle and debug the animation */}
          {/* <Button size="sm" variant="secondary" pill onClick={toggleDone}>
          Toogle Done
        </Button> */}

          <Button size="sm" prepend={<ColorSwatchIcon />} variant="secondary" pill onClick={handleChooseDesign}>
            Choose Design
          </Button>

          {isOpen && (
            <Button size="sm" prepend={<XIcon />} variant="default" pill onClick={() => setIsOpen(!isOpen)}>
              Close Card
            </Button>
          )}
          {!isOpen && (
            <Button size="sm" prepend={<BookOpenIcon />} variant="default" pill onClick={() => setIsOpen(!isOpen)}>
              Open Card
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export const GreetingCardCarrierPreview = React.forwardRef(GreetingCardCarrierPreviewComp);
