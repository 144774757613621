import { auth } from '@gik/auth';
import { useUserStore } from '@gik/core/store/UserStore';
import type { PopoverProps } from '@gik/ui/Popover';
import dynamic from 'next/dynamic';
import React from 'react';
import { AddToWishlistPopoverContent } from './AddToWishlistPopoverContent';

const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
  ssr: false,
});

interface AddToWishlistPopoverProps {
  productId: number;
  inkindRouteId?: string;
  child(onClick: (ev: React.MouseEvent<HTMLElement>) => void, isLoading: boolean): React.ReactElement;
  onClose?(): void;
}

export function AddToWishlistPopover({
  productId,
  child,
  onClose,
  inkindRouteId,
}: AddToWishlistPopoverProps): React.ReactElement {
  const userStore = useUserStore();
  const userId = userStore.id;

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  function onClick() {
    if (!userId) {
      auth.signin();
      return;
    }

    setPopoverOpen(true);
  }

  function handleClose() {
    setPopoverOpen(false);
    if (onClose) onClose();
  }

  return (
    <Popover
      trigger={child(onClick, false)}
      isOpen={popoverOpen}
      onClose={handleClose}
      noPad
      className="gik-add-to-wishlist-popover"
    >
      {popoverOpen && (
        <AddToWishlistPopoverContent productId={productId} closePopover={handleClose} inkindRouteId={inkindRouteId} />
      )}
    </Popover>
  );
}
