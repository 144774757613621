import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { GIKSiteSettings } from '@gik/core/models/gik/SiteSettings';

export type ClearCacheParams = {
  identifiers?: number[] | string[];
  type: string;
};

export const patchSiteSettings = async (settings: Partial<GIKSiteSettings>) => {
  return dotnetApi.patch('admin/siteSettings', {
    json: settings,
  });
};

export function useSiteSettings(config?: SWRConfigInterface) {
  return useApi<GIKSiteSettings>(`admin/siteSettings`, null, config);
}
