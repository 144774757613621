import type { APIWithPagination, SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useApi } from '@gik/core/api/swr/useApi';
import type { Product } from '@gik/core/models/gik/Product';
import { apiCap } from '../';

export type WishlistProductsParams = {
  inkindRouteId?: string;
} & APIWithPagination;

function getParams(params: WishlistProductsParams = {}) {
  return {
    perPage: apiCap,
    ...params,
  };
}

export function useWishlistProducts(inkindRouteId, params?: WishlistProductsParams, config?: SWRConfigInterface) {
  return useApi<Product[]>(
    inkindRouteId ? `inkinds/${inkindRouteId}/wishlistProducts` : null,
    getParams(params),
    config
  );
}
