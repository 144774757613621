import { useCalendarAnnouncementTypes } from '@gik/api/calendar/calendarAnnouncementType';
import { getCalendarAnnouncementIcon } from '@gik/calendar/utils/getCalendarAnnouncementIcon';
import bemBlock from '@gik/core/utils/bemBlock';
import { nl2br } from '@gik/core/utils/string';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';
import DateTimeDisplay from '../DateTimeDisplay/DateTimeDisplay';

export interface ICalendarAnnouncementViewProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  typeId: number;
  selected?: boolean;
  horizontal?: boolean;
  startsAt: string;
  endsAt: string;
  allDay: boolean;
}

function CalendarAnnouncementViewComp({
  typeId,
  title,
  description,
  selected,
  horizontal,
  className,
  startsAt,
  endsAt,
  allDay,
}: ICalendarAnnouncementViewProps) {
  const bem = bemBlock('calendar-announcement-view');

  const { data: announcementTypes } = useCalendarAnnouncementTypes();
  const announcementType = announcementTypes.find(item => item.id === typeId);

  const icon = getCalendarAnnouncementIcon(announcementType.acf.icon);

  return (
    <div
      className={bem(
        null,
        [{ horizontal }, { vertical: !horizontal }, { selected }, { [announcementType.slug]: announcementType.slug }],
        className
      )}
    >
      <SvgIcon className={bem('icon')} Icon={icon} />
      <span className={bem('title')}>
        <HTMLParser rawHtml={title} />
      </span>
      <DateTimeDisplay shortTimes timeSeparator="to" hideDate startsAt={startsAt} endsAt={endsAt} allDay={allDay} />
      <p className={bem('description')}>
        <HTMLParser rawHtml={nl2br(description)} />
      </p>
    </div>
  );
}

export const CalendarAnnouncementView = withComponentErrorBoundary(CalendarAnnouncementViewComp);
