// date format used to validate dates against schema and to submit to backend
export const timeDataFormat = 'HH:mm';
export const timeDisplayFormat = 'h:mm a';

export const timeDisplayNoMeridiemFormat = 'h:mm';
export const timeMeridiemOnlyFormat = 'a';

export const dateFormat = 'YYYY-MM-DD';
export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeFormatDetailed = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

// date format used for display
export const dateDisplayFormat = 'MMM DD, YYYY';
export const dateDisplayFormatFull = 'MM/DD/YYYY HH:mm:ss';
export const datefns_dateDisplayFormatFull = 'MM/dd/yyyy HH:mm:ss';
// export const datefns_dateDisplayFormatFull = 'MM/dd/yyyy - hh:mm:ss a';
export const weekDayDisplayFormat = 'ddd';
export const monthAndDayDisplayFormat = 'MMM. D';
export const yearDisplayFormat = 'YYYY';

export const time12HourFormat = 'h:mmA';
export const emailDateFormat = 'M/D/YYYY';

export const admin_dateDisplayFormatFull = 'MM/DD/YYYY hh:mm:ss A';
