import { navigateTo } from '@/utils/navigateTo';
import { Analytics, ImpactRadius } from '@gik/analytics';
import { fireUserNotice } from '@gik/analytics/utils/Analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { ActionTrackerId } from '@gik/analytics/utils/ImpactRadius';
import { logger } from '@gik/analytics/utils/logger';
import { emailUserTags, removeEmailUserTags } from '@gik/api/emailUsers/emailUsers';
import { createInkind } from '@gik/api/inkinds/inkinds';
import { getUser } from '@gik/api/users/user';
import type { User } from '@gik/core/models/gik/User';
import routes from '@gik/core/routes';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { extractCampaignSlug } from '@gik/core/utils/GoFundMe';
import sleep from '@gik/core/utils/sleep';
import { useCreateFlowStore } from '@gik/create';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { FeatureDonationsInputNames } from '@gik/create/enums/FeatureDonationsInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { RecipientInfoStepInputNames } from '@gik/create/enums/RecipientInfoStepInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import type { ICreateFlowFormValues } from '@gik/create/models/CreateFlowFormValues';
import { algoliaClient } from '@gik/search/algolia';
import { UI } from '@gik/ui/UIManager';

export async function createInkindPage(
  idempotencyKey: string,
  user: User,
  formValues: Partial<ICreateFlowFormValues>,
  groupId?: string
) {
  if (groupId) await algoliaClient.clearCache();

  return await createInkind(
    {
      pageTitle: formValues.pageName,
      situationIds: formValues.situations,
      alternateContact: {
        fullName: formValues.alternateNumberOwnerName,
        phoneNumber: formValues.alternateNumber,
      },
      enabledFeatures: {
        calendar: formValues.careCalendarEnabled,
        wishlist: formValues.wishlistEnabled,
        donations: formValues.donationsEnabled,
        careCard: true,
        discussions: true,
        giftboxes: true,
      },
      paypalEmail: formValues[FeatureDonationsInputNames.DonationsValues]?.[PayPalFormInputNames.EmailAddress],
      goFundMeUrl: extractCampaignSlug(
        formValues[FeatureDonationsInputNames.DonationsValues]?.[GoFundMeFormInputNames.PageURL]
      ),
      cashAppUrl: formValues[FeatureDonationsInputNames.DonationsValues]?.[CashAppFormInputNames.CashAppAddress],
      venmoUrl: formValues[FeatureDonationsInputNames.DonationsValues]?.[VenmoFormInputNames.VenmoAddress],
      inviteRecipientAsOrganizer:
        formValues.recipientType == RecipientType.Myself
          ? true
          : formValues[RecipientInfoStepInputNames.AddAsPageOrganizer],
      privacySettings: {
        // TODO: consider renaming these to be consistent
        allowCrawlers: formValues.searchEnginesEnabled,
        allowFlowers: formValues.flowersEnabled,
        allowPageSharing: formValues.pageSharingEnabled,
        allowPhoneCalls: formValues.phoneCallsEnabled,
        allowTexts: formValues.textMessagesEnabled,
        allowVisitors: formValues.visitorsEnabled,
        canFeature: formValues.featurePageEnabled,
        isPublic: formValues.showPageEnabled,
      },
      recipient: {
        type: formValues.recipientType,
        fullName:
          formValues.recipientType == RecipientType.Myself
            ? user.fullName
            : formValues[RecipientInfoStepInputNames.RecipientName],
        email:
          formValues.recipientType == RecipientType.Myself
            ? user.emailAddress
            : formValues[RecipientInfoStepInputNames.RecipientEmail],
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        state: formValues.state,
        postalCode: formValues.postalCode,
      },
      selectedWishlistProductIds: formValues.wishlistItems,
      groupId,
    },
    idempotencyKey
  );
}

type CreateInkindPageWithRetryParams = {
  idempotencyKey: string;
  user: User;
  formValues: Partial<ICreateFlowFormValues>;
  groupId?: string;
  maxRetries?: number;
  onError?: (error: Error) => void;
  onSuccess?: () => void;
};

async function createInkindPageWithRetry({
  idempotencyKey,
  user,
  formValues,
  groupId,
  maxRetries = 2,
  onError,
  onSuccess,
}: CreateInkindPageWithRetryParams) {
  let retryCount = 0;

  while (retryCount <= maxRetries) {
    try {
      const response = await createInkindPage(`retry:${retryCount}-${idempotencyKey}`, user, formValues, groupId);
      onSuccess?.();
      return response;
    } catch (error) {
      onError?.(error);
      retryCount++;
      logger.error(error);

      if (retryCount >= maxRetries) throw error;
      await sleep(2000);
    }
  }
}

export async function createPageFlow(
  handleLoginSignup,
  trackOnFinish,
  mutateUserPages,
  clearPersistent,
  clearStorage,
  resetFormValues,
  groupId,
  idempotencyKey
) {
  const formValues = useCreateFlowStore.getState().formValues;
  const updateCreateProgress = useCreateFlowStore.getState().updateCreateProgress;
  const useIndeterminateProgress = useCreateFlowStore.getState().useIndeterminateProgress;
  const useDeterminateProgress = useCreateFlowStore.getState().useDeterminateProgress;
  const userId = useUserStore.getState().id;
  const googleAnalyticsId = useEnvStore.getState().GOOGLE_ANALYTICS_ID;

  updateCreateProgress();
  logger.info('Create progress - user login/signup');
  let user: User;
  if (userId) {
    user = await getUser(userId);
  } else {
    try {
      user = await handleLoginSignup();
    } catch (e) {
      logger.error('Create Flow: Failed to login/signup', e);
      fireUserNotice('Create Flow: Failed to login/signup', 'errorCreatePage', { error: e });
      Analytics.fireEvent(
        AnalyticsEvents.CreatePageLoginSignupFailed,
        {},
        () => [] as never[],
        () => [] as never[]
      );
      await UI.alert('There was an error completing your request. Please try again soon.');
      navigateTo('/');
    }
  }

  if (user) {
    updateCreateProgress();
    logger.info('Create progress - onFinish analytics event');
    await trackOnFinish(user);
    try {
      updateCreateProgress();
      logger.info('Create progress - creating page');

      const response = await createInkindPageWithRetry({
        idempotencyKey,
        user,
        formValues,
        groupId,
        onError: useIndeterminateProgress,
        onSuccess: useDeterminateProgress,
        maxRetries: 0,
      });

      updateCreateProgress();
      logger.info('Create progress - updating user pages');
      await mutateUserPages();
      updateCreateProgress();
      try {
        logger.info('Create progress - affiliate signup');
        logger.info('Create progress - affiliate conversion');
        // conversion for page creations - use the page ID as both the external (unique) transaction ID, and the customer ID.
        ImpactRadius.trackConversion(ActionTrackerId.CREATE_PAGE, idempotencyKey, response.routeId, user.emailAddress);
      } catch (error) {
        logger.error('Failed to track affiliate conversion for inkind page creation', {
          routeId: response.routeId,
          error,
        });
      }
      updateCreateProgress();
      logger.info('Create progress - create finished event');
      Analytics.fireEvent(
        AnalyticsEvents.CreateFinished,
        {
          inkindRouteId: response.routeId,
        },
        keys => keys // only one key - inkindRouteId
      );

      logger.info('Create progress - legacy event');

      // fire legacy event
      Analytics.fireEvent(AnalyticsEvents.Create_Page_GTM, {
        inkindId: response.legacyInkindId.toString(),
        owner: user.id,
      });

      logger.info('Create progress - removing email user tags');
      removeEmailUserTags({
        userEmail: user.emailAddress,
        tags: [emailUserTags.createPageReminders],
      });
      logger.info('Create progress - resetting form');
      clearPersistent();
      clearStorage();
      updateCreateProgress();
      // reset create flow progress
      resetFormValues();
      logger.info('Create progress - redirect');

      return response;
    } catch (e) {
      logger.error('Create Flow: Failed to create new page', e);
      fireUserNotice('Create Flow: Failed to create new page', 'errorCreatePage', { error: e });
      Analytics.fireEvent(
        AnalyticsEvents.CreatePageFailed,
        {},
        () => [] as never[],
        () => [] as never[]
      );

      useIdempotencyKeysStore.getState().updateCreateFlowIdempotencyKey();
      const newIdempotencyKey = useIdempotencyKeysStore.getState().createFlowIdempotencyKey;

      if (e.status === 409) {
        const create = await UI.confirm(
          `It appears you have already created an InKind page recently, do you really wish to create another one?`,
          {
            okText: 'Create new page',
            cancelText: 'Go to user profile',
          }
        );

        if (create) {
          return createPageFlow(
            handleLoginSignup,
            trackOnFinish,
            mutateUserPages,
            clearPersistent,
            clearStorage,
            resetFormValues,
            groupId,
            newIdempotencyKey
          );
        }

        logger.info('Create progress - resetting form');
        clearPersistent();
        clearStorage();
        updateCreateProgress();
        // reset create flow progress
        resetFormValues();

        return void navigateTo(routes.userProfile);
      }

      await UI.alert('There was an error completing your request. Please try again soon.');
    }
  }
}
