import type { APIWithPagination, DeepQueryParams, SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';
import type WordpressSituation from '@gik/core/models/wordpress/WordpressSituation';
import { taxonomiesFields } from '../give/taxonomies';

const path = 'situation';

export type InkindSituationsParams = {
  _fields?: string[];
} & APIWithPagination;

function getParams(params: InkindSituationsParams): DeepQueryParams {
  return {
    _fields: params?._fields?.join(',') || taxonomiesFields,
    ...params,
    filter: {
      orderby: 'meta_value_num',
      meta_key: 'situation_weighting',
      order: 'desc',
    },
  };
}

export function getInkindSituations(params: InkindSituationsParams) {
  return wpApi
    .get(path, {
      searchParams: getParams(params),
    })
    .json<WordpressSituation[]>();
}

export function useInkindSituations(params: InkindSituationsParams, config?: SWRConfigInterface) {
  return useWPApi<WordpressSituation[]>(path, getParams(params), config);
}
