import { refillBillingAddressForm } from '@gik/checkout/components/BillingForm/BillingForm';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import { Button } from '@gik/ui/Button';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { withStripe } from '@gik/ui/Stripe';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { CheckoutFormValues } from '../components/CheckoutForm/CheckoutForm';
import type { CheckoutFormWrapperProps } from '../components/CheckoutForm/CheckoutFormWrapper';
import { CheckoutFormWrapper } from '../components/CheckoutForm/CheckoutFormWrapper';

export type openAddToCheckoutModalProps = {
  inkindRouteId: string;
  recipientName?: string;
  customMessage?: string;
  expiryMonth?: string;
  cardImage?: string;
  faceplate?: PerfectGiftFaceplate;
  hasPhysicalCard?: boolean;
  hasCardCarrier?: boolean;
  buyForSomeoneElse?: boolean;
  selectFromWishlist?: boolean;
  selectFromCalendar?: boolean;
  anonymousOverride?: boolean;
  isOpenLoop?: boolean;
  formProps: CheckoutFormWrapperProps;
  addToOrder?: boolean;
  onAddToOrder?(data: CheckoutFormValues): void;
};

export function openAddToCheckoutModal({
  inkindRouteId,
  recipientName,
  customMessage,
  expiryMonth,
  cardImage,
  faceplate,
  hasCardCarrier,
  hasPhysicalCard,
  buyForSomeoneElse,
  isOpenLoop,
  formProps,
  selectFromWishlist,
  selectFromCalendar,
  anonymousOverride,
  addToOrder,
  onAddToOrder,
}: openAddToCheckoutModalProps) {
  const _CheckoutFormWrapper = withStripe(CheckoutFormWrapper);

  return UI.dialog(
    ({ close, headerRef, footerRef, setFooterClass }) => {
      // return <AddFromWishlist inkindRouteId={inkindRouteId} buttonsPortal={footerRef.current} />;

      return (
        <_CheckoutFormWrapper
          onAddtoOrder={(data: CheckoutFormValues) => {
            onAddToOrder(data);
            close();
          }}
          addToOrder={addToOrder}
          hasBilling={false}
          anonymousOverride={anonymousOverride}
          selectFromWishlist={selectFromWishlist}
          selectFromCalendar={selectFromCalendar}
          inkindRouteId={inkindRouteId}
          stepsNavPortal={headerRef.current}
          buttonsPortal={footerRef.current}
          recipientName={recipientName}
          customMessage={customMessage}
          expiryMonth={expiryMonth}
          cardImage={cardImage}
          faceplate={faceplate}
          hasPhysicalCard={hasPhysicalCard}
          hasCardCarrier={hasCardCarrier}
          buyForSomeoneElse={buyForSomeoneElse}
          setDialogFooterClass={setFooterClass}
          isOpenLoop={isOpenLoop}
          id={'add-to-checkout'}
          getAnalyticsProps={() => ({})} // empty for this story
          initiatedOn="productPage"
          {...formProps}
        />
      );
    },
    {
      title: 'Add Gift Card',
      closable: false,
      autowidth: false,

      modalProps: {
        shouldCloseOnEsc: true,
        shouldCloseOnOverlayClick: false,
      },
      className: 'gik-modal-add-to-checkout',
      header: ({ close }) => (
        <div className="gik-modal-add-to-checkout__header">
          <div className="gik-modal-add-to-checkout__title">Add Gift Card</div>
          <div>
            <Button
              variant="default-light"
              pill
              onClick={e => {
                refillBillingAddressForm();
                close(e);
              }}
            >
              Return to Checkout
            </Button>
          </div>
        </div>
      ),
      footer: ({ footerClass }) => <ModalButtonFooter centeredButtons={false} className={footerClass} />,
    }
  );
}
