import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'benefits-hero';

// TODO: fill in missing translations
const translations = {
  // header
  withoutTitle: 'Without Give InKind',
  withoutDescription:
    'Managing multiple solutions can be overwhelming and take away from what’s really important — <strong>the ones needing support.</strong>',
  withTitle: 'With Give InKind',
  withDescription:
    'Your one place to easily organize every kind of social support and communicate exactly what’s needed to loved ones wanting to help.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
