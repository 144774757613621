import type { APIInfinitePagingConfig, APIWithPagination } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi, useApiInfinite } from '@gik/core/api/swr/useApi';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import type { SWRConfiguration } from 'swr';

const path = 'inkinds';

function getParams(userId: string, params?: APIWithPagination) {
  return {
    organizerId: userId,
    followerId: userId,
    ownerId: userId,
    ...params,
  };
}

export async function getUserPages(userId: string, params?: APIWithPagination) {
  if (!userId) return;
  return dotnetApi.get(path, {
    searchParams: getParams(userId, params),
  });
}

export function useUserPages(userId?: string, params?: APIWithPagination, config?: SWRConfiguration) {
  // important if there is no slug the path should be null so nothing will be fetched
  return useApi<InkindPageAPIModel[]>(userId ? path : null, getParams(userId, params), config);
}

export function useUserPagesInfinite(
  userId?: string,
  params?: APIWithPagination,
  config?: SWRConfiguration,
  paging?: APIInfinitePagingConfig
) {
  // important if there is no slug the path should be null so nothing will be fetched
  return useApiInfinite<InkindPageAPIModel>(userId ? path : null, getParams(userId, params), config, paging);
}

/*export function getUserOrganizingPages(userId: string) {
  return dotnetApi.get(path, {
    searchParams: { organizerId: userId },
  });
}*/

export function useUserOrganizingPages(userId?: string, config?: SWRConfiguration) {
  // important if there is no slug the path should be null so nothing will be fetched
  return useApi<InkindPageAPIModel[]>(userId ? path : null, { organizerId: userId, allPages: true }, config);
}

/*export function getUserOwnedPages(userId: string) {
  return dotnetApi.get(path, {
    searchParams: { ownerId: userId },
  });
}*/

export function useUserOwnedPages(userId?: string, config?: SWRConfiguration) {
  // important if there is no slug the path should be null so nothing will be fetched
  return useApi<InkindPageAPIModel[]>(userId ? path : null, { ownerId: userId, allPages: true }, config);
}

export function getUserOwnedOrOrganizingPages(userId: string, params: APIWithPagination = {}) {
  return dotnetApi.get(path, {
    searchParams: { organizerId: userId, ownerId: userId, ...params },
  });
}

export function useUserOwnedOrOrganizingPages(
  userId?: string,
  params: APIWithPagination = {},
  config?: SWRConfiguration
) {
  return useApi<InkindPageAPIModel[]>(
    userId ? path : null,
    { organizerId: userId, ownerId: userId, ...params },
    config
  );
}

export function useUserOwnedOrOrganizingPagesInfinite(
  userId?: string,
  params?: APIWithPagination,
  config?: SWRConfiguration,
  paging?: APIInfinitePagingConfig
) {
  return useApiInfinite<InkindPageAPIModel>(
    userId ? path : null,
    { organizerId: userId, ownerId: userId, ...params },
    config,
    paging
  );
}

export function getUserFollowingPages(userId: string) {
  return dotnetApi.get(path, {
    searchParams: { followerId: userId },
  });
}

export function useUserFollowingPages(userId?: string, config?: SWRConfiguration) {
  return useApi<InkindPageAPIModel[]>(userId ? path : null, { followerId: userId, allPages: true }, config);
}
