const separator = '.';

export default abstract class TranslationUtils {
  /**
   * Returns a dictionary of translation keys to keys readable by i18n (flattens the translations using the prefix)
   * e.g "validation", {required: 'This field is required'} -> {required: 'validation.required'}
   *
   * @param prefix The prefix to add to all the keys (e.g. "validation")
   * @param translations Map of translation key => translation strings
   */
  static mapKeys = (prefix: string, translations: { [key: string]: string }) =>
    Object.assign({}, ...[...Object.keys(translations).map(key => ({ [key]: prefix + separator + key }))]);
}
