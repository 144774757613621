import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import React from 'react';
import type { TableInstanceWithPlugins } from './Table';

export interface ITablePaginationProps<T extends object> {
  className?: string;
  tableProps: TableInstanceWithPlugins<T>;
  customDetailsDisplay(props: TableInstanceWithPlugins<T>): React.ReactElement;
}

export function TablePagination<T extends object>({
  className,
  customDetailsDisplay,
  tableProps,
}: ITablePaginationProps<T>) {
  const bem = useBemCN('table-pagination');

  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableProps;

  let totalDisplayedDots = 3; // note: this should be an odd number
  if (totalDisplayedDots > pageOptions.length) totalDisplayedDots = pageOptions.length;
  const maxDisplay = pageOptions.length - totalDisplayedDots;

  const endReached = pageIndex >= maxDisplay;

  const dotsOnEachSide = Math.floor(totalDisplayedDots / 2);

  let startNum = pageIndex - dotsOnEachSide;
  // stay at position 0 if we haven't gone far enough yet
  if (startNum < totalDisplayedDots - dotsOnEachSide) startNum = 0;
  // stay at end position if we have gone too far
  if (endReached) startNum = maxDisplay;

  if (pageOptions.length < 2) return null;

  return (
    <div {...bem(null, null, className)}>
      <span {...bem('page-indicator')}>
        {customDetailsDisplay ? (
          customDetailsDisplay(tableProps)
        ) : (
          <>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </>
        )}
      </span>

      <div {...bem('page-buttons')}>
        <Button
          variant="white"
          {...bem('btn-prev')}
          circle
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <SvgIcon Icon={ChevronLeftIcon} />
        </Button>
        {pageIndex > totalDisplayedDots - 1 && (
          <>
            <a {...bem('btn-page')} onClick={() => gotoPage(0)}>
              1
            </a>
            <a {...bem('btn-dots')}>
              <span>&hellip;</span>
            </a>
          </>
        )}
        {[...Array(totalDisplayedDots)].map((_v, index) => {
          const pageNum = startNum + index;
          return (
            <a key={index} {...bem('btn-page', [{ active: pageIndex === pageNum }])} onClick={() => gotoPage(pageNum)}>
              {pageNum + 1}
            </a>
          );
        })}
        {pageOptions.length > totalDisplayedDots && pageIndex < pageOptions.length - totalDisplayedDots && (
          <>
            <a {...bem('btn-dots')}>
              <span>&hellip;</span>
            </a>
            <a {...bem('btn-page')} onClick={() => gotoPage(pageOptions.length - 1)}>
              {pageOptions.length}
            </a>
          </>
        )}
        <Button
          variant="white"
          {...bem('btn-next')}
          circle
          size="sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <SvgIcon Icon={ChevronRightIcon} />
        </Button>
      </div>

      {/* <select
        {...bem('page-size-selector')}
        value={pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select> */}
    </div>
  );
}
