import { useEffect } from 'react';

export const useBrowserNavigation = callback => {
  useEffect(() => {
    const handleNavigation = () => {
      callback();
    };

    (async () => {
      const { isBrowser } = await import('@/utils/environment');
      if (isBrowser()) {
        window.addEventListener('popstate', handleNavigation);
      }
    })();

    return () => {
      (async () => {
        const { isBrowser } = await import('@/utils/environment');
        if (isBrowser()) {
          window.removeEventListener('popstate', handleNavigation);
        }
      })();
    };
  }, [callback]);
};
