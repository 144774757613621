import { USStates } from '@gik/core/constants';
import React from 'react';
import { Select, type ISelectProps, type ISingleSelectProps } from '.';

export interface ISelectUSStatesProps extends Omit<ISelectProps, 'options'> {
  type?: 'compact' | 'normal';
  loading?: boolean;
  loadingError?: boolean;
}

function SelectUSStatesComp({
  type = 'normal',
  placeholder,
  loading,
  loadingError,
  value,
  onChange,
  ...otherProps
}: ISelectUSStatesProps): React.ReactElement {
  const selectProps = React.useMemo(
    () =>
      ({
        ...otherProps,
        options: USStates?.map(item => {
          return {
            label: type === 'compact' ? item.abbr : item.name,
            value: item.abbr,
            searchTags: [item.name, item.abbr],
          };
        }),
        placeholder,
        value,
        onChange,
      } as ISingleSelectProps),
    [onChange, otherProps, placeholder, type, value]
  );

  return <Select filterable {...selectProps} />;
}

export const SelectUSStates = SelectUSStatesComp;
