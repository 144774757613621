import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import type { DonationsValues } from '@gik/create';
import CashAppDonationProvider from '@gik/create/components/DonationProvider/CashAppDonationProvider';
import GoFundMeDonationProvider from '@gik/create/components/DonationProvider/GoFundMeDonationProvider';
import PayPalDonationProvider from '@gik/create/components/DonationProvider/PayPalDonationProvider';
import VenmoDonationProvider from '@gik/create/components/DonationProvider/VenmoDonationProvider';
import { CashAppFormInputNames } from '@gik/create/enums/CashAppFormInputNames';
import { GoFundMeFormInputNames } from '@gik/create/enums/GoFundMeFormInputNames';
import { PayPalFormInputNames } from '@gik/create/enums/PayPalFormInputNames';
import { VenmoFormInputNames } from '@gik/create/enums/VenmoFormInputNames';
import { translationKeys } from '@gik/create/i18n/en';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { CashAppIcon } from '@gik/ui/SvgIcon/BrandIcons/CashApp';
import { GoFundMeIcon } from '@gik/ui/SvgIcon/BrandIcons/GoFundMe';
import { PayPalIcon } from '@gik/ui/SvgIcon/BrandIcons/PayPal';
import { VenmoIcon } from '@gik/ui/SvgIcon/BrandIcons/Venmo';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import React from 'react';

export interface IDonationsContainerProps {
  asForm?: boolean;
  onChange?: (values: DonationsValues) => void;
  value: DonationsValues;
  disabled?: boolean;
  className?: string;
  direction?: 'vertical' | 'horizontal';
  variant?: 'create' | 'page-settings';
  trackingId?: AnalyticsEvents;
  recalculateHeightCallback?: () => void;
  noFinePrint?: boolean;
}

const blockName = 'donations-container';

export default function DonationsContainer({
  asForm = false,
  onChange = noop,
  value,
  className,
  direction,
  variant,
  trackingId,
  recalculateHeightCallback,
  noFinePrint,
}: IDonationsContainerProps) {
  const bem = bemBlock(blockName);

  const options = ['gofundme', 'paypal', 'venmo', 'cashapp'] as const;

  type OptionsType = (typeof options)[number];
  const icons: Record<OptionsType, SvgrComponent> = {
    gofundme: GoFundMeIcon,
    paypal: PayPalIcon,
    venmo: VenmoIcon,
    cashapp: CashAppIcon,
  };
  const values: Record<OptionsType, string> = {
    gofundme: value?.[GoFundMeFormInputNames.PageURL],
    paypal: value?.[PayPalFormInputNames.EmailAddress],
    venmo: value?.[VenmoFormInputNames.VenmoAddress],
    cashapp: value?.[CashAppFormInputNames.CashAppAddress],
  };
  const [view, setView] = React.useState<OptionsType>('gofundme');

  function handleOptionChange(option: OptionsType) {
    setView(option);
    recalculateHeightCallback?.();
  }

  const middleIndex = Math.ceil(options.length / 2);
  const firstHalf = options.slice(0, middleIndex);
  const secondHalf = options.slice(-middleIndex);
  const buttons = [firstHalf, secondHalf];

  return (
    <section className={bem(null, [{ [direction]: true }], className)}>
      <div className={bem('selector')}>
        {buttons.map((options, i) => (
          <div key={i} className={bem('selection-inner')}>
            {options.map(option => (
              <Button
                key={option}
                className={bem('selection', [{ selected: option === view }])}
                pill
                variant={'icon'}
                onClick={() => handleOptionChange(option)}
                prepend={<SvgIcon Icon={icons[option]} />}
                append={values[option] ? <SvgIcon className={bem('check-icon')} Icon={CheckIcon} /> : null}
              >
                {option}
              </Button>
            ))}
          </div>
        ))}
      </div>
      <div className={bem('content')}>
        <Switch value={view}>
          <Case value={'gofundme'}>
            <GoFundMeDonationProvider
              asForm={asForm}
              trackingId={trackingId}
              recalculateHeightCallback={recalculateHeightCallback}
              value={{
                [GoFundMeFormInputNames.PageURL]: value?.[GoFundMeFormInputNames.PageURL],
                isGoFundMeValueValid: value?.isGoFundMeValueValid,
              }}
              variant={variant}
              onValueChange={newValue =>
                onChange({
                  ...value,
                  ...newValue,
                })
              }
            />
          </Case>
          <Case value={'paypal'}>
            <PayPalDonationProvider
              asForm={asForm}
              trackingId={trackingId}
              recalculateHeightCallback={recalculateHeightCallback}
              value={{
                [PayPalFormInputNames.EmailAddress]: value?.[PayPalFormInputNames.EmailAddress],
                isPayPalValueValid: value?.isPayPalValueValid,
              }}
              variant={variant}
              onValueChange={newValue =>
                onChange({
                  ...value,
                  ...newValue,
                })
              }
            />
          </Case>
          <Case value={'venmo'}>
            <VenmoDonationProvider
              asForm={asForm}
              trackingId={trackingId}
              recalculateHeightCallback={recalculateHeightCallback}
              value={{
                [VenmoFormInputNames.VenmoAddress]: value?.[VenmoFormInputNames.VenmoAddress],
                isVenmoValueValid: value?.isVenmoValueValid,
              }}
              variant={variant}
              onValueChange={newValue =>
                onChange({
                  ...value,
                  ...newValue,
                })
              }
            />
          </Case>
          <Case value={'cashapp'}>
            <CashAppDonationProvider
              asForm={asForm}
              trackingId={trackingId}
              recalculateHeightCallback={recalculateHeightCallback}
              value={{
                [CashAppFormInputNames.CashAppAddress]: value?.[CashAppFormInputNames.CashAppAddress],
                isCashAppValueValid: value?.isCashAppValueValid,
              }}
              variant={variant}
              onValueChange={newValue =>
                onChange({
                  ...value,
                  ...newValue,
                })
              }
            />
          </Case>
        </Switch>
      </div>
      {!noFinePrint && <div className={bem('footer')}>{i18n.t(translationKeys.FundraisingFinePrint).toString()}</div>}
    </section>
  );
}
