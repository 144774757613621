import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'inkindPage';
export const colorNameTranslationPrefix = 'PageHeaderColourName';

const translations = {
  ShareUpdateOnSocialMediaTitle: 'Share update',
  AddMediaModalAddButtonText: 'Upload photo',
  AddMediaModalAddButtonTitle: 'Add photo or video',
  ConfirmImageModalTitle: 'Confirm image',
  ConfirmVideoModalCaptionInputPlaceholder: 'Caption',
  ConfirmVideoModalTitle: 'Confirm video',
  EditUserUpdateModalButtonLabel: 'Save changes',
  AddUserUpdateModalButtonLabel: 'Post update',

  UpdateSentToPageLabel: 'Update sent to Give InKind Page',
  UpdateSentToPageEmailAndFollowersLabel: 'Update sent to Give InKind Page, Email Supporters & Followers',
  OneMoreStep: `<strong>One more step</strong> for posting to`,

  AddUpdateButtonLabel: 'Add update',
  EditUpdateButtonLabel: 'Edit update',
  DeleteUpdateButtonLabel: 'Delete update',

  FollowPageCTAFollowingLabel: 'Following page',
  FollowPageCTAFollowLabel: 'Follow page',
  FollowPageCTAUnfollowLabel: 'Unfollow page',

  RelatedProductsCopy: 'USEFUL ITEMS IN SIMILAR SITUATIONS',

  PageHeaderPageSettings: 'Page Settings',
  PageHeaderChangeBackground: 'Change Background',
  PageHeaderSavePhoto: 'Save Photo',
  PageHeaderRemovePhoto: 'Remove Photo',
  PageHeaderChoosePhoto: 'Choose Photo',
  PageHeaderColourName0: 'Aqua',
  PageHeaderColourName1: 'Neutral',
  PageHeaderColourName2: 'Love',
  PageHeaderColourName3: 'Sage',
  PageHeaderColourName4: 'Warm',
  PageHeaderColourName5: 'Purps',

  PageHeaderChangePageImageModalImagePickerText: 'Upload a photo for your page.',
  PageHeaderChangePageImageModalTitle: 'Edit photo',

  PrivacyBadgePhoneCallsOk: 'Accepting calls.',
  PrivacyBadgePrivacyBadgePhoneCallsAlt:
    'No direct calls. An alternative phone number has been provided. {{name}}: {{phone}}',
  PrivacyBadgePhoneCallsDisabled: 'No calls please.',

  PrivacyBadgeFlowersOk: 'OK to send flowers.',
  PrivacyBadgeFlowersDisabled: 'No flowers, thank you.',

  PrivacyBadgeSmsOk: 'Accepting text messages.',
  PrivacyBadgeSmsDisabled: 'No texts please.',

  PrivacyBadgeVisitorsOk: 'Visitors are welcome.',
  PrivacyBadgeVisitorsDisabled: 'No visitors please.',

  PrivacyBadgeShareOk: 'OK to share this page with others.',
  PrivacyBadgeShareDisabled: 'No page sharing please.',

  EditInkindPageInformationModalTitle: 'Edit Page Information',

  InkindPageInformationStoryLabel: 'Story',
  InkindPageInformationIntroEmptyCopy:
    'This is where the organizer will share more about why this page was created. Follow the page to get updates as they are posted.',
  EditProfileInformationTextButtonPageDescriptionLabel: 'Add Page Description',
  InkindPageInformationSpecialNotesLabel: 'Special Notes',
  InkindPageInformationSpecialNotesEmptyCopy:
    'Organizers may specify delivery instructions, favorite meals or dietary restrictions here, so check back for details when signing up.',
  EditProfileInformationTextButtonSpecialNotesLabel: 'Add Special Notes',
  EditProfileInformationIconButtonTooltip: 'Update page information',

  InkindUserUpdateCardPlaceholderCopy: 'More updates to come...',
  InkindUserUpdateCardPlaceholderCopyNoUpdates: 'No Updates Posted Yet',
  InkindUserUpdateCardPlaceholderSubtitleCopy: 'Check back again shortly.',

  InkindUserUpdatesTimelineModalTitle: 'Updates',
  InkindUserUpdatesSignIn: 'Sign in to view updates',

  InkindUserUpdateTimelineCardDeleteConfirmCopy: 'Are you sure you want to delete this update?',
  InkindUserUpdateTimelineCardLinkCopiedNotification: 'Page link copied to clipboard!',
  InkindUserUpdateTimelineCardShareFacebook: 'Share on Facebook',
  InkindUserUpdateTimelineCardShareTwitter: 'Share on Twitter',
  InkindUserUpdateTimelineCardAttachmentLabel: 'Attachments',

  // Page Settings modal
  pageSettings: 'Page Settings',
  save: 'Save',
  cancel: 'Cancel',
  deletePage: 'Deactivate Page',
  deactivatePage: 'Deactivate Page',
  deactivatePageConfirmationText:
    'Are you sure you want to deactivate this page? It will no longer be available to any visitors.\n\nThe InKind Wallet tied to this Page will remain accessible.',
  deactivatePageConfirmationTextNoWallets:
    'Are you sure you want to deactivate this page? It will no longer be available to any visitors.',
  // InKind Page Info section
  pageTitle: 'InKind Page Info',
  pageTitleDescription: 'Make it descriptive. Including words your supporters would recognize or search for.',
  pageTitlePlaceholder: 'The Johnson’s Family',
  groupPlaceholder: 'Add this InKind Page to a group?',
  pageTitleMaxLength: 'Maximum 80 characters.',
  // Features section
  featuresTitle: 'Features',
  featuresDescription:
    'Turn on the features that best suit your situations, turn off the ones you don’t need. <span class="tw-font-bold">At least one required.</span>',
  cantDisableCareCalendarAndWishlist:
    'You must have at least one feature, Care Calendar or Wishlist, enabled in order to proceed.',
  // Features section Wishlist card
  featuresWishlistTitle: 'Wishlist',
  featuresWishlistDescription: 'Your supporters will appreciate knowing exactly how to help.',
  featuresIncludeAmazonCheckboxLabel: 'Include an Amazon Wishlist',
  featuresWishlistHelpLink: 'Help me find link',
  amazonWishlistPopover: 'This will add a button to your page that links to an Amazon wishlist.',
  learnMore: 'Learn More',
  // validation error message
  invalidAmazonWishlistUrlValidation: 'This does not appear to be a Amazon wishlist URL',

  // giftbox feature
  sendGiftBoxTitle: 'Gift Box Button',
  sendGiftBoxDescription:
    'Allow your Page Supporters to send their love with one of Give InKind’s hand-curated Gift Boxes. No two boxes are the same.',

  // Care Card Feature
  sendCareCardTitle: 'Care Card Button',
  sendCareCardDescription:
    'Each Care Card lets the recipient choose from over 200 gift cards to get what’s needed most.',

  // Visa Card Feature
  sendVisaCardTitle: 'Visa Gift with Greeting Card',
  sendVisaCardDescription:
    'Allow supporters to send a personalized Visa gift card, shipped inside a greeting card with a customized personal message.',

  // Care Card Feature
  discussionTitle: 'Discussion',
  discussionDescription:
    'Allow visitors to leave Messages of support on the page, as well as comment and interact with Messages and Updates.',

  // suggestions features
  suggestedArticlesTitle: 'Related Articles',
  suggestedArticlesDescription:
    'Show articles below the wishlist for you and visitors to learn more about the situation.',
  suggestedProductsTitle: 'Suggested Items',
  suggestedProductsDescription:
    'Show useful product and service suggestions at the bottom of your page for supporters to gift.',

  // Recipient Info Section
  recipientInfoTitle: 'Recipient Info',
  recipientInfoDescription: 'Provided at checkout for purchased gifts and services.',
  recipientFullNamePlaceholder: 'Full Name',
  recipientAddress1Placeholder: 'Address',
  recipientAddress2Placeholder: 'Address 2 (Apt., room, suite #, etc.)',
  recipientCityPlaceholder: 'City',
  recipientStatePlaceholder: 'State',
  recipientPostalCodePlaceholder: 'Zip Code',
  recipientEmailPlaceholder: 'Email',
  recipientConfirmEmailPlaceholder: 'Confirm Recipient Email',
  // Privacy Settings section
  privacySettingsTitleVariantCreate: 'Page Privacy Settings',
  privacySettingsDescriptionVariantCreate:
    'We’ll display these preferences for your supporters. Change them at any time.',
  privacySettingsTitleVariantPageSettings: 'Privacy Settings',
  privacySettingsDescriptionVariantPageSettings:
    'We’ll display icons on your page to let supporters know your preferences.',
  // cards
  phoneCallsTitle: 'Phone Calls',
  phoneCallsDescription: 'Calls are OK',
  phoneCallsDescriptionDisabled: 'No calls please',
  textMessagesTitle: 'Text Messages',
  textMessagesDescription: 'Texts are OK',
  textMessagesDescriptionDisabled: 'No texts please',
  visitorsTitle: 'Visitors',
  visitorsDescription: 'Visitors are OK',
  visitorsDescriptionDisabled: 'No Visitors please',
  flowersTitle: 'Flowers',
  flowersDescription: 'Flowers are OK',
  flowersDescriptionDisabled: 'No flowers please',
  pageSharingTitle: 'Page Sharing',
  pageSharingDescription: 'OK for others to share',
  pageSharingDescriptionDisabled: 'Ask supporters not to share page',
  // checkboxes
  showPageCheckbox: 'Show page in public search results',
  showPageDescription:
    'Allow your page to appear when searched for on Give InKind. Uncheck the box to only allow users with a direct link to locate your page.',
  featurePageCheckbox: 'OK to feature page',
  featurePageDescription:
    'We like to highlight pages on our website or social media that inspire others and allow people to feel connected through similar situations. Let us know if this is ok.',
  includeSearchCheckbox: 'Include search engines',
  includeSearchDescription:
    'Check this box for your page to appear on search sites like Google and Bing, in addition to Give InKind.',
  // Alternate phone number
  altNumber: 'Alt. number',
  remove: 'remove',
  includeAlternateNumber: 'Include alternate number',
  alternatePhoneNumberSubmit: 'Add',
  alternatePhoneNumberNotNow: 'Not Now',
  alternatePhoneNumberTitle: 'Alternate Phone Number?',
  alternatePhoneNumberDescription:
    'You can provide an alternate phone number if there’s someone taking calls on the recipient’s behalf',
  phoneNumberPlaceholder: 'Phone Number',
  ownerPlaceholder: 'Whose number is it?',
  // Page Settings Scroll Navbar
  scrollNavTitle: 'Title',
  scrollNavFeatures: 'Features',
  scrollNavPrivacy: 'Privacy',
  scrollNavRecipient: 'Recipient',
  scrollNavOrganizers: 'Organizers',
  // Organizers section
  organizersTitle: 'Organizers',
  organizersDescription:
    'Invite others to help organize this page and/or receive notifications about support signups, gifts purchased, and other supporter interactions:',
  emailInvitations: 'Email Invitations',
  facebook: 'Facebook',
  messenger: 'Messenger',
  twitter: 'Twitter',
  moreSharingOptions: 'More Sharing Options',
  copyPageLink: 'Copy Page Link',

  emailAddress: 'Email Address',
  canEditPage: 'Can edit page',
  emailAllNotifications: 'Email All Notifications',
  inviteOrganizersButton: 'Invite organizers',
  pageOwner: 'PAGE OWNER',
  invited: 'INVITED • ',
  resend: 'Resend',
  tooltip: 'You cannot delete the page owner.',
  editLabel: 'EDIT',
  emailLabel: 'EMAIL',
  organizerUpdated: 'Organizer Updated',
  // validation error messages
  emailAlreadyOrganizerValidation: 'This e-mail address already belongs to an organizer of this page.',
  emailAlreadyInvitedValidation: 'This e-mail address has already been invited to be an organizer of this page.',

  // inkind page settings premium
  inkindPageSettingsPremiumPageBadgeText: 'PREMIUM PAGE',
  inkindPageSettingsUpgradeBadgeText: 'UPGRADE PAGE',

  // premium page upgrade modal
  premiumPageUpgradeModalTitle_Supporters: 'See who’s helped, and how',
  premiumPageUpgradeModalTitle_Suggestions: 'Your InKind Page, Your Way',
  premiumPageUpgradeModalTitle_Generic: 'Access Premium Features',
  premiumPageUpgradeModalTitle_ThankYouMessages: 'Customizable one-tap Thank Yous',
  premiumPageUpgradeModalSubtitle: 'More options and insights for managing your InKind Page',

  premiumPageUpgradeModalSupportersDescriptionSimple: '<strong>Supporter View</strong>—see who’s helped, and how',
  premiumPageUpgradeModalSupportersDescriptionAdvanced:
    '<strong>Supporter View</strong>—Browse, search, and export details',
  premiumPageUpgradeModalSuggestionsDescription: 'Optionally show our suggested articles',
  premiumPageUpgradeModalFeeDescription: 'One-time fee,&nbsp;<strong>no subscription</strong>',
  premiumPageUpgradeModalMissionDescription: 'Supports Give InKind’s mission',
  premiumPageUpgradeModalThankYouMessagesDescription:
    '<strong>Customizable Thank Yous</strong>—Send thank you messages to your supporters, in colors to match any mood.',

  // page tour
  pageSettingsContent:
    'Only Page Organizers can see this and the other orange edit buttons. Manage your page: update delivery address, invite Page Organizers, change preferences, toggle features on and off.',
  privacyPreferencesContent: 'Up for Visitors? Phone Calls? Flowers? Change at any time in Page Settings.',
  updatesContent: 'Share notes, photos and videos with supporters - we’ll notify them when you post an update.',
  storySpecialNotesContent:
    'Who is this page for and how can others help? Share favorite restaurant links, special delivery instructions or any other information you want to share here.',
  fundraisingContent: 'Link an existing PayPal account or GoFundMe campaign in Page Settings.',
  calendarContent: 'Page Organizers add support requests, then visitors click “I’ll Help” to sign-up.',
  wishlistContent: 'Add favorite products and gift cards that can be sent with a few simple clicks.',
  sharingContent:
    'Now it’s time to get the word out. Share your InKind through social media or email, or copy the link for pasting.',
  supportersContent: 'Track your supporters to see who has helped, and how.',

  // support sidebar
  sendAGiftBox: 'Gift Box',
  fromWishlist: 'Wishlist',
  fromCalendar: 'Calendar',
  messages: 'Messages',
  careCard: 'Care Card',

  // new page welcome
  NewPageNoThanks: 'no thanks',
  NewPageShowMe: 'Show me',
  NewPageTitle1: 'Welcome to your page.',
  NewPageTitle2: 'Let us show you around.',
  NewPageDescription: 'Take just a minute to make the most of your InKind.',

  // sample page banner
  SamplePageTitle: 'Sample Page',

  VisaButton: 'Gift with Greeting Card',

  // send a giftbox modal
  SendAGiftboxModalTitle: 'The easiest way to send your love to:',
  SendAGiftboxModalFinePrint: '*$25 Gift Card. If no gift card on Wishlist, Amazon card will be sent.',
  SendAGiftboxModalDescription:
    'Let us curate a gift specially for them with snacks, drinks, self-care & spa products, and stationery.',
  SendAGiftboxModalDescription2:
    'Each box includes a personalized note from you, and an optional $25 Amazon gift card.',
  SendAGiftboxModalModalTitle: 'Choose a Gift Box',
  SendAGiftboxModalGiftCardGiftBoxTitle: 'Gift Box <span>&</span> Gift Card',
  SendAGiftboxModalStandardGiftBoxTitle: 'Gift Box',
  SendAGiftboxModalStandardGiftBoxPrepend: 'Just the ',
  SendAGiftboxModalDeluxeGiftBoxTitle: 'Deluxe Gift Box',
  SendAGiftboxModalDeluxeGiftBoxAppend: 'Twice as many hand-picked goodies! Great for families and frontline workers.',

  // share inkind modal
  ShareInkindModalTitle: 'Share Page',
  ShareInkindModalContentTitle: 'Invite others to help support',
  ShareInkindModalDescription: 'Sharing this page increases the chances of the recipient getting the help they need.',

  // email invitation modal

  EmailInvitationsContentTitle: 'Email Invitations',
  EmailInvitationsDescription:
    'Just type, paste or import email addresses below, and we’ll email an invitation with a link directly to your InKind Page:',

  SupportersButton: 'Supporters',

  // upgrade page reminder notification
  UpgradePageReminderTitle: 'Upgrade your InKind Page',
  UpgradePageReminderDescription: 'Detailed supporter view, and more options for your Page',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
