import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-event-form';

const translations = {
  editDialogTitle: 'Edit Request',
  editRequestTypeDialogTitle: 'Change Request Type',
  editServiceTypeDialogTitle: 'Edit Gift Cards',
  editGiftCardsDialogTitle: 'Edit Gift Cards',
  editInstance: 'Edit Instance',
  editInstanceDescription: 'Use this if you want to edit just this instance and not the whole series.',
  editSeries: 'Edit Series',
  editSeriesDescription:
    'Use this if you want to edit the series, \n\n changes will affect all instances of this event.',
  deleteRequestConfirmTitle: 'Delete {{type}}?',
  deleteRequestConfirm: 'Remove this {{type}} from your calendar?',
  seriesType: 'Series',
  instanceType: 'Instance',
  requestType: 'Request',
  closeGiftCardWithoutSavingTitle: 'Exit Without Saving?',
  closeGiftCardWithoutSaving: "Are you sure you don't want to save your Gift Card Selection?",

  dateLabel: 'Date',
  startDateLabel: 'Start Date',
  startDatePlaceholder: 'Date',
  endDateLabel: 'End Date',
  endDatePlaceholder: 'End Date',
  allDayLabel: 'Anytime',
  noSpecificTimeLabel: 'No specific time',
  startTimeLabel: 'Start Time',
  endTimeLabel: 'End Time',
  repeatLabel: 'Repeat',
  repeatPlaceholder: 'How Often?',
  titleLabel: 'Name of Request',
  pickupLocationLabel: 'Pickup Address',
  dropOffLocationLabel: 'Dropoff Address',
  deliveryInstructionsLabel: 'Delivery Instructions',
  allowGiftCardsLabel: 'Allow supporters to send Gift Cards',
  petCareTypeIdPlaceholder: 'Choose one (optional)',
  petCareTypeIdLabel: 'Type of care?',

  eventTypeLabel: 'Requesting',
  serviceTypeLabel: 'Help Types',
  eventTypeSelectTitle: 'What type of help is needed?',
  formEmailToPageFollowersLabel: 'Email this request to your followers',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
