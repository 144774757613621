import type { Product } from '@gik/core/models/gik/Product';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import { usePgDesignUploader } from '@gik/shop/hooks/usePgDesignUploader';
import { usePGOLExpirationMonth } from '@gik/shop/hooks/usePGOLExpirationMonth';

export function useOpenLoopInfo(product: Product, toggleValue: OLProductType) {
  const useDesignUploader = usePgDesignUploader();
  const hasPhysicalCard = product?.isToggleable && toggleValue === 'physical';
  const designUploader = useDesignUploader && hasPhysicalCard;
  const expiryMonth = usePGOLExpirationMonth(product, toggleValue);

  return {
    hasPhysicalCard,
    designUploader,
    expiryMonth,
  };
}
