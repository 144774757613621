import { useInkind } from '@gik/api/inkinds/inkind';
import type { PremiumPageUpgradeThankYouVariant } from '@gik/checkout/components/PremiumPageUpgradeThankYou/PremiumPageUpgradeThankYou';
import { openPremiumPageThankYouModal } from '@gik/checkout/components/PremiumPageUpgradeThankYou/PremiumPageUpgradeThankYou';
import type { PremiumPageUpgradeModalVariant } from '@gik/checkout/utils/openPremiumPageUpgradeModal';
import { openPremiumPageUpgradeModal } from '@gik/checkout/utils/openPremiumPageUpgradeModal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import type { IButtonProps } from '@gik/ui/Button/ButtonProps';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import StarOutlineIcon from '@heroicons/react/outline/StarIcon';
import StarIcon from '@heroicons/react/solid/StarIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type InkindPageSettingsPremiumBadgeProps = {
  lockedText?: React.ReactNode;
  unlockedText?: React.ReactNode;
  lockedIcon?: SvgrComponent;
  unlockedIcon?: SvgrComponent;
  thankYouVariant?: PremiumPageUpgradeThankYouVariant;
  upgradeVariant?: PremiumPageUpgradeModalVariant;
} & IButtonProps;

function InkindPageSettingsPremiumBadgeComp({
  lockedText,
  unlockedText,
  lockedIcon,
  unlockedIcon,
  thankYouVariant,
  upgradeVariant,
  ...otherProps
}: InkindPageSettingsPremiumBadgeProps) {
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkindPage } = useInkind(inkindRouteId);
  const { t } = useTranslation();

  const icon: SvgrComponent = inkindPage?.isPremium ? unlockedIcon || StarIcon : lockedIcon || StarOutlineIcon;
  const text = inkindPage?.isPremium
    ? unlockedText || t(translationKeys.inkindPageSettingsPremiumPageBadgeText).toString()
    : lockedText || t(translationKeys.inkindPageSettingsUpgradeBadgeText).toString();

  function onPremiumButtonClick() {
    openPremiumPageThankYouModal({ variant: thankYouVariant || 'generic' });
  }

  function onUpgradeButtonClick() {
    openPremiumPageUpgradeModal({ initiatedOn: 'inkindPage', variant: upgradeVariant || 'generic' });
  }

  const onClick = () => (inkindPage?.isPremium ? onPremiumButtonClick() : onUpgradeButtonClick());

  return (
    <Button
      pill
      onClick={onClick}
      prepend={<SvgIcon Icon={icon} />}
      variant={inkindPage?.isPremium ? 'premium-unlocked' : 'premium-locked'}
      size="sm"
      {...otherProps}
    >
      {text}
    </Button>
  );
}

export const InkindPageSettingsPremiumBadge = withComponentErrorBoundary(InkindPageSettingsPremiumBadgeComp);
