import TranslationUtils from '@gik/core/utils/TranslationUtils';

const prefix = 'dialogs';

const translations = {
  alertTitle: 'Alert',
  alertAcceptButton: 'ok',

  infoTitle: 'Info',
  infoAcceptButton: 'ok',

  confirmTitle: 'Confirm',
  confirmAcceptButton: 'accept',
  confirmCancelButton: 'cancel',

  successTitle: 'Success',
  successAcceptButton: 'ok',

  errorTitle: 'Error',
  errorAcceptButton: 'ok',

  warnTitle: 'Warning',
  warnAcceptButton: 'ok',
};

export default {
  [prefix]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(prefix, translations);
