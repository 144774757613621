import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { logger } from '@gik/analytics/utils/logger';
import { createClaim, unclaimOrder } from '@gik/api/calendar/calendarClaims';
import type { ResolveGiftCardClaimConflictValues } from '@gik/calendar/models/Calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import type { ClaimConflictErrorDetails, ClaimConflictResolve } from '@gik/checkout/api';
import { ClaimConflictsResolution } from '@gik/checkout/components/ClaimConflictsResolution/ClaimConflictsResolution';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { UI } from '@gik/ui/UIManager';
import { StatusCodes } from 'http-status-codes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

export type GiftCardClaimConflictResolutionProps = {
  resolveGiftCardClaimConflictValues: ResolveGiftCardClaimConflictValues;
  buttonsPortal?: HTMLElement;
  onSuccess?: () => void;
  closeButtonOverrideRef?: React.MutableRefObject<() => Promise<boolean>>;
  setModalClosable?(closable: boolean): void;
};

function fireAnalyticsEvent(
  hasAvailableOptions: boolean,
  hasPaid: boolean,
  eventName: AnalyticsEvents.ViewClaimConflict | AnalyticsEvents.ResolveClaimConflict,
  resolveStatus?: 'cancel' | 'sendAnyway' | 'doNotAttach' | 'accept' | 'chosenAnotherRequest'
) {
  Analytics.fireEvent(
    eventName,
    {
      hasAvailableOptions: hasAvailableOptions.toString(),
      hasPaid: hasPaid.toString(),
      ...(resolveStatus ? { resolveStatus } : {}),
    },
    () => ['hasPaid'],
    keys => keys.filter(key => key !== 'hasPaid')
  );
}

export function GiftCardClaimConflictResolution({
  resolveGiftCardClaimConflictValues,
  buttonsPortal,
  onSuccess,
  closeButtonOverrideRef,
  setModalClosable,
}: GiftCardClaimConflictResolutionProps) {
  const bem = useBemCN('gift-card-claim-conflict-resolution');
  const { t } = useTranslation();

  React.useEffect(() => {
    setModalClosable?.(false);
  }, [setModalClosable]);

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const [isClaiming, setIsClaiming] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState<ClaimConflictResolve[]>(undefined);

  const {
    setResolveGiftCardClaimConflictValues,
    setPaymentConfirmationValues,
    setClaimConflicts,
    setGoneConflicts,
    claimConflicts,
    goneConflicts,
  } = useCalendarStore(state => ({
    setResolveGiftCardClaimConflictValues: state.setResolveGiftCardClaimConflictValues,
    setPaymentConfirmationValues: state.setPaymentConfirmationValues,
    setClaimFailErrorCode: state.setClaimFailErrorCode,
    setIgnoreClaimConflict: state.setIgnoreClaimConflict,
    setClaimConflicts: state.setClaimConflicts,
    setGoneConflicts: state.setGoneConflicts,
    claimConflicts: state.claimConflicts,
    goneConflicts: state.goneConflicts,
  }));

  const allConflicts = [...(claimConflicts || []), ...(goneConflicts || [])];
  const hasAvailableOptions = allConflicts?.some(conflict => conflict.availableDates?.length > 0);
  const hasPaid = !!resolveGiftCardClaimConflictValues?.paymentConfirmation;

  // React.useEffect(() => {
  //   setModalClosable?.(!isClaiming || hasAvailableOptions);
  // }, [hasAvailableOptions, isClaiming, setModalClosable]);

  async function handleClaimEvent(_e, _suggestions?: ClaimConflictResolve[]) {
    fireAnalyticsEvent(hasAvailableOptions, hasPaid, AnalyticsEvents.ResolveClaimConflict, 'chosenAnotherRequest');

    const s = _suggestions ?? suggestions;

    if (!hasAvailableOptions) return void handleDoNotAttach();
    if (!s || s.length === 0) return;

    setIsClaiming(true);

    try {
      const suggestionClaims = s.map(suggestion => ({
        ...(allConflicts.find(conflict => conflict.id === suggestion.id)?.createClaimRequest ?? {}),
        calendarEntryId: suggestion.date.entryId,
        claimDate: suggestion.date.instanceDate,
        orderId: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderId,
        orderKey: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderKey,
        lineItemId: suggestion.id,
      }));

      const responses = await Promise.all(suggestionClaims.map(claim => createClaim(claim)));

      // also unclaim events that do not have available dates
      await Promise.all(
        allConflicts
          .filter(conflict => conflict.availableDates?.length == 0)
          .map(conflict =>
            unclaimOrder({
              orderId: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderId,
              orderKey: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderKey,
              lineItemId: conflict.id,
            })
          )
      );

      if (responses.every(r => r.status === StatusCodes.CREATED)) {
        setResolveGiftCardClaimConflictValues(null);
        setClaimConflicts(null);
        setGoneConflicts(null);
        onSuccess?.();
      } else {
        // edge case: a claim conflict happened while accepting a conflict resolution.
        // in this case, since the user had already accepted the conflict resolution, we won't
        // prompt the user again to confirm a change in date, we will just claim on the next
        // available date from the server response.
        const newClaimConflict = await Promise.all(
          responses
            .filter(r => r.status === StatusCodes.CONFLICT)
            .map(async r => {
              const suggested = (await r.json()) as ClaimConflictErrorDetails;

              const date = suggested.availableDates[0];
              const index = responses.findIndex(_r => _r === r);

              return {
                id: s[index].id,
                date,
              } as ClaimConflictResolve;
            })
        );

        await handleClaimEvent(undefined, newClaimConflict);
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsClaiming(false);
    }
  }

  async function promptDoNotAttach() {
    if (
      await UI.confirm(t(translationKeys.claimConflictConfirmDoNotAttach), {
        title: t(translationKeys.claimConflictConfirmDoNotAttachTitle),
        okText: t(translationKeys.claimConflictConfirmOkButtonText),
        cancelText: t(translationKeys.claimConflictConfirmCancelButtonText),
        okButtonProps: {
          variant: 'danger',
        },
      })
    ) {
      return handleDoNotAttach();
    }
    return false;
  }

  async function handleDoNotAttach() {
    try {
      fireAnalyticsEvent(hasAvailableOptions, hasPaid, AnalyticsEvents.ResolveClaimConflict, 'doNotAttach');
      setIsClaiming(true);

      const responses = await Promise.all(
        allConflicts.map(conflict =>
          unclaimOrder({
            orderId: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderId,
            orderKey: resolveGiftCardClaimConflictValues?.paymentConfirmation.orderKey,
            lineItemId: conflict.id,
          })
        )
      );

      if (responses.every(r => r.status === StatusCodes.OK)) {
        setPaymentConfirmationValues(resolveGiftCardClaimConflictValues?.paymentConfirmation);
        setResolveGiftCardClaimConflictValues(null);
        setClaimConflicts(null);
        setGoneConflicts(null);
        if (closeButtonOverrideRef) closeButtonOverrideRef.current = null;
        onSuccess?.();
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsClaiming(false);
    }

    return true;
  }

  if (closeButtonOverrideRef) closeButtonOverrideRef.current = handleDoNotAttach;
  React.useEffect(
    () => () => {
      if (closeButtonOverrideRef) closeButtonOverrideRef.current = null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const buttons = [];

  if (hasAvailableOptions) {
    buttons.push(
      <Button key="btn-order-next" variant="default" onClick={promptDoNotAttach} disabled={isClaiming}>
        {t(translationKeys.claimConflictDoNotAttachButton)}
      </Button>
    );
  }

  buttons.push(
    <Button
      key="btn-order-next"
      wide
      variant="primary"
      onClick={handleClaimEvent}
      disabled={suggestions?.length === 0}
      loading={isClaiming}
    >
      {t(translationKeys.claimConflictConfirmOkButtonText)}
    </Button>
  );

  function handleSuggestedResolvesChange(suggestions: ClaimConflictResolve[]) {
    setSuggestions(suggestions);
  }

  return (
    <div {...bem()}>
      <ClaimConflictsResolution
        claimErrors={allConflicts}
        inkindRouteId={inkindRouteId}
        buttons={() =>
          buttons?.length > 1 ? (
            buttons
          ) : (
            <div className={'gik-form__actions-new gik-form__actions-new--centered'}>{buttons}</div>
          )
        }
        buttonsPortal={buttonsPortal}
        noRemoveButton
        postPurchase
        onSuggestedResolvesChange={handleSuggestedResolvesChange}
      />
    </div>
  );
}
