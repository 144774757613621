import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import { getTailwindConfig } from '../../utils/tailwind';

// intentionally manual mapping from tailwind.config with breakpoints we want available to use with the hook
export enum Breakpoint {
  XS_DOWN = 'xs-down',
  XS = 'xs',
  SM_DOWN = 'sm-down',
  SM = 'sm',
  MD_DOWN = 'md-down',
  MD = 'md',
  LG_DOWN = 'lg-down',
  LG = 'lg',
  XL_DOWN = 'xl-down',
  XL = 'xl',
}

enum AutoBreakpoint {
  AUTO = 'auto',
}

export const BreakpointWithAuto = { ...Breakpoint, ...AutoBreakpoint };
export type BreakpointWithAuto = AutoBreakpoint | Breakpoint;

export type BreakpointType = {
  breakpoint?: BreakpointWithAuto;
};

export const breakpointInitialValue: BreakpointWithAuto = BreakpointWithAuto.AUTO;

/**
 * @see breakpoint.sass
 */
export function breakpointBem(breakpoint: BreakpointWithAuto = breakpointInitialValue): string {
  return `breakpoint-${breakpoint}`;
}

export interface ISpecificBreakpointDefinition {
  max?: string;
  min?: string;
}

export type IShorthandBreakpointDefinition = string;

export type BreakpointDefinition = ISpecificBreakpointDefinition | IShorthandBreakpointDefinition;

export function getBreakpointDefinition(twBreakpointDefinition: BreakpointDefinition): ISpecificBreakpointDefinition {
  if (typeof twBreakpointDefinition === 'string') {
    return {
      min: twBreakpointDefinition,
    };
  } else {
    return twBreakpointDefinition;
  }
}

export function calculateBreakpointActive(breakpointDefinition: ISpecificBreakpointDefinition, width: number): boolean {
  if (breakpointDefinition.min !== undefined) {
    return width > Number.parseInt(breakpointDefinition.min);
  } else {
    return width <= Number.parseInt(breakpointDefinition.max);
  }
}

export function useBreakpoint(breakpoint: Breakpoint) {
  const breakpointDefinition: ISpecificBreakpointDefinition = React.useMemo(() => {
    const twConfig = getTailwindConfig();

    return getBreakpointDefinition(twConfig.theme.screens[breakpoint]);
  }, [breakpoint]);

  const { width } = useWindowSize();
  const [isBreakpointActive, setBreakpointActive] = React.useState<boolean>(
    calculateBreakpointActive(breakpointDefinition, width)
  );

  React.useEffect(
    () => {
      const isActive = calculateBreakpointActive(breakpointDefinition, width);
      if (isActive !== isBreakpointActive) {
        setBreakpointActive(isActive);
      }
    },
    //eslint-disable-next-line
    [width]
  );

  return isBreakpointActive;
}
