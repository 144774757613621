import TranslationUtils from '@gik/core/utils/TranslationUtils';

const prefix = 'groups-admin';

const translations = {
  returnToInkindPage: 'Return To Group Page',
  members: 'Members',

  noMembersYet: 'No members yet',
  noResultsForFilter: 'No results for "{{filter}}"',
  emptyTitle: 'Your first member, coming soon.',
  pendingFulfillment: 'PENDING',
  emptyDescription: 'Invite members',
  paginationDetails: 'Showing {{current}} of {{total}} Members',

  widgetEditorHeader1: 'Links & Options',
  widgetEditorParagraph1: 'This Widget will display on each InKind Page that is a part of this group.',
  widgetEditorInfo:
    'Add up to 3 links that will display in the widget on each InKind Page for your group. You can also choose to show your Group Description, a different description, or no description at all.',
  widgetEditorHeader2: 'Links',
  widgetEditorParagraph2: 'All links will open in a new tab',
  widgetEditorWidgetPreview: 'Widget Preview',
  groupDescriptionVisible: 'Include Description',
};

export default {
  [prefix]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(prefix, translations);
