import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-cal';

const translations = {
  calendarDayNoRequest: 'no requests',
  calendarFatalError: 'Something went wrong. If this problem persists, please send us a support message.',
  calendarFatalErrorRefresh: 'Refresh',
  calendarEventClaimButton: "I'll Help",
  calendarEventConflictResolutionClaimButton: 'Choose',
  giftCard: 'Gift Card',
  addRequest: 'Add Request',
  printMonth: 'Print Month',
  samplePageWarning: 'Sorry, this is a sample page. Create a page to enable full functionality.',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
