import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'contact-page-organizers';

const translations = {
  modalTitle: 'Page Organizers',
  pageOwner: 'Page Owner',
  okText: 'DONE',
  emailCopied: 'Email copied to clipboard!',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
