import { useEnvStore } from '@gik/core/store/EnvStore';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export function useGoogleTagManager() {
  const googleTagManagerId = useEnvStore.getState().GOOGLE_TAG_MANAGER_ID;

  useEffect(() => {
    if (googleTagManagerId) {
      TagManager.initialize({
        gtmId: googleTagManagerId,
      });
    }
  }, [googleTagManagerId]);
  return null;
}
