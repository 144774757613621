import type { FormikProps } from 'formik';
import React from 'react';
import ReactDOM from 'react-dom';

export interface IFormButtonsProps {
  form: FormikProps<object>;
  formId: string;
  buttons?: (form: FormikProps<object>, formId: string) => React.ReactNode;
  buttonsPortal?: () => HTMLElement;
}

export function FormButtons({ form, formId, buttons, buttonsPortal }: IFormButtonsProps): React.ReactElement {
  const _buttons = buttons ? buttons(form, formId) : null;

  return (
    <>
      {_buttons && buttonsPortal && buttonsPortal() && ReactDOM.createPortal(_buttons, buttonsPortal())}
      {_buttons && (!buttonsPortal || !buttonsPortal()) && <div className="gik-form__actions">{_buttons}</div>}
    </>
  );
}
