import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IZoomProps {
  level: number;
  levelXs?: number;
  levelSm?: number;
  levelMd?: number;
  levelLg?: number;
  levelXl?: number;
  className?: string;
  style?: React.CSSProperties;
}

export function Zoom({
  level,
  levelXs,
  levelSm,
  levelMd,
  levelLg,
  levelXl,
  children,
  className,
  style: propStyle,
}: React.PropsWithChildren<IZoomProps>) {
  const bem = useBemCN('zoom');

  // @ts-ignore
  const style: React.CSSProperties = React.useMemo(
    () => ({
      '--zoom-level': level,
      '--xs-zoom-level': levelXs ?? level,
      '--sm-zoom-level': levelSm ?? level,
      '--md-zoom-level': levelMd ?? level,
      '--lg-zoom-level': levelLg ?? level,
      '--xl-zoom-level': levelXl ?? level,
    }),
    [level, levelLg, levelMd, levelSm, levelXl, levelXs]
  );

  return (
    <div {...bem(null, null, className)} style={{ ...propStyle, ...style }}>
      <div {...bem('container')}>{children}</div>
    </div>
  );
}
