import { PartnerInfoBox } from '@gik/calendar/components/ClaimEvent/services/partner/PartnerInfoBox';
import { PartnerTimeline } from '@gik/calendar/constants/PartnerConstants';
import type { ICalendarEntry, ICalendarEvent } from '@gik/calendar/models/Calendar';
import type IWordpressGrubhubService from '@gik/core/models/wordpress/services/WordpressGrubhubService';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

type GrubhubBoxInfoProps = UIComponent & {
  entry: ICalendarEntry;
  event: ICalendarEvent;
  service: IWordpressGrubhubService;
};

export function GrubhubInfoBox({
  className,
  entry,
  event,
  service,
  ...otherProps
}: GrubhubBoxInfoProps): React.ReactElement {
  const bem = bemBlock('grubhub-info-box');
  const partnerUrl = service.acf.container_module_grubhub?.service_url_template;
  const partnerName = 'Grubhub';

  return (
    <PartnerInfoBox
      className={bem()}
      entry={entry}
      event={event}
      partnerUrl={partnerUrl}
      partnerName={partnerName}
      timeline={PartnerTimeline.WEEK_PRIOR}
      {...otherProps}
    />
  );
}
