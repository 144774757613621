import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/i18n/en/app';
import React from 'react';

export interface IMultiVideoDefinition {
  mp4?: string;
  webm?: string;
}

export interface IVideoProps
  extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  videos?: IMultiVideoDefinition;
  notSupportedTranslationKey?: string;
  className?: string;
}

const blockName = 'video';

export const Video = React.forwardRef<HTMLVideoElement, IVideoProps>(function Video(
  { videos = {}, notSupportedTranslationKey = translationKeys.embeddedVideoNotSupported, className, ...otherProps },
  ref
) {
  const bem = bemBlock(blockName);
  const videoRef = React.useRef<HTMLVideoElement>();
  React.useImperativeHandle(ref, () => videoRef.current);

  return (
    <>
      {(videos.webm || videos.mp4) && (
        <video ref={videoRef} className={bem(null, null, className)} {...otherProps}>
          <>
            {videos.webm && <source src={videos.webm} type="video/webm" />}
            {videos.mp4 && <source src={videos.mp4} type="video/mp4" />}
            {i18n.t(notSupportedTranslationKey)}
          </>
        </video>
      )}
    </>
  );
});
