import { useBemCN } from '@gik/core/utils/bemBlock';
import type { HeadingUIVariant } from '@gik/ui/types';
import React from 'react';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

// TODO we need to discuss the implementation and what kind of variations etc we want a Heading component to support.
export interface IHeadingProps {
  level?: HeadingLevel;
  variant?: HeadingUIVariant;
  className?: string;
  id?: string;
  noStyling?: boolean;
}

const blockName = 'heading-new'; // TODO class name clash

function HeadingComp({
  level = 1,
  variant = 'default',
  className,
  id,
  noStyling = false,
  children,
}: React.PropsWithChildren<IHeadingProps>) {
  const bem = useBemCN(blockName);

  let props = {
    id,
  };

  if (!noStyling) {
    props = { ...props, ...bem(null, [{ [`heading-${level}`]: true }, { [`variant-${variant}`]: true }], className) };
  } else {
    props = {
      ...props,
      ...{
        className,
      },
    };
  }

  return React.createElement(`h${level}`, props, children);
}

export const Heading = React.memo(HeadingComp);
