import { isBrowser } from '@/utils/environment';
import { defaultMapParameters } from '@gik/core/api/base/base';
import { searchParamsTransformKyHook } from '@gik/core/api/ky/hooks/searchParamsTransformKyHook';
// import { urlCleanupHook } from '@gik/core/api/ky/hooks/urlCleanupHook';
import { WPURLEncoderKyHook } from '@gik/core/api/ky/hooks/WPURLEncoderKyHook';
import type { DeepQueryKyHeadersKyInterface } from '@gik/core/api/ky/types';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { BeforeRequestHook } from 'ky';
import { defaultGikApi } from './base';
import { jwtAccessTokenKyHook } from './hooks/jwtAccessTokenKyHook';

const POST_TIMEOUT = 60000;
const LONGER_GET_TIMEOUT = 120000;

/**
 * Ky instance for querying the main Give Inkind api
 */
const createDotnetApi = (name: string, ...extraHooks: BeforeRequestHook[]) =>
  defaultGikApi.extend({
    prefixUrl: useEnvStore.getState().DOTNET_API_URL,
    headers: {
      'X-Ky-Instance': name,
    },
    throwHttpErrors: !isBrowser(), // we will handle the errors manually and throw custom http errors
    hooks: {
      beforeRequest: [
        jwtAccessTokenKyHook,
        searchParamsTransformKyHook(defaultMapParameters),
        ...extraHooks,
        // urlCleanupHook,
      ],
    },
  }) as DeepQueryKyHeadersKyInterface;

export const dotnetApi = createDotnetApi('dotnetApi');
export const dotnetApiLongerTimeout = createDotnetApi('dotnetApiLongerTimeout');
const originalApiGet = dotnetApi.get;
dotnetApiLongerTimeout.get = (...args) => originalApiGet(args[0], { timeout: LONGER_GET_TIMEOUT, ...args[1] });

const originalApiPost = dotnetApi.post;
dotnetApi.post = (...args) => originalApiPost(args[0], { timeout: POST_TIMEOUT, ...args[1] });

export const dotnetApiCached = createDotnetApi('dotnetApiCached', WPURLEncoderKyHook);
