import { defaultHeaders, defaultOptions } from '@gik/core/api/base/base';
import { errorLoggerKyHook } from '@gik/core/api/ky/hooks/errorLoggerKyHook';
import { requestKeyHeaderKyHook } from '@gik/core/api/ky/hooks/requestKeyHeaderKyHook';
import { requestLoggerKyHook } from '@gik/core/api/ky/hooks/requestLoggerKyHook';
import { sessionKeyHeaderKyHook } from '@gik/core/api/ky/hooks/SessionKeyHeaderKyHook';
import { userIdHeaderKyHook } from '@gik/core/api/ky/hooks/userIdHeaderKyHook';
import type { RemoveHeadersKyInterface } from '@gik/core/api/ky/types';
import ky from 'ky';
import { removeHeadersKyHook } from './hooks/removeHeadersKyHook';

export const defaultKyOptions = {
  ...defaultOptions,
  hooks: {
    beforeRequest: [sessionKeyHeaderKyHook, userIdHeaderKyHook, requestKeyHeaderKyHook, requestLoggerKyHook],
    afterResponse: [errorLoggerKyHook],
  },
};

/**
 * Headers that will be added to every api request
 */
export const defaultKyHeaders = {
  ...defaultHeaders,
  'X-Requested-With': 'ky',
};

export const baseApi = ky.create({
  ...defaultKyOptions,
});

/**
 * default Ky instance for querying all Give Inkind api
 */
export const defaultGikApi = baseApi.extend({
  headers: defaultKyHeaders,
  hooks: {
    beforeRequest: [removeHeadersKyHook],
  },
}) as RemoveHeadersKyInterface;
