import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import type { Moment } from 'moment';
import moment from 'moment';
import React from 'react';

export interface ICalendarBarProps extends React.HTMLAttributes<HTMLDivElement> {
  onNext?(): void;
  onPrev?(): void;
}

export function CalendarBar({
  className,
  onPrev = noop,
  onNext = noop,
  ...otherProps
}: ICalendarBarProps): React.ReactElement {
  const bem = bemBlock('calendar-bar');

  const [monthName, setMonthName] = React.useState<string>();
  const [prevMonthName, setPrevMonthName] = React.useState<string>();
  const [nextMonthName, setNextMonthName] = React.useState<string>();

  const storeSelectedMonth = useCalendarStore(state => state.selectedMonth);

  const setDate = React.useCallback((date: Moment) => {
    if (!date) return;

    setMonthName(date.format('MMMM YYYY'));
    setPrevMonthName(date.clone().add(-1, 'month').format('MMM'));
    setNextMonthName(date.clone().add(1, 'month').format('MMM'));
  }, []);

  React.useEffect(() => {
    setDate(moment(storeSelectedMonth));
    // eslint-disable-next-line
  }, [storeSelectedMonth]);

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      <Button
        squared
        onClick={onPrev}
        className={bem('btn-prev')}
        prepend={<SvgIcon size="sm" Icon={ChevronLeftIcon} />}
      >
        {prevMonthName}
      </Button>
      <div className={bem('title')}>{monthName}</div>
      <Button
        squared
        onClick={onNext}
        className={bem('btn-next')}
        append={<SvgIcon size="sm" Icon={ChevronRightIcon} />}
      >
        {nextMonthName}
      </Button>
    </div>
  );
}
