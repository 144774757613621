import { getTailwindConfig } from '@gik/core/utils/tailwind';
import dynamic from 'next/dynamic';
import type { SliderProps } from 'rc-slider';
import React from 'react';
import { string } from 'yargs';

const Slider = dynamic(() => import('rc-slider'), {
  ssr: true,
});

export type RangeSliderProps = SliderProps & {
  trackColor: string;
}

export function RangeSlider({ ...props }: RangeSliderProps): React.ReactElement {
  const twConfig = getTailwindConfig();
  return (
    <Slider
      railStyle={{
        height: 10,
        backgroundColor: twConfig.theme.colors.neutral[400],
      }}
      trackStyle={{
        height: 10,
        backgroundColor: props.trackColor || twConfig.theme.colors.primary[500],
      }}
      handleStyle={{
        height: 17,
        width: 17,
        backgroundColor: twConfig.theme.colors.white,
        border: 0,
        boxShadow: '0px 1px 2px 1px rgba(0,0,0,0.3)',
      }}
      {...props}
    />
  );
}
