import { isBrowser } from '@/utils/environment';
import Cookies from 'js-cookie';

const previewModeKey = 'preview-mode-v2';
const cachedPreviewModeValues: { [key: string]: boolean } = {};

export enum PreviewModeType {
  Blog = 'Blog',
  Calendar = 'Calendar',
  HomePage = 'HomePage',
}

// FIXME: turn into a hook that can notify changes
const getPreviewMode = (previewModeType: PreviewModeType = PreviewModeType.Blog): boolean => {
  if (!isBrowser()) {
    return false;
  }
  if (cachedPreviewModeValues[previewModeType] !== undefined) {
    return cachedPreviewModeValues[previewModeType];
  }

  const previewMode: boolean = Cookies.get(getPreviewModeKey(previewModeType)) === 'true';

  cachedPreviewModeValues[previewModeType] = previewMode;
  return previewMode;
};

// FIXME: use a hook for observing cookie change, make reload false by default
export const turnPreviewModeOn = (previewModeType: PreviewModeType = PreviewModeType.Blog, reload = true) => {
  Cookies.set(getPreviewModeKey(previewModeType), 'true');
  if (reload) window.location.reload();
};

// FIXME: use a hook for observing cookie change, make reload false by default
export const turnPreviewModeOff = (previewModeType: PreviewModeType = PreviewModeType.Blog, reload = true) => {
  Cookies.set(getPreviewModeKey(previewModeType), 'false');
  if (reload) window.location.reload();
};

const getPreviewModeKey = (previewModeType: PreviewModeType = PreviewModeType.Blog): string => {
  if (previewModeType == PreviewModeType.Blog) return previewModeKey;
  else return previewModeKey + '-' + previewModeType;
};

export default getPreviewMode;
