import type { INotification } from '.';
import type { INotificationsContext } from './context';

export class NotificationStore {
  private _context;

  constructor(context: INotificationsContext) {
    this._context = context;
  }

  add(item: INotification) {
    this._context.add(item);
  }

  remove(notificationId: string) {
    this._context.remove(notificationId);
  }

  removeAll() {
    this._context.removeAll();
  }
}
