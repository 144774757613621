import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { Source } from './Source';

export const CheckoutType = {
  TangoCard: 'tango-card',
  Perfectgift: 'perfectgift',
  GiftyaPlatform: 'giftya-platform',
  ExternalCheckout: 'external-checkout',
  Giftbox: 'giftbox',
  VirtualProduct: 'virtual-product',
  GikPremium: 'gik-premium',
  Donation: 'donation',
} as const;

export type ProductCheckoutType = (typeof CheckoutType)[keyof typeof CheckoutType];

export interface Product {
  id: number;
  groupedProductIds: number[]; // for "Grouped" products - e.g. gift box + gift card bundle
  giftCardCategoryIds: number[];
  name: string;
  description: string;
  shortDescription: string;
  physicalDescription?: string;
  digitalDescription?: string;
  physicalImage?: string;
  digitalImage?: string;
  disclaimer: string;
  terms: string;
  brandTerms: string;
  productTerms: string;
  sku: string;
  price: number;
  checkoutType: ProductCheckoutType;
  shippingRequired: boolean;
  shippingTaxable: boolean;
  type: 'fixed' | 'input' | 'select';
  thumbnails: string[];
  gridImage: string;
  images: string[];
  variations: ProductVariation[];
  productNonD2CUrl: string;
  sources: Source[];
  isInternalProduct: boolean;
  isTangoCardProduct: boolean;
  isPerfectgiftProduct: boolean;
  disableInterstitial: boolean;
  isHiddenProduct: boolean;
  isToggleable: true;
  isNonD2C: boolean;
  slug: string;
  situationIds: number[];
  recipientIds: number[];
  categoryIds: number[];
  eligibleZipCodes: number[];
  ineligibleZipCodes: number[];
  situationKeyword?: string;
  acf: object;
}

type PGFaceplatesKey = 'pg_faceplates';
export interface PGFaceplatesEntry {
  key: PGFaceplatesKey;
  value: PerfectGiftFaceplate[];
}

interface StringEntry {
  key: Exclude<string, PGFaceplatesKey>;
  value: string;
}

type MetaDataEntry = StringEntry; //  | PGFaceplatesEntry; // TODO: fix type union

export interface ProductVariation {
  id: number;
  name: string;
  price: number;
  quantity: number;
  minValue?: number;
  maxValue?: number;
  metaData: MetaDataEntry[];
}

export interface ProductAttribute {
  id: number;
  name: string;
  position: number;
  visible: boolean;
  variation: boolean;
  options: string[];
}
