import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'common-questions-page';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'Common Questions',
  leadDescription:
    'Learn more about organizing a food train, care calendar, donations, and other help and support on Give InKind for friends and family experiencing important life events. ',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
