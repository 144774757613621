import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'how-it-works';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'How Give InKind Works',
  samplePagesTitle: 'Sample Pages',
  seeMoreLink: 'SEE MORE SAMPLE PAGES',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
