import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useUser } from '@gik/api/users/user';
import { CalendarUtil, useCollectRecipientInfo } from '@gik/calendar';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { continueClaimAfterLogin, useCalendarModalsStore } from '@gik/calendar/utils/CalendarModals';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/CustomHooks';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { inkindPageTourSelectors } from '@gik/inkind-page/components/InkindPageTour/InkindPageTour';
import { Button } from '@gik/ui/Button';
import { Heading } from '@gik/ui/Heading';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import PrinterIcon from '@heroicons/react/solid/PrinterIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from './Calendar';
import { translationKeys } from './i18n/en';

export type ICalendarSectionProps = UIComponent & {
  inkindRouteId: string;
  forceEmptyView?: boolean;
  giftCardsOnly?: boolean;
  hidePastDays?: boolean;
};

function CompCalendarSection({
  inkindRouteId,
  className,
  forceEmptyView,
  giftCardsOnly,
  hidePastDays,
  ...otherProps
}: ICalendarSectionProps): React.ReactElement {
  const bem = bemBlock('inkind-calendar-section');
  const { t } = useTranslation();
  const collectRecipientInfo = useCollectRecipientInfo();

  const { data: inkind } = useInkind(inkindRouteId);

  const isMounted = useIsMounted();
  const canEdit = useInkindCan('manage', inkindRouteId, inkind?.groupId) && isMounted();

  const mainRef = React.useRef<HTMLDivElement>();

  async function handleAddRequest() {
    Analytics.fireEvent(AnalyticsEvents.CalendarAddRequestClicked, { inkindRouteId });
    await collectRecipientInfo();
    CalendarUtil.add();
  }

  const { selectedMonth } = useCalendarStore(state => ({
    selectedMonth: state.selectedMonth,
  }));

  function handlePrint() {
    window.open(
      `${window.location.origin}/inkinds/${inkindRouteId}/print-calendar?month=${selectedMonth}`,
      'print-calendar',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=1800,height=1000`
    );
  }

  const isXsDown = useBreakpoint(Breakpoint.XS_DOWN);

  const userId = useUserStore(state => state.id);
  const { data: user } = useUser(userId);
  const claimRequest = useCalendarModalsStore(state => state.claimRequest);

  React.useEffect(() => {
    if (claimRequest && user && inkind) {
      continueClaimAfterLogin(user, inkind);
    }
  }, [claimRequest, user, inkind]);

  const isLoading = !inkind;
  if (isLoading) return <LoadingSpinner center />;

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      <header className={bem('header', null, 'no-print')}>
        <Heading level={2} className={bem('title')}>
          Care Calendar
        </Heading>
        <div className={bem('buttons-wrapper')}>
          {canEdit && (
            <Button
              id={
                isXsDown
                  ? inkindPageTourSelectors.addCalendarRequestMobile
                  : inkindPageTourSelectors.addCalendarRequestDesktop
              }
              variant="secondary"
              circle={isXsDown}
              onClick={handleAddRequest}
              hideLabelOnMobile
              prepend={<SvgIcon Icon={PlusIcon} />}
              className={bem('add-request-btn')}
            >
              {t(translationKeys.addRequest)}
            </Button>
          )}

          <Button className="gik-hidden-md-down" onClick={handlePrint} prepend={<SvgIcon Icon={PrinterIcon} />}>
            {t(translationKeys.printMonth)}
          </Button>
        </div>
      </header>
      <div ref={mainRef}>
        <Calendar
          inkindRouteId={inkindRouteId}
          forceEmptyView={forceEmptyView}
          giftCardsOnly={giftCardsOnly}
          hidePastDays={hidePastDays}
        />
      </div>
    </div>
  );
}

export const CalendarSection = withComponentErrorBoundary(CompCalendarSection);
