import TranslationUtils from '@gik/core/utils/TranslationUtils';

// const blockName = 'checkout';

const translations = {
  tangoCardCannotPurchaseForSelf: 'Sorry, you cannot send a gift card to yourself.',
  // premium page upgrade thank you
  PremiumPageThankYouTitle: 'Page Has Been Upgraded',
  PremiumPageUnlockedBenefits: 'Here’s what’s been unlocked:',
  PremiumPageSupporterViewBenefit: '<strong>Supporter View</strong>—see who’s helped, and how.',
  PremiumPageThankYouMessagesViewBenefit:
    '<strong>Customizable Thank Yous</strong>—Send your supporters thank you messages in an array of colors.',
  PremiumPageRemoveSuggestionsBenefit:
    '<strong>Optional Suggested Articles & Products—control in Page Settings.</strong>',
  PremiumPageThankYouFooterMessage: 'Nothing to cancel—your page will remain upgraded for as long as it’s needed.',
  PremiumPageThankYouFooterThankYouMessages: 'plus you helped continue our mission to support supporters like you!',
  genericBackendError:
    'There was an error processing your transaction. You were not charged.<br /> Please try again or contact our support team.',
};

export default {
  checkout: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys('checkout', translations);
