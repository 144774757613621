import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'calendar-giftcards';

const translations = {
  maxCardsNotification: 'Maximum Four Cards. Delete one or more to add.',
  minCardsNotification: 'Please add at least once card.',
  noGiftCardsInFilter: 'No giftcards found.',
  searchPlaceholder: 'Start typing to search gift cards',
  editorPickerTitle: 'Your selections',
  editorPickerDescription: 'Offer up to four gift cards to supporters for this request',
  allCategoryName: 'All',
  pickerTitle: 'Offered Gift Cards',
  pickerEditButton: 'Edit Gift Cards',
  pickerDisclaimer:
    'For this request, we’ll offer the above cards to your supporters, should they choose to send a gift card. Delete the ones you don’t want offered, or edit above to browse and choose from hundreds of others.',
  pickerDisclaimerDismiss: 'Got it!',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
