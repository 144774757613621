import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { storage } from '@gik/core/utils/Storage';
import { Button } from '@gik/ui/Button/Button';
import React from 'react';

export type DismissableTextProps = {
  dismissText?: string;
} & UIComponent;

const storageKey = 'partner-claim-order-info-dismissed';

export function DismissableText({
  children,
  className,
  dismissText = 'Got It !',
  ...otherProps
}: React.PropsWithChildren<DismissableTextProps>): React.ReactElement {
  const bem = useBemCN('dismissable-text');

  const [show, setShow] = React.useState<boolean>(storage.get(storageKey) !== '1');

  const handleDismissText = React.useCallback(() => {
    storage.set(storageKey, '1');
    setShow(false);
  }, []);

  if (!show) return null;

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      {children}{' '}
      <Button variant="primary-link" {...bem('button')} onClick={handleDismissText}>
        {dismissText}
      </Button>
    </div>
  );
}
