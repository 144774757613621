import { isBrowser } from '@/utils/environment';

/**
 * Escape HTML characters in a string
 */
export function htmlEncode(s: string): string {
  if (!isBrowser()) return null;

  const el = document.createElement('div');
  el.innerText = el.textContent = s;
  s = el.innerHTML;
  return s;
}
