import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'ui';

const translations = {
  PremiumLockedNotification: 'Please upgrade to disable',
  PremiumUnlockedNotification: 'PAGE UPGRADED TO PREMIUM',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
