import { useInkind } from '@gik/api/inkinds/inkind';
import { useUserPages } from '@gik/api/inkinds/userPages';
import desktopAnimationData from '@gik/calendar/assets/calendar-empty-state/desktop.json';
import CalendarEmptyVisitorsLogo from '@gik/calendar/assets/calendar-empty-state/imgCalendarEmptyVisitors.svg';
import CalendarPastVisitorsLogo from '@gik/calendar/assets/calendar-empty-state/imgCalendarInPastVisitors.svg';
import mobileAnimationData from '@gik/calendar/assets/calendar-empty-state/mobile.json';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { DelayedRender } from '@gik/core/hooks/hooks/useDelayedRender';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { FollowPageCTA } from '@gik/inkind-page/components/FollowPageCTA/FollowPageCTA';
import { isUserFollowingPage } from '@gik/inkind-page/utils/inkindPagePermissions';
import { Animation } from '@gik/ui/Animation';
import { Button } from '@gik/ui/Button';
import { HTMLParser } from '@gik/ui/HTMLParser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

export interface ICalendarEmptyViewProps {
  canEdit?: boolean;
  inkindRouteId?: string;
  className?: string;
  isSamplePage?: boolean;
  hasUpcomingEvents?: boolean;
  lastActiveMonth?: string;
  noUpcomingEvents?: boolean;
  isCurrentMonth?: boolean;
  onAddEvent?(): void;
  onGotoLastActiveMonth?(): void;
  onGotoUpcomingEvent?(): void;
}

function CalendarEmptyViewComp({
  canEdit,
  inkindRouteId,
  isSamplePage,
  className,
  hasUpcomingEvents,
  lastActiveMonth,
  noUpcomingEvents,
  isCurrentMonth,
  onAddEvent,
  onGotoLastActiveMonth,
  onGotoUpcomingEvent,
}: ICalendarEmptyViewProps): React.ReactElement {
  const bem = useBemCN('inkind-calendar-empty-view');
  const { t } = useTranslation();
  const isMdDown = useBreakpoint(Breakpoint.MD_DOWN);
  const userId = useUserStore(state => state.id);
  const { data: userPages } = useUserPages(userId, { allPages: true });
  const { data: inkindPage } = useInkind(inkindRouteId);
  const isFollowing = isUserFollowingPage(inkindPage, userId, userPages);

  const hasPreviousEvents = !!lastActiveMonth;

  function _renderOrganizerView() {
    return (
      <div onClick={onAddEvent}>
        <div {...bem('organizer')}>
          <DelayedRender delay={500}>
            <Animation
              {...bem('animation')}
              data={isMdDown ? mobileAnimationData : desktopAnimationData}
              mode="looped"
              assetsPrepend={`${getAssetsUrl()}homepage/calendar/images/`}
            />

            <p {...bem('leadText')}>
              <HTMLParser rawHtml={t(translationKeys.OrganizerViewLeadText)} />
            </p>
          </DelayedRender>
        </div>
        <p {...bem('subtitle')}>{t(translationKeys.OrganizerViewText)}</p>
        <footer {...bem('footer')}>{t(translationKeys.OrganizerViewFooter)}</footer>
      </div>
    );
  }

  function _renderNonOrganizerView() {
    // upcoming or previous requests while not on current month
    if (hasUpcomingEvents) {
      return (
        <div {...bem('content')}>
          <div {...bem('logo-upcoming')}>
            <CalendarPastVisitorsLogo />
          </div>
          <p {...bem('title')}>
            {t(hasPreviousEvents ? translationKeys.PreviousEventsTitle : translationKeys.UpcomingEventsTitle)}
          </p>
          <p {...bem('subtitle')}>
            {t(hasPreviousEvents ? translationKeys.PreviousEventsText : translationKeys.UpcomingEventsText)}
          </p>

          <Button onClick={onGotoUpcomingEvent}>{t(translationKeys.goToRequests)}</Button>
        </div>
      );
    }

    // no upcoming events
    if (hasPreviousEvents) {
      return (
        <div {...bem('content')}>
          <div {...bem('logo-upcoming')}>
            <CalendarPastVisitorsLogo />
          </div>
          <p {...bem('title')}>{t(translationKeys.noUpcomingTitle)}</p>
          {isFollowing && <p {...bem('subtitle')}>{t(translationKeys.noUpcomingTextFollowing)}</p>}
          {!isFollowing && <p {...bem('subtitle')}>{t(translationKeys.noUpcomingText)}</p>}

          <FollowPageCTA inkindRouteId={inkindRouteId} isSamplePage={isSamplePage} />
          <br />
          <br />
          {hasPreviousEvents && (
            <Button variant="default-link" onClick={onGotoLastActiveMonth}>
              {t(translationKeys.viewPastRequests)}
            </Button>
          )}
        </div>
      );
    }

    // no requests yet
    return (
      <div {...bem('content')}>
        <div {...bem('logo')}>
          <CalendarEmptyVisitorsLogo />
        </div>
        <p {...bem('title')}>{t(translationKeys.NoRequestsText)}</p>
        {isFollowing && <p {...bem('subtitle')}>{t(translationKeys.NoRequestsText2Following)}</p>}
        {!isFollowing && <p {...bem('subtitle')}>{t(translationKeys.NoRequestsText2)}</p>}

        <FollowPageCTA inkindRouteId={inkindRouteId} isSamplePage={isSamplePage} />
      </div>
    );
  }

  return (
    <div {...bem(null, null, className)}>
      <div {...bem('wrapper')}>{canEdit ? _renderOrganizerView() : _renderNonOrganizerView()}</div>
    </div>
  );
}

export const CalendarEmptyView = withComponentErrorBoundary(CalendarEmptyViewComp);
