import type { GikIconProps } from '@gik/ui/SvgIcon/GikIcons/index';
import { GikIconDefaultSize } from '@gik/ui/SvgIcon/GikIcons/index';
import React from 'react';

export const WishlistLarge = React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        ref={svgRef}
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.6931 48H27.5931C27.0931 48 26.5931 48.4 26.5931 49C26.5931 49.5999 26.9931 50 27.5931 50H44.6931C45.1931 50 45.6931 49.5999 45.6931 49C45.6931 48.4 45.2931 48 44.6931 48Z"
          fill="currentColor"
        />
        <path
          d="M44.6931 32H27.5931C27.0931 32 26.5931 32.4 26.5931 33C26.5931 33.6 26.9931 34 27.5931 34H44.6931C45.1931 34 45.6931 33.6 45.6931 33C45.6931 32.4 45.2931 32 44.6931 32Z"
          fill="currentColor"
        />
        <path
          d="M50.6931 41.5C50.0931 41.5 49.6931 41.9 49.6931 42.5V42.5999C49.6931 43.1999 50.0931 43.5999 50.6931 43.5999C51.2931 43.5999 51.6931 43.1999 51.6931 42.5999V42.5C51.6931 41.9 51.1931 41.5 50.6931 41.5Z"
          fill="currentColor"
        />
        <path
          d="M20.0931 25.6L29.9931 15.7C33.0931 12.6 33.0931 7.49995 29.9931 4.39995C26.8931 1.29995 21.7931 1.29995 18.6931 4.39995C15.5931 1.29995 10.4931 1.29995 7.39312 4.39995C4.29312 7.49995 4.29312 12.6 7.39312 15.7L17.2931 25.6C17.9931 26.3 19.2931 26.3 20.0931 25.6ZM8.79312 5.79995C11.0931 3.49995 14.8931 3.49995 17.2931 5.79995C18.0931 6.59995 19.2931 6.59995 20.0931 5.79995C22.3931 3.49995 26.1931 3.49995 28.5931 5.79995C30.8931 8.09995 30.8931 11.9 28.5931 14.3L18.6931 24.2L8.79312 14.3C6.39312 11.9 6.39312 8.09995 8.79312 5.79995Z"
          fill="currentColor"
        />
        <path
          d="M44.6931 40H27.5931C27.0931 40 26.5931 40.4 26.5931 41C26.5931 41.5999 26.9931 42 27.5931 42H44.6931C45.1931 42 45.6931 41.5999 45.6931 41C45.6931 40.4 45.2931 40 44.6931 40Z"
          fill="currentColor"
        />
        <path
          d="M17.0931 33.9L18.4931 34.7999C18.6931 34.8999 18.8931 35 19.0931 35C19.3931 35 19.5931 34.9 19.7931 34.7L22.8931 31.7999C23.2931 31.4 23.3931 30.6999 22.9931 30.2999C22.5931 29.9 21.8931 29.8 21.4931 30.2L18.9931 32.5L18.2931 32.1C17.7931 31.8 17.0931 31.9 16.7931 32.4C16.4931 32.9 16.5931 33.6 17.0931 33.9Z"
          fill="currentColor"
        />
        <path
          d="M50.6931 45.9C50.0931 45.9 49.6931 46.3 49.6931 46.9V55H13.6931V24.5C13.6931 23.9 13.2931 23.5 12.6931 23.5C12.0931 23.5 11.6931 23.9 11.6931 24.5V55C11.6931 56.0999 12.5931 57 13.6931 57H49.6931C50.7931 57 51.6931 56.0999 51.6931 55V46.9C51.6931 46.4 51.1931 45.9 50.6931 45.9Z"
          fill="currentColor"
        />
        <path
          d="M23.0931 38.4C22.6931 38 21.9931 37.8999 21.5931 38.2999L18.9931 40.7L18.2931 40.2999C17.7931 40 17.0931 40.0999 16.7931 40.5999C16.4931 41.0999 16.5931 41.7999 17.0931 42.0999L18.4931 43C18.6931 43.0999 18.8931 43.2 19.0931 43.2C19.3931 43.2 19.5931 43.1 19.7931 42.9L22.8931 40C23.3931 39.5 23.4931 38.8 23.0931 38.4Z"
          fill="currentColor"
        />
        <path
          d="M19.8931 46C18.0931 46 16.4931 47.5 16.4931 49.2999C16.4931 51.0999 17.9931 52.5999 19.8931 52.5999C21.6931 52.5999 23.2931 51.0999 23.2931 49.2999C23.2931 47.5 21.7931 46 19.8931 46ZM19.8931 50.4C19.1931 50.4 18.6931 49.8999 18.6931 49.2999C18.6931 48.7 19.1931 48.2 19.8931 48.2C20.5931 48.2 21.0931 48.7 21.0931 49.2999C21.0931 49.8999 20.5931 50.4 19.8931 50.4Z"
          fill="currentColor"
        />
        <path
          d="M50.9931 18.5L41.0931 9.59995C40.7931 9.19995 40.2931 8.99995 39.7931 8.99995H35.1931C34.5931 8.99995 34.1931 9.39995 34.1931 9.99995C34.1931 10.6 34.5931 11 35.1931 11H38.6931V20C38.6931 20.6 39.0931 21 39.6931 21H49.6931V38.2C49.6931 38.7999 50.0931 39.2 50.6931 39.2C51.2931 39.2 51.6931 38.7999 51.6931 38.2V20C51.6931 19.4 51.3931 18.9 50.9931 18.5ZM40.6931 19V11.8L48.6931 19H40.6931Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
