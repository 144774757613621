import { getTailwindConfig } from '@gik/core/utils/tailwind';
import type * as stripeJs from '@stripe/stripe-js';
const twConfig = getTailwindConfig();

export * from './withStripe';

export const stripeCardElementOptions: stripeJs.StripeCardElementOptions = {
  hidePostalCode: true,
  // hideIcon: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: twConfig.theme.colors.primary[500],
      color: twConfig.theme.colors.neutral[900],
      fontWeight: 'normal',
      fontFamily: twConfig.theme.fontFamily.body.join(','),
      fontSize: 'initial',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: twConfig.theme.colors.neutral[900] },
      '::placeholder': { color: twConfig.theme.colors.neutral[700] },
    },
    invalid: {
      iconColor: twConfig.theme.colors.danger[500],
      color: twConfig.theme.colors.danger[900],
    },
  },
};
