import { useInkind } from '@gik/api/inkinds/inkind';
import ContactPageOrganizersForAddress from '@gik/calendar';
import isInkindPageRecipientInfoComplete from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import { Button } from '@gik/ui/Button';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import DuplicateIcon from '@heroicons/react/solid/DuplicateIcon';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from './i18n/en';

interface CopyInfoCardProps {
  title: string;
  icon: SvgrComponent | React.FC;
  content: string[];
  inkindRouteId?: string;
  recipientAddressRequired?: boolean;
}

export default function CopyInfoCard({
  title,
  icon,
  content,
  inkindRouteId,
  recipientAddressRequired,
}: CopyInfoCardProps): React.ReactElement {
  const { t } = useTranslation();
  const { data: inkindPage } = useInkind(inkindRouteId);

  const contentValid = !inkindPage || !recipientAddressRequired || isInkindPageRecipientInfoComplete(inkindPage);

  const handleCopyAddressClicked = React.useCallback(() => {
    copy(content.join('\n'));
    UI.notifySuccess(t(translationKeys.copied));
  }, [content, t]);

  return (
    <div className="external-product-interstitial-copy-info-card">
      <header>
        <SvgIcon Icon={icon} />
        <span>{title}</span>
      </header>
      {contentValid ? (
        <>
          <main>
            <div>
              {content
                .filter(line => line?.trim().length > 0)
                .map((line, index) => [<span key={index + '-line'}>{line}</span>, <br key={index + '-br'} />])}
            </div>
          </main>
          <div className="external-product-interstitial-copy-info-card-button">
            <Button variant="primary-light-outline" circle onClick={handleCopyAddressClicked}>
              <SvgIcon Icon={DuplicateIcon} />
            </Button>
          </div>
        </>
      ) : (
        <ContactPageOrganizersForAddress inkindRouteId={inkindRouteId} variant="interstitial" />
      )}
    </div>
  );
}
