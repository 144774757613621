import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import { breakpointBem } from '@gik/core/hooks/hooks/BreakpointHooks';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

const blockName = 'container';

export interface ContainerProps extends BreakpointType {
  className?: string;
  element?: string;
  centered?: boolean;
}

export function Container({
  children,
  className,
  element = 'div',
  centered = false,
  breakpoint,
}: React.PropsWithChildren<ContainerProps>) {
  const bem = useBemCN(blockName);

  return React.createElement(
    element,
    {
      ...bem(null, [{ centered }, breakpointBem(breakpoint)], [className]),
    },
    children
  );
}
