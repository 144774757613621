import { useUserStore } from '@gik/core/store/UserStore';
import type { InkindSearchFilter } from '@gik/search/components';
import { useSWRSearch } from '@gik/search/components';
import React from 'react';

export function useUserPages() {
  const userId = useUserStore(state => state.id);

  const filters: InkindSearchFilter[] = React.useMemo(() => {
    return [
      [
        {
          key: 'ownerId',
          value: userId,
        },
        {
          key: 'organizersIds',
          value: userId,
        },
        {
          key: 'followersIds',
          value: userId,
        },
      ],
    ];
  }, [userId]);

  const swr = useSWRSearch(
    userId
      ? {
          query: null,
          searchType: 'inkinds',
          filters,
          perPage: 100,
          page: 0,
          minQueryCharacters: 0,
        }
      : null
  );

  return React.useMemo(() => {
    if (!swr?.data) return swr;

    const following = swr.data.filter(
      h => h.followersIds?.includes(userId) && h.ownerId !== userId && !h.organizersIds?.includes(userId)
    );
    const organizing = swr.data.filter(h => h.ownerId === userId || h.organizersIds?.includes(userId));

    const data = organizing
      .sort((a, b) => a.pageTitle.localeCompare(b.pageTitle))
      .concat(following.sort((a, b) => a.pageTitle.localeCompare(b.pageTitle)));

    return {
      ...swr,
      data,
    };
  }, [swr, userId]);
}
