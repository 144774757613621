import type { AnalyticsEvents } from '@gik/analytics/utils/Events';
import type { USState } from '@gik/core/models/gik/USState';
import { htmlEncode } from '@gik/core/utils/htmlencode';
import i18next from '@gik/i18n';
import type { FormikValues } from 'formik/dist/types';
import { SelectUSStates } from '../Select';
import type { FormFieldType, FormSchemaEntry } from './types';

export type ValidationErrorType =
  | 'singleRequired'
  | 'custom'
  | 'weekday-selector'
  | 'yes-no'
  | 'number'
  | 'minLength'
  | 'maxLength'
  | 'minValue'
  | 'maxValue'
  | 'required'
  | 'format'
  | 'text-editable'
  | FormFieldType; // TODO: review: FormFieldType should not be a validation error type? same with yes-no and weekday-selector

export interface ValidationError {
  type?: ValidationErrorType; // type of validation error is used internally to determine what to do with certain validations
  message: string;
  params?: ITranslationParams;
  trackingId?: AnalyticsEvents;
}

export interface ITranslationParams {
  [key: string]: string;
}

export interface ValidationValues {
  [key: string]: string | number | boolean;
}

export interface ValidationErrors<ValuesType extends FormikValues> {
  // Note: the key is the name of the field to be validated
  // @ts-ignore
  [key: keyof ValuesType]: ValidationError;
}

export interface ValidationOptions<ValuesType extends FormikValues> {
  formEl: HTMLFormElement;
  errors?: ValidationErrors<ValuesType>; // existing errors
}

export async function validateForm<ValuesType extends FormikValues>(
  schema: FormSchemaEntry[],
  // allow use of any here since the value really could be anything
  // eslint-disable-next-line
  values: Record<string, any>,
  options?: ValidationOptions<ValuesType>
): Promise<ValidationErrors<ValuesType>> {
  if (!schema) return options?.errors || {};

  const { validator } = await import('@gik/core/utils/validator');

  const errors = options?.errors || {};

  // loop though all entries
  schema.forEach(entry => {
    if (!entry) {
      throw new Error('schema entry is empty while trying to validate');
    }
    const key = entry.name;
    let error: ValidationError;

    // get the form value for this entry
    const value = values ? values[key] : null;

    // do not validate plaintext entries
    if (entry.type === 'plaintext') {
      return;
    }

    // validate required fields
    if (entry.required) {
      const reqError: ValidationError = {
        message: entry.requiredMessage || 'required',
        params: { name: entry.errorName || entry.label || entry.placeholder || entry.name },
        type: 'required',
      };

      // specific validation based on field type
      if (entry.type === 'weekday-selector') {
        // the weekday selector is an array that always has values
        // it is considered empty when all values are false
        if (!value || !value.find((item: boolean) => item === true)) {
          error = reqError;
        }
      } else if (entry.type === 'select-us-states') {
        // FIXME: backend should return null instead of NA if user has not chosen a state
        if (!value || value === 'NA') {
          error = reqError;
        }
      }

      switch (true) {
        case Array.isArray(value):
          if (value.length === 0) {
            error = reqError;
          }
          break;
        case typeof value === 'boolean':
          if (entry.type === 'yes-no') {
            error = value !== undefined ? undefined : reqError;
          } else {
            error = value ? undefined : reqError;
          }
          break;
        default:
          if (validator.isEmpty(value)) {
            switch (true) {
              default:
                error = reqError;
            }
          }
      }
    }

    // validate min length
    if (!error && entry.minLength !== undefined && entry.minLength !== null) {
      let msg = 'minLength';
      if (entry.type === 'number') {
        msg = 'minLengthNumber';
      }

      error = validator.minLength(value as string, entry.minLength)
        ? { message: msg, params: { count: entry.minLength.toString() } }
        : undefined;

      if (error) {
        error.type = 'minLength';
      }
    }

    // validate max length
    if (!error && entry.maxLength !== undefined && entry.maxLength !== null) {
      let msg = 'maxLength';
      if (entry.type === 'number') {
        msg = 'maxLengthNumber';
      }

      const encodedValue = value ? htmlEncode(value) : undefined;

      error = validator.maxLength(encodedValue as string, entry.maxLength)
        ? { message: msg, params: { count: entry.maxLength.toString() } }
        : undefined;

      if (error) {
        error.type = 'maxLength';
      }
    }

    // validate min value
    if (!error && entry.minValue !== undefined && entry.minValue !== null) {
      error = validator.minValue(value as number, entry.minValue)
        ? undefined
        : { message: 'minValue', params: { count: entry.minValue.toString() } };

      if (error) {
        error.type = 'minValue';
      }
    }

    // validate max value
    if (!error && entry.maxValue !== undefined && entry.maxValue !== null) {
      error = validator.maxValue(value as number, entry.maxValue)
        ? undefined
        : { message: 'maxValue', params: { count: entry.maxValue.toString() } };

      if (error) {
        error.type = 'maxValue';
      }
    }

    // validate mustEqual
    if (!error && entry.mustEqual !== undefined && entry.mustEqual !== null) {
      const currentValue = values[entry.mustEqual];
      const mustEqualField = schema.find(item => item.name === entry.mustEqual);
      error =
        value !== currentValue
          ? ({
              message: 'mustEqual',
              params: {
                name:
                  mustEqualField.errorName || mustEqualField.label || mustEqualField.placeholder || mustEqualField.name,
                count: entry.mustEqual,
                value,
                currentValue,
              },
            } as ValidationError)
          : undefined;
    }

    // validate mustEqualCaseInsensitive
    if (!error && entry.mustEqualCaseInsensitive !== undefined && entry.mustEqualCaseInsensitive !== null) {
      const currentValue = values[entry.mustEqualCaseInsensitive];
      const mustEqualField = schema.find(item => item.name === entry.mustEqualCaseInsensitive);
      error =
        value?.toLowerCase() !== currentValue?.toLowerCase()
          ? ({
              message: 'mustEqualCaseInsensitive',
              params: {
                name:
                  mustEqualField.errorName || mustEqualField.label || mustEqualField.placeholder || mustEqualField.name,
                count: entry.mustEqual,
                value,
                currentValue,
              },
            } as ValidationError)
          : undefined;
    }

    // validate only if required or has value
    if (entry.required || !validator.isEmpty(value)) {
      // validate by type
      if (!error) {
        // const formGroupClassName = `gik-form-group--${entry.name}`;
        // const formGroup = document.getElementsByClassName(formGroupClassName)[0];
        let validUSStates: USState[];

        switch (entry.type) {
          case 'number':
            if (typeof value === 'number') break;
            error = validator.isNumeric(value) ? undefined : { message: 'numeric' };
            break;
          case 'email':
            error = validator.isString(value) && validator.isEmail(value as string) ? undefined : { message: 'email' };
            break;
          case 'select-us-states':
            // FIXME: in case of the select-us-states we don't have access to the options from the schema
            // need to get the options from the component instead
            validUSStates = SelectUSStates['data'];

            // make sure entered value is a valid option
            if (validUSStates) {
              if (!validUSStates.find(option => option.abbr === value)) {
                error = { message: `can't find state ${value}` };
              }
            }

            break;
          case 'url':
            error = validator.isURL(value, {
              require_protocol: true,
            })
              ? undefined
              : { message: 'url' };
            break;
        }

        if (error) {
          error.type = entry.type;
        }
      }

      // validate custom validation
      if (!error && entry.customValidation?.length > 0) {
        const { message } = entry.customValidation.find(({ validate }) => validate?.(value)) || {};

        if (message) {
          error = { message: typeof message == 'function' ? message(value) : message, type: 'custom' };
        }
      }
    }

    if (error) {
      // auto translate message if it was auto generated
      if (!entry.requiredMessage && error.type != 'custom') {
        error.message = i18next.t('validation.' + error.message, error.params);
      }
      errors[key] = error;
    }
  });

  return errors;
}
