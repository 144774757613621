import { dotnetApi } from '@gik/core/api/ky/dotnetApi';

export async function userLogout(refreshToken: string): Promise<boolean> {
  try {
    const response = await dotnetApi.post('authentication/logout', {
      json: {
        refreshToken,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to log out: ' + response.status + ' ' + response.statusText);
    }

    return true;
  } catch (err) {
    return false;
  }
}
