import he from 'he';
import removeAccents from 'remove-accents';

export function sanitizeCreditCardDisplayString(input: string) {
  if (!input) return input;
  // decode unicode chars
  let output = he.decode(input);
  // remove accents
  output = removeAccents.remove(output);
  // remove special chars
  output = output.replace(/[^\w\s\\-\\.]/gi, '');
  // limit length
  output = output.substring(0, 19);

  return output;
}

export function sanitizeCarrierString(input: string, maxLength = 50) {
  if (!input) return input;
  // decode unicode chars
  let output = he.decode(input);
  // remove accents
  output = removeAccents.remove(output);
  // remove new line chars
  output = output.replace(/(\r\n|\n|\r)/gm, '');
  // limit length
  output = output.substring(0, maxLength);

  return output;
}

export function sanitizePGRecipientName(input: string, maxLength = 50) {
  if (!input) return input;
  // decode unicode chars
  let output = he.decode(input);
  // remove accents
  output = removeAccents.remove(output);
  // remove special chars
  output = output.replace(/[^\w\s\\-]/gi, '');
  // limit length
  output = output.substring(0, maxLength);

  return output;
}

function removeDisallowedCharsFromAllowedChars(str: string, allowedCharsRegex: RegExp) {
  // match all allowed characters
  const matches = str.match(allowedCharsRegex);
  // join the matches to form a new string without disallowed characters
  return matches ? matches.join('') : '';
}

function removeDisallowedChars(str: string, disallowedCharsRegex: RegExp) {
  return str.replace(disallowedCharsRegex, '');
}
