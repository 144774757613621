import type { IOAuthUserLoginResponse, IUserLoginResponse } from '@gik/api/users/types';
import { ForgotPasswordSection } from '@gik/auth';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import { SignInFlow } from '@gik/auth/components/SignInFlow/SignInFlow';
import type { AuthModalSettings } from '@gik/auth/utils/auth';
import sleep from '@gik/core/utils/sleep';
import type { DialogCloseFnType } from '@gik/ui/Dialogs/DialogTypes';
import { ModalHeader } from '@gik/ui/Modal';
import { UI } from '@gik/ui/UIManager/manager';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ISignInFlowModalResponse = IUserLoginResponse | 'forgot-password';
export async function openSignInModal(
  settings?: AuthModalSettings
): Promise<IUserLoginResponse | IOAuthUserLoginResponse | undefined> {
  const headerRef = React.createRef<ISignInFlowModalHeaderRef>();
  const closeRef = React.createRef<DialogCloseFnType>() as React.MutableRefObject<DialogCloseFnType>;

  localStorage.setItem('signInModalSettings', JSON.stringify(settings));

  const response = await UI.dialog<ISignInFlowModalResponse | IOAuthUserLoginResponse>(
    ({ close }) => {
      closeRef.current = close;

      return (
        <SignInFlow
          {...settings}
          onSuccess={doneResponse => {
            close(doneResponse);
          }}
          onForgotPassword={async (initialValues?: Partial<IForgotPasswordFormValues>) => {
            close('forgot-password');
            await sleep(200);
            openForgotPasswordModal(initialValues);
          }}
          headerRef={headerRef}
        />
      );
    },
    {
      closable: true,
      className: 'gik-sign-in-flow-modal',
      key: 'signin',
      padding: false,
      header: <SignInFlowModalHeader ref={headerRef} close={() => closeRef.current?.(null)} />,
    }
  );

  if (!response) return void settings?.onCancel?.();
  if (response == 'forgot-password') return;
  return response;
}

type ISignInFlowModalHeaderProps = {
  close();
};
export type ISignInFlowModalHeaderRef = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};
const SignInFlowModalHeader = React.forwardRef<ISignInFlowModalHeaderRef, ISignInFlowModalHeaderProps>(
  function SignInFlowModalHeaderComp({ close }, ref) {
    const { t } = useTranslation();

    const [title, setTitle] = React.useState<string>();

    React.useImperativeHandle(ref, () => ({ setTitle }));

    return <ModalHeader title={t(title)} onClose={close} />;
  }
);

export async function openForgotPasswordModal(initialValues?: Partial<IForgotPasswordFormValues>): Promise<void> {
  const footerId = 'ForgotPasswordFormFooter';

  return void UI.dialog(
    ({ close }) => {
      return (
        <ForgotPasswordSection
          initialValues={initialValues}
          onSuccess={() => {
            close();
          }}
          buttonsPortal={() => document.getElementById(footerId)}
        />
      );
    },
    {
      title: 'Reset Password',
      key: 'reset-password',
      closable: true,
      className: 'gik-auth-modal gik-forgot-password-modal',
    }
  );
}
