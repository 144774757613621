import React from 'react';
import type { ICopyButtonProps } from './CopyButton';
import { CopyButton } from './CopyButton';

export interface ICopyAdressButtonProps extends Omit<ICopyButtonProps, 'notificationText'> {
  notificationText?: string;
}

// TODO: see if we can merge the newer copy address button in the product interstitial that uses i18n  with this one
export const CopyAddressButton: React.FC<ICopyAdressButtonProps> = ({
  text = 'COPY ADDRESS',
  notificationText = 'Address copied to clipboard!',
  ...otherProps
}: ICopyButtonProps) => <CopyButton text={text} notificationText={notificationText} {...otherProps} />;
