import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useServicesByEventTypeId } from '@gik/api/calendar/serviceProductBundle';
import { useInkind } from '@gik/api/inkinds/inkind';
import { useProductsByServiceProductBundle } from '@gik/api/products/productsByServiceProductBundle';
import { CalendarClaimFooter } from '@gik/calendar';
import { GiftCardTile } from '@gik/calendar/components/GiftCards';
import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import { CalendarServiceCategorySlugs } from '@gik/calendar/models/CalendarEventTypes';
import { useCalendarStore } from '@gik/calendar/store/CalendarStore';
import { useProducts } from '@gik/checkout/api';
import type { Product } from '@gik/core/models/gik/Product';
import { CheckoutType } from '@gik/core/models/gik/Product';
import { useEnvStore } from '@gik/core/store/EnvStore';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { Checkbox } from '@gik/ui/Checkbox';
import { FormError } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import type { ISelectListOptionsType } from '@gik/ui/SelectList';
import { SelectList } from '@gik/ui/SelectList';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { TabbedView } from '@gik/ui/TabbedView';
import { Tooltip } from '@gik/ui/Tooltip';
import { UI } from '@gik/ui/UIManager';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

export type GiftCardServiceFormProps = UIComponent & {
  // service?: IWordpressService;
  entry: ICalendarEntry;
  errorMessage?: React.ReactNode;
  editEventButton?: boolean;
  backButton?: boolean;
  submitButton?: boolean;
  privateClaimOverride?: boolean; // this override will determine the value of the privateClaim checkbox and also make it disabled so the user can't change the setting
  buttonsPortal?: () => HTMLElement;
  onBack?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onEditEvent?: () => void | Promise<void>;
  onSelect?: (product: Product) => void;
};

export default function GiftCardServiceForm({
  className,
  errorMessage,
  entry,
  editEventButton,
  backButton,
  submitButton = true,
  privateClaimOverride,
  buttonsPortal,
  onBack = noop,
  onCancel = noop,
  onEditEvent = noop,
  onSelect,
  ...otherProps
}: GiftCardServiceFormProps): React.ReactElement {
  const bem = bemBlock('giftcard-service-form');
  const { t } = useTranslation();

  const [options, setOptions] = React.useState<ISelectListOptionsType<number>[]>();
  const [filteredProducts, setFilteredProducts] = React.useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = React.useState<Product>();
  const [privateClaim, setPrivate] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const setSelectedGiftcard = useCalendarStore(state => state.setSelectedGiftcard);
  const setPrivateClaim = useCalendarStore(state => state.setPrivateClaim);
  const [serviceProductBundleId, setServiceProductBundleId] = React.useState<number>();

  const allowedGiftCardIds = React.useMemo(() => entry.allowedGiftCardIds || [], [entry]);
  const { data: products } = useProducts({ productIds: allowedGiftCardIds });

  const { data: productBundleGiftCards } = useProductsByServiceProductBundle(serviceProductBundleId);
  const { data: servicesForEventType } = useServicesByEventTypeId(allowedGiftCardIds?.length > 0 ? null : entry.typeId);

  const userId = useUserStore(state => state.id);
  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { data: inkind } = useInkind(inkindRouteId);

  async function handlePurchase() {
    if (!selectedProduct) {
      setError(t(translationKeys.giftcardRequired));
      UI.notifyError(t(translationKeys.giftcardRequired));
      return;
    }

    Analytics.fireEvent(AnalyticsEvents.BuyClick, {
      productNames: selectedProduct?.name,
      productIds: selectedProduct?.id.toString(),
      productCheckoutTypes: selectedProduct?.checkoutType,
      inkindRouteId,
      userId,
      initiatedOn: 'calendar',
    });

    setPrivateClaim(privateClaim);
    setSelectedGiftcard(selectedProduct);
  }

  const _buttons = (
    <CalendarClaimFooter
      onCancel={onCancel}
      backButton={backButton}
      onBack={onBack}
      editEventButton={editEventButton}
      onEditEvent={onEditEvent}
    >
      <Button variant="primary" onClick={handlePurchase} disabled={!selectedProduct}>
        {!userId && <span>Sign Up &amp; Purchase</span>}
        {userId && <span>Purchase</span>}
      </Button>
    </CalendarClaimFooter>
  );

  /**
   * filter giftcards and create an options object if data is ready/changes
   */
  React.useEffect(() => {
    let _products: Product[];

    if (!allowedGiftCardIds?.length) {
      const _giftCardService = servicesForEventType?.find(item =>
        item.slug.endsWith(CalendarServiceCategorySlugs.GiftCard)
      );

      const _serviceProductBundleId = _giftCardService ? _giftCardService['service-product-bundle'][0] : undefined;
      setServiceProductBundleId(_serviceProductBundleId);

      _products = productBundleGiftCards;
    } else {
      _products = allowedGiftCardIds
        ?.map(id => {
          return products?.find?.(item => item.id === id);
        })
        .filter(product => product);
    }

    const filteredProducts = _products?.filter(
      p =>
        p.checkoutType === CheckoutType.TangoCard ||
        p.checkoutType === CheckoutType.Perfectgift ||
        p.checkoutType === CheckoutType.GiftyaPlatform
    );

    const _options = filteredProducts?.map(item => ({
      value: item.id,
      name: item.name,
      gridImage: item.gridImage,
    }));

    setOptions(_options);
    setFilteredProducts(filteredProducts);
    // auto select product if there is only one;
    if (filteredProducts?.length === 1) {
      setSelectedProduct(filteredProducts[0]);
      onSelect?.(filteredProducts[0]);
    }
  }, [products, entry, servicesForEventType, allowedGiftCardIds, productBundleGiftCards, onSelect]);

  const isLoading =
    // inkind page is loading
    !inkind ||
    // we have allowedGiftCardIds but the products to display them are still loading
    (allowedGiftCardIds?.length && !products) ||
    // there are no allowedGiftCardIds and we are still loading data for the default giftcards for this event type
    (!allowedGiftCardIds?.length && (!servicesForEventType || !productBundleGiftCards));

  const privateClaimEnabled = useEnvStore(state => state.USE_ANONYMOUS_DONATIONS) === 'true';

  if (isLoading) return <LoadingSpinner center />;

  return (
    <div className={bem(null, 'claim', className)} {...otherProps}>
      {filteredProducts?.length > 0 ? (
        <>
          {/* TODO: i18n */}
          <p className={bem('caption')}>These gift cards have been selected by the page organizer:</p>

          <SelectList<number>
            flex
            value={selectedProduct?.id}
            options={options}
            className={bem('select-list')}
            onChange={v => {
              const product = filteredProducts.find(item => item.id === v);
              setSelectedProduct(product);
              onSelect?.(product);
            }}
            render={(item, selected) => {
              const giftCard = filteredProducts?.find(p => p.id === item.value);
              return <GiftCardTile name={giftCard.name} thumbnail={giftCard.gridImage} selected={selected} />;
            }}
          />

          {privateClaimEnabled && (
            <div>
              <div className={bem('private-wrapper')}>
                <Checkbox
                  variant={'primary'}
                  label={"Don't show my name publicly on the Calendar"}
                  checked={privateClaimOverride == undefined ? privateClaim : privateClaimOverride}
                  disabled={privateClaimOverride !== undefined}
                  onValueChange={privateClaim => setPrivate(privateClaim)}
                />
                <Tooltip
                  allowOnMobile
                  text={
                    privateClaimOverride !== undefined
                      ? 'All gifts in an order share the same anonymity setting. To send a gift or gifts under a different setting, complete this order then begin a separate purchase.'
                      : 'Your name will only be visible to the Page Organizer and the Recipient'
                  }
                >
                  <span className={'tw-text-neutral-600'}>
                    <SvgIcon Icon={InformationCircleIcon} />
                  </span>
                </Tooltip>
              </div>
            </div>
          )}

          {entry.allowedServiceIds?.length === 1 && (
            <TabbedView
              tabs={[
                {
                  title: 'Note to supporter',
                  content: entry.description ? (
                    <div>
                      <HTMLParser rawHtml={entry.description} />
                    </div>
                  ) : (
                    <div className="gik-text-muted">{'No description was provided for this request.'}</div>
                  ),
                },
              ]}
            />
          )}

          <FormError message={error || errorMessage} centered />

          {submitButton && renderPortal(_buttons, buttonsPortal)}
        </>
      ) : (
        <FormError className={bem('error-empty')} message="No giftcards found" centered />
      )}
    </div>
  );
}
