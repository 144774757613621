import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useApi } from '@gik/core/api/swr/useApi';
import type { InkindPageAPIModel, OrganizerDetail } from '@gik/core/models/gik/InkindPage';

export function useInkind(inkindRouteId?: string, config?: SWRConfigInterface) {
  return useApi<InkindPageAPIModel>(inkindRouteId ? `inkinds/${inkindRouteId}` : null, null, config);
}

export function useInkindNumSupporters(inkindRouteId?: string, config?: SWRConfigInterface) {
  return useApi<string>(inkindRouteId ? `inkinds/${inkindRouteId}/numSupporters` : null, null, config);
}

export function useInkindOrganizers(inkindRouteId?: string, config?: SWRConfigInterface) {
  return useApi<OrganizerDetail[]>(inkindRouteId ? `inkinds/${inkindRouteId}/organizers` : null, null, config);
}
