import { isBrowser } from '@/utils/environment';
import { useBemCN } from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import React from 'react';
import { useInView } from 'react-intersection-observer';

type InViewProps = {
  className: string;
  persist?: boolean;
  onInView?();
};

const InViewComp = ({
  className,
  children,
  persist = true,
  onInView = noop,
  ...otherProps
}: React.PropsWithChildren<InViewProps>) => {
  const bem = useBemCN('in-view');
  const [everInView, setEverInView] = React.useState(false);
  const { ref, inView } = useInView({ threshold: 0, rootMargin: isBrowser() ? window.innerHeight / 2 + 'px' : '0px' });
  React.useEffect(() => {
    if (inView) {
      setEverInView(true);
      onInView();
    }
  }, [inView, onInView, setEverInView]);

  return (
    <div ref={ref} {...bem(null, null, className)} {...otherProps}>
      {(!isBrowser() || inView || (everInView && persist)) && children}
    </div>
  );
};

export const InView = React.memo(InViewComp);
