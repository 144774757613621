import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { FormField } from '@gik/ui/Form';
import loadable from '@loadable/component';
import moment from 'moment';
import React from 'react';
import type { ICalendarEventTimeAndRepeatFormValues } from './CalendarEventForm';

const RRule = loadable.lib(() => import('rrule'));

export interface ICalendarTimePropsAndRepeatForm extends React.HTMLAttributes<HTMLDivElement> {
  giftCardsOnly?: boolean;
  formValues: ICalendarEventTimeAndRepeatFormValues;
}

export function CalendarTimeAndRepeatForm({
  className,
  giftCardsOnly = false,
  formValues,
  ...otherProps
}: ICalendarTimePropsAndRepeatForm): React.ReactElement {
  const bem = bemBlock('calendar-time-and-repeat');
  const isMd = useBreakpoint(Breakpoint.MD);

  const startDateforEndDate = formValues ? moment(formValues?.startDate).add(1, 'day').format() : null;

  const hasRepeat = formValues?.repeat !== undefined && formValues?.repeat !== null && formValues?.repeat !== -1;
  const repeatSelected = formValues?.repeat !== undefined && formValues?.repeat !== null;

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      {/* Desktop layout */}
      <div className={bem('group1')}>
        <div className={bem('repeat-section')}>
          {/* {formValues && formValues?.repeat !== undefined && formValues?.repeat !== null && formValues?.repeat !== -1 && (
            <FormField extraProps={{ startDate: startDateforEndDate }} name="endDate" />
          )} */}

          <FormField name="repeat" />
          {formValues?.repeat === RRule.WEEKLY && (
            <span className={bem('repeat-on')}>on {moment(formValues?.startDate).format('dddd')}</span>
          )}
        </div>

        {repeatSelected && (
          <div className={bem('date-time-section', null, ['gik-hidden-md-down'])}>
            {!giftCardsOnly && (
              <div className={bem('time-picker')}>
                <FormField name="startTime" vertical disabled={formValues?.allDay} />

                <FormField name="endTime" vertical disabled={formValues?.allDay} />
                {/* {!giftCardsOnly && <FormField name="anytime" />} */}
                {!giftCardsOnly && <FormField name="allDay" />}
              </div>
            )}
          </div>
        )}

        {formValues?.repeat === 10 && !isMd && <FormField name="weekdays" />}
      </div>
      {repeatSelected && (
        <div className={bem('group2', null, ['gik-hidden-md-down'])}>
          <FormField name="startDate" />
          {hasRepeat && <FormField extraProps={{ startDate: startDateforEndDate }} name="endDate" />}
          {formValues?.repeat === 10 && isMd && <FormField name="weekdays" />}
        </div>
      )}
      {/* {!isMd && formValues?.repeat === 10 && <FormField name="weekdays" inline />} */}
      {/* Mobile layout */}
      <div className={bem(null, ['mobile'], ['gik-hidden-md'])}>
        {repeatSelected && (
          <>
            <section className={bem('date-time-section')}>
              <main className={bem('date-section')}>
                <FormField name="startDate" />
                {hasRepeat && <FormField extraProps={{ startDate: startDateforEndDate }} name="endDate" />}
              </main>

              {!giftCardsOnly && (
                <aside className={bem('time-section')}>
                  <div className={bem('time-selectors')}>
                    <FormField name="startTime" disabled={formValues?.allDay} />
                    <FormField name="endTime" disabled={formValues?.allDay} />
                  </div>
                  {/* {!giftCardsOnly && <FormField name="anytime" />} */}
                  <FormField name="allDay" />
                </aside>
              )}
            </section>
          </>
        )}
      </div>
    </div>
  );
}
