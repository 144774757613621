import { EmailAddressField } from '@gik/core/form/fields/EmailAddressField';
import { FirstNameField } from '@gik/core/form/fields/FirstNameField';
import { LastNameField } from '@gik/core/form/fields/LastNameField';
import type { FormSchemaEntry } from '@gik/ui/Form';
import type { RefObject } from 'react';

export type ShippingFormSchemaProps = {
  ref?: RefObject<HTMLElement>;
  emailReadOnly?: boolean;
  nameReadOnly?: boolean;
  lastNameRequired?: boolean;
};

export const ShippingFormSchema: (props: ShippingFormSchemaProps) => FormSchemaEntry[] = ({
  ref,
  emailReadOnly,
  nameReadOnly,
  lastNameRequired,
}) => [
  FirstNameField({ readOnly: nameReadOnly }),
  LastNameField({ readOnly: nameReadOnly, required: lastNameRequired }),
  EmailAddressField({ readOnly: emailReadOnly }),
  // CompanyNameField(),
  // CountryField(),
  // StreetAddress1Field(),
  // StreetAddress2Field(),
  // CityField(),
  // StateField({ scrollParent: ref }),
  // ZipCodeField(),
];
