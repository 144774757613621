import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'wishlist-page';

const translations = {
  title: "{{inkindPageTitle}}'s Wishlist", // full wishlist page
  empty: 'Wishlist empty',
  findWishlistIdeas: 'Find Wishlist Ideas', // organizers only
  seeFull: 'SEE FULL WISHLIST', // wishlist rows / grid on inkind page
  seeMore: 'SEE MORE', // full wishlist page - paging
  backToPage: 'BACK TO PAGE', // full wishlist page - back to inkind page
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
