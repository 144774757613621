import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Outlook = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={svgRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 4.25352V19.5587L14.2719 22V2L2.5 4.25352ZM10.3773 14.4935C9.87981 15.141 9.23122 15.4655 8.43073 15.4655C7.65075 15.4655 7.01583 15.1516 6.52445 14.5243C6.03383 13.8963 5.78776 13.0791 5.78776 12.071C5.78776 11.0066 6.03687 10.1457 6.53584 9.48845C7.03482 8.83118 7.69556 8.50216 8.51807 8.50216C9.29501 8.50216 9.92386 8.81615 10.4031 9.44564C10.8831 10.0751 11.1231 10.9044 11.1231 11.9343C11.1238 12.9927 10.8747 13.846 10.3773 14.4935ZM15.0162 10.1525V6.24037H21.8066C22.0565 6.24037 22.4993 6.5769 22.4993 7.04638L16.5503 11.0133C16.5138 11.0246 16.4349 11.0238 16.3984 11.0133L15.0162 10.1525ZM16.2776 12.4691L15.0161 11.612H15.0153V16.9288H21.4238C21.9934 16.9288 22.5 16.6006 22.5 15.8178V8.6276C22.5 8.6276 16.6345 12.4811 16.6641 12.4722C16.6298 12.491 16.4387 12.5862 16.2776 12.4691ZM8.48464 9.80843C8.05478 9.80843 7.71226 10.0082 7.45935 10.4071C7.20645 10.806 7.07961 11.3341 7.07961 11.9913C7.07961 12.6584 7.20645 13.1857 7.45935 13.5733C7.71226 13.9617 8.04415 14.1547 8.45426 14.1547C8.87729 14.1547 9.21298 13.9662 9.46057 13.5891C9.70815 13.212 9.83271 12.6884 9.83271 12.0191C9.83271 11.3213 9.71271 10.7782 9.47196 10.3898C9.2312 10.0022 8.90235 9.80843 8.48464 9.80843Z"
          fill="#2A6EBB"
        />
      </svg>
    );
  }
);
