import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'competitors-preview';

// TODO: fill in missing translations
const translations = {
  // header
  title: 'How does\nGive InKind\nmeasure up?',
  description: 'We don’t feel like there’s competition,\nbut why don’t you be the judge',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
