import { useServices } from '@gik/api/calendar/service';
import { useServiceCategories } from '@gik/api/calendar/serviceProductBundle';
import type IWordpressService from '@gik/core/models/wordpress/WordpressService';
import bemBlock from '@gik/core/utils/bemBlock';
import { getTailwindConfig } from '@gik/core/utils/tailwind';
import { Image } from '@gik/ui/Image';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { Tooltip } from '@gik/ui/Tooltip';
import React from 'react';
import type { IWordpressServiceProductBundleOption } from './CalendarServiceSelectList';
const twConfig = getTailwindConfig();

export interface ICalendarServicePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number[];
  editable: boolean;
  allowGiftCards?: boolean;
  giftcardBundleId?: number;
  eventTypeId?: number;
}

export function CalendarServicePicker({
  className,
  editable = true,
  allowGiftCards,
  giftcardBundleId,
  value,
  eventTypeId,
  ...otherProps
}: ICalendarServicePickerProps): React.ReactElement {
  const bem = bemBlock('calendar-services-picker');

  // Local State
  const [options, setOptions] = React.useState<IWordpressServiceProductBundleOption[]>();

  // SWR
  const { data: services } = useServices();
  const { data: serviceProductBundles } = useServiceCategories();

  /**
   * Create a list of matching services based on calendar event type
   */
  React.useEffect(() => {
    if (!services || !serviceProductBundles) return;

    // get services for the requested event type
    let _matchingServices = services
      ?.filter(item => {
        return item.acf.preview_mode !== true && item['cal-event-type']?.indexOf(eventTypeId) > -1;
      })
      .sort((a: IWordpressService, b: IWordpressService) => parseInt(a.acf.sort_order) - parseInt(b.acf.sort_order));

    if (value) {
      _matchingServices = _matchingServices.filter(item => value.indexOf(item.id) > -1);
    }

    // create options for the SelectList
    const _options: IWordpressServiceProductBundleOption[] = _matchingServices.map(service => {
      const category = serviceProductBundles.find(item => item.id === service['service-category'][0]);

      return {
        label: service.acf.display_name || category.name,
        value: service.id,
        image: service.acf.taxonomy_svg_icon || category.acf.taxonomy_svg_icon,
      };
    });

    setOptions(_options);
  }, [services, serviceProductBundles, eventTypeId, value, giftcardBundleId, allowGiftCards]);

  const isLoading = !options;

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className={bem('icon-wrapper')}>
            {options.map(item => {
              return (
                <Tooltip key={item.value} text={item.label} zIndex={twConfig.theme.zIndex.popovers}>
                  <div>
                    <Image className={bem('icon')} src={item.image} />
                  </div>
                </Tooltip>
              );
            })}
          </div>

          {editable && <span className={bem('editButton')}>change</span>}
        </>
      )}
    </div>
  );
}
