import { Analytics } from '@gik/analytics';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { Input } from '@gik/ui/Input';
import React from 'react';

export type CheckoutPromoCodeProps = {
  className?: string;
  onSuccessfulPurchase?(promoCodeId?: string): void;
};

export function CheckoutPromoCode({
  className,
  onSuccessfulPurchase,
  ...otherProps
}: React.PropsWithChildren<CheckoutPromoCodeProps>): React.ReactElement {
  const bem = useBemCN('checkout-promo-code');

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);

  const [promoCode, setPromoCode] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const handleApply = React.useCallback(async () => {
    setError(null);
    setLoading(true);
    if (promoCode?.length < 5) {
      setError('Code must be at least 5 characters');
      setLoading(false);
      return;
    }

    const response = await dotnetApi.post('promoCodes/redeem', {
      json: {
        promoCodeId: promoCode,
        inkindRouteId: inkindRouteId,
      },
    });

    if (response.ok) {
      onSuccessfulPurchase(promoCode);
    } else {
      switch (response.status) {
        case 409:
          setError('This promo code is either invalid or has already been used');
          break;
        default:
          Analytics.fireUserNotice(await response.text(), 'errorPromoCode');
          setError('Something went wrong..., please try again or contact support');
      }
    }

    setLoading(false);
  }, [inkindRouteId, onSuccessfulPurchase, promoCode]);

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <span {...bem('title')}>Have a Promo Code?</span>
      <div {...bem('form')}>
        <span {...bem('code-prefix')}>GIVEINKIND-PREMIUM-</span>
        <Input
          placeholder="F23JU"
          value={promoCode}
          onValueChange={setPromoCode}
          maxLength={5}
          minLength={5}
          maxLengthDisplay={false}
        />
        {promoCode?.length === 5 && (
          <Button onClick={handleApply} size="sm" loading={loading}>
            Apply
          </Button>
        )}
      </div>
      {error && <div {...bem('error')}>{error}</div>}
    </div>
  );
}
