import type { FormSchemaEntry } from '@gik/ui/Form';

export const SaveAddressField = (storedBillingDetails: boolean): FormSchemaEntry => ({
  // label: 'Save Billing Address',
  name: 'saveAddress',
  type: 'checkbox',
  props: {
    label: storedBillingDetails ? 'Update billing details' : 'Remember billing details',
  },
});
