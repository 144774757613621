import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import type { BillingAddress } from '@gik/core/models/gik/BillingAddress';

const path = 'users';

export function addBillingAddress(userId: string, payload: BillingAddress) {
  return dotnetApi.post(`${path}/${userId}/billingaddresses`, {
    json: payload,
  });
}

export function updateBillingAddress(userId: string, addressId: string, payload: Partial<BillingAddress>) {
  return dotnetApi.patch(`${path}/${userId}/billingaddresses/${addressId}`, {
    json: payload,
  });
}

export function deleteBillingAddress(userId: string, addressId: string) {
  return dotnetApi.delete(`${path}/${userId}/billingaddresses/${addressId}`);
}
