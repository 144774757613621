import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';

export interface ICreatePageSplitLayoutProps {
  className?: string;
}

const blockName = 'create-page-split-layout';

export default function CreatePageSplitLayout({
  className,
  children,
  ...otherProps
}: React.PropsWithChildren<ICreatePageSplitLayoutProps>) {
  const bem = bemBlock(blockName);

  return (
    <div className={bem(null, null, className)} {...otherProps}>
      {children}
    </div>
  );
}
