import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import { CalendarEventTypeIds, CalendarServiceCategoryIds } from '@gik/calendar/models/CalendarEventTypes';
import { useEnvStore } from '@gik/core/store/EnvStore';
import type { FormSchemaEntry } from '@gik/ui/Form';

export function getCalendarClaimFormSchema(entry: ICalendarEntry, serviceTypeId: number): FormSchemaEntry[] {
  let descriptionLabel = 'What will you be providing?';
  let descriptionPlaceholder = 'optional';
  const privateClaimEnabled = useEnvStore.getState().USE_ANONYMOUS_DONATIONS === 'true';

  if (serviceTypeId === CalendarServiceCategoryIds.LocalRestaurant) {
    descriptionLabel = 'What are you ordering?';
    descriptionPlaceholder = 'optional';
  }

  if (entry.typeId === CalendarEventTypeIds.Other) {
    descriptionLabel = "Details on what you'll be providing";
    descriptionPlaceholder = 'optional';
  }

  if (
    entry.typeId === CalendarEventTypeIds.PetCare ||
    entry.typeId === CalendarEventTypeIds.ChildCare ||
    entry.typeId === CalendarEventTypeIds.SeniorCare ||
    entry.typeId === CalendarEventTypeIds.HomeServices ||
    entry.typeId === CalendarEventTypeIds.Transportation
  ) {
    descriptionLabel = 'Contact Info';
    descriptionPlaceholder = 'optional';
  }

  const schema: FormSchemaEntry[] = [
    {
      name: 'carType',
      type: 'textarea',
      label: 'What car will you be arriving in?',
      placeholder: 'optional',
      maxLength: 255,
      props: {
        rows: 3,
        maxLength: 255,
        maxLengthDisplay: true,
      },
    },
    {
      name: 'description',
      type: 'text',
      label: descriptionLabel,
      placeholder: descriptionPlaceholder,
      maxLength: 255,
      props: {
        rows: 3,
        maxLength: 255,
        maxLengthDisplay: true,
      },
    },
    {
      name: 'note',
      type: 'textarea',
      label: 'Personal note to recipient',
      placeholder: 'optional',
      maxLength: 1000,
      props: {
        rows: 6,
        maxLength: 1000,
        maxLengthDisplay: true,
      },
    },
  ];

  if (privateClaimEnabled) {
    schema.push({
      name: 'privateClaim',
      type: 'checkbox',
      props: {
        label: "Don't display my name publicly on the calendar",
        variant: 'primary',
      },
    });
  }

  return schema;
}
